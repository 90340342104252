import type { AnyAction } from 'redux';

import * as actions from 'app/redux/actions/guides';
import { createGuide } from 'app/redux/models/Guides/Guides';
import type { Guide } from 'app/redux/models/Guides/Guides';

export type Guides = {
  loading: boolean;
  guide: Guide | undefined;
};

export const initialState: Guides = {
  loading: false,
  guide: undefined,
};

function guides(state = initialState, action: AnyAction): Guides {
  if (actions.startLoading.match(action)) {
    return {
      ...state,
      loading: true,
    };
  }

  if (actions.stopLoading.match(action)) {
    return {
      ...state,
      loading: false,
    };
  }

  if (actions.fetchGuideSuccess.match(action)) {
    const { guide } = action.payload;
    const newGuide = createGuide(guide);

    return {
      ...state,
      loading: false,
      guide: newGuide,
    };
  }

  return state;
}

export default guides;
