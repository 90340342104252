import type { Coordinates } from 'app/redux/models/Coordinates/Coordinates';
import { createCoordinates } from 'app/redux/models/Coordinates/Coordinates';
import type { Location } from 'app/redux/models/Location/Location';

export type Club = {
  readonly id: number;
  readonly slug: string;
  readonly name: string;
  readonly description: string;
  readonly icon: string;
  readonly mailchimpList: string;
  readonly mailchimpTag: string;
  readonly radius: number;
  readonly countryId: number;
  readonly published: boolean;
  readonly groupId: number;
  readonly bounds: Array<Coordinates>;
  readonly city: Location;
};

export function createClub(values: any): Club {
  const {
    id,
    slug,
    name,
    description,
    icon,
    mailchimpList,
    mailchimpTag,
    radius,
    countryId,
    published,
    groupId,
    bounds,
    city,
  } = values;

  return {
    id,
    slug,
    name,
    description,
    icon,
    mailchimpList,
    mailchimpTag,
    radius,
    countryId,
    published,
    groupId,
    bounds: bounds.map((b: any) => createCoordinates(b)),
    city,
  };
}
