import { find, isArray, isEmpty } from 'lodash';
import { actionTypes, autofill, change, getFormValues } from 'redux-form';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import {
  entitySelector,
  experienceCategorySelector,
} from 'app/redux/selectors';

// TODO: Dunno if this logic should be here or in a component
export function* watchFormChange({ meta, payload }) {
  switch (meta.form) {
    case 'experience': {
      if (meta.field === 'categoryId' || meta.field === 'included') {
        const { included, categoryId, coverPicture } = yield select(
          getFormValues(meta.form)
        );

        const selectedCategoryFamily = yield select(
          experienceCategorySelector(categoryId)
        );

        const categoryWithExperienceType = find(
          selectedCategoryFamily,
          'experienceType'
        );

        yield put(
          autofill(
            'experience',
            'practicalInfo.type',
            categoryWithExperienceType.experienceType
          )
        );

        if (categoryWithExperienceType.experienceType === 'room_highlight') {
          yield put(autofill('experience', 'openingHours', undefined));
        }

        if (isEmpty(coverPicture) && !included) {
          const categoryWithPicture = find(selectedCategoryFamily, 'pictureId');

          if (categoryWithPicture) {
            const categoryEmoji = yield select(
              entitySelector('emojis', categoryWithPicture.pictureId)
            );

            yield put(autofill('experience', 'coverPicture', categoryEmoji));
          }
        }
      }

      if (meta.field === 'spaceIds') {
        const hotelSpaceId = isArray(payload) ? payload[0] : payload;
        const selectedSpace = yield select(
          entitySelector('hotelSpaces', hotelSpaceId)
        );

        if (selectedSpace) {
          yield put(
            autofill('experience', 'openingHours', selectedSpace.openingHours)
          );
        }
      }

      break;
    }

    case 'guide': {
      // Reset roomId on packageId change
      const fields = meta.field.split('.');
      const lastKey = fields.pop();

      if (lastKey === 'packageId') {
        const roomKey = [...fields, 'roomId'].join('.');

        yield put(change('guide', roomKey, null));
      }

      break;
    }

    default:
      break;
  }
}

export default function* entitiesSagas() {
  yield all([takeEvery(actionTypes.CHANGE, watchFormChange)]);
}
