import { message } from 'antd';
import { push } from 'connected-react-router';
import { all, put, takeEvery } from 'redux-saga/effects';

import * as entitiesActions from 'app/redux/actions/entities';
import * as guidesActions from 'app/redux/actions/guides';
import { get, patch, post, requestGenerator } from 'app/redux/requests';

export function* sortGuides(
  action: ReturnType<typeof guidesActions.trySortGuides>
) {
  const {
    payload: { guideIds },
  } = action;

  yield requestGenerator(
    () => put(entitiesActions.startPatchEntity('guides')),
    () => patch('guides/sort', guideIds),
    () => put(entitiesActions.patchEntitySuccess('guides')),
    (error: any) => put(entitiesActions.patchEntityFailure('guides', error)),
    { expectJson: false }
  );
}

export function* featureGuide(
  action: ReturnType<typeof guidesActions.tryFeatureGuide>
) {
  const {
    payload: { guideId },
  } = action;

  yield requestGenerator(
    () => put(entitiesActions.startPatchEntity('guides')),
    () => patch(`guides/${guideId}/feature`),
    () => {
      message.success(`Featured guide #${guideId}!`);

      return put(entitiesActions.patchEntitySuccess('guides'));
    },
    (error: any) => {
      message.error(`Failed to feature guide #${guideId}!`);

      return put(entitiesActions.patchEntityFailure('guides', error));
    }
  );
}

export function* hybridGuide(
  action: ReturnType<typeof guidesActions.tryHybridGuide>
) {
  const {
    payload: { guideId, isHybrid },
  } = action;

  yield requestGenerator(
    () => put(entitiesActions.startPatchEntity('guides')),
    () => patch(`guides/${guideId}/hybrid`, { isHybrid }),
    () => {
      message.success(`Success hybrid conversion for guide #${guideId}!`);

      return put(entitiesActions.patchEntitySuccess('guides'));
    },
    (error: any) => {
      message.error(`Failed to toggle hybrid for guide #${guideId}`);

      return put(entitiesActions.patchEntityFailure('guides', error));
    },
    { expectJson: false }
  );
}

export function* fetchGuide(
  action: ReturnType<typeof guidesActions.fetchGuide>
) {
  const { guideId } = action.payload;

  yield requestGenerator(
    () => put(guidesActions.startLoading()),
    () => get(`guides/${guideId}`),
    (guide: any) => put(guidesActions.fetchGuideSuccess({ guide })),
    (error: any) =>
      all([
        put(guidesActions.fetchGuideFailure({ error })),
        message.error(`Failed to fetch guide #${guideId}`),
      ])
  );
}

export function* saveGuide(action: ReturnType<typeof guidesActions.saveGuide>) {
  const { payload } = action;

  yield requestGenerator(
    () => put(guidesActions.startLoading()),
    () => post('guides', payload),
    (guideId: any) => {
      if (!payload.id) {
        if (payload.isDuplicate) {
          message.success(`Successly duplicated guide #${guideId}!`);
        } else {
          message.success(`Successly created guide #${guideId}!`);
        }

        put(guidesActions.stopLoading());

        return put(push(`/guides/${guideId}/edit`, { isNew: true }));
      }

      message.success(`Successly saved guide #${payload.id}!`);

      return put(guidesActions.fetchGuide({ guideId }));
    },
    () =>
      all([
        message.error(
          payload.id
            ? `Failed to save guide #${payload.id}`
            : 'Failed to create guide.'
        ),
        put(guidesActions.stopLoading()),
      ]),
    { expectJson: false }
  );
}

export function* duplicateGuide(
  action: ReturnType<typeof guidesActions.duplicateGuide>
) {
  const { guideId, featured, isHybrid } = action.payload;

  yield requestGenerator(
    () => put(guidesActions.startLoading()),
    () => get(`guides/${guideId}`),
    (guide: any) =>
      all([
        put(
          guidesActions.fetchGuideSuccess({
            guide: {
              ...guide,
              id: undefined,
              slug: undefined,
              clubId: undefined,
              sections: guide.sections.map((section: any) => {
                return {
                  ...section,
                  id: undefined,
                  packages: [],
                };
              }),
              featured,
              isHybrid,
              isDuplicate: true,
            },
          })
        ),
        put(push('/guides/new')),
      ]),
    () =>
      all([
        message.error(`Failed to duplicate guide #${guideId}`),
        put(guidesActions.stopLoading()),
      ])
  );
}

export default function* entitiesSagas() {
  yield all([
    takeEvery(guidesActions.GUIDES_TRY_SORT_GUIDES, sortGuides),
    takeEvery(guidesActions.GUIDES_TRY_FEATURE_GUIDE, featureGuide),
    takeEvery(guidesActions.GUIDES_TRY_HYBRID_GUIDE, hybridGuide),
    takeEvery(guidesActions.GUIDES_FETCH_GUIDE, fetchGuide),
    takeEvery(guidesActions.GUIDES_SAVE_GUIDE, saveGuide),
    takeEvery(guidesActions.GUIDES_DUPLICATE_GUIDE, duplicateGuide),
  ]);
}
