import { SHeader } from 'app/components/StaycationUI';

import WebsiteButton from '../../ExtranetInventory/WebsiteButton/WebsiteButton';
import { TaskCenterButton } from '../TaskCenter/TaskCenterButton/TaskCenterButton';

type ExtranetHeaderProps = {
  page: string;
  subTitle?: any;
  withWebsiteButton?: boolean;
};

export const ExtranetHeader = ({
  page,
  subTitle,
  withWebsiteButton = false,
}: ExtranetHeaderProps) => {
  return (
    <SHeader
      title={page}
      subtitle={subTitle}
      extra={[
        withWebsiteButton && <WebsiteButton key={'website-button'} />,
        <TaskCenterButton key={'task-center-button'} />,
      ]}
    />
  );
};
