import React from 'react';

import './Tooltip.scss';

export const StaycationRateTooltip = () => (
  <div className="extranet-inventory-tooltip__container">
    <div className="extranet-inventory-tooltip__title">
      Staycation rate is the rate published on Staycation and should match our
      discount requirements.
    </div>
    <div className="extranet-inventory-tooltip__subtitle">
      Please get in touch with your Staycation Account Manager if you need
      further assistance.
    </div>
  </div>
);
