import { useQuery } from '@tanstack/react-query';

import { AdminUser } from 'app/typings';
import { apiRequest } from 'app/utils/request/api';

const path = '/admin/users/admins' as const;
const pathGetById = '/admin/users/admins/:id' as const;

export function useAdminUser() {
  return useQuery({
    queryKey: [path],
    queryFn: () => apiRequest({ path }) as Promise<AdminUser[]>,
    staleTime: Infinity,
  });
}

export function useAdminUserById(id?: number) {
  return useQuery({
    queryKey: [pathGetById, { id }],
    queryFn: id
      ? () =>
          apiRequest({
            path: pathGetById,
            params: { id: id.toString() },
          }) as Promise<AdminUser>
      : undefined,
    enabled: !!id,
    staleTime: Infinity,
  });
}
