import { message } from 'antd';
import queryString from 'query-string';
import { all, put, takeEvery } from 'redux-saga/effects';

import * as gesturesActions from 'app/redux/actions/commercialGestures';
import { get, post, requestGenerator } from 'app/redux/requests';

const URL = 'admin/commercial-gestures';

export function* tryFetchGestures({
  payload,
}: ReturnType<typeof gesturesActions.tryFetchGestures>) {
  yield requestGenerator(
    () => put(gesturesActions.startLoading()),
    () => {
      const { search, sortingColumn, sortingOrder, offset, limit } = payload;

      const stringifiedParams = queryString.stringify({
        search,
        sortingColumn,
        sortingOrder,
        offset,
        limit,
      });

      return stringifiedParams ? get(`${URL}?${stringifiedParams}`) : get(URL);
    },
    (result: any) =>
      put(gesturesActions.fetchGesturesSuccess({ gestures: result })),
    (error: any) =>
      all([
        put(gesturesActions.fetchGesturesFailure()),
        message.error('Error fetching Gestures', error),
      ])
  );
}

export function* tryFetchHotels() {
  yield requestGenerator(
    () => put(gesturesActions.startLoading()),
    () => get('hotels'),
    (result: any) =>
      put(gesturesActions.fetchHotelsSuccess({ hotels: result })),
    (error: any) =>
      all([
        put(gesturesActions.fetchHotelsFailure()),
        message.error('Error fetching Hotels for gestures', error),
      ])
  );
}

export function* trySaveGesture({
  payload,
}: ReturnType<typeof gesturesActions.trySaveGesture>) {
  yield requestGenerator(
    () => put(gesturesActions.startLoading()),
    () => post(URL, payload),
    () =>
      all([
        put(gesturesActions.saveGesturesSuccess()),
        message.success(`Gesture succesfully created`),
        put(gesturesActions.tryFetchGestures({})),
      ]),
    (error: any) =>
      all([
        put(gesturesActions.fetchGesturesFailure()),
        message.error('Error fetching Gestures', error),
      ]),
    { expectJson: false }
  );
}

export default function* gesturesSaga() {
  yield all([
    takeEvery(gesturesActions.GESTURES_TRY_FETCH_GESTURES, tryFetchGestures),
    takeEvery(gesturesActions.GESTURES_TRY_FETCH_HOTELS, tryFetchHotels),
    takeEvery(gesturesActions.GESTURES_TRY_SAVE_GESTURE, trySaveGesture),
  ]);
}
