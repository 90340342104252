import { createAction } from '@reduxjs/toolkit';

import {
  GiftCard,
  GiftCardEditForm,
  GiftCardStatus,
  GiftCardType,
  RefundForm,
} from '../models/GiftCard/GiftCard';

export type TryFetchGiftCardsPayload = {
  offset: number;
  search?: string;
  sorting?: string;
  status: GiftCardStatus[];
  mytype: GiftCardType[];
};
export type FetchGiftCardsSuccessPayload = {
  giftCards: GiftCard[];
};
export type FetchGiftCardsFailurePayload = {
  error: string;
};
export type TryResendConfirmationPayload = {
  code: string;
};
export type ResendConfirmationSuccessPayload = {
  id: number;
};
export type ResendConfirmationFailurePayload = {
  error: string;
};
export type StartResendConfirmationPayload = {};

export type TryResendECardPayload = {
  code: string;
};
export type StartResendECardPayload = {};
export type ResendECardSuccessPayload = {
  id: number;
};
export type ResendECardFailurePayload = {
  error: string;
};

export type TryDownloadPDFPayload = {
  code: string;
};

export type TryEditGiftCardPayload = {
  code: string;
  body: GiftCardEditForm;
  refetch: () => Promise<unknown>;
};

export type RefundGiftCardPayload = {
  code: string;
  body: RefundForm;
};

export const TRY_RESEND_CONFIRMATION = 'GIFT_CARDS_TRY_RESEND_CONFIRMATION';
export const START_RESEND_CONFIRMATION = 'GIFT_CARDS_START_RESEND_CONFIRMATION';
export const RESEND_CONFIRMATION_SUCCESS =
  'GIFT_CARDS_RESEND_CONFIRMATION_SUCCESS';
export const RESEND_CONFIRMATION_FAILURE =
  'GIFT_CARDS_RESEND_CONFIRMATION_FAILURE';
export const TRY_RESEND_E_CARD = 'GIFT_CARDS_TRY_RESEND_E_CARD';
export const START_RESEND_E_CARD = 'GIFT_CARDS_START_RESEND_E_CARD';
export const RESEND_E_CARD_SUCCESS = 'GIFT_CARDS_RESEND_E_CARD_SUCCESS';
export const RESEND_E_CARD_FAILURE = 'GIFT_CARDS_RESEND_E_CARD_FAILURE';
export const TRY_DOWNLOAD_PDF = 'GIFT_CARDS_TRY_DOWNLOAD_PDF';
export const TRY_EDIT_GIFT_CARD = 'GIFT_CARDS_TRY_EDIT_GIFT_CARD';
export const TRY_FETCH_GIFT_CARDS = 'GIFT_CARDS_TRY_FETCH_GIFT_CARDS';
export const FETCH_GIFT_CARDS_SUCCESS = 'GIFT_CARDS_FETCH_GIFT_CARDS_SUCCESS';
export const FETCH_GIFT_CARDS_FAILURE = 'GIFT_CARDS_FETCH_GIFT_CARDS_FAILURE';
export const REFUND_GIFT_CARD = 'REFUND_GIFT_CARD';

export const tryResendConfirmation = createAction<TryResendConfirmationPayload>(
  TRY_RESEND_CONFIRMATION
);
export const startResendConfirmation =
  createAction<StartResendConfirmationPayload>(START_RESEND_CONFIRMATION);
export const resendConfirmationSuccess =
  createAction<ResendConfirmationSuccessPayload>(RESEND_CONFIRMATION_SUCCESS);
export const resendConfirmationFailure =
  createAction<ResendConfirmationFailurePayload>(RESEND_CONFIRMATION_FAILURE);

export const tryResendECard =
  createAction<TryResendECardPayload>(TRY_RESEND_E_CARD);
export const startResendECard =
  createAction<StartResendECardPayload>(START_RESEND_E_CARD);
export const resendECardSuccess =
  createAction<ResendConfirmationSuccessPayload>(RESEND_CONFIRMATION_SUCCESS);
export const resendECardFailure =
  createAction<ResendConfirmationSuccessPayload>(RESEND_CONFIRMATION_FAILURE);

export const tryDownloadPdf =
  createAction<TryDownloadPDFPayload>(TRY_DOWNLOAD_PDF);
export const tryEditGiftCard =
  createAction<TryEditGiftCardPayload>(TRY_EDIT_GIFT_CARD);

export const tryFetchGiftCards =
  createAction<TryFetchGiftCardsPayload>(TRY_FETCH_GIFT_CARDS);
export const fetchGiftCardsSuccess = createAction<FetchGiftCardsSuccessPayload>(
  FETCH_GIFT_CARDS_SUCCESS
);
export const fetchGiftCardsFailure = createAction<FetchGiftCardsFailurePayload>(
  FETCH_GIFT_CARDS_FAILURE
);

export const refundGiftCard =
  createAction<RefundGiftCardPayload>(REFUND_GIFT_CARD);
