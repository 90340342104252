export const actions = {
  TRY_SET_HOTEL_UNAVAILABLE: 'TRY_SET_HOTEL_UNAVAILABLE',
  TRY_DISMISS_WARNINGS: 'TRY_DISMISS_WARNINGS',
  TRY_FETCH_INVENTORY: 'TRY_FETCH_INVENTORY',
  FETCH_INVENTORY_SUCCESS: 'FETCH_INVENTORY_SUCCESS',
  INVENTORY_START_LOADING: 'INVENTORY_START_LOADING',
  INVENTORY_STOP_LOADING: 'INVENTORY_STOP_LOADING',
};

export function trySetHotelUnavailable(hotelId) {
  return { hotelId, type: actions.TRY_SET_HOTEL_UNAVAILABLE };
}

export function tryDismissWarnings(openingsIds) {
  return { openingsIds, type: actions.TRY_DISMISS_WARNINGS };
}

export function tryFetchInventory(params) {
  return { ...params, type: actions.TRY_FETCH_INVENTORY };
}

export function fetchInventorySuccess(inventory) {
  return { inventory, type: actions.FETCH_INVENTORY_SUCCESS };
}

export function startLoading() {
  return { type: actions.INVENTORY_START_LOADING };
}

export function stopLoading() {
  return { type: actions.INVENTORY_STOP_LOADING };
}

export default actions;
