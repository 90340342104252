import { Collapse } from 'antd';
import React from 'react';

import { BillingContact } from 'app/components/commons/BillingContact/BillingContact';
import { BookingContact } from 'app/components/commons/BookingContact/BookingContact';
import { GuestContact } from 'app/components/commons/GuestContact/GuestContact';
import { SaleContact } from 'app/components/commons/SaleContact/SaleContact';
import FormSection from 'app/components/forms/FormSection';
import type { Hotel } from 'app/redux/models/Hotel/Hotel';

type Props = {
  values: Hotel;
  setFieldsValue: (values: Hotel) => void;
  originalBillingEmails: string[] | undefined;
};

const CommunicationForm = ({
  values,
  setFieldsValue,
  originalBillingEmails,
}: Props) => (
  <FormSection title="Communication">
    <Collapse
      bordered={false}
      defaultActiveKey={[
        'sale-contact',
        'notif-contact',
        'guest-contact',
        'billing-contact',
      ]}
    >
      <SaleContact mode="edit" key="sale-contact" />
      <BookingContact
        notifEmails={values.notifEmails}
        billingEmails={values.billingEmails}
        setNotifEmails={(notifEmails) => {
          setFieldsValue({ ...values, notifEmails });
        }}
        mode="edit"
        key="notif-contact"
      />
      <GuestContact mode="edit" key="guest-contact" />
      <BillingContact
        mode="edit"
        key="billing-contact"
        originalBillingEmails={originalBillingEmails}
        notifEmails={values.notifEmails}
      />
    </Collapse>
  </FormSection>
);

export default CommunicationForm;
