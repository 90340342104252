import {
  EllipsisOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input, Layout, Menu, PageHeader, Table } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';

import CoverCell from 'app/components/commons/CoverCell/CoverCell';
import {
  computeExperienceName,
  getCategoryEmoji,
} from 'app/components/commons/Experience/utils';
import { PriceCell, PublishedCell } from 'app/components/lists/cells';
import ModalCheckBox from 'app/components/pages/Extranet/ExtranetBookings/ModalCheckBox';
import {
  FetchExperiencePayload,
  tryFetchExperiences,
  tryPublishExperience,
} from 'app/redux/actions/experiences';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import type { ExperienceListElement } from 'app/redux/models/ExperienceListElement/ExperienceListElement';
import { selectExperienceCategories } from 'app/redux/selectors/conf';
import {
  extractBoolean,
  parseArrayParam,
  useQueryParamHistory,
} from 'app/utils/queryParamHistory';
import { scrollToTop } from 'app/utils/scroll';
import {
  SortOrder,
  convertFromAntdSortOrder,
  convertToAntdSortOrder,
  formatSortingForAPI,
  getSortOrder,
} from 'app/utils/sort';

import 'styles/layout/page-list.scss';

const { Content } = Layout;
const { Column } = Table;

const LIMIT = 50;

type ExperiencesListQueryParam = {
  search: string;
  pageNumber: number;
  dateSorting: SortOrder;
  isPublishedFilter: boolean[];
  staycationExpFilter: boolean[];
};

const formatSorting = (sorting?: SortOrder) => {
  if (sorting) {
    return `date-${formatSortingForAPI(sorting)}`;
  }

  return null;
};

const formatIsPublished = (isPublishedFilter?: boolean[]) => {
  if (isPublishedFilter?.length === 1) {
    return isPublishedFilter[0];
  }

  return undefined;
};

const formatIsStaycationExp = (staycationExpFilter?: boolean[]) => {
  if (!staycationExpFilter || staycationExpFilter.length > 1) {
    return undefined;
  }

  return staycationExpFilter[0];
};

const queryParamInit = {
  pageNumber: 1,
  dateSorting: 'descend',
  isPublishedFilter: [true, false],
  staycationExpFilter: [false, true],
} as ExperiencesListQueryParam;
const queryParamKeys = {
  pageNumber: 'p',
  dateSorting: 'ds',
  isPublishedFilter: 'pu',
  staycationExpFilter: 'sx',
  search: 's',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  dateSorting: getSortOrder,
  isPublishedFilter: parseArrayParam(extractBoolean),
  staycationExpFilter: parseArrayParam(extractBoolean),
};

const makePayload = (
  queryParam: Partial<ExperiencesListQueryParam>
): FetchExperiencePayload => ({
  search: queryParam.search,
  sorting: formatSorting(queryParam.dateSorting) || undefined,
  offset: ((queryParam.pageNumber || 1) - 1) * LIMIT,
  limit: LIMIT,
  isPublished: formatIsPublished(queryParam.isPublishedFilter),
  isStaycationExp: formatIsStaycationExp(queryParam.staycationExpFilter),
});

export const Experiences = ({ history }: Props) => {
  const dispatch = useAppDispatch();
  const emojis = useAppSelector((state) => state.pictures.emojis);
  const experienceCategories = useAppSelector(selectExperienceCategories);
  const experiences = useAppSelector((state) => state.experiences.experiences);
  const isLoading = useAppSelector((state) => state.experiences.loading);

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<ExperiencesListQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const debounceFetch = useMemo(
    () =>
      debounce(
        (params: FetchExperiencePayload) =>
          dispatch(tryFetchExperiences(params)),
        500
      ),
    [dispatch]
  );

  useEffect(() => {
    debounceFetch(makePayload(queryParam));
  }, [queryParam, debounceFetch]);

  const handleOffset = (x: number) => {
    updateQueryParam({ pageNumber: (queryParam?.pageNumber || 1) + x });

    scrollToTop();
  };

  const dateSortingHandler = (sort: SortOrder) => {
    updateQueryParam({
      dateSorting: sort,
      pageNumber: 1,
    });
  };

  const handleSearch = (search: string) => {
    updateQueryParam({
      search: search,
      pageNumber: 1,
    });
  };

  const handlePublishedFilter = (filters: boolean[]) => {
    updateQueryParam({
      isPublishedFilter: filters,
      pageNumber: 1,
    });
  };

  const handleStaycationExpFilter = (filters: boolean[]) => {
    updateQueryParam({
      staycationExpFilter: filters,
      pageNumber: 1,
    });
  };

  const formatDate = (date: any) =>
    date && moment.utc(date).local().format('DD/MM/YYYY à HH:mm');

  const pagination = (className: any) => (
    <div className={className}>
      <Button
        disabled={queryParam.pageNumber === 1}
        onClick={() => handleOffset(-1)}
      >
        <LeftOutlined />
      </Button>
      <Button>{queryParam.pageNumber}</Button>
      <Button
        disabled={experiences.length < 50}
        onClick={() => handleOffset(1)}
      >
        <RightOutlined />
      </Button>
    </div>
  );

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={queryParam.search}
          onChange={(event) => handleSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
      </div>
      {pagination('button-wrapper')}
    </div>
  );

  const handleIsStaycationExpFilter = (statuses: any) => {
    if (!statuses.length) {
      handleStaycationExpFilter([false, true]);
    } else {
      handleStaycationExpFilter(statuses);
    }
  };

  const handleIsPublishedFilter = (statuses: any) => {
    if (!statuses.length) {
      handlePublishedFilter([true, false]);
    } else {
      handlePublishedFilter(statuses);
    }
  };

  const goToExperienceEditPage = (id: number) => {
    history.push(`/experiences/${id}/edit`);
  };

  const handleRowActions = ({ id }: any) => ({
    onClick: () => goToExperienceEditPage(id),
  });

  const actionMenu = (experience: ExperienceListElement) => (
    <div className="actions-menu">
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              key="edit"
              onClick={() => goToExperienceEditPage(experience.id)}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              key="publish"
              onClick={() => dispatch(tryPublishExperience(experience.id))}
            >
              {experience.published ? 'Unpublish' : 'Publish'}
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );

  const newExperienceButton = (
    <Button
      type="primary"
      size="large"
      onClick={() => history.push('experiences/new')}
    >
      New
    </Button>
  );

  const tableOnChange = (__: any, ___: any, sorter: any) => {
    const { order } = sorter;
    const parsed = convertFromAntdSortOrder(order);

    dateSortingHandler(parsed);
  };

  const nameColumnRender = (__: any, exp: ExperienceListElement) => {
    const cover =
      exp.coverPicture ||
      getCategoryEmoji(exp.category.id, experienceCategories, emojis);

    return (
      <CoverCell
        cover={cover}
        // @ts-ignore
        name={computeExperienceName(exp)}
      />
    );
  };

  const experienceTypeColumnTitle = (
    <ModalCheckBox
      title="Type"
      defaultValue={[false, true]}
      onChange={handleIsStaycationExpFilter}
      items={[
        {
          value: false,
          label: 'Hotel',
        },
        {
          value: true,
          label: 'Staycation',
        },
      ]}
      value={queryParam.staycationExpFilter}
    />
  );

  const experienceTypeRender = (__: any, exp: ExperienceListElement) => {
    return exp.hotelId ? 'Hotel' : 'Staycation';
  };

  const publishedColumnTitle = (
    <ModalCheckBox
      title="Status"
      defaultValue={[true, false]}
      onChange={handleIsPublishedFilter}
      items={[
        {
          value: true,
          label: 'Published',
        },
        {
          value: false,
          label: 'Unpublished',
        },
      ]}
      value={queryParam.isPublishedFilter}
    />
  );

  return (
    <Layout className="page-list">
      <PageHeader
        className="header"
        title="Experiences"
        extra={newExperienceButton}
      />
      <Content className="body">
        <Table
          dataSource={experiences}
          pagination={false}
          title={header}
          loading={isLoading}
          rowKey="id"
          onRow={handleRowActions}
          onChange={tableOnChange}
        >
          <Column
            title="Name"
            dataIndex="displayName"
            render={nameColumnRender}
          />
          <Column title="Hotel" dataIndex="hotelName" />
          <Column
            title={experienceTypeColumnTitle}
            render={experienceTypeRender}
            className="sort-column"
          />
          <Column
            title="Value"
            dataIndex="price"
            render={(price, entity) => (
              <PriceCell price={price} entity={entity} defaultText="Missing" />
            )}
          />
          <Column
            title="Last modified"
            dataIndex="updatedAt"
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.dateSorting)}
            render={formatDate}
          />
          <Column
            title={publishedColumnTitle}
            dataIndex="published"
            render={PublishedCell}
            className="sort-column"
          />
          <Column
            render={actionMenu}
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            align="center"
          />
        </Table>
        {pagination('footer')}
      </Content>
    </Layout>
  );
};

type Props = {
  history: {
    push: (path: string) => void;
  };
};

export default Experiences;
