import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const selectGiftCards = (state: RootState) => state.giftCards;
export const getGiftCardsList = createSelector(
  selectGiftCards,
  (giftCards) => giftCards.list
);
export const areGiftCardsLoading = createSelector(
  selectGiftCards,
  (giftCards) => giftCards.isLoading
);
