export const FORTNIGHT_PERIOD = 14;
export const BIMESTER_PERIOD = 60;
export const QUATERLY_PERIOD = 90;
export const YEARLY_PERIOD = 365;

export type DataValue = {
  startDate: string;
  endDate: string;
  value: number;
};

export type ApiTimeSerieType = {
  data: DataValue[];
  aggregatedValue: number;
};
export type ApiValueType = { value: number };

export type ICharts = ApiTimeSerieType & {
  type: DataType;
};

export type ApiLastUpdatedAt = {
  lastUpdatedAt?: string;
};

export type DatesPeriod = {
  start: Date;
  end: Date;
};

export const UNIT_ENUM_VALUE = [
  'DAILY',
  'WEEKLY',
  'MONTHLY',
  'YEARLY',
] as const;
export type Unit = (typeof UNIT_ENUM_VALUE)[number];

export const PERIODS_ENUM_VALUE = [
  'custom',
  'last7Days',
  'last4Weeks',
  'last3Months',
  'last12Months',
] as const;
export type Period = (typeof PERIODS_ENUM_VALUE)[number];

export const DATA_TYPE_ENUM_VALUE = [
  'REVENUE_PAYMENT',
  'BOOKINGS_PAYMENT',
  'REVENUE_CHECKIN',
  'BOOKINGS_CHECKIN',
  'ADDON_REVENUE',
  'ADDON_RATIO',
  'UPGRADE_REVENUE',
  'UPGRADE_RATIO',
  'STOCK',
  'HVS_STOCK',
  'PAGE_VIEWS',
  'CVR_7D',
  'WISHLISTED',
] as const;
export type DataType = (typeof DATA_TYPE_ENUM_VALUE)[number];

export const PERIOD_TO_COMPARE_VALUE = ['previousPeriod', 'lastYear'] as const;
export type PeriodToCompare = (typeof PERIOD_TO_COMPARE_VALUE)[number];

export type FormattedApiResults = {
  cards: {
    type: DataType;
    data: {
      previous: number;
      current: number;
      datesPreviousPeriod: {
        start: Date;
        end: Date;
      };
      datesCurrentPeriod: {
        start: Date;
        end: Date;
      };
    };
  }[];
  graphs: {
    type: DataType;
    data: {
      category: string;
      value: number;
      date: string;
      item: DataValue;
    }[];
  }[];
};

export const SECTIONS_ANCHOR_VALUES = [
  'revenues',
  'inventory',
  'visibility',
] as const;
export type SectionsAnchor = (typeof SECTIONS_ANCHOR_VALUES)[number];

type DataSectionsMap = {
  [key in DataType]: { section: SectionsAnchor; set: number };
};

export const DATA_SECTIONS: DataSectionsMap = {
  REVENUE_PAYMENT: { section: 'revenues', set: 1 },
  BOOKINGS_PAYMENT: { section: 'revenues', set: 1 },
  REVENUE_CHECKIN: { section: 'revenues', set: 1 },
  BOOKINGS_CHECKIN: { section: 'revenues', set: 1 },
  ADDON_REVENUE: { section: 'revenues', set: 2 },
  ADDON_RATIO: { section: 'revenues', set: 2 },
  UPGRADE_REVENUE: { section: 'revenues', set: 2 },
  UPGRADE_RATIO: { section: 'revenues', set: 2 },
  STOCK: { section: 'inventory', set: 1 },
  HVS_STOCK: { section: 'inventory', set: 1 },
  PAGE_VIEWS: { section: 'visibility', set: 1 },
  CVR_7D: { section: 'visibility', set: 1 },
  WISHLISTED: { section: 'visibility', set: 1 },
} as const;
