import { Button, Form, Input, Modal } from 'antd';
import React, { FC } from 'react';

import './ReviewEditModal.scss';

const RULES = [{ required: true, message: 'Required' }];

export const ReviewEditModal: FC<Props> = ({
  visible,
  review,
  onCancel,
  onSubmit,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const { editedReview } = values;

    onSubmit(editedReview);
  };

  return (
    <Modal
      width={560}
      onOk={() => form.submit()}
      onCancel={onCancel}
      okText="Save"
      visible={visible}
      title="Edit feedback"
      centered
      className="review-modal"
      footer={[
        <Button type="primary" onClick={() => form.submit()} key="save">
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ editedReview: review }}
      >
        <div className="input-row">
          <Form.Item name="editedReview" label="Review" rules={RULES}>
            <Input.TextArea rows={4} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

type Props = {
  visible: boolean;
  review: string;
  onCancel: () => void;
  onSubmit: (review: string) => void;
};

export default ReviewEditModal;
