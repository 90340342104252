export function isDefined<T>(value: T): value is NonNullable<T> {
  return value !== undefined && value !== null;
}

export function assertIsDefined<T>(
  value: T,
  errorMessage?: string
): asserts value is NonNullable<T> {
  if (!isDefined(value)) {
    throw new Error(
      errorMessage ?? `Expected 'value' to be defined, but received ${value}`
    );
  }
}

// @ts-expect-error -- The point of this helper is detect the error
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertNever(_x: never): never {
  // This asserts never, so nothing to be done here
}

declare const emptyObjectSymbol: unique symbol;

export type EmptyObject = { [emptyObjectSymbol]?: never };

export const parseNumber = (
  num: string | number | null
): number | undefined => {
  if (num === null) {
    return undefined;
  }

  const parsed = parseFloat(num as string);

  return !isNaN(parsed) ? parseFloat(parsed.toFixed(2)) : undefined;
};

export const parseInputNumber = (value?: string) =>
  value ? value.replace(',', '.') : '';
