import formatDate from 'date-fns/format';
import React from 'react';
import { Link } from 'react-router-dom';

import Pic from 'app/components/commons/Pic';
import DefaultProfile from 'app/components/commons/icons/DefaultProfile';
import { useAppSelector } from 'app/redux/hooks';
import type { BookingDetails } from 'app/redux/models/BookingDetails/BookingDetails';
import { getCurrencyFromCountrySlug } from 'app/redux/selectors/countries';
import { formatCurrency } from 'app/utils/strings';

type Props = {
  bookingDetails: BookingDetails;
};

const User = ({ bookingDetails }: Props) => {
  const {
    customerAdvanced,
    staycations,
    giftCards,
    bookingSummary: { customer },
  } = bookingDetails;
  const {
    firstName,
    lastName,
    gender,
    id: customerId,
    countrySlug = '',
  } = customer;
  const currency = useAppSelector((state) =>
    getCurrencyFromCountrySlug(countrySlug)(state)
  );

  const getUserName = () => {
    if (!firstName || !lastName) {
      return `#${customerId}`;
    }

    return gender === undefined || gender === 'Other'
      ? `${firstName} ${lastName}`
      : `${gender} ${firstName} ${lastName}`;
  };

  const amount =
    staycations.reduce((a, b) => a + b, 0) +
    giftCards.reduce((a, b) => a + b, 0);

  const myStaycations = `${staycations.length} booking${
    staycations.length > 1 ? 's' : ''
  } • 
        ${giftCards.length} card${giftCards.length > 1 ? 's' : ''} •
        ${formatCurrency(amount, currency)}`;

  return (
    <div className="bloc">
      <div className="title">User</div>
      <div className="user-content">
        {customerAdvanced && customerAdvanced.avatarPath ? (
          <Pic
            className="avatar"
            style={{ borderRadius: '50%' }}
            pictureId={customerAdvanced.avatarPath}
          />
        ) : (
          <DefaultProfile />
        )}
        <div className="details">
          <div className="name">
            <Link
              className="link"
              to={`/users/${customerId}/edit`}
              target="_blank"
            >
              {getUserName()}
            </Link>
          </div>
          {customerAdvanced && customerAdvanced.birthDate && (
            <div className="birth-date">
              {formatDate(new Date(customerAdvanced.birthDate), 'dd/MM/yyy ')}
            </div>
          )}
          <div className="staycations">{myStaycations}</div>
        </div>
      </div>
    </div>
  );
};

export default User;
