import { CameraOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import React, { FC } from 'react';

import './UploadZone.scss';

const { Dragger } = Upload;

const UploadZone: FC<Props> = ({
  upload,
  highlightedRequirement,
  showHint,
}) => (
  <div className="upload-zone">
    <Dragger
      name="file"
      multiple
      accept="image/*"
      showUploadList={false}
      customRequest={upload}
    >
      <p className="ant-upload-drag-icon">
        <CameraOutlined className="icon" />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">
        Supports single or bulk upload and files in jpg, jpeg or png.
      </p>
      {showHint && (
        <p
          className={`ant-upload-hint ${
            highlightedRequirement ? 'upload-zone-error-text' : ''
          }`}
        >
          (20 photos minimum)
        </p>
      )}
    </Dragger>
  </div>
);

type Props = {
  upload: ({ file }: { file: any }) => void;
  highlightedRequirement: boolean;
  showHint?: boolean;
};

export default UploadZone;
