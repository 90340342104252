export enum DisclaimerKind {
  ADVICE = 'ADVICE',
  ALERT = 'ALERT',
}

export type Disclaimer = {
  readonly id: number | null;
  readonly kind: DisclaimerKind;
  readonly text: string;
  readonly startDate: string | null;
  readonly endDate: string | null;
  readonly packageId: number;
  readonly temporaryId: number;
  readonly priority: number;
};

export type DisclaimerAPI = Omit<Disclaimer, 'temporaryId'>;

export function createDisclaimer(values: any): Disclaimer {
  const { id, kind, text, startDate, endDate, packageId, priority } = values;

  return {
    id: id || null,
    kind,
    text,
    startDate,
    endDate,
    packageId,
    temporaryId: values.temporaryId || new Date().getTime(),
    priority,
  };
}

export function pruneDisclaimer(disclaimer: Disclaimer): DisclaimerAPI {
  return {
    id: disclaimer.id,
    kind: disclaimer.kind,
    text: disclaimer.text,
    startDate: disclaimer.startDate,
    endDate: disclaimer.endDate,
    packageId: disclaimer.packageId,
    priority: disclaimer.priority,
  };
}
