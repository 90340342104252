import React, { useEffect, useState } from 'react';

type UsersnapAPI = any;
declare global {
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  interface Window {
    onUsersnapCXLoad: (newApi: UsersnapAPI) => void;
  }
}

const USERSNAP_API_KEY = '637ad411-8de6-4740-9d13-30ae69037c70';

const UsersnapContext = React.createContext(null);

const UsersnapProvider = ({ children }: any) => {
  const [usersnapApi, setUsersnapApi] = useState<UsersnapAPI | null>(null);

  useEffect(() => {
    if (usersnapApi || !USERSNAP_API_KEY) {
      return;
    }

    window.onUsersnapCXLoad = (newApi) => {
      newApi.init({});
      setUsersnapApi(newApi);
    };

    const script = document.createElement('script');

    script.defer = true;
    script.src = `https://widget.usersnap.com/global/load/${USERSNAP_API_KEY}?onload=onUsersnapCXLoad`;
    document.head.appendChild(script);

    return () => {
      if (usersnapApi) {
        usersnapApi.destroy();
      }

      script.remove();
    };
  }, [usersnapApi]);

  return (
    <UsersnapContext.Provider value={usersnapApi}>
      {children}
    </UsersnapContext.Provider>
  );
};

export default UsersnapProvider;
