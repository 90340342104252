export type RoomFeature = {
  readonly id: number;
  readonly name: string;
  readonly category: string;
  readonly basic: boolean;
  readonly priority: number;
};

export function createRoomFeature(values: any): RoomFeature {
  const { id, name, category, basic, priority } = values;

  return {
    id,
    name,
    category,
    basic,
    priority,
  };
}
