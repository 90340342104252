import { RoomListElement } from 'app/redux/models/RoomListElement/RoomListElement';

export const ROOMS_START_LOADING = 'ROOMS_START_LOADING';
export const ROOMS_STOP_LOADING = 'ROOMS_STOP_LOADING';
export const ROOMS_TRY_FETCH_ROOM = 'ROOMS_TRY_FETCH_ROOM';
export const ROOMS_FETCH_ROOM_SUCCESS = 'ROOMS_FETCH_ROOM_SUCCESS';
export const ROOMS_TRY_FETCH_HOTEL_ROOMS = 'ROOMS_TRY_FETCH_HOTEL_ROOMS';
export const ROOMS_FETCH_HOTEL_ROOMS_SUCCESS =
  'ROOMS_FETCH_HOTEL_ROOMS_SUCCESS';
export const ROOMS_TRY_SAVE_ROOM = 'ROOMS_TRY_SAVE_ROOM';
export const ROOMS_TRY_SAVE_HOTEL_ROOM = 'ROOMS_TRY_SAVE_HOTEL_ROOM';
export const ROOMS_TRY_DELETE_ROOM = 'ROOMS_TRY_DELETE_ROOM';
export const ROOMS_TRY_DELETE_HOTEL_ROOM = 'ROOMS_TRY_DELETE_HOTEL_ROOM';
export const ROOMS_TRY_FETCH_ROOMS = 'ROOMS_TRY_FETCH_ROOMS';
export const ROOMS_FETCH_ROOMS_SUCCESS = 'ROOMS_FETCH_ROOMS_SUCCESS';
export const ROOMS_TRY_PUBLISH_ROOM = 'ROOMS_TRY_PUBLISH_ROOM';
export const ROOMS_PUBLISH_ROOM_SUCCESS = 'ROOMS_PUBLISH_ROOM_SUCCESS';

type RoomsStartLoading = {
  type: typeof ROOMS_START_LOADING;
};

type RoomsStopLoading = {
  type: typeof ROOMS_STOP_LOADING;
};

type RoomsTryFetchRoom = {
  type: typeof ROOMS_TRY_FETCH_ROOM;
  roomId: number;
};

type RoomsFetchRoomSuccess = {
  type: typeof ROOMS_FETCH_ROOM_SUCCESS;
  result: any;
};

type RoomsTryFetchHotelRooms = {
  type: typeof ROOMS_TRY_FETCH_HOTEL_ROOMS;
  hotelId: number;
};

type RoomsFetchHotelRoomsSuccess = {
  type: typeof ROOMS_FETCH_HOTEL_ROOMS_SUCCESS;
  result: any;
};

type RoomsTrySaveRoom = {
  type: typeof ROOMS_TRY_SAVE_ROOM;
  room: any;
};

type RoomsTrySaveHotelRoom = {
  type: typeof ROOMS_TRY_SAVE_HOTEL_ROOM;
  hotelId: number;
  room: any;
};

type RoomsTryDeleteRoom = {
  type: typeof ROOMS_TRY_DELETE_ROOM;
  roomId: number;
};

type RoomsTryDeleteHotelRoom = {
  type: typeof ROOMS_TRY_DELETE_HOTEL_ROOM;
  hotelId: number;
  roomId: number;
};

type RoomsTryFetchRooms = {
  type: typeof ROOMS_TRY_FETCH_ROOMS;
  search?: string;
  limit?: number;
  sortingColumn?: string;
  sortingOrder?: string;
  offset?: number;
  isPublished?: boolean;
};

type RoomsFetchRoomsSuccess = {
  type: typeof ROOMS_FETCH_ROOMS_SUCCESS;
  result: Array<RoomListElement>;
};

type RoomsTryPublishRoom = {
  type: typeof ROOMS_TRY_PUBLISH_ROOM;
  roomId: number;
};

type RoomsPublishRoomSuccess = {
  type: typeof ROOMS_PUBLISH_ROOM_SUCCESS;
  roomId: number;
  result: any;
};

export type RoomActions =
  | RoomsStartLoading
  | RoomsStopLoading
  | RoomsTryFetchRoom
  | RoomsFetchRoomSuccess
  | RoomsTryFetchHotelRooms
  | RoomsFetchHotelRoomsSuccess
  | RoomsTrySaveRoom
  | RoomsTrySaveHotelRoom
  | RoomsTryDeleteHotelRoom
  | RoomsTryFetchRooms
  | RoomsFetchRoomsSuccess
  | RoomsTryPublishRoom
  | RoomsPublishRoomSuccess;

export function startLoading(): RoomsStartLoading {
  return { type: ROOMS_START_LOADING };
}

export function stopLoading(): RoomsStopLoading {
  return { type: ROOMS_STOP_LOADING };
}

export function tryFetchRoom(roomId: number): RoomsTryFetchRoom {
  return { type: ROOMS_TRY_FETCH_ROOM, roomId };
}

export function fetchRoomSuccess(result: any): RoomsFetchRoomSuccess {
  return { type: ROOMS_FETCH_ROOM_SUCCESS, result };
}

export function tryFetchHotelRooms(hotelId: number): RoomsTryFetchHotelRooms {
  return { type: ROOMS_TRY_FETCH_HOTEL_ROOMS, hotelId };
}

export function fetchHotelRoomsSuccess(
  result: any
): RoomsFetchHotelRoomsSuccess {
  return { type: ROOMS_FETCH_HOTEL_ROOMS_SUCCESS, result };
}

export function trySaveRoom(room: any): RoomsTrySaveRoom {
  return { type: ROOMS_TRY_SAVE_ROOM, room };
}

export function trySaveHotelRoom(
  hotelId: number,
  room: any
): RoomsTrySaveHotelRoom {
  return { type: ROOMS_TRY_SAVE_HOTEL_ROOM, hotelId, room };
}

export function tryDeleteRoom(roomId: number): RoomsTryDeleteRoom {
  return { type: ROOMS_TRY_DELETE_ROOM, roomId };
}

export function tryDeleteHotelRoom(
  hotelId: number,
  roomId: number
): RoomsTryDeleteHotelRoom {
  return { type: ROOMS_TRY_DELETE_HOTEL_ROOM, hotelId, roomId };
}

export function tryFetchRooms({
  search,
  limit,
  sortingColumn,
  sortingOrder,
  offset,
  isPublished,
}: {
  search?: string;
  limit?: number;
  sortingColumn?: string;
  sortingOrder?: string;
  offset?: number;
  isPublished?: boolean;
}): RoomsTryFetchRooms {
  return {
    type: ROOMS_TRY_FETCH_ROOMS,
    search,
    limit,
    sortingColumn,
    sortingOrder,
    offset,
    isPublished,
  };
}

export function fetchRoomsSuccess(result: any): RoomsFetchRoomsSuccess {
  return { type: ROOMS_FETCH_ROOMS_SUCCESS, result };
}

export function tryPublishRoom(roomId: number): RoomsTryPublishRoom {
  return { type: ROOMS_TRY_PUBLISH_ROOM, roomId };
}

export function publishRoomSuccess(
  roomId: number,
  result: any
): RoomsPublishRoomSuccess {
  return { type: ROOMS_PUBLISH_ROOM_SUCCESS, roomId, result };
}
