import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useMemo } from 'react';
import type { PropsWithChildren } from 'react';

type CurrencyFormatterOptions = {
  minimumFractionDigits: number;
  maximumFractionDigits: number;
};

const DEFAULT_CURRENCY_FORMATTER_OPTIONS: CurrencyFormatterOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

type Props = {
  currencyFormatter: (
    price: number,
    options?: CurrencyFormatterOptions
  ) => string;
  currencySymbol: string;
};

export const CurrencyContext = React.createContext<Props>({
  currencyFormatter: () => '',
  currencySymbol: '',
});

type ProviderProps = {
  currency: string;
};

export const CurrencyProvider = ({
  children,
  currency,
}: PropsWithChildren<ProviderProps>) => {
  const currencySymbol = useMemo(
    () => getSymbolFromCurrency(currency) || '',
    [currency]
  );

  const currencyFormatter = useMemo(
    () =>
      (price: number, options = DEFAULT_CURRENCY_FORMATTER_OPTIONS) => {
        const formatter = new Intl.NumberFormat('en', {
          currency,
          style: 'currency',
          notation: 'standard',
          ...DEFAULT_CURRENCY_FORMATTER_OPTIONS,
          ...options,
        });

        return formatter.format(price);
      },
    [currency]
  );

  return (
    <CurrencyContext.Provider
      value={{
        currencyFormatter,
        currencySymbol,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
