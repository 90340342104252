import { actions } from 'app/redux/actions/openings';

function openings(state = {}, action) {
  switch (action.type) {
    case actions.INVENTORY_START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actions.INVENTORY_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actions.FETCH_INVENTORY_SUCCESS:
      return {
        ...state,
        inventory: action.inventory,
      };
    default:
      return state;
  }
}

export default openings;
