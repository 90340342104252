export const EXPERIENCES_START_LOADING = 'EXPERIENCES_START_LOADING';
export const EXPERIENCES_STOP_LOADING = 'EXPERIENCES_STOP_LOADING';
export const EXPERIENCES_TRY_FETCH_HOTEL_EXPERIENCES =
  'EXPERIENCES_TRY_FETCH_HOTEL_EXPERIENCES';
export const EXPERIENCES_FETCH_HOTEL_EXPERIENCES_SUCCESS =
  'EXPERIENCES_FETCH_HOTEL_EXPERIENCES_SUCCESS';
export const EXPERIENCES_TRY_FETCH_EXPERIENCES_BRANDS =
  'EXPERIENCES_TRY_FETCH_EXPERIENCES_BRANDS';
export const EXPERIENCES_FETCH_EXPERIENCES_BRANDS_SUCCESS =
  'EXPERIENCES_FETCH_EXPERIENCES_BRANDS_SUCCESS';
export const EXPERIENCES_TRY_FETCH_EXPERIENCE =
  'EXPERIENCES_TRY_FETCH_EXPERIENCE';
export const EXPERIENCES_TRY_FETCH_HOTEL_EXPERIENCE =
  'EXPERIENCES_TRY_FETCH_HOTEL_EXPERIENCE';
export const EXPERIENCES_FETCH_HOTEL_EXPERIENCE_SUCCESS =
  'EXPERIENCES_FETCH_HOTEL_EXPERIENCE_SUCCESS';
export const EXPERIENCES_TRY_SAVE_HOTEL_EXPERIENCE =
  'EXPERIENCES_TRY_SAVE_HOTEL_EXPERIENCE';
export const EXPERIENCES_FETCH_EXPERIENCE_SUCCESS =
  'EXPERIENCES_FETCH_EXPERIENCE_SUCCESS';
export const EXPERIENCES_TRY_SAVE_EXPERIENCE =
  'EXPERIENCES_TRY_SAVE_EXPERIENCE';
export const EXPERIENCES_TRY_DELETE_EXPERIENCE =
  'EXPERIENCES_TRY_DELETE_EXPERIENCE';
export const EXPERIENCES_TRY_FETCH_EXPERIENCES =
  'EXPERIENCES_TRY_FETCH_EXPERIENCES';
export const EXPERIENCES_FETCH_EXPERIENCES_SUCCESS =
  'EXPERIENCES_FETCH_EXPERIENCES_SUCCESS';
export const EXPERIENCES_TRY_PUBLISH_EXPERIENCE =
  'EXPERIENCES_TRY_PUBLISH_EXPERIENCE';
export const EXPERIENCES_PUBLISH_EXPERIENCE_SUCCESS =
  'EXPERIENCES_PUBLISH_EXPERIENCE_SUCCESS';
export const EXPERIENCES_RESET_EXPERIENCE = 'EXPERIENCES_RESET_EXPERIENCE';

type ExperiencesStartLoading = {
  type: typeof EXPERIENCES_START_LOADING;
};

type ExperiencesStopLoading = {
  type: typeof EXPERIENCES_STOP_LOADING;
};

type ExperiencesTryFetchHotelExperiences = {
  type: typeof EXPERIENCES_TRY_FETCH_HOTEL_EXPERIENCES;
  hotelId: number;
  includeStaycationExp: boolean;
};

type ExperiencesFetchHotelExperiencesSuccess = {
  type: typeof EXPERIENCES_FETCH_HOTEL_EXPERIENCES_SUCCESS;
  result: any;
};

type ExperiencesTryFetchExperiencesBrands = {
  type: typeof EXPERIENCES_TRY_FETCH_EXPERIENCES_BRANDS;
  search: string;
  limit: number | undefined;
};

type ExperiencesFetchExperiencesBrandsSuccess = {
  type: typeof EXPERIENCES_FETCH_EXPERIENCES_BRANDS_SUCCESS;
  result: any;
};

type ExperiencesTryFetchExperience = {
  type: typeof EXPERIENCES_TRY_FETCH_EXPERIENCE;
  experienceId: number;
};

type ExperiencesFetchExperienceSuccess = {
  type: typeof EXPERIENCES_FETCH_EXPERIENCE_SUCCESS;
  result: any;
};

type ExperiencesTrySaveExperience = {
  type: typeof EXPERIENCES_TRY_SAVE_EXPERIENCE;
  experience: any;
};

type ExperiencesTryFetchHotelExperience = {
  type: typeof EXPERIENCES_TRY_FETCH_HOTEL_EXPERIENCE;
  experienceId: number;
};

type ExperiencesFetchHotelExperienceSuccess = {
  type: typeof EXPERIENCES_FETCH_HOTEL_EXPERIENCE_SUCCESS;
  result: any;
};

type ExperiencesTrySaveHotelExperience = {
  type: typeof EXPERIENCES_TRY_SAVE_HOTEL_EXPERIENCE;
  experience: any;
};

type ExperiencesTryDeleteExperience = {
  type: typeof EXPERIENCES_TRY_DELETE_EXPERIENCE;
  experienceId: number;
};

type ExperiencesFetchExperiencesSuccess = {
  type: typeof EXPERIENCES_FETCH_EXPERIENCES_SUCCESS;
  result: any;
};

type ExperiencesTryPublishExperience = {
  type: typeof EXPERIENCES_TRY_PUBLISH_EXPERIENCE;
  experienceId: number;
};

type ExperiencesPublishExperienceSuccess = {
  type: typeof EXPERIENCES_PUBLISH_EXPERIENCE_SUCCESS;
  experienceId: number;
  result: any;
};

export type FetchExperiencePayload = {
  search?: string;
  limit?: number;
  sorting?: any;
  offset?: number;
  isPublished?: boolean;
  isStaycationExp?: boolean;
};

type ExperiencesTryFetchExperiences = {
  type: typeof EXPERIENCES_TRY_FETCH_EXPERIENCES;
} & FetchExperiencePayload;

type ExperienceResetExperience = {
  type: typeof EXPERIENCES_RESET_EXPERIENCE;
};

export type ExperiencesActions =
  | ExperiencesStartLoading
  | ExperiencesStopLoading
  | ExperiencesTryFetchHotelExperiences
  | ExperiencesFetchHotelExperiencesSuccess
  | ExperiencesTryFetchExperiencesBrands
  | ExperiencesFetchExperiencesBrandsSuccess
  | ExperiencesTryFetchExperience
  | ExperiencesFetchExperienceSuccess
  | ExperiencesTrySaveExperience
  | ExperiencesTryDeleteExperience
  | ExperiencesFetchExperiencesSuccess
  | ExperiencesTryPublishExperience
  | ExperiencesPublishExperienceSuccess
  | ExperiencesTryFetchExperiences
  | ExperienceResetExperience;

export function startLoading(): ExperiencesStartLoading {
  return { type: EXPERIENCES_START_LOADING };
}

export function stopLoading(): ExperiencesStopLoading {
  return { type: EXPERIENCES_STOP_LOADING };
}

export function tryFetchHotelExperiences(
  hotelId: number,
  includeStaycationExp: boolean
): ExperiencesTryFetchHotelExperiences {
  return {
    type: EXPERIENCES_TRY_FETCH_HOTEL_EXPERIENCES,
    hotelId,
    includeStaycationExp,
  };
}

export function fetchHotelExperiencesSuccess(
  result: any
): ExperiencesFetchHotelExperiencesSuccess {
  return { type: EXPERIENCES_FETCH_HOTEL_EXPERIENCES_SUCCESS, result };
}

export function tryFetchExperiencesBrands(
  search: string,
  limit?: number
): ExperiencesTryFetchExperiencesBrands {
  return { type: EXPERIENCES_TRY_FETCH_EXPERIENCES_BRANDS, search, limit };
}

export function fetchExperiencesBrandsSuccess(
  result: any
): ExperiencesFetchExperiencesBrandsSuccess {
  return { type: EXPERIENCES_FETCH_EXPERIENCES_BRANDS_SUCCESS, result };
}

export function tryFetchExperience(
  experienceId: number
): ExperiencesTryFetchExperience {
  return { type: EXPERIENCES_TRY_FETCH_EXPERIENCE, experienceId };
}

export function fetchExperienceSuccess(
  result: any
): ExperiencesFetchExperienceSuccess {
  return { type: EXPERIENCES_FETCH_EXPERIENCE_SUCCESS, result };
}

export function trySaveExperience(
  experience: any
): ExperiencesTrySaveExperience {
  return { type: EXPERIENCES_TRY_SAVE_EXPERIENCE, experience };
}

export function tryFetchHotelExperience(
  experienceId: number
): ExperiencesTryFetchHotelExperience {
  return { type: EXPERIENCES_TRY_FETCH_HOTEL_EXPERIENCE, experienceId };
}

export function fetchHotelExperienceSuccess(
  result: any
): ExperiencesFetchHotelExperienceSuccess {
  return { type: EXPERIENCES_FETCH_HOTEL_EXPERIENCE_SUCCESS, result };
}

export function trySaveHotelExperience(
  experience: any
): ExperiencesTrySaveHotelExperience {
  return { type: EXPERIENCES_TRY_SAVE_HOTEL_EXPERIENCE, experience };
}

export function tryDeleteExperience(
  experienceId: number
): ExperiencesTryDeleteExperience {
  return { type: EXPERIENCES_TRY_DELETE_EXPERIENCE, experienceId };
}

export function tryFetchExperiences({
  search,
  limit,
  sorting,
  offset,
  isPublished,
  isStaycationExp,
}: FetchExperiencePayload): ExperiencesTryFetchExperiences {
  return {
    type: EXPERIENCES_TRY_FETCH_EXPERIENCES,
    search,
    limit,
    sorting,
    offset,
    isPublished,
    isStaycationExp,
  };
}

export function fetchExperiencesSuccess(
  result: any
): ExperiencesFetchExperiencesSuccess {
  return { type: EXPERIENCES_FETCH_EXPERIENCES_SUCCESS, result };
}

export function tryPublishExperience(
  experienceId: number
): ExperiencesTryPublishExperience {
  return { type: EXPERIENCES_TRY_PUBLISH_EXPERIENCE, experienceId };
}

export function publishExperienceSuccess(
  experienceId: number,
  result: any
): ExperiencesPublishExperienceSuccess {
  return { type: EXPERIENCES_PUBLISH_EXPERIENCE_SUCCESS, experienceId, result };
}

export function resetExperience(): ExperienceResetExperience {
  return { type: EXPERIENCES_RESET_EXPERIENCE };
}
