import { createAction } from '@reduxjs/toolkit';

import { Tag } from 'app/redux/models/Tag/Tag';

type TagsFetchTagsSuccessPayload = {
  result: Tag[];
};

export const TAGS_START_LOADING = 'TAGS_START_LOADING';
export const TAGS_STOP_LOADING = 'TAGS_STOP_LOADING';

export const TAGS_TRY_FETCH_TAGS = 'TAGS_TRY_FETCH_TAGS';
export const TAGS_FETCH_TAGS_SUCCESS = 'TAGS_FETCH_TAGS_SUCCESS';

export const startLoading = createAction(TAGS_START_LOADING);
export const stopLoading = createAction(TAGS_STOP_LOADING);

export const tryFetchTags = createAction(TAGS_TRY_FETCH_TAGS);
export const fetchTagsSuccess = createAction<TagsFetchTagsSuccessPayload>(
  TAGS_FETCH_TAGS_SUCCESS
);
