import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const getUsers = (state: RootState) => state.users;
export const getUsersList = createSelector(getUsers, ({ list }) => list);
export const areUsersListLoading = createSelector(
  getUsers,
  ({ listLoading }) => listLoading
);
