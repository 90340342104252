import { Button, Form, Layout } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';

import Spinner from 'app/components/commons/Spinner';

const { Content, Header } = Layout;

// TODO: Trigger Prompt when a few fields are filled
// TODO: Try to have custom UI for this prompt (https://bit.ly/2TH0TPh)
class FormPage extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    submitFailed: PropTypes.bool,
    handleSubmit: PropTypes.func,
    children: PropTypes.node,
  };

  UNSAFE_componentWillUpdate(nextProps) {
    const { pristine, submitting, submitSucceeded } = nextProps;

    if (
      process.env.NODE_ENV === 'production' &&
      !submitting &&
      !submitSucceeded &&
      !pristine
    ) {
      // eslint-disable-next-line no-restricted-globals
      window.onbeforeunload = () => confirm('Do you want to leave this page?');
    }
  }

  render() {
    const {
      title,
      handleSubmit,
      pristine,
      submitting,
      submitSucceeded,
      submitFailed,
      children,
    } = this.props;

    return (
      <Layout className="page__container">
        <Header className="page__form-header">
          <h1>{title}</h1>
          {handleSubmit && (
            <div className="page__form-control">
              {submitFailed && (
                <div className="page__form-message">Something went wrong.</div>
              )}
              <Button
                type="primary"
                size="large"
                disabled={submitting}
                onClick={handleSubmit}
              >
                {submitting ? 'Saving...' : 'Save'}
              </Button>
            </div>
          )}
        </Header>
        <Content className="page__body">
          <Form onSubmit={handleSubmit}>
            <Prompt
              when={
                process.env.NODE_ENV === 'production' &&
                !submitting &&
                !submitSucceeded &&
                !pristine
              }
              message="Do you really want to leave this form half-filled? ;)"
            />
            <Spinner spinning={submitting}>
              <Layout>
                <Content className="page__content">{children}</Content>
              </Layout>
            </Spinner>
          </Form>
        </Content>
      </Layout>
    );
  }
}

export default FormPage;
