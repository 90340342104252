import { Col, Collapse, CollapsePanelProps, Form, Input, Row } from 'antd';
import { Rule } from 'antd/lib/form';
import React from 'react';

import PanelHeader from 'app/components/layout/PanelHeader/PanelHeader';

const { Panel } = Collapse;

const RULES = [{ required: true, message: 'Required' }];
const EMAIL_RULES: Rule[] = [
  {
    message: 'Must be a valid email',
    type: 'email',
  },
  { required: true, message: 'Required' },
];

type Props = Partial<CollapsePanelProps> & {
  mode: 'view' | 'edit';
};

export const SaleContact = ({ mode, ...panelProps }: Props) => {
  const isDisabled = mode === 'view';

  return (
    <Panel
      key="sale-contact"
      className="panel"
      header={
        <PanelHeader
          name="Principal contact for Staycation"
          subTitle="Person in charge of the partnership (1 person) - will only be contacted for specific strategic issues "
        />
      }
      {...panelProps}
    >
      <Row>
        <Col span={12}>
          <Form.Item
            label="First name"
            name={['saleContact', 'firstName']}
            rules={RULES}
          >
            <Input disabled={isDisabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Last name"
            name={['saleContact', 'lastName']}
            rules={RULES}
          >
            <Input disabled={isDisabled} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Position" name={['saleContact', 'job']} rules={RULES}>
        <Input disabled={isDisabled} />
      </Form.Item>
      <Form.Item
        label="Email"
        name={['saleContact', 'email']}
        rules={EMAIL_RULES}
      >
        <Input disabled={isDisabled} />
      </Form.Item>
      <Form.Item
        label="Direct phone"
        name={['saleContact', 'phone']}
        rules={RULES}
      >
        <Input disabled={isDisabled} />
      </Form.Item>
    </Panel>
  );
};
