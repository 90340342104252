import type { Coordinates } from 'app/redux/models/Coordinates/Coordinates';
import { createCoordinates } from 'app/redux/models/Coordinates/Coordinates';

export type Country = {
  readonly id: number;
  readonly slug: string;
  readonly name: string;
  readonly lang: string;
  readonly locale: string;
  readonly currency: string;
  readonly phonePrefix: string;
  readonly coords: Coordinates;
  readonly facebookUrl: string;
  readonly instagramUrl: string;
  readonly timezone: string;
  readonly authorizedCountries: number[];
  readonly searchable: boolean;
  readonly playStoreUrl: string;
  readonly appleStoreUrl: string;
  readonly giftCardAllowed: boolean;
  readonly physicalGiftCardAllowed: boolean;
  readonly stripeActivated: boolean;
};

export function createCountry(values: any): Country {
  const {
    id,
    slug,
    name,
    lang,
    locale,
    currency,
    phonePrefix,
    coords,
    facebookUrl,
    instagramUrl,
    timezone,
    authorizedCountries,
    searchable,
    playStoreUrl,
    appleStoreUrl,
    giftCardAllowed,
    physicalGiftCardAllowed,
    stripeActivated,
  } = values;

  return {
    id,
    slug,
    name,
    lang,
    locale,
    currency,
    phonePrefix,
    coords: createCoordinates(coords),
    facebookUrl,
    instagramUrl,
    timezone,
    authorizedCountries,
    searchable,
    playStoreUrl,
    appleStoreUrl,
    giftCardAllowed,
    physicalGiftCardAllowed,
    stripeActivated,
  };
}
