import {
  EllipsisOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import formatDate from 'date-fns/format';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  DragHandle,
  DraggableTable,
} from 'app/components/commons/DraggableTable/DraggableTable';
import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';
import { PublishedCell } from 'app/components/lists/cells';
import { QuickFilterListItem } from 'app/components/pages/QuickFilters/types';
import {
  sortQuickFilters,
  useGetQuickFilters,
} from 'app/hooks/data/quickFilter/useGetQuickFilters';
import { useUpdateOrderQuickFilters } from 'app/hooks/data/quickFilter/useUpdateOrderQuickFilters';
import { useUpdatePublishQuickFilters } from 'app/hooks/data/quickFilter/useUpdatePublishQuickFilters';

import './QuickFiltersList.scss';

type QuickFiltersListProps = {
  dayPackage: boolean;
};

export const QuickFiltersList = ({ dayPackage }: QuickFiltersListProps) => {
  const [searchedText, setSearchedText] = useState('');
  const { quickFilters, loading } = useGetQuickFilters({
    dayPackage,
  });
  const history = useHistory();
  const { mutateAsync: publishedMutation } = useUpdatePublishQuickFilters();
  const { mutateAsync: orderMutation, isLoading: isLoadingOrder } =
    useUpdateOrderQuickFilters();
  const [dataSource, setDataSource] = useState(quickFilters);

  useEffect(() => {
    setDataSource(quickFilters);
  }, [quickFilters]);

  const onClickPublish = useCallback(
    async (quickFilter: QuickFilterListItem) => {
      const newList = await publishedMutation({
        id: quickFilter.id.toString(),
        published: !quickFilter.published,
      });

      setDataSource(newList);
    },
    [publishedMutation]
  );

  const createRedirectionUrl = () => {
    const queryParams = new URLSearchParams({
      type: dayPackage ? 'moment' : 'staycation',
    });

    return `quick-filters/new?${queryParams.toString()}`;
  };

  const columns: ColumnsType<QuickFilterListItem> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'label',
        className: 'drag-visible',
        width: '30%',
        render: (label: string, quickFilter: QuickFilterListItem) => (
          <div className="cover-cell-emoji">
            <div className="cover-cell__thumbnail">
              <PictureComponent pictureId={quickFilter.picture.pictureId} />
            </div>
            <div className="cover-cell">{label}</div>
          </div>
        ),
        filteredValue: [searchedText],
        onFilter: (
          label: string | number | boolean,
          quickFilter: QuickFilterListItem
        ) => {
          return quickFilter.label
            .toLowerCase()
            .includes(label.toString().toLowerCase());
        },
      },
      {
        title: 'Last Modified',
        dataIndex: 'updatedAt',
        render: (updatedAt: Date) =>
          formatDate(new Date(updatedAt), 'yyyy-MM-dd HH:mm:ss'),
      },
      {
        title: 'Status',
        dataIndex: 'published',
        render: PublishedCell,
      },
      {
        title: 'Order',
        dataIndex: 'displayOrder',
        render: (displayOrder: number | undefined) => displayOrder ?? 'None',
      },
      {
        width: 100,
        className: 'drag-visible',
        onCell: () => ({ onClick: (e) => e.stopPropagation() }),
        render: (quickFilter: QuickFilterListItem) => (
          <div className="sortOption">
            {quickFilter.published && <DragHandle />}
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'edit',
                    label: (
                      <Link to={`quick-filters/${quickFilter.id}/edit`}>
                        Edit
                      </Link>
                    ),
                  },
                  {
                    key: 'published',
                    label: (
                      <div onClick={() => onClickPublish(quickFilter)}>
                        {quickFilter.published ? 'Unpublish' : 'Publish'}
                      </div>
                    ),
                  },
                ],
              }}
              trigger={['click']}
            >
              <EllipsisOutlined rotate={90} />
            </Dropdown>
          </div>
        ),
      },
    ],
    [searchedText, onClickPublish]
  );

  const onSortEnd = async ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const publishedQuickFilter = dataSource.filter(
      (quickFilter) => quickFilter.published
    );

    if (oldIndex !== newIndex) {
      const movingElement = publishedQuickFilter[oldIndex];

      publishedQuickFilter.splice(oldIndex, 1);
      publishedQuickFilter.splice(newIndex, 0, movingElement);

      const newList = await orderMutation({
        dayPackage: dayPackage,
        ids: publishedQuickFilter.map((item) => item.id),
      });

      setDataSource(sortQuickFilters(newList));
    }
  };

  const header = () => (
    <div className="page__actions">
      <Input
        placeholder="Search here..."
        suffix={<SearchOutlined className="certain-category-icon" />}
        onChange={(e) => {
          setSearchedText(e.target.value);
        }}
      />
      <div className="page__actions-buttons">
        <Link to={createRedirectionUrl()}>
          <Button icon={<PlusOutlined />} type="primary">
            New
          </Button>
        </Link>
      </div>
    </div>
  );

  const onRowClick = (quickFilter: QuickFilterListItem) => ({
    onClick: () => history.push(`quick-filters/${quickFilter.id}/edit`),
  });

  return (
    <DraggableTable<QuickFilterListItem>
      className="quickFilterTable"
      onSortEnd={onSortEnd}
      title={header}
      pagination={false}
      dataSource={dataSource}
      loading={loading || isLoadingOrder}
      columns={columns}
      onRow={onRowClick}
    />
  );
};
