import {
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Button, Collapse, Divider, Form, FormInstance } from 'antd';
import { ValidatorRule } from 'rc-field-form/lib/interface';

import { emptySection } from 'app/components/pages/Edito/Event/Detail/EditoEventDetail';
import type { IEditoEventForm } from 'app/typings/edito';

import { EditoContainer } from '../../EditoContainer/EditoContainer';
import { EditoSectionsForm } from '../EditoSectionsForm/EditoSectionsForm';

import './EditoSectionsList.scss';

const { Panel } = Collapse;

const MINIMUM_NB_SECTION = 1;
const MAXIMUM_NB_SECTION = 6;

interface Props {
  sectionsIndexToCollapse: string[];
  setSectionsIndexToCollapse: (indexes: string[]) => void;
  form: FormInstance<IEditoEventForm>;
}

const sectionsValidatorRule: ValidatorRule[] = [
  {
    validator: async (_, names) => {
      if (!names || names.length < MINIMUM_NB_SECTION) {
        return Promise.reject(
          new Error(`At least ${MINIMUM_NB_SECTION} section to publish`)
        );
      }

      if (!names || names.length > MAXIMUM_NB_SECTION) {
        return Promise.reject(
          new Error(`At most ${MAXIMUM_NB_SECTION} sections to publish`)
        );
      }
    },
  },
];

export const EditoSectionsList = ({
  sectionsIndexToCollapse,
  setSectionsIndexToCollapse,
  form,
}: Props) => {
  return (
    <EditoContainer
      title="Sections"
      subTitle="Define the different sections users will see and interact with"
    >
      <div className="editoSectionsList">
        <Form.List name="sections" rules={sectionsValidatorRule}>
          {(fields, { add, remove }, { errors }) => (
            <div>
              <div className="collapses">
                {fields.map((field, index) => (
                  <Collapse
                    defaultActiveKey={0}
                    ghost
                    key={`${field} - ${index}`}
                    activeKey={sectionsIndexToCollapse}
                    onChange={(key) =>
                      setSectionsIndexToCollapse(
                        Array.isArray(key) ? key : [key]
                      )
                    }
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <UpOutlined className="expandIcon" />
                      ) : (
                        <DownOutlined className="expandIcon" />
                      )
                    }
                  >
                    <Panel
                      header={
                        <span className="title">{`Section ${index + 1}`}</span>
                      }
                      forceRender={true}
                      key={index}
                      extra={
                        <DeleteOutlined
                          className="deleteIcon"
                          onClick={() => remove(field.name)}
                        />
                      }
                    >
                      <EditoSectionsForm field={field} form={form} />
                    </Panel>
                  </Collapse>
                ))}
              </div>

              <Form.ErrorList errors={errors} />

              <Divider />
              {fields.length < MAXIMUM_NB_SECTION && (
                <Button
                  className="addSectionButton"
                  type="primary"
                  ghost
                  icon={<PlusOutlined className="icon" />}
                  onClick={() => {
                    setSectionsIndexToCollapse([
                      ...sectionsIndexToCollapse,
                      `${fields.length}`,
                    ]);
                    add(emptySection);
                  }}
                >
                  Add section
                </Button>
              )}
            </div>
          )}
        </Form.List>
      </div>
    </EditoContainer>
  );
};
