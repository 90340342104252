/* eslint-disable import/prefer-default-export */
import type { RootState } from 'app/redux/store';

export const selectHotelAdmin = (state: RootState) => state.hotelAdmin;

export const selectHotelAdminInventory = (state: RootState) =>
  selectHotelAdmin(state).inventory;

export const selectHotelAdminHotelId = (state: RootState) =>
  selectHotelAdmin(state).hotelId;

export const selectHotelAdminIsLoading = (state: RootState) =>
  selectHotelAdmin(state).isLoading;

export const selectHotelAdminHotels = (state: RootState) =>
  selectHotelAdmin(state).hotels;

export const selectHotelAdminDirtOpenings = (state: RootState) =>
  selectHotelAdmin(state).dirtyOpenings;

export const selectHotelAdminIsEditing = (state: RootState) =>
  selectHotelAdmin(state).isEditing;

export const selectHotelAdminBillsPage = (state: RootState) =>
  selectHotelAdmin(state).billsPage;

export const selectHotelAdminBills = (state: RootState) =>
  selectHotelAdminBillsPage(state).bills;
export const selectHotelAdminBillsIsLoading = (state: RootState) =>
  selectHotelAdminBillsPage(state).isLoading;
export const selectHotelAdminBillsPdfObject = (state: RootState) =>
  selectHotelAdminBillsPage(state).billPdfObject;
export const selectHotelAdminBillsIsDownloading = (state: RootState) =>
  selectHotelAdminBillsPage(state).isDownloading;

export const selectHotelAdminContacts = (state: RootState) =>
  selectHotelAdmin(state).contacts;
export const selectHotelAdminContactsData = (state: RootState) =>
  selectHotelAdminContacts(state).data;

export const selectHotelAdminIsValidationFailed = (state: RootState) =>
  selectHotelAdmin(state).isValidationFailed;
export const selectHotelAdminIsViewValidationFailed = (state: RootState) =>
  selectHotelAdmin(state).isViewValidationFailed;
