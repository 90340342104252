import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons';
import { Empty, Form, Input } from 'antd';
import React, { FC, useMemo } from 'react';

import './HotelLinks.scss';

const RULES = [
  { required: true, message: 'Required' },
  // { type: 'url', message: 'Need url format' },
];

export const BOOKING_COM_URL_REGEXP = /^https:\/\/www\.booking\.com\/.*\.html$/;

const KEYS = [
  'websiteUrl',
  'bookingUrl',
  'bookingComUrl',
  'instagramUrl',
  'instagramLocationUrl',
];

export const HotelLinks: FC<Props> = ({
  mode = 'edit',
  values,
  prefix = '',
}) => {
  const notEmpty = useMemo(
    () => !values || KEYS.some((key) => values[key]),
    [values]
  );

  if (mode === 'view' && !notEmpty) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <div className="hotel-links">
      {(mode === 'edit' || values.websiteUrl) && (
        <div
          className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}
        >
          <Form.Item
            name={prefix ? [prefix, 'websiteUrl'] : 'websiteUrl'}
            label="Website"
            rules={[...RULES, { type: 'url', message: 'Need url format' }]}
          >
            {mode === 'edit' || !values ? (
              <Input />
            ) : (
              <LinkOutlined onClick={() => window.open(values.websiteUrl)} />
            )}
          </Form.Item>
        </div>
      )}
      {(mode === 'edit' || values.bookingUrl) && (
        <div
          className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}
        >
          <Form.Item
            name={prefix ? [prefix, 'bookingUrl'] : 'bookingUrl'}
            label="Reservation"
            rules={[...RULES, { type: 'url', message: 'Need url format' }]}
          >
            {mode === 'edit' || !values ? (
              <Input />
            ) : (
              <LinkOutlined onClick={() => window.open(values.bookingUrl)} />
            )}
          </Form.Item>
          {mode === 'edit' && (
            <div className="description">
              <InfoCircleOutlined />
              <div>Link of the the booking page on the hotel’s own website</div>
            </div>
          )}
        </div>
      )}
      {(mode === 'edit' || values.bookingComUrl) && (
        <div
          className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}
        >
          <Form.Item
            name={prefix ? [prefix, 'bookingComUrl'] : 'bookingComUrl'}
            label="Booking.com"
            rules={[
              ...RULES,
              {
                pattern: new RegExp(BOOKING_COM_URL_REGEXP),
                message: 'Need booking url format',
              },
            ]}
          >
            {mode === 'edit' || !values ? (
              <Input />
            ) : (
              <LinkOutlined onClick={() => window.open(values.bookingComUrl)} />
            )}
          </Form.Item>
        </div>
      )}
      {(mode === 'edit' || values.instagramUrl) && (
        <div className="input-row">
          <Form.Item
            name={prefix ? [prefix, 'instagramUrl'] : 'instagramUrl'}
            label="Instagram"
            rules={[{ type: 'url', message: 'Need url format' }]}
          >
            {mode === 'edit' || !values ? (
              <Input />
            ) : (
              <LinkOutlined onClick={() => window.open(values.instagramUrl)} />
            )}
          </Form.Item>
          {mode === 'edit' && (
            <div className="description">
              <InfoCircleOutlined />
              <div>If there is one</div>
            </div>
          )}
        </div>
      )}
      {(mode === 'edit' || values.instagramLocationUrl) && (
        <div className="input-row">
          <Form.Item
            name={
              prefix ? [prefix, 'instagramLocationUrl'] : 'instagramLocationUrl'
            }
            label="Instagram location"
            rules={[{ type: 'url', message: 'Need url format' }]}
          >
            {mode === 'edit' || !values ? (
              <Input />
            ) : (
              <LinkOutlined
                onClick={() => window.open(values.instagramLocationUrl)}
              />
            )}
          </Form.Item>
          {mode === 'edit' && (
            <div className="description">
              <InfoCircleOutlined />
              <div>If there is one</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

type Props = {
  mode?: string;
  values?: any;
  prefix?: string;
};

export default HotelLinks;
