import React, { useState } from 'react';

import { Option, Select } from 'app/components/fields/Select';

const BrandSelect = ({ value, onChange, experiencesBrands }: Props) => {
  const [customBrands, setCustomBrands] = useState<string[]>([]);

  return (
    <Select
      value={value}
      onChange={onChange}
      showSearch
      allowClear
      onInputKeyDown={(event) => {
        if (event.key === 'Enter') {
          // @ts-ignore
          const newBrand = event.target.value;

          if (newBrand) {
            setCustomBrands((prevCustomBrands) => [
              ...prevCustomBrands,
              newBrand,
            ]);

            if (onChange) {
              onChange(newBrand);
            }
          }
        }
      }}
    >
      {[...experiencesBrands, ...customBrands].map((brand) => (
        <Option key={brand} value={brand}>
          {brand}
        </Option>
      ))}
    </Select>
  );
};

type Props = {
  value?: string;
  onChange?: (newValue: string) => void;
  experiencesBrands: string[];
};

export default BrandSelect;
