import PropTypes from 'prop-types';
import React from 'react';

import { Option, Select } from 'app/components/fields/Select';

// TODO: antd AutoComplete does not support int as value ;)
const SearchBox = ({ dataSource, ...props }) => (
  <Select showSearch optionFilterProp="children" {...props}>
    {dataSource.map((data) => (
      <Option key={data.id} value={data.id}>
        {data.name}
      </Option>
    ))}
  </Select>
);

SearchBox.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SearchBox;
