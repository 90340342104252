import { get, isNil } from 'lodash';

export const required = (value) => (!isNil(value) ? undefined : 'Required');
export const requiredIf = (prop, condition) => (value, allValues) =>
  get(allValues, prop) === condition ? required(value) : undefined;
export const requiredArray = (value) =>
  !value || !value.length || value.some((v) => v === '')
    ? 'You must add at least 1 entry'
    : undefined;

export const slugged = (value) =>
  /^([a-z]|[0-9]|-|_)+$/.test(value)
    ? undefined
    : 'Only lowercase letters and "-" "_" symbols are permitted';
