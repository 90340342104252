import { emptySection } from 'app/components/pages/Edito/Event/Detail/EditoEventDetail';
import {
  IEditoEventForm,
  IEditoEventRequest,
  IEditoEventResponse,
} from 'app/typings/edito';
import { formatToLocalDateTime } from 'app/utils/dates';

export const formatEditoEventResponseToForm = (
  editoEvent: IEditoEventResponse
): IEditoEventForm => {
  return {
    id: editoEvent.id,
    countryId: editoEvent.countryId,
    published: editoEvent.published,
    title: editoEvent.title,
    catchphrase: editoEvent.catchphrase,
    clubId: editoEvent.clubId ? editoEvent.clubId : 'empty',
    picture: editoEvent.picture,
    description: editoEvent.description,
    endDate: editoEvent.endDate ? new Date(editoEvent.endDate) : undefined,
    publishedAt: editoEvent.publishedAt,
    startDate: editoEvent.startDate
      ? new Date(editoEvent.startDate)
      : undefined,
    sections: editoEvent.sections.length
      ? editoEvent.sections.map((section) => ({
          picture: section.picture,
          title: section.title,
          description: section.description,
          actionType: section.actionType ? section.actionType : 'empty',
          emojiId: section.emojiId,
          label: section.label,
          webLink: section.webLink,
          appLink: section.appLink,
        }))
      : [emptySection],
  };
};

export const formatEditoEventFormToRequest = (
  editoEvent: IEditoEventForm
): IEditoEventRequest => {
  return {
    id: editoEvent.id,
    countryId: editoEvent.countryId,
    published: editoEvent.published,
    sections: editoEvent.sections.map((section) => ({
      title: section.title,
      description: section.description,
      actionType:
        section.actionType === 'empty' ? undefined : section.actionType,
      pictureId: section.picture?.id,
      emojiId: section.emojiId,
      label: section.label,
      webLink: section.webLink,
      appLink: section.appLink,
    })),
    title: editoEvent.title.trim(),
    catchphrase: editoEvent.catchphrase?.trim(),
    clubId: editoEvent.clubId === 'empty' ? undefined : editoEvent.clubId,
    pictureId: editoEvent.picture?.id,
    description: editoEvent.description?.trim(),
    endDate: editoEvent.endDate
      ? formatToLocalDateTime(editoEvent.endDate)
      : undefined,
    publishedAt: editoEvent.publishedAt,
    startDate: editoEvent.startDate
      ? formatToLocalDateTime(editoEvent.startDate)
      : undefined,
  };
};
