import { Button, Layout, PageHeader } from 'antd';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { CurrencyProvider } from 'app/context/CurrencyContext/CurrencyContext';
import { useGiftCardDetails } from 'app/hooks/data/useGiftCardDetails';
import { refundGiftCard, tryEditGiftCard } from 'app/redux/actions/giftCards';
import { useAppDispatch } from 'app/redux/hooks';
import {
  GiftCardDetails,
  GiftCardEditForm,
  RefundForm,
} from 'app/redux/models/GiftCard/GiftCard';

import 'styles/pages/Booking/booking-details.scss';
import 'styles/pages/GiftCards/gift-card-details.scss';

import BookingsLinked from './BookingsLinked';
import GiftCardEdit from './GiftCardEdit/GiftCardEdit';
import Payment from './Payment/Payment';
import Properties from './Properties/Properties';
import { RefundModal } from './RefundModal/RefundModal';
import { Refunds } from './Refunds/Refunds';
import Sender from './Sender/Sender';
import Summary from './Summary/Summary';

const GiftCardDetailsPage = () => {
  const { id: giftCardId } = useParams<{ id: string }>();
  const { data: giftCardDetails, refetch } = useGiftCardDetails(giftCardId);

  if (!giftCardDetails) {
    return <div />;
  }

  return (
    <GiftCardDetailsHydratedPage
      giftCardDetails={giftCardDetails}
      refetch={refetch}
    />
  );
};

type GiftCardDetailsHydratedProps = {
  giftCardDetails: GiftCardDetails;
  refetch: () => Promise<unknown>;
};

const GiftCardDetailsHydratedPage = ({
  giftCardDetails,
  refetch,
}: GiftCardDetailsHydratedProps) => {
  const dispatch = useAppDispatch();
  const [isRefundModalVisible, setIsRefundModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const history = useHistory();

  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  const hideModal = useCallback(() => {
    setIsEditModalVisible(false);
  }, []);

  const displayModal = useCallback(() => {
    setIsEditModalVisible(true);
  }, []);

  const onSubmit = useCallback(
    (body: GiftCardEditForm) => {
      dispatch(
        tryEditGiftCard({ code: giftCardDetails.giftCard.code, body, refetch })
      );
      setIsEditModalVisible(false);
    },
    [dispatch, giftCardDetails.giftCard.code, refetch]
  );

  const editGiftCardButton = (
    <div className="giftcard-edit-btn">
      <Button size="large" onClick={displayModal}>
        Edit
      </Button>
    </div>
  );

  const onRefund = (refundValues: RefundForm) => {
    dispatch(
      refundGiftCard({
        code: giftCardDetails.giftCard.code,
        body: refundValues,
      })
    );
  };

  const { giftCard, giftCardBookings, refunds } = giftCardDetails;
  const { currency, code } = giftCard;

  return (
    <Layout className="booking-details">
      <CurrencyProvider currency={currency}>
        <PageHeader
          className="header"
          onBack={back}
          title={`Gift card ${code}`}
          extra={editGiftCardButton}
        />
        <div className="body">
          <div className="left-part">
            {giftCardDetails.user && (
              <Sender giftCardDetails={giftCardDetails} />
            )}
            <BookingsLinked currency={currency} bookings={giftCardBookings} />
          </div>
          <div className="right-part">
            <Summary giftCard={giftCard} giftCardBookings={giftCardBookings} />
            <Properties giftCard={giftCard} />
            <Payment
              giftCard={giftCard}
              setIsRefundModalVisible={setIsRefundModalVisible}
            />
            <Refunds refunds={refunds} />
          </div>
        </div>
        <GiftCardEdit
          visible={isEditModalVisible}
          giftCard={giftCard}
          onCancel={hideModal}
          onSubmit={onSubmit}
        />
        {isRefundModalVisible && (
          <RefundModal
            giftCard={giftCard}
            refunds={refunds}
            title="Refund Gift Card"
            onOk={onRefund}
            onCancel={() => setIsRefundModalVisible(false)}
          />
        )}
      </CurrencyProvider>
    </Layout>
  );
};

export default GiftCardDetailsPage;
