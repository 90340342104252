import type { Address } from 'app/redux/models/Address/Address';
import type { Location } from 'app/redux/models/Location/Location';
import type { Picture } from 'app/redux/models/Picture/Picture';

import { PAX } from '../PAX/PAX';

export type Customer = {
  readonly gender: string | undefined;
  readonly firstName: string | undefined;
  readonly lastName: string | undefined;
  readonly email: string;
  readonly phoneNumber: string | undefined;
  readonly guestFirstname: string | undefined;
  readonly guestLastname: string | undefined;
  readonly countrySlug: string | undefined;
  readonly id: number;
};

export type CardInfo = {
  readonly id: string;
  readonly last4: string;
  readonly brand: string;
};

export type Room = {
  readonly id: number;
  readonly name: string | undefined;
  readonly category: string;
  readonly discountPrice: number;
  readonly price: number;
  readonly bedPricePerNight: number | undefined;
  readonly additionalAdultPricePerNight: number | undefined;
  readonly singleCustomerDiscountPerNight: number | undefined;
};

type Package = {
  readonly id: string;
  readonly slug: string;
  readonly hotelId: number;
  readonly hotelName: string;
  readonly name: string;
  readonly email: string;
  readonly stars: number;
  readonly phone: string;
  readonly address: Address;
  readonly location: Location;
  readonly coverPicture: Picture;
};

type PaymentAdvanced = {
  readonly createdAt: string;
  readonly couponCode: string | undefined;
  readonly couponAmount: number | undefined;
  readonly refusalReason: string | undefined;
};

type BookingExperienceCategory = {
  readonly name: string;
  readonly experienceType: string | undefined;
  readonly brand: boolean;
  readonly duration: boolean;
  readonly time: boolean;
  readonly quantityTitle: boolean;
  readonly locations: boolean;
  readonly quantity: boolean;
};

export type Experience = {
  readonly id: number;
  readonly name: string;
  readonly quantity: number;
  readonly category: BookingExperienceCategory;
  readonly price: number | undefined;
  readonly included: boolean;
  readonly priority: number;
  readonly brand: string | undefined;
  readonly quantityDescription: string | undefined;
  readonly duration: number | undefined;
  readonly durationDescription: string | undefined;
  readonly time: string | undefined;
  readonly roomServiceAvailable: boolean;
  readonly roomServiceIncluded: boolean;
  readonly roomServicePrice: number | undefined;
  readonly accessMode: string | undefined;
  readonly locations: string | undefined;
  readonly experienceQuantity: number | undefined;
};

export type BookingSummary = {
  readonly code: string;
  readonly avatarId: string;
  readonly customer: Customer;
  readonly countryId: number;
  readonly bookingId: number;
  readonly checkin: string;
  readonly checkout: string;
  readonly status: string;
  readonly creditAmount: number;
  readonly chargedAmount: number;
  readonly totalAmount: number;
  readonly cardInfo: CardInfo | undefined;
  readonly room: Room;
  readonly comment: string;
  readonly pkg: Package;
  readonly paymentAdvanced: PaymentAdvanced;
  readonly fees: number;
  readonly experiences: Array<Experience>;
  readonly psp: string | undefined;
  readonly basePackagePrice: number;
  readonly basePackageDiscountPrice: number;
  readonly hotelAmount: number;
  readonly paymentReceiptPath: string | undefined;
  readonly pax: PAX;
};

export type Feedback = {
  readonly id: number;
  readonly published: string;
  readonly createdAt: string;
  readonly comment: string;
  readonly experienceScore: number;
  readonly serviceScore: number;
  readonly vibeScore: number;
  readonly roomScore: number;
  readonly breakfastScore: number;
  readonly globalScore: number;
};

type CustomerAdvanced = {
  readonly avatarPath: string;
  readonly birthDate: string;
};

export enum VoucherReasonType {
  STAYCATION_ERROR = "Staycation's error",
  HOTEL_ERROR = 'Hotel error',
  BAD_FEEDBACK = 'Bad feedback',
  CLIENTS_COMPLAINTS = 'Clients complaints',
  OTHER = 'Other',
}

export type Cancellation = {
  reason?: number;
  comment?: string;
};

export type Voucher = {
  readonly code: string;
  readonly initialValue: number;
  readonly remainingValue: number;
  readonly expirationDate: string;
  readonly createdAt: string;
  readonly createdBy: string;
  readonly reasonType: VoucherReasonType;
  readonly comment?: string;
};

export type VoucherForm = {
  readonly code?: string;
  readonly value: number;
  readonly beneficiary: string;
  readonly countryId: number;
  readonly expirationDate: string;
  readonly bookingId: number;
  readonly reasonType: string;
  readonly comment?: string;
  readonly userId: number;
  readonly giftCardType: string;
  readonly infinite: boolean;
};

export type Refund = {
  readonly amountCb: number;
  readonly amountGiftCard: number;
  readonly amountCredit: number;
  readonly source: string;
  readonly createdAt: string;
  readonly reason?: string;
  readonly comment?: string;
  readonly refundedFromHotel?: number;
  readonly userName: string;
};

export type BookingDetails = {
  readonly bookingSummary: BookingSummary;
  readonly feedbacks: Feedback[];
  readonly staycations: Array<number>;
  readonly giftCards: Array<number>;
  readonly customerAdvanced: CustomerAdvanced;
  readonly voucher: Voucher | undefined;
  readonly refunds: Array<Refund>;
};

export function createBookingDetails(values: any): BookingDetails {
  const {
    bookingSummary,
    feedbacks,
    staycations,
    giftCards,
    customerAdvanced,
    voucher,
    refunds,
  } = values;

  return {
    bookingSummary,
    feedbacks,
    staycations,
    giftCards,
    customerAdvanced,
    voucher,
    refunds,
  };
}

export type RefundForm = {
  source: string;
  amountCb?: number | undefined;
  amountGiftCard?: number | undefined;
  amountCredit?: number | undefined;
  reason?: number | undefined;
  comment?: string | undefined;
  cancelBooking: boolean;
};

export type RefundParams = {
  initialPayment: number;
  alreadyRefunded: number;
  maxCbRefund: number;
  maxGiftCardRefund: number;
  maxCreditRefund: number;
  commissionPercent: number;
  maxHotelAmount: number;
};

export function createBookingRefundParams(values: any): RefundParams {
  const {
    initialPayment,
    alreadyRefunded,
    maxCbRefund,
    maxGiftCardRefund,
    commissionPercent,
    maxHotelAmount,
    maxCreditRefund,
  } = values;

  return {
    initialPayment,
    alreadyRefunded,
    maxCbRefund,
    maxGiftCardRefund,
    commissionPercent,
    maxHotelAmount,
    maxCreditRefund,
  };
}
