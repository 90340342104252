import React from 'react';

import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';
import type { Picture } from 'app/redux/models/Picture/Picture';

type Props = {
  cover: Pick<Picture, 'pictureId' | 'alt'>;
  name: string;
};

const CoverCell = ({ name, cover }: Props) => (
  <div className="cover-cell">
    <div className="cover-cell__thumbnail">
      <PictureComponent {...cover} />
    </div>
    <div className="cover-cell">{name}</div>
  </div>
);

export default CoverCell;
