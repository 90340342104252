import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

import '../GiftCardEdit.scss';

const MessageFormItem = () => (
  <div className="input-row">
    <Form.Item
      name="message"
      label="Message"
      rules={[{ required: true, message: 'Required' }]}
    >
      <TextArea maxLength={250} />
    </Form.Item>
  </div>
);

export default MessageFormItem;
