import { useState } from 'react';

type BoolStateActions = {
  open: () => void;
  close: () => void;
  toggle: () => void;
};

const useBoolState = (initialValue = false): [boolean, BoolStateActions] => {
  const [value, setValue] = useState(initialValue);
  const actions = {
    open: () => setValue(true),
    close: () => setValue(false),
    toggle: () => setValue(!value),
  };

  return [value, actions];
};

export default useBoolState;
