/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

import type { Country } from 'app/redux/models/Country/Country';
import type { RootState } from 'app/redux/store';

// TODO: Need to properly type the state to not enforce type here
export const getCountries = (state: RootState): Array<Country> =>
  state.conf.countries;

export const getCurrencyFromCountrySlug = (countrySlug: string) =>
  createSelector(getCountries, (countries) => {
    const country = countries.find((c) => c.slug === countrySlug);

    if (!country) {
      return null;
    }

    return country.currency;
  });
