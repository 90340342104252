import React from 'react';

import Line from 'app/components/pages/GiftCards/details/Line/Line';
import type { Customer } from 'app/redux/models/BookingDetails/BookingDetails';

type Props = {
  customer: Customer;
};

const Guest = ({ customer }: Props) => {
  const { firstName, lastName, guestFirstname, guestLastname } = customer;

  if (
    (!guestFirstname || guestFirstname === firstName) &&
    (!guestLastname || guestLastname === lastName)
  ) {
    return null;
  }

  return (
    <div className="bloc">
      <div className="title">Guest</div>
      <div className="generic-content">
        {guestFirstname && <Line title="Firstname" value={guestFirstname} />}
        {guestLastname && <Line title="Lastname" value={guestLastname} />}
      </div>
    </div>
  );
};

export default Guest;
