import { useQuery } from '@tanstack/react-query';

import { ExperienceCategory } from 'app/redux/models/ExperienceCategory/ExperienceCategory';
import { Experience } from 'app/typings/experiences';
import { apiRequest } from 'app/utils/request/api';

const pathExperiencesCategories = '/experiences/categories' as const;

export const pathExperiencesByHotelId =
  '/hotels/:hotelId/experiences?includeStaycationExp=false' as const;

export function useExperiencesByHotelId(hotelId: number) {
  return useQuery({
    queryKey: [pathExperiencesByHotelId],
    queryFn: () =>
      apiRequest({
        path: pathExperiencesByHotelId,
        params: { hotelId: hotelId.toString() },
      }) as Promise<Experience[]>,
    staleTime: 5000,
  });
}

export function useExperiencesCategories() {
  return useQuery({
    queryKey: [pathExperiencesCategories],
    queryFn: () =>
      apiRequest({
        path: pathExperiencesCategories,
      }) as Promise<ExperienceCategory[]>,
    staleTime: Infinity,
  });
}
