import { Button } from 'antd';
import { extendMoment } from 'moment-range';
import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';

import { IHotel } from 'app/typings';

const MomentModule = require('moment');

const moment = extendMoment(MomentModule);

export const WebsiteButton: FC<Props> = ({ hotel }) => {
  const validURL = (str: any) => {
    const pattern =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;

    return !!pattern.test(str);
  };

  const onWebsiteClick = useCallback(() => {
    if (!hotel) {
      return;
    }

    const startPattern = hotel.bookingUrl.match(/{start:.*?}/)?.[0];

    if (startPattern) {
      const startDateFormat = startPattern?.match(/^\{start:(.+)\}$/)?.[1];
      const startDate = moment(Date.now()).format(
        startDateFormat?.toUpperCase()
      );

      const endPattern = hotel.bookingUrl.match(/{end:.*?}/)?.[0];

      if (endPattern) {
        const endDateFormat = endPattern?.match(/^\{end:(.+)\}$/)?.[1];
        const endDate = moment(Date.now())
          .add(1, 'days')
          .format(endDateFormat?.toUpperCase());

        window.open(
          hotel.bookingUrl
            .replace(startPattern, startDate)
            .replace(endPattern, endDate)
        );
      } else {
        window.open(hotel.bookingUrl.replace(startPattern, startDate));
      }
    } else {
      window.open(hotel.bookingUrl);
    }
  }, [hotel]);

  if (!hotel) {
    return null;
  }

  return (
    <Button
      disabled={
        !hotel?.bookingUrl ||
        !hotel.bookingUrl.length ||
        !validURL(hotel.bookingUrl)
      }
      onClick={onWebsiteClick}
    >
      Hotel website
    </Button>
  );
};

const mapStateToProps = (state: any) => ({
  hotel: state.hotelAdmin.hotels.find(
    (h: any) => h.id === state.hotelAdmin.hotelId
  ),
});

type Props = {
  hotel: IHotel;
};

export default connect(mapStateToProps)(WebsiteButton);
