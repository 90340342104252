import { Form } from 'antd';
import React from 'react';

import Pictures from 'app/components/commons/Package/Pictures/Pictures';
import UploadList from 'app/components/commons/Uploader/UploadList/UploadList';
import type { Experience } from 'app/redux/models/Experience/Experience';

const DEFAULT_PICTURES_CATEGORIES = [
  { name: 'Official', type: 'official' },
  { name: 'Sourcing', type: 'sourcing' },
];
const DEFAULT_KIND = 'sourcing';

const Photos = ({
  title,
  mode = 'edit',
  values,
  hotelId,
  isBuilder,
  isOriginal,
}: Props) => (
  <Form.Item name="pictures">
    {mode !== 'view' ? (
      <Pictures
        sectionTitle={title || 'Add photos'}
        hotelId={!isOriginal ? hotelId : undefined}
        picturesCategories={!isOriginal ? DEFAULT_PICTURES_CATEGORIES : []}
        kind={DEFAULT_KIND}
        previewable={isBuilder}
        draggable={!isBuilder}
        editable={!isBuilder}
        removable
      />
    ) : (
      <UploadList pictures={values.pictures} uploadingItems={{}} previewable />
    )}
  </Form.Item>
);

type Props = {
  title?: string;
  mode?: string;
  isBuilder?: boolean;
  values: Experience;
  hotelId?: number;
  isOriginal: boolean;
};

export default Photos;
