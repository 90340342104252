import { Button, Layout } from 'antd';
import axios from 'axios';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import PictureGallery from '../../PictureGallery/PictureGallery';
import { Picture } from '../../Types';

const { Content, Footer } = Layout;

const GalleryDetailView: FC<Props> = ({
  pictures,
  selectedCategory,
  selectedPictures,
  setSelectedPictures,
  goToEdit,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedPictures([]);
  }, [selectedCategory, setSelectedPictures]);

  const download = async (url: any) => {
    const res = await axios(url, { responseType: 'blob' });
    const imgUrl = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');

    link.href = imgUrl;
    link.setAttribute('download', 'file.jpg');
    document.body.appendChild(link);
    link.click();
  };

  const downloadAsZip = async (picList: any) => {
    const zip = JSZip();
    const links = picList.map((pic: any) => {
      const url = `https://staycation-app.s3.eu-west-3.amazonaws.com/assets/pictures/${pic.pictureId}`;

      return axios(url, { responseType: 'blob' });
    });
    const files = await Promise.all(links);

    files.forEach((file: any, idx) => zip.file(`${idx}.jpg`, file.data));

    const content = await zip.generateAsync({ type: 'blob' });

    FileSaver.saveAs(content, 'pictures.zip');
  };

  const handleDownload = async () => {
    setLoading(true);

    if (selectedPictures.length === 1) {
      await download(
        `https://staycation-app.s3.eu-west-3.amazonaws.com/assets/pictures/${selectedPictures[0].pictureId}`
      );
    } else {
      downloadAsZip(selectedPictures);
    }

    setSelectedPictures([]);
    setLoading(false);
  };

  const handleSelect = (pic: any) => {
    if (selectedPictures.find((p) => p.id === pic.id)) {
      setSelectedPictures(_.reject(selectedPictures, { id: pic.id }));
    } else {
      setSelectedPictures([...selectedPictures, pic]);
    }
  };

  const handleSelectAll = () =>
    selectedPictures.length !== pictures.length
      ? setSelectedPictures([...pictures])
      : setSelectedPictures([]);

  const renderFooter = () => (
    <Footer className="gallery-detail__footer">
      <div>
        <Button
          type="default"
          size="large"
          onClick={handleSelectAll}
          loading={loading}
          disabled={!pictures.length}
          className="first-button"
        >
          {!pictures.length || selectedPictures.length !== pictures.length
            ? 'Select all'
            : 'Unselect all'}
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={handleDownload}
          loading={loading}
          disabled={!selectedPictures.length || loading}
        >
          Download
        </Button>
      </div>
      <Button type="primary" size="large" onClick={goToEdit} loading={loading}>
        Edit
      </Button>
    </Footer>
  );

  return (
    <>
      <Content className="gallery-detail__content">
        <PictureGallery pictures={pictures} onSelect={handleSelect} />
      </Content>
      {renderFooter()}
    </>
  );
};

type Props = {
  pictures: Array<Picture & { selected: boolean }>;
  selectedCategory: {};
  selectedPictures: Array<Picture>;
  setSelectedPictures: (pictures: Array<Picture>) => void;
  goToEdit: () => void;
};

export default GalleryDetailView;
