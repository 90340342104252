export type ExperienceFieldType = 'NOT_APPLICABLE' | 'OPTIONAL' | 'MANDATORY';

export type ExperienceCategory = {
  readonly id: number;
  readonly name: string;
  readonly pictureId?: number;
  readonly parentId?: number;
  readonly experienceType?: string;
  readonly brand: boolean;
  readonly duration: boolean;
  readonly time: boolean;
  readonly quantityTitle: boolean;
  readonly locations: boolean;
  readonly accessMode: boolean;
  readonly price: boolean;
  readonly priceDescription: boolean;
  readonly quantity: boolean;
  readonly quantityDescription: boolean;
  readonly openingHours: ExperienceFieldType;
  readonly roomService: boolean;
  readonly spaces: boolean;
};

const defaultState: ExperienceCategory = {
  id: 0,
  name: '',
  brand: false,
  duration: false,
  time: false,
  quantityTitle: false,
  locations: false,
  accessMode: false,
  price: false,
  priceDescription: false,
  quantity: false,
  quantityDescription: false,
  openingHours: 'NOT_APPLICABLE',
  roomService: false,
  spaces: false,
};

export function createExperienceCategory(values: any): ExperienceCategory {
  if (!values) {
    return defaultState;
  }

  const {
    id,
    name,
    pictureId,
    parentId,
    experienceType,
    brand,
    duration,
    time,
    quantityTitle,
    locations,
    accessMode,
    price,
    priceDescription,
    quantity,
    quantityDescription,
    openingHours,
    roomService,
    spaces,
  } = values;

  return {
    id,
    name,
    pictureId,
    parentId,
    experienceType,
    brand,
    duration,
    time,
    quantityTitle,
    locations,
    accessMode,
    price,
    priceDescription,
    quantity,
    quantityDescription,
    openingHours,
    roomService,
    spaces,
  };
}
