import { message } from 'antd';
import queryString from 'query-string';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import * as entitiesActions from 'app/redux/actions/entities';
import * as openingsActions from 'app/redux/actions/openings';
import { get, patch, post, requestGenerator } from 'app/redux/requests';

export function* setUnavailable({ hotelId }) {
  yield requestGenerator(
    () => put(entitiesActions.startPatchEntity('hotels')),
    () => patch(`hotels/${hotelId}/unavailable`),
    () => {
      message.success(`No more stock for hotel #${hotelId}!`);

      return put(entitiesActions.patchEntitySuccess('hotels'));
    },
    (error) => {
      message.error(`Failed to set hotel #${hotelId} unavailable!`);

      return put(entitiesActions.patchEntityFailure('hotels', error));
    },
    { expectJson: false }
  );
}

export function* dismissWarnings({ openingsIds }) {
  yield requestGenerator(
    () => put(entitiesActions.startPatchEntity('openings')),
    () => post('hotel-admin/warnings', openingsIds),
    () => put(entitiesActions.patchEntitySuccess('openings')),
    (error) => {
      message.error('Error dismissing openings warnings', error);

      return put(entitiesActions.patchEntityFailure('openings', error));
    }
  );
}

export function* fetchInventory({
  hotelGroup,
  search,
  channelManager,
  beginDate,
  endDate,
  sorting,
  offset,
  limit,
}) {
  yield requestGenerator(
    () => put(openingsActions.startLoading()),
    () => {
      const stringifiedParams = queryString.stringify({
        hotelGroup,
        search,
        channelManager,
        beginDate,
        endDate,
        sortingColumn: sorting?.sortingColumn,
        sortingOrder: sorting?.sortingOrder,
        offset,
        limit,
      });

      return get(`openings/paginated?${stringifiedParams}`);
    },
    (result) =>
      all([
        put(openingsActions.fetchInventorySuccess(result)),
        put(openingsActions.stopLoading()),
      ]),
    (error) =>
      all([
        put(openingsActions.stopLoading()),
        call(message.error('Error fetching inventory', error)),
      ])
  );
}

export default function* openingsSagas() {
  yield all([
    takeEvery(
      openingsActions.actions.TRY_SET_HOTEL_UNAVAILABLE,
      setUnavailable
    ),
    takeEvery(openingsActions.actions.TRY_DISMISS_WARNINGS, dismissWarnings),
    takeEvery(openingsActions.actions.TRY_FETCH_INVENTORY, fetchInventory),
  ]);
}
