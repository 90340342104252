import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu, PageHeader, Table } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import PaginatedSearchHeader from 'app/components/commons/Range/PaginatedSearchHeader/PaginatedSearchHeader';
import Pagination from 'app/components/commons/Range/Pagination/Pagination';
import { CoverCellRoom, PublishedCell } from 'app/components/lists/cells';
import ModalCheckBox from 'app/components/pages/Extranet/ExtranetBookings/ModalCheckBox';
import { tryFetchRooms, tryPublishRoom } from 'app/redux/actions/rooms';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import type { RoomListElement } from 'app/redux/models/RoomListElement/RoomListElement';
import {
  extractBoolean,
  parseArrayParam,
  useQueryParamHistory,
} from 'app/utils/queryParamHistory';
import {
  SortOrder,
  convertFromAntdSortOrder,
  convertToAntdSortOrder,
  formatSortingForAPI,
  getSortOrder,
} from 'app/utils/sort';

import 'styles/layout/page-list.scss';

import './Rooms.scss';

const { Content } = Layout;
const { Column } = Table;

const PAGE_SIZE = 50;

type RoomsListQueryParam = {
  search: string;
  pageNumber: number;
  dateSorting: SortOrder;
  isPublishedFilter: boolean[];
};

const formatIsPublished = (isPublishedFilter?: boolean[]) => {
  if (isPublishedFilter?.length === 1) {
    return isPublishedFilter[0];
  }

  return undefined;
};

const makePayload = (queryParam: Partial<RoomsListQueryParam>) => {
  const sortingColumn =
    queryParam.dateSorting && queryParam.dateSorting !== 'none'
      ? 'date'
      : undefined;
  const sortingOrder =
    queryParam.dateSorting && queryParam.dateSorting !== 'none'
      ? formatSortingForAPI(queryParam.dateSorting)
      : undefined;

  return {
    search: queryParam.search,
    sortingColumn,
    sortingOrder,
    offset: ((queryParam.pageNumber || 1) - 1) * PAGE_SIZE,
    limit: PAGE_SIZE,
    isPublished: formatIsPublished(queryParam.isPublishedFilter),
  };
};

const queryParamInit = {
  pageNumber: 1,
  dateSorting: 'descend',
  isPublishedFilter: [true, false],
} as RoomsListQueryParam;
const queryParamKeys = {
  pageNumber: 'p',
  dateSorting: 'ds',
  isPublishedFilter: 'pu',
  search: 's',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  dateSorting: getSortOrder,
  isPublishedFilter: parseArrayParam(extractBoolean),
};

export const Rooms = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const rooms = useAppSelector((state) => state.rooms.rooms);
  const isLoading = useAppSelector((state) => state.rooms.loading);

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<RoomsListQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const debounceFetch = useMemo(
    () =>
      debounce(
        (params: ReturnType<typeof makePayload>) =>
          dispatch(tryFetchRooms(params)),
        500
      ),
    [dispatch]
  );

  useEffect(() => {
    debounceFetch(makePayload(queryParam));
  }, [queryParam, debounceFetch]);

  const handlePageNumber = (pageNumber: number) =>
    updateQueryParam({ pageNumber });

  const handleSearch = (search: string) =>
    updateQueryParam({ pageNumber: 1, search });

  const dateSortingHandler = (sort: SortOrder) =>
    updateQueryParam({
      dateSorting: sort,
      pageNumber: 1,
    });

  const formatDate = (date: any) =>
    date && moment.utc(date).local().format('DD/MM/YYYY à HH:mm');

  const handlePublishedFilter = (filters: boolean[]) => {
    updateQueryParam({
      isPublishedFilter: filters,
      pageNumber: 1,
    });
  };

  const handleIsPublishedFilter = (statuses: any) => {
    if (!statuses.length) {
      handlePublishedFilter([true, false]);
    } else {
      handlePublishedFilter(statuses);
    }
  };

  const goToEditPage = (id: number) => {
    history.push(`/rooms/${id}/edit`);
  };

  const handleRowActions = ({ id }: any) => ({
    onClick: () => goToEditPage(id),
  });

  const actionMenu = (room: RoomListElement) => (
    <div className="actions-menu">
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="edit" onClick={() => goToEditPage(room.id)}>
              Edit
            </Menu.Item>
            <Menu.Item
              key="publish"
              onClick={() => dispatch(tryPublishRoom(room.id))}
            >
              {room.published ? 'Unpublish' : 'Publish'}
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );

  const newRoomButton = (
    <Button
      type="primary"
      size="large"
      onClick={() => history.push('rooms/new')}
    >
      New
    </Button>
  );

  const isLastPage = rooms.length < PAGE_SIZE;

  const renderHeader = () => (
    <PaginatedSearchHeader
      onSearch={handleSearch}
      currentSearch={queryParam.search}
      currentPage={queryParam.pageNumber || 1}
      setPageNumber={handlePageNumber}
      isLastPage={isLastPage}
    />
  );

  return (
    <Layout className="page-list room-list">
      <PageHeader className="header" title="Rooms" extra={newRoomButton} />
      <Content className="body">
        <Table<RoomListElement>
          dataSource={rooms}
          pagination={false}
          title={renderHeader}
          loading={isLoading}
          rowKey="id"
          onRow={handleRowActions}
          onChange={(__, ___, sorter) => {
            const { order } = Array.isArray(sorter) ? sorter[0] : sorter;

            dateSortingHandler(convertFromAntdSortOrder(order));
          }}
        >
          <Column
            title="Name"
            dataIndex="categoryName"
            render={CoverCellRoom}
          />
          <Column title="Hotel" dataIndex="hotelName" />
          <Column
            title="Last modified"
            dataIndex="updatedAt"
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.dateSorting)}
            render={formatDate}
          />
          <Column
            title={
              <ModalCheckBox
                title="Status"
                defaultValue={[true, false]}
                onChange={handleIsPublishedFilter}
                items={[
                  {
                    value: true,
                    label: 'Published',
                  },
                  {
                    value: false,
                    label: 'Unpublished',
                  },
                ]}
                value={queryParam.isPublishedFilter}
              />
            }
            dataIndex="published"
            render={PublishedCell}
            className="sort-column"
          />
          <Column
            render={actionMenu}
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            align="center"
          />
        </Table>
        <Pagination
          className="footer"
          currentPage={queryParam.pageNumber || 1}
          setPageNumber={handlePageNumber}
          isLastPage={isLastPage}
        />
      </Content>
    </Layout>
  );
};

export default Rooms;
