import { Checkbox, Form, Input } from 'antd';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IHotel } from 'app/components/commons/Hotel/Types';
import { Option, Select } from 'app/components/fields/Select';
import { tryFetchHotelExperiences } from 'app/redux/actions/experiences';
import { tryFetchHotelRooms } from 'app/redux/actions/rooms';

import './BasicInfo.scss';

const RULES = [{ required: true, message: 'Required' }];

export const BasicInfo: FC<Props> = ({ mode = 'edit', values, newHotel }) => {
  const dispatch = useDispatch();

  // TODO: We need to properly type the redux state
  // @ts-ignore
  const hotels: IHotel[] = useSelector((state) => state.hotels.hotels);

  const onChangeHotel = useCallback(
    (hotelId: number) => {
      dispatch(tryFetchHotelExperiences(hotelId, true));
      dispatch(tryFetchHotelRooms(hotelId));
    },
    [dispatch]
  );

  if (!hotels) {
    return null;
  }

  return (
    <div className="basic-info">
      <div
        className={`input-row input-row-hotel-select ${
          mode === 'view' ? 'input-row-no-rules' : ''
        }`}
      >
        <Form.Item name="hotelId" label="Hotel" rules={RULES}>
          {mode === 'edit' ? (
            <Select
              placeholder="Select a hotel"
              showSearch
              optionFilterProp="children"
              onChange={onChangeHotel}
              disabled={!newHotel}
            >
              {hotels.map((hotel) => (
                <Option key={hotel.id} value={hotel.id}>
                  {hotel.name}
                </Option>
              ))}
            </Select>
          ) : (
            <div className="view-text">
              {hotels.find((hotel) => hotel.id === values.hotelId)?.name}
            </div>
          )}
        </Form.Item>
      </div>
      <div
        className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}
      >
        <Form.Item name="slug" label="Slug">
          {mode === 'edit' || !values ? (
            <Input disabled={!newHotel} />
          ) : (
            <div className="view-text">{values.slug}</div>
          )}
        </Form.Item>
      </div>
      <div
        className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}
      >
        <Form.Item
          name="dayPackage"
          label="Day Package"
          valuePropName="checked"
        >
          <Checkbox disabled={mode !== 'edit'} />
        </Form.Item>
      </div>
    </div>
  );
};

type Props = {
  mode?: string;
  values?: any;
  newHotel: boolean;
};

export default BasicInfo;
