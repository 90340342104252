import PropTypes from 'prop-types';
import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import 'styles/layout/Sortable/List/sortable-list.scss';

const SortableList = SortableContainer(({ children }) => (
  <ul className="sortable-list">{children}</ul>
));

SortableList.propTypes = {
  children: PropTypes.node,
};

export default SortableList;
