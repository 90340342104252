import type { AnyAction } from 'redux';

import * as bookingActions from 'app/redux/actions/booking';
import type {
  BookingDetails,
  RefundParams,
} from 'app/redux/models/BookingDetails/BookingDetails';

export type Booking = {
  readonly isLoading: boolean;
  readonly bookingDetails: BookingDetails | null;
  readonly refundParams: RefundParams | null;
};

export const initialState: Booking = {
  isLoading: false,
  bookingDetails: null,
  refundParams: null,
};

function booking(state = initialState, action: AnyAction): Booking {
  if (
    bookingActions.getBookingDetailsSuccess.match(action) ||
    bookingActions.refundBookingSuccess.match(action)
  ) {
    const { booking: bookingDetails } = action.payload;

    return {
      ...state,
      bookingDetails,
    };
  }

  if (bookingActions.getBookingRefundParamsSuccess.match(action)) {
    const { params } = action.payload;

    return {
      ...state,
      refundParams: params,
    };
  }

  if (bookingActions.resendBookingConfirmation.match(action)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (bookingActions.resendBookingConfirmationFailure.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (bookingActions.resendBookingConfirmationSuccess.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (bookingActions.clearBookingDetailsState.match(action)) {
    return initialState;
  }

  return state;
}

export default booking;
