import { Switch } from 'antd';
import React from 'react';

const CustomSwitch = ({ value, onChange, mode }: Props) => (
  <Switch
    disabled={mode === 'view'}
    checked={value}
    onChange={(newValue) => {
      if (onChange) {
        onChange(!!newValue);
      }
    }}
  />
);

type Props = {
  value?: boolean;
  onChange?: (newValue: boolean) => void;
  mode?: string;
};

export default CustomSwitch;
