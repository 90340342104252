import { Button } from 'antd';
import React from 'react';

import './EditButton.scss';

type Props = {
  onClick: () => void;
};

const EditButton = ({ onClick }: Props) => (
  <div className="booking-edit-btn">
    <Button size="large" onClick={onClick}>
      Edit
    </Button>
  </div>
);

export default EditButton;
