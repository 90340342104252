/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/redux/store';

export const selectPackages = (state: RootState) => state.packages;
export const selectPackagesPackage = createSelector(
  selectPackages,
  (packages) => packages.package
);
export const selectPackagesPackages = createSelector(
  selectPackages,
  (packages) => packages.packages
);
export const selectPackagesLoading = createSelector(
  selectPackages,
  (packages) => packages.loading
);

export const selectPackagesCopywriting = createSelector(
  selectPackages,
  (packages) => packages.copywriting
);
