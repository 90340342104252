import { PlusOutlined } from '@ant-design/icons';
import { Form, List } from 'antd';
import React, { FC } from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import type { Picture } from 'app/components/commons/Uploader/Types';

import Headline from './Headline/Headline';
import './Headlines.scss';

export const Headlines: FC<IProps> = ({ mode, values, setFieldsValue }) => {
  const isEmpty =
    !values?.headlines || values.headlines.every((h) => !h.featured);

  return (
    <div className="headlines">
      <Form.List name="headlines">
        {(fields, { add, remove }) => (
          <>
            <List
              dataSource={mode === 'edit' || !isEmpty ? fields : []}
              renderItem={(item, index) =>
                mode === 'edit' || values?.headlines?.[index].featured ? (
                  <Headline
                    field={item}
                    mode={mode}
                    index={index}
                    fieldIndex={index}
                    remove={remove}
                    values={values}
                    setFieldsValue={setFieldsValue}
                    disabled={mode === 'view'}
                  />
                ) : (
                  <div />
                )
              }
              rowKey={(item) => item.key.toString()}
              bordered
            />
            {mode === 'edit' && (
              <div
                onClick={() => add({ featured: true })}
                className="headlines__add-button"
              >
                <PlusOutlined />
                <span className="text">Add</span>
              </div>
            )}
          </>
        )}
      </Form.List>
    </div>
  );
};

const SortableList = SortableContainer(Headlines);

const SortableHeadlines: FC<ISortableProps> = ({ move, ...props }) => (
  <SortableList axis="y" useDragHandle onSortEnd={move} {...props} />
);

type HeadlineForm = {
  headlines?: Array<{
    emoji: Picture;
    valueProposition: string;
    experienceId: number;
    featured: boolean;
    title?: string;
  }>;
};

interface IProps {
  mode: string;
  values?: HeadlineForm;
  setFieldsValue: (values: any) => void;
}

interface ISortableProps extends IProps {
  move: (indexes: { oldIndex: number; newIndex: number }) => void;
}

export default SortableHeadlines;
