import { invokeMap, keyBy, mapValues, reduce } from 'lodash';
import React, { ComponentType, FC, useEffect } from 'react';
import { InferableComponentEnhancerWithProps, connect } from 'react-redux';
import { formValueSelector, initialize } from 'redux-form';

import Spinner from 'app/components/commons/Spinner';
import Card from 'app/components/layout/Card';
import {
  tryFetchEntity,
  tryFetchEntityWithParams,
} from 'app/redux/actions/entities';
import {
  entitiesSelector,
  isLoadingSelector,
  isReadySelector,
} from 'app/redux/selectors';

export const entityConnect = <Entities extends object, OwnProps>(
  entities: (keyof Entities)[],
  fields: string[] = []
): EntityConnect<Entities, OwnProps> => {
  const fieldsMap = keyBy(invokeMap(fields, 'split', '.'), (split) => split[1]);

  const mapStateToProps = (state: any) => ({
    ready: reduce(
      entities,
      (acc, entity) => acc && isReadySelector(entity)(state),
      true
    ),
    loading: reduce(
      entities,
      (acc, entity) => acc || isLoadingSelector(entity)(state),
      false
    ),
    ...mapValues(keyBy(entities), (entity) => entitiesSelector(entity)(state)),
    ...mapValues(fieldsMap, ([formName, fieldName]) =>
      formValueSelector(formName)(state, fieldName)
    ),
  });

  const mapDispatchToProps = (dispatch: any) => ({
    fetchEntities: () =>
      entities.map((entity) => dispatch(tryFetchEntity(entity))),
    fetchEntitiesWithParams: (params: any) =>
      entities.map((entity) =>
        dispatch(tryFetchEntityWithParams(entity, params))
      ),
    initialize: (form: any, values: any) =>
      dispatch(
        initialize(form, values, {
          keepDirty: true,
          updateUnregisteredFields: true,
        })
      ),
    dispatch,
  });

  // @ts-ignore // TODO: fix typings later
  return connect(mapStateToProps, mapDispatchToProps);
};

type EntityConnectCommonProps = {
  ready: boolean;
  loading: boolean;
  fetchEntities: () => void;
  fetchEntitiesWithParams: (params: any) => void;
  initialize: (form: any, values: any) => void;
  dispatch: (action: any) => void;
};
type EntityConnect<Entities, OwnProps> = InferableComponentEnhancerWithProps<
  Entities & EntityConnectCommonProps,
  OwnProps
>;

export type EntityConnectProps<Entities, OwnProps> = Entities &
  OwnProps &
  EntityConnectCommonProps;

export const withEntities =
  <Entities extends object, OwnProps>(
    entities: (keyof Entities)[],
    fields: string[] = []
  ) =>
  (Component: ComponentType<EntityConnectProps<Entities, OwnProps>>) => {
    const EntityCardComponent: FC<EntityConnectProps<Entities, OwnProps>> = (
      props
    ) => {
      const { ready, loading, fetchEntities } = props;

      useEffect(() => {
        fetchEntities();
      }, [fetchEntities]);

      return (
        <Spinner spinning={loading}>
          {ready ? (
            // @ts-ignore
            <Component {...props} />
          ) : (
            <Card title="Loading..." />
          )}
        </Spinner>
      );
    };

    // @ts-ignore // TODO: fix typing later
    return entityConnect(entities, fields)(EntityCardComponent);
  };

export default entityConnect;
