import type { Coordinates } from 'app/redux/models/Coordinates/Coordinates';

export type Location = {
  locality: string;
  subLocality: string | undefined;
  coords: Coordinates;
};

export function createLocation(values: any): Location {
  const { locality, subLocality, coords } = values;

  return {
    locality,
    subLocality,
    coords,
  };
}
