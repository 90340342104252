import { Badge, Switch, Tag } from 'antd';
import formatDate from 'date-fns/format';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import Pic from 'app/components/commons/Pic';
import { currencySelector } from 'app/redux/selectors';
import { formatCurrency } from 'app/utils/strings';

import 'styles/lists/cells.scss';

export const dateTimeCell = (format: any) => (date: any) =>
  date && formatDate(new Date(date), format);

export const dateTimeCell2 = (date: any) =>
  date && (
    <div style={{ minWidth: 138 }}>
      {formatDate(new Date(date), "dd/MM/yyy 'at' HH:mm")}
    </div>
  );

export const lengthCell = () => (array: any) => array ? array.length : '0';

export const entityCell = (entities: any) => (entityId: any) =>
  _.get(_.find(entities, { id: entityId }), 'name', 'Unknown');

export const percentCell = (ratio: any) => `${Math.round(100 * ratio)}%`;

type PriceCellProps = {
  price: any;
  entity: unknown;
  defaultText?: string;
};

export const PriceCell = ({
  price,
  entity,
  defaultText = '',
}: PriceCellProps) => {
  const currency = useSelector(currencySelector(entity));

  return formatCurrency(price, currency, defaultText);
};

export const boolTextCell = (textTrue: any, textFalse: any) => (bool: any) =>
  bool ? textTrue : textFalse;

export const SwitchCell = (onChange: any) => (featured: any, entity: any) =>
  (
    <Switch
      checked={featured}
      onChange={() => onChange(entity.id, !featured)}
      size="small"
    />
  );

export const BadgeCell = (badges: any) => (value: any) => {
  const badgeData = _.find(badges, { value });

  return badgeData && <Badge {...badgeData} />;
};

export const PublishedCell = (published: any, { hidden }: any) =>
  hidden ? (
    <Badge color="#bfbfbf" text="Hidden" />
  ) : published ? (
    <Badge status="success" text="Published" />
  ) : (
    <Badge status="warning" text="Unpublished" />
  );

export const NewCell = (isNew: any) =>
  isNew ? <Tag color="#FF3366">NEW</Tag> : '';

export const StatusCell = (status: any) => {
  switch (status) {
    case 'accepted':
      return (
        <Badge style={{ marginRight: 0 }} status="success" text="Confirmed" />
      ); // GRENN-6
    case 'cancelled':
      return (
        <Badge style={{ marginRight: 0 }} status="error" text="Cancelled" />
      ); // ORANGE-6
    case 'pending':
      return (
        <Badge style={{ marginRight: 0 }} status="default" text="Pending" />
      ); // GRAY-6
    case 'expired':
      return (
        <Badge style={{ marginRight: 0 }} status="default" text="Expired" />
      ); // GRAY-6
    case 'aborted':
      return (
        <Badge style={{ marginRight: 0 }} status="default" text="Aborted" />
      ); // GRAY-6
    case 'rejected':
      return (
        <Badge style={{ marginRight: 0 }} status="default" text="Rejected" />
      ); // RED-6

    default:
      return status;
  }
};

export const StatusGiftCardBadge = (type: any) => {
  switch (type) {
    case 'active':
      return (
        <Badge style={{ marginRight: 0 }} status="success" text="Active" />
      ); // GRENN-6
    case 'expired':
      return (
        <Badge style={{ marginRight: 0 }} status="warning" text="Expired" />
      ); // ORANGE-6
    case 'empty':
      return <Badge style={{ marginRight: 0 }} status="default" text="Empty" />; // GRAY-6
    case 'rejected':
      return (
        <Badge style={{ marginRight: 0 }} status="default" text="Rejected" />
      ); // GRAY-6
    case 'pending':
      return (
        <Badge style={{ marginRight: 0 }} status="default" text="Pending" />
      ); // GRAY-6
    case 'cancelled':
      return (
        <Badge style={{ marginRight: 0 }} status="default" text="Cancelled" />
      ); // GRAY-6
    default:
      return type;
  }
};

export const StatusGiftCardCell = (type: any) => {
  switch (type) {
    case 'active':
      return (
        <Tag style={{ marginRight: 0 }} color="#52c41a">
          Active
        </Tag>
      ); // GRENN-6
    case 'expired':
      return (
        <Tag style={{ marginRight: 0 }} color="#fa8c16">
          Expired
        </Tag>
      ); // ORANGE-6
    case 'empty':
      return (
        <Tag style={{ marginRight: 0 }} color="#bfbfbf">
          Empty
        </Tag>
      ); // GRAY-6
    case 'rejected':
      return (
        <Tag style={{ marginRight: 0 }} color="#bfbfbf">
          Rejected
        </Tag>
      ); // GRAY-6
    case 'cancelled':
    case 'pending':
      return (
        <Tag style={{ marginRight: 0 }} color="#bfbfbf">
          Pending
        </Tag>
      ); // GRAY-6
    default:
      return type;
  }
};

export const CoverCell = (name: any, entity: any) => {
  const cover = _.get(entity, 'coverPicture', {});

  return (
    <div className="cover-cell">
      <div className="cover-cell__thumbnail">
        <Pic {...cover} />
      </div>
      <div className="cover-cell">{name}</div>
    </div>
  );
};

export const CoverCellCustomFunction =
  (f: any) => (value: any, entity: any) => {
    const cover = _.get(entity, 'coverPicture', {});

    return (
      <div className="cover-cell">
        <div className="cover-cell__thumbnail">
          <Pic {...cover} />
        </div>
        <div className="cover-cell">{f(entity)}</div>
      </div>
    );
  };

export const CoverCellRoom = (category: any, entity: any) => {
  const cover = _.get(entity, 'coverPicture', {});
  const name = _.get(entity, 'name', null);

  return (
    <div className="cover-cell-room">
      <div
        className={`cover-cell-room__thumbnail ${
          cover?.name?.includes('emoji')
            ? 'cover-cell-room__thumbnail-emoji'
            : ''
        }`}
      >
        <Pic {...cover} />
      </div>
      <div className="cover-cell-room__text">
        <div className="cover-cell-room__title">{category}</div>
        {!!name && <div className="cover-cell-room__subtitle">{name}</div>}
      </div>
    </div>
  );
};

export const TagsCell = (tags: any) => (tagIds: any) =>
  tagIds
    ? tagIds.map((id: any) => {
        const tag = _.find(tags, { id });

        if (!tag) {
          return null;
        }

        return (
          <Tag key={id} color={tag.color}>
            {tag.name}
          </Tag>
        );
      })
    : null;
