import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  InputNumberProps,
  Select,
  Switch,
} from 'antd';
import React, { useState } from 'react';

import CoverPicture from 'app/components/commons/CoverPicture/CoverPicture';
import Pictures from 'app/components/commons/Package/Pictures/Pictures';
import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';
import { Picture } from 'app/redux/models/Picture/Picture';

import { ColorPicker } from '../ColorPicker/ColorPicker';

import './CustomFields.scss';

const { Option } = Select;

const RULES = [{ required: true, message: 'Required' }];

type RowProps = {
  label: string;
  name: string | Array<string | number>;
  required?: boolean;
  max?: number;
  valuePropName?: string;
  initialValue?: any;
  noStyle?: boolean;
};

export const CustomRow = ({
  label,
  name,
  required,
  children,
  max,
  valuePropName,
  initialValue,
  noStyle = false,
}: RowProps & {
  children: any;
}) => {
  const rules: Array<{}> = [...(required ? RULES : [])];

  if (max) {
    rules.push({ max });
  }

  return (
    <div className="field-row">
      <div className="field-label">{label}</div>
      <div className="field-container">
        {name ? (
          <Form.Item
            name={name}
            rules={rules}
            valuePropName={valuePropName}
            initialValue={initialValue}
            noStyle={noStyle}
          >
            {children}
          </Form.Item>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export const InputRow = ({
  placeholder,
  disabled = false,
  ...props
}: RowProps & {
  placeholder?: string;
  disabled?: boolean;
}) => (
  <CustomRow {...props}>
    <Input placeholder={placeholder} disabled={disabled} />
  </CustomRow>
);

export const InputTextAreaRow = ({
  placeholder,
  rows,
  autoSize,
  ...props
}: RowProps & {
  placeholder?: string;
  rows?: number;
  autoSize?: boolean;
}) => (
  <CustomRow {...props}>
    <Input.TextArea placeholder={placeholder} rows={rows} autoSize={autoSize} />
  </CustomRow>
);

export const InputNumberRow = ({
  placeholder,
  disabled = false,
  min,
  step,
  parser,
  ...props
}: RowProps &
  Pick<InputNumberProps, 'parser'> & {
    placeholder?: string;
    disabled?: boolean;
    min?: number;
    step?: number;
  }) => (
  <CustomRow {...props}>
    <InputNumber
      min={min}
      step={step}
      placeholder={placeholder}
      disabled={disabled}
      parser={parser}
    />
  </CustomRow>
);

export const SwitchRow = ({
  disabled = false,
  ...props
}: RowProps & {
  disabled?: boolean;
}) => (
  <CustomRow {...props}>
    <Switch disabled={disabled} />
  </CustomRow>
);

export const SelectRow = ({
  placeholder,
  showSearch,
  dataSource,
  disabled = false,
  ...props
}: RowProps & {
  placeholder?: string;
  showSearch?: boolean;
  disabled?: boolean;
  dataSource: Array<{ id: number | string; name: string }>;
}) => (
  <CustomRow {...props}>
    <Select
      showSearch={showSearch}
      optionFilterProp="children"
      placeholder={placeholder}
      disabled={disabled}
    >
      {dataSource.map((data) => (
        <Option key={data.id} value={data.id}>
          {data.name}
        </Option>
      ))}
    </Select>
  </CustomRow>
);

export const SelectWithPictureRow = ({
  placeholder,
  showSearch,
  dataSource,
  ...props
}: RowProps & {
  placeholder?: string;
  showSearch?: boolean;
  dataSource: Array<{
    id: number;
    name: string;
    coverPicture: Picture;
    searchName?: string;
  }>;
}) => {
  const getKey = (data: any) =>
    data.searchName ? `${data.name} ${data.searchName}` : data.name;

  return (
    <CustomRow {...props}>
      <Select
        showSearch={showSearch}
        optionFilterProp="children"
        placeholder={placeholder}
        filterOption={(input, option) =>
          option?.props.value.toString().includes(input) ||
          option?.props.key.toLowerCase().includes(input.toLowerCase())
        }
      >
        {dataSource.map((data) => (
          <Option
            key={getKey(data)}
            value={data.id}
            className="select-item__option"
          >
            <div className="select-item__wrapper">
              <div className="select-item__thumbnail">
                <PictureComponent {...data.coverPicture} />
              </div>
              <div className="select-item__name">{data.name}</div>
            </div>
          </Option>
        ))}
      </Select>
    </CustomRow>
  );
};

export const DatePickerRow = ({
  disabled = false,
  format,
  ...props
}: RowProps & {
  disabled?: boolean;
  format?: string;
}) => (
  <CustomRow {...props}>
    <DatePicker disabled={disabled} format={format} />
  </CustomRow>
);

export const PictureRow = ({ ...props }: RowProps & {}) => (
  <CustomRow {...props}>
    <CoverPicture />
  </CustomRow>
);

export const ColorPickerRow = ({ ...props }: RowProps & {}) => {
  return (
    <CustomRow {...props}>
      <ColorPicker />
    </CustomRow>
  );
};

const CustomPictures = ({
  value,
  onChange,
  hotelId,
}: {
  value?: any;
  onChange?: any;
  hotelId: number;
}) => {
  const [internalValue, setInternalValue] = useState(value);

  if (!onChange) {
    return null;
  }

  const handleOnChange = (newValue: any) => {
    setInternalValue(newValue[0]);
    onChange(newValue[0]);
  };

  return (
    <Pictures
      value={internalValue ? [internalValue] : []}
      onChange={handleOnChange}
      sectionTitle="Cover"
      hotelId={hotelId}
      picturesCategories={[{ name: 'Sourcing', type: 'sourcing' }]}
      kind="sourcing"
      editable
      removable
      modalSubtitle="Select photos or upload new ones to illustrate the cover"
      maxPictures={1}
    />
  );
};

export const CustomPictureRow = ({
  hotelId,
  ...props
}: RowProps & {
  hotelId: number;
}) => (
  <CustomRow {...props}>
    <CustomPictures hotelId={hotelId} />
  </CustomRow>
);

export const CheckboxRow = ({ ...props }: RowProps) => (
  <CustomRow {...props} valuePropName="checked" initialValue={false}>
    <Checkbox />
  </CustomRow>
);

const Rows = {
  InputRow,
  InputTextAreaRow,
  InputNumberRow,
  SwitchRow,
  SelectRow,
  SelectWithPictureRow,
  PictureRow,
  CustomPictureRow,
};

export default Rows;
