import React, { FC, ReactFragment, ReactNode } from 'react';
import { SortableElement, SortableElementProps } from 'react-sortable-hoc';

import Pic from 'app/components/commons/Pic';
import { IPictureForm } from 'app/typings';

import 'styles/layout/Sortable/List/sortable-item.scss';

import SortableHandle from '../SortableHandle';

import ItemMenu from './ItemMenu';

type Props = {
  children: ReactNode | ReactFragment;
  picture: IPictureForm;
  onRemove?: () => void;
  sortable?: boolean;
};

// TODO: Create a simple list and remove sortable attribute
// TODO: Merge with PictureListItem
const SortableItem = SortableElement(
  ({ children, picture, onRemove, sortable = true }: Props) => (
    <div className="sortable-item">
      <div className="sortable-item__infos">
        <div className="sortable-item__thumbnail">
          <Pic {...picture} />
        </div>
        <div className="sortable-item__name">{children}</div>
      </div>
      <div
        className="sortable-item__actions"
        onClick={(e) => e.stopPropagation()}
      >
        {sortable && <SortableHandle />}
        {onRemove && <ItemMenu onRemove={onRemove} />}
      </div>
    </div>
  )
  // TODO replace react-sortable-hoc with https://github.com/clauderic/dnd-kit (recommended by react-sortable-hoc documentation)
) as unknown as FC<Props & SortableElementProps>;

export default SortableItem;
