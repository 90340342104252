import { useQuery } from '@tanstack/react-query';

import { HotelItem } from 'app/redux/models/HotelItem/HotelItem';
import { apiRequest } from 'app/utils/request/api';

const path = '/hotels' as const;

export function useAdminHotels() {
  return useQuery({
    queryKey: [path],
    queryFn: () => apiRequest({ path }) as Promise<HotelItem[]>,
    staleTime: Infinity,
  });
}
