import { MenuOutlined } from '@ant-design/icons';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import { ReactNode, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import {
  DragHandle,
  DraggableTable,
} from 'app/components/commons/DraggableTable/DraggableTable';
import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';
import { useEditoEventListParams } from 'app/hooks/data/edito/events/useEditoEventListParams';
import { useUpdateOrderEditoEvents } from 'app/hooks/data/edito/events/useUpdateOrderEditoEvent';
import type {
  EditoEventStatus,
  IEditoEventList,
  IEditoEventListResponse,
} from 'app/typings/edito';
import { formatDateWithoutHour } from 'app/utils/dates';
import { sortEditoEvents } from 'app/utils/edito';

import { EditoLocationTag } from '../../../commons/List/EditoLocationTag/EditoLocationTag';
import { EditoStatusTag } from '../../../commons/List/EditoStatusTag/EditoStatusTag';
import { EditoEventListActions } from '../EditoEventListActions/EditoEventListActions';
import { EditoEventListHeader } from '../EditoEventListHeader/EditoEventListHeader';

import './EditoEventList.scss';

export type EditoEventListProps = {
  editoEvents: IEditoEventList[];
  isFeatured: boolean;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<IEditoEventListResponse[], unknown>>;
  isLoading: boolean;
};

const draggableEditoStatuses: EditoEventStatus[] = ['featured', 'scheduled'];
const featuredEditoStatuses: EditoEventStatus[] = [
  'featured',
  'scheduled',
  'draft',
];

const PLACEHOLDER_PICTURE = 'static/default-picture.jpg';

export const EditoEventList = ({
  editoEvents,
  isFeatured,
  refetch,
  isLoading,
}: EditoEventListProps) => {
  const {
    searchedText,
    selectedClubId,
    selectedCountryId,
    onChangeSearchText,
    onChangeClubId,
    onChangeCountryId,
    selectedStatus,
    onChangeStatus,
  } = useEditoEventListParams();

  const { mutateAsync: updateOrders } = useUpdateOrderEditoEvents();
  const history = useHistory();

  const hasSelectedClub = selectedClubId !== 'all';

  const [countryEventDataSource, clubEventDataSource] = useMemo(() => {
    const countryEditoEvents: IEditoEventList[] = [];
    const clubEditoEvents: IEditoEventList[] = [];

    editoEvents.forEach((event) => {
      const isMatchingCountry = event.countryId === selectedCountryId;
      const isMatchingClub = event.clubId === selectedClubId;

      if (isFeatured ? featuredEditoStatuses.includes(event.status) : true) {
        if (event.clubId) {
          if (hasSelectedClub ? isMatchingClub : isMatchingCountry) {
            clubEditoEvents.push(event);
          }
        } else if (isMatchingCountry) {
          countryEditoEvents.push(event);
        }
      }
    });

    return [
      sortEditoEvents(countryEditoEvents, isFeatured, false),
      sortEditoEvents(clubEditoEvents, isFeatured, !hasSelectedClub),
    ];
  }, [
    editoEvents,
    isFeatured,
    hasSelectedClub,
    selectedClubId,
    selectedCountryId,
  ]);

  const columns = useCallback(
    (isClubTable: boolean): ColumnsType<IEditoEventList> => {
      const isDraggableEditoEvent = (editoStatus: EditoEventStatus) => {
        return (
          draggableEditoStatuses.includes(editoStatus) &&
          isFeatured &&
          (isClubTable ? hasSelectedClub : true)
        );
      };

      return [
        {
          title: 'Name',
          dataIndex: 'title',
          width: '40%',
          render: (title: string, editoEvent: IEditoEventList) => {
            return (
              <div className="cover-cell-emoji">
                <div className="cover-cell__thumbnail editoPicture">
                  <PictureComponent
                    pictureId={
                      editoEvent.picture?.pictureId ?? PLACEHOLDER_PICTURE
                    }
                  />
                </div>
                <div className="cover-cell">{title}</div>
              </div>
            );
          },
          filteredValue: [searchedText],
          onFilter: (
            title: string | number | boolean,
            editoEvent: IEditoEventList
          ) => {
            return editoEvent.title
              .toLowerCase()
              .includes(title.toString().toLowerCase());
          },
        },
        {
          title: 'Location',
          dataIndex: 'countryId',
          width: 140,
          render: (_countryId: number, editoEvent: IEditoEventList) => (
            <EditoLocationTag
              location={editoEvent.clubLabel ?? editoEvent.countryLabel}
            />
          ),
        },
        {
          title: 'Start date',
          dataIndex: 'startDate',
          width: 140,
          render: (startDate?: Date) =>
            startDate && formatDateWithoutHour(new Date(startDate)),
        },
        {
          title: 'End date',
          dataIndex: 'endDate',
          width: 140,
          render: (endDate?: Date) =>
            endDate && formatDateWithoutHour(new Date(endDate)),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          width: 120,
          render: (status: EditoEventStatus) => (
            <EditoStatusTag status={status} />
          ),
          filteredValue: [selectedStatus],
          onFilter: (
            status: string | number | boolean,
            editoEvent: IEditoEventList
          ) => (status === 'all' ? true : editoEvent.status === status),
        },
        {
          width: 100,
          className: 'drag-visible',
          onCell: () => ({ onClick: (e) => e.stopPropagation() }),
          render: (editoEvent: IEditoEventList) => (
            <div className="sortOption">
              {isDraggableEditoEvent(editoEvent.status) ? (
                <DragHandle />
              ) : (
                <MenuOutlined disabled style={{ color: '#E5E5E5' }} />
              )}
              <EditoEventListActions
                editoEvent={editoEvent}
                refetch={refetch}
              />
            </div>
          ),
        },
      ];
    },
    [searchedText, selectedStatus, isFeatured, hasSelectedClub, refetch]
  );

  const onSortEnd = async ({
    oldIndex,
    newIndex,
    dataSource,
    withClubId,
  }: {
    oldIndex: number;
    newIndex: number;
    dataSource: IEditoEventList[];
    withClubId: boolean;
  }) => {
    const draggableDataSource = dataSource.filter((edito) =>
      draggableEditoStatuses.includes(edito.status)
    );

    if (oldIndex !== newIndex) {
      const movingElement = draggableDataSource[oldIndex];

      draggableDataSource.splice(oldIndex, 1);
      draggableDataSource.splice(newIndex, 0, movingElement);

      await updateOrders({
        countryId: selectedCountryId,
        clubId: withClubId && hasSelectedClub ? selectedClubId : undefined,
        ids: draggableDataSource.map((item) => item.id),
      });

      await refetch();
    }
  };

  const header = () => (
    <EditoEventListHeader
      selectedClubId={selectedClubId}
      selectedCountryId={selectedCountryId}
      selectedStatus={selectedStatus}
      onChangeClubId={onChangeClubId}
      onChangeCountryId={onChangeCountryId}
      onChangeStatus={onChangeStatus}
      searchText={searchedText}
      setSearchText={onChangeSearchText}
      isFeatured={isFeatured}
    />
  );

  const onRowClick = (editoEvent: IEditoEventList) => ({
    onClick: () => history.push(`edito-events/${editoEvent.id}/edit`),
  });

  return (
    <div>
      <DraggableTable<IEditoEventList>
        className="editoEventTable"
        onSortEnd={(indexes) =>
          onSortEnd({
            ...indexes,
            dataSource: clubEventDataSource,
            withClubId: true,
          })
        }
        pagination={false}
        title={header}
        dataSource={clubEventDataSource}
        loading={isLoading}
        components={{
          header: {
            row: (props: { children: ReactNode[] }) => (
              <TableTitle
                showHeader
                title={hasSelectedClub ? 'Only to this club' : 'Only by club'}
                {...props}
              />
            ),
          },
        }}
        columns={columns(true)}
        onRow={onRowClick}
      />

      <DraggableTable<IEditoEventList>
        className="editoEventTable"
        onSortEnd={(indexes) =>
          onSortEnd({
            ...indexes,
            dataSource: countryEventDataSource,
            withClubId: false,
          })
        }
        pagination={false}
        dataSource={countryEventDataSource}
        loading={isLoading}
        columns={columns(false)}
        onRow={onRowClick}
        components={{
          header: {
            row: (props: { children: ReactNode[] }) => (
              <TableTitle
                showHeader={false}
                title="All over the country"
                {...props}
              />
            ),
          },
        }}
      />
    </div>
  );
};

interface PropsRow {
  title?: string;
  showHeader: boolean;
  children: ReactNode[];
}

const TableTitle = ({ children, title, showHeader }: PropsRow) => {
  return (
    <>
      {showHeader && (
        <tr>
          {children?.map((child) => {
            return child;
          })}
        </tr>
      )}
      {title && (
        <tr className="ant-table-row">
          <th
            colSpan={6}
            align="left"
            className={classNames([{ borderTopTable: showHeader }])}
          >
            <div className="editoEventTableLocationTr">
              <span className="title">{title}</span>
            </div>
          </th>
        </tr>
      )}
    </>
  );
};
