import { Button } from 'antd';
import { useState } from 'react';

import PreviewModal from 'app/components/pages/Collections/CollectionDetail/CollectionSections/PreviewModal/PreviewModal';
import { usePreviewResults } from 'app/hooks/data/quickFilter/usePreviewResults';
import { useAppSelector } from 'app/redux/hooks';
import { PreviewResult } from 'app/redux/models/PreviewResult/PreviewResult';
import { Tag } from 'app/redux/models/Tag/Tag';
import { selectTagList } from 'app/redux/selectors/tags';
import { isDefined } from 'app/utils/typing';

import { FormSection } from '../../FormSection';

interface Props {
  selectedTagIds: number[];
  isDayPackage?: boolean;
}

export const PreviewSection = ({ isDayPackage, selectedTagIds }: Props) => {
  const [previewResults, setPreviewResults] = useState<PreviewResult[]>([]);
  const [openPreviewResultModal, setOpenPreviewResultModal] = useState(false);
  const [tagsToDisplay, setTagsToDisplay] = useState<Tag[]>([]);
  const tags = useAppSelector(selectTagList);
  const [isLoading, setIsLoading] = useState(false);

  const { getPreviewResults } = usePreviewResults();
  const canPreview = !!selectedTagIds.length && isDefined(isDayPackage);

  const onPreviewResult = async () => {
    if (canPreview) {
      setIsLoading(true);

      const preview = await getPreviewResults(selectedTagIds, isDayPackage);

      const newTags = tags.filter((tag) => selectedTagIds.includes(tag.id));

      setPreviewResults(preview);
      setTagsToDisplay(newTags);
      setIsLoading(false);
      setOpenPreviewResultModal(true);
    }
  };

  return (
    <>
      <FormSection
        title="Preview"
        subTitle="You can preview how many packages results will appear in each club"
      >
        <div>
          <Button
            className="preview-button"
            onClick={onPreviewResult}
            disabled={!canPreview}
            loading={isLoading}
          >
            Preview results
          </Button>
        </div>
      </FormSection>
      <PreviewModal
        previews={previewResults}
        visible={openPreviewResultModal}
        onCancel={() => setOpenPreviewResultModal(false)}
        tags={tagsToDisplay}
      />
    </>
  );
};
