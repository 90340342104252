import { Form, Layout, Switch } from 'antd';
import React, { FC, ReactNode, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import type {
  IExperience,
  IExperienceCategory,
} from 'app/components/commons/Experience/Types';
import { computeExperienceName } from 'app/components/commons/Experience/utils';
import Pictures from 'app/components/commons/Package/Pictures/Pictures';
import { IDiscover, IPackage } from 'app/components/commons/Package/Types';
import UploadList from 'app/components/commons/Uploader/UploadList/UploadList';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { trySaveDiscover } from 'app/redux/actions/packages';

import './Discover.scss';

const { Content } = Layout;

export const Discover: FC<Props> = ({
  pkg,
  headerMenu,
  headerExtra,
  discover,
  experienceCategories,
  packageName,
  experiences,
  submitDiscover,
}) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values: any) => {
      if (pkg) {
        submitDiscover(pkg.id, values);
        setMode('view');
      }
    },
    [pkg, submitDiscover]
  );

  const onCancel = useCallback(() => {
    setMode('view');
  }, []);

  if (!pkg || !discover) {
    return null;
  }

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      initialValues={discover}
      className="discover-form"
    >
      {(values, { resetFields, isFieldsTouched, submit }) => (
        <SDetailLayout
          title={
            packageName && packageName.length
              ? packageName
              : `Package #${pkg.id}`
          }
          mode={mode}
          onEdit={() => setMode('edit')}
          onSave={submit}
          onCancel={onCancel}
          reset={resetFields}
          isDirty={isFieldsTouched()}
          headerMenu={headerMenu}
          headerExtra={headerExtra}
          isValid={form
            .getFieldsError()
            .every((item) => item.errors.length > 0)}
        >
          <Content className="package-detail__content">
            <SCard title="Introduction" className="scard-with-pictures">
              <Form.Item name="introPictures">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="Introduction"
                    hotelId={pkg.hotelId}
                    picturesCategories={[
                      { name: 'Official', type: 'official' },
                      { name: 'Sourcing', type: 'sourcing' },
                    ]}
                    kind="sourcing"
                    draggable
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the intro part"
                  />
                ) : (
                  <UploadList
                    pictures={values.introPictures}
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
            <Form.List name="packageExperiences">
              {(fields) =>
                fields.map((field, idx) => {
                  const experience = values.packageExperiences[idx];

                  if (!experience.discover && mode === 'view') {
                    return null;
                  }

                  const title = computeExperienceName({
                    category: experienceCategories.find(
                      (cat) => cat.id === experience.categoryId
                    ),
                    ...(experiences.find(
                      (exp) => exp.id === experience.id
                    ) as IExperience),
                  });

                  return (
                    <SCard
                      title={title}
                      isExpanded={experience.discover}
                      leftHeader={
                        mode === 'edit' ? (
                          <Form.Item
                            name={[field.name, 'discover']}
                            valuePropName="checked"
                          >
                            <Switch size="small" />
                          </Form.Item>
                        ) : undefined
                      }
                      className="scard-with-pictures"
                    >
                      <Form.Item {...field} name={[field.name, 'pictures']}>
                        {mode === 'edit' ? (
                          <Pictures
                            sectionTitle={title}
                            hotelId={pkg.hotelId}
                            picturesCategories={[
                              { name: 'Official', type: 'official' },
                              { name: 'Sourcing', type: 'sourcing' },
                            ]}
                            kind="sourcing"
                            draggable
                            editable
                            removable
                            modalSubtitle="Select photos or upload new ones to illustrate the experience"
                          />
                        ) : (
                          <UploadList
                            pictures={experience.pictures}
                            uploadingItems={{}}
                            previewable
                          />
                        )}
                      </Form.Item>
                    </SCard>
                  );
                })
              }
            </Form.List>
            <SCard
              title="Rooms"
              className="staycation-card-last scard-with-pictures"
            >
              <Form.Item name="roomPictures">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="Rooms"
                    hotelId={pkg.hotelId}
                    picturesCategories={[
                      { name: 'Photos List', type: 'sourcing' },
                    ]}
                    kind="sourcing"
                    draggable
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the rooms"
                    fetchRooms
                  />
                ) : (
                  <UploadList
                    pictures={values.roomPictures}
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
          </Content>
        </SDetailLayout>
      )}
    </FormLegacyRenderProp>
  );
};

type Props = {
  headerMenu: ReactNode;
  headerExtra: Array<ReactNode>;
  pkg: IPackage | null;
  discover: IDiscover | null;
  experienceCategories: Array<IExperienceCategory>;
  packageName: string;
  packageId: number | undefined;
  experiences: Array<IExperience>;
  expLoading: boolean;
  submitDiscover: (packageId: number, values: any) => void;
};

const mapStateToProps = (
  {
    packages: { package: pkg, discover, copywriting },
    conf: { experienceCategories },
    experiences: { hotelExperiences: experiences, loading: expLoading },
  }: any,
  { packageId }: any
) => ({
  pkg: packageId === pkg?.id ? pkg : null,
  discover: packageId === discover?.packageId ? discover : null,
  experienceCategories,
  packageName: copywriting?.name,
  experiences,
  expLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  submitDiscover: (packageId: any, values: any) =>
    dispatch(trySaveDiscover({ packageId, discover: values })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Discover);
