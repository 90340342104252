import { Button, Empty } from 'antd';
import React, { FC, useCallback } from 'react';
import { SortableContainer, SortableContainerProps } from 'react-sortable-hoc';

import { SCard } from 'app/components/StaycationUI';
import { createDisclaimer } from 'app/redux/models/Disclaimer/Disclaimer';
import type {
  Disclaimer,
  DisclaimerKind,
} from 'app/redux/models/Disclaimer/Disclaimer';

import DisclaimerBloc from '../DisclaimerBloc/DisclaimerBloc';

import './DisclaimerCategory.scss';

type Mode = 'view' | 'edit';

type OnChangeArg = {
  id: number | null;
  text: string;
  startDate: string | null;
  endDate: string | null;
};

type Props = {
  disclaimers: Array<Disclaimer>;
  kind: DisclaimerKind;
  mode: Mode;
  packageId: number;
  onValueChanged: (
    disclaimers: Array<Disclaimer>,
    kind: DisclaimerKind
  ) => void;
};

type ContainerProps = {
  disclaimers: Array<Disclaimer>;
  mode: Mode;
  onChange: (index: number) => (values: OnChangeArg) => void;
  onRemove: (index: number) => () => void;
  onAdd: () => void;
};

export const DisclaimerContainer = ({
  disclaimers,
  mode,
  onChange,
  onRemove,
  onAdd,
}: ContainerProps) => (
  <div className="scard-container">
    {disclaimers.map((disclaimer, index) => (
      <DisclaimerBloc
        index={index}
        key={disclaimer.id || disclaimer.temporaryId}
        id={disclaimer.id}
        priority={disclaimer.priority}
        mode={mode}
        text={disclaimer.text}
        startDate={disclaimer.startDate}
        endDate={disclaimer.endDate}
        temporaryId={disclaimer.temporaryId}
        onChange={onChange(index)}
        onRemove={onRemove(index)}
      />
    ))}
    {mode === 'edit' && (
      <div className="container-button">
        <Button size="middle" onClick={onAdd}>
          + Add
        </Button>
      </div>
    )}
    {mode === 'view' && !disclaimers.length && <Empty />}
  </div>
);

// TODO replace react-sortable-hoc with https://github.com/clauderic/dnd-kit (recommended by react-sortable-hoc documentation)
const SortableDisclaimerContainer = SortableContainer(
  DisclaimerContainer
) as unknown as FC<ContainerProps & SortableContainerProps>;

const DisclaimerCategory = ({
  disclaimers,
  kind,
  mode,
  packageId,
  onValueChanged,
}: Props) => {
  const onChange = useCallback(
    (index: number) => (values: OnChangeArg) => {
      const newDisclaimer = createDisclaimer({
        ...values,
        packageId,
        kind,
      });
      const newDisclaimersValues = [
        ...disclaimers.slice(0, index),
        newDisclaimer,
        ...disclaimers.slice(index + 1, disclaimers.length),
      ];

      onValueChanged(newDisclaimersValues, kind);
    },
    [disclaimers, kind, onValueChanged, packageId]
  );

  const onSort = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      if (oldIndex === newIndex) {
        return;
      }

      const newDisclaimersValues = disclaimers.map((d) => {
        if (d.priority === oldIndex + 1) {
          return { ...d, priority: newIndex + 1 };
        }

        if (d.priority > oldIndex + 1 && d.priority <= newIndex + 1) {
          return { ...d, priority: d.priority - 1 };
        }

        if (d.priority < oldIndex + 1 && d.priority >= newIndex + 1) {
          return { ...d, priority: d.priority + 1 };
        }

        return d;
      });

      onValueChanged(newDisclaimersValues, kind);
    },
    [disclaimers, kind, onValueChanged]
  );

  const onAdd = useCallback(() => {
    const newDisclaimer = createDisclaimer({
      text: '',
      kind,
      packageId,
      priority: disclaimers.length + 1,
    });

    onValueChanged([...disclaimers, newDisclaimer], kind);
  }, [onValueChanged, disclaimers, kind, packageId]);

  const onRemove = useCallback(
    (index: number) => () => {
      const newDisclaimersValues = [
        ...disclaimers.slice(0, index),
        ...disclaimers
          .slice(index + 1, disclaimers.length)
          .map((d) => ({ ...d, priority: d.priority - 1 })),
      ];

      onValueChanged(newDisclaimersValues, kind);
    },
    [disclaimers, kind, onValueChanged]
  );

  const title =
    kind === 'ADVICE' ? 'Advice (Cool to know)' : 'Alert (Attention please)';

  return (
    <SCard title={title}>
      <SortableDisclaimerContainer
        axis="y"
        useDragHandle
        onSortEnd={onSort}
        disclaimers={disclaimers}
        mode={mode}
        onChange={onChange}
        onRemove={onRemove}
        onAdd={onAdd}
      />
    </SCard>
  );
};

export default DisclaimerCategory;
