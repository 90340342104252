import { Modal, Select } from 'antd';
import React, { useMemo, useState } from 'react';

import { tryCreateCustomTag } from 'app/redux/actions/customTags';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { getCustomTags } from 'app/redux/selectors/customTags';
import { cleanString } from 'app/utils/sortAndFilter';

import './NewCustomTagModal.scss';

type Props = {
  closeModal: () => void;
};

type Option = {
  label: string;
  value: number;
  searchKey: string;
};

const NewCustomTagModal = ({ closeModal }: Props) => {
  const dispatch = useAppDispatch();
  const customTags = useAppSelector(getCustomTags);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [search, setSearch] = useState('');
  const [selection, setSelection] = useState<number | null>(null);

  const options: Option[] = useMemo(() => {
    if (!customTags) {
      return [];
    }

    return customTags.map((customTag) => ({
      label: customTag.name,
      value: customTag.id,
      searchKey: cleanString(customTag.name),
    }));
  }, [customTags]);

  const createOption: Option | null = useMemo(() => {
    const cleanSearch = cleanString(search);
    const match = options.find((option) => option.searchKey === cleanSearch);

    if (match || !cleanSearch) {
      return null;
    }

    return {
      label: `Create custom tag: ${search}`,
      value: -1,
      searchKey: '',
    };
  }, [options, search]);

  const fullOptions: Option[] = useMemo(() => {
    if (createOption) {
      return [createOption, ...options];
    }

    return options;
  }, [createOption, options]);

  const onSave = () => {
    dispatch(tryCreateCustomTag({ name: search }));
    closeModal();
  };

  const onFilter = (input: string, option?: Option) => {
    if (!option) {
      return false;
    }

    if (option.value === -1) {
      return true;
    }

    const cleanInput = cleanString(input);

    return option?.searchKey.includes(cleanInput);
  };

  return (
    <Modal
      width={800}
      onOk={onSave}
      okText="Save"
      okButtonProps={{ disabled: saveButtonDisabled }}
      onCancel={closeModal}
      open
      title="Create new custom tag"
      centered
      className="new-custom-tag-modal"
    >
      <Select
        value={selection}
        onChange={(value: number) => setSelection(value)}
        onSearch={(value: string) => {
          setSaveButtonDisabled(true);
          setSearch(value);
          setSelection(null);
        }}
        onSelect={(value: number) => {
          const createSelected = value === -1;

          setSaveButtonDisabled(!createSelected);
        }}
        showSearch
        filterOption={onFilter}
        options={fullOptions}
      />
    </Modal>
  );
};

export default NewCustomTagModal;
