import type { AnyAction } from 'redux';

import * as actions from 'app/redux/actions/hotels';
import type { Hotel } from 'app/redux/models/Hotel/Hotel';
import type { HotelItem } from 'app/redux/models/HotelItem/HotelItem';
import type { NewHotel } from 'app/redux/models/NewHotel/NewHotel';
import { ChannelManagers } from 'app/utils/channelManagers/channelManagers';
import { RateModes } from 'app/utils/channelManagers/rateModes';

export type Hotels = {
  hotel: Hotel | null;
  hotels: Array<HotelItem>;
  newHotel: Partial<NewHotel> | null;
  isLoading: boolean;
};

export const initialState: Hotels = {
  hotel: null,
  hotels: [],
  newHotel: null,
  isLoading: false,
};

function hotels(state = initialState, action: AnyAction): Hotels {
  if (actions.startLoading.match(action)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (actions.stopLoading.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (actions.fetchHotelsSuccess.match(action)) {
    return {
      ...state,
      hotels: action.payload.hotels,
      isLoading: false,
    };
  }

  if (actions.fetchHotelSuccess.match(action)) {
    return {
      ...state,
      hotel: {
        ...action.payload.hotel,
        channelManager:
          action.payload.hotel.channelManager || ChannelManagers.NONE,
        rateMode: action.payload.hotel.rateMode || RateModes.MULTI_RATE,
      },
    };
  }

  if (actions.resetHotel.match(action)) {
    return {
      ...state,
      hotel: null,
    };
  }

  if (actions.updateNewHotel.match(action)) {
    return {
      ...state,
      newHotel: {
        ...state.newHotel,
        ...action.payload.newHotel,
      },
    };
  }

  if (actions.resetNewHotel.match(action)) {
    return {
      ...state,
      newHotel: null,
    };
  }

  return state;
}

export default hotels;
