export const calculatePercentageDecrease = (
  originValue: number,
  valueToCalculate: number
) => {
  const decrease = originValue - valueToCalculate;
  const percentageDecrease = decrease * (100 / valueToCalculate);

  return percentageDecrease;
};

export const roundToDecimal = (
  value: number,
  decimalPlaces: number | undefined = 1
): number => {
  const factor = Math.pow(10, decimalPlaces);

  return Math.round(value * factor) / factor;
};
