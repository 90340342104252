import classNames from 'classnames';
import { format } from 'date-fns';
import React from 'react';

import { Unit } from 'app/typings/analytics';

import {
  DataSuffix,
  formatTooltipDateWithUnit,
  getPercentage,
  renderDataWithSuffix,
} from '../../commons/Utils';
import Badge from '../Badge/Badge';

import './Tooltip.scss';

export const Tooltip = ({
  title,
  items,
  unit,
  hasSuffix,
  hasBadge = false,
  hasColorMarker = false,
  isCardDisplay = false,
  currencyFormatter,
}: {
  title?: string;
  items: ItemsProps[];
  unit: Unit;
  hasSuffix?: DataSuffix;
  hasBadge?: boolean;
  hasColorMarker?: boolean;
  currencyFormatter: (price: number) => string;
  isCardDisplay?: boolean;
}) => {
  const renderDataInformation = (item: ItemsProps) => {
    return (
      <div className="data_container" key={item.text}>
        <div className="text_date">
          <div className="text">
            {hasColorMarker && (
              <div
                className="mapping_color"
                style={{ borderColor: item.color }}
              />
            )}
            {item.text} :
          </div>
          <div
            className={classNames([
              'date',
              {
                addMargin: hasColorMarker,
              },
            ])}
          >
            {!isCardDisplay
              ? formatTooltipDateWithUnit(unit, new Date(item.startDate))
              : `${format(
                  new Date(item.startDate),
                  'eee dd MMM yyyy'
                )} - ${format(new Date(item.endDate), 'eee dd MMM yyyy')}`}
          </div>
        </div>
        <div className="data">
          {renderDataWithSuffix(
            Number(item.value),
            currencyFormatter,
            hasSuffix
          )}
        </div>
      </div>
    );
  };

  const { percentage, type } = getPercentage(
    items[1] ? Number(items[1].value) : 0,
    items[0] ? Number(items[0].value) : 0
  );

  return (
    <div className="tooltip_container">
      {title && (
        <div className="tooltip_header">
          <div className="tooltip_title">{title}</div>
          {hasBadge && Number(items[0].value) > 0 && (
            <Badge value={`${percentage} %`} type={type} />
          )}
        </div>
      )}
      <div className="tooltip_main">
        {items.map((item: ItemsProps) => renderDataInformation(item))}
      </div>
    </div>
  );
};
export default Tooltip;

export type ItemsProps = {
  text?: string;
  color?: string;
  startDate: Date;
  endDate: Date;
  value: string | number;
};
