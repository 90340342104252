import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber } from 'antd';
import { ValidateFields } from 'rc-field-form/lib/interface';
import React, { FC } from 'react';

import { SpaceValueType } from 'app/components/commons/Hotel/Types';
import Pictures from 'app/components/commons/Package/Pictures/Pictures';
import TimeRangeSection from 'app/components/commons/TimeRangeSection/TimeRangeSection';
import UploadList from 'app/components/commons/Uploader/UploadList/UploadList';
import { SpaceType } from 'app/redux/models/SpaceType/SpaceType';

import './Space.scss';

const RULES = [{ required: true, message: 'Required' }];
const DEFAULT_PICTURES_CATEGORIES = [
  { name: 'Official', type: 'official' },
  { name: 'Sourcing', type: 'sourcing' },
];
const DEFAULT_KIND = 'sourcing';

export const Space: FC<Props> = ({
  mode = 'edit',
  values,
  field,
  fieldIndex,
  spaceType,
  setFieldsValue,
  hotelId,
  picturesCategories = DEFAULT_PICTURES_CATEGORIES,
  kind = DEFAULT_KIND,
  validateFields,
  previousPrefixes,
}) => (
  <div className="space">
    {spaceType.floorRequired &&
      (!!values.spaces?.[fieldIndex].floor || mode === 'edit') && (
        <div
          key="floor"
          className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}
        >
          <Form.Item name={[field.name, 'floor']} label="Floor">
            {mode === 'edit' || !values.spaces?.[fieldIndex].floor ? (
              <InputNumber min={-5} placeholder="1, 2, 3, ..." />
            ) : (
              <div className="view-text">{values.spaces[fieldIndex].floor}</div>
            )}
          </Form.Item>
          {mode === 'edit' && (
            <div className="description">
              <InfoCircleOutlined />
              <div>Considering 0 as ground floor</div>
            </div>
          )}
        </div>
      )}
    {spaceType.phoneRequired &&
      (!!values.spaces?.[fieldIndex].phone || mode === 'edit') && (
        <div
          key="phone"
          className={`input-row input-row-small-input ${
            mode === 'view' ? 'input-row-no-rules' : ''
          }`}
        >
          <Form.Item
            name={[field.name, 'phone']}
            label="Direct phone"
            rules={[
              {
                pattern: /^[0-9 .()+-]*$/,
                message:
                  'Invalid phone format, characters authorized : "0-9 . ( ) + -"',
              },
            ]}
          >
            {mode === 'edit' || !values.spaces?.[fieldIndex].phone ? (
              <Input placeholder="555-555-789" />
            ) : (
              <div className="view-text">{values.spaces[fieldIndex].phone}</div>
            )}
          </Form.Item>
        </div>
      )}
    {spaceType.nameRequired &&
      (!!values.spaces?.[fieldIndex].name || mode === 'edit') && (
        <div
          key="name"
          className={`input-row input-row-medium-input ${
            mode === 'view' ? 'input-row-no-rules' : ''
          }`}
        >
          <Form.Item name={[field.name, 'name']} label="Name">
            {mode === 'edit' || !values.spaces?.[fieldIndex].name ? (
              <Input placeholder="Space name" />
            ) : (
              <div className="view-text">{values.spaces[fieldIndex].name}</div>
            )}
          </Form.Item>
        </div>
      )}
    {spaceType.openingHoursRequired && (
      <TimeRangeSection
        key={field.key}
        mode={mode}
        openingHours={values.spaces?.[fieldIndex]?.openingHours}
        field={field}
        setFieldsValue={(openingHours) => {
          setFieldsValue({
            ...values,
            spaces: values.spaces?.map((space, idx) => {
              if (idx === fieldIndex) {
                return {
                  ...space,
                  openingHours,
                };
              }

              return space;
            }),
          });
        }}
        validateFields={validateFields}
        previousPrefixes={previousPrefixes}
      />
    )}
    {(!!values.spaces?.[fieldIndex].pictures?.length || mode === 'edit') && (
      <div
        key="pictures"
        className={`input-row input-row-medium-input ${
          mode === 'view' ? 'input-row-no-rules' : ''
        }`}
      >
        <Form.Item
          name={[field.name, 'pictures']}
          label="Pictures"
          rules={spaceType.picturesRequired ? RULES : undefined}
        >
          {mode === 'edit' ? (
            <Pictures
              key={field.key}
              sectionTitle={spaceType.name}
              hotelId={hotelId}
              picturesCategories={picturesCategories}
              kind={kind}
              editable
              removable
              modalSubtitle="Select photos or upload new ones to illustrate the space"
            />
          ) : (
            <UploadList
              pictures={values.spaces?.[fieldIndex].pictures ?? []}
              uploadingItems={{}}
              previewable
            />
          )}
        </Form.Item>
        {mode === 'edit' && (
          <div className="description">
            <InfoCircleOutlined />
            <div>
              Add as many photos as possible to help us highlight this space
            </div>
          </div>
        )}
      </div>
    )}
  </div>
);

type Props = {
  mode?: string;
  values: SpaceValueType;
  field: any;
  fieldIndex: number;
  spaceType: SpaceType;
  setFieldsValue: (values: SpaceValueType) => void;
  hotelId: number;
  picturesCategories?: Array<{ type: string; name: string }>;
  kind?: string;
  validateFields: ValidateFields;
  previousPrefixes: string[];
};

export default Space;
