import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppSelector } from 'app/redux/hooks';
import { selectClubs } from 'app/redux/selectors/conf';
import { getEditoEventListPath } from 'app/routes/edito';
import { IEditoEventListResponse } from 'app/typings/edito';
import { apiRequest } from 'app/utils/request/api';

import { formatEditoEventListResponseToList } from './format/list';

export const useGetEditoEvents = () => {
  const countries = useAppSelector((state) => state.conf.countries);
  const clubs = useAppSelector(selectClubs);

  const query = useQuery<IEditoEventListResponse[]>({
    queryKey: [getEditoEventListPath],
    queryFn: () =>
      apiRequest({
        path: getEditoEventListPath,
      }),
  });

  const editoEvents = useMemo(() => {
    if (query.data) {
      return formatEditoEventListResponseToList(query.data, countries, clubs);
    }

    return [];
  }, [query.data, countries, clubs]);

  return {
    ...query,
    data: editoEvents,
  };
};
