import {
  addDays,
  eachDayOfInterval,
  format,
  isSunday,
  nextSunday,
  subDays,
} from 'date-fns';
import React, { useState } from 'react';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import { updateDirtyOpening } from 'app/redux/actions/hotelAdmin';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { OpeningForm } from 'app/redux/models/RoomOpening/RoomOpening';
import { getUser } from 'app/redux/selectors/auth';
import {
  selectHotelAdminDirtOpenings,
  selectHotelAdminInventory,
} from 'app/redux/selectors/hotelAdmin';
import { parseNumber } from 'app/utils/typing';

import {
  DuplicationHover,
  DuplicationMode,
  DuplicationModeData,
  Room,
} from '../Types';
import { computePackageValue } from '../Utils';

import BarRow from './BarRow/BarRow';
import BookedRow from './BookedRow/BookedRow';
import DateRow from './DateRow/DateRow';
import DiscountPriceRow from './DiscountPriceRow/DiscountPriceRow';
import { DiscountRateRow } from './DiscountRateRow/DiscountRateRow';
import ForcedRow from './ForcedRow/ForcedRow';
import './RoomRow.scss';
import StatusRow from './StatusRow/StatusRow';
import StockRow from './StockRow/StockRow';

type Props = {
  room: Room;
  currentRange: Interval;
  currentDuplicateHoverRange?: DuplicationHover;
  handleDuplicateHover: (
    roomId: number
  ) => (day: Date, mode: DuplicationModeData, customDay?: Date) => void;
  dropDuplicationHover: () => void;
  autoDiscountRate?: number;
  lastMinuteAutoDiscountRate?: number;
  lastMinuteAutoDiscountDays?: number;
  hasChannelManager: boolean;
  urlTriggerPricingRule: boolean;
};

export const RoomRow = ({
  room,
  currentRange,
  currentDuplicateHoverRange,
  handleDuplicateHover,
  dropDuplicationHover,
  autoDiscountRate,
  lastMinuteAutoDiscountRate,
  lastMinuteAutoDiscountDays,
  hasChannelManager,
  urlTriggerPricingRule,
}: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const inventory = useAppSelector(selectHotelAdminInventory);
  const dirtyOpenings = useAppSelector(selectHotelAdminDirtOpenings);
  const [discountRoomFocus, setDiscountRoomFocus] = useState<
    { date: Date; roomId: number } | undefined
  >();
  const hasRights = user?.role === 'admin' || user?.role === 'superadmin';
  const { track } = useAmplitude();

  const handlePropertyChange = (
    opening: OpeningForm | undefined,
    property: string,
    value: any
  ) => {
    const absolute = Math.abs(parseFloat(value));
    const parsed = parseNumber(absolute);

    if (opening) {
      const newOpening = {
        ...opening,
        [property]: parsed ?? null,
        autoDiscount: false,
      };

      dispatch(updateDirtyOpening({ opening: newOpening, isForced: false }));
    }
  };

  const handleDuplicate = (
    property: 'bar' | 'stock' | 'discountPrice' | 'discountRate',
    value: number,
    day: Date,
    modeData: DuplicationModeData
  ) => {
    const getDatesToFill = (): Interval => {
      switch (modeData.mode) {
        case DuplicationMode.END_OF_WEEK:
          return { start: day, end: nextSunday(subDays(day, 1)) };

        case DuplicationMode.END_OF_NEXT_WEEK:
          if (!isSunday(day)) {
            return { start: day, end: nextSunday(addDays(day, 5)) };
          }

          return { start: day, end: nextSunday(day) };

        case DuplicationMode.END_OF_TABLE:
          return { start: day, end: currentRange.end };
        case DuplicationMode.CUSTOM:
          return { start: day, end: addDays(modeData.customDay, 1) };
        default:
          throw new Error('Unsupported Duplication mode');
      }
    };

    const datesToFill = getDatesToFill();

    eachDayOfInterval(datesToFill).forEach((day) => {
      const opening =
        dirtyOpenings.find(
          (o) =>
            o.date === format(day, 'yyyy-MM-dd') && o.roomId === room.room.id
        ) ||
        room.openings.find(
          (o) =>
            o.date === format(day, 'yyyy-MM-dd') && o.roomId === room.room.id
        ) ||
        ({
          date: format(day, 'yyyy-MM-dd'),
          roomId: room.room.id,
          hotelId: room.room.hotelId,
          stock: 0,
          booked: 0,
          remaining: 0,
          price: 0,
          bar: 0,
          discountPrice: 0,
          isForced: false,
          closed: false,
        } as OpeningForm);

      const absolute = Math.abs(value);

      if (property === 'discountRate') {
        const packageValue = computePackageValue(opening, inventory, day);
        const newDiscountPrice = Math.floor(
          packageValue - (packageValue * absolute) / 100
        );

        dispatch(
          updateDirtyOpening({
            opening: {
              ...opening,
              discountPrice: newDiscountPrice,
            },
            isForced: false,
          })
        );
      } else if (property === 'stock') {
        dispatch(
          updateDirtyOpening({
            opening: {
              ...opening,
              stock: Math.max(value, opening.booked),
            },
            isForced: false,
          })
        );
      } else {
        dispatch(
          updateDirtyOpening({
            opening: {
              ...opening,
              [property]: value,
            },
            isForced: false,
          })
        );
      }
    });

    track('Duplicate line', {
      from: format(datesToFill.start, 'yyyy-MM-dd'),
      to: format(datesToFill.end, 'yyyy-MM-dd'),
      line: property,
      duplicationType: modeData.mode,
    });
  };

  const handleForcedOpening = (
    dirtyOpening: OpeningForm | undefined,
    isForced: boolean
  ) => {
    if (dirtyOpening) {
      dispatch(
        updateDirtyOpening({
          opening: { ...dirtyOpening, published: isForced },
          isForced: true,
        })
      );
    }
  };

  return (
    <div className="extranet-inventory__grid">
      <DateRow
        roomId={room.room.id}
        currentRange={currentRange}
        currentDuplicateHoverRange={currentDuplicateHoverRange}
      />
      <StatusRow currentRange={currentRange} room={room} />
      <StockRow
        currentRange={currentRange}
        room={room}
        handlePropertyChange={handlePropertyChange}
        handleDuplicate={handleDuplicate}
        handleDuplicateHover={handleDuplicateHover(room.room.id)}
        dropDuplicationHover={dropDuplicationHover}
      />
      <BookedRow currentRange={currentRange} room={room} />
      <BarRow
        currentRange={currentRange}
        room={room}
        handlePropertyChange={handlePropertyChange}
        handleDuplicate={handleDuplicate}
        handleDuplicateHover={handleDuplicateHover(room.room.id)}
        dropDuplicationHover={dropDuplicationHover}
      />
      <DiscountPriceRow
        discountRoomFocus={discountRoomFocus}
        currentRange={currentRange}
        room={room}
        handlePropertyChange={handlePropertyChange}
        handleDuplicate={handleDuplicate}
        handleDuplicateHover={handleDuplicateHover(room.room.id)}
        dropDuplicationHover={dropDuplicationHover}
        onFocus={(date, roomId) => setDiscountRoomFocus({ date, roomId })}
        onBlur={() => setDiscountRoomFocus(undefined)}
        autoDiscountRate={autoDiscountRate}
        lastMinuteAutoDiscountRate={lastMinuteAutoDiscountRate}
        lastMinuteAutoDiscountDays={lastMinuteAutoDiscountDays}
      />
      <DiscountRateRow
        discountRoomFocus={discountRoomFocus}
        currentRange={currentRange}
        room={room}
        handlePropertyChange={handlePropertyChange}
        handleDuplicate={handleDuplicate}
        handleDuplicateHover={handleDuplicateHover(room.room.id)}
        dropDuplicationHover={dropDuplicationHover}
        onFocus={(date, roomId) => setDiscountRoomFocus({ date, roomId })}
        onBlur={() => setDiscountRoomFocus(undefined)}
        autoDiscountRate={autoDiscountRate}
        lastMinuteAutoDiscountRate={lastMinuteAutoDiscountRate}
        lastMinuteAutoDiscountDays={lastMinuteAutoDiscountDays}
        hasChannelManager={hasChannelManager}
        urlTriggerPricingRule={urlTriggerPricingRule}
      />
      <ForcedRow
        currentRange={currentRange}
        room={room}
        handleForcedOpening={handleForcedOpening}
        hasRights={hasRights}
      />
    </div>
  );
};

export default RoomRow;
