import { AnyAction } from 'redux';

import { actions } from 'app/redux/actions/pictures';

import { Picture } from '../models/Picture/Picture';

export type Pictures = {
  isLoading: boolean;
  pictures: Picture[];
  emojis: any;
  hotelGallery: any;
  hotelsGalleries: any;
};

const updateHotelGallery = (hotelGallery: any, newPicture: any) => {
  const index = hotelGallery.findIndex((pic: any) => newPicture.id === pic.id);

  if (index < 0) {
    return hotelGallery;
  }

  return [
    ...hotelGallery.slice(0, index),
    newPicture,
    ...hotelGallery.slice(index + 1),
  ];
};

export const initialState: Pictures = {
  isLoading: false,
  pictures: [],
  emojis: [],
  hotelGallery: undefined,
  hotelsGalleries: [],
};

function pictures(state = initialState, action: AnyAction) {
  switch (action.type) {
    case actions.PICTURES_START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case actions.PICTURES_STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case actions.PICTURES_FETCH_HOTEL_PICTURES_SUCCESS:
      return {
        ...state,
        pictures: action.result,
      };
    case actions.PICTURES_FETCH_HOTEL_GALLERY_ROOMS_SUCCESS:
    case actions.PICTURES_FETCH_HOTEL_GALLERY_SUCCESS:
      return {
        ...state,
        hotelGallery: action.result,
      };
    case actions.PICTURES_ADD_HOTEL_PICTURES_SUCCESS:
      return {
        ...state,
        hotelGallery: [...state.hotelGallery, ...action.pictures],
      };
    case actions.PICTURES_EDIT_PICTURE_SUCCESS:
      return {
        ...state,
        hotelGallery: updateHotelGallery(state.hotelGallery, action.picture),
      };
    case actions.PICTURES_REMOVE_HOTEL_PICTURE_SUCCESS:
      return {
        ...state,
        hotelGallery: state.hotelGallery.filter(
          (pic: any) => pic.id !== action.id
        ),
      };
    case actions.PICTURES_FETCH_HOTELS_GALLERIES_SUCCESS:
      return {
        ...state,
        hotelsGalleries: action.result,
        isLoading: false,
      };
    case actions.PICTURES_FETCH_EMOJIS_SUCCESS:
      return {
        ...state,
        emojis: action.result,
      };
    default:
      return state;
  }
}

export default pictures;
