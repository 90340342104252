import { Button, Form, Layout } from 'antd';
import moment from 'moment';
import React from 'react';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import {
  DatePickerRow,
  InputNumberRow,
  InputRow,
  SelectRow,
  SwitchRow,
} from 'app/components/commons/CustomFields/CustomFields';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { trySaveVoucher } from 'app/redux/actions/vouchers';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { Voucher } from 'app/redux/models/Vouchers/Vouchers';
import { getUser } from 'app/redux/selectors/auth';
import { selectCountries } from 'app/redux/selectors/conf';
import { copyToClipboard } from 'app/utils/clipboard';
import { generateCode } from 'app/utils/strings';
import { parseInputNumber } from 'app/utils/typing';

import './Vouchers.scss';

type FormValues = Omit<Voucher, 'startDate' | 'expirationDate'> & {
  startDate: moment.Moment;
  expirationDate: moment.Moment;
};

const { Content } = Layout;

export const Vouchers = () => {
  const initialValues = {
    giftCardType: 'voucher',
    code: generateCode(7),
    minAmount: 0,
    startDate: moment(),
    expirationDate: moment().add(1, 'year'),
  };
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);

  const countries = useAppSelector(selectCountries);

  const onSubmit = (values: FormValues) => {
    if (!user) {
      return;
    }

    const formattedValues =
      values.giftCardType === 'incentive'
        ? {
            ...values,
            startDate: values.startDate.format(),
            expirationDate: values.expirationDate.format(),
            userId: user.id,
          }
        : {
            ...values,
            startDate: moment().format(),
            expirationDate: values.expirationDate.format(),
            maxUse: 1,
            onlyFirstBooking: false,
            userId: user.id,
          };

    copyToClipboard(formattedValues.code);
    dispatch(trySaveVoucher(formattedValues));
  };

  const reset = () => {
    form.resetFields();
    form.setFieldValue('code', generateCode(7));
  };

  return (
    <Layout className="guide-layout">
      <FormLegacyRenderProp
        form={form}
        onFinish={onSubmit}
        initialValues={initialValues}
        onValuesChange={(changedValues) => {
          if (changedValues.giftCardType === 'incentive') {
            form.setFieldsValue({
              startDate: moment(),
              expirationDate: moment().add(3, 'months'),
              onlyFirstBooking: false,
            });
          } else if (changedValues.giftCardType) {
            form.setFieldsValue({
              startDate: moment(),
              expirationDate: moment().add(1, 'year'),
            });
          }
        }}
      >
        {(values, { submit }) => (
          <SDetailLayout
            title="New Voucher"
            mode="edit"
            onSave={submit}
            isDirty
            headerExtra={[
              <Button type="primary" size="large" onClick={reset} key="reset">
                Reset
              </Button>,
            ]}
          >
            <Content className="page-content">
              <SCard title="Voucher details">
                <SelectRow
                  label="Type"
                  name="giftCardType"
                  dataSource={[
                    {
                      id: 'voucher',
                      name: 'Voucher',
                    },
                    {
                      id: 'incentive',
                      name: 'Incentive',
                    },
                    {
                      id: 'internal',
                      name: 'Internal',
                    },
                  ]}
                  required
                />
                <InputNumberRow
                  label="Value"
                  name="value"
                  placeholder="10 €"
                  required
                  min={0}
                  step={5}
                  parser={parseInputNumber}
                />
                <SelectRow
                  label="Country"
                  name="countryId"
                  dataSource={countries}
                  placeholder="France"
                  required
                />
                <InputNumberRow
                  label="Minimum code use amount"
                  name="minAmount"
                  placeholder="75 €"
                  min={0}
                  required
                />
                <InputRow
                  label="Code"
                  name="code"
                  placeholder="ABCDEF"
                  required
                />
                {values.giftCardType === 'incentive' && (
                  <InputNumberRow
                    label="Max use"
                    name="maxUse"
                    placeholder="10"
                    min={1}
                    required
                  />
                )}
                {values.giftCardType === 'incentive' && (
                  <SwitchRow label="1st booking only" name="onlyFirstBooking" />
                )}
                {values.giftCardType === 'incentive' && (
                  <DatePickerRow
                    label="Start date"
                    name="startDate"
                    format="dddd DD MMMM YYYY"
                    required
                  />
                )}
                <DatePickerRow
                  label="Expiration date"
                  name="expirationDate"
                  format="dddd DD MMMM YYYY"
                  required
                />
                <InputRow
                  label="Beneficiary"
                  name="beneficiary"
                  placeholder="Kevin Hutchings"
                  required
                />
              </SCard>
            </Content>
          </SDetailLayout>
        )}
      </FormLegacyRenderProp>
    </Layout>
  );
};

export default Vouchers;
