import { useCallback, useEffect, useMemo } from 'react';

import { useAppSelector } from 'app/redux/hooks';
import {
  selectHotelAdminHotelId,
  selectHotelAdminHotels,
} from 'app/redux/selectors/hotelAdmin';
import { amplitudeTrack } from 'app/utils/track/amplitude';

export const useAmplitude = () => {
  const hotelId = useAppSelector(selectHotelAdminHotelId);
  const hotels = useAppSelector(selectHotelAdminHotels);
  const hotel = hotels.find((h) => h.id === hotelId);

  const track = useCallback(
    (event: string, properties?: any) => {
      if (hotel) {
        amplitudeTrack(event, {
          hotelId,
          channelManager: hotel?.channelManager,
          rateMode: hotel?.rateMode,
          ...properties,
        });
      }
    },
    [hotelId, hotel]
  );

  return useMemo(
    () => ({
      track,
    }),
    [track]
  );
};

export const useAmplitudeViewPage = (properties: any) => {
  const { track } = useAmplitude();
  const hotels = useAppSelector(selectHotelAdminHotels);

  useEffect(() => {
    track('View Page', properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want this to run one time
  }, [hotels]);

  return null;
};
