import { createAction } from '@reduxjs/toolkit';

import { Voucher } from 'app/redux/models/Vouchers/Vouchers';

export type TrySaveVoucherPayload = Voucher;

export const VOUCHERS_TRY_SAVE_VOUCHER = 'VOUCHERS_TRY_SAVE_VOUCHER';

export const trySaveVoucher = createAction<TrySaveVoucherPayload>(
  VOUCHERS_TRY_SAVE_VOUCHER
);
