import React from 'react';

export const PricingRuleModalHeader = () => {
  return (
    <div className="automatedDiscountModalTitle">
      <p className="automatedDiscountModalTitle_title">Default discount</p>
      <p className="automatedDiscountModalTitle_subtitle">
        Set up a standard discount at which your connected stocks will be
        automatically published.
      </p>
    </div>
  );
};
