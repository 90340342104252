import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, InputNumber, Tooltip } from 'antd';
import React, { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { Option, Select } from 'app/components/fields/Select';
import { Experience } from 'app/redux/models/Experience/Experience';
import { ExperienceCategory } from 'app/redux/models/ExperienceCategory/ExperienceCategory';

import CustomPrice from './CustomPrice/CustomPrice';

const Value = ({
  mode = 'edit',
  values,
  category,
  isOriginal,
  setFieldsValue,
}: Props) => {
  const { currencyFormatter, currencyInput } = useContext(CurrencyContext);

  const getOriginalLabelWithTooltip = (label: string, tip: string) => (
    <div>
      {label}{' '}
      <Tooltip title={tip}>
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );

  const costFieldLabel = !!isOriginal
    ? getOriginalLabelWithTooltip(
        'Client rate',
        'Amount added to Staycation rate and removed from hotel invoicing'
      )
    : 'Cost';

  const getViewCostField = () => {
    const originalPriceField = (
      <>
        <Form.Item
          label={getOriginalLabelWithTooltip(
            'Negotiated rate',
            'Amount paid by Staycation to supplier for each item sold'
          )}
        >
          {`${currencyFormatter(values.purchasePrice || 0)}`}
        </Form.Item>
        <Form.Item
          label={getOriginalLabelWithTooltip(
            'Public rate',
            'Amount added to BAR rate'
          )}
        >
          {`${currencyFormatter(values.publicPrice || 0)}`}
        </Form.Item>
      </>
    );
    const priceDescriptionField = ` ${values.priceDescription || ''}`;
    const priceField = (
      <>
        <Form.Item label=" ">
          <div className="FIELD">
            <div>
              {currencyFormatter(values.price || 0)}
              {!!category.priceDescription && priceDescriptionField}
            </div>
          </div>
        </Form.Item>
        {!!isOriginal && originalPriceField}
      </>
    );
    const costField = (
      <div className="input-row">
        <Form.Item label={costFieldLabel} name="price">
          <CustomPrice mode="view" />
        </Form.Item>
        {values.price !== 0 && priceField}
      </div>
    );

    return costField;
  };

  const getViewQuantityField = () => {
    const quantityDescriptionField = ` ${values.quantityDescription || ''}`;
    const quantityField = (
      <div className="input-row">
        <Form.Item label="Quantity">
          <div className="FIELD">
            <div>
              {values.quantity || 0}
              {!!category.quantityDescription && quantityDescriptionField}
            </div>
          </div>
        </Form.Item>
      </div>
    );

    return values.quantity ? quantityField : null;
  };

  const getEditCostField = () => {
    const priceDescriptionField = (
      <Form.Item noStyle name="priceDescription">
        <Select
          showSearch
          relativePopupContainer={(node) => node.parentNode.parentNode}
          value={
            values.priceDescription === null ? 'empty' : values.priceDescription
          }
          onSelect={(priceDescription) => {
            if (priceDescription === 'empty') {
              setFieldsValue({
                ...values,
                price: 0,
                priceDescription: null,
                publicPrice: undefined,
                purchasePrice: undefined,
              });
            }
          }}
        >
          <Option key="empty" value="empty">
            {' '}
          </Option>
          <Option key="per stay" value="per stay">
            per stay
          </Option>
          <Option key="/pers." value="/pers.">
            /pers.
          </Option>
        </Select>
      </Form.Item>
    );
    const originalPriceField = (
      <>
        <div className="input-row">
          <Form.Item
            label={getOriginalLabelWithTooltip(
              'Negotiated rate',
              'Amount paid by Staycation to supplier for each item sold'
            )}
            name="purchasePrice"
          >
            {currencyInput({
              min: 1,
            })}
          </Form.Item>
        </div>
        <div className="input-row">
          <Form.Item
            label={getOriginalLabelWithTooltip(
              'Public rate',
              'Amount added to BAR rate'
            )}
            name="publicPrice"
          >
            {currencyInput({
              min: 1,
            })}
          </Form.Item>
        </div>
      </>
    );

    const priceField = (
      <>
        <div className="input-row">
          <Form.Item label=" ">
            <div className="FIELD">
              <div className="price-kind">
                <Form.Item noStyle name="price">
                  {currencyInput({ min: 0 })}
                </Form.Item>
                {!!category.priceDescription && priceDescriptionField}
              </div>
            </div>
          </Form.Item>
        </div>
        {!!isOriginal && originalPriceField}
      </>
    );

    const costField = (
      <>
        <div className="input-row">
          <Form.Item label={costFieldLabel} name="price">
            <CustomPrice
              mode="edit"
              onChange={(newPrice) => {
                if (newPrice === 0) {
                  setFieldsValue({
                    ...values,
                    price: 0,
                    priceDescription: null,
                    publicPrice: undefined,
                    purchasePrice: undefined,
                  });
                }
              }}
            />
          </Form.Item>
        </div>
        {values.price !== 0 && priceField}
      </>
    );

    return costField;
  };

  const getEditQuantityField = () => {
    const quantityDescriptionField = (
      <Form.Item noStyle name="quantityDescription">
        <Select
          showSearch
          relativePopupContainer={(node) => node.parentNode.parentNode}
          value={
            values.durationDescription === null
              ? 'empty'
              : values.durationDescription
          }
          onSelect={(quantityDescription) => {
            if (quantityDescription === 'empty') {
              setFieldsValue({
                ...values,
                quantity: null,
                quantityDescription: null,
              });
            }
          }}
        >
          <Option key="empty" value="empty">
            {' '}
          </Option>
          <Option key="per pers" value="per pers">
            per pers
          </Option>
          <Option key="per stay" value="per stay">
            per stay
          </Option>
        </Select>
      </Form.Item>
    );
    const quantityField = (
      <div className="input-row">
        <Form.Item label="Quantity">
          <div className="FIELD">
            <div className="not-price-kind">
              <Form.Item noStyle name="quantity">
                <InputNumber
                  min={1}
                  onChange={(quantity) => {
                    if (quantity === null) {
                      setFieldsValue({
                        ...values,
                        quantity: null,
                        quantityDescription: null,
                      });
                    }
                  }}
                />
              </Form.Item>
              {!!category.quantityDescription && quantityDescriptionField}
            </div>
          </div>
        </Form.Item>
      </div>
    );

    return quantityField;
  };

  const view = mode === 'view';
  const costField = view ? getViewCostField() : getEditCostField();
  const quantityField = view ? getViewQuantityField() : getEditQuantityField();

  return (
    <>
      {!!category.price && costField}
      {!!category.quantity && quantityField}
    </>
  );
};

type Props = {
  mode?: string;
  values: Experience;
  category: ExperienceCategory;
  isOriginal: boolean;
  setFieldsValue: (newValues: Experience) => void;
};

export default Value;
