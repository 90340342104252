import { Rule } from 'antd/lib/form';

import { requiredRule } from 'app/utils/rules';

import { TranslationSectionForm } from './TranslationSection';

const MAX_CHARACTERS_SLICE_TITLE = 30;

const formLabelName = [
  {
    label: 'English name',
    name: 'sliceTitleEn',
    placeholder: 'ex. The best pools',
  },
  {
    label: 'French name',
    name: 'sliceTitleFr',
    placeholder: 'ex. Les meilleures piscines',
  },
  {
    label: 'Spanish name',
    name: 'sliceTitleEs',
    placeholder: 'ex. Las mejores piscinas',
  },
];

const validateFirstLetterUppercase: Rule = {
  validator(_, value) {
    if (!value || /^[A-Z].*/.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error('The first character should be in uppercase.')
    );
  },
};

const rules: Rule[] = [
  requiredRule,
  {
    max: MAX_CHARACTERS_SLICE_TITLE,
    message: `This field should contain at most ${MAX_CHARACTERS_SLICE_TITLE} characters.`,
  },
  validateFirstLetterUppercase,
];

export const SliceTitleTranslationSection = () => {
  return (
    <TranslationSectionForm
      title="Slice name"
      properties={formLabelName}
      rules={rules}
    />
  );
};
