import formatDate from 'date-fns/format';
import React from 'react';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';
import DefaultProfile from 'app/components/commons/icons/DefaultProfile';
import { useAppSelector } from 'app/redux/hooks';
import { GiftCardDetails } from 'app/redux/models/GiftCard/GiftCard';
import { getCurrencyFromCountrySlug } from 'app/redux/selectors/countries';
import { formatCurrency } from 'app/utils/strings';

type Props = {
  giftCardDetails: GiftCardDetails;
};

const Sender: FC<Props> = ({ giftCardDetails }: Props) => {
  const { giftCards, staycations, user: sender } = giftCardDetails;
  const {
    gender,
    firstName,
    lastName,
    id: senderId,
    avatarPath,
    birthDate,
    countrySlug,
  } = sender;
  const currency = useAppSelector((state) =>
    getCurrencyFromCountrySlug(countrySlug)(state)
  );

  const getUserName = () => {
    if (!firstName || !lastName) {
      return `#${senderId}`;
    }

    return gender === undefined || gender === 'Other'
      ? `${firstName} ${lastName}`
      : `${gender} ${firstName} ${lastName}`;
  };

  const amount = staycations.reduce((a, b) => a + b, 0);

  const myStaycations = [
    `${staycations.length} booking${staycations.length > 0 ? 's' : ''}`,
    `${giftCards.length} card${giftCards.length > 0 ? 's' : ''}`,
    `${formatCurrency(amount, currency)}`,
  ].join(' • ');

  return (
    <div className="bloc">
      <div className="title">Sender</div>
      <div className="user-content">
        {avatarPath ? (
          <PictureComponent
            className="avatar"
            style={{ borderRadius: '50%' }}
            pictureId={avatarPath}
          />
        ) : (
          <DefaultProfile />
        )}
        <div className="details">
          <div className="name">
            <Link
              className="link"
              to={`/users/${senderId}/edit`}
              target="_blank"
            >
              {getUserName()}
            </Link>
          </div>
          {!!birthDate && (
            <div className="birth-date">
              {formatDate(new Date(birthDate), 'dd/MM/yyy ')}
            </div>
          )}
          <div className="staycations">{myStaycations}</div>
        </div>
      </div>
    </div>
  );
};

export default Sender;
