import { Collapse, CollapseProps } from 'antd';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { SortableContainer, SortableContainerProps } from 'react-sortable-hoc';

type SortableCollapseProps = SortableContainerProps &
  CollapseProps & {
    activeKey: string[];
    onToggle: (key: string[]) => void;
    children: ReactNode;
    className?: string;
  };

const SortableCollapse = SortableContainer(
  ({ activeKey, onToggle, children, className }: SortableCollapseProps) => (
    <Collapse
      activeKey={activeKey}
      bordered={false}
      // We can't use a generic to improve typing here.
      // TODO replace react-sortable-hoc with dnd-kit (recommended by react-sortable-hoc documentation).
      // https://github.com/clauderic/react-sortable-hoc
      onChange={onToggle as ((key: string | string[]) => void) | undefined}
      className={classNames('sortable-collapse', className)}
    >
      {children}
    </Collapse>
  )
  // TODO replace react-sortable-hoc with https://github.com/clauderic/dnd-kit (recommended by react-sortable-hoc documentation)
) as unknown as FC<SortableCollapseProps>;

export default SortableCollapse;
