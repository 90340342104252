import { createAction } from '@reduxjs/toolkit';

import { CommercialGesture } from 'app/redux/models/CommercialGestures/CommercialGesture';
import { GestureListItem } from 'app/redux/models/CommercialGestures/CommercialGestureListItem';
import { Hotel } from 'app/redux/models/Hotel/Hotel';

export type TryFetchGesturesPayload = {
  search?: string;
  sortingColumn?: string;
  sortingOrder?: string;
  offset?: number;
  limit?: number;
};

type FetchGesturesSuccessPayload = {
  gestures: Array<GestureListItem>;
};

type FetchHotelsSuccessPayload = {
  hotels: Array<Hotel>;
};

export type TrySaveGesturePayload = {
  gesture: CommercialGesture;
};

export const GESTURES_START_LOADING = 'GESTURES_START_LOADING';
export const GESTURES_STOP_LOADING = 'GESTURES_STOP_LOADING';

export const GESTURES_TRY_FETCH_GESTURES = 'GESTURES_TRY_FETCH_GESTURES';
export const GESTURES_FETCH_GESTURES_SUCCESS =
  'GESTURES_FETCH_GESTURES_SUCCESS';
export const GESTURES_FETCH_GESTURES_FAILURE =
  'GESTURES_FETCH_GESTURES_FAILURE';

export const GESTURES_TRY_FETCH_HOTELS = 'GESTURES_TRY_FETCH_HOTELS';
export const GESTURES_FETCH_HOTELS_SUCCESS = 'GESTURES_FETCH_HOTELS_SUCCESS';
export const GESTURES_FETCH_HOTELS_FAILURE = 'GESTURES_FETCH_HOTELS_FAILURE';

export const GESTURES_TRY_SAVE_GESTURE = 'GESTURES_TRY_SAVE_GESTURE';
export const GESTURES_SAVE_GESTURE_SUCCESS = 'GESTURES_SAVE_GESTURE_SUCCESS';
export const GESTURES_SAVE_GESTURE_FAILURE = 'GESTURES_SAVE_GESTURE_FAILURE';

export const startLoading = createAction(GESTURES_START_LOADING);
export const stopLoading = createAction(GESTURES_STOP_LOADING);

export const tryFetchGestures = createAction<TryFetchGesturesPayload>(
  GESTURES_TRY_FETCH_GESTURES
);
export const fetchGesturesSuccess = createAction<FetchGesturesSuccessPayload>(
  GESTURES_FETCH_GESTURES_SUCCESS
);
export const fetchGesturesFailure = createAction(
  GESTURES_FETCH_GESTURES_FAILURE
);

export const tryFetchHotels = createAction(GESTURES_TRY_FETCH_HOTELS);
export const fetchHotelsSuccess = createAction<FetchHotelsSuccessPayload>(
  GESTURES_FETCH_HOTELS_SUCCESS
);

export const fetchHotelsFailure = createAction(GESTURES_FETCH_HOTELS_FAILURE);

export const trySaveGesture = createAction<TrySaveGesturePayload>(
  GESTURES_TRY_SAVE_GESTURE
);
export const saveGesturesSuccess = createAction(GESTURES_SAVE_GESTURE_SUCCESS);
export const saveGestureFailure = createAction(GESTURES_SAVE_GESTURE_FAILURE);
