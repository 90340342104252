import { InfoCircleOutlined } from '@ant-design/icons';

import './BannerInfo.scss';

type Props = {
  title: string;
  subTitle?: string;
};

export const BannerInfo = ({ title, subTitle }: Props) => {
  return (
    <div className="alertSlugContainer">
      <div className="iconContainer">
        <InfoCircleOutlined className="icon" />
      </div>
      <div className="textContainer">
        <span className="title">{title}</span>
        {subTitle && <span className="subTitle">{subTitle}</span>}
      </div>
    </div>
  );
};
