import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Pic from 'app/components/commons/Pic';
import { Option, Select } from 'app/components/fields/Select';
import { altRoomName } from 'app/utils/strings';

// TODO: Picture Option can be isolated into a component but wait for https://bit.ly/2Ug8KD0
// TODO: Select entities should be straightforward
const RoomSelect = ({ rooms, value, onSelect }) => (
  <Select
    className="select"
    onSelect={onSelect}
    placeholder="Select a room from the list"
    value={value}
  >
    {rooms.map((room) => (
      <Option
        key={room.id}
        label={altRoomName(room.categoryName, room.name)}
        value={room.id}
        className="select-item"
      >
        <div className="select-item__thumbnail">
          <Pic {...get(room, 'pictures[0]')} />
        </div>
        <div className="select-item__name">{room.name}</div>
      </Option>
    ))}
  </Select>
);

RoomSelect.propTypes = {
  rooms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    })
  ),
  value: PropTypes.number,
  onSelect: PropTypes.func,
};

export default RoomSelect;
