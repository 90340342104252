import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Task,
  TaskName,
  TaskSource,
} from 'app/components/pages/Extranet/commons/TaskCenter/Types';
import { useTaskCenter } from 'app/components/pages/Extranet/commons/Utils';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import { useAppSelector } from 'app/redux/hooks';
import { selectHotelAdminHotelId } from 'app/redux/selectors/hotelAdmin';

type TaskCenterContextType = {
  isTaskCenterOpen: boolean;
  openTaskCenter: () => void;
  closeTaskCenter: () => void;
  missingTask: Task[];
  doneTask: Task[];
  setOpenTask: (taskName: TaskName | undefined) => void;
  clickOpenTask: (taskInfo: Task, source: TaskSource) => void;
  openTask?: TaskName;
  clearOpenTask: () => void;
  setPage: (page: string) => void;
  page: string;
};

export const TaskCenterContext = React.createContext<TaskCenterContextType>({
  isTaskCenterOpen: false,
  openTaskCenter: () => '',
  closeTaskCenter: () => '',
  missingTask: [],
  doneTask: [],
  openTask: undefined,
  setOpenTask: () => '',
  clickOpenTask: () => '',
  clearOpenTask: () => '',
  setPage: () => '',
  page: '',
});

export const TaskCenterProvider = ({ children }: Props) => {
  const { track } = useAmplitude();
  const history = useHistory();
  const location = useLocation();
  const hotelId = useAppSelector(selectHotelAdminHotelId);

  const [isTaskCenterOpen, setIsTaskCenterOpen] = useState(false);
  const [openTask, setOpenTask] = useState<TaskName | undefined>(undefined);

  const [page, setPage] = useState<string>('');

  const openTaskCenter = () => setIsTaskCenterOpen(true);
  const closeTaskCenter = () => setIsTaskCenterOpen(false);

  const clearOpenTask = () => setOpenTask(undefined);

  const clickOpenTask = (taskInfo: Task, source: TaskSource) => {
    track('Open Task', {
      from: page,
      status: taskInfo.value === taskInfo.max ? 'done' : 'undone',
      taskName: taskInfo.taskName,
      source,
    });

    if (
      taskInfo.redirect &&
      !location.pathname.includes(`/extranet/${taskInfo.redirect}`)
    ) {
      history.replace({
        pathname: `/extranet/${taskInfo.redirect}`,
        search: `?hotelId=${hotelId}`,
      });
    }

    setOpenTask(taskInfo.taskName);
  };

  const { missingTask, doneTask } = useTaskCenter();

  return (
    <TaskCenterContext.Provider
      value={{
        isTaskCenterOpen,
        openTaskCenter,
        closeTaskCenter,
        missingTask,
        doneTask,
        openTask,
        setOpenTask,
        clickOpenTask,
        clearOpenTask,
        setPage,
        page,
      }}
    >
      {children}
    </TaskCenterContext.Provider>
  );
};

type Props = {
  children: React.ReactNode;
};

export default TaskCenterProvider;
