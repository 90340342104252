import { ColProps } from 'antd';
import { ReactNode } from 'react';

import './SubFormSection.scss';

export const colSizes: ColProps = {
  lg: 8,
  md: 12,
  sm: 24,
  xs: 24,
};
export const SubFormSection = ({
  children,
  title,
  subTitle,
  className,
}: {
  children: ReactNode;
  title: string;
  subTitle?: string;
  className?: string;
}) => {
  return (
    <div className={className}>
      <div className="formSectionTitle">{title}</div>
      {subTitle && <div className="sub-title">{subTitle}</div>}
      <div className="content">{children}</div>
    </div>
  );
};
