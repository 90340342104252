import React from 'react';

import { Option, Select } from 'app/components/fields/Select';
import type { Experience } from 'app/redux/models/Experience/Experience';
import type { Space } from 'app/redux/models/Space/Space';
import { SpaceType } from 'app/redux/models/SpaceType/SpaceType';

export const NOT_APPLICABLE_SPACE_ID = -1;

const SpacesSelect = ({
  value = [],
  onChange,
  hotelSpaces,
  spaceTypes,
  mode,
  notApplicableOption = false,
  setFieldsValue,
}: Props) => {
  const displayNotApplicableOption =
    notApplicableOption &&
    (value.length === 0 || value.includes(NOT_APPLICABLE_SPACE_ID));

  return (
    <Select
      value={value}
      onSelect={(selected) => {
        if (
          selected !== NOT_APPLICABLE_SPACE_ID &&
          value.includes(NOT_APPLICABLE_SPACE_ID)
        ) {
          setFieldsValue({
            spaceIds: [
              ...value.filter((v) => v !== NOT_APPLICABLE_SPACE_ID),
              selected,
            ],
          });
        }
      }}
      onChange={(newValue) => {
        if (onChange) {
          onChange(newValue);
        }
      }}
      showSearch
      mode="multiple"
      allowClear
      disabled={mode === 'view'}
    >
      {hotelSpaces &&
        hotelSpaces.map((space) => {
          const spaceTypeName =
            spaceTypes.find((spaceType) => spaceType.id === space.spaceTypeId)
              ?.name || '';
          const spaceName = space.name ? `(${space.name})` : '';

          return (
            <Option key={space.id} value={space.id}>
              {`${spaceTypeName} ${spaceName}`}
            </Option>
          );
        })}
      {displayNotApplicableOption && <Option value={-1}>Not applicable</Option>}
    </Select>
  );
};

type Props = {
  value?: number[];
  onChange?: (newValue: number[]) => void;
  hotelSpaces: Space[];
  spaceTypes: SpaceType[];
  mode?: string;
  notApplicableOption?: boolean;
  setFieldsValue: (values: Partial<Experience>) => void;
};

export default SpacesSelect;
