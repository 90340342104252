import { message } from 'antd';
import { push } from 'connected-react-router';
import { all, put, takeEvery } from 'redux-saga/effects';

import * as hotelsActions from 'app/redux/actions/hotels';
import { createHotel } from 'app/redux/models/Hotel/Hotel';
import {
  HotelItem,
  createHotelItem,
} from 'app/redux/models/HotelItem/HotelItem';
import {
  del,
  get,
  post,
  requestGenerator,
  put as rput,
} from 'app/redux/requests';
import { ChannelManagers } from 'app/utils/channelManagers/channelManagers';

export function* tryFetchHotel(
  action: ReturnType<typeof hotelsActions.tryFetchHotel>
) {
  const { hotelId } = action.payload;

  yield requestGenerator(
    () => put(hotelsActions.startLoading()),
    () => get(`hotels/${hotelId}`),
    (result: any) => {
      const hotel = createHotel(result);

      return put(hotelsActions.fetchHotelSuccess({ hotel }));
    },
    () => {
      message.error('Error fetching Hotel');

      return put(hotelsActions.stopLoading());
    }
  );
}

export function* tryFetchHotels() {
  yield requestGenerator(
    () => put(hotelsActions.startLoading()),
    () => get('hotels'),
    (result: Array<any>) => {
      const hotels = result.map<HotelItem>((r) => createHotelItem(r));

      return put(hotelsActions.fetchHotelsSuccess({ hotels }));
    },
    () => {
      message.error('Error fetching Hotels');

      return put(hotelsActions.stopLoading());
    }
  );
}

export function* trySaveHotel(
  action: ReturnType<typeof hotelsActions.trySaveHotel>
) {
  const { hotel } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () =>
      post('hotels', {
        ...hotel,
        channelManager:
          hotel.channelManager === ChannelManagers.NONE
            ? undefined
            : hotel.channelManager,
      }),
    (result: any) => {
      message.success('Hotel successfully saved!');

      const updatedHotel = createHotel(result);

      return put(hotelsActions.fetchHotelSuccess({ hotel: updatedHotel }));
    },
    () => message.error('Error saving Hotel'),
    { expectJson: true }
  );
}

export function* trySaveHotelAndRedirect(
  action: ReturnType<typeof hotelsActions.trySaveHotelAndRedirect>
) {
  const { hotel } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => post('hotels', hotel),
    () =>
      all([message.success('Hotel successfully saved!'), put(push('/hotels'))]),
    () => message.error('Error saving Hotel'),
    { expectJson: true }
  );
}

export function* tryDeleteHotel(
  action: ReturnType<typeof hotelsActions.tryDeleteHotel>
) {
  const { hotelId } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => del(`hotels/${hotelId}`),
    () =>
      all([message.success(`Hotel ${hotelId} deleted`), put(push('/hotels'))]),
    () => message.error('Not possible: this hotel is used somewhere else')
  );
}

export function* tryDuplicateHotel(
  action: ReturnType<typeof hotelsActions.tryDuplicateHotel>
) {
  const { hotelId } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => post(`hotels/${hotelId}/duplicate`),
    (result: any) =>
      all([
        message.success(`Hotel ${hotelId} duplicated, redirecting to copy`),
        put(hotelsActions.tryFetchHotel({ hotelId: result.hotelId })),
        put(push(`/hotels/${result.hotelId}/edit`)),
      ]),
    () => message.error('Failed to duplicate hotel')
  );
}

export function* trySaveBrief(
  action: ReturnType<typeof hotelsActions.trySaveBrief>
) {
  const { hotelId, brief } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => rput(`hotels/${hotelId}/brief`, brief),
    () =>
      all([
        put(hotelsActions.saveBriefSuccess()),
        put(hotelsActions.tryFetchHotel({ hotelId })),
      ]),
    () => message.error('Error posting Brief'),
    { expectJson: false }
  );
}

export function* trySaveSpaces(
  action: ReturnType<typeof hotelsActions.trySaveSpaces>
) {
  const { hotelId, spaces } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => rput(`hotels/${hotelId}/spaces`, spaces),
    () =>
      all([
        put(hotelsActions.saveSpacesSuccess()),
        put(hotelsActions.tryFetchHotel({ hotelId })),
      ]),
    () => message.error('Error posting Spaces'),
    { expectJson: false }
  );
}

export function* trySaveServices(
  action: ReturnType<typeof hotelsActions.trySaveServices>
) {
  const { hotelId, services } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => rput(`hotels/${hotelId}/services`, services),
    () => put(hotelsActions.tryFetchHotel({ hotelId })),
    () => message.error('Error updating Hotel Services'),
    { expectJson: false }
  );
}

export default function* hotelsSaga() {
  yield all([
    takeEvery(hotelsActions.HOTELS_TRY_FETCH_HOTELS, tryFetchHotels),
    takeEvery(hotelsActions.HOTELS_TRY_FETCH_HOTEL, tryFetchHotel),
    takeEvery(hotelsActions.HOTELS_TRY_SAVE_HOTEL, trySaveHotel),
    takeEvery(
      hotelsActions.HOTELS_TRY_SAVE_HOTEL_AND_REDIRECT,
      trySaveHotelAndRedirect
    ),
    takeEvery(hotelsActions.HOTELS_TRY_DELETE_HOTEL, tryDeleteHotel),
    takeEvery(hotelsActions.HOTELS_TRY_DUPLICATE_HOTEL, tryDuplicateHotel),
    takeEvery(hotelsActions.HOTELS_TRY_SAVE_BRIEF, trySaveBrief),
    takeEvery(hotelsActions.HOTELS_TRY_SAVE_SPACES, trySaveSpaces),
    takeEvery(hotelsActions.HOTELS_TRY_SAVE_SERVICES, trySaveServices),
  ]);
}
