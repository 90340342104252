import { select } from 'redux-saga/effects';

// TODO: create a class like Req.get, Req.put to avoid confusion with sagas' methods (and lodash's get)
export const JSON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const tokenToHeader = (token: string) => ({
  Authorization: `Bearer ${token}`,
});

function* makeRequest(method: any, endpoint: any, body = null): any {
  const token = yield select((state) => state.auth.token);
  const apiUrl = yield select((state) => state.navigation.apiUrl);

  const url = `${apiUrl}/${endpoint}`;

  const headers = {
    ...(method !== 'GET' ? JSON_HEADERS : {}),
    ...(token ? tokenToHeader(token) : {}),
  };

  const params = {
    headers,
    method,
    ...(body ? { body: JSON.stringify(body) } : {}),
  };

  return yield fetch(url, params);
}

export const get = (endpoint: string) => makeRequest('GET', endpoint);
export const post = (endpoint: string, body?: any) =>
  makeRequest('POST', endpoint, body);
export const put = (endpoint: string, body?: any) =>
  makeRequest('PUT', endpoint, body);
export const patch = (endpoint: string, body?: any) =>
  makeRequest('PATCH', endpoint, body);
export const del = (endpoint: string) => makeRequest('DELETE', endpoint);

type RequestGeneratorOptions = {
  expectJson?: boolean;
  expectFile?: boolean;
};

export function* requestGenerator(
  startEffectFn: any,
  requestFunction: any,
  successEffectFn: any,
  failureEffectFn: any,
  options: RequestGeneratorOptions = {
    expectJson: true,
    expectFile: false,
  }
): any {
  /*
   * Helper to factorize API call logic in sagas
   * startEffectFn: Function that marks the start of the request
   * requestFunction: Function that makes the request and return a Promise
   * successEffectFn: Function that returns a sagas effect called with the response
   * failureEffectFn: Function that returns a sagas effect called if the request fails
   */
  try {
    yield startEffectFn();

    const response = yield requestFunction();

    if (!response.ok) {
      const error = yield response.text();

      yield failureEffectFn(error, response.status);

      return error;
    }

    if (response.status === 204) {
      yield successEffectFn(null, response);
    } else if (options.expectFile) {
      const data = yield response.blob();

      yield successEffectFn(data, response);
    } else if (options.expectJson) {
      const data = yield response.json();

      yield successEffectFn(data, response);

      return data;
    } else {
      const text = yield response.text();

      yield successEffectFn(text, response);

      return text;
    }

    return true;
  } catch (e) {
    console.log('Error calling API', e);
    yield failureEffectFn(e);
  }

  return false;
}
