import { message } from 'antd';
import {
  setSubmitFailed,
  setSubmitSucceeded,
  startSubmit,
  stopSubmit,
} from 'redux-form';
import { all, put, takeEvery } from 'redux-saga/effects';

import * as entitiesActions from 'app/redux/actions/entities';
import * as salesActions from 'app/redux/actions/sales';
import { patch, requestGenerator } from 'app/redux/requests';

export function* toggleSaleOpen({ saleId }) {
  yield requestGenerator(
    () =>
      all([
        put(startSubmit('sale')),
        put(entitiesActions.startPatchEntity('sales')),
      ]),
    () => patch(`sales/${saleId}/open`),
    (result) =>
      all([
        message.success(
          `${result.isOpen ? 'Opened' : 'Closed'} sale #${saleId}`
        ),
        put(stopSubmit('sale')),
        put(setSubmitSucceeded('sale')),
        put(entitiesActions.patchEntitySuccess('sales')),
      ]),
    (error) =>
      all([
        put(stopSubmit('sale')),
        put(setSubmitFailed('sale')),
        message.error(`Failed to toggle sale #${saleId} opening!`),
        put(entitiesActions.patchEntityFailure('sales', error)),
      ])
  );
}

export default function* salesSagas() {
  yield all([
    takeEvery(salesActions.actions.TRY_TOGGLE_SALE_OPEN, toggleSaleOpen),
  ]);
}
