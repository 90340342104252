import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';
import type { Room as RoomType } from 'app/components/commons/Room/Types';

import type { BedCategory } from '../../../../HotelBuilder/Types';

import './Room.scss';

const BABY_COT_BED_CATEGORY_ID = 3;

type RoomProps = {
  index: number;
  room: RoomType;
  bedCategories: BedCategory[];
};

export const Room = ({ index, room, bedCategories }: RoomProps) => {
  const getBedNameById = (bedCategoryId?: number) =>
    bedCategories.find((b) => b.id === bedCategoryId)?.name ?? '';

  const getBedAdultCapacityById = (bedCategoryId?: number) =>
    bedCategories.find(
      (b) =>
        b.id === bedCategoryId &&
        b.id !== BABY_COT_BED_CATEGORY_ID &&
        !b.type?.includes('kid')
    )?.capacity ?? 0;

  const bedNames = room.beds
    .sort((bedA, bedB) => {
      if (bedA.priority && bedB.priority) {
        return bedA.priority - bedB.priority;
      }

      if (bedA.included) {
        return -1;
      }

      if (bedB.included) {
        return 1;
      }

      return 0;
    })
    .map((bed) => getBedNameById(bed.categoryId))
    .join(' · ');

  const roomMaxCapacity = room.beds.reduce(
    (capacity, bed) => capacity + getBedAdultCapacityById(bed.categoryId),
    0
  );

  return (
    <div className="room" key={index}>
      <PictureComponent
        className="room_image"
        pictureId={room.pictures[0].pictureId}
      />
      <div className="room_text">
        <div className="title">{room.name || room.categoryName}</div>
        <div className="description">
          {room.categoryName} · {roomMaxCapacity} adult guests
        </div>
        <div className="bed">{bedNames}</div>
      </div>
    </div>
  );
};
