import { createAction } from '@reduxjs/toolkit';

import { FormType } from 'app/components/pages/Users/details/Modals/EditUserModal';
import { UserDetail, UserList } from 'app/redux/models/Users/UserDetail';

type UserTryFetchDetail = {
  id: number;
};

type UserFetchDetailSuccess = {
  detail: UserDetail;
};

type UserTrySaveUser = {
  id: number;
  data: FormType;
};

type UserSaveUserSuccess = {
  detail: UserDetail;
};

type UserTryDeleteUser = {
  id: number;
};

type UserDeleteUserSuccess = {
  detail: UserDetail;
};

export type UserTryFetchUsers = {
  offset: number;
  search?: string;
  sorting?: string;
  roles: string[];
  clubs: string[];
};

type UserFetchUsersSuccess = {
  users: UserList[];
};

type UserFetchUsersError = {
  error: string;
};

export const USER_START_LOADING = 'USER_START_LOADING';
export const USER_STOP_LOADING = 'USER_STOP_LOADING';
export const USER_TRY_FETCH_DETAIL = 'USER_TRY_FETCH_DETAIL';
export const USER_FETCH_DETAIL_SUCCESS = 'USER_FETCH_DETAIL_SUCCESS';
export const USER_TRY_SAVE_USER = 'USER_TRY_SAVE_USER';
export const USER_SAVE_USER_SUCCESS = 'USER_SAVE_USER_SUCCESS';
export const USER_TRY_DELETE_USER = 'USER_TRY_DELETE_USER';
export const USER_DELETE_USER_SUCCESS = 'USER_DELETE_USER_SUCCESS';
export const USER_TRY_FETCH_USERS = 'USER_TRY_FETCH_USERS';
export const USER_FETCH_USERS_SUCCESS = 'USER_FETCH_USERS_SUCCESS';
export const USER_FETCH_USERS_ERROR = 'USER_FETCH_USERS_ERROR';

export const startLoading = createAction(USER_START_LOADING);
export const stopLoading = createAction(USER_STOP_LOADING);

export const tryFetchDetail = createAction<UserTryFetchDetail>(
  USER_TRY_FETCH_DETAIL
);
export const fetchDetailSuccess = createAction<UserFetchDetailSuccess>(
  USER_FETCH_DETAIL_SUCCESS
);

export const trySaveUser = createAction<UserTrySaveUser>(USER_TRY_SAVE_USER);
export const saveUserDetailSuccess = createAction<UserSaveUserSuccess>(
  USER_SAVE_USER_SUCCESS
);

export const tryDeleteUser =
  createAction<UserTryDeleteUser>(USER_TRY_DELETE_USER);
export const deleteUserSuccess = createAction<UserDeleteUserSuccess>(
  USER_DELETE_USER_SUCCESS
);

export const tryFetchUsers =
  createAction<UserTryFetchUsers>(USER_TRY_FETCH_USERS);
export const fetchUsersSuccess = createAction<UserFetchUsersSuccess>(
  USER_FETCH_USERS_SUCCESS
);
export const fetchUsersError = createAction<UserFetchUsersError>(
  USER_FETCH_USERS_ERROR
);
