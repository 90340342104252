import PropTypes from 'prop-types';
import React from 'react';

import { pic } from 'app/utils/pictures';

const Pic = ({ pictureId, alt = '', className = '', ...props }) => (
  <img
    {...props}
    className={`twic pic ${className}`}
    data-src={pic(pictureId)}
    src="https://staycation.twic.pics/v1/placeholder:transparent"
    alt={alt}
  />
);

Pic.propTypes = {
  pictureId: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
};

export default Pic;
