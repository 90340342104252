import { AnyAction } from '@reduxjs/toolkit';

import * as actions from 'app/redux/actions/commercialGestures';
import { createGestureListItem } from 'app/redux/models/CommercialGestures/CommercialGestureListItem';
import { GestureListItem } from 'app/redux/models/CommercialGestures/CommercialGestureListItem';
import { Hotel, createHotel } from 'app/redux/models/Hotel/Hotel';

export type CommercialGestures = {
  isLoading: boolean;
  gestures: GestureListItem[];
  hotels: Hotel[];
};

export const initialState: CommercialGestures = {
  isLoading: false,
  gestures: [],
  hotels: [],
};

function bills(state = initialState, action: AnyAction): CommercialGestures {
  if (
    actions.tryFetchGestures.match(action) ||
    actions.trySaveGesture.match(action) ||
    actions.tryFetchHotels.match(action)
  ) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (actions.fetchGesturesSuccess.match(action)) {
    const gestures = action.payload.gestures.map(createGestureListItem);

    return {
      ...state,
      isLoading: false,
      gestures,
    };
  }

  if (actions.fetchHotelsSuccess.match(action)) {
    const hotels = action.payload.hotels.map(createHotel);

    return {
      ...state,
      isLoading: false,
      hotels,
    };
  }

  if (
    actions.fetchGesturesFailure.match(action) ||
    actions.saveGesturesSuccess.match(action) ||
    actions.saveGestureFailure.match(action) ||
    actions.fetchHotelsFailure.match(action) ||
    actions.stopLoading.match(action)
  ) {
    return {
      ...state,
      isLoading: false,
    };
  }

  return state;
}

export default bills;
