import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const ItemMenu = ({ onRemove }) => (
  <Dropdown
    overlay={
      <Menu>
        <Menu.Item key="0" onClick={onRemove}>
          Remove
        </Menu.Item>
      </Menu>
    }
    trigger={['click']}
  >
    <EllipsisOutlined rotate={90} />
  </Dropdown>
);

ItemMenu.propTypes = {
  onRemove: PropTypes.func,
};

export default ItemMenu;
