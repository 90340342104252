import { Rule } from 'antd/lib/form';

export const requiredRule: Rule = {
  required: true,
  message: 'This field is required',
};

export const maxCharactersRule = (maxCharacters: number): Rule => {
  return {
    max: maxCharacters,
    message: `Max ${maxCharacters} characters`,
  };
};
