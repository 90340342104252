import { createAction } from '@reduxjs/toolkit';

import type { Booking, BookingStatus, PSP } from '../models/Booking/Booking';

export type TryFetchBookingsPayload = {
  search?: string;
  beginning?: string;
  ending?: string;
  offset: number;
  sorting?: string;
  psp: PSP[];
  status: BookingStatus[];
};

export type FetchBookingsSuccessPayload = {
  bookings: Booking[];
};
export type FetchBookingsFailurePayload = {
  error: string;
};

export const TRY_FETCH_BOOKINGS = 'BOOKINGS_TRY_FETCH_BOOKINGS';
export const FETCH_BOOKINGS_SUCCESS = 'BOOKINGS_FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_FAILURE = 'BOOKINGS_FETCH_BOOKINGS_FAILURE';

export const tryFetchBookings =
  createAction<TryFetchBookingsPayload>(TRY_FETCH_BOOKINGS);
export const fetchBookingsSuccess = createAction<FetchBookingsSuccessPayload>(
  FETCH_BOOKINGS_SUCCESS
);
export const fetchBookingsFailure = createAction<FetchBookingsFailurePayload>(
  FETCH_BOOKINGS_FAILURE
);
