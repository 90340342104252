import { G2 } from '@ant-design/plots';
import {
  format,
  isAfter,
  isEqual,
  isFuture,
  isSameDay,
  isToday,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
} from 'date-fns';

import { Unit } from 'app/typings/analytics';

// This function allows you to separate the dates into two arrays,
//the first includes only the current date and all dates in the past and
//the second only dates in the future
const splitData = (
  points: G2.Types.ShapeVertices,
  data: G2.Types.ShapeInfo['data'],
  unit: Unit
) => {
  const pastAndActualDates: (
    | G2.Types.RangePoint
    | G2.Types.Point
    | G2.Types.Point[]
  )[] = [];

  const futurDates: (
    | G2.Types.RangePoint
    | G2.Types.Point
    | G2.Types.Point[]
  )[] = [];

  data?.forEach(
    (data: { item: { startDate: string | number | Date } }, index: number) => {
      if (
        isFuture(new Date(data.item.startDate)) ||
        isFirstDayOfUnit(new Date(data.item.startDate), unit)
      ) {
        futurDates.push(points[index]);
      } else {
        pastAndActualDates.push(points[index]);
      }
    }
  );

  futurDates[0] && pastAndActualDates.push(futurDates[0]);

  return [pastAndActualDates, futurDates];
};

//This function creates the lines that will appear on the graph.
//Thanks to my "splitData" function we retrieve the dates in two arrays in order to create for each line (current and previous period)
//possibly two forms of lines, continuous lines for past and current dates and discontinuous lines for future dates
export const createLines = (unit: Unit) => {
  return G2.registerShape('line', 'lineShape', {
    draw(cfg, container) {
      const group = container.addGroup();

      const pointArrs = (cfg.points &&
        cfg.data &&
        splitData(cfg.points, cfg.data, unit)) || [
        [{ x: 0, y: 0 }],
        [{ x: 0, y: 0 }],
      ];

      const pastActualLine = [];

      for (let i = 0; i < pointArrs[0].length; i++) {
        let pre = 'L';

        if (i === 0) {
          pre = 'M';
        }

        // @ts-ignore
        pastActualLine.push([pre, pointArrs[0][i].x, pointArrs[0][i].y]);
      }

      group.addShape('path', {
        attrs: {
          path: pastActualLine,
          stroke: cfg.color,
          lineWidth: 1.5,
        },
      });

      const futureLine = [];

      for (let i = 0; i < pointArrs[1].length; i++) {
        let pre = 'L';

        if (i === 0) {
          pre = 'M';
        }

        // @ts-ignore
        futureLine.push([pre, pointArrs[1][i].x, pointArrs[1][i].y]);
      }

      group.addShape('path', {
        attrs: {
          path: futureLine,
          stroke: cfg.color,
          lineDash: [5],
          lineWidth: 1.5,
        },
      });

      return group;
    },
  });
};

const isFirstDayOfUnit = (date: Date, unit: Unit) => {
  switch (unit) {
    case 'WEEKLY':
      return isSameDay(date, startOfISOWeek(new Date()));
    case 'MONTHLY':
      return isSameDay(date, startOfMonth(new Date()));
    case 'YEARLY':
      return isSameDay(date, startOfYear(new Date()));
    default:
      return isToday(date);
  }
};

export const getMarkerDate = (ticks: string[], unit: Unit) => {
  if (ticks.includes(format(new Date(), 'yyyy-MM-dd'))) {
    return format(new Date(), 'yyyy-MM-dd');
  }

  switch (unit) {
    case 'WEEKLY':
      return format(startOfISOWeek(new Date()), 'yyyy-MM-dd');
    case 'MONTHLY':
      return format(startOfMonth(new Date()), 'yyyy-MM-dd');
    case 'YEARLY':
      return format(startOfYear(new Date()), 'yyyy-MM-dd');
    default:
      return format(new Date(), 'yyyy-MM-dd');
  }
};

export const getMarkerPositionOnGraph = (ticks: string[], date: string) => {
  if (ticks.length === 0) {
    return false;
  }

  return (
    isEqual(new Date(date), new Date(ticks[ticks.length - 1])) ||
    isAfter(new Date(date), new Date(ticks[ticks.length - 1]))
  );
};
