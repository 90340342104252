/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/redux/store';

export const selectGuides = (state: RootState) => state.guides;
export const selectGuidesGuide = createSelector(
  selectGuides,
  ({ guide }) => guide
);
export const selectGuidesLoading = createSelector(
  selectGuides,
  ({ loading }) => loading
);
