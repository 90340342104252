import { createAction } from '@reduxjs/toolkit';

import type { Bill, Provider } from 'app/redux/models/Bill/Bill';

export type TryFetchBillsPayload = {
  search?: string;
  provider?: Provider;
  sortingColumn?: string;
  sortingOrder?: string;
  offset?: number;
  limit?: number;
};

export enum BillingInterval {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

type FetchBillsSuccessPayload = {
  bills: Bill[];
};

export type DownloadBillPayload = {
  billId: number;
};

type DownloadBillSuccessPayload = {
  pdf: Blob;
  filename: string;
};

export const BILLS_START_LOADING = 'BILLS_START_LOADING';
export const BILLS_STOP_LOADING = 'BILLS_STOP_LOADING';

export const BILLS_TRY_FETCH_BILLS = 'BILLS_TRY_FETCH_BILLS';
export const BILLS_FETCH_BILLS_SUCCESS = 'BILLS_FETCH_BILLS_SUCCESS';
export const BILLS_FETCH_BILLS_FAILURE = 'BILLS_FETCH_BILLS_FAILURE';

export const BILLS_DOWNLOAD_BILL = 'BILLS_DOWNLOAD_BILL';
export const BILLS_DOWNLOAD_BILL_SUCCESS = 'BILLS_DOWNLOAD_BILL_SUCCESS';
export const BILLS_DOWNLOAD_BILL_FAILURE = 'BILLS_DOWNLOAD_BILL_FAILURE';
export const BILLS_RESET_BILL_OBJECT_PDF = 'BILLS_RESET_BILL_OBJECT_PDF';

export const startLoading = createAction(BILLS_START_LOADING);
export const stopLoading = createAction(BILLS_STOP_LOADING);

export const tryFetchBills = createAction<TryFetchBillsPayload>(
  BILLS_TRY_FETCH_BILLS
);
export const fetchBillsSuccess = createAction<FetchBillsSuccessPayload>(
  BILLS_FETCH_BILLS_SUCCESS
);
export const fetchBillsFailure = createAction(BILLS_FETCH_BILLS_FAILURE);

export const downloadBill =
  createAction<DownloadBillPayload>(BILLS_DOWNLOAD_BILL);
export const downloadBillSuccess = createAction<DownloadBillSuccessPayload>(
  BILLS_DOWNLOAD_BILL_SUCCESS
);
export const downloadBillFailure = createAction(BILLS_DOWNLOAD_BILL_FAILURE);

export const resetBillObjectPdf = createAction(BILLS_RESET_BILL_OBJECT_PDF);
