import { PlusOutlined } from '@ant-design/icons';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import EditPictureModal from 'app/components/commons/EditPictureModal/EditPictureModal';
import { Picture } from 'app/components/commons/Uploader/Types';
import UploadList from 'app/components/commons/Uploader/UploadList/UploadList';
import GalleryModal from 'app/components/pages/Galleries/GalleryModal/GalleryModal';
import {
  tryEditPicture,
  tryRemoveHotelPicture,
} from 'app/redux/actions/pictures';

import './Pictures.scss';

const DEFAULT_PICTURES_CATEGORIES = [
  { name: 'Media gallery', type: 'official' },
];
const DEFAULT_MODAL_SUBTITLE =
  'Select photos in your media gallery or upload new ones to illustrate this space';

export const Pictures: FC<Props> = ({
  value,
  onChange,
  sectionTitle,
  hotelId,
  picturesCategories = DEFAULT_PICTURES_CATEGORIES,
  kind = 'official',
  previewable = false,
  removable = false,
  editable = false,
  draggable = false,
  modalSubtitle = DEFAULT_MODAL_SUBTITLE,
  fetchRooms = false,
  editHotelPicture,
  removeHotelPicture,
  maxPictures,
}) => {
  const [internalValue, setInternalValue] = useState(value);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [pictureToEdit, setPictureToEdit] = useState<Picture | null>(null);
  const pictures = internalValue;

  useEffect(() => {
    if (value) {
      setInternalValue(value);
    }
  }, [value]);

  if (!onChange) {
    return null;
  }

  const handleOnChange = (newValue: any) => {
    setInternalValue(newValue);
    onChange(newValue);
  };

  const addPictures = (newPictures: any) => {
    if (!maxPictures || pictures.length + newPictures.length < maxPictures) {
      handleOnChange([...pictures, ...newPictures]);
    } else {
      handleOnChange([
        ...pictures.slice(0, pictures.length - newPictures.length),
        ...newPictures,
      ]);
    }
  };

  const handleRemovePicture = (index: any) => {
    if (index >= 0) {
      handleOnChange([
        ...pictures.slice(0, index),
        ...pictures.slice(index + 1),
      ]);
    }
  };

  const onRemove = (uid: any) => {
    const index = pictures.findIndex(
      (pic: any) => uid === `${pic.pictureId}-${pic.id}`
    );

    handleRemovePicture(index);
  };

  const updatePicture = (values: any) => {
    const newPicture = { ...pictureToEdit, ...values };

    editHotelPicture(newPicture);

    const pictureIdx = pictures.findIndex(
      (pic: any) => pic.id === newPicture.id
    );

    pictures[pictureIdx] = newPicture;
    handleOnChange([...pictures]);
  };

  return (
    <div className="pictures-content">
      <UploadList
        pictures={pictures || []}
        uploadingItems={{}}
        onChange={handleOnChange}
        remove={removable ? onRemove : undefined}
        edit={editable ? setPictureToEdit : undefined}
        customButton={
          <div onClick={() => setModalIsVisible(true)}>
            <PlusOutlined />
            <span className="text">Add</span>
          </div>
        }
        previewable={previewable}
        draggable={draggable}
      />
      <GalleryModal
        visible={modalIsVisible}
        title={sectionTitle}
        onOk={(newPictures) => {
          addPictures(newPictures);
          setModalIsVisible(false);
        }}
        onCancel={() => setModalIsVisible(false)}
        hotelId={hotelId}
        okText="Save"
        cancelText="Cancel"
        categories={picturesCategories}
        subTitle={modalSubtitle}
        kind={kind}
        fetchRooms={fetchRooms}
        maxPictures={maxPictures}
      />
      {!!pictureToEdit && (
        <EditPictureModal
          picture={pictureToEdit}
          closeModal={() => setPictureToEdit(null)}
          savePicture={(newPictureValues) => {
            updatePicture(newPictureValues);
            setPictureToEdit(null);
          }}
          deletePicture={() => {
            if (hotelId) {
              removeHotelPicture(hotelId, pictureToEdit.id);
            }

            onRemove(`${pictureToEdit.pictureId}-${pictureToEdit.id}`);
            setPictureToEdit(null);
          }}
          kind={kind}
        />
      )}
    </div>
  );
};

type Props = {
  value?: any;
  onChange?: any;
  sectionTitle: string;
  hotelId?: number;
  picturesCategories?: Array<{ name: string; type: string }>;
  kind?: string;
  previewable?: boolean;
  removable?: boolean;
  editable?: boolean;
  draggable?: boolean;
  modalSubtitle?: string;
  fetchRooms?: boolean;
  editHotelPicture: (picture: Picture) => void;
  removeHotelPicture: (hotelId: number, id: number) => void;
  maxPictures?: number;
};

const mapDispatchToProps = (dispatch: any) => ({
  editHotelPicture: (picture: any) => dispatch(tryEditPicture(picture)),
  removeHotelPicture: (hotelId: any, id: any) =>
    dispatch(tryRemoveHotelPicture(hotelId, id)),
});

export default connect(undefined, mapDispatchToProps)(Pictures);
