import classNames from 'classnames';

import './EditoActionLabel.scss';

export const EditoActionLabel = ({
  title,
  icon,
  danger,
  onClick,
}: {
  title: string;
  icon: React.ReactNode;
  danger?: boolean;
  onClick?: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames([{ danger }, 'editoListActionLabel'])}
    >
      {icon}
      {title}
    </div>
  );
};
