/* eslint-disable max-len */
import { createAction } from '@reduxjs/toolkit';

import type { BedCategory } from 'app/redux/models/BedCategory/BedCategory';
import type { CancellationReason } from 'app/redux/models/CancellationReason/CancellationReason';
import type { Club } from 'app/redux/models/Club/Club';
import type { Country } from 'app/redux/models/Country/Country';
import type { ExperienceCategory } from 'app/redux/models/ExperienceCategory/ExperienceCategory';
import type { GuideCategory } from 'app/redux/models/GuideCategory/GuideCategory';
import type { HotelGroup } from 'app/redux/models/HotelGroup/HotelGroup';
import type { HotelServiceType } from 'app/redux/models/HotelServiceType/HotelServiceType';
import type { RoomCategory } from 'app/redux/models/RoomCategory/RoomCategory';
import type { RoomFeature } from 'app/redux/models/RoomFeature/RoomFeature';
import type { SpaceType } from 'app/redux/models/SpaceType/SpaceType';

export const CONF_INIT = 'CONF_INIT';
export const CONF_FETCH_ROOM_CATEGORIES_SUCCESS =
  'CONF_FETCH_ROOM_CATEGORIES_SUCCESS';
export const CONF_FETCH_ROOM_FEATURES_SUCCESS =
  'CONF_FETCH_ROOM_FEATURES_SUCCESS';
export const CONF_FETCH_BED_CATEGORIES_SUCCESS =
  'CONF_FETCH_BED_CATEGORIES_SUCCESS';
export const CONF_FETCH_HOTEL_GROUPS_SUCCESS =
  'CONF_FETCH_HOTEL_GROUPS_SUCCESS';
export const CONF_FETCH_CLUBS_SUCCESS = 'CONF_FETCH_CLUBS_SUCCESS';
export const CONF_FETCH_COUNTRIES_SUCCESS = 'CONF_FETCH_COUNTRIES_SUCCESS';
export const CONF_FETCH_CANCELLATION_REASONS_SUCCESS =
  'CONF_FETCH_CANCELLATION_REASONS_SUCCESS';
export const CONF_FETCH_EXPERIENCE_CATEGORIES_SUCCESS =
  'CONF_FETCH_EXPERIENCE_CATEGORIES_SUCCESS';
export const CONF_FETCH_SPACE_TYPES_SUCCESS = 'CONF_FETCH_SPACE_TYPES_SUCCESS';
export const CONF_FETCH_HOTELS_SERVICES_TYPES_SUCCESS =
  'CONF_FETCH_HOTELS_SERVICES_TYPES_SUCCESS';
export const CONF_FETCH_GUIDE_CATEGORIES_SUCCESS =
  'CONF_FETCH_GUIDE_CATEGORIES_SUCCESS';

type InitConfPayload = {
  app: 'admin' | 'builder' | 'extranet';
};

type GetRoomCategoriesSuccessPayload = {
  roomCategories: Array<RoomCategory>;
};

type GetRoomFeaturesSuccessPayload = {
  roomFeatures: Array<RoomFeature>;
};

type GetBedCategoriesSuccessPayload = {
  bedCategories: Array<BedCategory>;
};

type GetHotelGroupsSuccessPayload = {
  hotelGroups: Array<HotelGroup>;
};

type GetClubsSuccessPayload = {
  clubs: Array<Club>;
};

type GetCountriesSuccessPayload = {
  countries: Array<Country>;
};

type GetCancellationReasonsSuccessPayload = {
  cancellationReasons: Array<CancellationReason>;
};

type GetExperienceCategoriesSuccessPayload = {
  experienceCategories: Array<ExperienceCategory>;
};

type GetSpaceTypesSuccessPayload = {
  spaceTypes: Array<SpaceType>;
};

type GetHotelsServicesTypesSuccessPayload = {
  hotelsServicesTypes: Array<HotelServiceType>;
};

type GetGuideCategoriesSuccessPayload = {
  guideCategories: Array<GuideCategory>;
};

export const initConf = createAction<InitConfPayload, typeof CONF_INIT>(
  CONF_INIT
);
export const fetchRoomCategoriesSuccess = createAction<
  GetRoomCategoriesSuccessPayload,
  typeof CONF_FETCH_ROOM_CATEGORIES_SUCCESS
>(CONF_FETCH_ROOM_CATEGORIES_SUCCESS);
export const fetchRoomFeaturesSuccess = createAction<
  GetRoomFeaturesSuccessPayload,
  typeof CONF_FETCH_ROOM_FEATURES_SUCCESS
>(CONF_FETCH_ROOM_FEATURES_SUCCESS);
export const fetchBedCategoriesSuccess = createAction<
  GetBedCategoriesSuccessPayload,
  typeof CONF_FETCH_BED_CATEGORIES_SUCCESS
>(CONF_FETCH_BED_CATEGORIES_SUCCESS);
export const fetchHotelGroupsSuccess = createAction<
  GetHotelGroupsSuccessPayload,
  typeof CONF_FETCH_HOTEL_GROUPS_SUCCESS
>(CONF_FETCH_HOTEL_GROUPS_SUCCESS);
export const fetchClubsSuccess = createAction<
  GetClubsSuccessPayload,
  typeof CONF_FETCH_CLUBS_SUCCESS
>(CONF_FETCH_CLUBS_SUCCESS);
export const fetchCountriesSuccess = createAction<
  GetCountriesSuccessPayload,
  typeof CONF_FETCH_COUNTRIES_SUCCESS
>(CONF_FETCH_COUNTRIES_SUCCESS);
export const fetchCancellationReasonsSuccess = createAction<
  GetCancellationReasonsSuccessPayload,
  typeof CONF_FETCH_CANCELLATION_REASONS_SUCCESS
>(CONF_FETCH_CANCELLATION_REASONS_SUCCESS);
export const fetchExperienceCategoriesSuccess = createAction<
  GetExperienceCategoriesSuccessPayload,
  typeof CONF_FETCH_EXPERIENCE_CATEGORIES_SUCCESS
>(CONF_FETCH_EXPERIENCE_CATEGORIES_SUCCESS);
export const fetchSpaceTypesSuccess = createAction<
  GetSpaceTypesSuccessPayload,
  typeof CONF_FETCH_SPACE_TYPES_SUCCESS
>(CONF_FETCH_SPACE_TYPES_SUCCESS);
export const fetchHotelsServicesTypesSuccess = createAction<
  GetHotelsServicesTypesSuccessPayload,
  typeof CONF_FETCH_HOTELS_SERVICES_TYPES_SUCCESS
>(CONF_FETCH_HOTELS_SERVICES_TYPES_SUCCESS);
export const fetchGuideCategoriesSuccess = createAction<
  GetGuideCategoriesSuccessPayload,
  typeof CONF_FETCH_GUIDE_CATEGORIES_SUCCESS
>(CONF_FETCH_GUIDE_CATEGORIES_SUCCESS);
