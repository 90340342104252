export type User = {
  email: string;
  firstName: string;
  gender: string;
  lastName: string;
  birthDate: string;
  avatarPath: string;
  id: number;
  countrySlug: string;
  hotelIds: number[];
};

export type AdminUser = {
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  role: UserRole;
  avatarId: string;
};

export enum UserRole {
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  User = 'user',
}

export const USER_ROLES = Object.values(UserRole);
