import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { apiMutation } from 'app/utils/request/api';

type PackageTagsPayload = {
  tagIds: number[];
  isGem: boolean;
};

type PackageTagsResponse = {
  tagIds: number[];
  isGem: boolean;
};

const path = '/packages/:packageId/tags' as const;

export function usePackageTagsSave(packageId: number) {
  return useMutation({
    mutationFn: async (payload: PackageTagsPayload) =>
      apiMutation(
        'PUT',
        {
          path,
          params: { packageId: `${packageId}` },
        },
        payload
      ) as Promise<PackageTagsResponse>,
    onSuccess: () => {
      message.success(`Package ${packageId} tags updated`);
    },
    onError: (error) => {
      message.error(`Error saving tags for package ${packageId}: ${error}`);
    },
  });
}
