export function getNewExtranetUrl(hotelId: number): string {
  const params = new URLSearchParams(window.location.search);

  params.delete('hotelId');
  params.append('hotelId', hotelId.toFixed(0));

  return (
    window.location.origin + window.location.pathname + '?' + params.toString()
  );
}
