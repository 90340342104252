import { Layout, Menu, PageHeader } from 'antd';
import _ from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { tryFetchHotel } from 'app/redux/actions/hotels';
import { tryFetchHotelGallery } from 'app/redux/actions/pictures';
import { IHotel } from 'app/typings';

import { Picture } from '../Types';

import './GalleryDetail.scss';
import GalleryDetailEdit from './GalleryDetailEdit/GalleryDetailEdit';
import GalleryDetailView from './GalleryDetailView/GalleryDetailView';

const MENU_ITEMS = [
  { name: 'Official', type: 'official' },
  { name: 'Sourcing', type: 'sourcing' },
  { name: 'Covers', type: 'cover' },
];

export const GalleryDetail: FC<Props> = ({
  hotel,
  hotelGallery,
  fetchHotel,
  fetchHotelGallery,
  history,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(MENU_ITEMS[0]);
  const [selectedPictures, setSelectedPictures] = useState<Array<Picture>>([]);
  const [editMode, setEditMode] = useState(false);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    fetchHotel(id);
    fetchHotelGallery(id);
  }, [fetchHotel, fetchHotelGallery, id]);

  useEffect(() => {
    setSelectedPictures([]);
  }, [selectedCategory]);

  const pictures = useMemo(() => {
    if (hotelGallery) {
      return hotelGallery
        .filter((picture) => picture?.kind === selectedCategory.type)
        .map((picture) => ({
          ...picture,
          selected: !!selectedPictures.find((id) => id.id === picture.id),
        }));
    }

    return [];
  }, [hotelGallery, selectedCategory, selectedPictures]);

  if (!hotelGallery || !hotel) {
    return null;
  }

  const getCategorySize = (type: any) =>
    hotelGallery.filter((picture) => picture?.kind === type).length;

  const renderHeader = () => (
    <PageHeader
      className="gallery-detail__header"
      onBack={() => history.goBack()}
      title={hotel.name}
    >
      <Menu
        mode="horizontal"
        onClick={({ key }) =>
          setSelectedCategory(MENU_ITEMS[parseInt(key, 10)])
        }
        selectedKeys={[
          MENU_ITEMS.findIndex(
            (item) => item.type === selectedCategory.type
          ).toString(),
        ]}
      >
        {MENU_ITEMS.map((item, index) => (
          <Menu.Item key={index}>{`${item.name} (${getCategorySize(
            item.type
          )})`}</Menu.Item>
        ))}
      </Menu>
    </PageHeader>
  );

  return (
    <Layout
      className={`gallery-detail__layout ${
        pictures.length ? 'gallery-detail__layout-block' : ''
      }`}
    >
      {renderHeader()}
      {editMode ? (
        <GalleryDetailEdit
          pictures={pictures}
          goToView={() => setEditMode(false)}
          kind={selectedCategory.type}
          hotelId={hotel.id}
        />
      ) : (
        <GalleryDetailView
          pictures={pictures}
          selectedCategory={selectedCategory}
          selectedPictures={selectedPictures}
          setSelectedPictures={setSelectedPictures}
          goToEdit={() => setEditMode(true)}
        />
      )}
    </Layout>
  );
};

type Props = {
  match: string;
  hotel: IHotel | null;
  hotelGallery: Array<Picture> | null;
  fetchHotel: (hotelId: string) => void;
  fetchHotelGallery: (hotelId: string) => void;
  history: { goBack: () => void };
};

const mapStateToProps = (state: any, props: any) => {
  const hotelId = parseInt(_.get(props.match, 'params.id', 0), 10);

  if (!hotelId || hotelId !== state.hotels.hotel?.id) {
    return {
      hotel: null,
      hotelGallery: null,
    };
  }

  return {
    hotel: state.hotels.hotel,
    hotelGallery: state.pictures.hotelGallery,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchHotel: (hotelId: string) =>
    dispatch(tryFetchHotel({ hotelId: +hotelId })),
  fetchHotelGallery: (hotelId: any) => dispatch(tryFetchHotelGallery(hotelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GalleryDetail);
