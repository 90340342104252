export type GuideCategory = {
  readonly id: number;
  readonly name: string;
};

export function createGuideCategory(values: any): GuideCategory {
  const { id, name } = values;

  return {
    id,
    name,
  };
}
