import { createAction } from '@reduxjs/toolkit';

import type { Guide } from 'app/redux/models/Guides/Guides';

type GuidesTrySortGuidesPayload = {
  guideIds: Array<number>;
};

type GuidesTryFeatureGuidePayload = {
  guideId: number;
};

type GuidesTryHybridGuidePayload = {
  guideId: number;
  isHybrid: boolean;
};

type GuidesFetchGuidePayload = {
  guideId: string;
};
type GuidesFetchGuideSuccessPayload = {
  guide: Guide;
};
type GuidesFetchGuideFailurePayload = {
  error: any;
};

type GuidesSaveGuidePayload = Omit<Guide, 'id'> & { id?: Guide['id'] };

type GuidesDuplicateGuidePayload = {
  guideId: number;
  featured: boolean;
  isHybrid: boolean;
};

export const GUIDES_TRY_SORT_GUIDES = 'GUIDES_TRY_SORT_GUIDES';
export const GUIDES_TRY_FEATURE_GUIDE = 'GUIDES_TRY_FEATURE_GUIDE';
export const GUIDES_TRY_HYBRID_GUIDE = 'GUIDES_TRY_HYBRID_GUIDE';

export const GUIDES_START_LOADING = 'GUIDES_START_LOADING';
export const GUIDES_STOP_LOADING = 'GUIDES_STOP_LOADING';

export const GUIDES_FETCH_GUIDE = 'GUIDES_FETCH_GUIDE';
export const GUIDES_FETCH_GUIDE_SUCCESS = 'GUIDES_FETCH_GUIDE_SUCCESS';
export const GUIDES_FETCH_GUIDE_FAILURE = 'GUIDES_FETCH_GUIDE_FAILURE';

export const GUIDES_SAVE_GUIDE = 'GUIDES_SAVE_GUIDE';

export const GUIDES_DUPLICATE_GUIDE = 'GUIDES_DUPLICATE_GUIDE';

export const trySortGuides = createAction<GuidesTrySortGuidesPayload>(
  GUIDES_TRY_SORT_GUIDES
);
export const tryFeatureGuide = createAction<GuidesTryFeatureGuidePayload>(
  GUIDES_TRY_FEATURE_GUIDE
);
export const tryHybridGuide = createAction<GuidesTryHybridGuidePayload>(
  GUIDES_TRY_HYBRID_GUIDE
);

export const startLoading = createAction(GUIDES_START_LOADING);
export const stopLoading = createAction(GUIDES_STOP_LOADING);

export const fetchGuide =
  createAction<GuidesFetchGuidePayload>(GUIDES_FETCH_GUIDE);
export const fetchGuideSuccess = createAction<GuidesFetchGuideSuccessPayload>(
  GUIDES_FETCH_GUIDE_SUCCESS
);
export const fetchGuideFailure = createAction<GuidesFetchGuideFailurePayload>(
  GUIDES_FETCH_GUIDE_FAILURE
);

export const saveGuide =
  createAction<GuidesSaveGuidePayload>(GUIDES_SAVE_GUIDE);

export const duplicateGuide = createAction<GuidesDuplicateGuidePayload>(
  GUIDES_DUPLICATE_GUIDE
);
