import React from 'react';

const ProfilIcon = () => (
  <svg
    width="120px"
    height="120px"
    viewBox="0 0 200 200"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Combined Shape</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M24.375,75 C23.6085989,88.6736151 27.1409773,98.0843803 34.9721352,103.232296 C46.7188719,110.954168 77.164553,113.466827 87.791352,100 C94.7218731,91.217287 98.7670723,83.0514057 99.9269494,75.5023561 L99.948,75.351 L99.9705731,75.5023561 C101.13045,83.0514057 105.175649,91.217287 112.106171,100 C122.73297,113.466827 153.178651,110.954168 164.925387,103.232296 C172.756545,98.0843803 176.288924,88.6736151 175.522523,75 L99.999,75 L100,75 L196.849518,74.9998245 C198.906159,82.9903754 200,91.3674476 200,100 C200,155.228475 155.228475,200 100,200 C44.771525,200 0,155.228475 0,100 C0,91.3674476 1.0938411,82.9903754 3.1504823,74.9998245 L24.375,75 Z M52.9434468,127.965364 C40.2187486,138.322739 68.2246044,158.75 99.682273,158.75 C131.139941,158.75 159.041737,138.027443 146.973499,127.965364 C134.358906,117.447753 134.277554,141.772993 99.549155,140.980481 C65.668145,140.207306 65.668145,117.607989 52.9434468,127.965364 Z M100,0 C140.276749,0 174.992052,23.811382 190.836685,58.1249232 L9.16331457,58.1249232 C25.0079482,23.811382 59.723251,0 100,0 Z"
        id="Combined-Shape"
        fill="#000000"
      />
    </g>
  </svg>
);

export default ProfilIcon;
