import { TimePicker as AntTimePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const TimePicker = ({ value, onChange, ...props }) => (
  <AntTimePicker
    {...props}
    value={value && moment(value, 'HH:mm')}
    onChange={(time) => onChange(time.format('HH:mm'))}
    minuteStep={30}
    format="HH:mm"
  />
);

TimePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TimePicker;
