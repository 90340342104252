import { actions } from 'app/redux/actions/entities';

function edition(state = { edit: {} }, action) {
  switch (action.type) {
    case actions.GET_ENTITY_SUCCESS:
      return {
        ...state,
        [action.entity]: {
          ...state.edit[action.entity],
          [action.entityId]: action.result,
        },
      };
    default:
      return state;
  }
}

export default edition;
