import { createAction } from '@reduxjs/toolkit';

import type { CustomTag } from 'app/redux/models/CustomTag/CustomTag';
import { SortOrder } from 'app/utils/sort';

type FetchCustomTagsSuccessPayload = {
  customTags: CustomTag[];
};

export type FilterCustomTagsPayload = {
  search?: string;
  dateSorting: SortOrder;
  offset: number;
  limit: number;
};

type TryCreateCustomTagPayload = {
  name: string;
};

export const CUSTOM_TAGS_START_LOADING = 'CUSTOM_TAGS_START_LOADING';
export const CUSTOM_TAGS_STOP_LOADING = 'CUSTOM_TAGS_STOP_LOADING';

export const CUSTOM_TAGS_TRY_FETCH_CUSTOM_TAGS =
  'CUSTOM_TAGS_TRY_FETCH_CUSTOM_TAGS';
export const CUSTOM_TAGS_FETCH_CUSTOM_TAGS_SUCCESS =
  'CUSTOM_TAGS_FETCH_CUSTOM_TAGS_SUCCESS';

export const CUSTOM_TAGS_FILTER_CUSTOM_TAGS = 'CUSTOM_TAGS_FILTER_CUSTOM_TAGS';

export const CUSTOM_TAGS_TRY_CREATE_CUSTOM_TAG =
  'CUSTOM_TAGS_TRY_CREATE_CUSTOM_TAG';

export const startLoading = createAction(CUSTOM_TAGS_START_LOADING);
export const stopLoading = createAction(CUSTOM_TAGS_STOP_LOADING);

export const tryFetchCustomTags = createAction(
  CUSTOM_TAGS_TRY_FETCH_CUSTOM_TAGS
);
export const fetchCustomTagsSuccess =
  createAction<FetchCustomTagsSuccessPayload>(
    CUSTOM_TAGS_FETCH_CUSTOM_TAGS_SUCCESS
  );

export const filterCustomTags = createAction<FilterCustomTagsPayload>(
  CUSTOM_TAGS_FILTER_CUSTOM_TAGS
);

export const tryCreateCustomTag = createAction<TryCreateCustomTagPayload>(
  CUSTOM_TAGS_TRY_CREATE_CUSTOM_TAG
);
