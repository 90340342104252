import {
  EllipsisOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input, Layout, Menu, PageHeader, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { PublishedCell } from 'app/components/lists/cells';
import {
  tryFetchPackages,
  tryHidePackage,
  tryPublishPackage,
} from 'app/redux/actions/packages';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { PackageListItem } from 'app/redux/models/Package/Package';
import {
  parseArrayParam,
  useQueryParamHistory,
} from 'app/utils/queryParamHistory';
import {
  SortOrder,
  convertFromAntdSortOrder,
  convertToAntdSortOrder,
  getSortOrder,
  sortDate,
  sortPrimitive,
} from 'app/utils/sort';

import 'styles/layout/page-list.scss';

const { Content } = Layout;
const { Column } = Table;

type PackagesListQueryParam = {
  search: string;
  pageNumber: number;
  nameSorting: SortOrder;
  updatedAtSorting: SortOrder;
  publishedFilter: string[];
};

const LIMIT = 50;

const filterStatus = (val: string) =>
  ['published', 'unpublished', 'hidden'].includes(val) ? val : undefined;

const queryParamInit = {
  pageNumber: 1,
  nameSorting: 'none',
  updatedAtSorting: 'none',
  publishedFilter: ['published', 'unpublished', 'hidden'],
} as PackagesListQueryParam;
const queryParamKeys = {
  pageNumber: 'p',
  nameSorting: 'ns',
  updatedAtSorting: 'us',
  publishedFilter: 'pu',
  search: 's',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  nameSorting: getSortOrder,
  updatedAtSorting: getSortOrder,
  publishedFilter: parseArrayParam(filterStatus),
};

const PackagesList = () => {
  const history = useHistory();
  const appUrl = useAppSelector((state) => state.navigation.appUrl);
  const user = useAppSelector((state) => state.auth.user);
  const packages = useAppSelector((s) => s.packages.packages);
  const loading = useAppSelector((s) => s.packages.loading);
  const dispatch = useAppDispatch();

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<PackagesListQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  useEffect(() => {
    dispatch(tryFetchPackages());
  }, [dispatch]);

  const handleSearch = (search: string) => {
    updateQueryParam({
      search: search,
      pageNumber: 1,
    });
  };

  const formatDate = (date: any) =>
    date && moment.utc(date).local().format('DD/MM/YYYY à HH:mm');

  const handleOffset = (x: any) => {
    updateQueryParam({ pageNumber: (queryParam.pageNumber || 1) + x });
  };

  const handleOnChange = (__: any, filter: any, sorter: any) => {
    const { field, order } = sorter;

    const adaptedOrder = convertFromAntdSortOrder(order);

    const nameSorting = field === 'name' ? adaptedOrder : 'none';
    const updatedAtSorting = field === 'updatedAt' ? adaptedOrder : 'none';

    updateQueryParam({
      publishedFilter: filter.published || [],
      nameSorting,
      updatedAtSorting,
      pageNumber: 1,
    });
  };

  const handleRowActions = (hotel: any) => ({
    onClick: () => history.push(`/packages/${hotel.id}/edit`),
  });

  const filteredBySearch = useMemo(
    () =>
      packages.filter(
        (pkg) =>
          pkg.name
            .toLowerCase()
            .includes(queryParam.search?.toLowerCase() || '') ||
          pkg.hotelName
            .toLowerCase()
            .includes(queryParam.search?.toLowerCase() || '')
      ),
    [queryParam.search, packages]
  );

  const filteredByPublished = useMemo(
    () =>
      filteredBySearch.filter((pkg) => {
        if (queryParam.publishedFilter?.includes('hidden') && pkg.hidden) {
          return true;
        }

        if (
          queryParam.publishedFilter?.includes('published') &&
          pkg.published
        ) {
          return true;
        }

        if (
          queryParam.publishedFilter?.includes('unpublished') &&
          !pkg.published &&
          !pkg.hidden
        ) {
          return true;
        }

        return false;
      }),
    [queryParam.publishedFilter, filteredBySearch]
  );

  const packagesWithDefaultNames = useMemo(
    () =>
      filteredByPublished.map((pkg) => ({
        ...pkg,
        name: pkg.name.trim() || `Package #${pkg.id}`,
      })),
    [filteredByPublished]
  );

  const sortedPackages = useMemo(
    () =>
      packagesWithDefaultNames.sort((packageA, packageB) => {
        if (queryParam.nameSorting && queryParam.nameSorting !== 'none') {
          return sortPrimitive(
            packageA.name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, ''),
            packageB.name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, ''),
            queryParam.nameSorting
          );
        }

        if (
          queryParam.updatedAtSorting &&
          queryParam.updatedAtSorting !== 'none'
        ) {
          return sortDate(
            packageA.updatedAt,
            packageB.updatedAt,
            queryParam.updatedAtSorting
          );
        }

        return 0;
      }),
    [
      queryParam.nameSorting,
      queryParam.updatedAtSorting,
      packagesWithDefaultNames,
    ]
  );

  const startIndex = ((queryParam.pageNumber || 1) - 1) * LIMIT;
  const paginatedHotels = sortedPackages.slice(startIndex, startIndex + LIMIT);

  const pagination = (className: any) => (
    <div className={className}>
      <Button
        disabled={queryParam.pageNumber === 1}
        onClick={() => handleOffset(-1)}
      >
        <LeftOutlined />
      </Button>
      <Button>{queryParam.pageNumber}</Button>
      <Button
        disabled={paginatedHotels.length < LIMIT}
        onClick={() => handleOffset(1)}
      >
        <RightOutlined />
      </Button>
    </div>
  );

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={queryParam.search}
          onChange={(event) => handleSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
      </div>
      {pagination('button-wrapper')}
    </div>
  );

  const newPackagesButton = (
    <Button
      type="primary"
      size="large"
      onClick={() => history.push('packages/new')}
    >
      New
    </Button>
  );

  const actionMenu = (pkg: PackageListItem) => (
    <div className="actions-menu">
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              key="preview"
              onClick={() => window.open(`${appUrl}/${pkg.url}`)}
            >
              Preview
            </Menu.Item>
            <Menu.Item
              key="edit"
              onClick={() => handleRowActions(pkg).onClick()}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              key="publish"
              onClick={() =>
                dispatch(
                  tryPublishPackage({
                    packageId: pkg.id,
                    published: !pkg.published,
                  })
                )
              }
              disabled={pkg.hidden}
            >
              {pkg.published ? 'Unpublish' : 'Publish'}
            </Menu.Item>
            <Menu.Item
              key="hide"
              onClick={() =>
                dispatch(
                  tryHidePackage({
                    packageId: pkg.id,
                    hidden: !pkg.hidden,
                  })
                )
              }
              disabled={pkg.published || user?.role !== 'superadmin'}
            >
              {pkg.hidden ? 'Show' : 'Hide'}
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );

  return (
    <Layout className="page-list">
      <PageHeader
        className="header"
        title="Packages"
        extra={newPackagesButton}
      />
      <Content className="body">
        <Table
          dataSource={paginatedHotels}
          pagination={false}
          title={header}
          loading={loading}
          rowKey="id"
          onRow={handleRowActions}
          onChange={handleOnChange}
        >
          <Column
            key="name"
            title="Name"
            dataIndex="name"
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.nameSorting)}
          />
          <Column key="hotelName" title="Hotel" dataIndex="hotelName" />
          <Column
            key="updatedAt"
            title="Last modified"
            dataIndex="updatedAt"
            sorter
            render={formatDate}
            sortOrder={convertToAntdSortOrder(queryParam.updatedAtSorting)}
          />
          <Column
            key="published"
            title="Published"
            dataIndex="published"
            render={PublishedCell}
            filters={[
              { text: 'Published', value: 'published' },
              { text: 'Unpublished', value: 'unpublished' },
              { text: 'Hidden', value: 'hidden' },
            ]}
            filteredValue={queryParam.publishedFilter || []}
          />

          <Column
            render={actionMenu}
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            align="center"
          />
        </Table>
        {pagination('footer')}
      </Content>
    </Layout>
  );
};

export default PackagesList;
