import { Form, Layout } from 'antd';
import arrayMove from 'array-move';
import React, { FC, ReactNode, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import type {
  IExperience,
  IExperienceCategory,
} from 'app/components/commons/Experience/Types';
import { computeExperienceName } from 'app/components/commons/Experience/utils';
import Headlines from 'app/components/commons/Package/Headlines/Headlines';
import {
  IHeadline,
  IIdentity,
  IPackage,
} from 'app/components/commons/Package/Types';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { trySaveCopywriting } from 'app/redux/actions/packages';

import './Copywriting.scss';
import Identity from './Identity/Identity';

const { Content } = Layout;

export const Copywriting: FC<Props> = ({
  pkg,
  headerMenu,
  headerExtra,
  experiences,
  identity,
  headlines,
  categories,
  submitCopywriting,
}) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [isDirtyMove, setIsDirtyMove] = useState(false);
  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values: any) => {
      if (pkg) {
        const formattedValues = {
          name: values.identity.name,
          highlight: values.identity.highlight,
          description: values.identity.description,
          headlines: values.headlines.map(({ title, ...h }: any) => h),
        };

        submitCopywriting(pkg.id, formattedValues);
        setMode('view');
      }
    },
    [pkg, submitCopywriting]
  );

  const onCancel = useCallback(() => {
    setMode('view');
  }, []);

  if (!pkg || !identity || !headlines || !experiences) {
    return null;
  }

  const formattedValues = {
    identity,
    headlines: headlines
      .filter(
        (h) =>
          !h.experienceId ||
          experiences.find((exp) => exp.id === h.experienceId)?.published
      )
      .map((h) => ({
        ...h,
        title: computeExperienceName({
          category: categories.find(
            (cat) =>
              cat.id ===
              experiences.find((exp) => exp.id === h.experienceId)?.categoryId
          ),
          ...(experiences.find(
            (exp) => exp.id === h.experienceId
          ) as IExperience),
        }),
      })),
  };

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      initialValues={formattedValues}
      className="cover-form"
    >
      {(values, { resetFields, isFieldsTouched, submit, setFieldsValue }) => (
        <SDetailLayout
          title={
            identity?.name && identity.name.length
              ? identity.name
              : `Package #${pkg.id}`
          }
          mode={mode}
          onEdit={() => setMode('edit')}
          onSave={() => {
            submit();
            setIsDirtyMove(false);
          }}
          onCancel={() => {
            onCancel();
            setIsDirtyMove(false);
          }}
          reset={resetFields}
          isDirty={isDirtyMove || isFieldsTouched()}
          headerMenu={headerMenu}
          headerExtra={headerExtra}
          isValid={form
            .getFieldsError()
            .every((item) => item.errors.length === 0)}
        >
          <Content className="package-detail__content">
            <Identity mode={mode} values={values?.identity} />
            <SCard title="Headlines">
              <Headlines
                mode={mode}
                values={values}
                setFieldsValue={setFieldsValue}
                move={({ oldIndex, newIndex }) => {
                  setIsDirtyMove(true);
                  setFieldsValue({
                    headlines: arrayMove(values.headlines, oldIndex, newIndex),
                  });
                }}
              />
            </SCard>
          </Content>
        </SDetailLayout>
      )}
    </FormLegacyRenderProp>
  );
};

type Props = {
  headerMenu: ReactNode;
  headerExtra: Array<ReactNode>;
  packageId: number | undefined;
  pkg: IPackage | null;
  identity: IIdentity | null;
  headlines: IHeadline[] | null;
  experiences: Array<IExperience>;
  categories: IExperienceCategory[];
  submitCopywriting: (packageId: number, values: any) => void;
};

const mapStateToProps = (state: any, props: any) => {
  const { packageId } = props;
  const pkg = state.packages.package;
  const { copywriting } = state.packages;
  const categories = state.conf.experienceCategories;

  if (packageId !== pkg.id) {
    return {
      pkg: null,
      identity: null,
      headlines: null,
    };
  }

  return {
    pkg,
    identity: {
      name: copywriting?.name,
      highlight: copywriting?.highlight,
      description: copywriting?.description,
    },
    headlines: copywriting?.headlines,
    experiences: state.experiences.hotelExperiences,
    categories,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  submitCopywriting: (packageId: any, copywriting: any) =>
    dispatch(trySaveCopywriting({ packageId, copywriting })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Copywriting);
