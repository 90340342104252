import { Picture } from 'app/redux/models/Picture/Picture';

export type ListGuide = {
  id: number;
  categoryId: number;
  clubId: number;
  coverPicture: Picture;
  featured: boolean;
  isHybrid: boolean;
  name: string;
  priority: number;
  published: boolean;
  updatedAt: string | Date;
};

export type SectionPackage = {
  packageId: number;
  title: string;
  description: string;
  coverPicture: Picture;
  id: number;
  roomId: number;
};

export type GuideSection = {
  title: string;
  menuTitle: string;
  description: string;
  id: number;
  packages: SectionPackage[];
};

export type Guide = {
  name: string;
  coverPicture: Picture;
  clubId: number;
  categoryId: number;
  id: number;
  slug: string;
  qualifier: string;
  pageTitle: string;
  mainTitle: string;
  introduction: string;
  conclusion: string;
  sections: GuideSection[];
  featured: boolean;
  isHybrid: boolean;
  isDuplicate: boolean;
};

export type GuideCategory = {
  id: number;
  name: string;
};

export function createGuide(values: any): Guide {
  const {
    name,
    coverPicture,
    clubId,
    categoryId,
    id,
    slug,
    qualifier,
    pageTitle,
    mainTitle,
    introduction,
    conclusion,
    sections,
    featured,
    isHybrid,
    isDuplicate,
  } = values;

  return {
    name,
    coverPicture,
    clubId,
    categoryId,
    id,
    slug,
    qualifier,
    pageTitle,
    mainTitle,
    introduction,
    conclusion,
    sections,
    featured,
    isHybrid,
    isDuplicate,
  };
}
