import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Layout, PageHeader, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import {
  FilterCustomTagsPayload,
  filterCustomTags,
  tryFetchCustomTags,
} from 'app/redux/actions/customTags';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import {
  getCustomTags,
  getFilteredCustomTags,
  getIsLoading,
} from 'app/redux/selectors/customTags';
import { useDebounce } from 'app/utils/hooks/useDebounce';
import { scrollToTop } from 'app/utils/scroll';
import {
  SortOrder,
  convertFromAntdSortOrder,
  convertToAntdSortOrder,
} from 'app/utils/sort';

import 'styles/layout/page-list.scss';

import NewCustomTagModal from './NewCustomTagModal/NewCustomTagModal';

type CustomTagsQueryParam = {
  search: string;
  pageNumber: number;
  dateSorting: SortOrder;
};

const { Content } = Layout;
const { Column } = Table;

const PAGE_SIZE = 50;

const formatDate = (date: any) => {
  return date && moment.utc(date).local().format('DD/MM/YYYY - HH:mm');
};

const makePayload = (
  queryParam: CustomTagsQueryParam
): FilterCustomTagsPayload => {
  return {
    search: queryParam.search,
    dateSorting: queryParam.dateSorting,
    offset: ((queryParam.pageNumber || 1) - 1) * PAGE_SIZE,
    limit: PAGE_SIZE,
  };
};

const CustomTags = () => {
  const dispatch = useAppDispatch();
  const customTags = useAppSelector(getCustomTags);
  const filteredCustomTags = useAppSelector(getFilteredCustomTags);
  const isLoading = useAppSelector(getIsLoading);
  const user = useAppSelector((state) => state.auth.user);
  const [showNewCustomTagModal, setShowNewCustomTagModal] = useState(false);
  const [queryParam, updateQueryParam] = useState<CustomTagsQueryParam>({
    pageNumber: 1,
    dateSorting: 'descend',
    search: '',
  });

  const debouncedQueryParams = useDebounce(queryParam);

  useEffect(() => {
    if (customTags) {
      const payload = makePayload(debouncedQueryParams);

      dispatch(filterCustomTags(payload));
    }
  }, [dispatch, debouncedQueryParams, customTags]);

  useEffect(() => {
    if (!customTags) {
      dispatch(tryFetchCustomTags());
    }
  }, [dispatch, customTags]);

  const handleOffset = (x: number) => {
    updateQueryParam((prev) => ({
      ...prev,
      pageNumber: (queryParam?.pageNumber || 1) + x,
    }));

    scrollToTop();
  };

  const dateSortingHandler = (sort: SortOrder) => {
    updateQueryParam((prev) => ({
      ...prev,
      pageNumber: 1,
      dateSorting: sort,
    }));
  };

  const handleSearch = (search: string) => {
    updateQueryParam((prev) => ({
      ...prev,
      pageNumber: 1,
      search: search,
    }));
  };

  const pagination = (className: any) => (
    <div className={className}>
      <Button
        disabled={queryParam.pageNumber === 1}
        onClick={() => handleOffset(-1)}
      >
        <LeftOutlined />
      </Button>
      <Button>{queryParam.pageNumber}</Button>
      <Button
        disabled={filteredCustomTags.length < 50}
        onClick={() => handleOffset(1)}
      >
        <RightOutlined />
      </Button>
    </div>
  );

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={queryParam.search}
          onChange={(event) => handleSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
      </div>
      {pagination('button-wrapper')}
    </div>
  );

  const newCustomTagButton = (
    <Button
      type="primary"
      size="large"
      onClick={() => setShowNewCustomTagModal(true)}
    >
      New
    </Button>
  );

  return (
    <Layout className="page-list">
      <PageHeader
        className="header"
        title="Custom tags"
        extra={user?.role === 'superadmin' ? newCustomTagButton : undefined}
      />
      <Content className="body">
        <Table
          dataSource={filteredCustomTags}
          pagination={false}
          title={header}
          loading={isLoading}
          rowKey="id"
          onChange={(__, ___, sorter) => {
            // @ts-ignore
            const { order } = sorter;

            dateSortingHandler(convertFromAntdSortOrder(order));
          }}
        >
          <Column title="Name" dataIndex="name" />
          <Column
            title="Creation date"
            dataIndex="createdAt"
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.dateSorting)}
            render={formatDate}
          />
        </Table>
      </Content>
      {!!showNewCustomTagModal && (
        <NewCustomTagModal closeModal={() => setShowNewCustomTagModal(false)} />
      )}
    </Layout>
  );
};

export default CustomTags;
