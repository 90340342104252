import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
  LogoutOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import { Dropdown, Modal } from 'antd';
import { Link } from 'react-router-dom';

import { useDeleteEditoEvents } from 'app/hooks/data/edito/events/useDeleteEditoEvent';
import { useFinishEditoEvents } from 'app/hooks/data/edito/events/useFinishEditoEvent';
import { usePublishEditoEvents } from 'app/hooks/data/edito/events/usePublishEditoEvent';
import { useUnpublishEditoEvents } from 'app/hooks/data/edito/events/useUnpublishEditoEvent';
import {
  EditoEventStatus,
  IEditoEventList,
  IEditoEventListResponse,
} from 'app/typings/edito';

import { EditoActionLabel } from '../../../commons/List/EditoActionLabel/EditoActionLabel';

import './EditoEventListActions.scss';

const { confirm } = Modal;

type Props = {
  editoEvent: IEditoEventList;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<IEditoEventListResponse[], unknown>>;
};

type EditoAction =
  | 'delete'
  | 'edit'
  | 'moveToDraft'
  | 'removeFromFeatured'
  | 'unpublish'
  | 'publish';

const statusActions: Record<EditoEventStatus, EditoAction[]> = {
  draft: ['edit', 'delete'],
  scheduled: ['edit', 'moveToDraft'],
  featured: ['edit', 'removeFromFeatured'],
  published: ['edit', 'unpublish'],
  unpublished: ['edit', 'publish', 'delete'],
};

export const EditoEventListActions = ({ editoEvent, refetch }: Props) => {
  const { mutateAsync: deleteEditoEvent } = useDeleteEditoEvents();
  const { mutateAsync: finishEditoEvent } = useFinishEditoEvents();
  const { mutateAsync: publishEditoEvent } = usePublishEditoEvents();
  const { mutateAsync: unpublishEditoEvent } = useUnpublishEditoEvents();

  const actionsLabel: Record<EditoAction, React.ReactNode> = {
    edit: (
      <Link to={`edito-events/${editoEvent.id}/edit`}>
        <EditoActionLabel
          title="Edit"
          icon={<EditOutlined className="icon" />}
        />
      </Link>
    ),
    delete: (
      <EditoActionLabel
        onClick={() => {
          confirm({
            title: 'Delete event',
            content: `Your event "${editoEvent.title}" will be deleted and you will no longer be able to access it.`,
            icon: <ExclamationCircleOutlined style={{ color: '#FF382C' }} />,
            okText: 'Delete',
            okButtonProps: {
              style: {
                backgroundColor: '#FF382C',
                color: 'white',
              },
            },
            cancelText: 'Cancel',
            async onOk() {
              await deleteEditoEvent({ id: editoEvent.id.toString() });
              await refetch();
            },
          });
        }}
        danger
        title="Delete"
        icon={<DeleteOutlined className="icon" />}
      />
    ),
    moveToDraft: (
      <EditoActionLabel
        title="Move to draft"
        icon={<InboxOutlined className="icon" />}
        onClick={async () => {
          await unpublishEditoEvent({ id: editoEvent.id.toString() });
          await refetch();
        }}
      />
    ),
    unpublish: (
      <EditoActionLabel
        title="Unpublish"
        onClick={() => {
          confirm({
            title: 'Unpublish event',
            content: `Users will lose access to your event "${editoEvent.title}"`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Unpublish',
            okButtonProps: {
              style: {
                backgroundColor: '#FF2E63',
                color: 'white',
              },
            },
            cancelText: 'Cancel',
            async onOk() {
              await unpublishEditoEvent({ id: editoEvent.id.toString() });
              await refetch();
            },
          });
        }}
        icon={<PauseCircleOutlined className="icon" />}
      />
    ),
    publish: (
      <EditoActionLabel
        title="Publish"
        icon={<PlayCircleOutlined className="icon" />}
        onClick={async () => {
          await publishEditoEvent({ id: editoEvent.id.toString() });
          await refetch();
        }}
      />
    ),
    removeFromFeatured: (
      <EditoActionLabel
        title="Remove from Featured"
        icon={<LogoutOutlined className="icon" />}
        onClick={() => {
          confirm({
            title: 'Remove event from Featured',
            content: `Your event "${editoEvent.title}" be remove from the product Homepage.`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Remove',
            okButtonProps: {
              style: {
                backgroundColor: '#FF2E63',
                color: 'white',
              },
            },
            cancelText: 'Cancel',
            async onOk() {
              await finishEditoEvent({ id: editoEvent.id.toString() });
              await refetch();
            },
          });
        }}
      />
    ),
  };

  const actionsByStatus = statusActions[editoEvent.status];

  return (
    <div className="editoListActionsContainer">
      <Dropdown
        menu={{
          items: actionsByStatus.map((action) => {
            return {
              key: action,
              label: actionsLabel[action],
            };
          }),
        }}
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );
};
