import { AnyAction } from '@reduxjs/toolkit';

import entitiesActions from 'app/redux/actions/entities';
import navigationActions from 'app/redux/actions/navigation';

type Config = {
  loading: boolean;
  appReady: boolean;
  apiUrl: string;
};

type EntityID = Exclude<string, keyof Config>;

type EntitiesState = Partial<Record<EntityID, any>>;

export type Navigation = Partial<Config> & EntitiesState;

function navigation(state: Navigation = {}, action: AnyAction): Navigation {
  switch (action.type) {
    case navigationActions.APP_READY:
      return {
        ...state,
        appReady: true,
      };
    case entitiesActions.START_FETCH_ENTITY:
      return {
        ...state,
        [action.entity]: { ...state[action.entity], loading: true },
      };
    case entitiesActions.FETCH_ENTITY_SUCCESS:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          loading: false,
          ready: true,
        },
      };
    case entitiesActions.FETCH_ENTITY_SUCCESS_WITH_PARAMS:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          loading: false,
          ready: true,
        },
      };
    case navigationActions.SET_CURRENCY:
      return {
        ...state,
        currency: action.currency,
      };
    default:
      return state;
  }
}

export default navigation;
