import React from 'react';

import ColorFormItem from '../ColorFormItem/ColorFormItem';
import MessageFormItem from '../MessageFormItem/MessageFormItem';

const PrintEditForm = () => (
  <>
    <ColorFormItem />
    <MessageFormItem />
  </>
);

export default PrintEditForm;
