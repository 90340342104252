import { message } from 'antd';
import { all, call, select, takeEvery } from 'redux-saga/effects';

import * as authActions from 'app/redux/actions/auth';
import { post, requestGenerator } from 'app/redux/requests';

function* redirectSetUserCookie({ token, redirect }) {
  const appUrl = yield select((state) => state.navigation.appUrl);
  const url = `${appUrl}/fr/set-cookie?token=${token}${
    redirect ? `&next=${redirect}` : ''
  }`;

  window.location.replace(url);
}

function* initPassword({ token, email, password, redirect }) {
  yield requestGenerator(
    () => null,
    () => post('admin/init-password', { email, password, token }),
    (loginToken) =>
      call(redirectSetUserCookie, { token: loginToken, redirect }),
    () => message.error('Failed to init password !'),
    { expectJson: false }
  );
}

export default function* authSaga() {
  yield all([takeEvery(authActions.actions.TRY_INIT_PASSWORD, initPassword)]);
}
