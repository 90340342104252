import { Checkbox, Form, Tooltip } from 'antd';
import React from 'react';

import './CheckboxMandatory.scss';

type Props = {
  mode: 'view' | 'edit';
  property: 'bookingConfirmation' | 'stock' | 'billing';
  index: number;
  disabled: boolean;
};

const tooltipTexts = {
  bookingConfirmation:
    'Reservation confirmations must be received by at least 1 person. Make sure the people in charge of reservations have the box checked.',
  stock:
    'Stock updates must be received by at least 1 person. Make sure the people in charge of inventory management have the box checked.',
  billing:
    'Add a contact in the "contact for billing" section to uncheck this box',
};

export const CheckboxMandatory = ({
  mode,
  index,
  property,
  disabled,
}: Props) => {
  const shouldShowToolTip = mode === 'edit' && disabled;

  if (!shouldShowToolTip) {
    return (
      <div className="notifEmailContainer">
        <Form.Item name={[index, property]} valuePropName="checked">
          <Checkbox disabled={disabled} />
        </Form.Item>
      </div>
    );
  }

  return (
    <Tooltip title={tooltipTexts[property]} trigger="hover">
      <div className="notifEmailContainer">
        <Form.Item name={[index, property]} valuePropName="checked">
          <Checkbox disabled={disabled} />
        </Form.Item>
      </div>
    </Tooltip>
  );
};
