import { ArrowRightOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import type { RangeValue } from 'rc-picker/lib/interface';
import React from 'react';

import './RangePicker.scss';

type Props = {
  value: RangeValue<Moment | null>;
  onChange: (value: RangeValue<Moment>) => void;
  editing: boolean;
  label: string;
  id: string;
};

const { RangePicker: RangePickerAnt } = DatePicker;

const RangePicker = ({ value, onChange, editing, id, label }: Props) => {
  const compToRender = editing ? (
    <RangePickerAnt
      value={value}
      onCalendarChange={onChange}
      format="DD-MM-YYYY"
      getPopupContainer={(trigger) => trigger.parentElement || trigger}
    />
  ) : (
    <div>
      {value && value[0]?.format('DD-MM-YYYY')}
      <ArrowRightOutlined />
      {value && value[1]?.format('DD-MM-YYYY')}
    </div>
  );

  return (
    <div className="input-datepicker">
      <label htmlFor={id}>{label}</label>
      {compToRender}
    </div>
  );
};

export default RangePicker;
