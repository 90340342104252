import _ from 'lodash';
import React from 'react';

import {
  GiftCard,
  IncentiveGiftCardProperties,
} from 'app/redux/models/GiftCard/GiftCard';

import Line from '../Line/Line';

type Props = {
  giftCard: GiftCard & IncentiveGiftCardProperties;
};

const IncentiveProperties = ({ giftCard }: Props) => {
  const {
    giftCardType,
    beneficiaryName,
    minAmount,
    onlyFirstBooking,
    maxUse,
    startDate,
  } = giftCard;

  return (
    <>
      <Line title="Type" value={_.capitalize(giftCardType)} />
      <Line title="Beneficiary name" value={beneficiaryName} />
      <Line title="Minimum code use amount" value={minAmount} />
      <Line title="Max use" value={maxUse} />
      <Line
        title="Only first booking"
        value={onlyFirstBooking ? 'true' : 'false'}
      />
      <Line title="Start date" value={startDate} />
    </>
  );
};

export default IncentiveProperties;
