import { PageHeader } from 'antd';
import { FC, ReactNode } from 'react';

import './SHeader.scss';

const SHeader: FC<Props> = ({
  title,
  subtitle,
  extra,
  onBack,
  className,
  children,
}) => (
  <PageHeader
    title={title}
    subTitle={subtitle}
    extra={extra}
    onBack={onBack}
    className={`sheader ${children ? 'sheader-children' : ''} ${
      className || ''
    }`}
  >
    {children}
  </PageHeader>
);

type Props = {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  extra?: Array<ReactNode>;
  onBack?: () => void;
  className?: string;
  children?: ReactNode;
};

export default SHeader;
