import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Switch } from 'antd';
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Option, Select } from 'app/components/fields/Select';
import { tryFetchHotels } from 'app/redux/actions/hotels';
import { IHotel } from 'app/typings';
import { hasBabyBed, maxRoomCapacity } from 'app/utils/room';
import { pluralize } from 'app/utils/strings';

import BabyLogo from '../../icons/BabyLogo';
import { BedCategory, RoomCategory } from '../Types';

import './BasicInfo.scss';
import HotelSelect from './HotelSelect/HotelSelect';

const RULES = [{ required: true, message: 'Required' }];

export const BasicInfo: FC<Props> = ({
  roomCategories,
  hotels,
  hotelSelect = false,
  mode = 'edit',
  values,
  fetchHotels,
  bedCategories,
}) => {
  useEffect(() => {
    if (hotelSelect) {
      fetchHotels();
    }
  }, [fetchHotels, hotelSelect]);

  return (
    <div className="basic-info">
      {!!hotelSelect && !!hotels && !!values && (
        <HotelSelect
          hotels={hotels}
          hotelId={values.hotelId}
          mode={mode}
          disabled={hotelSelect === 'disabled'}
        />
      )}
      <div className="input-row">
        <Form.Item name="categoryId" label="Category type" rules={RULES}>
          {mode === 'edit' || !values ? (
            <Select
              placeholder="Select a category"
              showSearch
              optionFilterProp="children"
            >
              {roomCategories.map((category) => (
                <Option key={category.id} value={category.id}>
                  {category.name}
                </Option>
              ))}
            </Select>
          ) : (
            <div className="view-text">
              {roomCategories.find((cat) => cat.id === values.categoryId)?.name}
            </div>
          )}
        </Form.Item>
      </div>
      <div className="input-row">
        <Form.Item name="name" label="Room name">
          {mode === 'edit' || !values ? (
            <Input />
          ) : (
            <div className="view-text">{values.name}</div>
          )}
        </Form.Item>
        {mode === 'edit' && (
          <div className="description">
            <InfoCircleOutlined />
            <div>
              If this category has a specific name, write it in your local
              language
            </div>
          </div>
        )}
      </div>
      <div className="input-row">
        <Form.Item
          name="categoryRoomCount"
          label="Category room count"
          rules={RULES}
        >
          {mode === 'edit' || !values ? (
            <InputNumber min={0} />
          ) : (
            <div className="view-text">{values.categoryRoomCount}</div>
          )}
        </Form.Item>
      </div>
      <div className="input-row area">
        <Form.Item name="area" label="Room size" rules={RULES}>
          {mode === 'edit' || !values ? (
            <InputNumber min={0} />
          ) : (
            <div className="view-text">{`${values.area}m²`}</div>
          )}
        </Form.Item>
      </div>
      <div className="input-row">
        <Form.Item className="no-margin-bottom" label="Max. room capacity">
          {mode === 'edit' || !values ? (
            <div className="maxRoomContainer">
              <Input
                className="fixed-width-number-input "
                disabled
                value={maxRoomCapacity(values.beds, bedCategories)}
                suffix={<UserOutlined />}
              />
              {hasBabyBed(values.beds) && (
                <Input
                  className="fixed-width-number-input "
                  disabled
                  value={hasBabyBed(values.beds) ? 1 : 0}
                  suffix={<BabyLogo color="#B3B3B3" />}
                />
              )}
            </div>
          ) : (
            <div className="view-text">
              {pluralize(
                maxRoomCapacity(values.beds, bedCategories),
                'guest',
                'guests'
              )}
              {hasBabyBed(values.beds) && ' + 1 baby'}
            </div>
          )}
        </Form.Item>
        <div className="description">
          <InfoCircleOutlined />
          <div>The room capacity depends on your bed capacity</div>
        </div>
      </div>
      <div className="input-row">
        <Form.Item name="hasPRM" label="PRM access" valuePropName="checked">
          <Switch disabled={mode === 'view'} />
        </Form.Item>
      </div>
    </div>
  );
};

type Props = {
  roomCategories: Array<RoomCategory>;
  hotels: Array<IHotel>;
  hotelSelect?: string;
  mode?: string;
  values?: any;
  fetchHotels: () => void;
  bedCategories: Array<BedCategory>;
};

const mapStateToProps = (state: any) => ({
  roomCategories: state.conf.roomCategories,
  hotels: state.hotels.hotels,
  bedCategories: state.conf.bedCategories,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchHotels: () => dispatch(tryFetchHotels()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
