import { Form, Layout } from 'antd';
import React, { useCallback, useEffect } from 'react';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import {
  InputRow,
  InputTextAreaRow,
  PictureRow,
  SelectRow,
} from 'app/components/commons/CustomFields/CustomFields';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { fetchGuide, saveGuide } from 'app/redux/actions/guides';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import type { Guide } from 'app/redux/models/Guides/Guides';
import { selectClubs, selectGuideCategories } from 'app/redux/selectors/conf';
import {
  selectGuidesGuide,
  selectGuidesLoading,
} from 'app/redux/selectors/guides';
import { slugify } from 'app/utils/strings';

import './Guide.scss';
import GuideSections from './GuideSections/GuideSections';

const { Content } = Layout;

const DEFAULT_VALUES = {
  sections: [{ packages: [{}] }],
};

type Props = {
  match: {
    params?: {
      id?: string;
    };
    path?: string;
  };
  location: {
    state?: any;
  };
};

const GuideComponent = ({ match, location }: Props) => {
  const isNew = match.path?.includes('/new');
  const guideId = match.params?.id || '';
  const parsedGuideId = parseInt(guideId, 10);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const guide = useAppSelector(selectGuidesGuide);
  const loading = useAppSelector(selectGuidesLoading);
  const guideCategories = useAppSelector(selectGuideCategories);
  const clubs = useAppSelector(selectClubs);

  useEffect(() => {
    if (guideId) {
      dispatch(fetchGuide({ guideId }));
    }
  }, [dispatch, guideId]);

  useEffect(() => {
    if (!loading) {
      form.resetFields();
      // This part (really) reinitialize the form after a re-fetch of the guide,
      // otherwise values like section.id would not appear when submitting
    }
  }, [loading, form]);

  const onSubmit = useCallback(
    (values: any) => {
      dispatch(
        saveGuide({
          id: (!isNew && guide?.id) || undefined,
          featured: (!isNew && guide?.featured) || false,
          isHybrid: (!isNew && guide?.isHybrid) || false,
          isDuplicate: guide?.isDuplicate,
          ...values,
        })
      );
    },
    [dispatch, guide, isNew]
  );

  const onChange = (newValues: any) => {
    if (newValues.name) {
      form.setFieldsValue({
        slug: slugify(newValues.name),
      });
    }
  };

  const initialValues =
    guide && (guide.id === parsedGuideId || guide.isDuplicate)
      ? guide
      : undefined;

  if (loading || (!isNew && !initialValues)) {
    return null;
  }

  return (
    <Layout className="guide-layout">
      <FormLegacyRenderProp
        form={form}
        onFinish={onSubmit}
        initialValues={initialValues || DEFAULT_VALUES}
        onValuesChange={onChange}
      >
        {(values: Partial<Guide>, { isFieldsTouched, submit }) => (
          <SDetailLayout
            title={initialValues?.name || 'New guide'}
            mode="edit"
            onSave={submit}
            isDirty={isFieldsTouched()}
            backRoute={location.state?.isNew ? '/guides' : undefined}
          >
            <Content className="page-content">
              <SCard title="Basic information">
                <h3>Header</h3>
                <InputRow
                  label="Title"
                  name="name"
                  placeholder="Valentine's day, Detox, Couple Anniversary..."
                  required
                />
                <InputRow
                  label="Slug"
                  name="slug"
                  placeholder="valentine-day"
                  required
                  disabled={!isNew}
                />
                <InputRow
                  label="Qualifier"
                  name="qualifier"
                  placeholder="(XX staycations) to celebrate your couple's anniversary"
                  required
                />
                <InputRow
                  label="Page Title"
                  name="pageTitle"
                  placeholder="Valentine's day: a weekend with your carnal"
                  required
                />
                <PictureRow label="Cover Picture" name="coverPicture" />
                <h3>Meta</h3>
                <SelectRow
                  label="Club"
                  name="clubId"
                  placeholder="Paris, Riviera"
                  required
                  showSearch
                  dataSource={clubs}
                />
                <SelectRow
                  label="Category"
                  name="categoryId"
                  placeholder="Mood, Experiences"
                  required
                  showSearch
                  dataSource={guideCategories}
                />
              </SCard>
              <SCard title="Introduction">
                <InputRow
                  label="Title"
                  name="mainTitle"
                  placeholder="Valentine's day: a weekend with your Rod"
                  required
                />
                <InputTextAreaRow
                  label="Introduction"
                  name="introduction"
                  placeholder="Enjoy your Valentine's day like never with the best selection of hotels in your city"
                  required
                />
              </SCard>
              <Form.List name="sections">
                {(fields, { add, remove, move }) => (
                  <GuideSections
                    fields={fields}
                    add={add}
                    remove={remove}
                    move={move}
                    values={values}
                  />
                )}
              </Form.List>
              <SCard title="Conclusion">
                <InputTextAreaRow
                  label="Conclusion"
                  name="conclusion"
                  placeholder="Like Rod used to say: Enjoy your staycation!"
                  required
                />
              </SCard>
            </Content>
          </SDetailLayout>
        )}
      </FormLegacyRenderProp>
    </Layout>
  );
};

export default GuideComponent;
