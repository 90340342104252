import { Checkbox, Layout, Select } from 'antd';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import { CustomRow } from 'app/components/commons/CustomFields/CustomFields';
import { useCustomTags } from 'app/hooks/data/useCustomTags';
import { usePackageTags } from 'app/hooks/data/usePackageTags';
import { usePackageTagsSave } from 'app/hooks/data/usePackageTagsSave';
import { useAppSelector } from 'app/redux/hooks';
import { cleanString } from 'app/utils/sortAndFilter';

const MODE = {
  VIEW: 'view',
  EDIT: 'edit',
} as const;

const { Content } = Layout;

type Option = {
  label: string;
  value: number;
  searchKey: string;
};

type Mode = (typeof MODE)[keyof typeof MODE];

type FormState = {
  mode: Mode;
  isDirty: boolean;
  tagIds: number[] | null;
  isGem: boolean | null;
};

type Props = {
  headerMenu: ReactNode;
  headerExtra: Array<ReactNode>;
  packageId?: number;
};

export const Tags = ({ headerMenu, headerExtra, packageId }: Props) => {
  if (!packageId) {
    return null;
  }

  return (
    <TagsOfPackage
      headerMenu={headerMenu}
      headerExtra={headerExtra}
      packageId={packageId}
    />
  );
};

export const TagsOfPackage = ({
  headerMenu,
  headerExtra,
  packageId,
}: Required<Props>) => {
  const packageName = useAppSelector(
    (state) => state.packages.copywriting.name
  );
  const [formState, setFormState] = useState<FormState>({
    mode: MODE.VIEW,
    isDirty: false,
    tagIds: null,
    isGem: null,
  });

  const customTags = useCustomTags();
  const packageTags = usePackageTags(packageId);
  const { mutateAsync: savePackageTags } = usePackageTagsSave(packageId);

  const options: Option[] | null = useMemo(() => {
    if (!customTags.data) {
      return null;
    }

    return customTags.data.map((customTag) => ({
      label: customTag.name,
      value: customTag.id,
      searchKey: cleanString(customTag.name),
    }));
  }, [customTags.data]);

  useEffect(() => {
    if (packageTags.data) {
      setFormState((previousState) => ({
        mode: previousState.mode,
        isDirty: previousState.isDirty,
        tagIds: packageTags.data.tagIds,
        isGem: packageTags.data.isGem,
      }));
    }
  }, [packageTags.data]);

  const onSave = useCallback(async () => {
    if (packageId && formState.tagIds !== null && formState.isGem !== null) {
      await savePackageTags({
        tagIds: formState.tagIds,
        isGem: formState.isGem,
      });
    }

    setFormState((previousState) => ({
      mode: MODE.VIEW,
      isDirty: false,
      tagIds: previousState.tagIds,
      isGem: previousState.isGem,
    }));
  }, [formState.isGem, formState.tagIds, packageId, savePackageTags]);

  const onFilter = (input: string, option?: Option) => {
    if (!option) {
      return false;
    }

    if (option.value === -1) {
      return true;
    }

    const cleanInput = cleanString(input);

    return option?.searchKey.includes(cleanInput);
  };

  if (
    !packageId ||
    !options ||
    formState.tagIds === null ||
    formState.isGem === null
  ) {
    return null;
  }

  return (
    <SDetailLayout
      title={packageName || `Package #${packageId}`}
      mode={formState.mode}
      onEdit={() => {
        setFormState((previousState) => ({
          ...previousState,
          mode: MODE.EDIT,
        }));
      }}
      onSave={onSave}
      onCancel={() => {
        setFormState((previousState) => ({
          ...previousState,
          mode: MODE.VIEW,
          isDirty: false,
        }));
      }}
      reset={() => {}}
      isDirty={formState.isDirty}
      headerMenu={headerMenu}
      headerExtra={headerExtra}
      isValid
    >
      <Content className="package-detail-content-tags">
        <SCard title="Tags">
          <CustomRow label="Custom tags" name="" noStyle>
            <Select
              value={formState.tagIds}
              onChange={(value: number[]) => {
                setFormState((previousState) => ({
                  ...previousState,
                  tagIds: value,
                  isDirty: true,
                }));
              }}
              showSearch
              filterOption={onFilter}
              options={options}
              mode="multiple"
              disabled={formState.mode !== MODE.EDIT}
            />
          </CustomRow>
          <CustomRow label="Gem" name="" noStyle>
            <Checkbox
              disabled={formState.mode !== MODE.EDIT}
              checked={!!formState.isGem}
              onChange={(event) => {
                setFormState((previousState) => ({
                  ...previousState,
                  isGem: event.target.checked,
                  isDirty: true,
                }));
              }}
            />
          </CustomRow>
        </SCard>
      </Content>
    </SDetailLayout>
  );
};

export default Tags;
