import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import * as confActions from 'app/redux/actions/conf';
import * as entitiesActions from 'app/redux/actions/entities';
import * as hotelAdminActions from 'app/redux/actions/hotelAdmin';
import * as navigationActions from 'app/redux/actions/navigation';
import { appReady, setCurrency } from 'app/redux/actions/navigation';
import { fetchEntity } from 'app/redux/sagas/entities';
import { tryFetchEmojis } from 'app/redux/sagas/pictures';
import { currencySelector, entityFormSelector } from 'app/redux/selectors';
import { getNewExtranetUrl } from 'app/utils/extranet';
import { removeUserCookie, setUserCookie } from 'app/utils/request/api';
import { amplitudeInit } from 'app/utils/track/amplitude';
import { hotjarIdentify } from 'app/utils/track/hotjar';

export function* setEntityContext({ entity, entityId }) {
  const fetchedEntity = yield select(entityFormSelector(entity, entityId));
  const currency = yield select(currencySelector(fetchedEntity));

  if (currency) {
    yield put(setCurrency(currency));
  }
}

export function* authenticateUser(app) {
  const isExtranet = app === 'extranet';

  const {
    query: { hotelId },
  } = yield select((s) => s.router.location);
  const { appUrl, apiUrl, amplitudeKey } = yield select(
    (state) => state.navigation
  );

  try {
    const res = yield axios.get(`${appUrl}/auth`, {
      withCredentials: true,
      headers: {
        'Cache-Control': 'no-store',
      },
    });
    const token = res.data;

    if (token) {
      const decodedToken = jwtDecode(token);

      const user = decodedToken.user;
      const userIsAdmin = user.role === 'admin' || user.role === 'superadmin';

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      let userWithRole;

      if (isExtranet && !userIsAdmin) {
        const resHotelRole = yield axios.get(
          `${apiUrl}/hotels/${hotelId ?? user.hotelIds[0]}/user-role?userId=${
            user.id
          }`,
          config
        );

        userWithRole = {
          ...user,
          hotelRole: resHotelRole.data.role ?? '',
        };
      }

      if (user.hotelIds.length === 0 && !userIsAdmin) {
        yield window.location.replace(appUrl);

        return;
      }

      setUserCookie(token);

      yield put(navigationActions.userLoggedIn(user, token));
      amplitudeInit(
        isExtranet && !userIsAdmin ? userWithRole : user,
        amplitudeKey
      );
      hotjarIdentify(user);

      if (window.userGuiding) {
        const userHotelId =
          user.hotelIds && user.hotelIds.length
            ? user.hotelIds[0]
            : user.hotelId;

        const userGuidingPayload = {
          hotel_id: userHotelId,
          email: user.email,
          role: user.role,
          club: user.club,
          created_at: user.createdAt,
        };

        window.userGuiding.identify(user.id, userGuidingPayload);
      }
    }
  } catch (e) {
    removeUserCookie();

    const currentUrl = window.location.href;

    yield window.location.replace(`${appUrl}/fr?login=true&next=${currentUrl}`);
  }
}

export function* initApp({ app }) {
  yield call(authenticateUser, app);
  yield put(confActions.initConf({ app }));

  if (app === 'extranet') {
    yield call(fetchEntity, { entity: 'hotels' });

    const {
      query: { hotelId },
    } = yield select((s) => s.router.location);
    const user = yield select((s) => s.auth.user);
    const hotels = yield select((s) => s.entities.hotels);

    if (!hotelId) {
      let newHotelId;

      if (user.hotelIds?.length) {
        newHotelId = user.hotelIds[0];
      } else if (
        Object.keys(hotels).length &&
        (user.role === 'admin' || user.role === 'superadmin')
      ) {
        newHotelId = parseInt(Object.keys(hotels)[0]);
      }

      if (newHotelId) {
        const path = getNewExtranetUrl(newHotelId);

        window.history.pushState({ path: path }, '', path);
      }

      yield put(
        hotelAdminActions.updateHotelId({ hotelId: parseInt(newHotelId) })
      );
    } else {
      yield put(
        hotelAdminActions.updateHotelId({ hotelId: parseInt(hotelId) })
      );
    }
  }

  if (app === 'admin') {
    yield all([
      call(fetchEntity, { entity: 'countries' }),
      call(fetchEntity, { entity: 'clubs' }),
      call(fetchEntity, { entity: 'hotelGroups' }),
      call(tryFetchEmojis),
    ]);
  }

  yield put(appReady());
}

export default function* entitiesSagas() {
  yield all([
    takeEvery(navigationActions.actions.INIT_APP, initApp),
    takeEvery(entitiesActions.actions.GET_ENTITY_SUCCESS, setEntityContext),
  ]);
}
