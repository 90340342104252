import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import classNames from 'classnames';
import React from 'react';

import { HOTEL_POSITION_ENUM } from '../../BookingContact/Utils';

import './NotifEmails.scss';

const NotifEmails = () => (
  <div className="notif-emails">
    <Form.List name="notifEmails">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Row className="email-row" key={field.key}>
              <Col span={20}>
                <div
                  className={classNames([
                    'formEmailPosition',
                    {
                      'with-delete': field.key !== 0,
                    },
                  ])}
                >
                  <Form.Item
                    {...field}
                    label={`Email #${index + 1}`}
                    name={[index, 'email']}
                    rules={[
                      { required: true, message: 'Required' },
                      { type: 'email', message: 'Please enter a valid email' },
                    ]}
                  >
                    <div className={field.key !== 0 ? 'flex' : ''}>
                      <Input type="email" />
                      {field.key !== 0 && (
                        <DeleteOutlined
                          className="delete-icon"
                          onClick={() => remove(field.name)}
                        />
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item {...field} label="Position" name={[index, 'role']}>
                    <Select
                      placeholder="Select position"
                      options={[
                        ...HOTEL_POSITION_ENUM.map((position) => ({
                          label: position,
                          value: position.toLowerCase(),
                        })),
                      ]}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          ))}
          <Row className="email-row" key="add-email-key">
            <Col span={20}>
              <Form.Item label=" ">
                <Button icon={<PlusOutlined />} onClick={() => add()}>
                  Add email
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </Form.List>
  </div>
);

export default NotifEmails;
