import { IPictureForm } from 'app/typings/misc';

export type CollectionItem = {
  readonly clubId: number;
  readonly countryId: number;
  readonly emoji: IPictureForm;
  readonly cover?: IPictureForm;
  readonly id: number;
  readonly name: string;
  readonly published: boolean;
  readonly updatedAt: string;
  readonly slug: string;
  readonly sectionCount: number;
  readonly trendDisplay: boolean;
  readonly cardDisplay: boolean;
  readonly sliceDisplay: boolean;
  readonly searchDisplay: boolean;
  readonly searchName?: string;
};

export function createCollectionItem(values: CollectionItem): CollectionItem {
  const {
    clubId,
    countryId,
    emoji,
    cover,
    id,
    name,
    published,
    updatedAt,
    slug,
    sectionCount,
    trendDisplay,
    cardDisplay,
    sliceDisplay,
    searchDisplay,
    searchName,
  } = values;

  return {
    clubId,
    countryId,
    emoji,
    cover,
    id,
    name,
    published,
    updatedAt,
    slug,
    sectionCount,
    trendDisplay,
    cardDisplay,
    sliceDisplay,
    searchDisplay,
    searchName,
  };
}
