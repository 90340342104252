import { ArrowRightOutlined, CheckCircleOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';

import { Badge } from 'app/components/pages/Extranet/ExtranetDashboard/_components/Badge/Badge';
import { Task } from 'app/components/pages/Extranet/commons/TaskCenter/Types';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';

import { Tasks } from '../../../commons/TaskCenter/Tasks/Tasks';

import './DashboardTaskCenter.scss';

const TASK_SOURCE = 'preview';

export const DashboardTaskCenter = () => {
  const { openTaskCenter, missingTask, doneTask, clickOpenTask } =
    useContext(TaskCenterContext);

  const nbTasks = missingTask.length + doneTask.length;

  const onClickUpdateButton = (taskInfo: Task) => {
    openTaskCenter();

    clickOpenTask(taskInfo, TASK_SOURCE);
  };

  return (
    <div className="extranet_taskCenter">
      <div className="taskCenter_header">
        <div className="taskCenter_title">
          Task center
          <Badge
            type={doneTask.length === nbTasks ? 'success' : 'danger'}
            value={`${doneTask.length} / ${nbTasks} completed`}
            isBold={true}
            style={{ padding: '1px 8px' }}
          />
        </div>
        <ArrowRightOutlined width={20} onClick={openTaskCenter} />
      </div>
      <div className="taskCenter_tasks">
        {missingTask.length > 0 && (
          <div className="tasks_wrappaer">
            <div className="taskCenter_task_subtitle">To do</div>

            {missingTask.map((task) => (
              <Tasks
                taskInfo={task}
                clickButton={() => onClickUpdateButton(task)}
                source={TASK_SOURCE}
                fromDashboard
              />
            ))}
          </div>
        )}
        {doneTask.length > 0 && (
          <div className="tasks_wrappaer">
            <div className="taskCenter_task_subtitle">
              <CheckCircleOutlined />
              Done
            </div>

            {doneTask.map((task) => (
              <Tasks
                taskInfo={task}
                clickButton={() => onClickUpdateButton(task)}
                source={TASK_SOURCE}
                fromDashboard
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
