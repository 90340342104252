import { InputNumber } from 'antd';
import styled from 'styled-components';

const CustomInput = styled(InputNumber)<CustomProps>`
  .ant-input-number-input-wrap {
    &::after {
      top: 8px;
      right: 26px;
      line-height: 16px;
      position: absolute;
      color: #bfbfbf;
      font-size: 14px;
      content: '${(props) => props.suffix}';
    }
  }
`;

type CustomProps = {
  suffix?: string;
};

export default CustomInput;
