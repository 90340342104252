import { LinkOutlined } from '@ant-design/icons';
import { Form, FormInstance, FormListFieldData, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { EditoActionType, IEditoEventForm } from 'app/typings/edito';

import './EditoSectionActionForm.scss';
import { LinkRedirectionForm } from './LinkRedirectionForm/LinkRedirectionForm';

const ActionItem = ({
  title,
  icon,
}: {
  title: string;
  icon?: React.ReactNode;
}) => {
  return (
    <div className="editoSectionActionItemContainer">
      {icon}
      <span className="title">{title}</span>
    </div>
  );
};

const options: DefaultOptionType[] = [
  {
    value: 'empty',
    label: <ActionItem title="No action" />,
  },
  //TODO: uncomment to add search option {
  //   value: 'search',
  //   label: <ActionItem title="Search redirection" icon={<SearchOutlined />} />,
  // },
  {
    value: 'link',
    label: <ActionItem title="Link redirection" icon={<LinkOutlined />} />,
  },
];

type ActionValue = 'empty' | EditoActionType;

const actionComponent = (
  field: FormListFieldData
): Record<ActionValue, React.ReactNode> => {
  return {
    empty: <></>,
    link: <LinkRedirectionForm field={field} />,
    // search: <SearchRedirectionForm field={field} />, TODO: uncomment to add search option
  };
};

interface Props {
  field: FormListFieldData;
  form: FormInstance<IEditoEventForm>;
}

export const EditoSectionActionForm = ({ field, form }: Props) => {
  const actionType = Form.useWatch(
    ['sections', field.name, 'actionType'],
    form
  );

  return (
    <div>
      <Form.Item name={[field.name, 'actionType']}>
        <Select<ActionValue> options={options} />
      </Form.Item>

      {actionComponent(field)[actionType ?? 'empty']}
    </div>
  );
};
