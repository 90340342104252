import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import {
  resetDirtyOpenings,
  tryFetchHotels,
  tryFetchInventory,
} from 'app/redux/actions/hotelAdmin';
import * as hotelAdminActions from 'app/redux/actions/hotelAdmin';
import { useAppDispatch } from 'app/redux/hooks';
import { IAutomateDiscountPayload } from 'app/typings/automateDiscount';
import { apiMutation } from 'app/utils/request/api';

export const path = '/hotel-admin/:hotelId/automate-openings' as const;

export function useAutomateDiscountSave(
  hotelId: number,
  start?: Date | string
) {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: async (payload: IAutomateDiscountPayload) =>
      apiMutation(
        'POST',
        {
          path,
          params: { hotelId: hotelId.toString() },
        },
        payload
      ) as Promise<{ discount: number }>,
    onSuccess: (_, payload) => {
      dispatch(tryFetchInventory({ hotelId, start }));
      dispatch(resetDirtyOpenings());
      dispatch(tryFetchHotels());
      message.success('Automated discount enabled');
    },
    onError: (err) => {
      message.error('Error saving automated discount');
    },
    onSettled: () => {
      dispatch(hotelAdminActions.stopLoading());
    },
    onMutate: () => {
      dispatch(hotelAdminActions.startLoading());
    },
  });
}

export function useAutomateDiscountDelete(
  hotelId: number,
  start?: Date | string
) {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: async (hotelId: number) =>
      apiMutation('DELETE', {
        path,
        params: { hotelId: hotelId.toString() },
      }) as Promise<[]>,
    onSuccess: (_) => {
      dispatch(tryFetchInventory({ hotelId, start }));
      dispatch(tryFetchHotels());
      dispatch(resetDirtyOpenings());
      message.success('Automated discount disabled');
    },
    onError: (err) => {
      message.error('Error deleting automated discount');
    },
    onSettled: () => {
      dispatch(hotelAdminActions.stopLoading());
    },
    onMutate: () => {
      dispatch(hotelAdminActions.startLoading());
    },
  });
}
