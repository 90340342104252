import { message } from 'antd';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import * as confActions from 'app/redux/actions/conf';
import { createBedCategory } from 'app/redux/models/BedCategory/BedCategory';
import type { BedCategory } from 'app/redux/models/BedCategory/BedCategory';
import { createCancellationReason } from 'app/redux/models/CancellationReason/CancellationReason';
import type { CancellationReason } from 'app/redux/models/CancellationReason/CancellationReason';
import { createClub } from 'app/redux/models/Club/Club';
import type { Club } from 'app/redux/models/Club/Club';
import { createCountry } from 'app/redux/models/Country/Country';
import type { Country } from 'app/redux/models/Country/Country';
import { createExperienceCategory } from 'app/redux/models/ExperienceCategory/ExperienceCategory';
import type { ExperienceCategory } from 'app/redux/models/ExperienceCategory/ExperienceCategory';
import { createGuideCategory } from 'app/redux/models/GuideCategory/GuideCategory';
import type { GuideCategory } from 'app/redux/models/GuideCategory/GuideCategory';
import { createHotelGroup } from 'app/redux/models/HotelGroup/HotelGroup';
import type { HotelGroup } from 'app/redux/models/HotelGroup/HotelGroup';
import { createHotelServiceType } from 'app/redux/models/HotelServiceType/HotelServiceType';
import type { HotelServiceType } from 'app/redux/models/HotelServiceType/HotelServiceType';
import { createRoomCategory } from 'app/redux/models/RoomCategory/RoomCategory';
import type { RoomCategory } from 'app/redux/models/RoomCategory/RoomCategory';
import { createRoomFeature } from 'app/redux/models/RoomFeature/RoomFeature';
import type { RoomFeature } from 'app/redux/models/RoomFeature/RoomFeature';
import { createSpaceType } from 'app/redux/models/SpaceType/SpaceType';
import type { SpaceType } from 'app/redux/models/SpaceType/SpaceType';
import { get, requestGenerator } from 'app/redux/requests';

export function* getRoomCategories() {
  yield requestGenerator(
    () => Promise.resolve,
    () => get('rooms/categories'),
    (result: Array<any>) => {
      const roomCategories = result.map<RoomCategory>((r) =>
        createRoomCategory(r)
      );

      return put(confActions.fetchRoomCategoriesSuccess({ roomCategories }));
    },
    () => message.error('Error fetching Room Categories')
  );
}

export function* getRoomFeatures() {
  yield requestGenerator(
    () => Promise.resolve,
    () => get('rooms/features'),
    (result: Array<any>) => {
      const roomFeatures = result.map<RoomFeature>((r) => createRoomFeature(r));

      return put(confActions.fetchRoomFeaturesSuccess({ roomFeatures }));
    },
    () => message.error('Error fetching Room Features')
  );
}

export function* getBedCategories() {
  yield requestGenerator(
    () => Promise.resolve,
    () => get('rooms/beds/categories'),
    (result: Array<any>) => {
      const bedCategories = result.map<BedCategory>((r) =>
        createBedCategory(r)
      );

      return put(confActions.fetchBedCategoriesSuccess({ bedCategories }));
    },
    () => message.error('Error fetching Bed Categories')
  );
}

export function* getHotelGroups() {
  yield requestGenerator(
    () => Promise.resolve,
    () => get('hotels/groups'),
    (result: Array<any>) => {
      const hotelGroups = result.map<HotelGroup>((r) => createHotelGroup(r));

      return put(confActions.fetchHotelGroupsSuccess({ hotelGroups }));
    },
    () => message.error('Error fetching Hotel Groups')
  );
}

export function* getClubs() {
  yield requestGenerator(
    () => Promise.resolve,
    () => get('clubs'),
    (result: Array<any>) => {
      const clubs = result.map<Club>((r) => createClub(r));

      return put(confActions.fetchClubsSuccess({ clubs }));
    },
    () => message.error('Error fetching Clubs')
  );
}

export function* getCountries() {
  yield requestGenerator(
    () => Promise.resolve,
    () => get('countries'),
    (result: Array<any>) => {
      const countries = result.map<Country>((r) => createCountry(r));

      return put(confActions.fetchCountriesSuccess({ countries }));
    },
    () => message.error('Error fetching Countries')
  );
}

export function* getCancellationReasons() {
  yield requestGenerator(
    () => Promise.resolve,
    () => get('admin/cancellation-reasons'),
    (result: Array<any>) => {
      const cancellationReasons = result.map<CancellationReason>((r) =>
        createCancellationReason(r)
      );

      return put(
        confActions.fetchCancellationReasonsSuccess({ cancellationReasons })
      );
    },
    () => message.error('Error fetching Cancellation Reasons')
  );
}

export function* selectExperienceCategories() {
  yield requestGenerator(
    () => Promise.resolve,
    () => get('experiences/categories'),
    (result: Array<any>) => {
      const experienceCategories = result.map<ExperienceCategory>((r) =>
        createExperienceCategory(r)
      );

      return put(
        confActions.fetchExperienceCategoriesSuccess({ experienceCategories })
      );
    },
    () => message.error('Error fetching Experience Categories')
  );
}

export function* selectSpaceTypes() {
  yield requestGenerator(
    () => Promise.resolve,
    () => get('hotels/spaces/types'),
    (result: Array<any>) => {
      const spaceTypes = result.map<SpaceType>((r) => createSpaceType(r));

      return put(confActions.fetchSpaceTypesSuccess({ spaceTypes }));
    },
    () => message.error('Error fetching Space Types')
  );
}

export function* getHotelsServicesTypes() {
  yield requestGenerator(
    () => Promise.resolve,
    () => get('hotels/services/types'),
    (result: Array<any>) => {
      const hotelsServicesTypes = result.map<HotelServiceType>((r) =>
        createHotelServiceType(r)
      );

      return put(
        confActions.fetchHotelsServicesTypesSuccess({ hotelsServicesTypes })
      );
    },
    () => message.error('Error fetching Hotels Services Types')
  );
}

export function* getGuideCategories() {
  yield requestGenerator(
    () => Promise.resolve,
    () => get('guides/categories'),
    (result: Array<any>) => {
      const guideCategories = result.map<GuideCategory>((r) =>
        createGuideCategory(r)
      );

      return put(confActions.fetchGuideCategoriesSuccess({ guideCategories }));
    },
    () => message.error('Error fetching Guide Categories')
  );
}

export function* initConf(action: ReturnType<typeof confActions.initConf>) {
  const { app } = action.payload;

  yield all([
    call(getRoomCategories),
    call(getRoomFeatures),
    call(getBedCategories),
    call(getHotelGroups),
    call(getClubs),
    call(getCountries),
    call(selectSpaceTypes),
    call(getHotelsServicesTypes),
  ]);

  if (app === 'builder') {
    yield call(selectExperienceCategories);
  }

  if (app === 'admin') {
    yield all([
      call(getCancellationReasons),
      call(selectExperienceCategories),
      call(getGuideCategories),
    ]);
  }
}

export default function* entitiesSagas() {
  yield all([takeEvery(confActions.CONF_INIT, initConf)]);
}
