import { DeleteOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import type { RangeValue } from 'rc-picker/lib/interface';
import React, { useCallback, useMemo } from 'react';
import { SortableElement } from 'react-sortable-hoc';

import RangePicker from 'app/components/fields/RangePicker/RangePicker';
import TextArea from 'app/components/fields/TextArea/TextArea';
import SortableHandle from 'app/components/layout/Sortable/SortableHandle';

import './DisclaimerBloc.scss';

type OnChangeArg = {
  id: number | null;
  text: string;
  startDate: string | null;
  endDate: string | null;
  temporaryId: number;
  priority: number;
};

interface IProps {
  mode: 'view' | 'edit';
  text: string;
  startDate: string | null;
  endDate: string | null;
  id: number | null;
  priority: number;
  temporaryId: number;
  onChange: (value: OnChangeArg) => void;
  onRemove: () => void;
}

export const DisclaimerBloc = ({
  mode,
  text,
  startDate,
  endDate,
  id,
  temporaryId,
  priority,
  onChange,
  onRemove,
}: IProps) => {
  const editing = mode === 'edit';
  // eslint-disable-next-line arrow-body-style
  const RangePickerValue = useMemo<RangeValue<Moment | null>>(() => {
    return [
      startDate ? moment(startDate, 'YYYY-MM-DD') : null,
      endDate ? moment(endDate, 'YYYY-MM-DD') : null,
    ];
  }, [startDate, endDate]);

  const onTextChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange({
        text: event.target.value,
        startDate,
        endDate,
        id,
        temporaryId,
        priority,
      });
    },
    [onChange, id, priority, startDate, endDate, temporaryId]
  );

  const onDateChange = useCallback(
    (rangeValue: RangeValue<Moment | null>) => {
      onChange({
        text,
        startDate: (rangeValue && rangeValue[0]?.format('YYYY-MM-DD')) || null,
        endDate: (rangeValue && rangeValue[1]?.format('YYYY-MM-DD')) || null,
        id,
        priority,
        temporaryId,
      });
    },
    [text, id, priority, temporaryId, onChange]
  );

  return (
    <div className="disclaimer-bloc">
      <div className="disclaimer-left">
        <TextArea
          editing={editing}
          value={text}
          label="Message"
          id={`${temporaryId}-textarea`}
          onChange={onTextChange}
          rows={3}
        />
        <div className="spacer" />
        <RangePicker
          editing={editing}
          value={RangePickerValue}
          label="Date range"
          id={`${temporaryId}-rangepicker`}
          onChange={onDateChange}
        />
      </div>
      {editing && (
        <div className="disclaimer-right">
          <DeleteOutlined onClick={onRemove} />
          <SortableHandle key="handle" />
        </div>
      )}
    </div>
  );
};

interface ISortableProps extends IProps {
  index: number;
}

const SortableItem = SortableElement(DisclaimerBloc);

const SortableDisclaimerBloc = (props: ISortableProps) => (
  <SortableItem {...props} />
);

export default SortableDisclaimerBloc;
