import moment from 'moment';

import { OpeningHours } from 'app/components/commons/Hotel/Types';
import { Picture } from 'app/components/commons/Uploader/Types';
import {
  ExperienceCategory,
  ExperienceFieldType,
} from 'app/redux/models/ExperienceCategory/ExperienceCategory';

export enum DurationDescription {
  MINUTE = 'minutes',
  HOUR = 'hours',
  HALF_DAY = 'half-days',
  DAY = 'days',
}

export interface IExperience {
  hotelId: number;
  categoryId: number;
  spaceIds: number[];
  name: string | null;
  description: string | null;
  coverPicture: Picture;
  emoji: Picture | null;
  pictures: Picture[];
  included?: boolean;
  price: number | null;
  priceDescription: number | null;
  openingHours: OpeningHours | null;
  published: boolean;
  id: number;
  brand: string | null;
  quantity: number | null;
  quantityDescription: string | null;
  duration: number | null;
  durationDescription: DurationDescription | null;
  time: moment.Moment | string | null;
  roomServiceAvailable: boolean;
  roomServiceIncluded: boolean;
  roomServicePrice: number | null;
  accessMode: string | null;
  locations: string | null;
}

export interface IExperienceListElement {
  hotelId: number;
  hotelName: string;
  coverPicture: Picture;
  price: number | null;
  published: boolean;
  id: number;
  brand: string | null;
  quantity: number | null;
  duration: number | null;
  durationDescription: string | null;
  time: moment.Moment | string | null;
  locations: string | null;
  updatedAt: string;
  countryId: number;
  category: IExperienceCategory;
}

export interface IExperienceCategory {
  id: number;
  parentId?: number;
  pictureId: number;
  name: string;
  experienceType: string;
  accessMode: boolean;
  brand: boolean;
  duration: boolean;
  time: boolean;
  locations: boolean;
  openingHours: ExperienceFieldType;
  price: boolean;
  priceDescription: boolean;
  quantity: boolean;
  quantityDescription: boolean;
  quantityTitle: boolean;
  roomService: boolean;
  spaces: boolean;
}

export type ExperienceNameArg = {
  category: IExperienceCategory | ExperienceCategory | null | undefined;
  quantity: number | null;
  brand: string | null;
  duration: number | null;
  durationDescription: string | null;
  time: moment.Moment | string | null;
  locations: string | null;
};
