import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { useContext, useState } from 'react';

import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { useRooms } from 'app/hooks/data/useRooms';
import { useAppSelector } from 'app/redux/hooks';
import { selectHotelAdminInventory } from 'app/redux/selectors/hotelAdmin';
import { IHotel } from 'app/typings';
import { assertNever } from 'app/utils/typing';

import { PricingRule } from '../../ExtranetInventory/RoomRow/AutomatedDiscount/PricingRule';
import { setBusinessWeek } from '../../ExtranetInventory/Utils';
import { ExtraAddOn } from '../ExtraAddOn/ExtraAddOn';
import { ExtraBedModal } from '../ExtraBed/Modal/ExtraBedModal';
import { ExtraGuest } from '../ExtraGuest/ExtraGuest';

import './TaskCenter.scss';
import { Tasks } from './Tasks/Tasks';
import { Task } from './Types';

type TaskCenterProps = {
  hotel: IHotel;
};

const TASK_SOURCE = 'drawer';

export const TaskCenter = ({ hotel }: TaskCenterProps) => {
  const {
    isTaskCenterOpen,
    closeTaskCenter,
    missingTask,
    doneTask,
    openTask,
    clickOpenTask,
    clearOpenTask,
  } = useContext(TaskCenterContext);

  const inventory = useAppSelector(selectHotelAdminInventory);

  const { data: rooms } = useRooms(hotel.id);

  const [currentRange] = useState<Interval>(setBusinessWeek(new Date()));

  const renderModal = (task: Task, onCompleteTask: () => void) => {
    switch (task.taskName) {
      case 'Last minute pricing rule':
      case 'Pricing rule':
        return (
          inventory && (
            <PricingRule
              onClose={() => clearOpenTask()}
              hotelId={hotel.id}
              autoDiscountRate={hotel.autoDiscountRate}
              lastMinuteAutoDiscountRate={hotel.lastMinuteAutoDiscountRate}
              lastMinuteAutoDiscountDays={hotel.lastMinuteAutoDiscountDays}
              inventory={inventory}
              startCurrentRange={currentRange.start}
              isOpen={task.taskName === openTask}
              onCompleteTask={onCompleteTask}
            />
          )
        );
      case 'Baby cot':
      case 'Guest bed':
        return (
          rooms && (
            <ExtraBedModal
              hotel={hotel}
              bedTypeId={task.categoryId}
              rooms={rooms}
              isOpen={task.taskName === openTask}
              onCompleteTask={onCompleteTask}
            />
          )
        );
      case 'Attract extra guests':
      case 'Attract single guests':
        return (
          rooms && (
            <CurrencyWrapper hotelId={hotel.id} hotel={hotel}>
              <ExtraGuest
                hotel={hotel}
                taskName={task.taskName}
                isOpen={task.taskName === openTask}
                onCompleteTask={onCompleteTask}
                rooms={rooms}
              />
            </CurrencyWrapper>
          )
        );

      case 'Early Check-in':
      case 'Late Check-out':
        return (
          <CurrencyWrapper hotelId={hotel.id} hotel={hotel}>
            <ExtraAddOn
              hotel={hotel}
              isOpen={task.taskName === openTask}
              task={task}
              onCompleteTask={onCompleteTask}
            />
          </CurrencyWrapper>
        );
      default:
        assertNever(task);
    }
  };

  const renderTask = (task: Task, index: number) => {
    return (
      <Tasks
        key={index}
        taskInfo={task}
        clickButton={() => clickOpenTask(task, TASK_SOURCE)}
        renderModal={(onCompleteTask) => renderModal(task, onCompleteTask)}
        source={TASK_SOURCE}
      />
    );
  };

  return (
    <Drawer
      title={
        <div className="taskCenter__header">
          <h1>Task center</h1>
        </div>
      }
      className="taskCenter"
      width={'510px'}
      open={isTaskCenterOpen}
      bodyStyle={{ padding: 0 }}
      onClose={closeTaskCenter}
    >
      <div className="container">
        <div className="taskCenter__body">
          <div className="taskCenter__body_explanation">
            <h1>Welcome to your Task Center</h1>
            <p>
              In this space, you can quickly update missing information in order
              to increase your hotel's visibility and attractiveness.
            </p>
          </div>
          <div className="taskCenter__body_task">
            {missingTask && missingTask.length > 0 && (
              <div className="taskCenter__body_todo">
                <div className="section_title">
                  <p>To do</p>
                </div>
                {missingTask.map((task, index) => renderTask(task, index))}
              </div>
            )}
            {doneTask && doneTask.length > 0 && (
              <div className="taskCenter__body_done">
                <div className="section_title">
                  <CheckCircleOutlined className="doneIcon" />
                  <p>Done</p>
                </div>
                {doneTask.map((task, index) => renderTask(task, index))}
              </div>
            )}
          </div>
        </div>
        <div className="taskCenter__footer">
          <Button size="large" type="ghost" onClick={closeTaskCenter}>
            Close
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
