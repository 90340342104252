import { Button, Form, Modal, message } from 'antd';
import React, { useContext } from 'react';

import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';
import {
  BABY_COT_BED_CATEGORY_ID,
  DAY_PACKAGE_ROOM_CATEGORY_ID,
} from 'app/components/commons/Room/Types';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { useRoomSave } from 'app/hooks/data/useRooms';
import { IHotel } from 'app/typings';

import { Bed, Room } from '../../../HotelBuilder/Types';
import { ExtraBed } from '../ExtraBed';

import './ExtraBedModal.scss';

type Props = {
  hotel: IHotel;
  bedTypeId: number;
  rooms: Room[];
  isOpen?: boolean;
  onCompleteTask?: () => void;
};

export const ExtraBedModal = ({
  hotel,
  bedTypeId,
  rooms,
  isOpen,
  onCompleteTask,
}: Props) => {
  const [form] = Form.useForm();
  const { mutateAsync } = useRoomSave(hotel.id);
  const { clearOpenTask } = useContext(TaskCenterContext);

  const onFinish = async (values: any) => {
    try {
      await mutateAsync(values.rooms);
      message.success('Rooms saved');
    } catch (error) {
      console.log(error);
      message.error('Error saving rooms');
    }

    onCompleteTask?.();

    clearOpenTask();
  };

  const getPrefix = (rooms: Room[], index: number) => {
    const listBeds = rooms[index].beds;
    const bed = listBeds.find((bed: Bed) => bed.categoryId === bedTypeId);

    const bedIndex = bed
      ? listBeds.findIndex((b: Bed) => b.id === bed?.id)
      : listBeds.length - 1;

    return [bedIndex];
  };

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      initialValues={{ rooms: rooms }}
      preserve={false}
    >
      {(values) => (
        <Modal
          width={558}
          className="extraBedModal"
          bodyStyle={{ maxHeight: 'calc(100vh - 400px)', overflowY: 'auto' }}
          open={isOpen}
          title={
            <div className="extraBedModal__header">
              <h1>
                Can this room accommodate a{' '}
                {bedTypeId === BABY_COT_BED_CATEGORY_ID
                  ? 'baby cot'
                  : 'guest bed'}
                ?
              </h1>
            </div>
          }
          onCancel={clearOpenTask}
          wrapClassName="extraBedModalContainer"
          centered
          footer={[
            <Button size="large" type="ghost" onClick={clearOpenTask}>
              Close
            </Button>,
            <Button
              key="save"
              size="large"
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
            >
              Update
            </Button>,
          ]}
          destroyOnClose
        >
          <div className="extraBedModal__body">
            <p className="subtitle">Your room categories</p>
            <div className="extraBedModal__body_rooms">
              <CurrencyWrapper hotel={hotel}>
                <Form.List name="rooms">
                  {(fields) =>
                    fields.map(
                      ({ key, name: roomName, ...restField }, index) =>
                        values.rooms[index].categoryId !==
                          DAY_PACKAGE_ROOM_CATEGORY_ID && (
                          <div
                            className="extraBedModal__body_rooms_room"
                            key={index}
                          >
                            <div className="extraBedModal__body_rooms_room_info">
                              <div className="extraBedModal__body_rooms_room_info_image">
                                <PictureComponent
                                  pictureId={
                                    values.rooms[index].pictures[0].pictureId
                                  }
                                />
                              </div>
                              <div className="extraBedModal__body_rooms_room_info_text">
                                <p className="title">
                                  {values.rooms[index].name ||
                                    values.rooms[index].categoryName}
                                </p>
                                <p className="description">
                                  {values.rooms[index].categoryName}
                                </p>
                              </div>
                            </div>

                            <Form.List name={[key, 'beds']}>
                              {(
                                bedsFields,
                                { add: addBed, remove: removeBed }
                              ) => (
                                <ExtraBed
                                  categoryName={
                                    bedTypeId === BABY_COT_BED_CATEGORY_ID
                                      ? 'Baby cot'
                                      : 'Guest bed'
                                  }
                                  categoryId={bedTypeId}
                                  bed={values.rooms[index].beds.find(
                                    (bed: Bed) => bed.categoryId === bedTypeId
                                  )}
                                  listBed={values.rooms[index].beds}
                                  add={addBed}
                                  remove={removeBed}
                                  previousPrefixes={['rooms', roomName, 'beds']}
                                  prefix={getPrefix(values.rooms, index)}
                                />
                              )}
                            </Form.List>
                          </div>
                        )
                    )
                  }
                </Form.List>
              </CurrencyWrapper>
            </div>
          </div>
        </Modal>
      )}
    </FormLegacyRenderProp>
  );
};
