import { format, isAfter, isBefore, isSameDay, isSunday } from 'date-fns';
import type { RangeValue } from 'rc-picker/lib/interface';

import { rangeNumbers } from './array';

export const isInDateArray = (date: Date, days: string[]) =>
  !!days.find((d) => d === format(date, 'yyyy-MM-dd'));

export const isPassed = (date: Date) =>
  isAfter(new Date(), date) && !isSameDay(new Date(), date);

export const isBetween = (startDate: Date, endDate: Date) => {
  const today = new Date();

  return isAfter(today, startDate) && isBefore(today, endDate);
};

export const isEndOfWeek = (date: Date) => isSunday(date);

export const rangeValueIsNotNull = (
  rangeValue: RangeValue<Date>
): rangeValue is [Date, Date] => {
  if (rangeValue === null || rangeValue[0] === null || rangeValue[1] === null) {
    return false;
  }

  return true;
};

export const disabledStartTimesBeforeEndDate = (
  startValue: Date,
  endDate: Date
) => {
  if (isSameDay(startValue, endDate)) {
    const endHours = endDate.getHours();
    const endMinutes = endDate.getMinutes();
    const endSeconds = endDate.getSeconds();

    return {
      disabledHours: () => rangeNumbers(endHours + 1, 24),
      disabledMinutes: (hour: number) =>
        hour === endHours ? rangeNumbers(endMinutes + 1, 60) : [],
      disabledSeconds: (hour: number, minute: number) =>
        hour === endHours && minute === endMinutes
          ? rangeNumbers(endSeconds + 1, 60)
          : [],
    };
  }

  return {};
};

export const disabledEndTimesAfterStartDate = (
  endValue: Date,
  startDate: Date
) => {
  if (isSameDay(endValue, startDate)) {
    const startHours = startDate.getHours();
    const startMinutes = startDate.getMinutes();
    const startSeconds = startDate.getSeconds();

    return {
      disabledHours: () => rangeNumbers(0, startHours),
      disabledMinutes: (hour: number) =>
        hour === startHours ? rangeNumbers(0, startMinutes) : [],
      disabledSeconds: (hour: number, minute: number) =>
        hour === startHours && minute === startMinutes
          ? rangeNumbers(0, startSeconds + 1)
          : [],
    };
  }

  return {};
};

export const formatToLocalDateTime = (date: Date) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss");
};

export const dateAndHour = (date: string) =>
  format(new Date(date), "dd/MM/yyy 'at' HH:mm");

export const formatDateWithoutHour = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};
