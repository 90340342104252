import { FilterFilled } from '@ant-design/icons';
import { Checkbox, Col, Popover, Row } from 'antd';
import React, { FC, useState } from 'react';

const PopoverCheckbox: FC<Props> = ({ value, onChange, title, items }) => {
  const [isVisible, setIsVisible] = useState(false);

  const checkboxItem = (cbi: any) => (
    <Row key={cbi.label}>
      <Col>
        <Checkbox value={cbi.value}>{cbi.label}</Checkbox>
      </Col>
    </Row>
  );

  const checkbox = (
    <Checkbox.Group onChange={onChange} value={value}>
      {items.map(checkboxItem)}
    </Checkbox.Group>
  );

  return (
    <Popover
      content={checkbox}
      trigger="click"
      visible={isVisible}
      onVisibleChange={setIsVisible}
    >
      <div className="sort-button min-width-90">
        {title}
        <FilterFilled style={{ color: '#BFBFBF', marginLeft: '10px' }} />
      </div>
    </Popover>
  );
};

type ValueType = string | number | boolean;

type Props = {
  value: Array<ValueType>;
  onChange: (newValues: Array<ValueType>) => void;
  title: string;
  items: Array<{
    value: ValueType;
    label: string;
  }>;
};

export default PopoverCheckbox;
