export const actions = {
  PICTURES_START_LOADING: 'PICTURES_START_LOADING',
  PICTURES_STOP_LOADING: 'PICTURES_STOP_LOADING',
  PICTURES_TRY_FETCH_HOTEL_PICTURES: 'PICTURES_TRY_FETCH_HOTEL_PICTURES',
  PICTURES_FETCH_HOTEL_PICTURES_SUCCESS:
    'PICTURES_FETCH_HOTEL_PICTURES_SUCCESS',
  PICTURES_TRY_SAVE_GALLERY: 'PICTURES_TRY_SAVE_GALLERY',
  PICTURES_TRY_FETCH_HOTEL_GALLERY: 'PICTURES_TRY_FETCH_HOTEL_GALLERY',
  PICTURES_FETCH_HOTEL_GALLERY_SUCCESS: 'PICTURES_FETCH_HOTEL_GALLERY_SUCCESS',
  PICTURES_TRY_ADD_HOTEL_PICTURES: 'PICTURES_TRY_ADD_HOTEL_PICTURES',
  PICTURES_ADD_HOTEL_PICTURES_SUCCESS: 'PICTURES_ADD_HOTEL_PICTURES_SUCCESS',
  PICTURES_TRY_EDIT_PICTURE: 'PICTURES_TRY_EDIT_PICTURE',
  PICTURES_EDIT_PICTURE_SUCCESS: 'PICTURES_EDIT_PICTURE_SUCCESS',
  PICTURES_TRY_REMOVE_HOTEL_PICTURE: 'PICTURES_TRY_REMOVE_HOTEL_PICTURE',
  PICTURES_REMOVE_HOTEL_PICTURE_SUCCESS:
    'PICTURES_REMOVE_HOTEL_PICTURE_SUCCESS',
  PICTURES_TRY_FETCH_HOTELS_GALLERIES: 'PICTURES_TRY_FETCH_HOTELS_GALLERIES',
  PICTURES_FETCH_HOTELS_GALLERIES_SUCCESS:
    'PICTURES_FETCH_HOTELS_GALLERIES_SUCCESS',
  PICTURES_TRY_FETCH_HOTEL_GALLERY_ROOMS:
    'PICTURES_TRY_FETCH_HOTEL_GALLERY_ROOMS',
  PICTURES_FETCH_HOTEL_GALLERY_ROOMS_SUCCESS:
    'PICTURES_FETCH_HOTEL_GALLERY_ROOMS_SUCCESS',
  PICTURES_TRY_FETCH_EMOJIS: 'PICTURES_TRY_FETCH_EMOJIS',
  PICTURES_FETCH_EMOJIS_SUCCESS: 'PICTURES_FETCH_EMOJIS_SUCCESS',
};

export function startLoading() {
  return { type: actions.PICTURES_START_LOADING };
}

export function stopLoading() {
  return { type: actions.PICTURES_STOP_LOADING };
}

export function tryFetchHotelPictures(hotelId) {
  return { type: actions.PICTURES_TRY_FETCH_HOTEL_PICTURES, hotelId };
}

export function fetchHotelPicturesSuccess(result) {
  return { type: actions.PICTURES_FETCH_HOTEL_PICTURES_SUCCESS, result };
}

export function trySaveGallery(gallery) {
  return { type: actions.PICTURES_TRY_SAVE_GALLERY, gallery };
}

export function tryFetchHotelGallery(hotelId) {
  return { type: actions.PICTURES_TRY_FETCH_HOTEL_GALLERY, hotelId };
}

export function fetchHotelGallerySuccess(result) {
  return { type: actions.PICTURES_FETCH_HOTEL_GALLERY_SUCCESS, result };
}

export function tryAddHotelPictures(hotelId, kind, pictures) {
  return {
    type: actions.PICTURES_TRY_ADD_HOTEL_PICTURES,
    hotelId,
    kind,
    pictures,
  };
}

export function addHotelPicturesSuccess(pictures) {
  return { type: actions.PICTURES_ADD_HOTEL_PICTURES_SUCCESS, pictures };
}

export function tryEditPicture(picture) {
  return { type: actions.PICTURES_TRY_EDIT_PICTURE, picture };
}

export function editPictureSuccess(picture) {
  return { type: actions.PICTURES_EDIT_PICTURE_SUCCESS, picture };
}

export function tryRemoveHotelPicture(hotelId, id) {
  return { type: actions.PICTURES_TRY_REMOVE_HOTEL_PICTURE, hotelId, id };
}

export function removeHotelPictureSuccess(id) {
  return { type: actions.PICTURES_REMOVE_HOTEL_PICTURE_SUCCESS, id };
}

export function tryFetchHotelsGalleries({ search, limit, sorting, offset }) {
  return {
    type: actions.PICTURES_TRY_FETCH_HOTELS_GALLERIES,
    search,
    limit,
    sorting,
    offset,
  };
}

export function fetchHotelsGalleriesSuccess(result) {
  return { type: actions.PICTURES_FETCH_HOTELS_GALLERIES_SUCCESS, result };
}

export function tryFetchHotelGalleryRooms(hotelId) {
  return { hotelId, type: actions.PICTURES_TRY_FETCH_HOTEL_GALLERY_ROOMS };
}

export function fetchHotelGalleryRoomsSuccess(result) {
  return { type: actions.PICTURES_FETCH_HOTEL_GALLERY_ROOMS_SUCCESS, result };
}

export function tryFetchEmojis() {
  return { type: actions.PICTURES_TRY_FETCH_EMOJIS };
}

export function fetchEmojisSuccess(result) {
  return { type: actions.PICTURES_FETCH_EMOJIS_SUCCESS, result };
}

export default actions;
