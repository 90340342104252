import { UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import React from 'react';

import { Experience } from 'app/redux/models/Package/Package';
import { assertNever } from 'app/utils/typing';

export const getSumOfPerPersonExperience = (
  description: string,
  experiences?: Experience[]
) => {
  if (experiences) {
    return experiences.reduce((acc, exp) => {
      if (
        exp.price &&
        exp.priceDescription &&
        exp.priceDescription.endsWith(description)
      ) {
        return acc + exp.price;
      }

      return acc;
    }, 0);
  }

  return 0;
};

export type ExtraGuestDetail = {
  priceConfiguration: {
    title: string;
    section: {
      title: string;
      icon: React.ReactElement;
      price: {
        firstPart: string;
        secondPart: string;
      };
      information: string;
    };
  };
  title: string;
  description: string;
  hasRoom: boolean;
  valueName: 'singleCustomerDiscount' | 'additionalAdultPrice';
};

export const getExtraGuestDetail = (
  taskName: 'Attract extra guests' | 'Attract single guests'
): ExtraGuestDetail => {
  switch (taskName) {
    case 'Attract extra guests':
      return {
        priceConfiguration: {
          title: 'Fee configuration',
          section: {
            title: 'Allow extra guest fee',
            icon: <UsergroupAddOutlined />,
            price: {
              firstPart: 'Apply an additional fee of ',
              secondPart: 'per extra adult guests',
            },
            information:
              'This fee will be applied to the default total price (package price + room price)',
          },
        },
        title: 'Define extra guest fee',
        description:
          'Attract more than 2 adult guests by setting up your extra guest fee',
        hasRoom: true,
        valueName: 'additionalAdultPrice',
      };

    case 'Attract single guests':
      return {
        priceConfiguration: {
          title: 'Discount configuration',
          section: {
            title: 'Allow single guest discount',
            icon: <UserOutlined />,
            price: {
              firstPart: 'Apply a discount of ',
              secondPart: 'for single guests',
            },
            information:
              'This discount will be added to the total price (package price + room price)',
          },
        },
        title: 'Define single guest discount',
        description:
          'Attract single adult guests by setting up your single guest discount',
        hasRoom: false,
        valueName: 'singleCustomerDiscount',
      };

    default:
      assertNever(taskName);
  }
};
