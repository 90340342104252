import { Empty, Modal } from 'antd';
import arrayMove from 'array-move';
import React, { FC, useState } from 'react';
import { SortableContainer, SortableContainerProps } from 'react-sortable-hoc';

import Pic from 'app/components/commons/Pic';

import { Picture } from '../Types';

import UploadItem from './UploadItem/UploadItem';
import './UploadList.scss';

const UploadList: FC<IProps> = ({
  pictures,
  uploadingItems,
  remove,
  edit,
  customButton = null,
  previewable = false,
  draggable = false,
  fromUploader = false,
  disabled = false,
}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const showPreview = (pic: any) => {
    setPreviewTitle(pic.name);
    setPreviewImage(pic.pictureId);
    setPreviewVisible(true);
  };

  const closePreview = () => {
    setPreviewVisible(false);
    setPreviewTitle('');
    setPreviewImage('');
  };

  return (
    <div className="upload-list">
      {pictures &&
        pictures.map((pic, idx) => (
          <UploadItem
            index={idx}
            key={idx.toString()}
            item={pic}
            disabled={!draggable || disabled}
            onPreview={previewable ? () => showPreview(pic) : undefined}
            onRemove={
              remove ? () => remove(`${pic.pictureId}-${pic.id}`) : undefined
            }
            onEdit={edit && !disabled ? () => edit(pic) : undefined}
          />
        ))}
      {uploadingItems &&
        Object.keys(uploadingItems).map((key) => (
          <div className="upload-list__uploading-item" key={key}>
            <span>Uploading ...</span>
          </div>
        ))}
      {!disabled && <div className="upload-list__new-item">{customButton}</div>}
      {!pictures?.length && !customButton && !fromUploader && !disabled && (
        <div className="upload-list__empty-list">
          <Empty />
        </div>
      )}
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={closePreview}
        wrapClassName="upload-list-modal"
      >
        <div className="thumbnail">
          <Pic pictureId={previewImage} alt="alt" width={472} />
        </div>
      </Modal>
    </div>
  );
};

// TODO replace react-sortable-hoc with https://github.com/clauderic/dnd-kit (recommended by react-sortable-hoc documentation)
const SortableList = SortableContainer(UploadList) as unknown as FC<
  IProps & SortableContainerProps
>;

const SortableUploadList: FC<ISortableProps> = ({
  pictures,
  onChange,
  ...props
}) => (
  <SortableList
    pictures={pictures}
    axis="xy"
    pressDelay={200}
    onSortEnd={({ oldIndex, newIndex }) =>
      onChange && onChange(arrayMove(pictures, oldIndex, newIndex))
    }
    {...props}
  />
);

interface IProps {
  pictures: Array<Picture>;
  uploadingItems: {
    [fileName: string]: number;
  };
  remove?: (uid: string) => void;
  edit?: (picture: Picture) => void;
  customButton?: any;
  previewable?: boolean;
  draggable?: boolean;
  fromUploader?: boolean;
  disabled?: boolean;
}

interface ISortableProps extends IProps {
  onChange?: (pictures: Array<Picture>) => void;
}

export default SortableUploadList;
