import { message } from 'antd';
import queryString from 'query-string';
import { all, put, takeEvery } from 'redux-saga/effects';

import * as picturesActions from 'app/redux/actions/pictures';
import { del, get, patch, post, requestGenerator } from 'app/redux/requests';

export function* tryFetchHotelPictures({ hotelId }) {
  yield requestGenerator(
    () => Promise.resolve,
    () => get(`hotels/${hotelId}/pictures`),
    (result) => put(picturesActions.fetchHotelPicturesSuccess(result)),
    () => message.error('Error fetching Hotel Pictures')
  );
}

export function* trySaveGallery({ gallery }) {
  yield requestGenerator(
    () => Promise.resolve,
    () => post('hotels/gallery', gallery),
    (hotelId) => put(picturesActions.tryFetchHotelPictures(hotelId)),
    () => message.error('Error saving Hotel Gallery'),
    { expectJson: false }
  );
}

export function* tryFetchHotelGallery({ hotelId }) {
  yield requestGenerator(
    () => Promise.resolve,
    () => get(`hotels/${hotelId}/gallery`),
    (result) => put(picturesActions.fetchHotelGallerySuccess(result)),
    (error) => message.error('Error fetching Hotel gallery', error)
  );
}

export function* tryAddHotelPictures({ hotelId, kind, pictures }) {
  yield requestGenerator(
    () => Promise.resolve,
    () =>
      post(
        `hotels/${hotelId}/pictures/${kind}`,
        pictures.map((pic) => pic.id)
      ),
    () => put(picturesActions.addHotelPicturesSuccess(pictures)),
    (error) => message.error('Error adding picture to hotel gallery', error)
  );
}

export function* tryEditPicture({ picture }) {
  yield requestGenerator(
    () => Promise.resolve,
    () => patch(`pictures/${picture.id}`, picture),
    () => put(picturesActions.editPictureSuccess(picture)),
    (error) => message.error('Error editing picture from hotel gallery', error)
  );
}

export function* tryRemoveHotelPicture({ hotelId, id }) {
  yield requestGenerator(
    () => Promise.resolve,
    () => del(`hotels/${hotelId}/pictures/${id}`),
    () => put(picturesActions.removeHotelPictureSuccess(id)),
    (error) => message.error('Error removing picture from hotel gallery', error)
  );
}

export function* tryFetchHotelsGalleries({ search, limit, sorting, offset }) {
  yield requestGenerator(
    () => put(picturesActions.startLoading()),
    () => {
      const stringifiedParams = queryString.stringify({
        search,
        limit,
        sorting,
        offset,
      });

      return get(`hotels/galleriesOpti?${stringifiedParams}`);
    },
    (result) => put(picturesActions.fetchHotelsGalleriesSuccess(result)),
    (error) => {
      message.error('Error fetching Hotels Galleries', error);

      return put(picturesActions.stopLoading());
    }
  );
}

export function* tryFetchHotelGalleryRooms({ hotelId }) {
  yield requestGenerator(
    () => Promise.resolve,
    () => get(`hotels/${hotelId}/gallery/rooms`),
    (result) => put(picturesActions.fetchHotelGalleryRoomsSuccess(result)),
    (error) => message.error('Error fetching Hotel gallery rooms', error)
  );
}

export function* tryFetchEmojis() {
  yield requestGenerator(
    () => Promise.resolve,
    () => get('pictures/emojis'),
    (result) => put(picturesActions.fetchEmojisSuccess(result)),
    () => message.error('Error fetching Emojis')
  );
}

export default function* picturesSaga() {
  yield all([
    takeEvery(
      picturesActions.actions.PICTURES_TRY_FETCH_HOTEL_PICTURES,
      tryFetchHotelPictures
    ),
    takeEvery(
      picturesActions.actions.PICTURES_TRY_SAVE_GALLERY,
      trySaveGallery
    ),
    takeEvery(
      picturesActions.actions.PICTURES_TRY_FETCH_HOTEL_GALLERY,
      tryFetchHotelGallery
    ),
    takeEvery(
      picturesActions.actions.PICTURES_TRY_ADD_HOTEL_PICTURES,
      tryAddHotelPictures
    ),
    takeEvery(
      picturesActions.actions.PICTURES_TRY_EDIT_PICTURE,
      tryEditPicture
    ),
    takeEvery(
      picturesActions.actions.PICTURES_TRY_REMOVE_HOTEL_PICTURE,
      tryRemoveHotelPicture
    ),
    takeEvery(
      picturesActions.actions.PICTURES_TRY_FETCH_HOTELS_GALLERIES,
      tryFetchHotelsGalleries
    ),
    takeEvery(
      picturesActions.actions.PICTURES_TRY_FETCH_HOTEL_GALLERY_ROOMS,
      tryFetchHotelGalleryRooms
    ),
    takeEvery(
      picturesActions.actions.PICTURES_TRY_FETCH_EMOJIS,
      tryFetchEmojis
    ),
  ]);
}
