import { Menu, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import Uploader from 'app/components/commons/Uploader/Uploader';
import PictureGallery, {
  ISelectablePicture,
} from 'app/components/pages/Galleries/PictureGallery/PictureGallery';

import { Picture } from '../../Uploader/Types';

import './CoverPictureModal.scss';

export type { ISelectablePicture };

const ITEMS = [
  { key: 'gallery' as const, label: 'Gallery' },
  { key: 'upload' as const, label: 'Upload' },
];

type MenuKey = (typeof ITEMS)[number]['key'];

type Props = {
  open: boolean;
  title: string;
  onOk: (picture: Picture | undefined) => void;
  onCancel: () => void;
  /**
   * If prop pictures is given as prop, a tab containing a PictureGallery will be added to the modal.
   */
  pictures?: ISelectablePicture[];
};

const CoverPictureModal = ({
  open,
  title,
  onOk,
  onCancel,
  pictures = [],
}: Props) => {
  const [uploadedPicture, setUploadedPicture] = useState<Picture | undefined>();

  useEffect(() => {
    if (!open) {
      setUploadedPicture(undefined);
    }
  }, [open]);

  // If pictures is undefined, we hide the first tab.
  const menuItems = pictures.length > 0 ? ITEMS : ITEMS.slice(1);

  const [activeKey, setActiveKey] = useState<MenuKey>(menuItems[0].key);

  return (
    <Modal
      width={800}
      open={open}
      title={`Add photos ${title ? `(${title})` : ''}`}
      onOk={() => onOk(uploadedPicture)}
      onCancel={onCancel}
      // TODO check with product if we really need this Save button since we can only select/upload one picture at a time.
      okText="Save"
      cancelText="Cancel"
      className="upload-modal"
      centered
    >
      <Menu
        mode="horizontal"
        selectedKeys={[activeKey]}
        onClick={(e) => setActiveKey(e.key as MenuKey)}
        items={menuItems}
        className="upload-modal__menu"
      />
      <div className="upload-modal__body">
        {activeKey === 'gallery' && (
          <PictureGallery
            onSelect={(selectedPicture) => {
              onOk(selectedPicture);
            }}
            pictures={pictures}
          />
        )}

        {activeKey === 'upload' && (
          <Uploader
            pictures={uploadedPicture ? [uploadedPicture] : []}
            add={setUploadedPicture}
            remove={() => setUploadedPicture(undefined)}
            previewable
          />
        )}
      </div>
    </Modal>
  );
};

export default CoverPictureModal;
