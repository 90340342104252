import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import { startOfDay } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import './DateRangeSection.scss';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

const RULES = [{ required: true, message: 'Required' }];

export const DateRangeSection = ({
  mode = 'edit',
  field,
  title,
  buttonText,
}: Props) => {
  return (
    <Form.List name={field}>
      {(fields, { add, remove }) => (
        <>
          <div className="title_button">
            <div className="title">{title}</div>
            <Button
              className="add-range "
              onClick={() => add()}
              disabled={mode === 'view'}
            >
              {buttonText}
            </Button>
          </div>
          <div className="list_closing_periods">
            {fields.map((subField, idx) => (
              <div className="range-row" key={idx.toString()}>
                <Form.Item {...subField} label=" " rules={RULES} noStyle>
                  <DatePicker.RangePicker
                    disabled={mode === 'view'}
                    bordered={mode === 'edit'}
                    order={false}
                    format="yyyy-MM-DD"
                    disabledDate={(current) =>
                      current &&
                      current.valueOf() < startOfDay(Date.now()).valueOf()
                    }
                  />
                </Form.Item>
                {mode === 'edit' && (
                  <div className="delete-range" onClick={() => remove(idx)}>
                    <DeleteOutlined />
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </Form.List>
  );
};

type Props = {
  mode?: string;
  field: string;
  title: string;
  buttonText: string;
};

export default DateRangeSection;
