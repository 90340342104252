import { Button, Layout } from 'antd';
import React, { FC } from 'react';

import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';

import './ThankYou.scss';

const { Content } = Layout;

const ThankYou: FC<Props> = ({ handleNext, showButton }) => (
  <Layout className="thank-you">
    <Content className="container">
      <div className="block">
        <div className="first-section">
          <PictureComponent
            className="picture"
            pictureId="static/green-check.png"
            alt="green check"
          />
          <div className="title">Thank you</div>
        </div>
        <div className="second-section">
          <div className="title">
            Thank you for filling in all this information.
          </div>
          <div className="description">
            It has been sent to our team who will contact you soon. Once your
            hotel is online you can use this same link to access your extranet.
          </div>
        </div>
        {!!showButton && (
          <div className="button-section">
            <div className="button-container">
              <Button type="primary" onClick={handleNext}>
                CTA des biz dev
              </Button>
            </div>
          </div>
        )}
      </div>
    </Content>
  </Layout>
);

type Props = {
  handleNext: () => void;
  showButton: boolean;
};

export default ThankYou;
