import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { PAGE_LIMIT } from './constants';

type PaginationProps = {
  className: string;
  pageNumber: number;
  pageResultsLength: number;
  handleOffset: (offset: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({
  className,
  pageNumber,
  pageResultsLength,
  handleOffset,
}: PaginationProps) => (
  <div className={className}>
    <Button disabled={pageNumber === 1} onClick={() => handleOffset(-1)}>
      <LeftOutlined />
    </Button>
    <Button>{pageNumber}</Button>
    <Button
      disabled={pageResultsLength < PAGE_LIMIT}
      onClick={() => handleOffset(1)}
    >
      <RightOutlined />
    </Button>
  </div>
);

export default Pagination;
