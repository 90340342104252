import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/redux/store';

const selectRooms = (state: RootState) => state.rooms;

export const getRoomsRooms = createSelector(
  selectRooms,
  (rooms) => rooms.rooms
);

export const getRoomsRoom = createSelector(selectRooms, (rooms) => rooms.room);

export const getHotelRooms = createSelector(
  selectRooms,
  (rooms) => rooms.hotelRooms
);

export const getRoomsLoading = createSelector(
  selectRooms,
  (rooms) => rooms.loading
);
