import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { createEditoEventPath } from 'app/routes/edito';
import { IEditoEventRequest } from 'app/typings/edito';
import { apiMutation } from 'app/utils/request/api';

export const useCreateEditoEvent = () => {
  return useMutation<number, unknown, IEditoEventRequest, unknown>({
    mutationFn: async (payload) =>
      apiMutation(
        'POST',
        {
          path: createEditoEventPath,
        },
        payload
      ),
    onError: () => {
      message.error('Error while creating the event as draft');
    },
  });
};
