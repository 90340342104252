import type { AnyAction } from 'redux';

import * as actions from 'app/redux/actions/tags';
import { Tag } from 'app/redux/models/Tag/Tag';

export type Tags = {
  loading: boolean;
  tags: Tag[];
};

export const initialState: Tags = {
  loading: false,
  tags: [],
};

function tags(state = initialState, action: AnyAction): Tags {
  if (actions.startLoading.match(action)) {
    return {
      ...state,
      loading: true,
    };
  }

  if (actions.stopLoading.match(action)) {
    return {
      ...state,
      loading: false,
    };
  }

  if (actions.fetchTagsSuccess.match(action)) {
    return {
      ...state,
      loading: false,
      tags: action.payload.result,
    };
  }

  return state;
}

export default tags;
