export const actions = {
  TRY_GET_ENTITY: 'TRY_GET_ENTITY',
  START_GET_ENTITY: 'START_GET_ENTITY',
  GET_ENTITY_SUCCESS: 'GET_ENTITY_SUCCESS',
  GET_ENTITY_FAILURE: 'GET_ENTITY_FAILURE',

  TRY_FETCH_ENTITY: 'TRY_FETCH_ENTITY',
  TRY_FETCH_ENTITY_WITH_PARAMS: 'TRY_FETCH_ENTITY_WITH_PARAMS',
  START_FETCH_ENTITY: 'START_FETCH_ENTITY',
  FETCH_ENTITY_SUCCESS: 'FETCH_ENTITY_SUCCESS',
  FETCH_ENTITY_SUCCESS_WITH_PARAMS: 'FETCH_ENTITY_SUCCESS_WITH_PARAMS',
  FETCH_ENTITY_FAILURE: 'FETCH_ENTITY_FAILURE',

  TRY_SAVE_ENTITY: 'TRY_SAVE_ENTITY',
  START_SAVE_ENTITY: 'START_SAVE_ENTITY',
  SAVE_ENTITY_SUCCESS: 'SAVE_ENTITY_SUCCESS',
  SAVE_ENTITY_FAILURE: 'SAVE_ENTITY_FAILURE',

  START_PATCH_ENTITY: 'START_PATCH_ENTITY',
  PATCH_ENTITY_SUCCESS: 'PATCH_ENTITY_SUCCESS',
  PATCH_ENTITY_FAILURE: 'PATCH_ENTITY_FAILURE',

  TRY_PUBLISH_ENTITY: 'TRY_PUBLISH_ENTITY',

  TRY_HIDE_ENTITY: 'TRY_HIDE_ENTITY',

  OPEN_PREVIEW: 'OPEN_PREVIEW',

  USER_LOGGED_IN: 'USER_LOGGED_IN',
};

export function tryGetEntity(entity, entityId) {
  return { entity, entityId, type: actions.TRY_GET_ENTITY };
}

export function startGetEntity(entity, entityId) {
  return { entity, entityId, type: actions.START_GET_ENTITY };
}

export function getEntitySuccess(entity, entityId, result) {
  return { entity, entityId, result, type: actions.GET_ENTITY_SUCCESS };
}

export function getEntityFailure(entity, entityId, error) {
  return { entity, entityId, error, type: actions.GET_ENTITY_FAILURE };
}

export function tryFetchEntity(entity, params) {
  return { entity, params, type: actions.TRY_FETCH_ENTITY };
}

export function tryFetchEntityWithParams(entity, params) {
  return { entity, params, type: actions.TRY_FETCH_ENTITY_WITH_PARAMS };
}

export function startFetchEntity(entity) {
  return { entity, type: actions.START_FETCH_ENTITY };
}

export function fetchEntitySuccess(entity, result) {
  return { entity, result, type: actions.FETCH_ENTITY_SUCCESS };
}

export function fetchEntitySuccessWithParams(entity, result) {
  return { entity, result, type: actions.FETCH_ENTITY_SUCCESS_WITH_PARAMS };
}

export function fetchEntityFailure(entity, error) {
  return { entity, error, type: actions.FETCH_ENTITY_FAILURE };
}

export function trySaveEntity(entity, values, redirectToEdit = 'entity') {
  return { entity, values, redirectToEdit, type: actions.TRY_SAVE_ENTITY };
}

export function startSaveEntity(entity) {
  return { entity, type: actions.START_SAVE_ENTITY };
}

export function saveEntitySuccess(entity, entityId, redirectToEdit) {
  return {
    entity,
    entityId,
    redirectToEdit,
    type: actions.SAVE_ENTITY_SUCCESS,
  };
}

export function saveEntityFailure(entity, error) {
  return { entity, error, type: actions.SAVE_ENTITY_FAILURE };
}

export function startPatchEntity(entity) {
  return { entity, type: actions.START_PATCH_ENTITY };
}

export function patchEntitySuccess(entity) {
  return { entity, type: actions.PATCH_ENTITY_SUCCESS };
}

export function patchEntityFailure(entity, error) {
  return { entity, error, type: actions.PATCH_ENTITY_FAILURE };
}

export function tryPublishEntity(entity, entityId) {
  return { entity, entityId, type: actions.TRY_PUBLISH_ENTITY };
}

export function tryHideEntity(entity, entityId) {
  return { entity, entityId, type: actions.TRY_HIDE_ENTITY };
}

export function openPreview(entityPath) {
  return { entityPath, type: actions.OPEN_PREVIEW };
}

export default actions;
