export const actions = {
  INIT_APP: 'INIT_APP',

  APP_READY: 'APP_READY',

  USER_LOGGED_IN: 'USER_LOGGED_IN',

  SET_CURRENCY: 'SET_CURRENCY',
};

export function initApp(app) {
  return { app, type: actions.INIT_APP };
}

export function appReady() {
  return { type: actions.APP_READY };
}

export function userLoggedIn(user, token) {
  return { user, token, type: actions.USER_LOGGED_IN };
}

export function setCurrency(currency) {
  return { currency, type: actions.SET_CURRENCY };
}

export default actions;
