export type FormattedPlaceProperties = {
  streetNumber?: string;
  street?: string;
  city?: string;
  department?: string;
  region?: string;
  zipCode?: string;
};

export type GooglePlaceAddress = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type GooglePlacePayload = {
  address_components: GooglePlaceAddress[];
  geometry: {
    location: {
      lng: () => number;
      lat: () => number;
    };
  };
  formatted_phone_number: string;
  name: string;
};

export function formatGooglePlacePayload(
  place: GooglePlaceAddress[]
): FormattedPlaceProperties {
  return place.reduce((previousProperties, addressEntry) => {
    const [firstTypeEntry] = addressEntry.types;

    switch (firstTypeEntry) {
      case 'street_number':
        return { ...previousProperties, streetNumber: addressEntry.short_name };
      case 'route':
        return { ...previousProperties, street: addressEntry.short_name };
      case 'locality':
        return { ...previousProperties, city: addressEntry.short_name };
      case 'administrative_area_level_2':
        return { ...previousProperties, department: addressEntry.short_name };
      case 'administrative_area_level_1':
        return { ...previousProperties, region: addressEntry.short_name };
      case 'postal_code':
        return { ...previousProperties, zipCode: addressEntry.short_name };
      default:
        return previousProperties;
    }
  }, {});
}
