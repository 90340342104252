import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Spinner = ({ spinning, children }) => (
  <Spin spinning={spinning} size="large" indicator={<LoadingOutlined spin />}>
    {children}
  </Spin>
);

Spinner.propTypes = {
  spinning: PropTypes.bool,
  children: PropTypes.node,
};

export default Spinner;
