import React, { FC, ReactNode, ReactNodeArray } from 'react';

import 'styles/layout/card.scss';

const Card: FC<Props> = ({
  title,
  children,
  actions,
  formOnly,
  className = '',
}) => (
  <div className={`card ${className}`}>
    <div className="card__content">
      <h2>{title}</h2>
      <div className={`card__body ${formOnly ? 'card__body--form' : ''}`}>
        <div className="card__content">{children}</div>
      </div>
    </div>
    {actions && (
      <div className="card__actions">
        {actions.map((action, idx) => (
          <div key={idx} className="card__action">
            {action}
          </div>
        ))}
      </div>
    )}
  </div>
);

type Props = {
  title: string;
  children?: ReactNode;
  actions?: ReactNodeArray;
  formOnly?: boolean;
  className?: string;
};

export default Card;
