import { Divider, Form, Layout, Radio } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { Content } from 'antd/lib/layout/layout';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { SDetailLayout } from 'app/components/StaycationUI';
import { BannerInfo } from 'app/components/commons/BannerInfo/BannerInfo';
import { useGetQuickFilterDetails } from 'app/hooks/data/quickFilter/useGetQuickFilterDetails';
import { useSaveQuickFilter } from 'app/hooks/data/quickFilter/useSaveQuickFilter';
import { tryFetchTags } from 'app/redux/actions/tags';
import { useAppDispatch } from 'app/redux/hooks';
import { requiredRule } from 'app/utils/rules';
import { formatSlugTitle } from 'app/utils/string';
import { isDefined } from 'app/utils/typing';

import { QuickFilter, QuickFilterForm } from '../types';

import { FormSection } from './FormSection/FormSection';
import { EmojiSectionForm } from './FormSection/SubFormSection/EmojiSection/EmojiSection';
import { PreviewSection } from './FormSection/SubFormSection/PreviewSection/PreviewSection';
import { TagsSectionForm } from './FormSection/SubFormSection/TagsSection/TagsSection';
import { LabelTranslationSection } from './FormSection/SubFormSection/TranslationSection/LabelTranslationSection';
import { SliceTitleTranslationSection } from './FormSection/SubFormSection/TranslationSection/SliceTitleTranslationSection';
import './QuickFilterDetail.scss';

export const QuickFilterDetail = () => {
  const dispatch = useAppDispatch();
  const { id: paramId } = useParams<{ id: string }>();
  const parsedParamId = parseInt(paramId);
  const quickFilterId = isNaN(parsedParamId) ? undefined : parsedParamId;
  const [form] = Form.useForm<QuickFilterForm>();
  const { createQuickFilter, updateQuickFilter } = useSaveQuickFilter();
  const query = new URLSearchParams(useLocation().search);
  const initialType = query.get('type');

  const selectedTagIds = useWatch('tags', form);
  const selectedType = useWatch('type', form);
  const currentTranslationEn = useWatch('translationEn', form);

  const formattedQuickFilterForm: QuickFilterForm | undefined =
    useGetQuickFilterDetails(quickFilterId);

  const isQuickFilterExisting = isDefined(formattedQuickFilterForm);

  useEffect(() => {
    dispatch(tryFetchTags());
  }, [dispatch]);

  const onSave = (quickFilterForm: QuickFilterForm) => {
    const quickFilter: QuickFilter = {
      id: quickFilterForm.id,
      dayPackage: quickFilterForm.type === 'moment',
      label: {
        en: quickFilterForm.translationEn.trim(),
        es: quickFilterForm.translationEs.trim(),
        fr: quickFilterForm.translationFr.trim(),
        key: formattedQuickFilterForm?.translationKey,
      },
      sliceTitle: {
        en: quickFilterForm.sliceTitleEn.trim(),
        es: quickFilterForm.sliceTitleEs.trim(),
        fr: quickFilterForm.sliceTitleFr.trim(),
        key: formattedQuickFilterForm?.sliceTitleKey,
      },
      slug: '',
      pictureId: quickFilterForm.pictureId,
      tags: quickFilterForm.tags,
    };

    if (isQuickFilterExisting && quickFilterId) {
      updateQuickFilter.mutate({ ...quickFilter, id: quickFilterId });
    } else {
      createQuickFilter.mutate(quickFilter);
    }
  };

  useEffect(() => {
    if (formattedQuickFilterForm) {
      form.setFieldsValue(formattedQuickFilterForm);
    }
  }, [form, formattedQuickFilterForm]);

  const initialValues = isDefined(formattedQuickFilterForm)
    ? formattedQuickFilterForm
    : {
        type: initialType,
      };

  const backRoute = () => {
    if (!initialType) {
      return `/quick-filters`;
    }

    const queryParams = new URLSearchParams({
      type: initialType,
    });

    return `/quick-filters?${queryParams.toString()}`;
  };

  const id = `-${
    formattedQuickFilterForm?.id ? formattedQuickFilterForm.id : 'id'
  }`;
  const formatTitle = formatSlugTitle(
    formattedQuickFilterForm?.translationEn
      ? `${formattedQuickFilterForm.translationEn}`
      : `${currentTranslationEn ?? ''}`
  );

  const title = formatTitle ? `-${formatTitle}` : '';

  return (
    <Layout className="quickFilterLayout">
      <SDetailLayout
        mode={'edit'}
        buttonSaveText={isQuickFilterExisting ? 'Save' : 'Create quick filter'}
        isDirty
        title={isQuickFilterExisting ? 'Edit quick filter' : 'New quick filter'}
        backRoute={backRoute()}
        onSave={form.submit}
      >
        <Content className="content-container">
          <Form<QuickFilterForm>
            layout="vertical"
            initialValues={initialValues}
            form={form}
            onFinish={onSave}
            className="form-container"
          >
            <FormSection title="Type">
              <Form.Item name="type" rules={[requiredRule]}>
                <Radio.Group disabled={isQuickFilterExisting}>
                  <Radio value="staycation">Staycation</Radio>
                  <Radio value="moment">Moment</Radio>
                </Radio.Group>
              </Form.Item>
            </FormSection>

            <Divider />

            <FormSection title="Names">
              <LabelTranslationSection />
              <BannerInfo
                title="Slug name"
                subTitle={`quickFilter${id}${title}`}
              />
              <SliceTitleTranslationSection />
            </FormSection>

            <Divider />

            <FormSection title="Settings">
              <EmojiSectionForm />
              <TagsSectionForm />
            </FormSection>

            <Divider />

            <PreviewSection
              isDayPackage={
                selectedType ? selectedType === 'moment' : selectedType
              }
              selectedTagIds={selectedTagIds ?? []}
            />
          </Form>
        </Content>
      </SDetailLayout>
    </Layout>
  );
};
