import { createExperienceCategory } from '../ExperienceCategory/ExperienceCategory';
import type { ExperienceCategory } from '../ExperienceCategory/ExperienceCategory';
import type { Picture } from '../Picture/Picture';

export type ExperienceListElement = {
  readonly id: number;
  readonly name: string;
  readonly hotelId: number;
  readonly hotelName: string;
  readonly included: boolean;
  readonly coverPicture?: Picture;
  readonly price: number;
  readonly countryId: number;
  readonly published: boolean;
  readonly updatedAt: string;
  readonly category: ExperienceCategory;
  readonly quantity: number | null;
  readonly duration: number | null;
  readonly durationDescription: string | null;
  readonly time: string | null;
  readonly locations: string | null;
  readonly brand: string | null;
};

export function createExperienceListElement(
  values: any
): ExperienceListElement {
  const {
    id,
    hotelId,
    hotelName,
    included,
    name,
    price,
    published,
    countryId,
    updatedAt,
    coverPicture,
    category,
    quantity,
    duration,
    durationDescription,
    time,
    locations,
    brand,
  } = values;

  return {
    id,
    hotelId,
    hotelName,
    included,
    name,
    coverPicture,
    price,
    published,
    countryId,
    updatedAt,
    category: createExperienceCategory(category),
    quantity,
    duration,
    durationDescription,
    time,
    locations,
    brand,
  };
}
