export enum ChannelManagers {
  NONE = 'NONE',
  D_EDGE = 'DEDGE',
  SITEMINDER = 'SITEMINDER',
  RESERVIT = 'RESERVIT',
  CUBILIS = 'CUBILIS',
  TRAVELCLICK = 'TRAVELCLICK',
  ROOMCLOUD = 'ROOMCLOUD',
}

export const channelManagerLabels = [
  { manager: ChannelManagers.NONE, label: 'None' },
  { manager: ChannelManagers.D_EDGE, label: 'D-Edge' },
  { manager: ChannelManagers.SITEMINDER, label: 'Siteminder' },
  { manager: ChannelManagers.RESERVIT, label: 'Reservit' },
  { manager: ChannelManagers.CUBILIS, label: 'Cubilis' },
  { manager: ChannelManagers.TRAVELCLICK, label: 'Travelclick' },
  { manager: ChannelManagers.ROOMCLOUD, label: 'Roomcloud' },
] as const;

export const getChannelManagerLink = (
  channelManager: ChannelManagers | undefined
) => {
  if (channelManager === ChannelManagers.D_EDGE) {
    return 'https://extranet.availpro.com/Planning/Monthly';
  }

  if (channelManager === ChannelManagers.SITEMINDER) {
    return 'https://authx.siteminder.com/login';
  }

  if (channelManager === ChannelManagers.RESERVIT) {
    return 'https://securestatic.reservit.com/bo/login/2';
  }

  if (channelManager === ChannelManagers.CUBILIS) {
    return 'https://my.stardekk.com';
  }

  if (channelManager === ChannelManagers.TRAVELCLICK) {
    return 'https://otaconnect-cert.travelclick.com/';
  }

  if (channelManager === ChannelManagers.ROOMCLOUD) {
    return 'https://secure.roomcloud.net/be/owners_area/index.jsp';
  }

  return '';
};

export const getChannelManagerLabel = (channelManager: ChannelManagers) => {
  return (
    channelManagerLabels.find(({ manager }) => manager === channelManager)
      ?.label || 'None'
  );
};

export const ChannelManagerDataSource = (
  Object.keys(ChannelManagers) as Array<keyof typeof ChannelManagers>
).map((key) => ({
  id: ChannelManagers[key] as ChannelManagers,
  name:
    channelManagerLabels.find((cml) => cml.manager === ChannelManagers[key])
      ?.label || 'Unknown',
}));
