import { Button, DatePicker } from 'antd';
import { DatePickerProps } from 'antd/es/date-picker';
import moment, { Moment } from 'moment';
import React, { useCallback } from 'react';

const DatePickerWithHelper = ({
  value,
  onChange,
  ...props
}: DatePickerWithHelperProps) => {
  const handleChange = useCallback(
    (momentDate: Moment | null) => {
      if (onChange) {
        onChange(momentDate || moment());
      }
    },
    [onChange]
  );

  return (
    <>
      <DatePicker
        {...props}
        value={value}
        onChange={handleChange}
        picker="date"
      />
      <Button onClick={() => handleChange(moment(value).add(1, 'M'))}>
        +1 month
      </Button>
      <Button onClick={() => handleChange(moment(value).add(3, 'M'))}>
        +3 months
      </Button>
    </>
  );
};

type DatePickerWithHelperProps = Omit<DatePickerProps, 'value' | 'onChange'> & {
  value?: Moment;
  onChange?: (date: Moment) => void;
};

export default DatePickerWithHelper;
