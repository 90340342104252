import {
  LeftOutlined,
  RightOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import { Interval, format, isAfter } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import React, { useCallback, useMemo, useState } from 'react';

import {
  computeDiscount,
  computePackageValue,
  isValidDiscount,
} from 'app/components/pages/Extranet/ExtranetInventory/Utils';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import {
  resetDirtyOpenings,
  toggleIsEditing,
  tryValidateOpenings,
} from 'app/redux/actions/hotelAdmin';
import { useAppDispatch } from 'app/redux/hooks';
import { useAppSelector } from 'app/redux/hooks';
import { OpeningForm } from 'app/redux/models/RoomOpening/RoomOpening';
import {
  selectHotelAdminDirtOpenings,
  selectHotelAdminHotelId,
  selectHotelAdminInventory,
  selectHotelAdminIsEditing,
  selectHotelAdminIsLoading,
} from 'app/redux/selectors/hotelAdmin';
import { RateModes } from 'app/utils/channelManagers/rateModes';
import { isPassed } from 'app/utils/dates';

import 'styles/lists/left-right-header.scss';

import './InventoryHeader.scss';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

type Props = {
  currentRange: Interval;
  goToPreviousWeek: () => void;
  goToNextWeek: () => void;
  autoDiscountRate?: number;
  lastMinuteAutoDiscountRate?: number;
  lastMinuteAutoDiscountDays?: number;
  isAdmin?: boolean;
};

export const InventoryHeader = ({
  currentRange,
  goToPreviousWeek,
  goToNextWeek,
  autoDiscountRate,
  isAdmin,
}: Props) => {
  const dispatch = useAppDispatch();
  const inventory = useAppSelector(selectHotelAdminInventory);
  const hotelId = useAppSelector(selectHotelAdminHotelId);
  const dirtyOpenings = useAppSelector(selectHotelAdminDirtOpenings);
  const isLoading = useAppSelector(selectHotelAdminIsLoading);
  const isEditing = useAppSelector(selectHotelAdminIsEditing);
  const [isConfirmationPopinVisible, setIsConfirmationPopinVisible] =
    useState(false);
  const { track } = useAmplitude();

  const handleSaveClick = () => {
    if (!hotelId) {
      return;
    }

    setIsConfirmationPopinVisible(false);
    track('Click Save Extranet');
    dispatch(
      tryValidateOpenings({
        hotelId,
        openings: dirtyOpenings,
        start: format(currentRange.start, 'yyyy-MM-dd'),
        inventory,
        mode: 'edit',
      })
    );
  };

  const handleCancelClick = () => {
    track('Click Cancel Extranet');
    dispatch(toggleIsEditing({ value: false }));
    dispatch(resetDirtyOpenings());
  };

  const invalidDiscount = useCallback(
    (opening: OpeningForm) => {
      const packageValue = computePackageValue(
        opening,
        inventory,
        opening.date
      );
      const discount = computeDiscount(opening, packageValue);

      return !isValidDiscount(discount, inventory?.pkg.dayPackage || false);
    },
    [inventory]
  );

  const openingsDeclined = useMemo(() => {
    if (!inventory || isPassed(new Date(currentRange.end))) {
      return [];
    }

    return inventory.rooms
      .map((room) => room.openings)
      .flat()
      .filter(
        (op) =>
          !op.published &&
          !isPassed(new Date(op.date)) &&
          !isAfter(new Date(op.date), currentRange.end) &&
          (op.stock || op.bar || op.discountPrice) &&
          (!op.bar ||
            !op.discountPrice ||
            invalidDiscount(op) ||
            op.isForced ||
            op.closed)
      );
  }, [inventory, currentRange.end, invalidDiscount]);

  const renderConfirmationPopin = () => (
    <div className="extranet-confirmation-popin">
      <div className="popin-text">
        <p>Are you sure you want to confirm these rates ? </p>
        <p className="grey-text">
          Keep in mind rates cannot be changed once sales start.
        </p>
      </div>
      <div className="popin-cta">
        <Button
          className="popin-no"
          onClick={() => setIsConfirmationPopinVisible(false)}
        >
          No
        </Button>
        <Button
          className="popin-yes"
          type="primary"
          onClick={() => handleSaveClick()}
          loading={isLoading}
        >
          Yes
        </Button>
      </div>
    </div>
  );

  const isButtonDisabled =
    !isAdmin &&
    inventory?.rateMode === RateModes.MULTI_RATE &&
    !autoDiscountRate;

  const onEdit = () => {
    track('Click Edit Extranet');

    dispatch(toggleIsEditing({ value: true }));
  };

  const renderEditButton = () => {
    return (
      <Button
        key="edit-button"
        type="primary"
        onClick={onEdit}
        disabled={isButtonDisabled}
      >
        Edit
      </Button>
    );
  };

  return (
    <div className="left-right-header sticky-header">
      <div className="left-right-header__left">
        <Button
          className="extranet-inventory-header-previous"
          onClick={goToPreviousWeek}
        >
          <LeftOutlined />
        </Button>
        <DatePicker.RangePicker
          value={[new Date(currentRange.start), new Date(currentRange.end)]}
          disabled={true}
          format="EEE dd/MM"
        />
        <Button
          className="extranet-inventory-header-next"
          onClick={goToNextWeek}
        >
          <RightOutlined />
        </Button>
        {!!openingsDeclined.length && (
          <Button
            className="extranet-inventory-header-errors-button"
            icon={<WarningOutlined style={{ color: '#F5222D' }} />}
            onClick={() => {
              track('Click CTA Errors');

              if (!hotelId) {
                return;
              }

              dispatch(
                tryValidateOpenings({
                  hotelId,
                  openings: openingsDeclined,
                  start: format(currentRange.start, 'yyyy-MM-dd'),
                  inventory,
                  mode: 'view',
                })
              );
            }}
          >
            {`${openingsDeclined.length} ${
              openingsDeclined.length > 1 ? 'errors' : 'error'
            } detected`}
          </Button>
        )}
      </div>
      <div className="left-right-header__right">
        {!isEditing ? (
          isButtonDisabled ? (
            <Tooltip
              title={
                'Activate the standard pricing rule to edit your Staycation rate on specific days'
              }
              placement="left"
            >
              {renderEditButton()}
            </Tooltip>
          ) : (
            renderEditButton()
          )
        ) : (
          <>
            <Button key="cancel-button" onClick={() => handleCancelClick()}>
              Cancel
            </Button>
            <Popover
              content={renderConfirmationPopin}
              placement="bottomRight"
              trigger="click"
              open={isConfirmationPopinVisible}
              onOpenChange={(visible) =>
                visible &&
                dirtyOpenings.length &&
                setIsConfirmationPopinVisible(visible)
              }
            >
              <Button
                key="save-button"
                type="primary"
                disabled={!dirtyOpenings.length}
                loading={isLoading}
              >
                Save
              </Button>
            </Popover>
          </>
        )}
      </div>
    </div>
  );
};

export default InventoryHeader;
