import { Menu } from 'antd';
import _ from 'lodash';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { EntityConnectProps, entityConnect } from 'app/redux/entityConnect';
import { IClub } from 'app/typings';

import ListPage from './ListPage';

// TODO: Filter data by club directly here?
//  Tried it, but it was quite painful to type things correctly (needs a generic entityConnect to do that)
const ClubListPage: FC<Props> = ({ title, clubs, fetchEntities, children }) => {
  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const [clubId, setClubId] = useState(1);
  const [countryId, setCountryId] = useState(1);

  const handleChangeTab = useCallback(
    ({ key }: any) => {
      setClubId(+key);

      const club = _.find(clubs, { id: +key });

      if (club) {
        setCountryId(club.countryId);
      }
    },
    [clubs]
  );

  return (
    <ListPage
      title={title}
      menu={
        <Menu
          mode="horizontal"
          onClick={handleChangeTab}
          selectedKeys={[clubId.toString()]}
        >
          {clubs &&
            clubs.map((cl) => <Menu.Item key={cl.id}>{cl.name}</Menu.Item>)}
        </Menu>
      }
    >
      {children({ clubId, countryId })}
    </ListPage>
  );
};

type ChildProps = {
  clubId: number;
  countryId: number;
};

type ConnectedProps = {
  clubs: IClub[];
};

type OwnProps = {
  title: string;
  children: (props: ChildProps) => ReactNode;
};

type Props = EntityConnectProps<ConnectedProps, OwnProps>;

export default entityConnect<ConnectedProps, OwnProps>(['clubs'])(ClubListPage);
