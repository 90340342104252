import { message } from 'antd';
import queryString from 'query-string';
import { all, put, takeEvery } from 'redux-saga/effects';

import * as userActions from 'app/redux/actions/users';
import { createUserDetail } from 'app/redux/models/Users/UserDetail';
import { del, get, requestGenerator, put as rput } from 'app/redux/requests';

export function* tryFetchUser(
  action: ReturnType<typeof userActions.tryFetchDetail>
) {
  yield requestGenerator(
    () => put(userActions.startLoading()),
    () => get(`admin/users/${action.payload.id}`),
    (result: any) => {
      const detail = createUserDetail(result);

      return all([
        put(userActions.fetchDetailSuccess({ detail })),
        put(userActions.stopLoading()),
      ]);
    },
    (error: any) => {
      message.error('Error fetching User detail', error);

      return put(userActions.stopLoading());
    }
  );
}

export function* trySaveUser(
  action: ReturnType<typeof userActions.trySaveUser>
) {
  yield requestGenerator(
    () => put(userActions.startLoading()),
    () => rput(`admin/users/${action.payload.id}/account`, action.payload.data),
    (result: any) => {
      const detail = createUserDetail(result);

      return all([
        put(userActions.saveUserDetailSuccess({ detail })),
        put(userActions.stopLoading()),
      ]);
    },
    (error: any) => {
      message.error('Error saving user data', error);

      return put(userActions.stopLoading());
    }
  );
}

export function* tryDeleteUser(
  action: ReturnType<typeof userActions.tryDeleteUser>
) {
  yield requestGenerator(
    () => put(userActions.startLoading()),
    () => del(`admin/users/${action.payload.id}`),
    (result: any) => {
      const detail = createUserDetail(result);

      return all([
        put(userActions.fetchDetailSuccess({ detail })),
        put(userActions.stopLoading()),
      ]);
    },
    (error: any) => {
      message.error('Error deleting user data', error);

      return put(userActions.stopLoading());
    }
  );
}

export function* tryFetchUsers({
  payload,
}: ReturnType<typeof userActions.tryFetchUsers>): any {
  const { offset, search, sorting, roles, clubs } = payload;

  const stringifiedParams = queryString.stringify({
    offset,
    search,
    sorting,
    roles,
    clubs,
  });

  return yield requestGenerator(
    () => {},
    () => get(`admin/users?${stringifiedParams}`),
    (result: any) => put(userActions.fetchUsersSuccess({ users: result })),
    (error: any) => put(userActions.fetchUsersError({ error }))
  );
}

export default function* usersSaga() {
  yield all([
    takeEvery(userActions.USER_TRY_FETCH_DETAIL, tryFetchUser),
    takeEvery(userActions.USER_TRY_SAVE_USER, trySaveUser),
    takeEvery(userActions.USER_TRY_DELETE_USER, tryDeleteUser),
    takeEvery(userActions.USER_TRY_FETCH_USERS, tryFetchUsers),
  ]);
}
