import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';

import { QuickFilter } from 'app/components/pages/QuickFilters/types';
import { apiMutation } from 'app/utils/request/api';

const createPath = '/admin/quick-filters' as const;
const updatePath = '/admin/quick-filters/:id' as const;
const listPath = '/admin/quick-filters' as const;

export const useSaveQuickFilter = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const onSuccessSave = (
    isDayPackage: boolean,
    successMessage: string,
    quickFilterId?: number
  ) => {
    queryClient.invalidateQueries({
      queryKey: [listPath, { dayPackage: isDayPackage }],
    });

    if (quickFilterId) {
      queryClient.invalidateQueries({
        queryKey: [updatePath, { id: quickFilterId }],
      });
    }

    message.success(successMessage);

    const queryParams = new URLSearchParams({
      type: isDayPackage ? 'moment' : 'staycation',
    });

    const route = `/quick-filters?${queryParams.toString()}`;

    history.push(route);
  };

  const createQuickFilter = useMutation({
    mutationFn: async (payload: QuickFilter) =>
      apiMutation(
        'POST',
        {
          path: createPath,
        },
        payload
      ) as Promise<QuickFilter>,
    onSuccess: (data: QuickFilter) => {
      onSuccessSave(
        data.dayPackage,
        `The quick filter ${data.label.en} has been successfully created`
      );
    },
    onError: () => {
      message.error('Error while creating quick filter');
    },
  });

  const updateQuickFilter = useMutation({
    mutationFn: async (payload: Required<QuickFilter>) =>
      apiMutation(
        'PUT',
        {
          path: updatePath,
          params: { id: payload.id.toString() },
        },
        payload
      ) as Promise<QuickFilter>,
    onSuccess: (data: QuickFilter) => {
      onSuccessSave(
        data.dayPackage,
        `The quick filter ${data.label.en} has been successfully updated`,
        data.id
      );
    },
    onError: () => {
      message.error('Error while updating quick filter');
    },
  });

  return {
    updateQuickFilter,
    createQuickFilter,
  };
};
