import { message } from 'antd';
import queryString from 'query-string';
import { all, put, takeEvery } from 'redux-saga/effects';

import * as billsActions from 'app/redux/actions/bills';
import { get, requestGenerator } from 'app/redux/requests';

const URL = 'admin/bills';

export function* tryFetchBills({
  payload,
}: ReturnType<typeof billsActions.tryFetchBills>): any {
  return yield requestGenerator(
    () => put(billsActions.startLoading()),
    () => {
      const { search, provider, sortingColumn, sortingOrder, offset, limit } =
        payload;

      const stringifiedParams = queryString.stringify({
        search,
        provider,
        sortingColumn,
        sortingOrder,
        offset,
        limit,
      });

      return get(`${URL}?${stringifiedParams}`);
    },
    (result: any) => put(billsActions.fetchBillsSuccess({ bills: result })),
    (error: any) =>
      all([
        put(billsActions.fetchBillsFailure()),
        message.error('Error fetching Bills', error),
      ])
  );
}

export function* downloadBill({
  payload,
}: ReturnType<typeof billsActions.downloadBill>): any {
  const { billId } = payload;

  return yield requestGenerator(
    () => Promise.resolve(),
    () => get(`${URL}/${billId}/pdf`),
    (result: any, response: any) => {
      const headers = response.headers.get('Content-Disposition').split('; ');

      const filename =
        headers.length === 3
          ? // Header Format: inline; filename="Staycation - Invoice F-202210-1 - VIP Paris Yacht H?tel.pdf"; filename*=utf-8''Staycation%20-%20Invoice%20F-202210-1%20-%20VIP%20Paris%20Yacht%20H%c3%b4tel.pdf
            headers[2].split("filename*=utf-8''")[1]
          : // Header Format: inline; filename="Staycation - Invoice F-202210-2 - Couvent des Minimes.pdf"
            headers[1].split('filename=')[1].replaceAll('"', '');

      return put(
        billsActions.downloadBillSuccess({
          pdf: result,
          filename: decodeURIComponent(filename),
        })
      );
    },
    (error: any) =>
      all([
        put(billsActions.downloadBillFailure()),
        message.error('Error downloading Bill', error),
      ]),
    { expectFile: true }
  );
}

export default function* billsSaga() {
  yield takeEvery(billsActions.BILLS_TRY_FETCH_BILLS, tryFetchBills);
  yield takeEvery(billsActions.BILLS_DOWNLOAD_BILL, downloadBill);
}
