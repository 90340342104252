import { Button, Layout } from 'antd';
import React, { FC, useState } from 'react';
import { connect } from 'react-redux';

import EditPictureModal from 'app/components/commons/EditPictureModal/EditPictureModal';
import { Picture } from 'app/components/commons/Uploader/Types';
import Uploader from 'app/components/commons/Uploader/Uploader';
import {
  tryAddHotelPictures,
  tryEditPicture,
  tryRemoveHotelPicture,
} from 'app/redux/actions/pictures';

import './GalleryDetailEdit.scss';

const { Content, Footer } = Layout;

export const GalleryDetailEdit: FC<Props> = ({
  pictures,
  goToView,
  addHotelPicture,
  editHotelPicture,
  removeHotelPicture,
  kind,
  hotelId,
}) => {
  const [uploadedPictures, setUploadedPictures] = useState<Array<Picture>>([]);
  const [pictureToEdit, setPictureToEdit] = useState<Picture | null>(null);

  const add = (addedPicture: any) => {
    const newPicture = {
      ...addedPicture,
      kind,
    };

    setUploadedPictures((previousUploadedPictures) => [
      ...previousUploadedPictures,
      newPicture,
    ]);
    addHotelPicture(hotelId, kind, newPicture);
  };

  const handleCancel = () => {
    uploadedPictures.forEach((picture) => {
      removeHotelPicture(hotelId, picture.id);
    });
    goToView();
  };

  const renderFooter = () => (
    <Footer className="gallery-detail__footer">
      <div />
      <div>
        <Button size="large" onClick={handleCancel} className="first-button">
          Cancel
        </Button>
        <Button type="primary" size="large" onClick={goToView}>
          Save
        </Button>
      </div>
    </Footer>
  );

  return (
    <>
      <Content className="gallery-detail__content">
        <div className="edit-container">
          <Uploader
            pictures={pictures}
            add={add}
            edit={(picture) => {
              if (picture) {
                setPictureToEdit(picture);
              }
            }}
          />
        </div>
      </Content>
      {renderFooter()}
      {!!pictureToEdit && (
        <EditPictureModal
          picture={pictureToEdit}
          closeModal={() => setPictureToEdit(null)}
          savePicture={(newPictureValues) => {
            editHotelPicture({
              ...pictureToEdit,
              ...newPictureValues,
            });
            setPictureToEdit(null);
          }}
          deletePicture={() => {
            removeHotelPicture(hotelId, pictureToEdit.id);
            setPictureToEdit(null);
          }}
          kind={kind}
        />
      )}
    </>
  );
};

type Props = {
  pictures: Array<Picture & { selected: boolean }>;
  goToView: () => void;
  addHotelPicture: (hotelId: number, kind: string, picture: Picture) => void;
  editHotelPicture: (picture: Picture) => void;
  removeHotelPicture: (hotelId: number, id: number) => void;
  kind: string;
  hotelId: number;
};

const mapDispatchToProps = (dispatch: any) => ({
  addHotelPicture: (hotelId: any, kind: any, picture: any) =>
    dispatch(tryAddHotelPictures(hotelId, kind, [picture])),
  editHotelPicture: (picture: any) => dispatch(tryEditPicture(picture)),
  removeHotelPicture: (hotelId: any, id: any) =>
    dispatch(tryRemoveHotelPicture(hotelId, id)),
});

export default connect(undefined, mapDispatchToProps)(GalleryDetailEdit);
