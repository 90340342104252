import { Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

// import 'styles/form/form-group.scss';

const FormGroup = ({ label, extra, touched, warning, error, children }) => (
  <Form.Item
    label={label}
    extra={extra}
    colon={false}
    validateStatus={touched && error ? 'error' : warning ? 'warning' : ''}
    help={(touched && error) || warning}
  >
    {children}
  </Form.Item>
);

FormGroup.propTypes = {
  label: PropTypes.string,
  extra: PropTypes.string,
  warning: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  children: PropTypes.node,
};

export default FormGroup;
