import { message } from 'antd';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import * as tagsActions from 'app/redux/actions/tags';
import { get, requestGenerator } from 'app/redux/requests';

export function* tryFetchTags() {
  yield requestGenerator(
    () => put(tagsActions.startLoading()),
    () => get('tags'),
    (result: any) => put(tagsActions.fetchTagsSuccess({ result })),
    (error: any) =>
      all([
        put(tagsActions.stopLoading()),
        call(message.error('Error fetching Tags', error)),
      ])
  );
}

export default function* tagsSaga() {
  yield all([takeEvery(tagsActions.TAGS_TRY_FETCH_TAGS, tryFetchTags)]);
}
