import React, { useCallback } from 'react';

import { InputField, SwitchField } from 'app/components/fields';
import {
  SortableItem,
  SortableList,
} from 'app/components/layout/Sortable/List';

const Experiences = ({ fields }: any) => {
  const handleSort = useCallback(
    ({ oldIndex, newIndex }: any) => fields.move(oldIndex, newIndex),
    [fields]
  );

  return (
    <SortableList useDragHandle onSortEnd={handleSort}>
      {fields.map((field: any, idx: any) => {
        const exp = fields.get(idx);

        return (
          <SortableItem index={idx} key={`exp-${exp.id}`} picture={exp.emoji}>
            <>
              <div className="sortable-item__input">
                <InputField
                  name={`${field}.valueProposition`}
                  placeholder={exp.name}
                />
              </div>
              <SwitchField name={`${field}.featured`} size="small" />
            </>
          </SortableItem>
        );
      })}
    </SortableList>
  );
};

export default Experiences;
