import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { SortableElement } from 'react-sortable-hoc';

import { SListItem } from 'app/components/StaycationUI/List';
import { RoomType } from 'app/components/commons/Package/General/Types';
import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';
import SortableHandle from 'app/components/layout/Sortable/SortableHandle';
import { useAppSelector } from 'app/redux/hooks';
import { hasBabyBed, maxRoomCapacity } from 'app/utils/room';
import { pluralize } from 'app/utils/strings';

import './RoomItem.scss';

const RoomItem = ({ field, room, mode, remove }: Props) => {
  const bedCategories = useAppSelector((state) => state.conf.bedCategories);

  if (!field || !room) {
    return null;
  }

  const RoomItemTitle = () => {
    return (
      <div className="room-item-title">
        <div className="title-description">
          <div>{room?.title}</div>
          <div className="room-description">{room?.description}</div>
        </div>
        {<Badge status="success" text="Published" />}
      </div>
    );
  };

  const renderAvatar = () => (
    <div
      className={`room-item__avatar ${
        room.avatar?.name.includes('emoji') ? 'room-item__avatar-emoji' : ''
      }`}
    >
      <PictureComponent {...room.avatar} width={56} />
    </div>
  );

  const renderEdit = () => (
    <div className="edit-link">
      <Link to={`/rooms/${room.id}/edit`} target="_blank">
        <EditOutlined />
      </Link>
    </div>
  );

  const renderDelete = () => <DeleteOutlined onClick={remove} />;

  const renderDragHandler = () => <SortableHandle key="handle" />;

  const renderCapacity = () => {
    return (
      <>
        {room.beds &&
          pluralize(
            maxRoomCapacity(room.beds, bedCategories),
            'guest',
            'guests'
          )}
        {room.beds && hasBabyBed(room.beds) && ' + 1 baby'}
      </>
    );
  };

  const renderActions = () => {
    if (mode === 'edit') {
      return [
        renderCapacity(),
        renderEdit(),
        renderDelete(),
        renderDragHandler(),
      ];
    }

    return [renderCapacity()];
  };

  return (
    <SListItem
      title={RoomItemTitle()}
      avatar={renderAvatar()}
      actions={renderActions()}
      className="room-item"
    />
  );
};

const SortableItem = SortableElement(RoomItem);

const SortableRoomItem = ({ disabled, ...props }: SortableProps) => (
  <SortableItem disabled={disabled} {...props} />
);

type Props = {
  field?: any;
  room?: RoomType;
  mode: string;
  remove: () => void;
};

type SortableProps = Props & {
  index: number;
  disabled?: boolean;
};

export default SortableRoomItem;
