import { Layout, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { capitalize, debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ListPage from 'app/components/lists/ListPage';
import Pagination from 'app/components/lists/Pagination';
import Header from 'app/components/lists/TableHeader';
import { dateTimeCell2 } from 'app/components/lists/cells';
import { DEBOUNCE_TIME_MS, PAGE_LIMIT } from 'app/components/lists/constants';
import { UserTryFetchUsers } from 'app/redux/actions/users';
import { tryFetchUsers } from 'app/redux/actions/users';
import { useAppDispatch } from 'app/redux/hooks';
import { UserList } from 'app/redux/models/Users/UserDetail';
import { selectClubs } from 'app/redux/selectors/conf';
import { areUsersListLoading, getUsersList } from 'app/redux/selectors/users';
import { USER_ROLES, UserRole } from 'app/typings/user';
import {
  parseArrayParam,
  useQueryParamHistory,
} from 'app/utils/queryParamHistory';
import { scrollToTop } from 'app/utils/scroll';
import { SortOrder, formatSortingForAPI, getSortOrder } from 'app/utils/sort';
import { toSnakeCase } from 'app/utils/strings';

import 'styles/lists/left-right-header.scss';
// TODO: use it's own css or use shared stylesheet
import 'styles/pages/Booking/booking.scss';

type UsersListQueryParam = {
  search: string;
  sortOrder: SortOrder;
  sortingField: string;
  clubSorting: string[];
  roleSorting: string[];
  pageNumber: number;
};

const queryParamKeys = {
  search: 's',
  sortOrder: 'so',
  sortingField: 'sf',
  clubSorting: 'cls',
  roleSorting: 'rs',
  pageNumber: 'p',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  createdAtSorting: getSortOrder,
  firstNameSorting: getSortOrder,
  lastNameSorting: getSortOrder,
  clubSorting: parseArrayParam((v) => v),
  roleSorting: parseArrayParam((v) => v),
};

const UsersList = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const users = useSelector(getUsersList);
  const isLoading = useSelector(areUsersListLoading);
  const clubs = useSelector(selectClubs);

  const queryParamInit = useMemo(
    () =>
      ({
        sortingField: 'createdAt',
        sortOrder: 'descend',
        clubSorting: clubs.map((c) => c.id.toString()),
        roleSorting: USER_ROLES,
        pageNumber: 1,
      } as UsersListQueryParam),
    [clubs]
  );

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<UsersListQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const pageNumber = queryParam.pageNumber || 1;

  const formatSorting = useCallback(() => {
    if (!queryParam.sortOrder) return undefined;

    return `${toSnakeCase(queryParam.sortingField)}:${formatSortingForAPI(
      queryParam.sortOrder
    )}`;
  }, [queryParam.sortOrder, queryParam.sortingField]);

  const makeParams = useCallback(
    () => ({
      sorting: formatSorting() || undefined,
      clubs: queryParam.clubSorting || [],
      roles: queryParam.roleSorting || [],
      offset: (pageNumber - 1) * PAGE_LIMIT,
      search: queryParam.search,
    }),
    [
      formatSorting,
      pageNumber,
      queryParam.clubSorting,
      queryParam.roleSorting,
      queryParam.search,
    ]
  );

  const debounceFetch = useMemo(
    () =>
      debounce(
        (params: UserTryFetchUsers) => dispatch(tryFetchUsers(params)),
        DEBOUNCE_TIME_MS
      ),
    [dispatch]
  );

  useEffect(() => {
    debounceFetch(makeParams());
  }, [queryParam, debounceFetch, makeParams]);

  const handleOffset = (x: number) => {
    updateQueryParam({
      pageNumber: pageNumber + x,
    });
    scrollToTop();
  };

  const handleSearch = (search: string) => {
    updateQueryParam({
      search,
      pageNumber: pageNumber,
    });
  };

  const handleRowActions = ({ id }: UserList) => ({
    onClick: () => history.push(`users/${id}/edit`),
  });

  return (
    <ListPage title="Users">
      <Layout className="booking">
        <Table
          title={() => (
            <Header
              handleSearch={handleSearch}
              searchValue={queryParam.search || ''}
              pageNumber={pageNumber}
              pageResultsLength={users.length}
              handleOffset={handleOffset}
            />
          )}
          loading={isLoading}
          onRow={handleRowActions}
          dataSource={users}
          pagination={false}
          rowKey="id"
          onChange={(__, filter, sorter) => {
            // @ts-ignore
            const { field, order } = sorter;

            const roleSorting = (filter.role || []) as string[];
            const clubSorting = (filter.clubId || []) as string[];

            updateQueryParam({
              sortOrder: order,
              sortingField: field,
              roleSorting,
              clubSorting,
              pageNumber: 1,
            });
          }}
        >
          <Column
            key="id"
            title={<div style={{ paddingLeft: 16, minWidth: 92 }}>ID</div>}
            dataIndex="id"
            render={(elem) => <div style={{ fontWeight: 500 }}>{elem}</div>}
          />

          <Column
            key="createdAt"
            title="Created at"
            sorter
            dataIndex="createdAt"
            defaultSortOrder={'descend'}
            render={dateTimeCell2}
          />

          <Column
            key="firstName"
            title="Firstname"
            sorter
            dataIndex={'firstName'}
          />

          <Column
            key="lastName"
            title="Lastname"
            sorter
            dataIndex={'lastName'}
          />

          <Column key="email" title="Email" dataIndex={'email'} />

          <Column
            key="role"
            title="Role"
            dataIndex="role"
            filters={[
              { text: 'Superadmin', value: UserRole.SuperAdmin },
              { text: 'Admin', value: UserRole.Admin },
              { text: 'User', value: UserRole.User },
            ]}
            filteredValue={queryParam.roleSorting || []}
            render={capitalize}
          />

          <Column
            key="clubId"
            title="Club"
            dataIndex="clubId"
            filters={clubs.map((c) => ({
              text: c.name,
              value: c.id.toString(),
            }))}
            filteredValue={queryParam.clubSorting || []}
            defaultFilteredValue={clubs.map((c) => c.id.toString())}
            render={(clubId) => clubs.find((club) => club.id === clubId)?.name}
          />
        </Table>
        <Pagination
          className="booking__footer"
          pageNumber={pageNumber}
          pageResultsLength={users.length}
          handleOffset={handleOffset}
        />
      </Layout>
    </ListPage>
  );
};

export default UsersList;
