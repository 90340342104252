import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';

import type { CustomTag } from 'app/redux/models/CustomTag/CustomTag';
import { apiRequest } from 'app/utils/request/api';

const path = '/tags' as const;

export function useCustomTags() {
  return useQuery({
    queryKey: [path],
    queryFn: () =>
      apiRequest({
        path,
        queryParams: { tagType: 'package_custom' },
      }) as Promise<CustomTag[]>,
    onError: () => {
      message.error('Error fetching CustomTags');
    },
    staleTime: Infinity,
  });
}
