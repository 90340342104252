import { LineChartOutlined } from '@ant-design/icons';
import React from 'react';

import './Chart.scss';

export const EmptyChart = () => {
  return (
    <div className="emptyChartsContainer">
      <LineChartOutlined className="emptyChartsIcon" sizes={'40px'} />
      <div className="emptyChartsText">
        <div className="emptyChartsTitle">Not enough data</div>
        <div className="emptyChartsDescription">
          No data available for this period
        </div>
      </div>
    </div>
  );
};

export default EmptyChart;
