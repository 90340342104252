import { useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { FormInstance, ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  getEditoEventDetailPath,
  getEditoEventListPath,
} from 'app/routes/edito';
import { IEditoEventForm } from 'app/typings/edito';
import { getEditoEventFormStatus } from 'app/utils/edito';
import { scrollToElement } from 'app/utils/scroll';
import { isDefined } from 'app/utils/typing';

import { formatEditoEventFormToRequest } from './format/details';
import { useCreateEditoEvent } from './useCreateEditoEvent';
import { usePublishEditoEvents } from './usePublishEditoEvent';
import { useUpdateEditoEvent } from './useUpdateEditoEvent';

interface Props {
  form: FormInstance<IEditoEventForm>;
  initialEditoEventForm?: IEditoEventForm;
}

const requiredDraftFields = ['title', 'countryId'];

export const useFinishEditoEventForm = ({
  form,
  initialEditoEventForm,
}: Props) => {
  const [sectionsIndexToCollapse, setSectionsIndexToCollapse] = useState(['0']);
  const locationRef = useRef<null | HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const history = useHistory();

  const { mutateAsync: updateEditoEventMutation } = useUpdateEditoEvent();
  const { mutateAsync: createEditoEventMutation } = useCreateEditoEvent();
  const { mutateAsync: publishEditoEventMutation } = usePublishEditoEvents();

  const isCreatingEditoEvent = !isDefined(initialEditoEventForm);

  const refreshListDetailQueries = async () => {
    await queryClient.invalidateQueries({
      queryKey: [getEditoEventListPath],
    });

    if (initialEditoEventForm) {
      await queryClient.invalidateQueries({
        queryKey: [getEditoEventDetailPath, { id: initialEditoEventForm.id }],
      });
    }
  };

  const backToList = () => {
    history.goBack();
  };

  const initialEditoStatus = initialEditoEventForm
    ? getEditoEventFormStatus({
        endDate: initialEditoEventForm.endDate,
        startDate: initialEditoEventForm.startDate,
        published: initialEditoEventForm.published,
        publishedAt: initialEditoEventForm.publishedAt,
      })
    : 'draft';

  const updateEditoEvent = (values: IEditoEventForm) => {
    if (initialEditoEventForm) {
      return updateEditoEventMutation({
        ...formatEditoEventFormToRequest(values),
        id: initialEditoEventForm.id,
      });
    }
  };

  const createEditoEvent = async (
    values: IEditoEventForm,
    asDraft: boolean
  ) => {
    const createdEditoEventId = await createEditoEventMutation(
      formatEditoEventFormToRequest(values)
    );

    message.success(
      `The event has been successfully created ${asDraft ? 'as draft' : ''}`
    );

    return createdEditoEventId;
  };

  const finishUpdatingEditoEvent = async (values: IEditoEventForm) => {
    await updateEditoEvent(values);
    await refreshListDetailQueries();
    backToList();
  };

  const publishDraftEditoEvent = async (
    validatedEditoEventForm: IEditoEventForm
  ) => {
    if (isCreatingEditoEvent) {
      const createdEditoEventId = await createEditoEvent(
        validatedEditoEventForm,
        false
      );

      await publishEditoEventMutation({
        id: createdEditoEventId.toString(),
      });
    } else {
      const updatedEditoEventId = await updateEditoEventMutation({
        ...formatEditoEventFormToRequest(validatedEditoEventForm),
        id: initialEditoEventForm.id,
      });

      await publishEditoEventMutation({
        id: updatedEditoEventId.toString(),
      });
    }

    await refreshListDetailQueries();
    backToList();
  };

  const clearErrors = () => {
    form.getFieldsError().forEach((field) => {
      if (field.name[0] !== 'sections') {
        form.setFields([
          {
            name: field.name,
            errors: [],
          },
        ]);
      }
    });
  };

  const saveDraft = async () => {
    try {
      clearErrors();
      await form.validateFields(requiredDraftFields);

      const formValues = form.getFieldsValue();

      if (isCreatingEditoEvent) {
        await createEditoEvent(formValues, true);
      } else {
        await updateEditoEvent(formValues);
      }

      await refreshListDetailQueries();
      backToList();
    } catch (error: unknown) {
      if (typeof error === 'object' && error && 'errorFields' in error) {
        const err = error as ValidateErrorEntity<IEditoEventForm>;
        const fieldsError = err.errorFields.map((err) => ({
          name: err.name,
          errors: err.errors,
        }));

        scrollToElement(locationRef);
        form.setFields(fieldsError);
      }
    }
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity<IEditoEventForm>) => {
    const invalidSectionIndexes: string[] = [];

    errorInfo.errorFields.forEach((error) => {
      const isSectionError = error.name[0] === 'sections';

      if (isSectionError) {
        const index = error.name[1]?.toString();

        if (
          !invalidSectionIndexes.find((invalidIndex) => invalidIndex === index)
        ) {
          invalidSectionIndexes.push(index);
        }
      }
    });

    if (invalidSectionIndexes.length) {
      setSectionsIndexToCollapse(invalidSectionIndexes);
    }
  };

  const onFinish =
    initialEditoStatus === 'draft'
      ? publishDraftEditoEvent
      : finishUpdatingEditoEvent;

  return {
    sectionsIndexToCollapse,
    saveDraft,
    onFinish,
    onFinishFailed,
    locationRef,
    initialEditoStatus,
    setSectionsIndexToCollapse,
  };
};
