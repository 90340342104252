import { Button, Popconfirm } from 'antd';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';

import { tryDownloadPdf, tryResendECard } from 'app/redux/actions/giftCards';
import { useAppDispatch } from 'app/redux/hooks';
import { GiftCard } from 'app/redux/models/GiftCard/GiftCard';

import PropertiesContent from '../PropertiesContent/PropertiesContent';

type Props = {
  giftCard: GiftCard;
};

export const Properties = ({ giftCard }: Props) => {
  const { code } = giftCard;

  const dispatch = useAppDispatch();

  const downloadPdf = useCallback(() => {
    dispatch(tryDownloadPdf({ code }));
  }, [dispatch, code]);

  const resendECard = useCallback(() => {
    dispatch(tryResendECard({ code }));
  }, [dispatch, code]);

  const isEmailSendDateAlreadyPassed = useMemo(() => {
    // This function makes sense only for a gift card of type email
    if (giftCard.giftCardType !== 'email') {
      return false;
    }

    return moment().isSameOrAfter(giftCard.emailSendDate, 'day');
  }, [giftCard]);

  return (
    <div className="bloc">
      <div className="header-title-button">
        <div className="title-with-button">Properties</div>
        <div>
          {giftCard.giftCardType === 'email' &&
            isEmailSendDateAlreadyPassed && (
              <Popconfirm
                title={`You're going to resend the e-card to ${giftCard.beneficiaryEmail}`}
                onConfirm={resendECard}
                okText="Send"
                cancelText="Cancel"
                placement="bottomRight"
                getPopupContainer={(trigger) =>
                  trigger.parentElement || trigger
                }
              >
                <Button className="button">Resend e-card</Button>
              </Popconfirm>
            )}
          <Button className="button" onClick={downloadPdf}>
            Download pdf
          </Button>
        </div>
      </div>
      <div className="generic-content">
        <PropertiesContent giftCard={giftCard} />
      </div>
    </div>
  );
};

export default Properties;
