import _ from 'lodash';
import React, { useMemo } from 'react';

import { useAppSelector } from 'app/redux/hooks';
import {
  GiftCard,
  PhysicalGiftCardProperties,
} from 'app/redux/models/GiftCard/GiftCard';

import Line from '../Line/Line';

type Props = {
  giftCard: GiftCard & PhysicalGiftCardProperties;
};

const PhysicalProperties = ({ giftCard }: Props) => {
  const {
    giftCardType,
    senderName,
    beneficiaryName,
    shippingFirstName,
    shippingLastName,
    shippingStreet1,
    shippingStreet2,
    shippingZipCode,
    shippingCity,
    shippingCountryId,
  } = giftCard;

  const countries = useAppSelector((state) => state.conf.countries);

  const shippingCountryName = useMemo(
    () => countries.find(({ id }) => id === shippingCountryId)?.name,
    [countries, shippingCountryId]
  );

  return (
    <>
      <Line title="Type" value={_.capitalize(giftCardType)} />
      <Line title="From" value={senderName} />
      <Line title="To" value={beneficiaryName} />
      <Line title="First name (shipping)" value={shippingFirstName} />
      <Line title="Last name (shipping)" value={shippingLastName} />
      <Line title="Street 1" value={shippingStreet1} />
      <Line title="Street 2" value={shippingStreet2} />
      <Line title="Zip code" value={shippingZipCode} />
      <Line title="City" value={shippingCity} />
      <Line title="Country" value={shippingCountryName} />
    </>
  );
};

export default PhysicalProperties;
