import { InfoCircleOutlined } from '@ant-design/icons';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  InputNumber,
  Switch,
  Tooltip,
} from 'antd';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import classNames from 'classnames';
import {
  addDays,
  addYears,
  eachDayOfInterval,
  format,
  isAfter,
  isBefore,
  subDays,
} from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { useContext, useMemo, useState } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { PercentageInput } from 'app/components/commons/PercentageInput/PercentageInput';
import { useBulkEditSave } from 'app/hooks/data/useBulkEditSave';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import { IBulkEditPayload } from 'app/typings/bulkEdit';

import { BarRowTooltip } from '../Tooltip/BarRowTooltip';
import { StaycationDiscountTooltip } from '../Tooltip/StaycationDiscountTooltip';
import { StaycationRateTooltip } from '../Tooltip/StaycationRateTooltip';
import { Inventory, Room } from '../Types';
import {
  computeCrossedPriceDescription,
  computeDiscount,
  computePackageValue,
  computePriceFromDiscount,
  getDiscountClassName,
  isValidDiscount,
} from '../Utils';
import ValidationBulkEditModal from '../ValidationBulkEditModal/ValidationBulkEditModal';

import './BulkEdit.scss';

const RULES = [{ required: true, message: 'Required' }];

type FieldDisplayValues = {
  stock: boolean;
  bar: boolean;
  discountPrice: boolean;
  discountRate: boolean;
};

const initialDisplayValue = {
  stock: false,
  bar: false,
  discountPrice: false,
  discountRate: false,
};

const days = [
  { label: 'Mon', value: 1, fullName: 'Monday' },
  { label: 'Tue', value: 2, fullName: 'Tuesday' },
  { label: 'Wed', value: 3, fullName: 'Wednesday' },
  { label: 'Thu', value: 4, fullName: 'Thursday' },
  { label: 'Fri', value: 5, fullName: 'Friday' },
  { label: 'Sat', value: 6, fullName: 'Saturday' },
  { label: 'Sun', value: 7, fullName: 'Sunday' },
];

type Props = {
  isBulkEditOpen: boolean;
  setIsBulkEditOpen: (isBulkEditOpen: boolean) => void;
  room?: Room;
  currentRange?: Interval;
  inventory?: Inventory;
  hotelId: number;
  autoDiscountRate?: number;
};

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export const BulkEdit = ({
  setIsBulkEditOpen,
  isBulkEditOpen,
  room,
  inventory,
  currentRange,
  hotelId,
  autoDiscountRate,
}: Props) => {
  const [form] = Form.useForm();
  const { track } = useAmplitude();

  const formValues = Form.useWatch([], form);

  const { data, isLoading, mutate } = useBulkEditSave(hotelId);

  const [isValidationBulkEditModalOpen, setIsValidationBulkEditModalOpen] =
    useState<boolean>(false);

  const [payload, setPayload] = useState<IBulkEditPayload>({
    hotelId: 0,
    roomId: 0,
    startDate: new Date(),
    endDate: new Date(),
    days: [1, 2, 3, 4, 5, 6, 7],
    stock: undefined,
    bar: undefined,
    discountPrice: undefined,
    discountRate: undefined,
    dryRun: true,
  });

  const [fromDate, setFromDate] = useState<Date>();
  const [openValues, setOpenValues] =
    useState<FieldDisplayValues>(initialDisplayValue);

  const packageValue = computePackageValue(
    undefined,
    inventory,
    fromDate || new Date()
  );

  const { currencyFormatter, currencyInput } = useContext(CurrencyContext);

  const tooltipDetail = computeCrossedPriceDescription(
    inventory,
    currencyFormatter
  );

  const discountFromPrice = useMemo(
    () =>
      form.getFieldValue('discountPrice') &&
      form.getFieldValue('bar') &&
      computeDiscount(
        {
          discountPrice: formValues.discountPrice,
          bar: Math.abs(formValues.bar),
        },
        Math.abs(formValues.bar) + packageValue
      ),
    [formValues, form, packageValue]
  );

  const priceFromDiscount = useMemo(
    () =>
      form.getFieldValue('discountRate') &&
      form.getFieldValue('bar') &&
      computePriceFromDiscount(
        formValues.discountRate,
        formValues.bar + packageValue
      ),
    [formValues, form, packageValue]
  );

  const onFinish = (values: any) => {
    const payload: IBulkEditPayload = {
      ...values,
      hotelId,
      roomId: room?.room.id,
      startDate: format(values.startDate, 'yyyy-MM-dd'),
      endDate: format(values.endDate, 'yyyy-MM-dd'),
      dryRun: true,
    };

    setPayload(payload);
    mutate(payload);

    const amplitudeValue = {
      from: format(values.startDate, 'yyyy-MM-dd'),
      to: format(values.endDate, 'yyyy-MM-dd'),
      daysChanged: days
        .filter((day) =>
          values.days.some((daySelect: number) => day.value === daySelect)
        )
        .map((day) => day.fullName),
      numberDaysChanged: eachDayOfInterval({
        start: values.startDate,
        end: values.endDate,
      }).filter((x) =>
        values.days.some(
          (daySelect: number) =>
            new Date(x).getDay() === (daySelect === 7 ? 0 : daySelect)
        )
      ).length,
      stock: values.stock || null,
      bar: values.bar || null,
      staycationRate: values.discountPrice || null,
    };

    track('Save Bulk Edit', amplitudeValue);
    setIsValidationBulkEditModalOpen(true);
  };

  const opening = room?.openings.find(
    (o) => o.date === format(fromDate || new Date(), 'yyyy-MM-dd')
  );

  const toggleSwitch = (switchValue: boolean, section: string) => {
    setOpenValues((values) => ({ ...values, [section]: switchValue }));

    !switchValue && form.setFieldValue([section], undefined);
    form.validateFields([section, 'discountPrice']);
  };

  return (
    <Drawer
      title={
        <div className="bulkEdit__header">
          <h1>Bulk Edit</h1>
          <h2>· {room?.roomName}</h2>
        </div>
      }
      className="bulkEdit"
      width={'510px'}
      closable={false}
      open={isBulkEditOpen}
      bodyStyle={{ padding: 0 }}
      onClose={() => setIsBulkEditOpen(false)}
    >
      <Form
        name="bulkEditForm"
        autoComplete="off"
        className="form"
        form={form}
        onFinish={onFinish}
      >
        <div className="bulkEdit__body">
          <section className="bulkEdit__body__selectedDates">
            <h2>Selected dates</h2>
            <div className="bulkEdit__body__selectedDates_pickers">
              <div className="from">
                <Form.Item label="From" name="startDate" rules={RULES}>
                  <DatePicker
                    format={'EEE dd MMM'}
                    disabledDate={(d) =>
                      !d ||
                      isBefore(d, subDays(new Date(), 1)) ||
                      isAfter(d, addYears(new Date(), 1))
                    }
                    showToday
                    onChange={(dateFrom) => setFromDate(dateFrom || new Date())}
                  />
                </Form.Item>
              </div>
              <div className="upTo">
                <Form.Item
                  label="Up to and including:"
                  name="endDate"
                  rules={RULES}
                >
                  <DatePicker
                    format={'EEE dd MMM'}
                    disabledDate={(d) =>
                      !d ||
                      isBefore(d, addDays(fromDate || new Date(), 1)) ||
                      isAfter(d, addYears(new Date(), 1))
                    }
                    showToday
                    disabled={!fromDate && true}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="bulkEdit__body__selectedDates_applyTo">
              <p className="subtitle">
                Which day of the week do you want to apply changes to:
              </p>
              <div className="listCheckbox">
                <Form.Item name="days" initialValue={[1, 2, 3, 4, 5, 6, 7]}>
                  <Checkbox.Group options={days} />
                </Form.Item>
              </div>
            </div>
          </section>
          <section className="bulkEdit__body__values">
            <h2>Values</h2>
            {!inventory?.channelManager && (
              <>
                <div className="values_wrappers values__stock">
                  <div className="title_switch">
                    <div className="title_tooltip">
                      <h3>Stock per day</h3>
                      <Tooltip
                        title={
                          'Bookings already made will be subtracted from that value'
                        }
                        placement="top"
                      >
                        <InfoCircleOutlined color="#BBB" />
                      </Tooltip>
                    </div>
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      defaultChecked={openValues.stock}
                      onChange={(value: boolean) => {
                        toggleSwitch(value, 'stock');
                      }}
                      disabled={!!inventory?.channelManager && true}
                    />
                  </div>
                  <p className="subtitle">
                    Number of rooms you want to sell for each day
                  </p>
                  <div className="inputContainer">
                    <Form.Item
                      shouldUpdate
                      name="stock"
                      rules={[
                        {
                          required: openValues.stock,
                          message:
                            'Stock is required if you activate the toggle',
                        },
                      ]}
                      noStyle={
                        formValues && formValues.stock === null ? false : true
                      }
                    >
                      <InputNumber
                        precision={0}
                        disabled={
                          (!!inventory?.channelManager || !openValues.stock) &&
                          true
                        }
                        type="number"
                        min={0}
                        placeholder="Stock number"
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="values_wrappers values__bar">
                  <div className="title_switch">
                    <div className="title_tooltip">
                      <h3>BAR R/O (NA/NR)</h3>
                      {inventory && (
                        <Tooltip
                          title={
                            <BarRowTooltip
                              detail={tooltipDetail}
                              inventory={inventory}
                            />
                          }
                          overlayClassName="extranet-inventory-tooltip bulkedit"
                          placement="topLeft"
                        >
                          <InfoCircleOutlined color="#BBB" />
                        </Tooltip>
                      )}
                    </div>
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      defaultChecked={openValues.bar}
                      onChange={(value: boolean) => {
                        toggleSwitch(value, 'bar');
                      }}
                      disabled={!!inventory?.channelManager && true}
                    />
                  </div>
                  <p className="subtitle">
                    Best Available Rate (NA/NR) for this room
                  </p>
                  <div className="inputContainer">
                    <Form.Item
                      name="bar"
                      rules={[
                        {
                          required: openValues.bar,
                          message: 'BAR is required if you activate the toggle',
                        },
                      ]}
                      noStyle={
                        formValues && formValues.bar === null ? false : true
                      }
                    >
                      {currencyInput({
                        disabled: !openValues.bar,
                        min: 0,
                        placeholder: 'BAR R/O',
                        onWheel: (e) => e.currentTarget.blur(),
                      })}
                    </Form.Item>
                    <p className="detail crossedText">
                      {openValues.bar &&
                        typeof form.getFieldValue('bar') === 'number' &&
                        currencyFormatter(
                          form.getFieldValue('bar') + packageValue ||
                            packageValue
                        )}
                    </p>
                  </div>
                </div>
              </>
            )}
            <div className="values_wrappers values__discountPrice">
              <div className="title_switch">
                <div className="title_tooltip">
                  <h3>Staycation rate</h3>
                  <Tooltip
                    title={<StaycationRateTooltip />}
                    overlayClassName="extranet-inventory-tooltip bulkedit"
                    placement="topLeft"
                  >
                    <InfoCircleOutlined color="#BBB" />
                  </Tooltip>
                </div>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={openValues.discountPrice}
                  checked={openValues.discountPrice}
                  onChange={(value: boolean) => {
                    toggleSwitch(value, 'discountPrice');
                    toggleSwitch(false, 'discountRate');
                  }}
                />
              </div>
              <p className="subtitle">
                Rate that will be published on Staycation for the whole package
                (discount must be between -18% and -70% of crossed rate)
              </p>
              <div className="inputContainer">
                <Form.Item
                  name="discountPrice"
                  dependencies={['bar']}
                  rules={[
                    {
                      validator() {
                        if (!openValues.discountPrice) {
                          return Promise.resolve('');
                        }

                        /** Only == to match both null and undefined */

                        if (
                          openValues.bar &&
                          form.getFieldValue('discountPrice') == null
                        ) {
                          return Promise.reject(
                            'Discount price is required if you activate the Bar toggle'
                          );
                        }

                        if (form.getFieldValue('discountPrice') == null) {
                          return Promise.reject(
                            'Discount price is required if you activate the toggle'
                          );
                        }

                        if (
                          openValues.bar &&
                          form.getFieldValue('bar') &&
                          inventory &&
                          !isValidDiscount(
                            discountFromPrice,
                            inventory.pkg.dayPackage
                          )
                        ) {
                          return Promise.reject(
                            'Staycation rate must be between -18% and -70% of crossed rate'
                          );
                        }

                        return Promise.resolve('');
                      },
                    },
                  ]}
                  noStyle={
                    openValues.bar
                      ? form.getFieldError('discountPrice').length > 0
                        ? true
                        : false
                      : formValues?.discountPrice === null
                      ? false
                      : true
                  }
                >
                  {currencyInput({
                    disabled: !openValues.discountPrice,
                    min: 0,
                    placeholder: 'Rate',
                    onWheel: (e) => e.currentTarget.blur(),
                  })}
                </Form.Item>

                <Form.Item shouldUpdate>
                  {() => {
                    if (
                      !discountFromPrice ||
                      form.getFieldError('discountPrice').length > 0
                    ) {
                      return null;
                    }

                    return (
                      <p
                        className={classNames([
                          'detail',
                          inventory &&
                            getDiscountClassName(
                              discountFromPrice,
                              fromDate || new Date(),
                              opening,
                              inventory
                            ),
                        ])}
                      >
                        {`${discountFromPrice}%`}
                      </p>
                    );
                  }}
                </Form.Item>
              </div>
              <div className="title_switch">
                <div className="title_tooltip">
                  <h3>Staycation discount</h3>
                  <Tooltip
                    title={<StaycationDiscountTooltip />}
                    overlayClassName="extranet-inventory-tooltip bulkedit"
                    placement="topLeft"
                  >
                    <InfoCircleOutlined color="#BBB" />
                  </Tooltip>
                </div>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={openValues.discountRate}
                  checked={openValues.discountRate}
                  onChange={(value: boolean) => {
                    toggleSwitch(value, 'discountRate');
                    toggleSwitch(false, 'discountPrice');
                  }}
                />
              </div>
              <p className="subtitle">
                Discount applied to the room (impacts the Staycation rate)
              </p>
              <div className="inputContainer">
                <Form.Item
                  name="discountRate"
                  dependencies={['bar']}
                  rules={[
                    {
                      validator() {
                        if (!openValues.discountRate) {
                          return Promise.resolve('');
                        }

                        /** Only == to match both null and undefined */

                        if (
                          openValues.bar &&
                          form.getFieldValue('discountRate') == null
                        ) {
                          return Promise.reject(
                            'Discount price is required if you activate the Bar toggle'
                          );
                        }

                        if (
                          inventory &&
                          form.getFieldValue('discountRate') !== undefined &&
                          !isValidDiscount(
                            -form.getFieldValue('discountRate'),
                            inventory.pkg.dayPackage
                          )
                        ) {
                          return Promise.reject(
                            'Staycation rate must be between -18% and -70% of crossed rate'
                          );
                        }

                        return Promise.resolve('');
                      },
                    },
                  ]}
                  noStyle={false}
                >
                  <PercentageInput
                    disabled={!openValues.discountRate}
                    min={0}
                    placeholder="Discount"
                    onWheel={(e) => e.currentTarget.blur()}
                  />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => {
                    if (
                      !priceFromDiscount ||
                      form.getFieldError('discountRate').length > 0
                    ) {
                      return null;
                    }

                    return (
                      <p
                        className={classNames([
                          'detail',
                          inventory &&
                            getDiscountClassName(
                              -formValues.discountRate,
                              fromDate || new Date(),
                              opening,
                              inventory
                            ),
                        ])}
                      >
                        {`${currencyFormatter(priceFromDiscount)}`}
                      </p>
                    );
                  }}
                </Form.Item>
              </div>
            </div>
          </section>
        </div>
        <div className="bulkEdit__footer">
          <Form.Item shouldUpdate>
            {() => (
              <>
                <Button
                  size="large"
                  type="ghost"
                  onClick={() => setIsBulkEditOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  key="save"
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={
                    Object.values(openValues).every((item) => item === false) ||
                    !form.isFieldsTouched(['startDate', 'endDate'], true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                  loading={isLoading}
                >
                  Save changes
                </Button>
              </>
            )}
          </Form.Item>
        </div>
      </Form>
      {isValidationBulkEditModalOpen && !isLoading && data && currentRange && (
        <ValidationBulkEditModal
          setIsValidationBulkEditModalOpen={setIsValidationBulkEditModalOpen}
          setIsBulkEditOpen={setIsBulkEditOpen}
          hotelId={hotelId}
          currentRange={currentRange}
          payload={payload}
          dryRunResults={data}
          autoDiscountRate={autoDiscountRate}
        />
      )}
    </Drawer>
  );
};

export default BulkEdit;
