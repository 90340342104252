import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { useHistory } from 'react-router-dom';

import ActionCellMenu from 'app/components/lists/ActionCellMenu';
import { dateTimeCell } from 'app/components/lists/cells';
import { GiftCardBooking } from 'app/redux/models/GiftCard/GiftCard';
import { sortDate } from 'app/utils/sort';
import { formatCurrency } from 'app/utils/strings';

const sortBookingsByDateField = (
  valueA: GiftCardBooking,
  valueB: GiftCardBooking
) => sortDate(valueA.date, valueB.date, 'ascend');

const sortBookingsByAmountField = (
  valueA: GiftCardBooking,
  valueB: GiftCardBooking
) => valueA.couponAmount - valueB.couponAmount;

type BookingsLinkedProps = {
  bookings: GiftCardBooking[];
  currency: string;
};

const BookingsLinked: React.FC<BookingsLinkedProps> = ({
  currency,
  bookings,
}: BookingsLinkedProps) => {
  const history = useHistory();

  const handleActionClick = ({ code }: GiftCardBooking) => {
    history.push(`/bookings/${code}`);
  };

  const handleRowClick = ({ code }: GiftCardBooking) => {
    return {
      onClick: () => history.push(`/bookings/${code}`),
    };
  };

  return (
    <div className="bloc">
      <div className="title">Bookings linked</div>
      <Table
        dataSource={bookings}
        pagination={false}
        rowKey="code"
        onRow={handleRowClick}
      >
        <Column className="strong" key="code" title="Code" dataIndex="code" />
        <Column
          key="date"
          title="Date"
          sorter={sortBookingsByDateField}
          defaultSortOrder="descend"
          dataIndex="date"
          render={dateTimeCell('dd/MM/yyyy')}
        />
        <Column
          key="couponAmount"
          title="Amount"
          sorter={sortBookingsByAmountField}
          render={(amount) => <div>{formatCurrency(amount, currency)}</div>}
          dataIndex="couponAmount"
        />
        <Column
          key="actions"
          onCell={() => ({ onClick: (e) => e.stopPropagation() })}
          render={(entity) => (
            <ActionCellMenu
              entity={entity}
              handleActionClick={handleActionClick}
            />
          )}
        />
      </Table>
    </div>
  );
};

export default BookingsLinked;
