import { Form, FormListFieldData, Input } from 'antd';

import { requiredRule } from 'app/utils/rules';

import { HeaderWrapper } from '../../../../HeaderWrapper/HeaderWrapper';
import { ActionContainer } from '../ActionContainer/ActionContainer';

import './LinkRedirectionForm.scss';

interface Props {
  field: FormListFieldData;
}

export const LinkRedirectionForm = ({ field }: Props) => {
  const { name } = field;

  return (
    <ActionContainer field={field}>
      <HeaderWrapper title="Links" gapTitleChildren={16}>
        <div className="linkRedirectionForm">
          <HeaderWrapper isBold={false} title="Web link">
            <Form.Item name={[name, 'webLink']} rules={[requiredRule]}>
              <Input placeholder="Paste link here" />
            </Form.Item>
          </HeaderWrapper>
          <HeaderWrapper isBold={false} title="App link">
            <Form.Item name={[name, 'appLink']} rules={[requiredRule]}>
              <Input placeholder="Paste link here" />
            </Form.Item>
          </HeaderWrapper>
        </div>
      </HeaderWrapper>
    </ActionContainer>
  );
};
