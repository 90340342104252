import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, FormInstance, Modal } from 'antd';
import { isFuture } from 'date-fns';
import { useMemo } from 'react';

import { EditoEventStatus, IEditoEventForm } from 'app/typings/edito';
import {
  EventConfirmAction,
  getEventEditionConfirmAction,
} from 'app/utils/edito';

type Props = {
  form: FormInstance<IEditoEventForm>;
  startDate?: Date;
  endDate?: Date;
  editoTitle: string;
  initialEditoStatus: EditoEventStatus;
  saveDraft: () => void;
  submitForm: () => void;
};

const { confirm } = Modal;

type EventConfirmLabelAction = Record<
  EventConfirmAction,
  {
    buttonLabel: string;
    onClick: () => void;
  }
>;

export const EditoEventFooter = ({
  editoTitle,
  endDate,
  startDate,
  initialEditoStatus,
  submitForm,
  saveDraft,
}: Props) => {
  const eventConfirmLabelActions: EventConfirmLabelAction = useMemo(() => {
    return {
      updateAndMoveToFeatured: {
        onClick: () => {
          submitForm();
        },
        buttonLabel: 'Update & Move to Featured',
      },
      updateAndMoveToPublished: {
        onClick: () => {
          submitForm();
        },
        buttonLabel: 'Update & Move to Published',
      },
      updateAndRemoveFromFeatured: {
        onClick: () => {
          const willBecomeScheduled = startDate && isFuture(startDate);

          confirm({
            title: 'Remove event from Featured',
            content: willBecomeScheduled
              ? `Your event "${editoTitle}" will be removed from the Homepage and scheduled for a later date.`
              : `Your event "${editoTitle}" will be removed from the Homepage.`,
            icon: <ExclamationCircleOutlined style={{ color: '#FF382C' }} />,
            okText: 'Update & Remove',
            okButtonProps: {
              style: {
                backgroundColor: '#FF382C',
                color: '#FFF',
              },
            },
            cancelText: 'Cancel',
            onOk() {
              submitForm();
            },
          });
        },
        buttonLabel: 'Update & Remove from Featured',
      },
      updateAndSchedule: {
        onClick: () => {
          submitForm();
        },
        buttonLabel: 'Update & Schedule',
      },
      update: {
        onClick: () => {
          submitForm();
        },
        buttonLabel: 'Update',
      },
    };
  }, [editoTitle, startDate, submitForm]);

  const updateButtonLabelClick = () => {
    if (startDate && endDate && initialEditoStatus !== 'draft') {
      const confirmAction: EventConfirmAction = getEventEditionConfirmAction(
        { startDate, endDate },
        initialEditoStatus
      );

      return eventConfirmLabelActions[confirmAction];
    }

    return eventConfirmLabelActions.update;
  };

  const updateButton = updateButtonLabelClick();

  return (
    <div>
      {initialEditoStatus === 'draft' ? (
        <div>
          <Button size="large" onClick={saveDraft}>
            Save as draft
          </Button>
          <Button size="large" type="primary" onClick={submitForm}>
            Publish
          </Button>
        </div>
      ) : (
        <Button onClick={updateButton.onClick} size="large" type="primary">
          {updateButton.buttonLabel}
        </Button>
      )}
    </div>
  );
};
