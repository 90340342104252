import { Button, Checkbox, Form, Input, Modal, Popconfirm } from 'antd';
import React, { useContext, useState } from 'react';

import CurrencyInput from 'app/components/commons/Currency/CurrencyInput/CurrencyInput';
import { Option, Select } from 'app/components/fields/Select';
import { CurrencyContext } from 'app/context/CurrencyContext/CurrencyContext';
import { useAppSelector } from 'app/redux/hooks';
import type {
  GiftCard,
  Refund,
  RefundForm,
} from 'app/redux/models/GiftCard/GiftCard';
import { selectCancellationReasons } from 'app/redux/selectors/conf';
import { parseInputNumber } from 'app/utils/typing';

import './RefundModal.scss';

const RULES = [{ required: true, message: 'Required' }];

const defaultFormValues: RefundForm = {
  amountCb: 0,
  deactivateGiftCard: false,
};

type Props = {
  title: string;
  onOk: (values: RefundForm) => void;
  onCancel: () => void;
  giftCard: GiftCard;
  refunds: Refund[];
};

export const RefundModal = ({
  title,
  onOk,
  onCancel,
  giftCard,
  refunds,
}: Props) => {
  const cancellationReasons = useAppSelector(selectCancellationReasons);
  const { currencyFormatter, currencySymbol } = useContext(CurrencyContext);
  const [form] = Form.useForm();
  const { remainingValue } = giftCard;
  const alreadyRefunded = refunds.reduce((acc, val) => acc + val.amountCb, 0);
  const maximumToRefund = (remainingValue || 0) - alreadyRefunded;

  const [formValues, setFormValues] = useState<RefundForm>(defaultFormValues);

  const merge = (values: RefundForm) => {
    setFormValues((oldValues) => ({
      ...oldValues,
      ...values,
    }));
  };

  const onSubmit = () => {
    onOk(formValues);
    onCancel();
  };

  return (
    <Modal
      width={560}
      open
      title={title}
      onCancel={onCancel}
      okText="Cancel"
      className="refund-modal"
      wrapClassName="admin-modal"
      centered
      // Popconfirm doesn't watch if button is disabled or not
      // https://github.com/ant-design/ant-design/issues/14788
      footer={
        <Popconfirm
          title="Are you sure you want to refund this gift card ?"
          placement="bottomRight"
          onConfirm={() => form.submit()}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary">Confirm</Button>
        </Popconfirm>
      }
    >
      <Form<RefundForm>
        onValuesChange={(v, h) => merge(h)}
        initialValues={defaultFormValues}
        colon={false}
        form={form}
        onFinish={onSubmit}
      >
        <div className="input-row">
          <Form.Item
            name="amountCb"
            label="Refund on CB"
            help={'Max ' + currencyFormatter(maximumToRefund)}
          >
            <CurrencyInput
              min={0}
              max={maximumToRefund}
              currency={currencySymbol}
              parser={parseInputNumber}
            />
          </Form.Item>
        </div>

        <div className="input-row">
          <Form.Item name="reason" label="Reason type" rules={RULES}>
            <Select placeholder="Choose a reason">
              {cancellationReasons.map((reason) => (
                <Option key={reason.id} value={reason.id}>
                  {reason.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="input-row">
          <Form.Item name="comment" label="Comment">
            <Input.TextArea placeholder="Optional" rows={4} />
          </Form.Item>
        </div>

        <div className="input-row">
          <Form.Item
            name="deactivateGiftCard"
            valuePropName="checked"
            label="Deactivate?"
          >
            <Checkbox>Yes, deactivate the gift card</Checkbox>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
