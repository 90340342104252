import { Tag } from 'antd';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { FC, useCallback } from 'react';

import { makeInputField } from 'app/components/fields';
import DatePicker from 'app/components/fields/DatePicker';

import 'styles/pages/Sales/bookable-days-picker.scss';

const BookableDaysPicker: FC<Props> = ({ value, onChange }) => {
  const handleAdd = useCallback(
    (date?: string) => {
      if (date) {
        const changed = _.uniq([date, ...value].sort());

        onChange(changed);
      }
    },
    [value, onChange]
  );

  const handleRemove = useCallback(
    (date: string) => {
      if (date) {
        const changed = _.without(value, date);

        onChange(changed);
      }
    },
    [value, onChange]
  );

  return (
    <div>
      <DatePicker
        onChange={handleAdd}
        valueFormat="YYYY-MM-DD"
        format="dddd DD MMMM"
      />
      <div className="bookable-days-picker__days">
        {value.map((date) => (
          <Tag visible closable onClose={() => handleRemove(date)}>
            {format(new Date(date), 'EEE dd MMM')}
          </Tag>
        ))}
      </div>
    </div>
  );
};

type Props = {
  value: string[];
  onChange: (dates: string[]) => void;
};

export default makeInputField(BookableDaysPicker);
