import { Button, Modal } from 'antd';
import { format } from 'date-fns';

import { useBulkEditSave } from 'app/hooks/data/useBulkEditSave';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import { IBulkEditPayload } from 'app/typings/bulkEdit';

import { PricingRuleBulkEditAlert } from '../RoomRow/AutomatedDiscount/_components/PricingRuleBulkEditAlert';
import { Opening } from '../Types';

import './ValidationBulkEditModal.scss';

type Props = {
  setIsBulkEditOpen: (isBulkEditOpen: boolean) => void;
  setIsValidationBulkEditModalOpen: (
    isValidationBulkEditModalOpen: boolean
  ) => void;
  hotelId: number;
  currentRange: Interval;
  payload: IBulkEditPayload;
  dryRunResults: Opening[];
  autoDiscountRate?: number;
};

export const ValidationBulkEditModal = ({
  setIsBulkEditOpen,
  setIsValidationBulkEditModalOpen,
  hotelId,
  currentRange,
  payload,
  dryRunResults,
  autoDiscountRate,
}: Props) => {
  const nbPublished = dryRunResults.filter(({ published }) => published).length;
  const nbErrors = dryRunResults.filter(({ published }) => !published).length;
  const { track } = useAmplitude();

  const bulkEditSave = useBulkEditSave(
    hotelId,
    format(currentRange.start, 'yyyy-MM-dd')
  );

  const handleCancel = () => {
    setIsValidationBulkEditModalOpen(false);
    track('Return to Bulk Edit Changes', {
      errors: nbErrors,
    });
  };

  const handleSaveChange = () => {
    bulkEditSave?.mutate({ ...payload, dryRun: false });

    track('Accept Bulk Edit Changes', {
      stockPublished: nbPublished,
      stockNotPublished: nbErrors,
      nbAutoDiscountDeactivated: autoDiscountRate ? nbPublished + nbErrors : 0,
    });

    setIsValidationBulkEditModalOpen(false);
    setIsBulkEditOpen(false);
  };

  return (
    <Modal
      className="modal"
      open={true}
      title="Apply your changes?"
      onCancel={handleCancel}
      wrapClassName="validationBulkEditModalClassname"
      footer={[
        <Button size="large" type="ghost" onClick={handleCancel}>
          Back to edit
        </Button>,
        <Button
          key="save"
          size="large"
          type="primary"
          htmlType="submit"
          onClick={handleSaveChange}
        >
          Save changes
        </Button>,
      ]}
    >
      <p>By saving your changes, you will apply the following modifications:</p>
      <ul>
        {nbPublished > 0 && (
          <li>
            <span className="modal__validDays">{nbPublished} days</span> will be
            edited and published
          </li>
        )}

        {nbErrors > 0 && (
          <li>
            <span className="modal__errorDays">{nbErrors} days</span> have
            errors and will be edited but not published
          </li>
        )}
      </ul>
      {autoDiscountRate && <PricingRuleBulkEditAlert />}
    </Modal>
  );
};

export default ValidationBulkEditModal;
