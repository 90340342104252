import { PropsWithChildren } from 'react';

import './EditoContainer.scss';

type Props = {
  title: string;
  subTitle: string;
};

export const EditoContainer = ({
  subTitle,
  title,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div className="editoContainer">
      <div className="titleContainer">
        <span className="title">{title}</span>
        <span className="subTitle">{subTitle}</span>
      </div>

      <div className="childrenContainer">{children}</div>
    </div>
  );
};
