import { Form, FormInstance, FormProps } from 'antd';
import React from 'react';

export type FormLegacyRenderPropProps<FormValues> = Omit<
  FormProps<FormValues>,
  'children' | 'form'
> & {
  form: FormInstance<FormValues>;
  children: (
    values: FormValues,
    form: FormInstance<FormValues>
  ) => React.ReactNode;
};

/**
 * A part of the codebase was using a render prop syntax for antd's Form component that worked but was not documented.
 * When we try to upgrade typescript, we start gettings errors about this syntax.
 * We created this component has a wrapper around antd's Form component to replicate the render prop syntax without any TS error.
 * This component should be used in place of antd's Form component when we want to use a render prop that returns the current form values and that re-renders when any form value changes.
 */
export function FormLegacyRenderProp<
  FormValues = any // TODO require all Form instances to define a type for FormValues.
>({ children, form, ...props }: FormLegacyRenderPropProps<FormValues>) {
  return (
    <Form form={form} {...props}>
      {/*
      // TODO try to rewrite our Forms without render props. We could rely on useWatch instead: https://4x.ant.design/components/form/#Form.useWatch
      // @ts-ignore this syntax is not documented but it works.   */}
      {(values: FormValues) => children(values, form)}
    </Form>
  );
}
