import { Form, Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { SDetailLayout } from 'app/components/StaycationUI';
import { useFinishEditoEventForm } from 'app/hooks/data/edito/events/useFinishEditoEventForm';
import { useGetEditoEventDetails } from 'app/hooks/data/edito/events/useGetEditoEventDetails';
import { IEditoEventForm } from 'app/typings/edito';
import { isDefined } from 'app/utils/typing';

import { EditoSectionsList } from '../../commons/Detail/EditoSections/EditoSectionsList/EditoSectionsList';

import './EditoEventDetail.scss';
import { EditoEventFooter } from './Footer/EditoEventFooter';
import { EditoEventLocation } from './Location/EditoEventLocation';
import { EditoEventMainInformation } from './MainInformation/EditoEventMainInformation';
import { EditoEventPublication } from './Publication/EditoEventPublication';

export const emptySection: IEditoEventForm['sections'][number] = {
  title: '',
  actionType: 'empty',
};

export const EditoEventDetail = () => {
  const { id: paramId } = useParams<{ id: string }>();
  const parsedParamId = parseInt(paramId);
  const editoEventId = isNaN(parsedParamId) ? undefined : parsedParamId;
  const [form] = Form.useForm<IEditoEventForm>();
  const startDate = Form.useWatch('startDate', form);
  const endDate = Form.useWatch('endDate', form);
  const title = Form.useWatch('title', form);

  const { initialEditoEventForm } = useGetEditoEventDetails(editoEventId);

  const {
    locationRef,
    onFinish,
    onFinishFailed,
    saveDraft,
    initialEditoStatus,
    sectionsIndexToCollapse,
    setSectionsIndexToCollapse,
  } = useFinishEditoEventForm({
    form,
    initialEditoEventForm,
  });

  const isCreatingEditoEvent = !isDefined(initialEditoEventForm);

  useEffect(() => {
    if (initialEditoEventForm) {
      form.setFieldsValue(initialEditoEventForm);
    }
  }, [form, initialEditoEventForm]);

  const initialValues = initialEditoEventForm ?? { sections: [emptySection] };

  return (
    <Layout className="editoEventDetailLayout">
      <SDetailLayout
        mode={'edit'}
        isDirty
        title={isCreatingEditoEvent ? 'New event' : 'Edit event'}
        customFooterButtons={
          <EditoEventFooter
            form={form}
            initialEditoStatus={initialEditoStatus}
            editoTitle={title}
            endDate={endDate}
            startDate={startDate}
            submitForm={form.submit}
            saveDraft={saveDraft}
          />
        }
      >
        <Content className="content-container">
          <Form
            form={form}
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            scrollToFirstError={{
              behavior: 'smooth',
              inline: 'center',
              block: 'center',
            }}
          >
            <div className="container">
              <div ref={locationRef}>
                <EditoEventLocation form={form} />
              </div>
              <EditoEventMainInformation
                currentTitle={title}
                initialEditoEventForm={initialEditoEventForm}
              />
              <EditoSectionsList
                sectionsIndexToCollapse={sectionsIndexToCollapse}
                setSectionsIndexToCollapse={setSectionsIndexToCollapse}
                form={form}
              />
              <EditoEventPublication startDate={startDate} endDate={endDate} />
            </div>
          </Form>
        </Content>
      </SDetailLayout>
    </Layout>
  );
};
