// TODO: do it without mutating the original list
export const move = (list, fromIndex, toIndex) => {
  const element = list[fromIndex];

  list.splice(fromIndex, 1);
  list.splice(toIndex, 0, element);

  return list;
};

export default move;
