import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';

import type {
  ISlide,
  ISlideCollection,
  ISlidePackage,
} from 'app/typings/slides';
import { SlideType } from 'app/typings/slides';
import { apiRequest } from 'app/utils/request/api';
import { assertNever } from 'app/utils/typing';

type QueryParams = {
  search?: string;
  sortingOrder?: 'DESC' | 'ASC';
  sortingColumn?: string;
  offset?: number;
  limit?: number;
};

export function useSlideEntities(
  slideType: ISlide['slideType'],
  clubId: number,
  queryParams: QueryParams = {}
) {
  return useQuery({
    queryKey: [
      {
        slideType,
        queryParams,
      },
    ],
    queryFn: () => {
      if (slideType === SlideType.PACKAGE) {
        return apiRequest({
          path: '/admin/slides/packages',
          queryParams,
        }) as Promise<ISlidePackage[]>;
      }

      if (slideType === SlideType.COLLECTION) {
        return apiRequest({
          path: '/admin/slides/collections/:clubId',
          params: { clubId: clubId.toString() },
          queryParams,
        }) as Promise<ISlideCollection[]>;
      }

      if (slideType === SlideType.EDITO) {
        return;
      }

      assertNever(slideType);
    },
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    keepPreviousData: true,
  });
}
