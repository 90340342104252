export enum RateModes {
  SINGLE_RATE = 'SINGLE_RATE',
  MULTI_RATE = 'MULTI_RATE',
}

export const rateModeLabels = [
  { mode: RateModes.MULTI_RATE, label: 'BAR + Staycation Rate' },
  { mode: RateModes.SINGLE_RATE, label: 'BAR' },
] as const;

export const getRateModeLabel = (rateMode?: RateModes): string => {
  return (
    rateModeLabels.find(({ mode }) => mode === rateMode)?.label ||
    'BAR + Staycation rate'
  );
};

export const RateModesDataSource = (
  Object.keys(RateModes) as Array<keyof typeof RateModes>
).map((key) => ({
  id: RateModes[key] as RateModes,
  name:
    rateModeLabels.find((cml) => cml.mode === RateModes[key])?.label ||
    'Unknown',
}));
