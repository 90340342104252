import classNames from 'classnames';
import React from 'react';

import {
  FormattedApiResults,
  Period,
  PeriodToCompare,
  Unit,
} from 'app/typings/analytics';

import { getDataTypeWording } from '../../commons/Utils';
import Chart from '../Chart/Chart';
import { DashboardTaskCenter } from '../DashboardTaskCenter/DashboardTaskCenter';
import { DashboardTitle } from '../DashboardTitle/DashboardTitle';
import DataCard from '../DataCard/DataCard';

import './DashboardSection.scss';

const MAX_CARD_WITH_TASK_CENTER = 2;
const LARGE_CARD_LIMIT = 3;
const MEDIUM_CARD_LIMIT = 4;

type DashboardSectionProps = {
  title?: string;
  anchor?: string;
  results: FormattedApiResults[];
  isReady: boolean;
  unit: Unit;
  period: Period;
  dateRange: Date[];
  index: number;
  periodToCompare: PeriodToCompare;
};

const DashboardSection = ({
  title,
  anchor,
  results,
  isReady,
  unit,
  period,
  dateRange,
  index,
  periodToCompare,
}: DashboardSectionProps) => {
  const hasTaskCenter = index === 0;

  return (
    <div className="dashboardSection" id={anchor}>
      {title && <DashboardTitle title={title} />}

      {results.map((row, indexSet) => {
        const isFirstIndexWithTC = hasTaskCenter && indexSet === 0;

        const numberCardsColumn = isFirstIndexWithTC
          ? MAX_CARD_WITH_TASK_CENTER
          : row.cards.length <= LARGE_CARD_LIMIT
          ? LARGE_CARD_LIMIT
          : MEDIUM_CARD_LIMIT;

        const numberCardsRow = Math.ceil(row.cards.length / numberCardsColumn);

        if (row.cards.length === 0 || row.graphs.length === 0) return null;

        return (
          <>
            <div className="cards" key={'cards' + indexSet}>
              <div
                className={classNames([
                  'cardsContainer',
                  {
                    cardsWithTaskcenter: isFirstIndexWithTC,
                  },
                ])}
                style={{
                  gridTemplateColumns: `repeat(${numberCardsColumn}, 1fr)`,
                  gridTemplateRows: `repeat(${numberCardsRow}, 1fr)`,
                }}
              >
                {row.cards.map((data) => {
                  return (
                    <DataCard
                      key={data.type}
                      dataType={data.type}
                      cardData={data.data}
                      showComparePrevPeriod
                      showDataBadge
                      periodToCompare={periodToCompare}
                    />
                  );
                })}
              </div>
              {isFirstIndexWithTC && <DashboardTaskCenter />}
            </div>

            <div className="charts">
              {row.graphs.map((data, indexChart) => {
                const { hasChart } = getDataTypeWording(
                  data.type,
                  periodToCompare
                );

                if (hasChart) {
                  return (
                    <Chart
                      key={data.type}
                      chartData={data.data}
                      dataType={data.type}
                      index={indexChart}
                      isFetching={!isReady}
                      unit={unit}
                      dateRange={dateRange}
                      period={period}
                      periodToCompare={periodToCompare}
                    />
                  );
                }

                return <></>;
              })}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default DashboardSection;
