import { Button } from 'antd';
import React from 'react';

type PricingRulePopinProps = {
  setIsConfirmationPopinVisible: (isConfirmationPopinVisible: boolean) => void;
  handleDeactivationAutomation: () => void;
  title?: string;
  description: string;
  onClickNo: () => void;
  onClickYes: () => void;
};

export const PricingRulePopin = ({
  setIsConfirmationPopinVisible,
  handleDeactivationAutomation,
  title,
  description,
  onClickNo,
  onClickYes,
}: PricingRulePopinProps) => {
  return (
    <div className="automate-discount-confirmation-popin">
      <div className="popin-text">
        {title && <p className="bold-text">{title}</p>}
        <p>{description}</p>
      </div>
      <div className="popin-cta">
        <Button
          id="cancelDesactivationDiscountRule"
          className="popin-no"
          onClick={onClickNo}
        >
          No
        </Button>
        <Button
          id="validateDesactivationDiscountRule"
          className="popin-yes"
          type="primary"
          onClick={onClickYes}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};
