import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import React, { FC, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { Room, RoomCategory } from '../../Types';

import './RoomCategories.scss';
import RoomModal from './RoomModal/RoomModal';

export const RoomCategories: FC<Props> = ({
  rooms,
  saveRoom,
  deleteRoom,
  roomCategories,
  hotelId,
}) => {
  const [selectedRoom, setSelectedRoom] = useState<Room | {} | null>(null);

  const categories: any = useMemo(
    () =>
      roomCategories.reduce(
        (acc, val) => ({
          ...acc,
          [val.id]: val.name,
        }),
        {}
      ),
    [roomCategories]
  );

  const getActions = (room: any) => [
    <EditOutlined onClick={() => setSelectedRoom(room)} />,
    <DeleteOutlined onClick={() => deleteRoom(room.id)} />,
  ];

  const getAvatar = (room: any) => {
    const firstPicture = room.pictures?.[0];

    if (!firstPicture) {
      return null;
    }

    return (
      <div className="avatar">
        <img
          className="twic pic"
          data-src={`image:pictures/${firstPicture.pictureId}`}
          src="https://staycation.twic.pics/v1/placeholder:transparent"
          alt=""
        />
      </div>
    );
  };

  const sortedRooms = rooms.sort((r1, r2) => r1.id - r2.id);

  return (
    <div className="room-categories">
      <div className="categories">
        <List
          itemLayout="horizontal"
          dataSource={sortedRooms}
          renderItem={(room) => (
            <List.Item actions={getActions(room)}>
              <List.Item.Meta
                avatar={getAvatar(room)}
                title={room.categoryId ? categories[room.categoryId] : ''}
                description={room.name}
              />
            </List.Item>
          )}
        />
      </div>
      <div className="footer">
        <Button icon={<PlusOutlined />} onClick={() => setSelectedRoom({})}>
          Add a category
        </Button>
      </div>
      {!!selectedRoom && (
        <RoomModal
          saveRoom={saveRoom}
          close={() => setSelectedRoom(null)}
          room={selectedRoom}
          categories={categories}
          hotelId={hotelId}
        />
      )}
    </div>
  );
};

type Props = {
  rooms: Array<Room>;
  saveRoom: (room: Room) => void;
  deleteRoom: (roomId: number) => void;
  roomCategories: Array<RoomCategory>;
  hotelId: number;
};

const mapStateToProps = (state: any) => ({
  roomCategories: state.conf.roomCategories,
});

export default connect(mapStateToProps)(RoomCategories);
