import { isDefined } from '../typing';

/**
 * This helper infers the type of the params needed to build the API URL
 * params are prefixed by `:`
 * e.g. InferUrlParam<'/:slug/packages/:id/availabilities'> = { id: string, slug: string }
 */
type InferUrlParam<Path extends string> =
  Path extends `${string}:${infer PotentialPathParam}`
    ? PotentialPathParam extends `${infer PathParam}/${infer RestPotentialPathParam}`
      ? {
          [K in
            | PathParam
            | keyof InferUrlParam<RestPotentialPathParam>]: string;
        }
      : { [K in PotentialPathParam]: string }
    : {};

// Replace with EmptyObject from type-fest when we upgrade to a more recent version of TS
type EmptyObject = Record<string, never>;

type QueryParamValue = number | string | boolean;

type QueryParamsObject = Partial<
  Record<string, QueryParamValue | QueryParamValue[]>
>;

type InferUrlParamsObject<Path extends string> =
  InferUrlParam<Path> extends EmptyObject
    ? { params?: undefined }
    : { params: InferUrlParam<Path> };

export type ForgeUrlArgs<Path extends string> = {
  path: Path;
  queryParams?: QueryParamsObject;
} & InferUrlParamsObject<Path>;

export function forgeUrl<Path extends string>(
  baseUrl: string,
  args: ForgeUrlArgs<Path>
): string {
  const path = forgetUrlPath(
    args.path,
    (args as { params: InferUrlParam<Path> }).params ?? {}
  );

  const url = new URL(path, baseUrl);

  const queryParams: QueryParamsObject = args.queryParams ?? {};

  for (const [key, value] of Object.entries(queryParams)) {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          url.searchParams.append(key, value.join(','));
        }
      } else {
        url.searchParams.append(key, value.toString());
      }
    }
  }

  return url.toString();
}

function forgetUrlPath<PathName extends string>(
  pathName: PathName,
  params: InferUrlParam<PathName>
): string {
  const paramsCopy: Record<string, string> = {
    ...params,
  };

  return pathName.replace(/:(\w+)/g, (_, param: string) => {
    if (!(param in params)) {
      throw new Error(`missing param ${param}`);
    }

    delete paramsCopy[param];

    return (params as Record<string, string>)[param];
  });
}
