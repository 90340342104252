export type Coordinates = {
  readonly lat: number;
  readonly lng: number;
};

export function createCoordinates(values: any): Coordinates {
  const { lat, lng } = values;

  return {
    lat,
    lng,
  };
}
