import { Button, ConfigProvider, Select } from 'antd';
import locale from 'antd/es/locale/en_GB';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/en-gb';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import type { RangeValue } from 'rc-picker/lib/interface';
import { useEffect, useMemo, useState } from 'react';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import { Period, PeriodToCompare, Unit } from 'app/typings/analytics';
import { rangeValueIsNotNull } from 'app/utils/dates';

import {
  formatDateText,
  getPeriodsDates,
  getRangeValue,
  getUnitFromRange,
} from '../../commons/Utils';

import './DashboardHeader.scss';
import { DashboardMenu } from './DashboardMenu';

const { Option } = Select;

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);
const { RangePicker } = DatePicker;

type DashboardHeaderProps = {
  setDateRange: (dateRange: [Date, Date]) => void;
  dateRange: [Date, Date];
  setUnit: (unit: Unit) => void;
  unit: Unit;
  setPeriod: (period: Period) => void;
  period: Period;
  setPeriodToCompare: (periodToCompare: PeriodToCompare) => void;
  periodToCompare: PeriodToCompare;
  menu: { label: string; key: string }[];
  saveScroll: () => void;
};

const UNIT_OPTIONS = [
  {
    value: 'DAILY',
    label: 'Daily',
  },
  {
    value: 'WEEKLY',
    label: 'Weekly',
  },
  {
    value: 'MONTHLY',
    label: 'Monthly',
  },
  {
    value: 'YEARLY',
    label: 'Yearly',
  },
] as const satisfies readonly { value: Unit; label: string }[];

const PERIODS_OPTIONS = [
  {
    value: 'custom',
    label: 'Custom',
  },
  {
    value: 'last7Days',
    label: 'Last 7 days',
  },
  {
    value: 'last4Weeks',
    label: 'Last 4 weeks',
  },
  {
    value: 'last3Months',
    label: 'Last 3 months',
  },
  {
    value: 'last12Months',
    label: 'Last 12 months',
  },
] as const satisfies readonly { value: Period; label: string }[];

const COMPARE_TO_OPTIONS = [
  {
    value: 'previousPeriod',
    label: 'Previous period',
  },
  {
    value: 'lastYear',
    label: 'Last Year',
  },
] as const satisfies readonly { value: PeriodToCompare; label: string }[];

moment.locale('en-gb', {
  week: {
    dow: 1,
  },
});

export const DashboardHeader = ({
  setDateRange,
  dateRange,
  setUnit,
  unit,
  setPeriod,
  period,
  setPeriodToCompare,
  periodToCompare,
  menu,
  saveScroll,
}: DashboardHeaderProps) => {
  const { track } = useAmplitude();

  const [pendingRangeValue, setPendingRangeValue] =
    useState<[Date, Date]>(dateRange);
  const [openRangePicker, setOpenRangePicker] = useState(false);

  const unitOptions = useMemo(
    () => getUnitFromRange(period, [...UNIT_OPTIONS], dateRange),
    [period, dateRange]
  );

  useEffect(() => {
    if (
      !unitOptions.some((option) => option.value === unit) &&
      unitOptions[0].value
    ) {
      setUnit(unitOptions[0].value);
    }
  }, [dateRange, setUnit, unitOptions, unit]);

  const onChangePeriod = (newPeriod: Period) => {
    onChangeDashboardConfig(() => {
      track('Change quick action', { value: newPeriod });

      setPeriod(newPeriod);
      setDateRange(getRangeValue(unit, newPeriod));
    });
  };

  const onChangeUnit = (newUnit: Unit) => {
    onChangeDashboardConfig(() => {
      track('Change unit', { value: newUnit });
      setUnit(newUnit);

      setDateRange(getRangeValue(newUnit, period, dateRange));
    });
  };

  const onChangeRange = (newRange: RangeValue<Date>) => {
    if (rangeValueIsNotNull(newRange)) {
      onChangeDashboardConfig(() => {
        setPendingRangeValue(newRange);
      });
    }
  };

  const applyCustomDates = () => {
    track('Use custom dates', {
      startDate: pendingRangeValue[0] || '',
      endDate: pendingRangeValue[1] || '',
    });
    setPeriod('custom');
    setOpenRangePicker(false);

    setDateRange(getRangeValue(unit, 'custom', pendingRangeValue));
  };

  const onChangeCompareTo = (newCompareTo: PeriodToCompare) => {
    onChangeDashboardConfig(() => {
      track('Change compare to', { value: newCompareTo });
      setPeriodToCompare(newCompareTo);
    });
  };

  const onChangeDashboardConfig = (func: () => void) => {
    func();
    saveScroll();
  };

  return (
    <div className="dashboardHeader">
      <div className="customizeDashboard">
        <div className="leftPart">
          <Select
            optionLabelProp="label"
            optionFilterProp="children"
            className="headerSelect period"
            onChange={onChangePeriod}
            value={period}
          >
            {PERIODS_OPTIONS.map((periodOption) => (
              <Option
                key={periodOption.value}
                value={periodOption.value}
                label={periodOption.label}
              >
                <div className="optionDashboardItem">
                  <div
                    className={classNames([
                      'optionValue',
                      { selected: periodOption.value === period },
                    ])}
                  >
                    {periodOption.label}
                  </div>
                </div>
              </Option>
            ))}
          </Select>
          <ConfigProvider locale={locale}>
            <RangePicker
              className="periodRange"
              format="eee DD MMM YY"
              onChange={onChangeRange}
              value={dateRange}
              allowClear={false}
              renderExtraFooter={() => (
                <div className="rangeFooter">
                  <Button type="primary" onClick={applyCustomDates}>
                    Apply
                  </Button>
                </div>
              )}
              open={openRangePicker}
              onOpenChange={() => {
                setOpenRangePicker(true);
              }}
              onBlur={() => setOpenRangePicker(false)}
            />
          </ConfigProvider>
        </div>
        <span>compare to</span>
        <div className="rightPart">
          <Select
            optionLabelProp="label"
            optionFilterProp="children"
            onChange={onChangeCompareTo}
            value={periodToCompare}
            className="headerSelect unit"
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ width: '380px' }}
          >
            {COMPARE_TO_OPTIONS.map((compare) => (
              <Option
                key={compare.value}
                value={compare.value}
                label={compare.label}
              >
                <div className="optionDashboardItem">
                  <div
                    className={classNames([
                      'optionValue',
                      { selected: compare.value === periodToCompare },
                    ])}
                  >
                    {compare.label}
                  </div>
                  <div className="previousDates">
                    {formatDateText(
                      getPeriodsDates(dateRange, unit, compare.value)
                        .datesPreviousPeriod
                    )}
                  </div>
                </div>
              </Option>
            ))}
          </Select>

          <Select
            optionLabelProp="label"
            optionFilterProp="children"
            onChange={onChangeUnit}
            value={unit}
            className="headerSelect unit"
          >
            {unitOptions.map((unitOption) => (
              <Option
                key={unitOption.value}
                value={unitOption.value}
                label={unitOption.label}
              >
                <div className="optionDashboardItem">
                  <div
                    className={classNames([
                      'optionValue',
                      { selected: unitOption.value === unit },
                    ])}
                  >
                    {unitOption.label}
                  </div>
                </div>
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="dashboardNavigation">
        <DashboardMenu menu={menu} />
      </div>
    </div>
  );
};

export default DashboardHeader;
