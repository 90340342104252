/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import React, { FC, useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';

import Pic from 'app/components/commons/Pic';
import { Picture } from 'app/components/commons/Uploader/Types';

import './UploadItem.scss';

export const UploadItem: FC<IProps> = ({
  item,
  onPreview,
  onRemove,
  onEdit,
  draggable,
}) => {
  const [isHover, setIsOver] = useState(false);

  return (
    <div className={`upload-item ${draggable ? 'upload-item-sortable' : ''}`}>
      <div className="upload-item__thumbnail">
        <Pic pictureId={item.pictureId} alt={item.alt} />
      </div>
      <div
        onMouseLeave={() => setIsOver(false)}
        onMouseOver={() => setIsOver(true)}
        className="upload-item__hover-area"
      >
        {!!isHover && (
          <div className="upload-item__hover-buttons">
            {!!onPreview && <EyeOutlined onClick={onPreview} />}
            {!!onEdit && <EditOutlined onClick={onEdit} />}
            {!!onRemove && <DeleteOutlined onClick={onRemove} />}
          </div>
        )}
      </div>
    </div>
  );
};

const SortableItem = SortableElement(UploadItem);

const SortableUploadItem: FC<ISortableProps> = ({ disabled, ...props }) => (
  <SortableItem draggable={!disabled} disabled={disabled} {...props} />
);

interface IProps {
  item: Picture;
  onPreview?: () => void;
  onRemove?: () => void;
  onEdit?: () => void;
  draggable?: boolean;
}

interface ISortableProps extends IProps {
  index: number;
  disabled?: boolean;
}

export default SortableUploadItem;
