import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { displayOrderEditoEventPath } from 'app/routes/edito';
import { IEditoEventListResponse } from 'app/typings/edito';
import { apiMutation } from 'app/utils/request/api';

type PayloadProps = {
  countryId: number;
  clubId?: number;
  ids: number[];
};

export const useUpdateOrderEditoEvents = () => {
  return useMutation<IEditoEventListResponse[], unknown, PayloadProps, unknown>(
    {
      mutationFn: async (payload: PayloadProps) =>
        apiMutation(
          'PATCH',
          {
            path: displayOrderEditoEventPath,
          },
          payload
        ),
      onSuccess: () => {
        message.success('The order of the event has been successfully updated');
      },
      onError: () => {
        message.error('Error while updating the order of the event');
      },
    }
  );
};
