import { Badge, Button, Progress, Tag } from 'antd';
import { differenceInHours, format, isAfter } from 'date-fns';
import moment from 'moment-timezone';
import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Card from 'app/components/layout/Card';
import { entitySelector } from 'app/redux/selectors';
// TODO: horrible mix between moment & date-fns
import { ISaleDates } from 'app/typings';

import 'styles/pages/Sales/sale-info.scss';

import SaleEditModal from './SaleEditModal';

const SaleInfo: FC<{ sale: ISaleDates }> = ({ sale }) => {
  const { startDate, endDate, bookableDays } = sale;

  const [editModal, setEditModal] = useState<
    'sale' | 'extraDays' | undefined
  >();

  const country = useSelector(entitySelector('countries', sale.countryId));

  const { isOpen, isClosed, progress } = useMemo(() => {
    const saleLength = differenceInHours(
      new Date(endDate),
      new Date(startDate)
    );
    const saleProgress = differenceInHours(new Date(), new Date(startDate));

    return {
      isOpen: isAfter(new Date(), new Date(startDate)),
      isClosed: isAfter(new Date(), new Date(endDate)),
      progress: (100 * saleProgress) / saleLength,
    };
  }, [startDate, endDate]);

  return (
    <div className="sale-info">
      <Card
        title={`Current Sale ${sale.open ? '' : '(FORCED CLOSED)'}`}
        className="sale-info__timeline"
        actions={[<Button onClick={() => setEditModal('sale')}>Edit</Button>]}
      >
        <div className="sale-info__progress">
          <Badge status={isOpen ? 'success' : 'default'} />
          <Progress
            status="success"
            strokeWidth={4}
            percent={progress}
            showInfo={false}
          />
          <Badge status={isClosed ? 'success' : 'default'} />
        </div>
        <div className="sale-info__labels">
          <p>Open</p>
          <p>Close</p>
        </div>
        <div className="sale-info__dates">
          <p>
            {moment(startDate)
              .tz(country.timezone)
              .format('dddd DD MMMM • HH:mm')}
          </p>
          <p>
            {moment(endDate)
              .tz(country.timezone)
              .format('dddd DD MMMM • HH:mm')}
          </p>
        </div>
        <div className="sale-info__section">
          <h4>Bookable days</h4>
          <div className="sale-info__bookable-days">
            {bookableDays.map((day) => (
              <Tag>{format(new Date(day), 'EEE dd MMM')}</Tag>
            ))}
          </div>
        </div>
      </Card>
      <Card
        title="Extranet openings"
        className="sale-info__extra-days"
        actions={[
          <Button onClick={() => setEditModal('extraDays')}>Edit</Button>,
        ]}
      >
        {sale.extraDays.map((day) => (
          <div className="sale-info__extra-day">
            {format(new Date(day), 'EEEE dd MMMM')}
          </div>
        ))}
        {!sale.extraDays.length && <div>No extras inventory days.</div>}
      </Card>
      <SaleEditModal
        sale={sale}
        editType={editModal}
        onClose={() => setEditModal(undefined)}
      />
    </div>
  );
};

export default SaleInfo;
