import { message as messageAntd } from 'antd';
import type { MessageType } from 'antd/lib/message';

export const copyToClipboard = (text: string, message?: MessageType): void => {
  navigator.clipboard.writeText(text).then(
    () => {
      if (message) {
        return message;
      }

      messageAntd.success('Text copied to clipboard.');
    },
    () => {
      messageAntd.error('Failed to copy text to clipboard.');
    }
  );
};

export default copyToClipboard;
