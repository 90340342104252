import queryString from 'query-string';
import { put, takeEvery } from 'redux-saga/effects';

import { get, requestGenerator } from 'app/redux/requests';

import * as bookingsActions from '../actions/bookings';

const URL = 'admin/bookings';

export function* tryFetchBookings({
  payload,
}: ReturnType<typeof bookingsActions.tryFetchBookings>): any {
  const { offset, search, sorting, status, psp, beginning, ending } = payload;

  const stringifiedParams = queryString.stringify({
    offset,
    search,
    sorting,
    status,
    psp,
    beginning,
    ending,
  });

  return yield requestGenerator(
    () => {},
    () => get(`${URL}?${stringifiedParams}`),
    (result: any) =>
      put(bookingsActions.fetchBookingsSuccess({ bookings: result })),
    (error: any) => put(bookingsActions.fetchBookingsFailure({ error }))
  );
}

export default function* bookingsSaga() {
  yield takeEvery(bookingsActions.TRY_FETCH_BOOKINGS, tryFetchBookings);
}
