import React from 'react';

import Page from 'app/components/layout/Page';

const Home = () => (
  <Page title="Welcome to a new and better admin!">
    <h2>Soon, wonderful dashboards.</h2>
  </Page>
);

export default Home;
