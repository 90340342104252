import _ from 'lodash';
import React from 'react';

import {
  GiftCard,
  InternalGiftCardProperties,
  VoucherGiftCardProperties,
} from 'app/redux/models/GiftCard/GiftCard';

import Line from '../Line/Line';

type Props = {
  giftCard: GiftCard & (VoucherGiftCardProperties | InternalGiftCardProperties);
};

const VoucherProperties = ({ giftCard }: Props) => {
  const { giftCardType, beneficiaryName, minAmount } = giftCard;

  return (
    <>
      <Line title="Type" value={_.capitalize(giftCardType)} />
      <Line title="Beneficiary name" value={beneficiaryName} />
      <Line title="Minimum code use amount" value={minAmount} />
    </>
  );
};

export default VoucherProperties;
