import type { AnyAction } from 'redux';

import * as actions from 'app/redux/actions/conf';
import type { BedCategory } from 'app/redux/models/BedCategory/BedCategory';
import type { CancellationReason } from 'app/redux/models/CancellationReason/CancellationReason';
import type { Club } from 'app/redux/models/Club/Club';
import type { Country } from 'app/redux/models/Country/Country';
import type { ExperienceCategory } from 'app/redux/models/ExperienceCategory/ExperienceCategory';
import type { GuideCategory } from 'app/redux/models/GuideCategory/GuideCategory';
import type { HotelGroup } from 'app/redux/models/HotelGroup/HotelGroup';
import type { HotelServiceType } from 'app/redux/models/HotelServiceType/HotelServiceType';
import type { RoomCategory } from 'app/redux/models/RoomCategory/RoomCategory';
import type { RoomFeature } from 'app/redux/models/RoomFeature/RoomFeature';
import type { SpaceType } from 'app/redux/models/SpaceType/SpaceType';

export type Conf = {
  readonly roomCategories: Array<RoomCategory>;
  readonly roomFeatures: Array<RoomFeature>;
  readonly bedCategories: Array<BedCategory>;
  readonly hotelGroups: Array<HotelGroup>;
  readonly clubs: Array<Club>;
  readonly countries: Array<Country>;
  readonly cancellationReasons: Array<CancellationReason>;
  readonly experienceCategories: Array<ExperienceCategory>;
  readonly spaceTypes: Array<SpaceType>;
  readonly hotelsServicesTypes: Array<HotelServiceType>;
  readonly guideCategories: Array<GuideCategory>;
};

export const initialState: Conf = {
  roomCategories: [],
  roomFeatures: [],
  bedCategories: [],
  hotelGroups: [],
  clubs: [],
  countries: [],
  cancellationReasons: [],
  experienceCategories: [],
  spaceTypes: [],
  hotelsServicesTypes: [],
  guideCategories: [],
};

function conf(state = initialState, action: AnyAction): Conf {
  if (actions.fetchRoomCategoriesSuccess.match(action)) {
    return {
      ...state,
      roomCategories: action.payload.roomCategories,
    };
  }

  if (actions.fetchRoomFeaturesSuccess.match(action)) {
    return {
      ...state,
      roomFeatures: action.payload.roomFeatures,
    };
  }

  if (actions.fetchBedCategoriesSuccess.match(action)) {
    return {
      ...state,
      bedCategories: action.payload.bedCategories,
    };
  }

  if (actions.fetchHotelGroupsSuccess.match(action)) {
    return {
      ...state,
      hotelGroups: action.payload.hotelGroups,
    };
  }

  if (actions.fetchClubsSuccess.match(action)) {
    return {
      ...state,
      clubs: action.payload.clubs,
    };
  }

  if (actions.fetchCountriesSuccess.match(action)) {
    return {
      ...state,
      countries: action.payload.countries,
    };
  }

  if (actions.fetchCancellationReasonsSuccess.match(action)) {
    return {
      ...state,
      cancellationReasons: action.payload.cancellationReasons,
    };
  }

  if (actions.fetchExperienceCategoriesSuccess.match(action)) {
    return {
      ...state,
      experienceCategories: action.payload.experienceCategories,
    };
  }

  if (actions.fetchSpaceTypesSuccess.match(action)) {
    return {
      ...state,
      spaceTypes: action.payload.spaceTypes,
    };
  }

  if (actions.fetchHotelsServicesTypesSuccess.match(action)) {
    return {
      ...state,
      hotelsServicesTypes: action.payload.hotelsServicesTypes,
    };
  }

  if (actions.fetchGuideCategoriesSuccess.match(action)) {
    return {
      ...state,
      guideCategories: action.payload.guideCategories,
    };
  }

  return state;
}

export default conf;
