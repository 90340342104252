import { message } from 'antd';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import * as bookingActions from 'app/redux/actions/booking';
import {
  createBookingDetails,
  createBookingRefundParams,
} from 'app/redux/models/BookingDetails/BookingDetails';
import {
  del,
  get,
  post,
  requestGenerator,
  put as rput,
} from 'app/redux/requests';
import { getBookingCode } from 'app/redux/selectors/bookings';

export function* getBookingDetails(
  action: ReturnType<typeof bookingActions.getBookingDetails>
) {
  const { bookingCode } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => get(`admin/bookings/${bookingCode}`),
    (booking: any) => {
      const bookingDetails = createBookingDetails(booking);

      return put(
        bookingActions.getBookingDetailsSuccess({ booking: bookingDetails })
      );
    },
    (error: any) =>
      all([
        put(bookingActions.getBookingDetailsFailure({ error })),
        message.error(`Failed to fetch booking #${bookingCode}!`),
      ])
  );
}

export function* getRefundParams(
  action: ReturnType<typeof bookingActions.getBookingRefundParams>
) {
  const { bookingCode } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => get(`admin/bookings/${bookingCode}/refund`),
    (booking: any) => {
      const refundParams = createBookingRefundParams(booking);

      return put(
        bookingActions.getBookingRefundParamsSuccess({ params: refundParams })
      );
    },
    (error: any) =>
      all([
        put(bookingActions.getBookingRefundParamsFailure({ error })),
        message.error(
          `Failed to fetch booking refund params for #${bookingCode}!`
        ),
      ])
  );
}

export function* refundBooking(
  action: ReturnType<typeof bookingActions.refundBooking>
) {
  const { bookingCode, body } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => post(`admin/bookings/${bookingCode}/refund`, body),
    (booking: any) => {
      const bookingDetails = createBookingDetails(booking);

      return all([
        put(bookingActions.refundBookingSuccess({ booking: bookingDetails })),
        message.success(`Refunded booking #${bookingCode}!`),
      ]);
    },
    (error: any) =>
      all([
        put(bookingActions.refundBookingFailure({ error })),
        message.error(`Failed to refund booking #${bookingCode}!`),
      ])
  );
}

export function* editBookingReview(
  action: ReturnType<typeof bookingActions.editBookingReview>
) {
  const { bookingCode, feedbackId, review } = action.payload;

  yield requestGenerator(
    () => Promise.resolve(),
    () =>
      rput(`admin/bookings/${bookingCode}/feedback/${feedbackId}/comment`, {
        comment: review,
      }),
    () =>
      all([
        put(bookingActions.getBookingDetails({ bookingCode })),
        message.success('Feedback edited'),
      ]),
    () => call(message.error, 'Failed to edit feedback', 10),
    { expectJson: false }
  );
}

export function* editBookingPublished(
  action: ReturnType<typeof bookingActions.editBookingPublished>
) {
  const { bookingCode, feedbackId, published } = action.payload;

  yield requestGenerator(
    () => Promise.resolve(),
    () =>
      rput(`admin/bookings/${bookingCode}/feedback/${feedbackId}/published`, {
        published,
      }),
    () =>
      all([
        put(bookingActions.getBookingDetails({ bookingCode })),
        message.success(`Feedback ${published ? 'published' : 'unpublished'}`),
      ]),
    () =>
      call(
        message.error,
        `Failed to ${published ? 'publish' : 'unpublish'} feedback`,
        10
      ),
    { expectJson: false }
  );
}

export function* resendBookingConfirmation(
  action: ReturnType<typeof bookingActions.resendBookingConfirmation>
) {
  const { bookingCode } = action.payload;

  yield requestGenerator(
    () => post(`bookings/${bookingCode}/mails?customerOnly=true`),
    () =>
      all([
        put(bookingActions.resendBookingConfirmationSuccess()),
        message.success('Mail has been resent'),
      ]),
    (error: any) =>
      all([
        put(bookingActions.resendBookingConfirmationFailure({ error })),
        message.error('Failed to resend message', 10),
      ]),
    { expectJson: false }
  );
}

export function* resendBookingHotelConfirmation(
  action: ReturnType<typeof bookingActions.resendBookingConfirmation>
) {
  const { bookingCode } = action.payload;

  yield requestGenerator(
    () => post(`bookings/${bookingCode}/mails?hotelOnly=true`),
    () =>
      all([
        put(bookingActions.resendBookingConfirmationSuccess()),
        message.success('Mail has been resent'),
      ]),
    (error: any) =>
      all([
        put(bookingActions.resendBookingConfirmationFailure({ error })),
        message.error('Failed to resend message', 10),
      ]),
    { expectJson: false }
  );
}

export function* createVoucher(
  action: ReturnType<typeof bookingActions.createVoucher>
) {
  const { voucher } = action.payload;
  const bookingCode: ReturnType<typeof getBookingCode> = yield select(
    getBookingCode
  );

  yield requestGenerator(
    () => Promise.resolve(),
    () => post('vouchers', voucher),
    () =>
      all([
        put(bookingActions.getBookingDetails({ bookingCode })),
        message.success('Voucher created'),
      ]),
    () => call(message.error, 'Failed to create voucher', 10),
    { expectJson: false }
  );
}

export function* updateVoucher(
  action: ReturnType<typeof bookingActions.updateVoucher>
) {
  const { voucher } = action.payload;
  const bookingCode: ReturnType<typeof getBookingCode> = yield select(
    getBookingCode
  );

  yield requestGenerator(
    () => Promise.resolve(),
    () => rput(`vouchers/${voucher.code}`, voucher),
    () =>
      all([
        put(bookingActions.getBookingDetails({ bookingCode })),
        message.success('Voucher updated'),
      ]),
    () => call(message.error, 'Failed to update voucher', 10),
    { expectJson: false }
  );
}

export function* deleteVoucher(
  action: ReturnType<typeof bookingActions.deleteVoucher>
) {
  const { code } = action.payload;
  const bookingCode: ReturnType<typeof getBookingCode> = yield select(
    getBookingCode
  );

  yield requestGenerator(
    () => Promise.resolve(),
    () => del(`vouchers/${code}`),
    () =>
      all([
        put(bookingActions.getBookingDetails({ bookingCode })),
        message.success('Voucher deleted'),
      ]),
    () => call(message.error, 'Failed to delete voucher', 10),
    { expectJson: false }
  );
}

export function* updateBookingEmail(
  action: ReturnType<typeof bookingActions.updateBookingEmail>
) {
  const { email } = action.payload;
  const bookingCode: ReturnType<typeof getBookingCode> = yield select(
    getBookingCode
  );

  yield requestGenerator(
    () => Promise.resolve(),
    () => rput(`admin/bookings/${bookingCode}/email`, { email }),
    () =>
      all([
        put(bookingActions.getBookingDetails({ bookingCode })),
        message.success('Email updated'),
      ]),
    () => call(message.error, 'Failed to update email', 10)
  );
}

export function* generateReceipt(
  action: ReturnType<typeof bookingActions.generateReceipt>
) {
  const bookingCode: ReturnType<typeof getBookingCode> = yield select(
    getBookingCode
  );

  yield requestGenerator(
    () => Promise.resolve(),
    () => post(`bookings/${bookingCode}/generate-receipt`),
    () => call(message.success('New receipt successfully generated')),
    () => call(message.error, 'Failed to start receipt generation', 10)
  );
}

export default function* entitiesSagas() {
  yield all([
    takeEvery(bookingActions.GET_BOOKING_DETAILS, getBookingDetails),
    takeEvery(bookingActions.GET_BOOKING_REFUND_PARAMS, getRefundParams),
    takeEvery(bookingActions.REFUND_BOOKING, refundBooking),
    takeEvery(bookingActions.EDIT_BOOKING_REVIEW, editBookingReview),
    takeEvery(bookingActions.EDIT_BOOKING_PUBLISHED, editBookingPublished),
    takeEvery(
      bookingActions.RESEND_BOOKING_CONFIRMATION,
      resendBookingConfirmation
    ),
    takeEvery(
      bookingActions.RESEND_BOOKING_HOTEL_CONFIRMATION,
      resendBookingHotelConfirmation
    ),
    takeEvery(bookingActions.CREATE_VOUCHER, createVoucher),
    takeEvery(bookingActions.UPDATE_VOUCHER, updateVoucher),
    takeEvery(bookingActions.DELETE_VOUCHER, deleteVoucher),
    takeEvery(bookingActions.UPDATE_BOOKING_EMAIL, updateBookingEmail),
    takeEvery(bookingActions.GENERATE_RECEIPT, generateReceipt),
  ]);
}
