import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';

type UserActionMenuProps<T> = {
  entity: T;
  handleActionClick: (e: T) => void;
};

const ActionCellMenu = <T extends { code: string }>({
  entity,
  handleActionClick,
}: UserActionMenuProps<T>) => (
  <Dropdown
    placement="bottom"
    dropdownRender={() => (
      <Menu>
        <Menu.Item
          key="custom-action-0"
          onClick={(e) => handleActionClick(entity)}
        >
          View
        </Menu.Item>
      </Menu>
    )}
    trigger={['click']}
  >
    <EllipsisOutlined rotate={90} />
  </Dropdown>
);

export default ActionCellMenu;
