import { Select as AntSelect } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React from 'react';

export const { Option, OptGroup } = AntSelect;

export type Props = {
  relativePopupContainer?: (props: any) => HTMLElement;
  useDefaultPopupContainer?: boolean;
} & SelectProps<any>;

export const Select = ({
  relativePopupContainer,
  useDefaultPopupContainer,
  children,
  ...props
}: Props) => {
  const additionalProps = useDefaultPopupContainer
    ? {}
    : {
        getPopupContainer:
          relativePopupContainer || ((trigger) => trigger.parentNode),
      };

  return (
    <AntSelect {...additionalProps} {...props}>
      {children}
    </AntSelect>
  );
};
