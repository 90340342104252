import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Room } from 'app/components/commons/Room/Types';
import { RoomForm } from 'app/redux/models/RoomOpening/RoomOpening';
import { apiMutation, apiRequest } from 'app/utils/request/api';

const path = '/hotels/:id/rooms' as const;
const postPath = '/rooms/bulk' as const;

export function useRooms(id: number) {
  return useQuery({
    queryKey: [path, { id }],
    queryFn: () =>
      apiRequest({ path, params: { id: id.toString() } }) as Promise<Room[]>,
    staleTime: Infinity,
  });
}

export function useRoomSave(hotelId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: RoomForm) =>
      apiMutation(
        'POST',
        {
          path: postPath,
        },
        payload
      ) as Promise<[]>,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [path, { id: hotelId }],
      });
    },
  });
}
