type Customer = {
  firstName: string;
  lastName: string;
  email: string;
};

export enum BookingStatus {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Expired = 'expired',
  Aborted = 'aborted',
  Rejected = 'rejected',
}

export enum PSP {
  Ayden = 'ADYEN',
  Stripe = 'STRIPE',
}

export type Booking = {
  id: number;
  code: string;
  createdAt: string;
  checkin: string;
  hotelName: string;
  status: BookingStatus;
  psp: PSP;
  customer: Customer;
};

export function isValidStatus(
  maybeStatus: string
): maybeStatus is BookingStatus {
  return Object.values(BookingStatus).some((status) => status === maybeStatus);
}

export function isPSP(maybePSP: string): maybePSP is PSP {
  return Object.values(PSP).some((psp) => psp === maybePSP);
}
