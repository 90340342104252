import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { Option, Select } from 'app/components/fields/Select';

const LazySelect = ({ baseOptions, defaultValue, ...props }) => {
  const [options, setOptions] = useState(baseOptions || []);

  useEffect(() => {
    setOptions(baseOptions);
  }, [baseOptions]);

  const updateValue = useCallback(
    (input) => {
      if (
        input &&
        !_.some(baseOptions, (option) => _.includes(option, input))
      ) {
        setOptions([...baseOptions, input]);
      }
    },
    [baseOptions]
  );

  return (
    <Select
      showSearch
      optionFilterProp="children"
      defaultValue={
        defaultValue && defaultValue !== '' ? defaultValue : undefined
      }
      onSearch={updateValue}
      {...props}
    >
      {options.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );
};

LazySelect.propTypes = {
  baseOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultValue: PropTypes.string,
};

export default LazySelect;
