import React from 'react';

import './DashboardTitle.scss';

type DashboardTitleProps = {
  title: string;
};

export const DashboardTitle = ({ title }: DashboardTitleProps) => {
  return (
    <div className="dashboardTitleDivider">
      <div className="titleLine"></div>
      {title}
    </div>
  );
};
