import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import SortableHandle from 'app/components/layout/Sortable/SortableHandle';
import {
  openPreview,
  tryHideEntity,
  tryPublishEntity,
} from 'app/redux/actions/entities';
import { getUser } from 'app/redux/selectors/auth';

import 'styles/lists/actions-menu.scss';

// TODO: Merge with UploadItemMenu
const ActionsMenu = ({
  entity,
  customActions,
  sortable,
  onHide,
  onEdit,
  onPublish,
  onPreview,
  name,
  user,
}) => (
  <div className="actions-menu">
    {sortable && <SortableHandle />}
    <Dropdown
      overlay={
        <Menu>
          {entity.url && (
            <Menu.Item key="preview" onClick={onPreview(entity.url)}>
              Preview
            </Menu.Item>
          )}
          <Menu.Item key="edit" onClick={() => onEdit(name)}>
            Edit
          </Menu.Item>
          {entity.published !== undefined && (
            <Menu.Item
              key="publish"
              onClick={() => onPublish(name)}
              disabled={entity.hidden}
            >
              {entity.published ? 'Unpublish' : 'Publish'}
            </Menu.Item>
          )}
          {entity.hidden !== undefined && (
            <Menu.Item
              key="hide"
              onClick={() => onHide(name)}
              disabled={entity.published || user.role !== 'superadmin'}
            >
              {entity.hidden ? 'Show' : 'Hide'}
            </Menu.Item>
          )}
          {customActions &&
            customActions.map((action, idx) => (
              <Menu.Item key={`custom-action-${idx}`}>{action}</Menu.Item>
            ))}
        </Menu>
      }
      trigger={['click']}
    >
      <EllipsisOutlined rotate={90} />
    </Dropdown>
  </div>
);

ActionsMenu.propTypes = {
  entity: PropTypes.shape({
    published: PropTypes.bool,
    hidden: PropTypes.bool,
  }),
  customActions: PropTypes.arrayOf(PropTypes.node),
  sortable: PropTypes.bool,
  onHide: PropTypes.func,
  onEdit: PropTypes.func,
  onPublish: PropTypes.func,
  onPreview: PropTypes.func,
  name: PropTypes.string,
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const mapDispatchToProps = (dispatch, props) => {
  const { entity } = props;

  return {
    onHide: (name) => dispatch(tryHideEntity(name, entity.id)),
    onEdit: (name) => dispatch(push(`${name}/${entity.id}/edit`)),
    onPublish: (name) => dispatch(tryPublishEntity(name, entity.id)),
    onPreview: (url) => () => dispatch(openPreview(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsMenu);
