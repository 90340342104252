import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import React from 'react';

import './PaginationButton.scss';

type Props = {
  className?: string;
  leftButtonDisabled: boolean;
  rightButtonDisabled: boolean;
  onPrev: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onNext: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  pageNumber: number;
};

export const PaginationButton = ({
  className,
  leftButtonDisabled,
  rightButtonDisabled,
  onPrev,
  onNext,
  pageNumber,
}: Props) => {
  return (
    <div className={classNames('paginationButtonContainer', className)}>
      <Button disabled={leftButtonDisabled} onClick={onPrev}>
        <LeftOutlined />
      </Button>
      <Button>{pageNumber}</Button>
      <Button disabled={rightButtonDisabled} onClick={onNext}>
        <RightOutlined />
      </Button>
    </div>
  );
};
