import { parse } from 'date-fns';
import formatDate from 'date-fns/format';
import _ from 'lodash';
import React from 'react';

import {
  EmailGiftCardProperties,
  GiftCard,
} from 'app/redux/models/GiftCard/GiftCard';

import Line from '../Line/Line';

type Props = {
  giftCard: GiftCard & EmailGiftCardProperties;
};

const EmailProperties = ({ giftCard }: Props) => {
  const {
    giftCardType,
    color,
    emailSendDate,
    senderName,
    billingEmail,
    beneficiaryName,
    beneficiaryEmail,
    message,
  } = giftCard;

  return (
    <>
      <Line title="Type" value={_.capitalize(giftCardType)} />
      <Line title="Color" value={_.capitalize(color)} />
      <Line
        title="Send date"
        value={`${formatDate(
          parse(emailSendDate, 'yyyy-MM-dd', new Date()),
          'dd/MM/yyyy'
        )}`}
      />
      <Line title="Sender name" value={senderName} />
      <Line title="Sender email" value={billingEmail} />
      <Line title="Beneficiary name" value={beneficiaryName} />
      <Line title="Beneficiary email" value={beneficiaryEmail} />
      <Line title="Message" value={message} />
    </>
  );
};

export default EmailProperties;
