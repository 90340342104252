import { formatDistance, isBefore } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { tryFetchEntity } from 'app/redux/actions/entities';
import {
  tryDismissWarnings,
  tryFetchInventory,
  trySetHotelUnavailable,
} from 'app/redux/actions/openings';
import { entitiesSelector } from 'app/redux/selectors';
import { IHotelGroup, IInventory } from 'app/typings';

import InventoryPage from './InventoryPage/InventoryPage';

declare type SortOrder = 'descend' | 'ascend' | null;

const DEFAULT_GROUP_ID = 1;
const DEFAULT_CM_FILTER = [
  'NONE',
  'DEDGE',
  'SITEMINDER',
  'RESERVIT',
  'CUBILIS',
  'TRAVELICK',
  'ROOMCLOUD',
];
const CM_FILTER_VALUES = [
  {
    value: 'NONE',
    label: 'None',
  },
  {
    value: 'DEDGE',
    label: 'D-Edge',
  },
  {
    value: 'SITEMINDER',
    label: 'Siteminder',
  },
  {
    value: 'RESERVIT',
    label: 'Reservit',
  },
  {
    value: 'CUBILIS',
    label: 'Cubilis',
  },
  { value: 'TRAVELCLICK', label: 'Travelclick' },
  {
    value: 'ROOMCLOUD',
    label: 'Roomcloud',
  },
];
const LIMIT = 50;

export const Inventory = ({
  fetchHotelGroups,
  fetchInventory,
  hotelGroups,
  inventory,
  loading,
  dismissNoStock,
  dismissDeclined,
}: Props) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hotelFilter, setHotelFilter] = useState<string>();
  const [dateFilter, setDateFilter] = useState<[string, string]>();
  const [channelManagerFilter, setChannelManagerFilter] =
    useState(DEFAULT_CM_FILTER);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [lastRefresh, setLastRefresh] = useState(currentTime);
  const [groupId, setGroupId] = useState(DEFAULT_GROUP_ID);
  const [inventorySorting, setInventorySorting] = useState<SortOrder>();
  const [bookingSorting, setBookingSorting] = useState<SortOrder>();
  const [stockSorting, setStockSorting] = useState<SortOrder>();
  const [dateSorting, setDateSorting] = useState<SortOrder>('descend');

  const formatSort = useCallback((sort: any) => {
    if (sort === 'ascend') {
      return 'ASC';
    }

    if (sort === 'descend') {
      return 'DESC';
    }

    return '';
  }, []);

  const formatSorting = useCallback(() => {
    if (inventorySorting) {
      return {
        sortingColumn: 'stock',
        sortingOrder: formatSort(inventorySorting),
      };
    }

    if (bookingSorting) {
      return {
        sortingColumn: 'booked',
        sortingOrder: formatSort(bookingSorting),
      };
    }

    if (stockSorting) {
      return {
        sortingColumn: 'remaining',
        sortingOrder: formatSort(stockSorting),
      };
    }

    if (dateSorting) {
      return {
        sortingColumn: 'lastUpdatedAt',
        sortingOrder: formatSort(dateSorting),
      };
    }

    return null;
  }, [formatSort, inventorySorting, bookingSorting, stockSorting, dateSorting]);

  const makePayload = useCallback(
    () => ({
      hotelGroup: groupId,
      search: hotelFilter || undefined,
      channelManager: channelManagerFilter || undefined,
      beginDate: dateFilter?.[0],
      endDate: dateFilter?.[1],
      sorting: formatSorting() || undefined,
      offset: (pageNumber - 1) * LIMIT,
      limit: LIMIT,
    }),
    [
      formatSorting,
      pageNumber,
      channelManagerFilter,
      groupId,
      hotelFilter,
      dateFilter,
    ]
  );

  useEffect(() => {
    fetchHotelGroups();
    fetchInventory(makePayload());
  }, [fetchHotelGroups, fetchInventory, makePayload]);

  useEffect(() => {
    fetchInventory(makePayload());
  }, [fetchInventory, makePayload, pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [
    setPageNumber,
    hotelFilter,
    inventorySorting,
    bookingSorting,
    stockSorting,
    dateSorting,
    groupId,
  ]);

  const handleRefresh = useCallback(() => {
    fetchInventory(makePayload());
    setLastRefresh(new Date());
  }, [fetchInventory, makePayload]);

  useEffect(() => {
    const autoRerender = setInterval(() => setCurrentTime(new Date()), 30000);
    const autoRefreshData = setInterval(handleRefresh, 600000);

    return () => {
      clearInterval(autoRerender);
      clearInterval(autoRefreshData);
    };
  }, [handleRefresh]);

  const handleFilterDate = (interval: any) => {
    if (!interval || !interval[0] || !interval[1]) {
      setDateFilter(undefined);
    } else {
      setDateFilter([
        interval[0].format('yyyy-MM-DD'),
        interval[1].format('yyyy-MM-DD'),
      ]);
    }
  };

  const handleChannelManagerFilter = (statuses: any) => {
    if (!statuses.length) {
      setChannelManagerFilter(DEFAULT_CM_FILTER);
    } else {
      setChannelManagerFilter(statuses);
    }
  };

  const inventorySortingHandler = (sort: any) => {
    setInventorySorting(sort);
    setBookingSorting(null);
    setStockSorting(null);
    setDateSorting(null);
  };

  const bookingSortingHandler = (sort: any) => {
    setInventorySorting(null);
    setBookingSorting(sort);
    setStockSorting(null);
    setDateSorting(null);
  };

  const stockSortingHandler = (sort: any) => {
    setInventorySorting(null);
    setBookingSorting(null);
    setStockSorting(sort);
    setDateSorting(null);
  };

  const dateSortingHandler = (sort: any) => {
    setInventorySorting(null);
    setBookingSorting(null);
    setStockSorting(null);
    setDateSorting(sort);
  };

  const lastRefreshFormatted = formatDistance(lastRefresh, currentTime);

  const formattedInventory = useMemo(
    () =>
      inventory
        ? inventory.map((row) => {
            const snoozedStock =
              !!row.snoozeNotifications &&
              isBefore(new Date(), new Date(row.snoozeNotifications));
            const disabled =
              snoozedStock && (row.stock === 0 || row.remaining === 0);

            return { ...row, disabled };
          })
        : [],
    [inventory]
  );

  return (
    <InventoryPage
      setPageNumber={setPageNumber}
      pageNumber={pageNumber}
      limit={LIMIT}
      hotelFilter={hotelFilter}
      setHotelFilter={setHotelFilter}
      handleFilterDate={handleFilterDate}
      channelManagerFilterValues={CM_FILTER_VALUES}
      channelManagerFilter={channelManagerFilter}
      handleChannelManagerFilter={handleChannelManagerFilter}
      lastRefreshFormatted={lastRefreshFormatted}
      handleRefresh={handleRefresh}
      dismissNoStock={dismissNoStock}
      dismissDeclined={dismissDeclined}
      hotelGroups={hotelGroups}
      groupId={groupId}
      setGroupId={setGroupId}
      inventory={formattedInventory}
      loading={loading}
      inventorySortingHandler={inventorySortingHandler}
      bookingSortingHandler={bookingSortingHandler}
      stockSortingHandler={stockSortingHandler}
      dateSortingHandler={dateSortingHandler}
      inventorySorting={inventorySorting}
      bookingSorting={bookingSorting}
      stockSorting={stockSorting}
      dateSorting={dateSorting}
    />
  );
};

type Props = {
  hotelGroups: Array<IHotelGroup>;
  inventory: Array<IInventory>;
  loading: boolean;
  fetchHotelGroups: () => void;
  fetchInventory: (args: {
    hotelGroup: number;
    search?: string;
    channelManager?: string[];
    beginDate?: string;
    endDate?: string;
    sorting?: { sortingColumn: string; sortingOrder: string };
    offset?: number;
    limit?: number;
  }) => void;
  dismissNoStock: (hotelId: number) => void;
  dismissDeclined: (openingsIds: Array<number>) => void;
};

const mapStateToProps = (state: any) => ({
  hotelGroups: entitiesSelector('hotelGroups')(state),
  inventory: state.openings.inventory,
  loading: state.openings.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchHotelGroups: () => dispatch(tryFetchEntity('hotelGroups')),
  fetchInventory: _.debounce(
    (payload) => dispatch(tryFetchInventory(payload)),
    800
  ),
  dismissNoStock: (hotelId: any) => dispatch(trySetHotelUnavailable(hotelId)),
  dismissDeclined: (openingsIds: any) =>
    dispatch(tryDismissWarnings(openingsIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
