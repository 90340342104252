import { Input } from 'antd';
import React from 'react';

import './TextArea.scss';

type Props = {
  editing: boolean;
  value: string;
  label: string;
  id: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  rows?: number;
};

const TextArea = ({ editing, value, label, id, onChange, rows = 2 }: Props) => {
  const compToRender = editing ? (
    <Input.TextArea id={id} onChange={onChange} value={value} rows={rows} />
  ) : (
    <div>{value}</div>
  );

  return (
    <div className="input-textarea">
      <label htmlFor={id}>{label}</label>
      {compToRender}
    </div>
  );
};

export default TextArea;
