import { Form, Layout } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import Delete from 'app/components/commons/ExtraButtons/Delete/Delete';
import Features from 'app/components/commons/Features/Features';
import BasicInfo from 'app/components/commons/Room/BasicInfo/BasicInfo';
import Beds from 'app/components/commons/Room/Beds/Beds';
import Pictures from 'app/components/commons/Room/Pictures/Pictures';
import { Room } from 'app/components/commons/Room/Types';
import UploadList from 'app/components/commons/Uploader/UploadList/UploadList';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { tryFetchHotel } from 'app/redux/actions/hotels';
import {
  tryDeleteRoom,
  tryFetchRoom,
  trySaveRoom,
} from 'app/redux/actions/rooms';
import {
  selectRoomCategories,
  selectRoomFeatures,
} from 'app/redux/selectors/conf';
import { getHotelsHotel } from 'app/redux/selectors/hotels';
import { getRoomsRoom } from 'app/redux/selectors/rooms';

import './RoomDetail.scss';

const { Content } = Layout;

export type FormValues = Partial<Room>;

export const RoomDetail = () => {
  const { id: roomIdParam } = useParams<{ id: string | undefined }>();
  const history = useHistory();

  const roomFeatures = useSelector(selectRoomFeatures);
  const roomCategories = useSelector(selectRoomCategories);
  const fetchedRoom = useSelector(getRoomsRoom);
  const fetchedHotel = useSelector(getHotelsHotel);

  const hotelId = fetchedRoom?.hotelId;
  const hotel = fetchedHotel?.id === hotelId ? fetchedHotel : null;

  const roomId = roomIdParam ? parseInt(roomIdParam, 10) : undefined;

  const [mode, setMode] = useState<'view' | 'edit'>(roomId ? 'view' : 'edit');
  const [form] = Form.useForm<FormValues>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (roomId) {
      dispatch(tryFetchRoom(roomId));
    }
  }, [dispatch, roomId]);

  useEffect(() => {
    if (hotelId) {
      dispatch(tryFetchHotel({ hotelId }));
    }
  }, [dispatch, hotelId]);

  const basicFeatureIds = useMemo(
    () =>
      roomFeatures
        .filter((feature) => feature.basic)
        .map((feature) => feature.id),
    [roomFeatures]
  );

  const categories = useMemo(
    () =>
      roomCategories.reduce(
        (acc, val) => ({
          ...acc,
          [val.id]: val.name,
        }),
        {} as { [key: number]: string }
      ),
    [roomCategories]
  );

  const featuresByCategory = useMemo(() => {
    return roomFeatures.reduce((acc: any, feature) => {
      if (!acc[feature.category]) {
        acc[feature.category] = [];
      }

      acc[feature.category].push(feature);

      return acc;
    }, {});
  }, [roomFeatures]);

  const onCancel = useCallback(() => {
    if (roomId) {
      setMode('view');
    } else {
      history.push('/rooms');
    }
  }, [roomId, history]);

  const room: Partial<Room> | undefined = useMemo(() => {
    // Create form
    if (!roomId) {
      return {};
    }

    // Room stored in redux doesn't match the one we want to edit
    // We don't want to render the form
    if (fetchedRoom && fetchedRoom.id !== roomId) {
      return undefined;
    }

    // Edit form
    return fetchedRoom;
  }, [fetchedRoom, roomId]);

  if (!room || !roomCategories) {
    return null;
  }

  const formattedValues: FormValues = {
    ...room,
    beds: room.beds
      // We want to display the included bed first.
      ?.sort((bedA, bedB) => {
        if (bedA.included && bedB.included && bedA.priority && bedB.priority) {
          return bedA.priority - bedB.priority;
        }

        if (bedA.included) {
          return -1;
        }

        if (bedB.included) {
          return 1;
        }

        return 0;
      })
      .map((bed) => ({
        ...bed,
        included: bed.included,
      })) || [{}],
    featureIds: (room.id ? room.featureIds : basicFeatureIds) ?? undefined,
    hasPRM: room.hasPRM ?? false,
    pictures: room.pictures || [],
  };

  const onFinish = (values: FormValues) => {
    const formatted = {
      ...values,
      beds: values.beds?.map((bed: any) => ({
        ...bed,
        included: bed.included !== false,
        price: bed.price || 0,
      })),
      hasPRM: values.hasPRM !== false,
      id: formattedValues.id,
    };

    dispatch(trySaveRoom(formatted));
    setMode('view');
  };

  const renderDeleteButton = () => {
    if (!roomId) {
      return null;
    }

    return (
      <Delete
        entityDescription={`Room #${roomId}`}
        onDelete={() => dispatch(tryDeleteRoom(roomId))}
      />
    );
  };

  const renderTitle = () => {
    if (!formattedValues.id) {
      return 'New room';
    }

    if (formattedValues.name) {
      return formattedValues.name;
    }

    if (formattedValues.categoryId) {
      return categories[formattedValues.categoryId];
    }

    return '';
  };

  return (
    <Layout className="room-detail">
      <FormLegacyRenderProp
        form={form}
        onFinish={onFinish}
        initialValues={formattedValues}
      >
        {(values, { resetFields, isFieldsTouched, getFieldValue, submit }) => (
          <SDetailLayout
            title={renderTitle()}
            mode={mode}
            onEdit={() => setMode('edit')}
            onSave={submit}
            onCancel={onCancel}
            reset={resetFields}
            headerExtra={renderDeleteButton()}
            isDirty={isFieldsTouched()}
            isValid={form
              .getFieldsError()
              .every((item) => item.errors.length > 0)}
          >
            <Content className="room-detail__content">
              <CurrencyWrapper hotel={hotel}>
                <SCard title="Basic information">
                  <BasicInfo
                    hotelSelect={roomId ? 'disabled' : 'enabled'}
                    mode={mode}
                    values={values}
                  />
                </SCard>
                <SCard title="Beds">
                  <Form.List name="beds">
                    {(fields, { add: addBed, remove: removeBed }) => (
                      <Beds
                        fields={fields}
                        add={addBed}
                        remove={removeBed}
                        mode={mode}
                        values={values}
                      />
                    )}
                  </Form.List>
                </SCard>
                <SCard title="Features">
                  <Form.Item name="featureIds">
                    <Features
                      featuresByCategory={featuresByCategory}
                      mode={mode}
                    />
                  </Form.Item>
                </SCard>
                <SCard
                  title="Photos"
                  subtitle="In edit mode, you can drag and drop photos to rearrange them. The first one will be the room cover"
                  className="room-detail__last-card"
                >
                  <Form.Item name="pictures">
                    {mode === 'edit' && values.hotelId ? (
                      <Pictures
                        roomCategoryId={room.categoryId}
                        categories={categories}
                        hotelId={values?.hotelId}
                        picturesCategories={[
                          { name: 'Official', type: 'official' },
                          { name: 'Sourcing', type: 'sourcing' },
                        ]}
                        kind="sourcing"
                        draggable
                        previewable
                        removable
                      />
                    ) : (
                      <UploadList
                        pictures={getFieldValue('pictures')}
                        uploadingItems={{}}
                        previewable
                      />
                    )}
                  </Form.Item>
                </SCard>
              </CurrencyWrapper>
            </Content>
          </SDetailLayout>
        )}
      </FormLegacyRenderProp>
    </Layout>
  );
};

export default RoomDetail;
