import type { User } from 'app/redux/models/User/User';

export enum UserRole {
  SUPER_ADMIN = 'superadmin',
  ADMIN = 'admin',
  USER = 'user',
}

export const isUserSuperAdmin = (user: User | undefined) => {
  return user?.role === UserRole.SUPER_ADMIN;
};
