import { message } from 'antd';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { all, put, takeEvery } from 'redux-saga/effects';

import * as experiencesActions from 'app/redux/actions/experiences';
import { del, get, patch, post, requestGenerator } from 'app/redux/requests';

export function* tryFetchHotelExperiences({ hotelId, includeStaycationExp }) {
  yield requestGenerator(
    () => put(experiencesActions.startLoading()),
    () =>
      get(
        `hotels/${hotelId}/experiences?includeStaycationExp=${includeStaycationExp}`
      ),
    (experiences) =>
      all([
        put(experiencesActions.fetchHotelExperiencesSuccess(experiences)),
        put(experiencesActions.stopLoading()),
      ]),
    () =>
      all([
        put(experiencesActions.stopLoading()),
        message.error('Error fetching Hotel Experiences'),
      ])
  );
}

export function* tryFetchExperiencesBrands({ search, limit = 50 }) {
  yield requestGenerator(
    () => Promise.resolve,
    () => {
      const stringifiedParams = queryString.stringify({
        search,
        limit,
      });

      return get(`experiences/brands?${stringifiedParams}`);
    },
    (experiencesBrands) =>
      put(experiencesActions.fetchExperiencesBrandsSuccess(experiencesBrands)),
    () => message.error('Error fetching Experiences Brands')
  );
}

export function* tryFetchExperience({ experienceId }) {
  yield requestGenerator(
    () => put(experiencesActions.startLoading()),
    () => get(`experiences/${experienceId}`),
    (experience) =>
      all([
        put(experiencesActions.fetchExperienceSuccess(experience)),
        put(experiencesActions.stopLoading()),
      ]),
    () =>
      all([
        put(experiencesActions.stopLoading()),
        message.error('Error fetching Experience'),
      ])
  );
}

export function* tryFetchHotelExperience({ experienceId }) {
  yield requestGenerator(
    () => put(experiencesActions.startLoading()),
    () => get(`experiences/${experienceId}`),
    (experience) =>
      all([
        put(experiencesActions.fetchExperienceSuccess(experience)),
        put(experiencesActions.fetchHotelExperienceSuccess(experience)),
        put(experiencesActions.stopLoading()),
      ]),
    () =>
      all([
        put(experiencesActions.stopLoading()),
        message.error('Error fetching Experience'),
      ])
  );
}

export function* trySaveExperience({ experience }) {
  yield requestGenerator(
    () => Promise.resolve,
    () => post('experiences', experience),
    (experienceId) => put(experiencesActions.tryFetchExperience(experienceId)),
    (error) => {
      message.error('Error saving Experience', error);
    }
  );
}

export function* trySaveHotelExperience({ experience }) {
  yield requestGenerator(
    () => Promise.resolve,
    () => post('experiences', experience),
    (experienceId) =>
      put(experiencesActions.tryFetchHotelExperience(experienceId)),
    (error) => {
      message.error('Error saving Experience', error);
    }
  );
}

export function* tryDeleteExperience({ experienceId }) {
  yield requestGenerator(
    () => Promise.resolve,
    () => del(`experiences/${experienceId}`),
    () =>
      all([
        message.success(`Experience ${experienceId} deleted`),
        put(push('/experiences')),
      ]),
    () => message.error('Not possible: this experience is used somewhere else')
  );
}

export function* tryFetchExperiences({
  search,
  limit,
  sorting,
  offset,
  isPublished,
  isStaycationExp,
}) {
  yield requestGenerator(
    () => put(experiencesActions.startLoading()),
    () => {
      const stringifiedParams = queryString.stringify({
        search,
        limit,
        sorting,
        offset,
        isPublished,
        isStaycationExp,
      });

      return get(`experiences?${stringifiedParams}`);
    },
    (result) =>
      all([
        put(experiencesActions.fetchExperiencesSuccess(result)),
        put(experiencesActions.stopLoading()),
      ]),
    (error) => {
      message.error('Error fetching Experiences', error);

      return put(experiencesActions.stopLoading());
    }
  );
}

export function* tryPublishExperience({ experienceId }) {
  yield requestGenerator(
    () => put(experiencesActions.startLoading()),
    () => patch(`experiences/${experienceId}/publish`),
    (result) =>
      all([
        put(experiencesActions.publishExperienceSuccess(experienceId, result)),
        put(experiencesActions.stopLoading()),
      ]),
    (error) => {
      message.error('Error publishing Experiences', error);

      return put(experiencesActions.stopLoading());
    }
  );
}

export default function* experiencesSaga() {
  yield all([
    takeEvery(
      experiencesActions.EXPERIENCES_TRY_FETCH_HOTEL_EXPERIENCES,
      tryFetchHotelExperiences
    ),
    takeEvery(
      experiencesActions.EXPERIENCES_TRY_FETCH_EXPERIENCES_BRANDS,
      tryFetchExperiencesBrands
    ),
    takeEvery(
      experiencesActions.EXPERIENCES_TRY_FETCH_EXPERIENCE,
      tryFetchExperience
    ),
    takeEvery(
      experiencesActions.EXPERIENCES_TRY_SAVE_EXPERIENCE,
      trySaveExperience
    ),
    takeEvery(
      experiencesActions.EXPERIENCES_TRY_FETCH_HOTEL_EXPERIENCE,
      tryFetchHotelExperience
    ),
    takeEvery(
      experiencesActions.EXPERIENCES_TRY_SAVE_HOTEL_EXPERIENCE,
      trySaveHotelExperience
    ),
    takeEvery(
      experiencesActions.EXPERIENCES_TRY_DELETE_EXPERIENCE,
      tryDeleteExperience
    ),
    takeEvery(
      experiencesActions.EXPERIENCES_TRY_FETCH_EXPERIENCES,
      tryFetchExperiences
    ),
    takeEvery(
      experiencesActions.EXPERIENCES_TRY_PUBLISH_EXPERIENCE,
      tryPublishExperience
    ),
  ]);
}
