import type { BookingStatus } from 'app/redux/models/Booking/Booking';
import { User } from 'app/redux/models/User/User';

export enum GiftCardType {
  Email = 'email',
  Print = 'print',
  Physical = 'physical',
  Voucher = 'voucher',
  Incentive = 'incentive',
  Internal = 'internal',
}

export type VoucherGiftCardProperties = {
  giftCardType: 'voucher';
};

export type IncentiveGiftCardProperties = {
  giftCardType: 'incentive';
};

export type InternalGiftCardProperties = {
  giftCardType: 'internal';
};

export type EmailGiftCardProperties = {
  giftCardType: 'email';
  color: string;
  message: string;
  beneficiaryEmail: string;
  billingEmail: string;
  emailSendDate: string;
};

export type PrintGiftCardProperties = {
  giftCardType: 'print';
  color: string;
  message: string;
};

export type PhysicalGiftCardProperties = {
  giftCardType: 'physical';
  shippingFirstName: string;
  shippingLastName: string;
  shippingStreet1: string;
  shippingStreet2: string;
  shippingZipCode: string;
  shippingCity: string;
  shippingCountryId: number;
};

export enum GiftCardStatus {
  Active = 'active',
  Empty = 'empty',
  Expired = 'expired',
  Rejected = 'rejected',
  Pending = 'pending',
  Cancelled = 'cancelled',
}

export type Refund = {
  amountCb: number;
  reason: number;
  comment?: string | undefined;
  createdAt: string;
  userName: string;
};

export type GiftCard = {
  id: number;
  code: string;
  createdAt: string;
  startDate?: string;
  expirationDate?: string;
  status: GiftCardStatus;
  initialValue: number;
  remainingValue?: number;
  maxUse?: number;
  minAmount?: number;
  onlyFirstBooking?: boolean;
  senderName: string;
  beneficiaryName: string;
  totalAmount: number;
  cardInfo?: {
    last4: string;
  };
  billingFirstName?: string;
  billingEmail?: string;
  currency: string;
} & (
  | EmailGiftCardProperties
  | PrintGiftCardProperties
  | PhysicalGiftCardProperties
  | VoucherGiftCardProperties
  | IncentiveGiftCardProperties
  | InternalGiftCardProperties
);

export type GiftCardBooking = {
  code: string;
  date: string;
  couponAmount: number;
  status: BookingStatus;
};

export type GiftCardDetails = {
  giftCard: GiftCard;
  giftCardBookings: GiftCardBooking[];
  staycations: number[];
  giftCards: number[];
  user: User;
  refunds: Array<Refund>;
};

export type GiftCardEditForm = {
  startDate?: string;
  expirationDate: string;
  remainingValue: number;
  maxUse?: number;
  senderName: string;
  beneficiaryName: string;
  color?: string;
  beneficiaryEmail?: string;
  billingEmail?: string;
  emailSendDate?: string;
  message?: string;
  shippingFirstName?: string;
  shippingLastName?: string;
  shippingStreet1?: string;
  shippingStreet2?: string;
  shippingZipCode?: string;
  shippingCity?: string;
  shippingCountry?: string;
};

export type RefundForm = {
  amountCb?: number | undefined;
  reason?: number | undefined;
  comment?: string | undefined;
  deactivateGiftCard: boolean;
};

export function isValidStatus(
  maybeStatus: string
): maybeStatus is GiftCardStatus {
  return Object.values(GiftCardStatus).some((status) => status === maybeStatus);
}

export function isValidType(maybeType: string): maybeType is GiftCardType {
  return Object.values(GiftCardType).some((status) => status === maybeType);
}
