import { Form, Input } from 'antd';
import React, { FC } from 'react';

import { SCard } from 'app/components/StaycationUI';

import './Identity.scss';

const RULES = [{ required: true, message: 'Required' }];

const Identity: FC<Props> = ({ mode, values }) => (
  <SCard title="Identity" className="identity">
    <div className="container">
      <div className="input-row">
        <Form.Item
          label="Package name"
          name={['identity', 'name']}
          rules={RULES}
        >
          {mode === 'edit' || !values ? (
            <Input />
          ) : (
            <div className="view-text">{values?.name}</div>
          )}
        </Form.Item>
      </div>
      <div className="input-row">
        <Form.Item
          label="Introduction"
          name={['identity', 'highlight']}
          rules={RULES}
        >
          {mode === 'edit' || !values ? (
            <Input.TextArea rows={4} />
          ) : (
            <div className="view-text-area">{values?.highlight}</div>
          )}
        </Form.Item>
      </div>
      <div className="input-row">
        <Form.Item
          label="Description"
          name={['identity', 'description']}
          rules={RULES}
        >
          {mode === 'edit' || !values ? (
            <Input.TextArea rows={4} />
          ) : (
            <div className="view-text-area">{values?.description}</div>
          )}
        </Form.Item>
      </div>
    </div>
  </SCard>
);

type Props = {
  mode: 'view' | 'edit';
  values?: {
    name: string;
    highlight: string;
    description: string;
  };
};

export default Identity;
