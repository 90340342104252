import {
  EllipsisOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Layout,
  Menu,
  Table,
  Tag,
} from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';

import PopoverCheckbox from 'app/components/commons/PopoverCheckbox/PopoverCheckbox';
import ListPage from 'app/components/lists/ListPage';
import { IHotelGroup, IInventory } from 'app/typings';
import { ChannelManagerDataSource } from 'app/utils/channelManagers/channelManagers';
import { RateModesDataSource } from 'app/utils/channelManagers/rateModes';

import './InventoryPage.scss';

declare type SortOrder = 'descend' | 'ascend' | null;

const { Content } = Layout;
const { Column } = Table;
const { RangePicker } = DatePicker;

const InventoryPage = ({
  setPageNumber,
  pageNumber,
  limit,
  hotelFilter,
  setHotelFilter,
  handleFilterDate,
  channelManagerFilterValues,
  channelManagerFilter,
  handleChannelManagerFilter,
  lastRefreshFormatted,
  handleRefresh,
  dismissNoStock,
  dismissDeclined,
  hotelGroups,
  groupId,
  setGroupId,
  inventory,
  loading,
  inventorySortingHandler,
  bookingSortingHandler,
  stockSortingHandler,
  dateSortingHandler,
  inventorySorting,
  bookingSorting,
  stockSorting,
  dateSorting,
}: Props) => {
  const goToNewExtranetInventory = (hotelId: any) =>
    window.open(`/extranet/inventory?hotelId=${hotelId}`, '_blank');

  const header = () => (
    <div className="left-right-header">
      <div className="left-right-header__left">
        <Input
          placeholder="Search hotel..."
          value={hotelFilter}
          onChange={(event) => setHotelFilter(event.target.value)}
          suffix={<SearchOutlined className="certain-category-icon" />}
          className="left-right-header__search"
          size="large"
        />
        <RangePicker
          size="large"
          onChange={handleFilterDate}
          disabledDate={(d) => !d.isSameOrAfter(new Date(), 'day')}
          format="ddd DD MMM"
          ranges={{
            Today: [moment(), moment()],
            'This Week': [
              moment().startOf('isoWeek'),
              moment().endOf('isoWeek'),
            ],
          }}
        />
      </div>
      <div className="left-right-header__right">
        <div className="left-right-header__refresh">
          <div className="left-right-header__last-refresh">
            Last refresh
            <br />
            {lastRefreshFormatted}
          </div>
          <SyncOutlined onClick={handleRefresh} />
        </div>
      </div>
    </div>
  );

  const alertCell = ({ stock, declinedOpenings }: any) => {
    if (!stock) {
      return <Tag color="#F52F39">Get stock</Tag>;
    }

    if (declinedOpenings.length) {
      return <Tag color="#FA8C16">{`${declinedOpenings.length} declined`}</Tag>;
    }

    return null;
  };

  const alertActions = ({ hotelId, stock, declinedOpenings }: any) => {
    if (!stock) {
      return (
        <Menu.Item
          key="no-stock"
          onClick={() => {
            dismissNoStock(hotelId);
            handleRefresh();
          }}
        >
          No Stock
        </Menu.Item>
      );
    }

    if (declinedOpenings.length) {
      return (
        <Menu.Item
          key="dismiss"
          onClick={() => {
            dismissDeclined(declinedOpenings);
            handleRefresh();
          }}
        >
          Dismiss
        </Menu.Item>
      );
    }

    return null;
  };

  const actionMenu = (inv: any) => (
    <div className="actions-menu">
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              key="open"
              onClick={() => goToNewExtranetInventory(inv.hotelId)}
            >
              Open
            </Menu.Item>
            {alertActions(inv)}
          </Menu>
        }
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );

  const GroupMenu = (
    <Menu
      mode="horizontal"
      onClick={(group) => setGroupId(parseInt(group.key, 10))}
      selectedKeys={[groupId.toString()]}
    >
      {hotelGroups &&
        hotelGroups.map((group) => (
          <Menu.Item key={group.id}>{group.name}</Menu.Item>
        ))}
    </Menu>
  );

  const formatDate = (date: any) =>
    date && moment.utc(date).local().format('DD/MM/YYYY à HH:mm');

  const pagination = (className: any) => (
    <div className={className}>
      <Button
        disabled={pageNumber === 1}
        onClick={() => setPageNumber(pageNumber - 1)}
      >
        <LeftOutlined />
      </Button>
      <Button>{pageNumber}</Button>
      <Button
        disabled={(inventory?.length || 0) < limit}
        onClick={() => setPageNumber(pageNumber + 1)}
      >
        <RightOutlined />
      </Button>
    </div>
  );

  const renderChannelManagerLabel = (value: any) => {
    const cm = ChannelManagerDataSource.find((ds) => ds.id === value);

    if (cm) {
      return cm.name;
    }

    return 'None';
  };

  const renderRateModeLabel = (value: any) => {
    const rm = RateModesDataSource.find((ds) => ds.id === value);

    if (rm) {
      return rm.name;
    }

    return 'None';
  };

  return (
    <div className="inventory">
      <ListPage title="Inventory" menu={GroupMenu}>
        <Layout className="foo">
          <Content className="page__content">
            <div className="foo">
              <Table
                title={header}
                loading={loading}
                onRow={({ hotelId }) => ({
                  onClick: () => goToNewExtranetInventory(hotelId),
                })}
                dataSource={inventory}
                pagination={false}
                rowKey="hotelId"
                rowClassName={({ disabled }) =>
                  disabled ? 'inventory__row--disabled' : ''
                }
                onChange={(__, ___, sorter) => {
                  // @ts-ignore
                  const { field, order } = sorter;

                  if (field === 'stock') {
                    inventorySortingHandler(order);
                  }

                  if (field === 'booked') {
                    bookingSortingHandler(order);
                  }

                  if (field === 'remaining') {
                    stockSortingHandler(order);
                  }

                  if (field === 'lastUpdatedAt') {
                    dateSortingHandler(order);
                  }
                }}
              >
                <Column
                  title={
                    <div style={{ paddingLeft: 16, minWidth: 350 }}>Hotel</div>
                  }
                  dataIndex="hotelName"
                  render={(hotelName) => (
                    <div style={{ fontWeight: 500, textOverflow: 'ellipsis' }}>
                      {hotelName}
                    </div>
                  )}
                />
                <Column
                  title="Inventory"
                  dataIndex="stock"
                  sorter
                  sortOrder={inventorySorting}
                />
                <Column
                  title="Bookings"
                  dataIndex="booked"
                  sorter
                  sortOrder={bookingSorting}
                />
                <Column
                  title="Stock"
                  dataIndex="remaining"
                  sorter
                  sortOrder={stockSorting}
                />
                <Column
                  title={
                    <PopoverCheckbox
                      title="Channel Manager"
                      // @ts-ignore
                      onChange={handleChannelManagerFilter}
                      items={channelManagerFilterValues}
                      value={channelManagerFilter}
                    />
                  }
                  dataIndex="channelManager"
                  className="sort-column"
                  render={renderChannelManagerLabel}
                />
                <Column
                  title="Connected rates"
                  dataIndex="rateMode"
                  render={renderRateModeLabel}
                />
                <Column
                  title="Last modified"
                  dataIndex="lastUpdatedAt"
                  sorter
                  sortOrder={dateSorting}
                  render={formatDate}
                />
                <Column
                  title={<div style={{ paddingLeft: 16 }}>Alerts</div>}
                  render={alertCell}
                />
                <Column
                  render={actionMenu}
                  onCell={() => ({ onClick: (e) => e.stopPropagation() })}
                  align="center"
                />
              </Table>
              {pagination('footer')}
            </div>
          </Content>
        </Layout>
      </ListPage>
    </div>
  );
};

type Props = {
  setPageNumber: (pageNumber: number) => void;
  pageNumber: number;
  limit: number;
  hotelFilter: string | undefined;
  setHotelFilter: (newFilter: string) => void;
  handleFilterDate: (newFilter: [Moment | null, Moment | null] | null) => void;
  channelManagerFilterValues: Array<{ value: string; label: string }>;
  channelManagerFilter: Array<string>;
  handleChannelManagerFilter: (newFilter: Array<string>) => void;
  lastRefreshFormatted: string;
  handleRefresh: () => void;
  dismissNoStock: (hotelId: number) => void;
  dismissDeclined: (openingsIds: Array<number>) => void;
  hotelGroups: Array<IHotelGroup>;
  groupId: number;
  setGroupId: (newValue: number) => void;
  inventory: IInventory[];
  loading: boolean;
  inventorySortingHandler: (sort: SortOrder) => void;
  bookingSortingHandler: (sort: SortOrder) => void;
  stockSortingHandler: (sort: SortOrder) => void;
  dateSortingHandler: (sort: SortOrder) => void;
  inventorySorting?: SortOrder;
  bookingSorting?: SortOrder;
  stockSorting?: SortOrder;
  dateSorting?: SortOrder;
};

export default InventoryPage;
