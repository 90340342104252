import { Layout } from 'antd';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import SideMenuAdmin from 'app/components/layout/SideMenuAdmin';
import Bills from 'app/components/pages/Bills/Bills';
import BookingsList from 'app/components/pages/Bookings';
import BookingDetails from 'app/components/pages/Bookings/details/index.tsx';
import CollectionDetail from 'app/components/pages/Collections/CollectionDetail/CollectionDetail';
import CollectionList from 'app/components/pages/Collections/Collections';
import CommercialGestures from 'app/components/pages/CommercialGestures/CommercialGestures';
import CustomTags from 'app/components/pages/CustomTags/CustomTags';
import { EditoEventDetail } from 'app/components/pages/Edito/Event/Detail/EditoEventDetail';
import { EditoEventListPage } from 'app/components/pages/Edito/Event/List/EditoEventListPage';
import Experience from 'app/components/pages/Experiences/Experience/Experience';
import Experiences from 'app/components/pages/Experiences/Experiences';
import Galleries from 'app/components/pages/Galleries/Galleries';
import Gallery from 'app/components/pages/Galleries/GalleryDetail/GalleryDetail';
import GiftCardsList from 'app/components/pages/GiftCards';
import GiftCardDetails from 'app/components/pages/GiftCards/details';
import Guide from 'app/components/pages/Guides/Guide/Guide';
import GuidesList from 'app/components/pages/Guides/Guides';
import Home from 'app/components/pages/Home';
import HotelsList from 'app/components/pages/Hotels';
import HotelEdit from 'app/components/pages/Hotels/Form/Edit';
import HotelNew from 'app/components/pages/Hotels/NewHotel/NewHotel';
import Inventory from 'app/components/pages/Inventory';
import MerchPackagesList from 'app/components/pages/MerchPackages';
import MerchPackageForm from 'app/components/pages/MerchPackages/Form';
import PackagesList from 'app/components/pages/Packages';
import PackageDetail from 'app/components/pages/Packages/PackageDetail/PackageDetail';
import { QuickFilterDetail } from 'app/components/pages/QuickFilters/QuickFilterDetail/QuickFilterDetail';
import { QuickFiltersListPage } from 'app/components/pages/QuickFilters/QuickFilterListPage/QuickFiltersListPage';
import RoomDetail from 'app/components/pages/Rooms/RoomDetail/RoomDetail';
import RoomsList from 'app/components/pages/Rooms/RoomsRange/Rooms';
import SalesList from 'app/components/pages/Sales';
import Slider from 'app/components/pages/Slider';
import UsersList from 'app/components/pages/Users';
import UserDetails from 'app/components/pages/Users/details';
import VoucherForm from 'app/components/pages/Vouchers/Vouchers';
import { initApp } from 'app/redux/actions/navigation';

const AdminApp = ({ user, appUrl, appReady, init }) => {
  useEffect(() => {
    init();
  }, [init]);

  if (!user || !appReady) {
    return null;
  }

  if (user && user.role !== 'admin' && user.role !== 'superadmin') {
    // TODO: see how we can handle this better
    return window.location.replace(appUrl);
  }

  return (
    <>
      <Helmet>
        <title>Staycation - Admin</title>
      </Helmet>
      <Layout className="app">
        <SideMenuAdmin user={user} />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/rooms" exact component={RoomsList} />
          <Route path="/rooms/new" component={RoomDetail} />
          <Route path="/rooms/:id/edit" component={RoomDetail} />
          <Route path="/experiences/new" component={Experience} />
          <Route path="/experiences/:id/edit" component={Experience} />
          <Route path="/experiences" exact component={Experiences} />
          <Route path="/hotels" exact component={HotelsList} />
          <Route path="/hotels/new" component={HotelNew} />
          <Route path="/hotels/:id/edit" component={HotelEdit} />
          <Route path="/hotels/galleries" component={Galleries} />
          <Route path="/hotels/:id/gallery" component={Gallery} />
          <Route path="/bookings/:bookingCode" component={BookingDetails} />
          <Route path="/bookings" component={BookingsList} />
          <Route path="/gift-cards/:id" component={GiftCardDetails} />
          <Route path="/gift-cards" component={GiftCardsList} />
          <Route path="/packages" exact component={PackagesList} />
          <Route path="/packages/new" component={PackageDetail} />
          <Route path="/packages/:id/edit" component={PackageDetail} />
          <Route path="/openings" component={Inventory} />
          <Route path="/bills" component={Bills} />
          <Route path="/sales" component={SalesList} />
          <Route path="/slider" exact component={Slider} />
          <Route path="/merch/packages" exact component={MerchPackagesList} />
          <Route
            path="/merch/packages/:id/edit"
            exact
            component={MerchPackageForm}
          />
          <Route path="/custom-tags" component={CustomTags} />
          <Route path="/guides" exact component={GuidesList} />
          <Route path="/guides/new" component={Guide} />
          <Route path="/guides/:id/edit" component={Guide} />
          <Route path="/collections" exact component={CollectionList} />
          <Route path="/collections/new" component={CollectionDetail} />
          <Route path="/collections/:id/edit" component={CollectionDetail} />
          <Route path="/users/:id/edit" component={UserDetails} />
          <Route path="/users" component={UsersList} />
          <Route path="/vouchers/new" component={VoucherForm} exact />
          <Redirect from="/vouchers/:id/edit" to="/vouchers/new" />
          {user.role === 'superadmin' && (
            <>
              <Route
                path="/quick-filters"
                exact
                component={QuickFiltersListPage}
              />
              <Route
                path="/edito-events"
                exact
                component={EditoEventListPage}
              />
              <Route path="/edito-events/new" component={EditoEventDetail} />
              <Route
                path="/edito-events/:id/edit"
                exact
                component={EditoEventDetail}
              />
              <Route path="/quick-filters/new" component={QuickFilterDetail} />
              <Route
                path="/quick-filters/:id/edit"
                component={QuickFilterDetail}
              />
              <Route
                path="/commercial-gestures"
                component={CommercialGestures}
                exact
              />
            </>
          )}
        </Switch>
      </Layout>
    </>
  );
};

AdminApp.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }),
  appUrl: PropTypes.string.isRequired,
  appReady: PropTypes.bool,
  init: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  appUrl: state.navigation.appUrl,
  appReady: state.navigation.appReady,
});

const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(initApp('admin')),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminApp)
);
