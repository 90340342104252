import { Col, Collapse, CollapsePanelProps, Form, Input, Row } from 'antd';
import { Rule } from 'antd/lib/form';
import React from 'react';

import PanelHeader from 'app/components/layout/PanelHeader/PanelHeader';

const { Panel } = Collapse;

const RULES = [{ required: true, message: 'Required' }];
const EMAIL_RULES: Rule[] = [
  {
    message: 'Must be a valid email',
    type: 'email',
  },
  { required: true, message: 'Required' },
];

type Props = Partial<CollapsePanelProps> & {
  mode: 'view' | 'edit';
};

export const GuestContact = ({ mode, ...panelProps }: Props) => {
  const isDisabled = mode === 'view';

  return (
    <Panel
      key="guest-contact"
      className="panel"
      header={
        <PanelHeader
          name="Contact for Guest Relations"
          subTitle="Person(s) to contact for specific demands related to customer stays (minimum 1 person)"
        />
      }
      {...panelProps}
    >
      <Form.Item name="email" label="Email" rules={EMAIL_RULES}>
        <Input disabled={isDisabled} />
      </Form.Item>
      <Form.Item name="phone" label="Direct phone" rules={RULES}>
        <Input disabled={isDisabled} />
      </Form.Item>
      <Row>
        <Col span={12}>
          <Form.Item name={['guestContact', 'firstName']} label="First name">
            <Input disabled={isDisabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['guestContact', 'lastName']} label="Last name">
            <Input disabled={isDisabled} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={['guestContact', 'job']} label="Position">
        <Input disabled={isDisabled} />
      </Form.Item>
    </Panel>
  );
};
