import { createAction } from '@reduxjs/toolkit';

import type {
  BookingDetails,
  Refund,
  RefundParams,
  VoucherForm,
} from 'app/redux/models/BookingDetails/BookingDetails';

type GetBookingDetailsPayload = {
  bookingCode: string;
};

type GetBookingDetailsSuccessPayload = {
  booking: BookingDetails;
};

type GetBookingDetailsFailurePayload = {
  error: any;
};

type GetBookingRefundParamsPayload = {
  bookingCode: string;
};

type GetBookingRefundParamsSuccessPayload = {
  params: RefundParams;
};

type GetBookingRefundParamsFailurePayload = {
  error: any;
};

type RefundBookingPayload = {
  bookingCode: string;
  body: Refund;
};

type RefundBookingSuccessPayload = {
  booking: BookingDetails;
};

type RefundBookingFailurePayload = {
  error: any;
};

type EditBookingReviewPayload = {
  bookingCode: string;
  feedbackId: number;
  review: string;
};

type EditBookingPublishedPayload = {
  bookingCode: string;
  feedbackId: number;
  published: boolean;
};

type ResendBookingConfirmationPayload = {
  bookingCode: string;
};

type ResendBookingConfirmationFailurePayload = {
  error: any;
};

type ResendBookingHotelConfirmationPayload = {
  bookingCode: string;
};

type ResendBookingHotelConfirmationFailurePayload = {
  error: any;
};

type CreateVoucherPayload = {
  voucher: VoucherForm;
};

type UpdateVoucherPayload = {
  voucher: VoucherForm;
};

type DeleteVoucherPayload = {
  code: string;
};

type UpdateBookingEmailPayload = {
  email: string;
};

export const GET_BOOKING_DETAILS = 'GET_BOOKING_DETAILS';
export const GET_BOOKING_DETAILS_SUCCESS = 'GET_BOOKING_DETAILS_SUCCESS';
export const GET_BOOKING_DETAILS_FAILURE = 'GET_BOOKING_DETAILS_FAILURE';

export const GET_BOOKING_REFUND_PARAMS = 'GET_BOOKING_REFUND_PARAMS';
export const GET_BOOKING_REFUND_PARAMS_SUCCESS =
  'GET_BOOKING_REFUND_PARAMS_SUCCESS';
export const GET_BOOKING_REFUND_PARAMS_FAILURE =
  'GET_BOOKING_REFUND_PARAMS_FAILURE';

export const REFUND_BOOKING = 'REFUND_BOOKING';
export const REFUND_BOOKING_SUCCESS = 'REFUND_BOOKING_SUCCESS';
export const REFUND_BOOKING_FAILURE = 'REFUND_BOOKING_FAILURE';

export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS';
export const CANCEL_BOOKING_FAILURE = 'CANCEL_BOOKING_FAILURE';

export const EDIT_BOOKING_REVIEW = 'EDIT_BOOKING_REVIEW';
export const EDIT_BOOKING_PUBLISHED = 'EDIT_BOOKING_PUBLISHED';

export const RESEND_BOOKING_CONFIRMATION = 'RESEND_BOOKING_CONFIRMATION';
// export const START_RESEND_BOOKING_CONFIRMATION = 'START_RESEND_BOOKING_CONFIRMATION';
export const RESEND_BOOKING_CONFIRMATION_SUCCESS =
  'RESEND_BOOKING_CONFIRMATION_SUCCESS';
export const RESEND_BOOKING_CONFIRMATION_FAILURE =
  'RESEND_BOOKING_CONFIRMATION_FAILURE';

export const RESEND_BOOKING_HOTEL_CONFIRMATION =
  'RESEND_BOOKING_HOTEL_CONFIRMATION';
// export const START_RESEND_BOOKING_HOTEL_CONFIRMATION = 'START_RESEND_BOOKING_HOTEL_CONFIRMATION';
export const RESEND_BOOKING_HOTEL_CONFIRMATION_SUCCESS =
  'RESEND_BOOKING_HOTEL_CONFIRMATION_SUCCESS';
export const RESEND_BOOKING_HOTEL_CONFIRMATION_FAILURE =
  'RESEND_BOOKING_HOTEL_CONFIRMATION_FAILURE';

export const CLEAR_BOOKING_DETAILS_STATE = 'CLEAR_BOOKING_DETAILS_STATE';

export const CREATE_VOUCHER = 'CREATE_VOUCHER';
export const UPDATE_VOUCHER = 'UPDATE_VOUCHER';
export const DELETE_VOUCHER = 'DELETE_VOUCHER';

export const UPDATE_BOOKING_EMAIL = 'UPDATE_BOOKING_EMAIL';

export const GENERATE_RECEIPT = 'GENERATE_RECEIPT';

export const getBookingDetails =
  createAction<GetBookingDetailsPayload>(GET_BOOKING_DETAILS);
export const getBookingDetailsSuccess =
  createAction<GetBookingDetailsSuccessPayload>(GET_BOOKING_DETAILS_SUCCESS);
export const getBookingDetailsFailure =
  createAction<GetBookingDetailsFailurePayload>(GET_BOOKING_DETAILS_FAILURE);

export const getBookingRefundParams =
  createAction<GetBookingRefundParamsPayload>(GET_BOOKING_REFUND_PARAMS);
export const getBookingRefundParamsSuccess =
  createAction<GetBookingRefundParamsSuccessPayload>(
    GET_BOOKING_REFUND_PARAMS_SUCCESS
  );
export const getBookingRefundParamsFailure =
  createAction<GetBookingRefundParamsFailurePayload>(
    GET_BOOKING_REFUND_PARAMS_FAILURE
  );

export const refundBooking = createAction<RefundBookingPayload>(REFUND_BOOKING);
export const refundBookingSuccess = createAction<RefundBookingSuccessPayload>(
  REFUND_BOOKING_SUCCESS
);
export const refundBookingFailure = createAction<RefundBookingFailurePayload>(
  REFUND_BOOKING_FAILURE
);

export const editBookingReview =
  createAction<EditBookingReviewPayload>(EDIT_BOOKING_REVIEW);
export const editBookingPublished = createAction<EditBookingPublishedPayload>(
  EDIT_BOOKING_PUBLISHED
);

export const resendBookingConfirmation =
  createAction<ResendBookingConfirmationPayload>(RESEND_BOOKING_CONFIRMATION);
export const resendBookingConfirmationSuccess = createAction(
  RESEND_BOOKING_CONFIRMATION_SUCCESS
);
// eslint-disable-next-line max-len
export const resendBookingConfirmationFailure =
  createAction<ResendBookingConfirmationFailurePayload>(
    RESEND_BOOKING_CONFIRMATION_FAILURE
  );

export const resendBookingHotelConfirmation =
  createAction<ResendBookingHotelConfirmationPayload>(
    RESEND_BOOKING_HOTEL_CONFIRMATION
  );
export const resendBookingHotelConfirmationSuccess = createAction(
  RESEND_BOOKING_HOTEL_CONFIRMATION_SUCCESS
);
// eslint-disable-next-line max-len
export const resendBookingHotelConfirmationFailure =
  createAction<ResendBookingHotelConfirmationFailurePayload>(
    RESEND_BOOKING_HOTEL_CONFIRMATION_FAILURE
  );

export const clearBookingDetailsState = createAction(
  CLEAR_BOOKING_DETAILS_STATE
);

export const createVoucher = createAction<CreateVoucherPayload>(CREATE_VOUCHER);
export const updateVoucher = createAction<UpdateVoucherPayload>(UPDATE_VOUCHER);
export const deleteVoucher = createAction<DeleteVoucherPayload>(DELETE_VOUCHER);

export const updateBookingEmail =
  createAction<UpdateBookingEmailPayload>(UPDATE_BOOKING_EMAIL);

export const generateReceipt = createAction(GENERATE_RECEIPT);
