import React from 'react';

import './Tooltip.scss';

export const StaycationDiscountTooltip = () => (
  <div className="extranet-inventory-tooltip__container">
    <div className="extranet-inventory-tooltip__title">
      Discount applied to the total value of the package (BAR + value of
      experiences included in the package). Total package price - Discount =
      Staycation rate.
    </div>
    <div className="extranet-inventory-tooltip__subtitle">
      For your stocks to be published on Staycation, you must respect a minimum
      discount rate of 18% on the total value of the package (no discount needed
      in case of a day-package).
    </div>
  </div>
);
