import { Empty } from 'antd';
import React from 'react';
import ReactPhotoGallery from 'react-photo-gallery';

import { Picture } from '../Types';

import SelectablePictureComponent from './SelectablePicture/SelectablePicture';

export type ISelectablePicture = Picture & { selected: boolean };

type Props = {
  pictures: ISelectablePicture[];
  onSelect: (picture: Picture) => void;
};

const PictureGallery = ({ pictures, onSelect }: Props) => {
  if (!pictures.length) {
    return (
      <div className="div-empty">
        <Empty />
      </div>
    );
  }

  return (
    <ReactPhotoGallery
      columns={5}
      photos={pictures.map((picture) => ({
        ...picture,
        src: '',
        width: picture.dimensions.width,
        height: picture.dimensions.height,
      }))}
      onClick={(event, { index }) => onSelect(pictures[index])}
      // @ts-ignore
      renderImage={SelectablePictureComponent}
      direction="column"
    />
  );
};

export default PictureGallery;
