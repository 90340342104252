import PropTypes from 'prop-types';
import React from 'react';

import 'styles/form/form-section.scss';

const FormSection = ({ title, bordered, separated, children }) => (
  <div
    className={`section ${bordered ? 'section--bordered' : ''} ${
      separated ? 'section--separated' : ''
    }`}
  >
    <h3 className="section__title">{title}</h3>
    <div className="section__content">{children}</div>
  </div>
);

FormSection.propTypes = {
  title: PropTypes.string.isRequired,
  bordered: PropTypes.bool,
  separated: PropTypes.bool,
  children: PropTypes.node,
};

export default FormSection;
