import { Rule } from 'antd/lib/form';

import { requiredRule } from 'app/utils/rules';

import { TranslationSectionForm } from './TranslationSection';

const MAX_CHARACTERS_LABEL = 30;

const formLabelName = [
  {
    label: 'English name',
    name: 'translationEn',
    placeholder: 'ex. Pool',
  },
  {
    label: 'French name',
    name: 'translationFr',
    placeholder: 'ex. Piscine',
  },
  {
    label: 'Spanish name',
    name: 'translationEs',
    placeholder: 'ex. Piscina',
  },
];

const validateFirstLetterUppercase: Rule = {
  validator(_, value) {
    if (!value || /^[A-Z].*/.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error('The first character should be in uppercase.')
    );
  },
};

const rules: Rule[] = [
  requiredRule,
  {
    max: MAX_CHARACTERS_LABEL,
    message: `This field should contain at most ${MAX_CHARACTERS_LABEL} characters.`,
  },
  validateFirstLetterUppercase,
];

export const LabelTranslationSection = () => {
  return (
    <TranslationSectionForm
      properties={formLabelName}
      title="Label name"
      rules={rules}
    />
  );
};
