import styled from 'styled-components';

export const SectionTitleNoMargin = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  position: relative;
  margin: 0;
`;

export const SectionTitleSM = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  position: relative;
  margin: 0;
`;
