import { BulbOutlined } from '@ant-design/icons';
import React from 'react';

import './CoolToKnow.scss';

const CoolToKnow = () => (
  <div className="cool-to-know">
    <div className="border" />
    <div className="content">
      <div className="title">
        <BulbOutlined />
        <div>Cool to know</div>
      </div>
      <div className="text">
        Add 1 photo minimum and as many as possible to help us highlight this
        room. Guests like to have room and bathroom photos, we’ve noticed that a
        room with at least 4 photos is more booked than others.
      </div>
    </div>
  </div>
);

export default CoolToKnow;
