import { Card, Form, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  tryFetchHotels,
  trySaveGesture,
} from 'app/redux/actions/commercialGestures';
import { useAppSelector } from 'app/redux/hooks';
import { Country } from 'app/redux/models/Country/Country';
import { Hotel } from 'app/redux/models/Hotel/Hotel';
import {
  getHotels,
  isGesturesLoading,
} from 'app/redux/selectors/commercialGestures';
import { selectCountries } from 'app/redux/selectors/conf';

import CommercialGestureForm from './CommercialGestureForm/CommercialGestureForm';
import CommercialGestureList from './CommercialGestureList/CommercialGestureList';
import './CommercialGestures.scss';
import ConfirmModal from './ConfirmModal/ConfirmModal';

const { Content, Header } = Layout;

const CommercialGestures = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedHotel, setSelectedHotel] = useState<Hotel>();

  const hotels: Array<Hotel> = useSelector(getHotels);
  const countries: Array<Country> = useAppSelector(selectCountries);
  const isLoading: boolean = useSelector(isGesturesLoading);

  const handleSubmit = (values: any) => {
    dispatch(trySaveGesture(values));
  };

  useEffect(() => {
    dispatch(tryFetchHotels());
  }, [dispatch]);

  const handleHotelChange = (hotelId: number) => {
    const selectedAccount = hotels.find((a) => a.id === hotelId);

    if (selectedAccount) {
      setSelectedHotel(selectedAccount);
    }
  };

  const getCurrency = (countryId: number) =>
    countries.find((c) => c.id === countryId)?.currency;

  return (
    <Layout className="page__container commercial-gestures">
      <Header className="header">
        <h1>{'Commercial Gestures'}</h1>
      </Header>
      <Content className="body">
        <Card
          title="Make a commercial gesture"
          actions={[
            <ConfirmModal
              key="confirm"
              account={{
                name: selectedHotel?.name || '',
                currency: selectedHotel
                  ? getCurrency(selectedHotel.countryId) || ''
                  : '',
              }}
              onSubmit={() => form.submit()}
              form={form}
            />,
          ]}
        >
          <Form form={form} onFinish={handleSubmit}>
            <CommercialGestureForm
              hotels={hotels}
              countries={countries}
              onHotelChange={handleHotelChange}
            />
          </Form>
        </Card>
        <CommercialGestureList
          hotels={hotels}
          countries={countries}
          loading={isLoading}
        />
      </Content>
    </Layout>
  );
};

export default CommercialGestures;
