import type { AnyAction } from 'redux';

import * as actions from 'app/redux/actions/collections';
import type { Collection } from 'app/redux/models/Collection/Collection';
import { createCollection } from 'app/redux/models/Collection/Collection';
import type { CollectionItem } from 'app/redux/models/CollectionItem/CollectionItem';
import { createCollectionItem } from 'app/redux/models/CollectionItem/CollectionItem';
import type { PreviewResult } from 'app/redux/models/PreviewResult/PreviewResult';
import { createCollectionPreview } from 'app/redux/models/PreviewResult/PreviewResult';

export type Collections = {
  readonly loading: boolean;
  readonly collections: CollectionItem[];
  readonly previews: PreviewResult[];
  readonly collection: Partial<Collection> | null;
};

export const initialState: Collections = {
  loading: false,
  collections: [],
  previews: [],
  collection: null,
};

function collections(state = initialState, action: AnyAction): Collections {
  if (actions.startLoading.match(action)) {
    return {
      ...state,
      loading: true,
    };
  }

  if (actions.stopLoading.match(action)) {
    return {
      ...state,
      loading: false,
    };
  }

  if (actions.fetchCollectionsSuccess.match(action)) {
    const newCollections = action.payload.result.map((e) =>
      createCollectionItem(e)
    );

    return {
      ...state,
      loading: false,
      collections: newCollections,
    };
  }

  if (actions.fetchCollectionPreviewSuccess.match(action)) {
    const newCollectionPreviews = action.payload.result.map((e) =>
      createCollectionPreview(e)
    );

    return {
      ...state,
      loading: false,
      previews: newCollectionPreviews,
    };
  }

  if (actions.fetchCollectionSuccess.match(action)) {
    const newCollection = createCollection(action.payload.result);

    return {
      ...state,
      collection: newCollection,
    };
  }

  if (actions.resetCollection.match(action)) {
    return {
      ...state,
      collection: null,
      previews: [],
    };
  }

  return state;
}

export default collections;
