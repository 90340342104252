import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

import Pagination from './Pagination';

type TableHeaderProps = {
  handleSearch: (search: string) => void;
  searchValue: string;
  pageNumber: number;
  pageResultsLength: number;
  handleOffset: (offset: number) => void;
};

const Header: React.FC<TableHeaderProps> = ({
  handleSearch,
  searchValue,
  pageNumber,
  pageResultsLength,
  handleOffset,
}: TableHeaderProps) => (
  <div className="left-right-header">
    <div className="left-right-header__left">
      <Input
        placeholder="Search here..."
        onChange={(event) => handleSearch(event.target.value)}
        suffix={<SearchOutlined className="certain-category-icon" />}
        className="left-right-header__search"
        size="large"
        value={searchValue}
      />
    </div>
    <Pagination
      className="left-right-header__right"
      pageNumber={pageNumber || 1}
      pageResultsLength={pageResultsLength}
      handleOffset={handleOffset}
    />
  </div>
);

export default Header;
