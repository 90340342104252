import { Button, PageHeader, Tooltip } from 'antd';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import './SDetailLayout.scss';

export const SDetailLayout: FC<Props> = ({
  title,
  backRoute,
  mode,
  onEdit,
  onSave,
  onCancel,
  reset,
  isDirty,
  disabled = false,
  saveDisabled = false,
  headerMenu,
  headerExtra,
  isValid,
  children,
  tooltipEdit,
  extraViewFooter,
  buttonSaveText,
  customFooterButtons,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const cancelCallback = () => {
    if (reset) {
      reset();
    }

    if (onCancel) {
      onCancel();
    }
  };

  const onClickSave = () => {
    if (isValid) {
      setIsLoading(true);
    }

    if (onSave) {
      onSave();
    }
  };

  const onClickEdit = () => {
    setIsLoading(false);

    if (onEdit) {
      onEdit();
    }
  };

  useEffect(() => {
    if (!isValid) {
      setIsLoading(false);
    }
  }, [isValid]);

  return (
    <div className="detail-layout">
      {title && (
        <PageHeader
          className={`detail-layout__header ${
            headerMenu ? 'detail-layout__header-menu' : ''
          }`}
          onBack={() =>
            backRoute ? history.push(backRoute) : history.goBack()
          }
          title={title}
          extra={headerExtra}
        >
          {headerMenu}
        </PageHeader>
      )}
      {children}
      <div className="detail-layout__footer">
        {customFooterButtons ? (
          customFooterButtons
        ) : mode === 'edit' ? (
          <>
            {onCancel && (
              <Button size="large" disabled={disabled} onClick={cancelCallback}>
                Cancel
              </Button>
            )}
            <Button
              type="primary"
              size="large"
              loading={isLoading}
              disabled={!isDirty || disabled || isLoading || saveDisabled}
              onClick={onClickSave}
            >
              {buttonSaveText ?? 'Save'}
            </Button>
          </>
        ) : tooltipEdit ? (
          <>
            {extraViewFooter}
            <Tooltip title={tooltipEdit}>
              <Button size="large" disabled={disabled} onClick={onClickEdit}>
                {buttonSaveText ?? 'Edit'}
              </Button>
            </Tooltip>
          </>
        ) : (
          <>
            {extraViewFooter}
            <Button size="large" disabled={disabled} onClick={onClickEdit}>
              {buttonSaveText ?? 'Edit'}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

type Props = {
  title?: string;
  backRoute?: string;
  mode: string;
  onEdit?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
  reset?: () => void;
  isDirty: boolean;
  disabled?: boolean;
  saveDisabled?: boolean;
  headerMenu?: ReactNode;
  headerExtra?: ReactNode;
  isValid?: boolean;
  children?: ReactNode;
  tooltipEdit?: React.ReactNode;
  extraViewFooter?: ReactNode;
  buttonSaveText?: string;
  customFooterButtons?: ReactNode;
};

export type ExternalProps = Omit<Props, 'history'>;

export default SDetailLayout;
