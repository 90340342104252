import { AnyAction } from '@reduxjs/toolkit';

import * as customTagsActions from 'app/redux/actions/customTags';
import { createCustomTag } from 'app/redux/models/CustomTag/CustomTag';
import type { CustomTag } from 'app/redux/models/CustomTag/CustomTag';
import { sortAndFilterCustomTags } from 'app/utils/sortAndFilter';

export type CustomTags = {
  isLoading: boolean;
  customTags: CustomTag[] | null;
  filteredCustomTags: CustomTag[];
};

export const initialState: CustomTags = {
  isLoading: false,
  customTags: null,
  filteredCustomTags: [],
};

function customTags(state = initialState, action: AnyAction): CustomTags {
  if (customTagsActions.startLoading.match(action)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (customTagsActions.stopLoading.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (customTagsActions.fetchCustomTagsSuccess.match(action)) {
    const newCustomTags = action.payload.customTags.map(createCustomTag);

    return {
      ...state,
      customTags: newCustomTags,
    };
  }

  if (customTagsActions.filterCustomTags.match(action)) {
    if (!state.customTags) {
      return state;
    }

    const filteredCustomTags = sortAndFilterCustomTags(
      state.customTags,
      action.payload
    );

    return {
      ...state,
      isLoading: false,
      filteredCustomTags,
    };
  }

  return state;
}

export default customTags;
