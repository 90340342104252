import { PlusOutlined } from '@ant-design/icons';
import { Form, Layout } from 'antd';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { SDetailLayout } from 'app/components/StaycationUI';
import Desk from 'app/components/commons/Hotel/Spaces/Desk/Desk';
import InformativePhoto from 'app/components/commons/Hotel/Spaces/InformativePhoto/InformativePhoto';
import Space from 'app/components/commons/Hotel/Spaces/Space/Space';
import SpaceModal from 'app/components/commons/Hotel/Spaces/SpaceModal/SpaceModal';
import {
  formatNewSpaces,
  formatSpacesFormFromAPI,
  reformatSpacesFormForAPI,
} from 'app/components/commons/Hotel/Spaces/utils';
import {
  IHotel,
  ISpaceType,
  SpaceSubmitType,
} from 'app/components/commons/Hotel/Types';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { trySaveSpaces } from 'app/redux/actions/hotels';

import SpaceItem from './SpaceItem/SpaceItem';
import './Spaces.scss';

const { Content } = Layout;

export const Spaces = ({
  headerMenu,
  hotel,
  spaceTypes,
  submitSpaces,
}: Props) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values: any) => {
      const reformatted = reformatSpacesFormForAPI(values, hotel, spaceTypes);

      submitSpaces(hotel.id, reformatted);
      setMode('view');
    },
    [spaceTypes, hotel, submitSpaces]
  );

  const onCancel = useCallback(() => {
    setMode('view');
  }, []);

  const formattedValues = useMemo(() => {
    if (!hotel) {
      return null;
    }

    return formatSpacesFormFromAPI(hotel, spaceTypes);
  }, [hotel, spaceTypes]);

  const onModalSave = (newSpaces: any) => {
    const values = form.getFieldsValue();
    const formatted = formatNewSpaces(newSpaces, values, spaceTypes, true);

    form.setFieldsValue(formatted);
  };

  if (!hotel) {
    return null;
  }

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      initialValues={formattedValues || {}}
      className="spaces-form-master"
    >
      {(
        values,
        { resetFields, isFieldsTouched, submit, setFieldsValue, validateFields }
      ) => (
        <SDetailLayout
          title={hotel.name}
          backRoute="/hotels"
          mode={mode}
          onEdit={() => setMode('edit')}
          onSave={submit}
          onCancel={onCancel}
          reset={resetFields}
          isDirty={isFieldsTouched()}
          headerMenu={headerMenu}
          disabled={!hotel.spaces}
          isValid={form
            .getFieldsError()
            .every((item) => item.errors.length > 0)}
        >
          <Content className="hotel-detail__content">
            <SpaceItem
              title="Informative Photo"
              expanded={!!hotel.spaces}
              disabled={!hotel.spaces}
            >
              <InformativePhoto
                mode={mode}
                values={values}
                hotelId={hotel.id}
              />
            </SpaceItem>
            <SpaceItem
              title="Desk"
              expanded={!!hotel.spaces}
              disabled={!hotel.spaces}
            >
              <Desk mode={mode} values={values} />
            </SpaceItem>
            <Form.List name="spaces">
              {(fields, { remove }) => (
                <>
                  {fields.map((field, idx) => {
                    const space = values.spaces[idx];
                    const spaceType = spaceTypes.find(
                      (type) => type.id === space.spaceTypeId
                    );

                    if (!space || !spaceType) {
                      return null;
                    }

                    return (
                      <SpaceItem
                        title={spaceType.name}
                        onRemove={
                          mode === 'edit' ? () => remove(idx) : undefined
                        }
                        expanded={space?.addedSpace}
                        key={`${spaceType.id}-${idx}`}
                      >
                        <Space
                          mode={mode}
                          values={values}
                          field={field}
                          fieldIndex={idx}
                          spaceType={spaceType}
                          setFieldsValue={setFieldsValue}
                          hotelId={hotel.id}
                          validateFields={validateFields}
                          previousPrefixes={['spaces']}
                        />
                      </SpaceItem>
                    );
                  })}
                  {mode === 'edit' && (
                    <div className="spaces__add-button-container">
                      <div
                        onClick={() => setIsModalOpen(true)}
                        className="spaces__add-button"
                      >
                        <PlusOutlined />
                        <span className="text">Add spaces</span>
                      </div>
                      <SpaceModal
                        onCancel={() => setIsModalOpen(false)}
                        onSave={onModalSave}
                        visible={isModalOpen}
                        values={values}
                        spaceTypes={spaceTypes}
                      />
                    </div>
                  )}
                </>
              )}
            </Form.List>
          </Content>
        </SDetailLayout>
      )}
    </FormLegacyRenderProp>
  );
};

type Props = {
  headerMenu: ReactNode;
  hotel: IHotel;
  spaceTypes: Array<ISpaceType>;
  submitSpaces: (hotelId: number, values: SpaceSubmitType) => void;
};

const mapStateToProps = (state: any, props: any) => {
  const hotelId = parseInt(props.hotelId, 10);

  return {
    hotel:
      hotelId && hotelId === state.hotels.hotel.id ? state.hotels.hotel : null,
    spaceTypes: state.conf.spaceTypes,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  submitSpaces: (hotelId: any, values: any) =>
    dispatch(trySaveSpaces({ hotelId, spaces: values })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Spaces);
