import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import classNames from 'classnames';
import React, { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { DataType, PeriodToCompare } from 'app/typings/analytics';

import {
  getDataTypeWording,
  getPercentage,
  getPeriodWording,
  renderDataWithSuffix,
} from '../../commons/Utils';
import Badge from '../Badge/Badge';
import Tooltip from '../Tooltip/Tooltip';

import './DataCard.scss';

type DataCardType = {
  dataType: DataType;
  periodToCompare: PeriodToCompare;
  cardData: {
    previous: number;
    current: number;
    datesCurrentPeriod: {
      start: Date;
      end: Date;
    };
    datesPreviousPeriod: {
      start: Date;
      end: Date;
    };
  };
  description?: string;
  showComparePrevPeriod?: boolean;
  showDataBadge?: boolean;
  showTopBadge?: boolean;
};

export const DataCard = ({
  dataType,
  periodToCompare,
  cardData,
  description,
  showComparePrevPeriod = false,
  showDataBadge = false,
  showTopBadge = false,
}: DataCardType) => {
  const periodToCompareWording = getPeriodWording(periodToCompare);
  const { currencyFormatter } = useContext(CurrencyContext);
  const { title, hasSuffix, chartCurrentLineName, chartPreviousLineName } =
    getDataTypeWording(dataType, periodToCompare);

  const hasData = cardData.current > 0 || cardData.previous > 0;

  const { percentage, type } = getPercentage(
    cardData.current,
    cardData.previous
  );

  const tooltipData = [
    {
      text: chartPreviousLineName,
      startDate: new Date(cardData.datesPreviousPeriod.start),
      endDate: new Date(cardData.datesPreviousPeriod.end),
      value: cardData.previous,
    },
    {
      text: chartCurrentLineName,
      startDate: new Date(cardData.datesCurrentPeriod.start),
      endDate: new Date(cardData.datesCurrentPeriod.end),
      value: cardData.current,
    },
  ];

  return (
    <div className="dataCard_container">
      <div className="dataCard_title_container">
        <div className="dataCard_title">{title}</div>
        {showTopBadge && hasData && (
          <Badge value={`${percentage} %`} type={type} hasIcon={true} />
        )}
      </div>
      <div className="dataCard_data">
        <div className="dataCard_number_container">
          <div className="dataCard_number">
            {hasData
              ? renderDataWithSuffix(
                  cardData.current,
                  currencyFormatter,
                  hasSuffix
                )
              : '-'}
          </div>
          {showDataBadge && hasData && (
            <Badge value={`${percentage} %`} type={type} hasIcon={true} />
          )}
        </div>

        {showComparePrevPeriod && (
          <div className="dataCard_period_comparaison">
            {hasData ? (
              <>
                vs.{' '}
                {renderDataWithSuffix(
                  cardData.previous,
                  currencyFormatter,
                  hasSuffix
                )}{' '}
                {periodToCompareWording}{' '}
                <Popover
                  placement="bottom"
                  overlayClassName="popoverDataCard"
                  content={
                    <Tooltip
                      unit={'DAILY'}
                      items={tooltipData}
                      hasSuffix={hasSuffix}
                      currencyFormatter={currencyFormatter}
                      isCardDisplay
                    />
                  }
                >
                  <InfoCircleOutlined />
                </Popover>
              </>
            ) : (
              'not enough data to compare'
            )}
          </div>
        )}
        {description && (
          <div className="dataCard_description">
            <Badge type={type} hasIcon={true} />
            <div className="dataCard_description_text">
              <span className={classNames(type)}>{percentage} %</span>{' '}
              {description}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataCard;
