import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQueries, useQuery } from '@tanstack/react-query';

import { getDataTypeWording } from 'app/components/pages/Extranet/ExtranetDashboard/commons/Utils';
import {
  ApiLastUpdatedAt,
  ApiTimeSerieType,
  ApiValueType,
  DATA_TYPE_ENUM_VALUE,
  DataType,
  DataValue,
  PeriodToCompare,
  Unit,
} from 'app/typings/analytics';
import { apiRequest } from 'app/utils/request/api';

const timeSeriesPath = '/gateway/analytics/charts/timeseries' as const;
const valuesPath = '/gateway/analytics/charts/values' as const;
const lastUpdatedAtPath = '/gateway/analytics/charts/last-updated-at' as const;

type QueryParams = {
  hotelId?: number;
  unit?: Unit;
  startDate?: string;
  endDate?: string;
};

type DataTypePartition = {
  withChart: DataType[];
  withoutChart: DataType[];
};

export function useFetchDashboardData(
  datatype: DataType[],
  periodToCompare: PeriodToCompare,
  queryParams: QueryParams
) {
  const { withChart, withoutChart }: DataTypePartition = datatype.reduce(
    (acc, type) => {
      const { hasChart } = getDataTypeWording(type, periodToCompare);

      if (hasChart) {
        acc.withChart.push(type);
      } else {
        acc.withoutChart.push(type);
      }

      return acc;
    },
    { withChart: [], withoutChart: [] } as DataTypePartition
  );

  const timeseriesResults = useHotelAnalytics<ApiTimeSerieType>(
    withChart,
    timeSeriesPath,
    queryParams,
    (data, type) => {
      return {
        type: type,
        ...data,
      };
    }
  );

  const valueResults = useHotelAnalytics<ApiValueType>(
    withoutChart,
    valuesPath,
    queryParams,
    (data, type) => {
      return {
        type: type,
        aggregatedValue: data.value,
        data: [],
      };
    }
  );

  const sortedResults = [...timeseriesResults, ...valueResults].sort((a, b) => {
    return a.data?.type && b.data?.type
      ? [...DATA_TYPE_ENUM_VALUE].indexOf(a.data?.type) -
          [...DATA_TYPE_ENUM_VALUE].indexOf(b.data?.type)
      : 0;
  });

  return sortedResults;
}

const useHotelAnalytics = <T>(
  datatype: DataType[],
  path: string,
  queryParams: QueryParams,
  selectFn: (
    data: T,
    type: DataType
  ) => { type: DataType; data: DataValue[]; aggregatedValue: number }
) => {
  return useQueries({
    queries: datatype.map((type) => {
      const formatQueryParams = { ...queryParams, dataType: type };

      return {
        queryKey: [path, { formatQueryParams }],
        queryFn: () =>
          apiRequest({
            path,
            queryParams: formatQueryParams,
          }) as Promise<T>,
        select: (data: T) => selectFn(data, type),
        staleTime: 5 * MILLISECONDS_IN_MINUTE,
      };
    }),
  });
};

export function useHotelDashboardLastUpdatedAt(hotelId?: number) {
  return useQuery({
    queryKey: [lastUpdatedAtPath, { hotelId }],
    queryFn: () =>
      apiRequest({
        path: lastUpdatedAtPath,
        queryParams: { hotelId },
      }) as Promise<ApiLastUpdatedAt>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}
