import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';

import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import Delete from 'app/components/commons/ExtraButtons/Delete/Delete';
import Copywriting from 'app/components/pages/Packages/PackageDetail/Copywriting/Copywriting';
import Cover from 'app/components/pages/Packages/PackageDetail/Cover/Cover';
import Disclaimer from 'app/components/pages/Packages/PackageDetail/Disclaimer/Disclaimer';
import Discover from 'app/components/pages/Packages/PackageDetail/Discover/Discover';
import General from 'app/components/pages/Packages/PackageDetail/General/General';
import Preview from 'app/components/pages/Packages/PackageDetail/Preview/Preview';
import Tags from 'app/components/pages/Packages/PackageDetail/Tags/Tags';
import { tryFetchHotelExperiences } from 'app/redux/actions/experiences';
import { tryFetchHotels } from 'app/redux/actions/hotels';
import {
  tryDeletePackage,
  tryFetchCopywriting,
  tryFetchCover,
  tryFetchDiscover,
  tryFetchPackage,
} from 'app/redux/actions/packages';
import { tryFetchHotelRooms } from 'app/redux/actions/rooms';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';

import './PackageDetail.scss';

const MENU_ITEMS = [
  { name: 'General' },
  { name: 'Copywriting' },
  { name: 'Discover' },
  { name: 'Cover' },
  { name: 'Disclaimer' },
  { name: 'Tags' },
];

type MenuProps = {
  menuItems: Array<{ name: string }>;
  selectedMenu: { name: string };
  setSelectedMenu: (menu: { name: string }) => void;
};

const MenuCustom = ({
  menuItems,
  selectedMenu,
  setSelectedMenu,
}: MenuProps) => (
  <Menu
    mode="horizontal"
    onClick={({ key }) => setSelectedMenu(menuItems[parseInt(key, 10)])}
    selectedKeys={[
      menuItems.findIndex((item) => item.name === selectedMenu.name).toString(),
    ]}
  >
    {menuItems.map((item, index) => (
      <Menu.Item key={index}>{item.name}</Menu.Item>
    ))}
  </Menu>
);

type Props = {
  match: {
    params: {
      id?: string;
    };
  };
};

export const PackageDetail = ({ match }: Props) => {
  const dispatch = useAppDispatch();
  const appUrl = useAppSelector((state) => state.navigation.appUrl);
  const statePackage = useAppSelector((state) => state.packages.package);
  const [menu, setMenu] = useState(MENU_ITEMS[0]);
  const deletePackage = (packageId: any) =>
    dispatch(tryDeletePackage({ packageId }));
  const paramsId = match.params?.id;
  const packageId = paramsId ? parseInt(paramsId, 10) : undefined;
  const pkg = paramsId ? statePackage : undefined; // TODO verification between state id and params id ?

  useEffect(() => {
    dispatch(tryFetchHotels());
  }, [dispatch]);

  useEffect(() => {
    if (packageId) {
      dispatch(tryFetchPackage({ packageId }));
      dispatch(tryFetchDiscover({ packageId }));
      dispatch(tryFetchCover({ packageId }));
      dispatch(tryFetchCopywriting({ packageId }));
    }
  }, [dispatch, packageId]);

  useEffect(() => {
    if (pkg?.hotelId) {
      dispatch(tryFetchHotelExperiences(pkg.hotelId, true));
      dispatch(tryFetchHotelRooms(pkg.hotelId));
    }
  }, [dispatch, pkg]);

  const renderMenu = () => (
    <MenuCustom
      menuItems={MENU_ITEMS}
      selectedMenu={menu}
      setSelectedMenu={setMenu}
    />
  );

  const renderPreviewButton = () => {
    if (!packageId || !pkg) {
      return null;
    }

    return appUrl ? (
      <Preview
        key="preview"
        onClick={() => window.open(`${appUrl}/fr/hotels/${pkg.slug}`)}
      />
    ) : (
      <Preview />
    );
  };

  const renderDeleteButton = () => {
    if (!packageId || !pkg) {
      return null;
    }

    return (
      <Delete
        key="delete"
        entityDescription={`Package #${packageId}`}
        onDelete={() => deletePackage(packageId)}
      />
    );
  };

  const dispatchMenuSection = () => {
    switch (menu.name) {
      case 'General':
        return (
          <CurrencyWrapper hotelId={pkg?.hotelId}>
            <General
              headerMenu={packageId ? renderMenu() : undefined}
              headerExtra={[renderPreviewButton(), renderDeleteButton()]}
              packageId={packageId}
            />
          </CurrencyWrapper>
        );
      case 'Copywriting':
        return (
          <Copywriting
            headerMenu={renderMenu()}
            headerExtra={[renderPreviewButton()]}
            packageId={packageId}
          />
        );
      case 'Discover':
        return (
          <Discover
            headerMenu={renderMenu()}
            headerExtra={[renderPreviewButton()]}
            packageId={packageId}
          />
        );
      case 'Cover':
        return (
          <Cover
            headerMenu={renderMenu()}
            headerExtra={[renderPreviewButton()]}
            packageId={packageId}
          />
        );
      case 'Disclaimer':
        return (
          <Disclaimer
            headerMenu={renderMenu()}
            headerExtra={[renderPreviewButton()]}
            packageId={packageId}
          />
        );
      case 'Tags':
        return (
          <Tags
            headerMenu={renderMenu()}
            headerExtra={[renderPreviewButton()]}
            packageId={packageId}
          />
        );
      default:
        return null;
    }
  };

  return <Layout className="package-detail">{dispatchMenuSection()}</Layout>;
};

export default PackageDetail;
