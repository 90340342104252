import { Form, Layout } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { SDetailLayout } from 'app/components/StaycationUI';
import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import Service from 'app/components/commons/Hotel/Services/Service/Service';
import 'app/components/commons/Hotel/Services/Services.scss';
import {
  addService,
  formatServicesForAPI,
  isServicesValid,
  removeService,
  updateService,
} from 'app/components/commons/Hotel/Services/utils';
import {
  HotelServiceForm,
  IHotel,
  IHotelsServicesType,
  Mode,
} from 'app/components/commons/Hotel/Types';
import { trySaveServices } from 'app/redux/actions/hotels';

const { Content } = Layout;

export const Services: FC<Props> = ({
  headerMenu,
  hotel,
  saveServices,
  hotelsServicesTypes,
}) => {
  const hotelServices = hotel?.services.services;
  const hotelName = hotel?.name;
  const [form] = Form.useForm();
  const [services, setServices] = useState<HotelServiceForm[]>(
    hotelServices || []
  );
  const add = (service: any) => addService(service, setServices);
  const update = (service: any) => updateService(service, setServices);
  const remove = (service: any) => removeService(service, setServices);

  useEffect(() => {
    if (hotelServices) {
      setServices(hotelServices);
    }
  }, [hotelServices]);

  const [mode, setMode] = useState<Mode>('view');

  const formServices = form.getFieldsValue()?.services;

  const onFinish = useCallback(() => {
    const formattedServices = formatServicesForAPI(
      services,
      hotelsServicesTypes,
      formServices
    );

    saveServices(formattedServices);
    setMode('view');
  }, [formServices, saveServices, services, hotelsServicesTypes]);

  const onCancel = useCallback(() => {
    setMode('view');
  }, []);

  const saveDisabled =
    mode !== 'view' &&
    !isServicesValid(hotelsServicesTypes, services, formServices);

  return (
    <div className="services-container">
      <SDetailLayout
        title={hotelName || 'Services'}
        backRoute="/hotels"
        mode={mode}
        onEdit={() => setMode('edit')}
        onSave={onFinish}
        onCancel={onCancel}
        reset={() => setServices(hotelServices || [])}
        headerMenu={headerMenu}
        isDirty={!saveDisabled}
        isValid={form.getFieldsError().every((item) => item.errors.length > 0)}
      >
        <Content className="hotel-detail__content">
          <CurrencyWrapper hotel={hotel}>
            {hotelsServicesTypes.map((hotelsServicesType) => (
              <Service
                key={hotelsServicesType.id}
                services={services}
                hotelsServicesType={hotelsServicesType}
                mode={mode}
                add={add}
                update={update}
                remove={remove}
                form={form}
              />
            ))}
          </CurrencyWrapper>
        </Content>
      </SDetailLayout>
    </div>
  );
};

type Props = {
  headerMenu: any;
  hotel: IHotel | null;
  hotelId: string;
  saveServices: (services: HotelServiceForm[]) => void;
  hotelsServicesTypes: IHotelsServicesType[];
};

const mapStateToProps = (state: any, props: any) => {
  const hotelId = parseInt(props.hotelId, 10);

  return {
    hotel:
      hotelId && hotelId === state.hotels.hotel.id ? state.hotels.hotel : null,
    hotelsServicesTypes: state.conf.hotelsServicesTypes,
  };
};

const mapDispatchToProps = (dispatch: any, props: any) => {
  const hotelId = parseInt(props.hotelId, 10);

  return {
    saveServices: (services: any) =>
      dispatch(trySaveServices({ hotelId, services })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
