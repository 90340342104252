import { AnyAction } from '@reduxjs/toolkit';

import * as giftCardsActions from 'app/redux/actions/giftCards';
import { GiftCard } from 'app/redux/models/GiftCard/GiftCard';

export type GiftCards = {
  isLoading: boolean;
  list: GiftCard[];
};

export const initialState: GiftCards = {
  isLoading: false,
  list: [],
};

function giftCards(state = initialState, action: AnyAction) {
  if (giftCardsActions.tryFetchGiftCards.match(action)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (giftCardsActions.fetchGiftCardsSuccess.match(action)) {
    return {
      ...state,
      isLoading: false,
      list: action.payload.giftCards,
    };
  }

  if (giftCardsActions.fetchGiftCardsFailure.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (giftCardsActions.startResendConfirmation.match(action)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (giftCardsActions.startResendECard.match(action)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (giftCardsActions.resendECardSuccess.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (giftCardsActions.resendECardFailure.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (giftCardsActions.resendConfirmationSuccess.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (giftCardsActions.resendConfirmationFailure.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  return state;
}

export default giftCards;
