import { InfoCircleOutlined } from '@ant-design/icons';
import { Line } from '@ant-design/plots';
import { Tooltip as AntdTooltip } from 'antd';
import { differenceInBusinessDays } from 'date-fns';
import React, { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import {
  DataType,
  DataValue,
  Period,
  PeriodToCompare,
  Unit,
} from 'app/typings/analytics';

import {
  createLines,
  getMarkerDate,
  getMarkerPositionOnGraph,
} from '../../commons/ChartUtils';
import {
  formatChartDateWithUnit,
  formatChartItemsForTooltipData,
  getDataTypeWording,
  renderDataWithSuffix,
} from '../../commons/Utils';
import Tooltip from '../Tooltip/Tooltip';

import './Chart.scss';
import EmptyChart from './EmptyChart';

type ChartProps = {
  chartData?: {
    category?: string;
    value: number;
    date: string;
    item: DataValue;
  }[];
  dataType: DataType;
  index: number;
  isFetching: boolean;
  unit: Unit;
  dateRange: Date[];
  period: Period;
  periodToCompare: PeriodToCompare;
};

export const Chart = ({
  chartData = [],
  dataType,
  index,
  isFetching,
  unit,
  dateRange,
  period,
  periodToCompare,
}: ChartProps) => {
  const { currencyFormatter } = useContext(CurrencyContext);
  const hasNoData = chartData.every((data) => data.value === 0);
  const dateArray = chartData.map((data) => data.date);
  const ticks = Array.from(new Set(dateArray));
  const todayMarker = getMarkerDate(ticks, unit);
  const isTodayLastGraphPoint = getMarkerPositionOnGraph(ticks, todayMarker);

  const { title, hasSuffix, explanation } = getDataTypeWording(
    dataType,
    periodToCompare
  );
  const nbDayBetweenRange = differenceInBusinessDays(
    dateRange[1],
    dateRange[0]
  );

  createLines(unit);

  const config = {
    data: chartData,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    xAxis: {
      type: 'timeCat',
      range: [0, 1],
      tickCount: period === 'last7Days' || nbDayBetweenRange <= 7 ? 7 : 5,
      showLast: true,
      label: {
        formatter: (date: string) =>
          formatChartDateWithUnit(unit, new Date(date), dateRange),
      },
    },
    yAxis: {
      grid: {
        line: {
          type: 'line',
          style: { lineWidth: 1, lineDash: [5] },
        },
      },
      label: {
        formatter: (text: string) =>
          renderDataWithSuffix(Number(text), currencyFormatter, hasSuffix),
      },
    },
    colorField: 'category',
    color: index % 2 ? ['#D9D9D9', '#6828A7'] : ['#D9D9D9', '#FF2E63'],
    connectNulls: false,
    lineShape: 'lineShape',
  };

  return (
    <div className="chartsContainer">
      <div className="chartsHeader">
        <div className="chartsTitle">
          {title}{' '}
          {explanation && (
            <AntdTooltip title={explanation} placement="top">
              <InfoCircleOutlined />
            </AntdTooltip>
          )}
        </div>
        <div className="chartsLegend" />
      </div>
      {hasNoData && !isFetching ? (
        <EmptyChart />
      ) : (
        <Line
          {...config}
          renderer="svg"
          legend={{
            padding: [16, 0, 24, 0],
            radio: { style: { opacity: 0 } },
            marker: { style: { cursor: 'pointer' } },
            itemName: { style: { cursor: 'pointer' } },
          }}
          tooltip={{
            enterable: true,
            domStyles: {
              'g2-tooltip': {
                padding: '16px',
                borderRadius: '8px',
              },
            },
            customContent: (_, items) => (
              <Tooltip
                title={title}
                items={formatChartItemsForTooltipData(items)}
                unit={unit}
                hasSuffix={hasSuffix}
                hasBadge
                hasColorMarker
                currencyFormatter={currencyFormatter}
              />
            ),
          }}
          annotations={[
            {
              type: 'line',
              start: [todayMarker, 'min'],
              end: [todayMarker, 'max'],
              isVertical: true,
              style: {
                stroke: '#666666',
              },
              text: {
                content: 'Today',
                autoRotate: false,
                position: 'end',
                offsetX: isTodayLastGraphPoint ? -30 : -15,
                offsetY: -5,
              },
            },
          ]}
        />
      )}
    </div>
  );
};
export default Chart;
