export type CustomTag = {
  readonly id: number;
  readonly name: string;
  readonly createdAt: string;
};

export function createCustomTag(values: any): CustomTag {
  const { id, name, createdAt } = values;

  return {
    id,
    name,
    createdAt,
  };
}
