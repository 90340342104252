import styled from 'styled-components';

const BookingPrice = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const BookingPriceLG = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const BookingPriceBold = styled.div`
  font-size: 18px !important;
  font-weight: 700;
  line-height: 24px;
`;

export default BookingPrice;
