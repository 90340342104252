import React, { FC } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import InitPassword from 'app/components/pages/PublicApp/InitPassword/InitPassword';

const PublicApp: FC = () => (
  <Switch>
    <Route path="/public/init-password" component={InitPassword} />
  </Switch>
);

export default withRouter(PublicApp);
