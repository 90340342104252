import { Form, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';

import { BannerInfo } from 'app/components/commons/BannerInfo/BannerInfo';
import CoverPicture from 'app/components/commons/CoverPicture/CoverPicture';
import { IEditoEventForm } from 'app/typings/edito';
import { maxCharactersRule, requiredRule } from 'app/utils/rules';
import { formatSlugTitle } from 'app/utils/string';

import { EditoContainer } from '../../../commons/Detail/EditoContainer/EditoContainer';
import { HeaderWrapper } from '../../../commons/Detail/HeaderWrapper/HeaderWrapper';

import './EditoEventMainInformation.scss';

const maxTitleCharacters = 50;
const maxCatchphraseCharacters = 120;

interface Props {
  currentTitle: string;
  initialEditoEventForm?: IEditoEventForm;
}

export const EditoEventMainInformation = ({
  currentTitle,
  initialEditoEventForm,
}: Props) => {
  const id = `-${initialEditoEventForm?.id ? initialEditoEventForm.id : 'id'}`;
  const formatTitle = formatSlugTitle(
    initialEditoEventForm?.title
      ? `${initialEditoEventForm.title}`
      : `${currentTitle ?? ''}`
  );
  const title = formatTitle ? `-${formatTitle}` : '';

  return (
    <EditoContainer
      title="Main informations"
      subTitle="Enter the main informations that will showcase your event"
    >
      <div className="editoEventMainInformation">
        <div className="titleContainer">
          <HeaderWrapper title="Title" maxCharacters={maxTitleCharacters}>
            <FormItem
              name="title"
              rules={[requiredRule, maxCharactersRule(maxTitleCharacters)]}
            >
              <Input placeholder="ex. Saint-Valentin" />
            </FormItem>
          </HeaderWrapper>
          <BannerInfo title="Slug name" subTitle={`edito-event${id}${title}`} />
        </div>
        <HeaderWrapper
          title="Catchphrase"
          maxCharacters={maxCatchphraseCharacters}
        >
          <FormItem
            name="catchphrase"
            rules={[requiredRule, maxCharactersRule(maxCatchphraseCharacters)]}
          >
            <Input placeholder="ex. Offrez-vous du temps love" />
          </FormItem>
        </HeaderWrapper>

        <HeaderWrapper title="Description">
          <FormItem name="description" rules={[requiredRule]}>
            <TextArea placeholder="Describe your event in few lines" />
          </FormItem>
        </HeaderWrapper>

        <HeaderWrapper title="Cover">
          <div className="imageRow">
            <Form.Item name="picture" rules={[requiredRule]}>
              <CoverPicture />
            </Form.Item>
          </div>
        </HeaderWrapper>
      </div>
    </EditoContainer>
  );
};
