import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import React from 'react';

import './Badge.scss';

type BadgeProps = {
  type: 'success' | 'danger' | 'neutral';
  value?: string;
  hasIcon?: boolean;
  isBold?: boolean;
  style?: React.CSSProperties;
};

export const Badge = ({
  value,
  type,
  style,
  hasIcon = false,
  isBold = false,
}: BadgeProps) => {
  return (
    <div className={classNames('badgeContainer', type)} style={style}>
      {hasIcon &&
        (type === 'success' ? (
          <ArrowUpOutlined />
        ) : type === 'danger' ? (
          <ArrowDownOutlined />
        ) : (
          <ArrowRightOutlined />
        ))}
      {value && (
        <div className={classNames(['title', { bold: isBold }])}>{value}</div>
      )}
    </div>
  );
};

export default Badge;
