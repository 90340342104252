import React from 'react';

import 'styles/layout/panel-header.scss';

type Props = {
  name: string;
  subTitle?: string;
  card?: boolean;
  actions?: React.ReactNode;
};

const PanelHeader = ({ name, card, actions, subTitle }: Props) => (
  <div className="panel-header">
    <div className="panel-header-titles">
      {card ? <h2>{name}</h2> : <h3>{name}</h3>}
      {subTitle && <h4>{subTitle}</h4>}
    </div>
    {actions && (
      <div className="panel__actions" onClick={(e) => e.stopPropagation()}>
        {actions}
      </div>
    )}
  </div>
);

export default PanelHeader;
