import { Picture } from 'app/redux/models/Picture/Picture';

export type RoomListElement = {
  readonly id: number;
  readonly name: string;
  readonly hotelId: number;
  readonly hotelName: string;
  readonly categoryName: string;
  readonly coverPicture: Picture;
  readonly countryId: number;
  readonly published: boolean;
  readonly updatedAt: string;
};

export function createRoomListElement(values: any): RoomListElement {
  const {
    id,
    name,
    hotelId,
    hotelName,
    categoryName,
    coverPicture,
    countryId,
    published,
    updatedAt,
  } = values;

  return {
    id,
    hotelId,
    hotelName,
    categoryName,
    name,
    coverPicture,
    published,
    countryId,
    updatedAt,
  };
}
