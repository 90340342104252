import { WarningOutlined } from '@ant-design/icons/lib';
import { Button, Modal, Popconfirm } from 'antd';
import React, { FC, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { DatePickerField } from 'app/components/fields';
import { trySaveEntity } from 'app/redux/actions/entities';
import { tryToggleSaleOpen } from 'app/redux/actions/sales';
import { entitySelector } from 'app/redux/selectors';
import { ISaleDates } from 'app/typings';
import { required } from 'app/utils/validate';

import 'styles/pages/Sales/edit-sale.scss';

import BookableDaysPickerField from './BookableDaysPicker';

const DATE_FORMAT = 'dddd DD MMMM HH:mm';

const SaleEditModal: FC<Props & InjectedFormProps<ISaleDates, Props>> = ({
  sale,
  editType,
  handleSubmit,
  submitting,
  submitSucceeded,
  toggleSaleOpen,
  onClose,
}) => {
  useEffect(() => {
    if (submitSucceeded) {
      onClose();
    }
  }, [onClose, submitSucceeded]); // TODO: redux-form's onSubmitSuccess prop doesn't work :/

  const country = useSelector(entitySelector('countries', sale.countryId));

  if (!editType) {
    return null;
  }

  return (
    <Modal
      width={800}
      visible={!!editType}
      okText="Save"
      okButtonProps={{ loading: submitting }}
      onOk={handleSubmit}
      onCancel={onClose}
      title={
        editType === 'sale' ? 'Edit current sale' : 'Edit extra inventory days'
      }
    >
      {editType === 'sale' ? (
        <>
          <DatePickerField
            label="Start"
            name="startDate"
            componentProps={{ format: DATE_FORMAT }}
            timezone={country.timezone}
            showTime
            disabled
          />
          <DatePickerField
            label="End"
            name="endDate"
            validate={required}
            componentProps={{ format: DATE_FORMAT }}
            timezone={country.timezone}
            showTime
          />
          <BookableDaysPickerField label="Bookable days" name="bookableDays" />
          <div className="edit-sale__close-btn">
            <Popconfirm
              title={`This will ${
                sale.open ? 'close' : 'open'
              } the current sale immediately. Sure?`}
              onConfirm={() => toggleSaleOpen(sale.id)}
            >
              <Button danger={sale.open}>
                <WarningOutlined />
                {sale.open ? (
                  <span>Close current sale</span>
                ) : (
                  <span>Open current sale</span>
                )}
              </Button>
            </Popconfirm>
          </div>
        </>
      ) : editType === 'extraDays' ? (
        <BookableDaysPickerField label="Extra inventory" name="extraDays" />
      ) : null}
    </Modal>
  );
};

type OwnProps = {
  editType?: 'sale' | 'extraDays';
  sale: ISaleDates;
  onClose: () => void;
};

type DispatchProps = {
  toggleSaleOpen: (saleId: number) => void;
};

type Props = OwnProps & DispatchProps;

const form = reduxForm<ISaleDates, Props>({})(SaleEditModal);

const mapStateToProps = (_state: any, props: any) => ({
  form: 'sale',
  initialValues: props.sale,
  onSubmit: (sale: ISaleDates) => trySaveEntity('sale', sale, 'list'),
  submitAsSideEffect: true,
  enableReinitialize: true,
});

const mapDispatchToProps = {
  toggleSaleOpen: (id: number) => tryToggleSaleOpen(id),
};

export default connect<{}, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(form);
