import { Badge, Button } from 'antd';

import Line from 'app/components/pages/GiftCards/details/Line/Line';
import { editBookingPublished } from 'app/redux/actions/booking';
import { useAppDispatch } from 'app/redux/hooks';
import type { Feedback as FeedbackType } from 'app/redux/models/BookingDetails/BookingDetails';
import { dateAndHour } from 'app/utils/dates';

type Props = {
  bookingCode: string;
  feedback: FeedbackType;
  onEdit: () => void;
};

const Feedback = ({ bookingCode, feedback, onEdit }: Props) => {
  const dispatch = useAppDispatch();

  const {
    experienceScore,
    serviceScore,
    vibeScore,
    roomScore,
    breakfastScore,
    globalScore,
    published,
    createdAt,
    id,
    comment,
  } = feedback;
  const created = dateAndHour(createdAt);

  return (
    <div className="bloc">
      <div className="header-title-button">
        <div className="title-with-button">Feedback</div>
        <div>
          <Button
            className="button"
            onClick={() =>
              dispatch(
                editBookingPublished({
                  bookingCode,
                  feedbackId: id,
                  published: !published,
                })
              )
            }
          >
            {`${published ? 'Unpublish' : 'Publish'}`}
          </Button>
          <Button className="button" onClick={onEdit}>
            Edit
          </Button>
        </div>
      </div>
      <div className="generic-content">
        <Line title="Feedback ID" value={id} />
        <Line
          title="Status"
          value={
            published ? (
              <Badge
                style={{ marginRight: 0 }}
                status="success"
                text="Published"
              />
            ) : (
              <Badge
                style={{ marginRight: 0 }}
                status="default"
                text="Unpublished"
              />
            )
          }
        />
        <Line title="Created at" value={created} />
        <Line title="Comment" value={comment} />
        <Line title="Global rating" value={globalScore} />
        <Line title="Experience" value={experienceScore} />
        <Line title="Service" value={serviceScore} />
        <Line title="Vibe" value={vibeScore} />
        <Line title="Room" value={roomScore} />
        <Line title="Breakfeast" value={breakfastScore} />
      </div>
    </div>
  );
};

export default Feedback;
