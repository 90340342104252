import { Modal } from 'antd';
import React, { FC } from 'react';
import { connect } from 'react-redux';

import Pic from 'app/components/commons/Pic';
import type { Picture } from 'app/components/commons/Uploader/Types';

import './EmojiModal.scss';

export const EmojiModal: FC<Props> = ({
  visible,
  onSelect,
  onCancel,
  emojis,
}) => {
  if (!emojis) {
    return null;
  }

  return (
    <Modal
      width={800}
      onCancel={onCancel}
      visible={visible}
      title="Choose emoji"
      centered
      footer={[]}
      className="emoji-modal"
    >
      <div className="emoji-modal__container">
        {emojis.map((emoji) => (
          <div
            onClick={() => onSelect(emoji)}
            key={emoji.pictureId}
            className="emoji-modal__thumbnail"
          >
            <Pic {...emoji} width={40} />
          </div>
        ))}
      </div>
    </Modal>
  );
};

type Props = {
  visible: boolean;
  onSelect: (emoji: Picture) => void;
  onCancel: () => void;
  emojis: Array<Picture>;
};

const mapStateToProps = (state: any) => ({
  emojis: state.pictures.emojis,
});

export default connect(mapStateToProps)(EmojiModal);
