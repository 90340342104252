import { Menu, Modal } from 'antd';
import _ from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import Uploader from 'app/components/commons/Uploader/Uploader';
import {
  tryAddHotelPictures,
  tryFetchHotelGallery,
  tryFetchHotelGalleryRooms,
  tryRemoveHotelPicture,
} from 'app/redux/actions/pictures';

import PictureGallery from '../PictureGallery/PictureGallery';
import { Picture } from '../Types';

import './GalleryModal.scss';

export const GalleryModal: FC<Props> = ({
  visible,
  title,
  subTitle,
  onOk,
  onCancel,
  okText,
  cancelText,
  hotelId,
  gallery,
  categories,
  kind,
  fetchRooms = false,
  fetchHotelGallery,
  fetchHotelGalleryRooms,
  addHotelPicture,
  removeHotelPicture,
  maxPictures,
}) => {
  const sections = useMemo(
    () => [...categories, { name: 'Upload', type: 'upload' }],
    [categories]
  );
  const [selectedCategory, setSelectedCategory] = useState<Category>(
    sections[0]
  );
  const [selectedPictures, setSelectedPictures] = useState<Array<Picture>>([]);
  const [uploadedPictures, setUploadedPictures] = useState<Array<Picture>>([]);

  useEffect(() => {
    if (hotelId && visible) {
      if (fetchRooms) {
        fetchHotelGalleryRooms(hotelId);
      } else {
        fetchHotelGallery(hotelId);
      }
    }

    if (!visible) {
      setSelectedPictures([]);
      setUploadedPictures([]);
    }
  }, [hotelId, visible, fetchRooms, fetchHotelGalleryRooms, fetchHotelGallery]);

  useEffect(() => {
    setSelectedPictures([]);
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedCategory(sections[0]);
  }, [sections]);

  const pictures = useMemo(() => {
    if (gallery && hotelId) {
      return gallery
        .filter((picture) => picture?.kind === selectedCategory.type)
        .map((picture) => ({
          ...picture,
          selected: !!selectedPictures.find((id) => id.id === picture.id),
        }));
    }

    return [];
  }, [gallery, selectedCategory.type, selectedPictures, hotelId]);

  const handleSelect = (pic: any) => {
    if (selectedPictures.find((p) => p.id === pic.id)) {
      setSelectedPictures(_.reject(selectedPictures, { id: pic.id }));
    } else {
      if (uploadedPictures.length) {
        setUploadedPictures([]);
      }

      if (maxPictures && selectedPictures.length >= maxPictures) {
        setSelectedPictures([
          ...selectedPictures.slice(0, selectedPictures.length - 1),
          pic,
        ]);
      } else {
        setSelectedPictures([...selectedPictures, pic]);
      }
    }
  };

  const onUploadAdd = (picture: any) => {
    setUploadedPictures((previousPictures) => {
      if (maxPictures && previousPictures.length >= maxPictures) {
        return [
          ...previousPictures.slice(0, previousPictures.length - 1),
          picture,
        ];
      }

      return [...previousPictures, picture];
    });

    if (hotelId) {
      addHotelPicture(hotelId, kind, picture);
    }
  };

  const onUploadDelete = (uid: any) => {
    const id = uploadedPictures.find(
      (pic) => `${pic.pictureId}-${pic.id}` === uid
    )?.id;

    setUploadedPictures(uploadedPictures.filter((pic) => pic.id !== id));

    if (hotelId && id) {
      removeHotelPicture(hotelId, id);
    }
  };

  return (
    <Modal
      width={800}
      visible={visible}
      title={`Add photos (${title})`}
      onOk={() => onOk([...selectedPictures, ...uploadedPictures])}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      className="gallery-modal"
      centered
    >
      <div className="gallery-modal__header-sub">{subTitle}</div>
      <Menu
        mode="horizontal"
        onClick={({ key }) => setSelectedCategory(sections[parseInt(key, 10)])}
        selectedKeys={[
          sections
            .findIndex((item) => item.type === selectedCategory.type)
            .toString(),
        ]}
        className="gallery-modal__menu-header"
      >
        {sections.map((item, index) => (
          <Menu.Item key={index}>{item.name}</Menu.Item>
        ))}
      </Menu>
      <div
        className={`gallery-modal__body ${
          selectedCategory.name !== 'Upload' && !pictures.length
            ? 'gallery-modal__body-empty'
            : ''
        }`}
      >
        {selectedCategory.name !== 'Upload' ? (
          <PictureGallery onSelect={handleSelect} pictures={pictures} />
        ) : (
          <Uploader
            pictures={uploadedPictures}
            add={onUploadAdd}
            remove={onUploadDelete}
            previewable
          />
        )}
      </div>
    </Modal>
  );
};

type Category = { name: string; type: string };

type Props = {
  visible: boolean;
  title: string;
  subTitle: string;
  onOk: (pictures: Array<Picture>) => void;
  onCancel: () => void;
  okText: string;
  cancelText: string;
  hotelId?: number;
  gallery: Array<Picture>;
  categories: Array<Category>;
  kind: string;
  fetchRooms?: boolean;
  fetchHotelGallery: (hotelId: number) => void;
  fetchHotelGalleryRooms: (hotelId: number) => void;
  addHotelPicture: (hotelId: number, kind: string, picture: Picture) => void;
  removeHotelPicture: (hotelId: number, id: number) => void;
  maxPictures?: number;
};

const mapStateToProps = (state: any) => ({
  gallery: state.pictures.hotelGallery,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchHotelGallery: (hotelId: any) => dispatch(tryFetchHotelGallery(hotelId)),
  fetchHotelGalleryRooms: (hotelId: any) =>
    dispatch(tryFetchHotelGalleryRooms(hotelId)),
  addHotelPicture: (hotelId: any, kind: any, picture: any) =>
    dispatch(tryAddHotelPictures(hotelId, kind, [picture])),
  removeHotelPicture: (hotelId: any, id: any) =>
    dispatch(tryRemoveHotelPicture(hotelId, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GalleryModal);
