export const HOTEL_POSITION_ENUM = [
  'General management',
  'Revenue management',
  'Sales and Marketing',
  'Operations',
  'Reception',
  'Reservations',
  'Bar and restaurant',
  'Spa',
  'Other',
];
export type HotelPosition = (typeof HOTEL_POSITION_ENUM)[number];
