/* eslint-disable max-len */
import { createAction } from '@reduxjs/toolkit';

import type { Hotel, NotifEmails } from 'app/redux/models/Hotel/Hotel';
import type { HotelBrief } from 'app/redux/models/HotelBrief/HotelBrief';
import type { HotelItem } from 'app/redux/models/HotelItem/HotelItem';
import type { NewHotel } from 'app/redux/models/NewHotel/NewHotel';

export const HOTELS_START_LOADING = 'HOTELS_START_LOADING';
export const HOTELS_STOP_LOADING = 'HOTELS_STOP_LOADING';
export const HOTELS_TRY_FETCH_HOTEL = 'HOTELS_TRY_FETCH_HOTEL';
export const HOTELS_FETCH_HOTEL_SUCCESS = 'HOTELS_FETCH_HOTEL_SUCCESS';
export const HOTELS_TRY_FETCH_HOTELS = 'HOTELS_TRY_FETCH_HOTELS';
export const HOTELS_FETCH_HOTELS_SUCCESS = 'HOTELS_FETCH_HOTELS_SUCCESS';
export const HOTELS_TRY_SAVE_HOTEL = 'HOTELS_TRY_SAVE_HOTEL';
export const HOTELS_TRY_SAVE_HOTEL_AND_REDIRECT =
  'HOTELS_TRY_SAVE_HOTEL_AND_REDIRECT';
export const HOTELS_TRY_DELETE_HOTEL = 'HOTELS_TRY_DELETE_HOTEL';
export const HOTELS_TRY_SAVE_BRIEF = 'HOTELS_TRY_SAVE_BRIEF';
export const HOTELS_TRY_DUPLICATE_HOTEL = 'HOTELS_TRY_DUPLICATE_HOTELS';
export const HOTELS_SAVE_BRIEF_SUCCESS = 'HOTELS_SAVE_BRIEF_SUCCESS';
export const HOTELS_TRY_SAVE_SPACES = 'HOTELS_TRY_SAVE_SPACES';
export const HOTELS_SAVE_SPACES_SUCCESS = 'HOTELS_SAVE_SPACES_SUCCESS';
export const HOTELS_TRY_SAVE_SERVICES = 'HOTELS_TRY_SAVE_SERVICES';
export const HOTELS_RESET_HOTEL = 'HOTELS_RESET_HOTEL';
export const RESET_NEW_HOTEL = 'RESET_NEW_HOTEL';
export const UPDATE_NEW_HOTEL = 'UPDATE_NEW_HOTEL';

type tryFetchHotelPayload = {
  hotelId: number;
};

type fetchHotelSuccessPayload = {
  hotel: Hotel;
};

type fetchHotelsSuccessPayload = {
  hotels: Array<HotelItem>;
};

type trySaveHotelPayload = {
  hotel: Hotel;
};

type trySaveHotelAndRedirectPayload = {
  hotel: Omit<NewHotel, 'notifEmails'> & {
    notifEmails: Array<NotifEmails>;
  };
};

type tryDeleteHotelPayload = {
  hotelId: number;
};

type trySaveBriefPayload = {
  hotelId: number;
  brief: HotelBrief;
};

// TODO WHAT IS THIS
type trySaveSpacesPayload = {
  hotelId: number;
  spaces: Array<any>;
};

// TODO WHAT IS THIS
type trySaveServicesPayload = {
  hotelId: number;
  services: any;
};

type updateNewHotelPayload = {
  newHotel: Partial<NewHotel>;
};

type tryDuplicateHotelPayload = {
  hotelId: number;
};

export const startLoading = createAction(HOTELS_START_LOADING);
export const stopLoading = createAction(HOTELS_STOP_LOADING);
export const tryFetchHotel = createAction<
  tryFetchHotelPayload,
  typeof HOTELS_TRY_FETCH_HOTEL
>(HOTELS_TRY_FETCH_HOTEL);
export const fetchHotelSuccess = createAction<
  fetchHotelSuccessPayload,
  typeof HOTELS_FETCH_HOTEL_SUCCESS
>(HOTELS_FETCH_HOTEL_SUCCESS);
export const tryFetchHotels = createAction(HOTELS_TRY_FETCH_HOTELS);
export const fetchHotelsSuccess = createAction<
  fetchHotelsSuccessPayload,
  typeof HOTELS_FETCH_HOTELS_SUCCESS
>(HOTELS_FETCH_HOTELS_SUCCESS);
export const trySaveHotel = createAction<
  trySaveHotelPayload,
  typeof HOTELS_TRY_SAVE_HOTEL
>(HOTELS_TRY_SAVE_HOTEL);
export const trySaveHotelAndRedirect = createAction<
  trySaveHotelAndRedirectPayload,
  typeof HOTELS_TRY_SAVE_HOTEL_AND_REDIRECT
>(HOTELS_TRY_SAVE_HOTEL_AND_REDIRECT);
export const tryDeleteHotel = createAction<
  tryDeleteHotelPayload,
  typeof HOTELS_TRY_DELETE_HOTEL
>(HOTELS_TRY_DELETE_HOTEL);
export const tryDuplicateHotel = createAction<
  tryDuplicateHotelPayload,
  typeof HOTELS_TRY_DUPLICATE_HOTEL
>(HOTELS_TRY_DUPLICATE_HOTEL);
export const trySaveBrief = createAction<
  trySaveBriefPayload,
  typeof HOTELS_TRY_SAVE_BRIEF
>(HOTELS_TRY_SAVE_BRIEF);
export const saveBriefSuccess = createAction(HOTELS_SAVE_BRIEF_SUCCESS);
export const trySaveSpaces = createAction<
  trySaveSpacesPayload,
  typeof HOTELS_TRY_SAVE_SPACES
>(HOTELS_TRY_SAVE_SPACES);
export const saveSpacesSuccess = createAction(HOTELS_SAVE_SPACES_SUCCESS);
export const trySaveServices = createAction<
  trySaveServicesPayload,
  typeof HOTELS_TRY_SAVE_SERVICES
>(HOTELS_TRY_SAVE_SERVICES);
export const resetHotel = createAction(HOTELS_RESET_HOTEL);

export const resetNewHotel = createAction(RESET_NEW_HOTEL);
export const updateNewHotel = createAction<
  updateNewHotelPayload,
  typeof UPDATE_NEW_HOTEL
>(UPDATE_NEW_HOTEL);
