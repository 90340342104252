import { Badge, Checkbox, Modal } from 'antd';
import React, { useState } from 'react';

import { SList, SListItem } from 'app/components/StaycationUI/List';
import { ExperienceType } from 'app/components/commons/Package/General/Types';
import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';

import './ExperienceModal.scss';

type ExperienceItemTitleProps = {
  experience: ExperienceType;
};

const ExperienceItemTitle = ({ experience }: ExperienceItemTitleProps) => {
  return (
    <div className="experienceItemTitle">
      {experience.title}
      {experience.published ? (
        <Badge status="success" text="Published" />
      ) : (
        <Badge status="warning" text="Unpublished" />
      )}
    </div>
  );
};

const ExperienceModal = ({
  visible,
  onSelect,
  onCancel,
  hotelExperiences,
  staycationExperiences,
}: Props) => {
  const [selectedExperiences, setSelectedExperiences] = useState<
    ExperienceType[]
  >([]);

  const handleSelect = (experience: ExperienceType) => {
    if (selectedExperiences.some((e) => e.id === experience.id)) {
      setSelectedExperiences(
        selectedExperiences.filter((e) => e.id !== experience.id)
      );
    } else {
      setSelectedExperiences([...selectedExperiences, experience]);
    }
  };

  const renderAvatar = (experience: ExperienceType) =>
    experience.avatar ? (
      <div className="experience-modal__item__avatar">
        <PictureComponent
          {...(experience.emoji || experience.avatar)}
          width={54}
        />
      </div>
    ) : null;

  const renderExperience = (experience: ExperienceType) => (
    <SListItem
      title={<ExperienceItemTitle experience={experience} />}
      description={experience.description}
      avatar={renderAvatar(experience)}
      actions={[
        <Checkbox
          checked={selectedExperiences.some((e) => e.id === experience.id)}
          key="checkbox"
        />,
      ]}
      onClick={() => handleSelect(experience)}
      className="experience-modal__item"
      key={experience.id}
    />
  );

  return (
    <Modal
      width={800}
      onOk={() => {
        onSelect(selectedExperiences);
        setSelectedExperiences([]);
        onCancel();
      }}
      okText="Save"
      onCancel={() => {
        onCancel();
        setSelectedExperiences([]);
      }}
      open={visible}
      title={`Choose experiences`}
      centered
      className="experience-modal"
    >
      <h3>Hotel experiences</h3>
      <SList
        data={hotelExperiences || []}
        renderItem={renderExperience}
        split={false}
      />
      <h3>Staycation experiences</h3>
      <SList
        data={staycationExperiences || []}
        renderItem={renderExperience}
        split={false}
      />
    </Modal>
  );
};

type Props = {
  visible: boolean;
  onSelect: (experiences: any) => void;
  onCancel: () => void;
  hotelExperiences?: Array<ExperienceType>;
  staycationExperiences?: Array<ExperienceType>;
};

export default ExperienceModal;
