import React from 'react';

import { GiftCard } from 'app/redux/models/GiftCard/GiftCard';

import EmailProperties from '../EmailProperties/EmailProperties';
import IncentiveProperties from '../IncentiveProperties/IncentiveProperties';
import PhysicalProperties from '../PhysicalProperties/PhysicalProperties';
import PrintProperties from '../PrintProperties/PrintProperties';
import VoucherProperties from '../VoucherProperties/VoucherProperties';

type Props = {
  giftCard: GiftCard;
};

export const PropertiesContent = ({ giftCard }: Props) => {
  if (
    giftCard.giftCardType === 'voucher' ||
    giftCard.giftCardType === 'internal'
  ) {
    return <VoucherProperties giftCard={giftCard} />;
  }

  if (giftCard.giftCardType === 'incentive') {
    return <IncentiveProperties giftCard={giftCard} />;
  }

  if (giftCard.giftCardType === 'email') {
    return <EmailProperties giftCard={giftCard} />;
  }

  if (giftCard.giftCardType === 'physical') {
    return <PhysicalProperties giftCard={giftCard} />;
  }

  return <PrintProperties giftCard={giftCard} />;
};

export default PropertiesContent;
