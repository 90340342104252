import { Button, Layout } from 'antd';
import React, { FC } from 'react';

import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';

import './Welcome.scss';

const { Content } = Layout;

const Welcome: FC<Props> = ({ handleNext }) => (
  <Layout className="welcome">
    <Content className="container">
      <div className="block">
        <div className="first-section">
          <PictureComponent
            className="picture"
            pictureId="static/emojis/emoji_waving_hand.png"
            alt="hello"
          />
          <div className="title">Welcome</div>
        </div>
        <div className="second-section">
          <div className="title">
            We are here to help you publish your hotel on Staycation.
          </div>
          <div className="description">
            Before you start, make sure you have all the information about your
            hotel, your rooms and your experiences, as well as all the photos
            that illustrate them. It will take about 20 minutes to complete.
          </div>
        </div>
        <div className="third-section">
          <Button type="primary" onClick={handleNext}>
            Start
          </Button>
        </div>
      </div>
    </Content>
  </Layout>
);

type Props = {
  handleNext: () => void;
};

export default Welcome;
