export type RoomCategory = {
  readonly id: number;
  readonly name: string;
};

export function createRoomCategory(values: any): RoomCategory {
  const { id, name } = values;

  return {
    id,
    name,
  };
}
