import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';

import { ISlide } from 'app/typings/slides';
import { apiRequest } from 'app/utils/request/api';

export const path = '/admin/slides/:clubId' as const;

export function useSlides(clubId: number) {
  return useQuery({
    queryKey: [path, { clubId }],
    queryFn: () =>
      apiRequest({ path, params: { clubId: clubId.toString() } }) as Promise<
        ISlide[]
      >,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}
