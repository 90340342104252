import { find, fromPairs, get, orderBy, values } from 'lodash';
import URLSearchParams from 'url-search-params';

export const entitiesSelector = (entity) => (state) =>
  orderBy(
    values(get(state.entities, entity, {})),
    ['priority', 'updatedAt'],
    ['asc', 'desc']
  );
export const entitySelector = (entity, id) => (state) =>
  get(state.entities, `${entity}.${id}`);
export const entityFormSelector = (entity, id) => (state) =>
  get(state.edition, `${entity}.${id}`);
export const isEntityReadySelector = (entity) => (state) =>
  get(state.navigation, `${entity}.ready`, false);

export const experienceCategorySelector = (id) => (state) => {
  const categories = entitiesSelector('experienceCategories')(state);
  let category = find(categories, { id });
  const categoryFamily = [category];

  while (category.parentId) {
    const parentCategory = find(categories, { id: category.parentId });

    categoryFamily.push(parentCategory);
    category = parentCategory;
  }

  return categoryFamily;
};

export const isReadySelector = (entity) => (state) =>
  get(state.navigation, `${entity}.ready`);
export const isLoadingSelector = (entity) => (state) =>
  get(state.navigation, `${entity}.loading`, false);

export const entityCountrySelector = (entity) => (state) => {
  if (!entity) {
    return undefined;
  }

  if (entity.countryId) {
    return entitySelector('countries', entity.countryId)(state);
  }

  if (entity.clubId) {
    const club = entitySelector('clubs', entity.clubId)(state);

    return entitySelector('countries', club.countryId)(state);
  }

  if (entity.hotelId) {
    const hotel = entitySelector('hotels', entity.hotelId)(state);

    return entitySelector('countries', hotel.countryId)(state);
  }

  return undefined;
};
export const localeSelector = (entity) => (state) => {
  const entityCountry = entityCountrySelector(entity)(state);

  if (entityCountry) {
    return entityCountry.locale;
  }

  return undefined;
};
export const currencySelector = (entity) => (state) => {
  // Used on table cells
  const entityCountry = entityCountrySelector(entity)(state);

  if (entityCountry) {
    return entityCountry.currency;
  }

  if (entity.accountId) {
    const payoutAccount = entitySelector(
      'payoutAccounts',
      entity.accountId
    )(state);

    return payoutAccount.currency;
  }

  return undefined;
};
export const currencySymbolSelector = (state) => {
  // Used for price inputs
  const currency = get(state.navigation, 'currency');

  return currency
    ? Number()
        .toLocaleString('en-US', { style: 'currency', currency })
        .slice(0, 1)
    : 'XXX';
};

export const searchParamsSelector = (state) =>
  get(state.router, 'location.search');
export const urlParamSelector = (key, defaultValue) => (state) =>
  new URLSearchParams(searchParamsSelector(state)).get(key) || defaultValue;
export const urlParamsSelector = (state) => {
  const urlParams = new URLSearchParams(searchParamsSelector(state));

  return fromPairs(Array.from(urlParams.entries()));
};
