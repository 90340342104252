import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  Layout,
  PageHeader,
  Pagination,
  Table,
  TablePaginationConfig,
} from 'antd';
import type { TableProps } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import { FilterValue } from 'antd/lib/table/interface';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { boolTextCell } from 'app/components/lists/cells';
import { useAdminUser } from 'app/hooks/data/useAdminUser';
import { useAdminHotels } from 'app/hooks/data/useHotels';
import { HotelItem } from 'app/redux/models/HotelItem/HotelItem';

import './index.scss';

const { Content } = Layout;
const { Column } = Table;

const PSP_ONBOARDING_DONE = [
  { text: 'Done', value: 'true' },
  { text: 'Not done', value: 'false' },
];

const LIMIT = 50;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: SortOrder;
  filters?: Record<string, FilterValue | null>;
}

const HotelsList = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { data: hotels, isLoading: loading } = useAdminHotels();
  const { data: admins, isLoading: adminsLoading } = useAdminUser();
  const [searchTerm, setSearchTerm] = useState<string>(
    searchParams.get('search') ?? ''
  );
  const [tableDataSize, setTableDataSize] = useState<number>(0);
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({ pspOnboardingDone: [true, false] });

  useEffect(() => {
    if (hotels && !loading) {
      setTableDataSize(hotels.length);
    }
  }, [hotels, loading]);

  const getInitialTableParams = () => {
    setFilteredInfo(
      searchParams.get('filters')
        ? JSON.parse(searchParams.get('filters') as string)
        : { pspOnboardingDone: filteredInfo.pspOnboardingDone }
    );

    return {
      pagination: {
        current: searchParams.get('page')
          ? parseInt(searchParams.get('page') as string, 10)
          : 1,
        total: tableDataSize,
        pageSize: LIMIT,
        showQuickJumper: false,
        showSizeChanger: false,
        showLessItems: true,
      },
      sortField: searchParams.get('sortField') || undefined,
      sortOrder: (searchParams.get('sortOrder') as SortOrder) || undefined,
      filters: searchParams.get('filters')
        ? JSON.parse(searchParams.get('filters') as string)
        : { pspOnboardingDone: filteredInfo.pspOnboardingDone },
    };
  };

  const [tableParams, setTableParams] = useState<TableParams>(
    getInitialTableParams
  );

  useEffect(() => {
    const params = new URLSearchParams({
      search: searchTerm || '',
      page: tableParams.pagination?.current?.toString() || '1',
      sortField: tableParams.sortField || '',
      sortOrder: tableParams.sortOrder || '',
      filters: JSON.stringify(tableParams.filters) || '{}',
    });

    history.replace({
      search: params.toString(),
    });
  }, [tableParams, searchTerm, history]);

  const handleRowActions = (hotel: any) => ({
    onClick: () => history.push(`/hotels/${hotel.id}/edit`),
  });

  const formatDate = (date: any) =>
    date && moment.utc(date).local().format('DD/MM/YYYY à HH:mm');

  const pagination = (className: any) => (
    <div className={className}>
      <Pagination
        {...tableParams.pagination}
        total={tableDataSize ?? 1}
        onChange={handleChangePage}
      />
    </div>
  );

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
            setTableDataSize(filteredHotels?.length ?? 0);
          }}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
      </div>
      {pagination('button-wrapper')}
    </div>
  );

  const newHotelsButton = (
    <Button
      type="primary"
      size="large"
      onClick={() => history.push('hotels/new')}
    >
      New
    </Button>
  );

  const handleTableChange: TableProps<HotelItem>['onChange'] = (
    pagination,
    filters,
    sorter,
    size
  ) => {
    if (!Array.isArray(sorter)) {
      setFilteredInfo(filters);
      setTableParams({
        pagination,
        filters,
        sortField: sorter?.field as string,
        sortOrder: sorter?.order as SortOrder,
      });
      setTableDataSize(size.currentDataSource.length || 0);
    }
  };

  const handleChangePage = (page: number) => {
    setTableParams((oldValue) => ({
      ...oldValue,
      pagination: {
        ...oldValue.pagination,
        current: page,
      },
    }));
  };

  const managerIds = hotels
    ?.map((obj) => obj.managerId)
    .filter((id) => id !== undefined);

  const filterOwner =
    admins
      ?.filter((user) => managerIds?.includes(user.id))
      ?.map((admin) => ({
        text:
          admin.firstName && admin.lastName
            ? `${admin.firstName} ${admin.lastName}`
            : admin.email,
        value: admin.id.toString(),
      })) ?? [];

  const sortOwner = (a: number, b: number) => {
    const personA = admins?.find((admin) => admin.id === a);
    const personB = admins?.find((admin) => admin.id === b);

    const isUndefinedA = !personA?.firstName || !personA?.lastName;
    const isUndefinedB = !personB?.firstName || !personB?.lastName;

    if (!isUndefinedA && !isUndefinedB) {
      const fullNameA = `${personA?.firstName} ${personA?.lastName}`;
      const fullNameB = `${personB?.firstName} ${personB?.lastName}`;

      return fullNameA.localeCompare(fullNameB);
    }

    if (isUndefinedA && !isUndefinedB) {
      return 1;
    }

    if (!isUndefinedA && isUndefinedB) {
      return -1;
    }

    return 0;
  };

  const renderOwner = (id: number) => {
    const admin = admins?.find((admin) => admin.id === id);

    if (admin) {
      return admin.firstName && admin.lastName
        ? `${admin.firstName} ${admin.lastName}`
        : admin.email;
    }

    return '';
  };

  const filteredHotels = hotels
    ?.sort((a: HotelItem, b: HotelItem) => {
      let comparison = 0;

      if (tableParams.sortField && tableParams.sortOrder) {
        const fieldA = a[tableParams.sortField as keyof HotelItem];
        const fieldB = b[tableParams.sortField as keyof HotelItem];

        if (fieldA && fieldB) {
          comparison = fieldA > fieldB ? 1 : fieldA < fieldB ? -1 : 0;

          return tableParams.sortOrder === 'ascend' ? comparison : -comparison;
        } else if (fieldA && !fieldB) {
          return tableParams.sortOrder === 'ascend' ? -1 : 1;
        } else if (!fieldA && fieldB) {
          return tableParams.sortOrder === 'ascend' ? 1 : -1;
        }
      }

      return comparison;
    })
    .filter((hotel) => {
      if (!searchTerm) return true;

      const lowerSearchTerm = searchTerm.toLowerCase();
      const hotelName = `${hotel.name || ''}`.toLowerCase();
      const hotelId = `${hotel.id || ''}`.toLowerCase();
      const manager = renderOwner(hotel.managerId).toLowerCase();

      return (
        hotelName.includes(lowerSearchTerm) ||
        hotelId.includes(lowerSearchTerm) ||
        manager.includes(lowerSearchTerm)
      );
    });

  return (
    <Layout className="page-list">
      <PageHeader className="header" title="Hotels" extra={newHotelsButton} />
      <Content className="body">
        <Table
          dataSource={filteredHotels}
          pagination={{ ...tableParams.pagination, total: tableDataSize }}
          title={header}
          loading={loading || adminsLoading}
          rowKey={(record) => record.id}
          onRow={handleRowActions}
          onChange={handleTableChange}
        >
          <Column
            key="name"
            title="Name"
            dataIndex="name"
            defaultSortOrder={
              tableParams.sortField === 'name'
                ? tableParams.sortOrder
                : undefined
            }
            sorter={(a: HotelItem, b: HotelItem) =>
              a.name.localeCompare(b.name)
            }
          />
          <Column
            key="id"
            title="Id"
            dataIndex="id"
            sorter={(a: HotelItem, b: HotelItem) => a.id - b.id}
            defaultSortOrder={
              tableParams.sortField === 'id' ? tableParams.sortOrder : undefined
            }
          />
          <Column
            key="owner"
            title="Owner"
            dataIndex="managerId"
            defaultSortOrder={
              tableParams.sortField === 'managerId'
                ? tableParams.sortOrder
                : undefined
            }
            sorter={(a: HotelItem, b: HotelItem) =>
              sortOwner(a.managerId, b.managerId)
            }
            render={(adminId) => renderOwner(adminId)}
            filters={[...filterOwner, { text: 'No owner', value: 'noOwner' }]}
            filteredValue={filteredInfo.owner || null}
            onFilter={(value: string | number | boolean, record: HotelItem) => {
              if (value === 'noOwner') {
                return record.managerId === undefined;
              }

              if (record.managerId && record.managerId.toString() === value) {
                return true;
              }

              return false;
            }}
            defaultFilteredValue={
              tableParams.sortField === 'owner'
                ? tableParams.filters?.owner
                : null
            }
            filterSearch={true}
          />
          <Column
            key="pspOnboardingDone"
            title="Stripe onboarding"
            dataIndex="pspOnboardingDone"
            sorter={(a: HotelItem, b: HotelItem) =>
              (b.pspOnboardingDone ? 1 : -1) - (a.pspOnboardingDone ? 1 : -1)
            }
            defaultSortOrder={
              tableParams.sortField === 'pspOnboardingDone'
                ? tableParams.sortOrder
                : undefined
            }
            filters={PSP_ONBOARDING_DONE}
            defaultFilteredValue={
              tableParams.sortField === 'pspOnboardingDone'
                ? tableParams.filters?.pspOnboardingDone
                : null
            }
            filteredValue={filteredInfo.pspOnboardingDone || null}
            onFilter={(value: string | number | boolean, record: HotelItem) => {
              if (value === 'true') {
                return record.pspOnboardingDone === true;
              } else if (value === 'false') {
                return record.pspOnboardingDone === false;
              }

              return true;
            }}
            render={boolTextCell('Done', 'Not done')}
          />
          <Column
            key="updatedAt"
            title="Last modified"
            dataIndex="updatedAt"
            sorter={(a: HotelItem, b: HotelItem) =>
              new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
            }
            render={formatDate}
            defaultSortOrder={
              tableParams.sortField === 'updatedAt'
                ? tableParams.sortOrder
                : undefined
            }
          />
        </Table>
      </Content>
    </Layout>
  );
};

export default HotelsList;
