import { useMutation } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';

import { apiMutation } from 'app/utils/request/api';

type InitPassword = {
  email: string;
  role: string;
  password: string;
  token: string;
  redirect: string;
};

const postPath = '/admin/init-password' as const;

export function useInitHotelUserPassword() {
  const history = useHistory();

  return useMutation({
    mutationFn: async (payload: InitPassword) =>
      apiMutation(
        'POST',
        {
          path: postPath,
        },
        payload
      ) as Promise<void>,
    onSuccess: (_, payload) => {
      const url = new URL(payload.redirect);

      history.push(url.pathname);
    },
  });
}
