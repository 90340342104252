import _ from 'lodash';
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { FieldArray, InjectedFormProps, reduxForm } from 'redux-form';

import { InputField, TextAreaField } from 'app/components/fields';
import FormPage from 'app/components/forms/FormPage';
import FormSection from 'app/components/forms/FormSection';
import Card from 'app/components/layout/Card';
import { tryGetEntity, trySaveEntity } from 'app/redux/actions/entities';
import { entityFormSelector } from 'app/redux/selectors';
import { IMerchPackage } from 'app/typings';
import { required } from 'app/utils/validate';

import Experiences from './Experiences';

const MerchPackageForm: FC<Props & InjectedFormProps<IMerchPackage, Props>> = ({
  packageId,
  getPackage,
  ...formProps
}) => {
  useEffect(() => {
    getPackage();
  }, [getPackage]);

  return (
    <FormPage title={`Edit Package #${packageId} copywriting`} {...formProps}>
      <Card title="Copywriting" formOnly>
        <FormSection title="Package merch">
          <InputField
            label="Value proposition"
            name="valueProposition"
            placeholder="Discover a rodific pool in your neighborhood"
            validate={required}
          />
          <TextAreaField
            label="Introduction"
            name="highlight"
            placeholder="All hail the new royal couple! Tip your hat to the butler and step into this majestic palace..."
            validate={required}
            autosize
          />
        </FormSection>
        <FormSection title="Experiences merch">
          <FieldArray name="experiences" component={Experiences} props={{}} />
        </FormSection>
      </Card>
    </FormPage>
  );
};

const mapStateToProps = (state: any, props: any) => {
  const packageId = _.get(props.match, 'params.id');

  return {
    form: 'merchPackages',
    packageId,
    initialValues: entityFormSelector('merchPackages', packageId)(state),
    onSubmit: (values: any) => trySaveEntity('merchPackages', values),
    submitAsSideEffect: true,
  };
};

const mapDispatchToProps = (dispatch: any, props: any) => {
  const packageId = _.get(props.match, 'params.id');

  return {
    getPackage: () => dispatch(tryGetEntity('merchPackages', packageId)),
  };
};

type Props = {
  packageId: number;
  getPackage: () => void;
};

const form = reduxForm<IMerchPackage, Props>({})(MerchPackageForm);

export default connect(mapStateToProps, mapDispatchToProps)(form);
