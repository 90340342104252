import { Layout, PageHeader } from 'antd';
import { useEffect, useState } from 'react';

import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import Spinner from 'app/components/commons/Spinner';
import {
  clearBookingDetailsState,
  getBookingDetails,
  refundBooking,
  resendBookingConfirmation,
  resendBookingHotelConfirmation,
} from 'app/redux/actions/booking';
import { editBookingReview } from 'app/redux/actions/booking';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import type {
  Feedback as FeedbackType,
  Refund,
} from 'app/redux/models/BookingDetails/BookingDetails';

import 'styles/pages/Booking/booking-details.scss';

import Contact from './Contact/Contact';
import EditButton from './EditButton/EditButton';
import EditEmailModal from './EditEmailModal/EditEmailModal';
import Feedback from './Feedback/Feedback';
import Guest from './Guest/Guest';
import Payment from './Payment/Payment';
import RefundModal from './RefundModal/RefundModal';
import Refunds from './Refunds/Refunds';
import Reservation from './Reservation/Reservation';
import ReviewEditModal from './ReviewEditModal/ReviewEditModal';
import Summary from './Summary/Summary';
import User from './User/User';
import Voucher from './Voucher/Voucher';
import VoucherModal from './VoucherModal/VoucherModal';

const BookingDetails = ({ history, match }: Props) => {
  const { bookingCode } = match.params;
  const dispatch = useAppDispatch();

  const bookingDetails = useAppSelector(
    (state) => state.booking.bookingDetails
  );
  const isLoading: boolean = useAppSelector((state) => state.booking.isLoading);

  useEffect(() => {
    if (bookingCode) {
      dispatch(getBookingDetails({ bookingCode }));
    }
  }, [dispatch, bookingCode]);

  useEffect(
    () => () => {
      dispatch(clearBookingDetailsState());
    },
    [dispatch]
  );

  const onRefundBooking = (values: Refund) => {
    dispatch(refundBooking({ bookingCode, body: values }));
  };

  const resendConfirmationMail = () => {
    if (bookingCode) {
      dispatch(resendBookingConfirmation({ bookingCode }));
    }
  };

  const resendHotelConfirmationMail = () => {
    if (bookingCode) {
      dispatch(resendBookingHotelConfirmation({ bookingCode }));
    }
  };

  const [refundModalVisible, setRefundModalVisible] = useState(false);
  const [feedbackInModal, setFeedbackInModal] = useState<
    FeedbackType | undefined
  >(undefined);
  const [resendModalVisible, setResendModalVisible] = useState(false);
  const [resendHotelModalVisible, setResendHotelModalVisible] = useState(false);
  const [isVoucherModalVisible, setIsVoucherModalVisible] = useState(false);
  const [isEditEmailModalVisible, setIsEditEmailModalVisible] = useState(false);

  const back = () => {
    history.goBack();
  };

  if (
    bookingDetails === null ||
    bookingDetails.bookingSummary.code !== bookingCode
  ) {
    return null;
  }

  const { bookingSummary, refunds, feedbacks } = bookingDetails;
  const { code, customer } = bookingSummary;

  return (
    <Layout className="booking-details">
      <Spinner spinning={isLoading}>
        <PageHeader
          className="header"
          onBack={back}
          title={`Booking ${code}`}
          extra={
            <EditButton onClick={() => setIsEditEmailModalVisible(true)} />
          }
        />
        <CurrencyWrapper countryId={bookingSummary.countryId}>
          <div className="body">
            <div className="left-part">
              <User bookingDetails={bookingDetails} />
              <Guest customer={customer} />
              <Contact customer={customer} />
            </div>
            <div className="right-part">
              <Reservation
                bookingSummary={bookingSummary}
                resendModalVisible={resendModalVisible}
                resendConfirmationMail={resendConfirmationMail}
                setResendModalVisible={setResendModalVisible}
                resendHotelModalVisible={resendHotelModalVisible}
                resendHotelConfirmationMail={resendHotelConfirmationMail}
                setResendHotelModalVisible={setResendHotelModalVisible}
              />
              {refundModalVisible && (
                <RefundModal
                  bookingCode={bookingCode}
                  title="Refund Booking"
                  onOk={onRefundBooking}
                  onCancel={() => setRefundModalVisible(false)}
                />
              )}
              <Summary bookingSummary={bookingSummary} />
              <Payment
                setIsVoucherModalVisible={setIsVoucherModalVisible}
                bookingDetails={bookingDetails}
                setRefundModalVisible={setRefundModalVisible}
              />
              <Voucher setIsVoucherModalVisible={setIsVoucherModalVisible} />
              <VoucherModal
                isVisible={isVoucherModalVisible}
                setIsVoucherModalVisible={setIsVoucherModalVisible}
                bookingDetails={bookingDetails}
              />
              {feedbacks.map((feedback) => (
                <Feedback
                  bookingCode={bookingCode}
                  feedback={feedback}
                  onEdit={() => setFeedbackInModal(feedback)}
                />
              ))}
              {refunds && !!refunds.length && <Refunds refunds={refunds} />}
              <EditEmailModal
                email={customer.email}
                visible={isEditEmailModalVisible}
                onCancel={() => setIsEditEmailModalVisible(false)}
              />
            </div>
          </div>
        </CurrencyWrapper>
      </Spinner>
      {feedbackInModal && (
        <ReviewEditModal
          visible
          review={feedbackInModal?.comment || ''}
          onCancel={() => setFeedbackInModal(undefined)}
          onSubmit={(review) => {
            if (!feedbackInModal) {
              return;
            }

            dispatch(
              editBookingReview({
                bookingCode,
                feedbackId: feedbackInModal.id,
                review,
              })
            );
            setFeedbackInModal(undefined);
          }}
        />
      )}
    </Layout>
  );
};

type Props = {
  history: any;
  match: {
    params: {
      bookingCode: string;
    };
  };
};

export default BookingDetails;
