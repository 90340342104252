export const createEditoEventPath = '/edito/events' as const;
export const deleteCreateEditoEventPath = '/edito/events/:id' as const;
export const finishCreateEditoEventPath = '/edito/events/:id/finish' as const;
export const getEditoEventDetailPath = '/edito/events/:id' as const;
export const getEditoEventListPath = '/edito/events' as const;
export const publishEditoEventPath = '/edito/events/:id/publish' as const;
export const unpublishEditoEventPath = '/edito/events/:id/unpublish' as const;
export const updateEditoEventPath = '/edito/events/:id' as const;
export const displayOrderEditoEventPath =
  '/edito/events/display-order' as const;
