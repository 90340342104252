import { List } from 'antd';
import React, { FC, ReactNode } from 'react';

import './SListItem.scss';

export const SListItem: FC<Props> = ({
  title,
  description,
  avatar,
  actions,
  onClick,
  className = '',
}) => (
  <List.Item
    className={`slist-item ${className}`}
    actions={actions}
    onClick={onClick}
  >
    <List.Item.Meta avatar={avatar} title={title} description={description} />
  </List.Item>
);

type Props = {
  title: string | ReactNode;
  description?: string | ReactNode;
  avatar?: ReactNode;
  actions?: Array<ReactNode>;
  onClick?: () => void;
  className?: string;
};

export default SListItem;
