export const scrollToElement = (
  ref: React.MutableRefObject<Element | null>
) => {
  if (ref.current) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
    });
  }
};

export const scrollToTop = () => window.scrollTo({ left: 0, top: 0 });
