import React from 'react';

import Spinner from 'app/components/commons/Spinner';
import ClubListPageWithProps from 'app/components/lists/ClubListPageWithProps';
import { useClubs } from 'app/hooks/data/useClubs';
import { useSlides } from 'app/hooks/data/useSlides';
import { useSlidesSave } from 'app/hooks/data/useSlidesSave';
import { ISlideSavePayload } from 'app/typings/slides';
import { SlideType } from 'app/typings/slides';
import { assertNever } from 'app/utils/typing';

import { SliderForm } from './SliderForm';
import { SliderFormValidValues } from './types';

export const Slider = () => {
  const clubs = useClubs();

  return (
    <ClubListPageWithProps
      title="Slider"
      clubs={clubs.data}
      withContentWrapper={false}
    >
      {({ clubId }) => (
        <ClubSlider
          // Re-mount the component when clubId changes.
          key={clubId}
          clubId={clubId}
        />
      )}
    </ClubListPageWithProps>
  );
};

export default Slider;

type ClubSliderProps = {
  clubId: number;
};

const ClubSlider = ({ clubId }: ClubSliderProps) => {
  const slides = useSlides(clubId);

  const slidesSave = useSlidesSave(clubId);

  if (!slides.data || slides.isFetching) {
    return <Spinner spinning />;
  }

  const getSlideTypeProperties = (
    slide: SliderFormValidValues['slides'][number]
  ) => {
    if (slide.slideType === SlideType.PACKAGE) {
      return { packageId: slide.pkg.id };
    }

    if (slide.slideType === SlideType.COLLECTION) {
      return { collectionId: slide.collection.id };
    }

    if (slide.slideType === SlideType.EDITO) {
      return {
        appLink: JSON.stringify(slide.appLink),
        webLink: JSON.stringify(slide.webLink),
        category: slide.category,
      };
    }

    assertNever(slide);
  };

  // We wait for data to be loaded before rendering the form.
  // Otherwise, the form is initialised with empty values and it often can cause bugs.
  return (
    <SliderForm
      clubId={clubId}
      initialValues={{ slides: slides.data }}
      onFinish={(formValues: SliderFormValidValues) => {
        const payload: ISlideSavePayload[] = formValues.slides.map(
          (slide, index) => ({
            id: slide.id,
            title: slide.title,
            picture: slide.picture,
            slideType: slide.slideType,
            priority: index + 1,
            ...getSlideTypeProperties(slide),
          })
        );

        slidesSave.mutate(payload);
      }}
      isSubmitting={slidesSave.isLoading}
    />
  );
};
