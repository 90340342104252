/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Radio, TimePicker } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DurationDescription } from 'app/components/commons/Experience/Types';
import EmojiModal from 'app/components/commons/Package/Headlines/EmojiModal/EmojiModal';
import Pic from 'app/components/commons/Pic';
import { parseOpeningHoursFromAPI } from 'app/components/commons/TimeRangeSection/utils';
import { Option, Select } from 'app/components/fields/Select';
import { tryFetchExperiencesBrands } from 'app/redux/actions/experiences';
import { tryFetchHotels } from 'app/redux/actions/hotels';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { Experience } from 'app/redux/models/Experience/Experience';
import { ExperienceCategory } from 'app/redux/models/ExperienceCategory/ExperienceCategory';
import { selectCountries } from 'app/redux/selectors/conf';
import { getHotelsHotels } from 'app/redux/selectors/hotels';

import BrandSelect from './BrandSelect/BrandSelect';
import Cascada from './Cascada/Cascada';
import HotelSelect from './HotelSelect/HotelSelect';

const RULES = [{ required: true, message: 'Required' }];

const BasicInfo = ({
  isBuilder,
  disabled,
  mode = 'edit',
  values,
  setFieldsValue,
  experienceCategories,
  category,
  experienceType,
  setExperienceType,
  isOriginal,
}: Props) => {
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [isHover, setIsOver] = useState(false);
  const dispatch = useAppDispatch();
  const countries = useAppSelector(selectCountries);
  const hotels = useAppSelector(getHotelsHotels);
  const experiencesBrands = useAppSelector(
    (state) => state.experiences.experiencesBrands
  );
  const fetchExperiencesBrands = useMemo(
    () => _.debounce(() => dispatch(tryFetchExperiencesBrands('')), 800),
    [dispatch]
  );

  useEffect(() => {
    if (!isBuilder) {
      dispatch(tryFetchHotels());
    }
  }, [dispatch, isBuilder]);

  const displayDurationDescription = (
    description: DurationDescription | string | null
  ) => {
    if (description === null) {
      return '';
    }

    return description;
  };

  const durationDescriptionOption = (
    Object.keys(DurationDescription) as Array<keyof typeof DurationDescription>
  ).map((key) => (
    <Option key={key} value={DurationDescription[key]}>
      {DurationDescription[key]}
    </Option>
  ));

  const onEmojiSelect = useCallback(
    (emoji: any) => {
      if (!values) {
        return;
      }

      setFieldsValue({
        ...values,
        emoji,
      });
      setIsEmojiOpen(false);
    },
    [values, setFieldsValue]
  );

  useEffect(() => {
    if (category?.brand) {
      fetchExperiencesBrands();
    }
  }, [fetchExperiencesBrands, category]);

  const handleExperienceType = (event: any) =>
    setExperienceType(event?.target?.value);

  return (
    <>
      {!isBuilder && (
        <div className="input-row">
          <Form.Item label="Experience type">
            <Radio.Group
              value={experienceType}
              onChange={handleExperienceType}
              disabled={disabled}
            >
              <Radio value={1}>Hotel</Radio>
              <Radio value={2}>Staycation</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      )}
      {!isBuilder && !isOriginal && hotels && values ? (
        <HotelSelect
          hotels={hotels}
          hotelId={values.hotelId}
          mode={mode}
          disabled={disabled}
          onChange={(newValue) => {
            setFieldsValue({
              ...values,
              hotelId: newValue,
              spaceIds: [],
              accessMode: null,
              openingHours: parseOpeningHoursFromAPI(undefined),
              roomServiceAvailable: false,
              roomServiceIncluded: false,
              roomServicePrice: null,
              pictures: [],
              price: 0,
            });
          }}
        />
      ) : null}
      {!isBuilder && isOriginal ? (
        <div className="input-row">
          <Form.Item label="Country" name="countryId" rules={RULES}>
            <Select
              placeholder="Country"
              disabled={disabled || mode === 'view'}
            >
              {countries.map((country) => (
                <Option key={country.id} value={country.id}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      ) : null}
      {!isBuilder && (
        <div className="input-row">
          <Form.Item label="Category" name="categoryId" rules={RULES}>
            <Cascada
              dataSource={experienceCategories}
              onChange={(newValue) => {
                setFieldsValue({
                  ...values,
                  categoryId: newValue,
                  brand: null,
                  duration: null,
                  durationDescription: null,
                  time: null,
                  quantity: null,
                  quantityDescription: null,
                  locations: null,
                  description: null,
                  emoji: null,
                });
              }}
              mode={mode}
            />
          </Form.Item>
        </div>
      )}
      {!!category && (
        <>
          {!isBuilder && (
            <div className="input-row">
              <Form.Item
                label="Emoji"
                name="emoji"
                rules={RULES}
                valuePropName="key"
              >
                <div className="experience__avatar-container">
                  <div
                    onClick={
                      mode === 'edit' ? () => setIsEmojiOpen(true) : undefined
                    }
                    className={`experience__avatar ${
                      mode === 'edit' ? 'experience__avatar-clickable' : ''
                    }`}
                    key={values?.emoji?.pictureId}
                  >
                    {values?.emoji && <Pic {...values.emoji} width={40} />}
                  </div>
                  {mode === 'edit' && (
                    <div
                      onMouseLeave={() => setIsOver(false)}
                      onMouseOver={() => setIsOver(true)}
                      className="experience__avatar__hover-area"
                    >
                      {!!isHover && (
                        <div className="upload-item__hover-buttons">
                          <EditOutlined onClick={() => setIsEmojiOpen(true)} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Form.Item>
              <EmojiModal
                visible={isEmojiOpen}
                onSelect={onEmojiSelect}
                onCancel={() => setIsEmojiOpen(false)}
              />
            </div>
          )}
          {!!category.brand && (mode !== 'view' || !!values.brand) && (
            <div className="input-row">
              <Form.Item label="Brand" name="brand">
                {mode !== 'view' ? (
                  <BrandSelect experiencesBrands={experiencesBrands} />
                ) : (
                  <div>{values.brand || ''}</div>
                )}
              </Form.Item>
              {mode !== 'view' && (
                <div className="description">
                  <InfoCircleOutlined />
                  <div>
                    If applicable, it will be highlighted in the experience
                    title
                  </div>
                </div>
              )}
            </div>
          )}
          {!!category.duration &&
            (mode !== 'view' ||
              (!!values.duration && !!values.durationDescription)) && (
              <div className="input-row">
                <Form.Item label="Duration">
                  <div className="FIELD">
                    {mode !== 'view' ? (
                      <div className="not-price-kind">
                        <Form.Item noStyle name="duration">
                          <InputNumber
                            value={values.duration}
                            min={1}
                            onChange={(duration) => {
                              if (duration === null) {
                                setFieldsValue({
                                  ...values,
                                  duration: null,
                                  durationDescription: null,
                                });
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item noStyle name="durationDescription">
                          <Select
                            showSearch
                            relativePopupContainer={(node) =>
                              node.parentNode.parentNode
                            }
                            value={
                              values.durationDescription === null
                                ? 'empty'
                                : values.durationDescription
                            }
                            onSelect={(durationDescription) => {
                              if (durationDescription === 'empty') {
                                setFieldsValue({
                                  ...values,
                                  duration: null,
                                  durationDescription: null,
                                });
                              }
                            }}
                          >
                            <Option key={'empty'} value={'empty'}>
                              {' '}
                            </Option>
                            {durationDescriptionOption}
                          </Select>
                        </Form.Item>
                      </div>
                    ) : (
                      <div>
                        {`${values.duration || 0} ${displayDurationDescription(
                          values.durationDescription
                        )}`}
                      </div>
                    )}
                  </div>
                </Form.Item>
                {mode !== 'view' && (
                  <div className="description">
                    <InfoCircleOutlined />
                    <div>
                      If applicable, it will be highlighted in the experience
                      title
                    </div>
                  </div>
                )}
              </div>
            )}
          {!!category.time && (mode !== 'view' || !!values.time) && (
            <div className="input-row">
              <Form.Item label="Time" name="time">
                {mode !== 'view' ? (
                  <TimePicker format="HH:mm" />
                ) : (
                  <div>
                    {moment.isMoment(values.time)
                      ? values.time.format('HH:mm')
                      : values.time || ''}
                  </div>
                )}
              </Form.Item>
              {mode !== 'view' && (
                <div className="description">
                  <InfoCircleOutlined />
                  <div>
                    If applicable, it will be highlighted in the experience
                    title
                  </div>
                </div>
              )}
            </div>
          )}
          {!!category.quantityTitle &&
            (mode !== 'view' ||
              (!!values.quantity && !!values.quantityDescription)) && (
              <div className="input-row">
                <Form.Item label="Quantity">
                  <div className="FIELD">
                    {mode !== 'view' ? (
                      <div className="not-price-kind">
                        <Form.Item noStyle name="quantity">
                          <InputNumber min={1} />
                        </Form.Item>
                        {!!category.quantityDescription && (
                          <Form.Item noStyle name="quantityDescription">
                            <Select
                              showSearch
                              relativePopupContainer={(node) =>
                                node.parentNode.parentNode
                              }
                            >
                              <Option key="empty" value="empty">
                                {' '}
                              </Option>
                              <Option key="per pers" value="per pers">
                                per pers
                              </Option>
                              <Option key="per stay" value="per stay">
                                per stay
                              </Option>
                            </Select>
                          </Form.Item>
                        )}
                      </div>
                    ) : (
                      <div>
                        {`${values.quantity || 0} ${
                          values.quantityDescription
                        }`}
                      </div>
                    )}
                  </div>
                </Form.Item>
                {mode !== 'view' && (
                  <div className="description">
                    <InfoCircleOutlined />
                    <div>
                      If applicable, it will be highlighted in the experience
                      title
                    </div>
                  </div>
                )}
              </div>
            )}
          {!!category.locations && (mode !== 'view' || !!values.locations) && (
            <div className="input-row">
              <Form.Item label="Location" name="locations">
                {mode !== 'view' ? (
                  <Input />
                ) : (
                  <div>{values.locations || ''}</div>
                )}
              </Form.Item>
              {mode !== 'view' && (
                <div className="description">
                  <InfoCircleOutlined />
                  <div>
                    If applicable, it will be highlighted in the experience
                    title
                  </div>
                </div>
              )}
            </div>
          )}
          {(mode !== 'view' || !!values.description) && (
            <div className="input-row">
              <Form.Item label="Description" name="description">
                {mode !== 'view' ? (
                  <Input.TextArea rows={4} />
                ) : (
                  <div className="view-text__area">
                    {values.description || ''}
                  </div>
                )}
              </Form.Item>
              {mode !== 'view' && (
                <div className="description">
                  <InfoCircleOutlined />
                  <div>Any useful information about this experience</div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

type Props = {
  isBuilder?: boolean;
  disabled: boolean;
  mode?: string;
  values: Experience;
  setFieldsValue: (newValues: Experience) => void;
  experienceCategories: ExperienceCategory[];
  category?: ExperienceCategory;
  experienceType: number;
  setExperienceType: (newType: number) => void;
  isOriginal: boolean;
};

export default BasicInfo;
