import { Checkbox, Modal } from 'antd';
import React, { useState } from 'react';

import { SList, SListItem } from 'app/components/StaycationUI/List';
import { RoomType } from 'app/components/commons/Package/General/Types';
import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';

import './RoomModal.scss';

const RoomModal = ({ visible, onSelect, onCancel, allRooms }: Props) => {
  const [selectedRooms, setSelectedRooms] = useState<RoomType[]>([]);

  if (!allRooms) {
    return null;
  }

  const handleSelect = (room: any) => {
    if (selectedRooms.some((e) => e.id === room.id)) {
      setSelectedRooms(selectedRooms.filter((e) => e.id !== room.id));
    } else {
      setSelectedRooms([...selectedRooms, room]);
    }
  };

  const renderAvatar = (room: any) =>
    room.avatar ? (
      <div className="room-modal__item__avatar">
        <PictureComponent {...(room.emoji || room.avatar)} width={54} />
      </div>
    ) : null;

  const renderRoom = (room: any, idx: any) => (
    <SListItem
      title={room.title}
      description={room.description}
      avatar={renderAvatar(room)}
      actions={[
        <Checkbox
          checked={selectedRooms.some((e) => e.id === room.id)}
          key="checkbox"
        />,
      ]}
      onClick={() => handleSelect(room)}
      className="room-modal__item"
      key={idx.toString()}
    />
  );

  return (
    <Modal
      width={800}
      onOk={() => {
        onSelect(selectedRooms);
        setSelectedRooms([]);
        onCancel();
      }}
      okText="Save"
      onCancel={() => {
        onCancel();
        setSelectedRooms([]);
      }}
      open={visible}
      title={`Choose rooms`}
      centered
      className="room-modal"
    >
      <SList data={allRooms || []} renderItem={renderRoom} split={false} />
    </Modal>
  );
};

type Props = {
  visible: boolean;
  onSelect: (rooms: any) => void;
  onCancel: () => void;
  allRooms?: Array<RoomType>;
};

export default RoomModal;
