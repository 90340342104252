import { Button, Collapse, Form, Layout, message } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { SDetailLayout } from 'app/components/StaycationUI';
import { BillingContact } from 'app/components/commons/BillingContact/BillingContact';
import { BookingContact } from 'app/components/commons/BookingContact/BookingContact';
import { GuestContact } from 'app/components/commons/GuestContact/GuestContact';
import { Notification } from 'app/components/commons/Notification/Notification';
import { SaleContact } from 'app/components/commons/SaleContact/SaleContact';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import {
  useAmplitude,
  useAmplitudeViewPage,
} from 'app/hooks/useAmplitude/useAmplitude';
import { fetchContacts, saveContacts } from 'app/redux/actions/hotelAdmin';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { HotelAdminContact } from 'app/redux/models/Hotel/Hotel';
import {
  selectHotelAdminBillsIsLoading,
  selectHotelAdminContactsData,
  selectHotelAdminHotelId,
} from 'app/redux/selectors/hotelAdmin';
import useBoolState from 'app/utils/hooks';

import { ExtranetHeader } from '../commons/ExtranetHeader/ExtranetHeader';

import './ExtranetContact.scss';

const { Content } = Layout;
const PAGE = 'Contact';

export const ExtranetContact = () => {
  const { setPage } = useContext(TaskCenterContext);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const hotelId = useAppSelector(selectHotelAdminHotelId);
  const contacts = useAppSelector(selectHotelAdminContactsData);
  const isContactLoading = useAppSelector(selectHotelAdminBillsIsLoading);
  const [trackAddedEmail, setTrackAddedEmail] = useBoolState(false);
  const [trackDeletedEmail, setTrackDeletedEmail] = useBoolState(false);
  const [trackUpdatedEmail, setTrackUpdatedEmail] = useBoolState(false);
  const [trackUpdatedPanel, setTrackUpdatedPanel] = useBoolState(false);
  const [originalBillingEmails, setOriginalBillingsEmails] = useState(
    contacts?.billingEmails
  );

  setPage(PAGE);

  const [visible, setModalVisible] = useState(false);

  const handleOk = useCallback(() => {
    setModalVisible(false);
  }, []);

  const { track } = useAmplitude();

  useEffect(() => {
    if (hotelId) {
      dispatch(fetchContacts({ hotelId }));
    }
  }, [dispatch, hotelId]);

  useAmplitudeViewPage({ page: 'contact' });

  useEffect(() => {
    if (contacts) {
      form.resetFields();
    }
  }, [form, contacts]);

  useEffect(() => {
    if (!isContactLoading) {
      setOriginalBillingsEmails(contacts?.billingEmails);
    }
  }, [contacts?.billingEmails, isContactLoading]);

  const toggleMode = () =>
    setMode((currentMode) => {
      if (currentMode === 'view') {
        track('Click edit contact');
      }

      return currentMode === 'view' ? 'edit' : 'view';
    });

  const onOkClick = () => {
    track('Click Everything ok');
    message.success('Noted, thank you!');
  };

  const isEmailField = (name: NamePath) => {
    const stringName = name.toString();

    const fieldsToCheck = [
      ['saleContact', 'email'],
      ['email'],
      ['notifEmails', 'email'],
      ['billingEmails'],
    ];

    const touchedFields = fieldsToCheck.filter(
      (fieldName) => stringName === fieldName.toString()
    );

    return touchedFields.length > 0;
  };

  const isPanelField = (name: NamePath) => {
    const notifEmailsValue: HotelAdminContact['notifEmails'] =
      form.getFieldValue('notifEmails');
    const stringName = name.toString();

    const checked = notifEmailsValue.map((_, index) => {
      const fieldsToCheck = [
        ['notifEmails', index, 'bookingConfirmation'],
        ['notifEmails', index, 'stock'],
        ['notifEmails', index, 'billing'],
      ];

      const touchedFields = fieldsToCheck.filter(
        (fieldName) => stringName === fieldName.toString()
      );

      return touchedFields.length > 0;
    });

    return checked.some((elem) => elem);
  };

  const handleSubmit = (values: HotelAdminContact) => {
    toggleMode();

    if (hotelId) {
      if (trackAddedEmail) {
        track('Add Email');
      }

      if (trackDeletedEmail) {
        track('Delete Email');
      }

      if (trackUpdatedEmail && !trackAddedEmail && !trackDeletedEmail) {
        track('Update email');
      }

      if (trackUpdatedPanel) {
        track('Update preference center');
      }

      dispatch(saveContacts({ hotelId, form: values }));
      setTrackAddedEmail.close();
      setTrackDeletedEmail.close();
      setTrackUpdatedEmail.close();
      setTrackUpdatedPanel.close();

      setModalVisible(true);
    }
  };

  if (!contacts) {
    return null;
  }

  return (
    <Layout className="extranet-contact">
      <ExtranetHeader page={PAGE} />
      <FormLegacyRenderProp
        form={form}
        onFinish={handleSubmit}
        initialValues={contacts}
        onFieldsChange={(changedFields) => {
          if (changedFields[0] && isEmailField(changedFields[0].name)) {
            setTrackUpdatedEmail.open();
          }

          if (changedFields[0] && isPanelField(changedFields[0].name)) {
            setTrackUpdatedPanel.open();
          }
        }}
      >
        {(
          values,
          { getFieldsError, isFieldsTouched, submit, setFieldsValue }
        ) => (
          <SDetailLayout
            isDirty={isFieldsTouched()}
            mode={mode}
            onEdit={toggleMode}
            onSave={submit}
            onCancel={toggleMode}
            saveDisabled={getFieldsError().some(
              (item) => item.errors.length > 0
            )}
            extraViewFooter={<Button onClick={onOkClick}>Everything ok</Button>}
          >
            <Content className="container">
              <div className="container-collapse">
                <Collapse
                  bordered={false}
                  defaultActiveKey={[
                    'sale-contact',
                    'notif-contact',
                    'guest-contact',
                    'billing-contact',
                  ]}
                >
                  <SaleContact mode={mode} key="sale-contact" />
                  <BookingContact
                    mode={mode}
                    key="notif-contact"
                    notifEmails={values.notifEmails}
                    billingEmails={values.billingEmails}
                    setNotifEmails={(notifEmails) => {
                      setFieldsValue({ ...values, notifEmails });
                    }}
                    trackAdd={setTrackAddedEmail.open}
                    trackDelete={setTrackDeletedEmail.open}
                  />
                  <GuestContact mode={mode} key="guest-contact" />
                  <BillingContact
                    mode={mode}
                    key="billing-contact"
                    originalBillingEmails={originalBillingEmails}
                    notifEmails={values.notifEmails}
                    trackAdd={setTrackAddedEmail.open}
                    trackDelete={setTrackDeletedEmail.open}
                  />
                </Collapse>
              </div>
            </Content>
          </SDetailLayout>
        )}
      </FormLegacyRenderProp>
      {visible && (
        <Notification
          title="Thanks for updating your contact details"
          content="If needed, please also update your contact on Stripe to avoid losing access."
          onOk={handleOk}
          visible={visible}
          type="warning"
          hasCancelButton={false}
          centered
        />
      )}
    </Layout>
  );
};

export default ExtranetContact;
