import { Layout } from 'antd';
import React, { FC, ReactNode } from 'react';

import 'styles/layout/page.scss';

const { Header, Content } = Layout;

const ListPage: FC<Props> = ({
  title,
  menu,
  withContentWrapper = true,
  children,
}) => (
  <Layout className="page__container">
    <Header className="page__list-header">
      <h1>{title}</h1>
      {menu}
    </Header>
    {withContentWrapper ? (
      <Content className="page__body">{children}</Content>
    ) : (
      children
    )}
  </Layout>
);

type Props = {
  title: string;
  menu?: ReactNode;
  // We don't always want to wrap children with a <Content> component.
  // For example, when we want to render a <Footer> in the page, we need to render it next to <Content>, not inside it.
  withContentWrapper?: boolean;
  children: ReactNode;
};

export default ListPage;
