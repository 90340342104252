import { HOURS_IN_DAY } from '@stdlib/constants-time';
import { Form, Layout } from 'antd';
import { ValidateFields } from 'rc-field-form/lib/interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  SCard,
  SDetailLayout,
  SDetailLayoutProps,
} from 'app/components/StaycationUI';
import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import Delete from 'app/components/commons/ExtraButtons/Delete/Delete';
import CustomInput from 'app/components/fields/Input';
import { Option, Select } from 'app/components/fields/Select';
import { tryDeleteExperience } from 'app/redux/actions/experiences';
import { tryFetchHotel } from 'app/redux/actions/hotels';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import type { Experience } from 'app/redux/models/Experience/Experience';
import { selectExperienceCategories } from 'app/redux/selectors/conf';
import { getHotel } from 'app/redux/selectors/hotels';
import { selectEmojis } from 'app/redux/selectors/pictures';
import { pluralize } from 'app/utils/strings';
import { parseInputNumber } from 'app/utils/typing';

import ToggleableInput from '../ToggleableInput/ToggleableInput';

import { Availability } from './Availability/Availability';
import BasicInfo from './BasicInfo/BasicInfo';
import './ExperienceForm.scss';
import Photos from './Photos/Photos';
import PracticalInfo from './PracticalInfo/PracticalInfo';
import Value from './Value/Value';
import { computeExperienceName } from './utils';

const { Content } = Layout;
const RULES = [{ required: true, message: 'Required' }];

const TIME_UNIT = ['hours', 'days'];

type FormWrapperProps = {
  isBuilder?: boolean;
  layoutProps: SDetailLayoutProps;
  children: JSX.Element;
};

const HOTEL_EXPERIENCE_TYPE = 1;
const ORIGINAL_EXPERIENCE_TYPE = 2;

export const FormWrapper = ({
  isBuilder,
  layoutProps,
  children,
}: FormWrapperProps) =>
  isBuilder ? (
    <div className="experiences-builder-form page-content">{children}</div>
  ) : (
    <SDetailLayout {...layoutProps}>
      <Content className="page-content">{children}</Content>
    </SDetailLayout>
  );

type FormPartWrapperProps = {
  isBuilder?: boolean;
  subtitle?: string;
  isLastCard?: boolean;
  title: string;
  children: JSX.Element;
};

export const FormPartWrapper = ({
  isBuilder,
  isLastCard,
  title,
  subtitle,
  children,
}: FormPartWrapperProps) =>
  isBuilder ? (
    <div className="form-part">
      <h3>{title}</h3>

      {children}
    </div>
  ) : (
    <SCard
      title={title}
      subtitle={subtitle}
      className={isLastCard ? 'last-card' : ''}
    >
      {children}
    </SCard>
  );

export const ExperienceForm = ({
  title,
  isBuilder,
  experienceId,
  mode,
  setMode,
  values,
  initialValues,
  setFieldsValue,
  resetFields,
  isFieldsTouched,
  submit,
  onCancel,
  isFormValid,
  validateFields,
}: Props) => {
  const dispatch = useAppDispatch();
  const experienceCategories = useAppSelector(selectExperienceCategories);
  const emojis = useAppSelector(selectEmojis);
  const stateHotel = useAppSelector(getHotel);
  const [experienceType, setExperienceType] = useState(HOTEL_EXPERIENCE_TYPE);
  const isOriginal = experienceType === ORIGINAL_EXPERIENCE_TYPE;
  const hotel = stateHotel?.id === values.hotelId ? stateHotel : null;

  useEffect(() => {
    if (!hotel && values.hotelId) {
      dispatch(tryFetchHotel({ hotelId: values.hotelId }));
    }
  }, [dispatch, hotel, values.hotelId]);

  useEffect(() => {
    if (experienceType === HOTEL_EXPERIENCE_TYPE && values.countryId) {
      setExperienceType(ORIGINAL_EXPERIENCE_TYPE);
    }
  }, [experienceType, values.countryId]);

  const category = useMemo(
    () => experienceCategories.find((cat) => cat.id === values.categoryId),
    [values.categoryId, experienceCategories]
  );

  const computedExperienceName = useMemo(() => {
    return computeExperienceName({
      category: experienceCategories.find(
        (cat) => cat.id === values.categoryId
      ),
      quantity: values.quantity,
      brand: values.brand,
      duration: values.duration,
      durationDescription: values.durationDescription,
      time: values.time,
      locations: values.locations,
    });
  }, [
    experienceCategories,
    values.quantity,
    values.categoryId,
    values.brand,
    values.duration,
    values.durationDescription,
    values.time,
    values.locations,
  ]);

  useEffect(() => {
    const emoji = emojis.find((e: any) => e.id === category?.pictureId);

    if (emoji && !values.emoji) {
      setFieldsValue({
        ...values,
        emoji,
      });
    }
  }, [category, emojis, setFieldsValue, values]);

  const deleteExperience = (newExperienceId: number) =>
    dispatch(tryDeleteExperience(newExperienceId));

  const renderDeleteButton = () => {
    if (!experienceId) {
      return null;
    }

    return (
      <Delete
        entityDescription={`Experience #${experienceId}`}
        onDelete={() => deleteExperience(+experienceId)}
      />
    );
  };

  const onEdit = () => {
    if (setMode) {
      setMode('edit');
    }
  };

  const renderMaximumQuantity = useCallback(() => {
    return (
      <div className="input-value">
        {mode === 'view' ? (
          <div>
            {(values && values.maxQuantity) ?? initialValues?.maxQuantity}
          </div>
        ) : (
          <>
            <Form.Item name="maxQuantity" noStyle>
              <CustomInput
                suffix={'max'}
                onWheel={(e) => e.currentTarget.blur()}
                min={0}
                parser={parseInputNumber}
              />
            </Form.Item>
          </>
        )}
        per booking
      </div>
    );
  }, [initialValues?.maxQuantity, mode, values]);

  const renderRemoveAvailability = useCallback(() => {
    const valueDisplay =
      values.addOnLimitAvailabilityUnit === 'days'
        ? pluralize(values.closedHoursBeforeCheckin, 'day', 'days')
        : pluralize(values.closedHoursBeforeCheckin, 'hour', 'hours');

    return (
      <div className="input-value">
        {mode === 'view' ? (
          <div>{valueDisplay}</div>
        ) : (
          <div className="combine-input">
            <Form.Item name="closedHoursBeforeCheckin" rules={RULES} noStyle>
              <CustomInput
                onWheel={(e) => e.currentTarget.blur()}
                min={0}
                parser={parseInputNumber}
              />
            </Form.Item>
            <Form.Item name="addOnLimitAvailabilityUnit" rules={RULES} noStyle>
              <Select
                defaultValue={
                  values.closedHoursBeforeCheckin &&
                  values.closedHoursBeforeCheckin % HOURS_IN_DAY === 0
                    ? 'days'
                    : 'hours'
                }
              >
                {TIME_UNIT.map((timeUnit) => (
                  <Option key={timeUnit} value={timeUnit}>
                    {timeUnit}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        )}
        before check-in
      </div>
    );
  }, [mode, values]);

  const addOnLimitationOption = useMemo(
    () => [
      {
        title: 'Maximum quantity',
        renderInput: renderMaximumQuantity(),
        value: initialValues?.maxQuantity
          ? initialValues?.maxQuantity
          : undefined,
        name: 'maxQuantity',
      },
      {
        title: 'Remove availability',
        renderInput: renderRemoveAvailability(),
        value: initialValues?.closedHoursBeforeCheckin
          ? initialValues?.closedHoursBeforeCheckin
          : undefined,
        disclaimer:
          'Users will not see this add-on if they want to book during this period.',
        name: 'closedHoursBeforeCheckin',
      },
    ],
    [renderMaximumQuantity, initialValues, renderRemoveAvailability]
  );

  return (
    <FormWrapper
      isBuilder={isBuilder}
      layoutProps={{
        mode,
        onCancel,
        title: computedExperienceName,
        onEdit,
        onSave: submit,
        reset: resetFields,
        headerExtra: [renderDeleteButton()],
        isDirty:
          isFieldsTouched() ||
          !!(initialValues?.emoji?.id !== values?.emoji?.id),
        isValid: isFormValid,
      }}
    >
      <CurrencyWrapper
        hotel={!isOriginal ? hotel : undefined}
        countryId={isOriginal ? values.countryId : undefined}
      >
        <FormPartWrapper isBuilder={isBuilder} title="Basic information">
          <BasicInfo
            isBuilder={isBuilder}
            disabled={!!experienceId}
            mode={mode}
            values={values}
            setFieldsValue={setFieldsValue}
            category={category}
            experienceCategories={experienceCategories}
            experienceType={experienceType}
            setExperienceType={setExperienceType}
            isOriginal={isOriginal}
          />
        </FormPartWrapper>
        {!!category && (
          <>
            <FormPartWrapper
              isBuilder={isBuilder}
              title="Practical information"
              subtitle={
                category.openingHours !== 'NOT_APPLICABLE'
                  ? 'Add practical information regarding the opening hours of the experience. This information will be shown at the bottom of the Package page. '
                  : undefined
              }
            >
              <PracticalInfo
                isBuilder={isBuilder}
                mode={mode}
                values={values}
                setFieldsValue={setFieldsValue}
                hotelSpaces={!isOriginal ? hotel?.spaces : undefined}
                category={category}
                validateFields={validateFields}
              />
            </FormPartWrapper>
            <FormPartWrapper isBuilder={isBuilder} title="Value">
              <Value
                mode={mode}
                values={values}
                category={category}
                isOriginal={isOriginal}
                setFieldsValue={setFieldsValue}
              />
            </FormPartWrapper>
            <FormPartWrapper
              isBuilder={isBuilder}
              title="Photos"
              subtitle="In edit mode, you can drag and drop photos to rearrange them. The first one will be the experience cover"
              isLastCard={isBuilder ? true : false}
            >
              <Photos
                title={title}
                mode={mode}
                values={values}
                hotelId={!isOriginal ? hotel?.id : undefined}
                isBuilder={isBuilder}
                isOriginal={isOriginal}
              />
            </FormPartWrapper>
            {!isBuilder && (
              <FormPartWrapper
                isBuilder={isBuilder}
                title="Availability"
                subtitle="Configure days when the experience will be available for guests during their stay"
              >
                <Availability mode={mode} values={values} />
              </FormPartWrapper>
            )}
            {!isBuilder && (
              <FormPartWrapper
                isBuilder={isBuilder}
                title="Add-on limitations"
                subtitle="These settings are effective only if this experience is an add-on"
                isLastCard
              >
                <div className="container">
                  {addOnLimitationOption.map((section, index) => (
                    <div key={index} className="sectionContainer">
                      <div>{section.title}</div>
                      <ToggleableInput
                        value={section.value}
                        disabled={mode === 'view'}
                        name={section.name}
                      >
                        {section.renderInput}
                      </ToggleableInput>
                    </div>
                  ))}
                </div>
              </FormPartWrapper>
            )}
          </>
        )}
      </CurrencyWrapper>
    </FormWrapper>
  );
};

type Props = {
  title?: string;
  isBuilder?: boolean;
  experienceId: number;
  mode: 'view' | 'edit';
  setMode?: (newMode: 'view' | 'edit') => void;
  values: Experience;
  initialValues?: Experience | null;
  setFieldsValue: (newValues: Partial<Experience>) => void;
  resetFields: () => void;
  isFieldsTouched: () => boolean;
  submit: () => void;
  onCancel: () => void;
  isFormValid: boolean;
  validateFields: ValidateFields;
};

export default ExperienceForm;
