import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { updateEditoEventPath } from 'app/routes/edito';
import { IEditoEventRequest } from 'app/typings/edito';
import { apiMutation } from 'app/utils/request/api';

export const useUpdateEditoEvent = () => {
  return useMutation<number, unknown, IEditoEventRequest, unknown>({
    mutationFn: async (payload) =>
      apiMutation(
        'PUT',
        {
          path: updateEditoEventPath,
          params: { id: payload.id.toString() },
        },
        payload
      ),
    onSuccess: () => {
      message.success('The event has been successfully updated');
    },
    onError: () => {
      message.error('Error while updating the event');
    },
  });
};
