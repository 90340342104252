import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import 'styles/layout/Sortable/sortable-row.scss';

const SortableTr = SortableElement(({ className, ...props }) => (
  <tr className={`sortable-row ${className}`} {...props} />
));

const SortableRow = ({ 'data-row-key': rowKey, ...props }) => (
  <SortableTr key={rowKey} index={rowKey} {...props} />
);

export default SortableRow;
