import { Button } from 'antd';
import React, { FC } from 'react';

import './Features.scss';

type Feature = {
  priority?: number;
  id: number;
  name: string;
};

type Props = {
  value?: Array<number>;
  onChange?: (newValue: Array<number>) => void;
  featuresByCategory: Record<string, Array<Feature>>;
  mode?: string;
};

export const Features: FC<Props> = ({
  value,
  onChange,
  featuresByCategory,
  mode = 'edit',
}) => {
  if (!onChange) {
    return null;
  }

  const handleChange = (featureId: number, newValue: boolean) => {
    const parsedValue = value || [];

    if (newValue) {
      onChange([...parsedValue, featureId]);
    } else {
      const index = parsedValue.indexOf(featureId);

      if (index >= 0) {
        onChange([
          ...parsedValue.slice(0, index),
          ...parsedValue.slice(index + 1),
        ]);
      }
    }
  };

  const renderFeature = (feature: Feature) => {
    const checked = value && value.includes(feature.id);

    if (mode === 'view' && !checked) {
      return;
    }

    return (
      <Button
        key={`feature-${feature.id}`}
        className={`check-button ${checked ? 'check-button--checked' : ''}`}
        onClick={() => mode === 'edit' && handleChange(feature.id, !checked)}
      >
        {feature.name}
      </Button>
    );
  };

  return (
    <>
      {Object.keys(featuresByCategory).map((category, index) => {
        const features = featuresByCategory[category];
        const sortedFeatures = features.sort(
          (f1, f2) => (f1.priority || 0) - (f2.priority || 0)
        );

        return (
          <div key={`category-${index}`} className="feature-category">
            <div className="label">{category}</div>
            <div className="buttons">{sortedFeatures.map(renderFeature)}</div>
          </div>
        );
      })}
    </>
  );
};

export default Features;
