import { InputNumber } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { currencySymbolSelector } from 'app/redux/selectors';

import 'styles/fields/input-number.scss';

export const NumberWithSuffix = ({ suffix, ...props }) => (
  <div className="input-number">
    <InputNumber {...props} />
    <span className="input-number__suffix">{suffix}</span>
  </div>
);

NumberWithSuffix.propTypes = {
  suffix: PropTypes.string,
};

export default connect((state, props) => ({
  suffix: props.currency ? currencySymbolSelector(state) : props.suffix,
}))(NumberWithSuffix);
