import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { deleteCreateEditoEventPath } from 'app/routes/edito';
import { IEditoEventListResponse } from 'app/typings/edito';
import { apiMutation } from 'app/utils/request/api';

type PayloadProps = {
  id: string;
};

export const useDeleteEditoEvents = () => {
  return useMutation<IEditoEventListResponse[], unknown, PayloadProps, unknown>(
    {
      mutationFn: async (payload: PayloadProps) =>
        apiMutation(
          'DELETE',
          {
            path: deleteCreateEditoEventPath,
            params: { id: payload.id },
          },
          payload
        ),
      onSuccess: () => {
        message.success('The event has been successfully deleted');
      },
      onError: () => {
        message.error('Error while deleting event');
      },
    }
  );
};
