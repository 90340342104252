import { Layout } from 'antd';
import _ from 'lodash';
import React, { FC, useEffect } from 'react';

import Card from 'app/components/layout/Card';
import CountryListPage from 'app/components/lists/CountryListPage';
import entityConnect from 'app/redux/entityConnect';
import { ICountry, ISaleDates } from 'app/typings';

import SaleInfo from './SaleInfo';

const { Content } = Layout;

const SalesList: FC<Props> = ({ sales, fetchEntities }) => {
  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  return (
    <CountryListPage title="Sale times">
      {({ countryId }) => {
        const sale = _.find(sales, { countryId });

        return (
          <Layout>
            <Content className="page__content">
              {sale ? (
                <SaleInfo sale={sale} />
              ) : (
                <Card title="No current or upcoming sale." />
              )}
            </Content>
          </Layout>
        );
      }}
    </CountryListPage>
  );
};

type Props = {
  sales: ISaleDates[];
  countries: ICountry[];
  fetchEntities: () => void;
};

export default entityConnect(['sales'])(SalesList);
