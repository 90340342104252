import { Layout, PageHeader, Tabs, TabsProps } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import { useGetEditoEvents } from 'app/hooks/data/edito/events/useGetEditoEvents';
import { getEditoEventListCategory } from 'app/utils/edito';
import { useSearchParams } from 'app/utils/searchParams';

import {
  EditoEventList,
  EditoEventListProps,
} from './EditoEventList/EditoEventList';
import './EditoEventListPage.scss';

export const EditoEventListPage = () => {
  const { data: editoEvents, isLoading, refetch } = useGetEditoEvents();
  const { getSearchParam, setSearchParams } = useSearchParams();
  const initialCategoryParams = getSearchParam('category');
  const initialCategory = getEditoEventListCategory(initialCategoryParams);

  const commonProps: Omit<EditoEventListProps, 'isFeatured'> = {
    editoEvents,
    refetch,
    isLoading,
  };

  const tabs: TabsProps['items'] = [
    {
      key: 'featured',
      label: 'Featured',
      children: <EditoEventList isFeatured={true} {...commonProps} />,
    },
    {
      key: 'allEvents',
      label: 'All events',
      children: <EditoEventList isFeatured={false} {...commonProps} />,
    },
  ];

  const onSwitchTab = (key: string) => {
    setSearchParams({ category: key });
  };

  return (
    <Layout className="page-list">
      <PageHeader title="Events" />
      <Content className="editoEventContent">
        <Tabs
          onChange={onSwitchTab}
          items={tabs}
          className="editoEventTabs"
          defaultActiveKey={initialCategory}
        />
      </Content>
    </Layout>
  );
};
