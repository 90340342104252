import { SearchOutlined } from '@ant-design/icons';
import { Input, Table, TableProps } from 'antd';
import React, { ChangeEventHandler, useState } from 'react';

import CoverCell from 'app/components/commons/CoverCell/CoverCell';
import Pagination from 'app/components/commons/Range/Pagination/Pagination';
import { useSlideEntities } from 'app/hooks/data/useSlidesEntities';
import { ISlideCollection, ISlidePackage } from 'app/typings/slides';
import { SlideType } from 'app/typings/slides';
import { useDebounce } from 'app/utils/hooks/useDebounce';
import { formatPackageName } from 'app/utils/packages';

import styles from './SlideEntitiesTable.module.scss';

const { Column } = Table;

const PAGE_SIZE = 10;

export type SlideEntity = ISlidePackage | ISlideCollection;

export type SlideEntitiesTableProps = {
  slideType: SlideType.PACKAGE | SlideType.COLLECTION;
  clubId: number;
  onRowClick: (row: ISlidePackage | ISlideCollection) => void;
};

export const SlideEntitiesTable = ({
  slideType,
  clubId,
  onRowClick,
}: SlideEntitiesTableProps) => {
  const [pageNumber, setPageNumber] = useState(1);

  const [search, setSearch] = useState('');

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (evt) => {
    setSearch(evt.target.value);
    setPageNumber(1);
  };

  const debouncedSearch = useDebounce(search);

  const [sortOrder, setSortOrder] = useState<'ascend' | 'descend'>('ascend');

  const handleChange: TableProps<SlideEntity>['onChange'] = (
    _value,
    _record,
    sorter
  ) => {
    const { order } = Array.isArray(sorter) ? sorter[0] : sorter;

    // We only have 1 column, we don't need to have an "unsorted" state.
    setSortOrder(order ?? 'ascend');
  };

  const { data, isFetching } = useSlideEntities(slideType, clubId, {
    search: debouncedSearch,
    sortingOrder: sortOrder === 'descend' ? 'DESC' : 'ASC',
    sortingColumn: 'hotelName',
    offset: (pageNumber - 1) * PAGE_SIZE,
    limit: PAGE_SIZE,
  });

  // We type page as SlideEntity[] to make it easier to work with the generic in Table props,
  // But in reality page will only contain one type of data: ISlidePackage or ISlideCollection.
  const page: ISlidePackage[] | ISlideCollection[] | undefined = data;

  const isLastPage = !!page && page.length < PAGE_SIZE;

  return (
    <>
      <div className={styles.header}>
        <Input
          placeholder="Search here..."
          onChange={handleSearch}
          suffix={<SearchOutlined />}
          className={styles.search}
        />

        <Pagination
          className={styles.pagination}
          currentPage={pageNumber}
          setPageNumber={setPageNumber}
          isLastPage={isLastPage}
        />
      </div>

      <Table
        rowKey={(row) => row.id}
        pagination={false}
        dataSource={page}
        loading={isFetching}
        onRow={(row) => ({
          onClick: () => {
            onRowClick(
              row as SlideType extends SlideType.PACKAGE
                ? ISlidePackage
                : ISlideCollection
            );
          },
        })}
        onChange={handleChange}
      >
        <Column<SlideEntity>
          title="Name"
          render={(_value, record) => {
            const name = (record as ISlidePackage).hotelName
              ? formatPackageName(record as ISlidePackage)
              : record.name;

            if (record.cover) {
              return <CoverCell name={name} cover={record.cover} />;
            }

            return <div>{name}</div>;
          }}
          sorter
          sortOrder={sortOrder}
        />
      </Table>
    </>
  );
};
