import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const selectBills = (state: RootState) => state.bills;

export const getBills = createSelector(selectBills, (bills) => bills.bills);

export const getIsLoading = createSelector(
  selectBills,
  (bills) => bills.isLoading
);

export const getBillPdfObject = createSelector(
  selectBills,
  (bills) => bills.billPdfObject
);

export const getIsDownloading = createSelector(
  selectBills,
  (bills) => bills.isDownloading
);
