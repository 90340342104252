import { Col, Row } from 'antd';
import classNames from 'classnames';
import { eachDayOfInterval, format, isWithinInterval } from 'date-fns';
import { FC } from 'react';

import { isEndOfWeek } from 'app/utils/dates';

import { DuplicationHover } from '../../Types';

import './DateRow.scss';

const DateRow: FC<Props> = ({
  roomId,
  currentRange,
  currentDuplicateHoverRange,
}) => {
  const isDuplicateHovered = (date: Date) =>
    currentDuplicateHoverRange &&
    currentDuplicateHoverRange.roomId === roomId &&
    currentDuplicateHoverRange.range &&
    isWithinInterval(date, currentDuplicateHoverRange.range);

  const renderDates = (date: any) => (
    <Col
      className={classNames([
        'extranet-inventory__date-cell',
        {
          'extranet-inventory__date-cell--end-of-week': isEndOfWeek(date),
          'extranet-inventory__date-cell--to-duplicate':
            isDuplicateHovered(date),
        },
      ])}
      flex="1"
      key={format(date, 'EEE dd/MM')}
    >
      <div>
        <div>{format(date, 'EEE')}</div>
        <div className="date-day">{format(date, 'dd/MM')}</div>
      </div>
    </Col>
  );

  return (
    <Row>
      <Col className="extranet-inventory__date-cell extranet-inventory__head">
        Time Period
      </Col>
      {eachDayOfInterval(currentRange).map(renderDates)}
    </Row>
  );
};

type Props = {
  roomId: number;
  currentRange: Interval;
  currentDuplicateHoverRange?: DuplicationHover;
};

export default DateRow;
