import moment from 'moment';

import { ISpace } from 'app/components/commons/Hotel/Types';
import {
  formatOpeningHoursForAPI,
  parseOpeningHoursFromAPI,
} from 'app/components/commons/TimeRangeSection/utils';

export const CATEGORIES = [
  'wellness',
  'food',
  'social',
  'outdoors',
  'entertainment',
];

export const formatSpacesFormFromAPI = (hotel: any, spaceTypes: any) => {
  const defaultSpaces = hotel.spaces || [];
  const spaces = defaultSpaces
    .filter(
      (space: any) =>
        space.spaceTypeId !==
        spaceTypes.find((type: any) => type.name === 'Desk')?.id
    )
    .map((space: any) => ({
      ...space,
      pictures: space.pictures || [],
      openingHours: parseOpeningHoursFromAPI(space.openingHours),
    }))
    .reduce((acc: any, space: ISpace) => {
      const type = spaceTypes.find(
        (spaceType: any) => spaceType.id === space.spaceTypeId
      );

      if (type) {
        acc[type.category] = acc[type.category] || [];
        acc[type.category].push(space);
      }

      return acc;
    }, {});
  const sortedSpaces: Array<ISpace> = CATEGORIES.reduce(
    (acc: ISpace[], cat) => {
      if (cat in spaces) {
        const sortedSpaces = spaces[cat].sort(
          (a: any, b: any) => a.spaceTypeId - b.spaceTypeId
        );

        return [...acc, ...sortedSpaces];
      }

      return acc;
    },
    []
  );

  return {
    informativePhoto: hotel.streetPicture ? [hotel.streetPicture] : [],
    desk: {
      ...defaultSpaces.find(
        (space: any) =>
          space.spaceTypeId ===
          spaceTypes.find((type: any) => type.name === 'Desk')?.id
      ),
      checkInTime: hotel.checkInTime
        ? moment(hotel.checkInTime, 'HH:mm')
        : undefined,
      checkOutTime: hotel.checkOutTime
        ? moment(hotel.checkOutTime, 'HH:mm')
        : undefined,
    },
    spaces: sortedSpaces,
  };
};

export const formatNewSpaces = (
  newSpaces: any,
  values: any,
  spaceTypes: any,
  addedSpace: any
) => {
  const addedSpaces = newSpaces.map((spaceTypeId: any) => ({
    spaceTypeId,
    pictures: [],
    openingHours: parseOpeningHoursFromAPI(undefined),
    addedSpace,
  }));
  const allSpaces = [...values.spaces, ...addedSpaces];
  const spaces = allSpaces.reduce((acc, space) => {
    const type = spaceTypes.find(
      (spaceType: any) => spaceType.id === space.spaceTypeId
    );

    if (type) {
      acc[type.category] = acc[type.category] || [];
      acc[type.category].push(space);
    }

    return acc;
  }, {});
  const sortedSpaces: Array<ISpace> = CATEGORIES.reduce(
    (acc: ISpace[], cat) => {
      if (cat in spaces) {
        const sortedSpaces = spaces[cat].sort(
          (a: any, b: any) => a.spaceTypeId - b.spaceTypeId
        );

        return [...acc, ...sortedSpaces];
      }

      return acc;
    },
    []
  );

  return {
    ...values,
    spaces: sortedSpaces,
  };
};

const findAndFormatDeletedSpaces = (hotel: any, spaces: any) => {
  if (!hotel.spaces) {
    return [];
  }

  return hotel.spaces
    .filter(
      (space: any) =>
        space.spaceTypeId > 2 && !spaces.find((sp: any) => sp.id === space.id)
    )
    .map((space: any) => ({
      ...space,
      isDeleted: true,
    }));
};

export const reformatSpacesFormForAPI = (
  values: any,
  hotel: any,
  spaceTypes: any
) => {
  const formattedFilteredSpaces = values.spaces
    .filter(
      (space: any) =>
        space.spaceTypeId !==
        spaceTypes.find((type: any) => type.name === 'Desk')?.id
    )
    .map((space: any) => ({
      ...space,
      openingHours: formatOpeningHoursForAPI(space.openingHours),
    }));

  return {
    streetPictureId: values.informativePhoto?.[0].id,
    checkInTime: moment(values.desk.checkInTime).format('HH:mm:ss'),
    checkOutTime: moment(values.desk.checkOutTime).format('HH:mm:ss'),
    spaces: [
      {
        spaceTypeId: 2,
        id: hotel?.spaces?.find((space: any) => space.spaceTypeId === 2)?.id,
        floor: values.desk.floor,
        phone: values.desk.phone,
      },
      ...formattedFilteredSpaces,
      ...findAndFormatDeletedSpaces(hotel, values.spaces),
    ],
  };
};
