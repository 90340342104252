import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';

import { SortableCollapse } from 'app/components/layout/Sortable/Collapse';
import type { GuideSection } from 'app/redux/models/Guides/Guides';
import type { Picture } from 'app/redux/models/Picture/Picture';

import SectionPackage from './SectionPackage/SectionPackage';
import './SectionPackages.scss';

type Field = {
  name: number;
  key: number;
};

type Props = {
  fields: Field[];
  add: (defaultValue?: any, insertIndex?: number) => void;
  remove: (index: number) => void;
  move: (from: number, to: number) => void;
  guideSection?: Partial<GuideSection>;
  formattedPackages: Array<{
    id: number;
    name: string;
    coverPicture: Picture;
    searchName?: string;
  }>;
};

const SectionPackages = ({
  fields,
  add,
  remove,
  move,
  guideSection,
  formattedPackages,
}: Props) => {
  const [activeKeys, setActiveKeys] = useState(['pkg-0']);

  const handleAdd = () => {
    setActiveKeys((prevActiveKeys) => [
      ...prevActiveKeys,
      `pkg-${fields.length}`,
    ]);
    add({});
  };

  const handleRemove = (index: any) => {
    remove(index);

    const sectionPackage = guideSection?.packages?.[index];

    if (sectionPackage?.id) {
      add({ ...sectionPackage, id: -sectionPackage.id }, index);
    }
  };

  return (
    <>
      <SortableCollapse
        activeKey={activeKeys}
        onToggle={setActiveKeys}
        onSortEnd={({ oldIndex, newIndex }) => move(oldIndex, newIndex)}
        useDragHandle
      >
        {fields.map((field, index) => {
          const { name, key } = field;
          const sectionPackage = guideSection?.packages?.[name];

          if (sectionPackage?.id !== undefined && sectionPackage?.id < 0) {
            return null;
          }

          return (
            <SectionPackage
              key={`pkg-${key}`}
              handleRemove={handleRemove}
              field={field}
              index={index}
              sectionPackage={sectionPackage}
              formattedPackages={formattedPackages}
            />
          );
        })}
      </SortableCollapse>
      <div className="section-packages__add">
        <Button icon={<PlusOutlined />} onClick={handleAdd}>
          Add Package
        </Button>
      </div>
    </>
  );
};

export default SectionPackages;
