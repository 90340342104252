import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useAppSelector } from 'app/redux/hooks';
import { selectClubs } from 'app/redux/selectors/conf';
import {
  EditoEventStatus,
  SelectedEventClubId,
  editoEventCatalogStatuses,
  editoEventFeaturedStatuses,
} from 'app/typings/edito';
import { capitalizeFirstLetter } from 'app/utils/string';

import './EditoEventListHeader.scss';

type Props = {
  searchText: string;
  setSearchText: (text: string) => void;
  selectedCountryId: number;
  selectedClubId: SelectedEventClubId;
  selectedStatus: EditoEventStatus | 'all';
  onChangeCountryId: (countryId: number) => void;
  onChangeClubId: (clubId: SelectedEventClubId) => void;
  onChangeStatus: (status: EditoEventStatus | 'all') => void;
  isFeatured: boolean;
};

const ALL_STATUS = {
  label: 'All status',
  value: 'all',
};

const ALL_CLUB = {
  label: 'All club',
  value: 'all',
};

export const EditoEventListHeader = ({
  searchText,
  setSearchText,
  selectedClubId,
  selectedCountryId,
  selectedStatus,
  onChangeClubId,
  onChangeCountryId,
  onChangeStatus,
  isFeatured,
}: Props) => {
  const countries = useAppSelector((state) => state.conf.countries);
  const countryOptions: DefaultOptionType[] = useMemo(
    () =>
      countries.map((country) => ({
        label: country.name,
        value: country.id,
      })),
    [countries]
  );

  const clubs = useAppSelector(selectClubs);
  const filteredClub = useMemo(
    () =>
      clubs
        .filter((club) => {
          return selectedCountryId
            ? selectedCountryId === club.countryId
            : true;
        })
        .map((club) => ({
          label: club.name,
          value: club.id,
        })),
    [selectedCountryId, clubs]
  );
  const clubOptions = [ALL_CLUB, ...filteredClub];

  const formattedStatus = useMemo(
    () =>
      (isFeatured ? editoEventFeaturedStatuses : editoEventCatalogStatuses).map(
        (status) => ({
          label: capitalizeFirstLetter(status),
          value: status,
        })
      ),
    [isFeatured]
  );

  const statusOptions = [ALL_STATUS, ...formattedStatus];

  return (
    <div className="editoListHeader">
      <Row gutter={[16, 16]} className="editoRow">
        <Col md={24} lg={8} xxl={6}>
          <Input
            placeholder="Search here..."
            suffix={<SearchOutlined className="certain-category-icon" />}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </Col>

        <Col md={24} lg={8} xxl={6}>
          <Row className="locationSelects">
            <Col flex={1}>
              <Select
                onChange={onChangeCountryId}
                value={selectedCountryId}
                showSearch
                options={countryOptions}
                className="first-select"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Col>
            <Col flex={1}>
              <Select
                showSearch
                disabled={!selectedCountryId}
                options={clubOptions}
                value={selectedClubId}
                className="second-select"
                onChange={onChangeClubId}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={8} lg={4} xxl={4}>
          <Select
            options={statusOptions}
            onChange={onChangeStatus}
            value={selectedStatus}
            defaultValue={'all'}
          />
        </Col>
        <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link to={'edito-events/new'}>
            <Button
              icon={<PlusOutlined className="newEditoButton" />}
              type="primary"
            >
              New
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};
