export type HotelServiceType = {
  readonly id: number;
  readonly name: string;
  readonly openingHoursRequired: boolean;
  readonly priceRequired: boolean;
  readonly priceKinds?: Array<string>;
  readonly priority: number;
  readonly serviceKinds?: Array<string>;
};

export function createHotelServiceType(values: any): HotelServiceType {
  const {
    id,
    name,
    openingHoursRequired,
    priceRequired,
    priceKinds,
    priority,
    serviceKinds,
  } = values;

  return {
    id,
    name,
    openingHoursRequired,
    priceRequired,
    priceKinds,
    priority,
    serviceKinds,
  };
}
