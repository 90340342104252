/* eslint-disable import/prefer-default-export */
import { Selector, createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/redux/store';

export const getBookingCode: Selector<RootState, string> = (state) => {
  if (state.booking.bookingDetails === null) {
    throw new Error(
      'Selector getBookingCode called without bookingDetails set'
    );
  }

  return state.booking.bookingDetails.bookingSummary.code;
};

export const getBookings = (state: RootState) => state.bookings;
export const getBookingsList = createSelector(getBookings, ({ list }) => list);
export const areBookingsLoading = createSelector(
  getBookings,
  ({ isLoading }) => isLoading
);
