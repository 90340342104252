import React from 'react';

const AdminLogo = () => (
  <svg
    width="81px"
    height="24px"
    viewBox="0 0 81 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Title</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Navbar/Bar/default"
        transform="translate(-24.000000, -24.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Title" transform="translate(24.000000, 24.000000)">
          <path
            d="M34.896,17 L35.28,15.64 L39.248,15.64 L39.632,17 L42.368,17 L38.56,5.8 L35.952,5.8 L32.16,17 L34.896,17 Z M38.672,13.608 L35.856,13.608 L37.264,8.632 L38.672,13.608 Z M46.608,17.208 C47.68,17.208 48.64,16.68 49.056,15.912 L49.056,17 L51.488,17 L51.488,5.8 L49.056,5.8 L49.056,10.392 C48.64,9.608 47.76,9.08 46.64,9.08 C44.4,9.08 42.976,10.84 42.976,13.144 C42.976,15.464 44.304,17.208 46.608,17.208 Z M47.312,15.176 C46.176,15.176 45.472,14.312 45.472,13.144 C45.472,11.976 46.176,11.128 47.312,11.128 C48.528,11.128 49.152,12.04 49.152,13.144 C49.152,14.264 48.528,15.176 47.312,15.176 Z M55.968,17 L55.968,13.032 C55.968,11.576 56.496,11.144 57.28,11.144 C58.032,11.144 58.416,11.576 58.416,12.648 L58.416,17 L60.88,17 L60.88,13.032 C60.88,11.576 61.408,11.144 62.176,11.144 C62.928,11.144 63.328,11.592 63.328,12.664 L63.328,17 L65.792,17 L65.792,12.216 C65.792,10.6 65.264,9.08 63.056,9.08 C61.92,9.08 61.008,9.672 60.592,10.504 C60.256,9.688 59.552,9.08 58.176,9.08 C57.104,9.08 56.304,9.64 55.968,10.408 L55.968,9.288 L53.52,9.288 L53.52,17 L55.968,17 Z M68.944,8.616 C69.824,8.616 70.464,7.944 70.464,7.128 C70.464,6.296 69.824,5.624 68.944,5.624 C68.064,5.624 67.44,6.296 67.44,7.128 C67.44,7.944 68.064,8.616 68.944,8.616 Z M70.16,17 L70.16,9.288 L67.728,9.288 L67.728,17 L70.16,17 Z M74.64,17 L74.64,12.968 C74.64,11.784 75.152,11.144 76.128,11.144 C77.12,11.144 77.488,11.736 77.488,13.032 L77.488,17 L79.952,17 L79.952,12.232 C79.952,10.136 79.008,9.08 77.136,9.08 C75.968,9.08 75.088,9.544 74.64,10.408 L74.64,9.288 L72.192,9.288 L72.192,17 L74.64,17 Z"
            id="Admin"
          />
          <g id="Logo">
            <path
              d="M18.1232076,0 C21.0012497,0 23.3343638,2.33311412 23.3343638,5.21115622 L23.3343638,7.98644599 C23.03672,8.00504305 20.8975348,8.61944428 20.8849548,11.6735999 C20.8723748,14.7277554 23.0359274,15.2986105 23.3343638,15.3158358 L23.3343638,18.1360435 C23.3343638,21.0140856 21.0012497,23.3471997 18.1232076,23.3471997 L5.21115622,23.3471997 C2.33311412,23.3471997 0,21.0140856 0,18.1360435 L0,5.21115622 C0,2.33311412 2.33311412,0 5.21115622,0 L18.1232076,0 Z M11.6349521,5.41758224 C9.07498638,5.41758224 7.44132404,6.79690427 7.44132404,8.86588729 C7.44132404,10.3965983 8.24973429,11.4899633 9.83287099,12.0786984 L11.9549478,12.9029274 C13.0159862,13.3234524 13.3865076,13.6935144 13.3865076,14.4168174 C13.3865076,15.2410464 12.8138837,15.7120344 11.8033709,15.7120344 C10.6075974,15.7120344 9.88339663,14.9550894 9.88339663,13.6766934 L7.15501208,13.6766934 C7.15501208,16.3175904 8.88972569,17.8651224 11.786529,17.8651224 C14.3970204,17.8651224 16.1148921,16.4353374 16.1148921,14.2317864 C16.1148921,12.6506123 15.3738494,11.5572473 13.740187,10.9180493 L11.786529,10.1442833 C10.6244393,9.67329532 10.1697086,9.2695913 10.1697086,8.61357231 C10.1697086,7.90709028 10.6918068,7.4529233 11.5844265,7.4529233 C12.6454649,7.4529233 13.2180888,8.05847929 13.2180888,9.1518443 L15.9464733,9.1518443 C15.9464733,6.88100927 14.2286016,5.41758224 11.6349521,5.41758224 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AdminLogo;
