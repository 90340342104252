export enum WeekDays {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum AvailableOn {
  CHECK_IN = 'CHECK_IN',
  MID_STAY = 'MID_STAY',
  CHECK_OUT = 'CHECK_OUT',
}

export const DEFAULT_CHECKIN = [AvailableOn.CHECK_IN];
export const DEFAULT_CHECKOUT = [AvailableOn.CHECK_OUT];
export const DEFAULT_AVAILABLE_ON = Object.values(AvailableOn);

export const EARLY_CHECKIN = 115;
export const LATE_CHECKOUT = 116;

export const getDisableLimitation = (categoryId: number, value: string) => {
  if (categoryId === EARLY_CHECKIN && value !== AvailableOn.CHECK_IN) {
    return true;
  }

  if (categoryId === LATE_CHECKOUT && value !== AvailableOn.CHECK_OUT) {
    return true;
  }

  return false;
};
