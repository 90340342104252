import { useQuery } from '@tanstack/react-query';

import { apiRequest } from 'app/utils/request/api';

type PackageTagsResponse = {
  tagIds: number[];
  isGem: boolean;
};

const path = '/packages/:packageId/tags' as const;

export function usePackageTags(packageId?: number) {
  return useQuery({
    queryKey: [path],
    queryFn: () =>
      apiRequest({
        path,
        params: { packageId: `${packageId}` },
      }) as Promise<PackageTagsResponse>,
    staleTime: 0,
    enabled: !!packageId,
  });
}
