export type SpaceType = {
  readonly id: number;
  readonly name: string;
  readonly multiple: boolean;
  readonly nameRequired: boolean;
  readonly floorRequired: boolean;
  readonly phoneRequired: boolean;
  readonly openingHoursRequired: boolean;
  readonly picturesRequired: boolean;
  readonly category: string;
};

export function createSpaceType(values: any): SpaceType {
  const {
    id,
    name,
    multiple,
    nameRequired,
    floorRequired,
    phoneRequired,
    openingHoursRequired,
    picturesRequired,
    category,
  } = values;

  return {
    id,
    name,
    multiple,
    nameRequired,
    floorRequired,
    phoneRequired,
    openingHoursRequired,
    picturesRequired,
    category,
  };
}
