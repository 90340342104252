import { Col, Form, Input, Row } from 'antd';
import { Rule } from 'antd/lib/form';

import { SubFormSection, colSizes } from '../SubFormSection';

type TranslationFormProperties = {
  label: string;
  name: string;
  placeholder: string;
}[];

interface Props {
  title: string;
  subTitle?: string;
  properties: TranslationFormProperties;
  rules?: Rule[];
}

export const TranslationSectionForm = ({
  rules,
  properties,
  title,
  subTitle,
}: Props) => {
  return (
    <SubFormSection
      title={title}
      subTitle={subTitle}
      className="quickFilterTranslation"
    >
      <Row gutter={[24, 12]}>
        {properties.map(({ label, name, placeholder }) => {
          return (
            <Col {...colSizes} key={name}>
              <Form.Item
                label={label}
                name={name}
                rules={rules}
                getValueFromEvent={(e) => {
                  if (!!e.target.value.length) {
                    const capitalizedValue: string =
                      e.target.value.charAt(0).toUpperCase() +
                      e.target.value.slice(1);

                    return capitalizedValue.trimStart();
                  }
                }}
              >
                <Input placeholder={placeholder} />
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    </SubFormSection>
  );
};
