import { Divider, Layout, Spin } from 'antd';
import { format } from 'date-fns';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { useHotelDashboardLastUpdatedAt } from 'app/hooks/data/useHotelAnalytics';
import { useAmplitudeViewPage } from 'app/hooks/useAmplitude/useAmplitude';
import { useAppSelector } from 'app/redux/hooks';
import { selectHotelAdminHotelId } from 'app/redux/selectors/hotelAdmin';
import {
  Period,
  PeriodToCompare,
  SECTIONS_ANCHOR_VALUES,
  Unit,
} from 'app/typings/analytics';
import { upperCaseFirstLetter } from 'app/utils/strings';

import { ExtranetHeader } from '../commons/ExtranetHeader/ExtranetHeader';

import './ExtranetDashboard.scss';
import DashboardHeader from './_components/DashboardHeader/DashboardHeader';
import DashboardSection from './_components/DashboardSection/DashboardSection';
import { getPeriodsDates, getRangeValue } from './commons/Utils';
import { useQueryDashboardData } from './commons/useQueryDashboardData';

const PAGE = 'Dashboard';

export const ExtranetDashboard = () => {
  const { setPage } = useContext(TaskCenterContext);
  const [scrollPosition, setScrollPosition] = useState(0);
  const ref = useRef<null | HTMLDivElement>(null);

  useAmplitudeViewPage({ page: 'dashboard' });

  const [unit, setUnit] = useState<Unit>('DAILY');
  const [period, setPeriod] = useState<Period>('last4Weeks');
  const [periodToCompare, setPeriodToCompare] =
    useState<PeriodToCompare>('previousPeriod');

  setPage(PAGE);

  const hotelId = useAppSelector(selectHotelAdminHotelId);
  const hotel = useAppSelector((state) =>
    state.hotelAdmin.hotels.find((h) => h.id === hotelId)
  );

  const [dateRange, setDateRange] = useState(getRangeValue(unit, period));

  const { datesCurrentPeriod, datesPreviousPeriod } = getPeriodsDates(
    dateRange,
    unit,
    periodToCompare
  );

  const { organizedData: results, isReady } = useQueryDashboardData({
    hotelId,
    unit,
    datesCurrentPeriod,
    datesPreviousPeriod,
    periodToCompare,
  });

  const { data } = useHotelDashboardLastUpdatedAt(hotelId);

  const menu = SECTIONS_ANCHOR_VALUES.map((anchor) => ({
    label: upperCaseFirstLetter(anchor),
    key: anchor,
  }));

  const saveScroll = () => {
    if (ref.current) {
      setScrollPosition(ref.current.scrollTop);
    }
  };

  useEffect(() => {
    if (ref.current && isReady && !!scrollPosition) {
      ref.current.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  }, [isReady, scrollPosition]);

  return (
    <Layout className="extranetDashboard">
      <ExtranetHeader
        page="Dashboard"
        subTitle={
          data?.lastUpdatedAt
            ? `Last update ${format(
                new Date(data?.lastUpdatedAt),
                'dd/MM/yyyy kk:mm'
              )}`
            : ''
        }
      />
      <CurrencyWrapper hotel={hotel}>
        <div className="mainDashboard" ref={ref}>
          <DashboardHeader
            setDateRange={setDateRange}
            dateRange={dateRange}
            unit={unit}
            setUnit={setUnit}
            setPeriod={setPeriod}
            period={period}
            setPeriodToCompare={setPeriodToCompare}
            periodToCompare={periodToCompare}
            menu={menu}
            saveScroll={saveScroll}
          />

          <div className="dataContainer">
            {!isReady && (
              <div className="loading">
                <Spin />
              </div>
            )}

            {SECTIONS_ANCHOR_VALUES.map((anchor, index) => (
              <Fragment key={anchor}>
                <DashboardSection
                  title={upperCaseFirstLetter(anchor)}
                  anchor={anchor}
                  results={results[anchor]}
                  isReady={isReady}
                  unit={unit}
                  period={period}
                  dateRange={dateRange}
                  index={index}
                  periodToCompare={periodToCompare}
                />
                {SECTIONS_ANCHOR_VALUES.length - 1 !== index && (
                  <Divider orientationMargin={32} />
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </CurrencyWrapper>
    </Layout>
  );
};

export default ExtranetDashboard;
