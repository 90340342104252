import { useQuery } from '@tanstack/react-query';

import { GiftCardDetails } from 'app/redux/models/GiftCard/GiftCard';
import { apiRequest } from 'app/utils/request/api';

const path = '/admin/gift-cards/:code' as const;

export function useGiftCardDetails(code: string) {
  return useQuery({
    queryKey: [path, { code }],
    queryFn: () =>
      apiRequest({ path, params: { code } }) as Promise<GiftCardDetails>,
    staleTime: Infinity,
  });
}
