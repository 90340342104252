import { CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Radio, Switch, TimePicker } from 'antd';
import moment from 'moment';
import { ValidateFields } from 'rc-field-form/lib/interface';
import React, { FC } from 'react';

import { OpeningHours } from '../Hotel/Types';

import './TimeRangeSection.scss';

const RULES = [{ required: true, message: 'Required' }];

export type PresetOpeningHours = {
  name: string;
  openingHours: OpeningHours;
};

export const TimeRangeSection: FC<Props> = ({
  mode = 'edit',
  openingHours,
  field,
  setFieldsValue,
  nonApplicableOption = false,
  validateFields,
  presetHours = [],
  previousPrefixes = [],
}) => {
  const { type, isAllDay } = openingHours;
  const fieldPrefix = field ? [field.name] : [];

  const toggleIsAllDay = () => {
    setFieldsValue({
      ...openingHours,
      isAllDay: !isAllDay,
    });
  };

  const toggleDayIsAllDay = (day: any) => {
    setFieldsValue({
      ...openingHours,
      days: {
        ...openingHours.days,
        [day]: {
          ...openingHours.days[day],
          isAllDay: !openingHours.days[day].isAllDay,
        },
      },
    });
  };

  const setPreset = (o: any) => {
    setFieldsValue({
      ...openingHours,
      ...o,
    });
  };

  const renderIsAllDay = () => (
    <Form.Item name={[...fieldPrefix, 'openingHours', 'isAllDay']} label=" ">
      <Button
        icon={<CloseOutlined />}
        onClick={toggleIsAllDay}
        disabled={mode === 'view'}
      >
        24/24
      </Button>
    </Form.Item>
  );

  const renderDaySection = (day: any) => (
    <div className="day-section" key={day}>
      <div className="range-row">
        <Form.Item
          name={[...fieldPrefix, 'openingHours', 'days', day, 'enabled']}
          label=" "
          rules={RULES}
          valuePropName="checked"
        >
          <Switch
            size="small"
            defaultChecked={false}
            disabled={mode === 'view'}
            onChange={() =>
              validateFields([
                [
                  ...previousPrefixes,
                  ...fieldPrefix,
                  'openingHours',
                  'innerType',
                ],
              ])
            }
          />
        </Form.Item>
        <div className="day-text">{day.toLowerCase()}</div>
      </div>
      {openingHours.days[day].enabled && (
        <>
          {openingHours.days[day].isAllDay ? (
            <Form.Item
              name={[...fieldPrefix, 'openingHours', 'days', day, 'isAllDay']}
              label=" "
            >
              <Button
                icon={<CloseOutlined />}
                onClick={() => toggleDayIsAllDay(day)}
                disabled={mode === 'view'}
              >
                24/24
              </Button>
            </Form.Item>
          ) : (
            <Form.List
              name={[...fieldPrefix, 'openingHours', 'days', day, 'slots']}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((subField, idx) => (
                    <div className="range-row" key={idx.toString()}>
                      <Form.Item {...subField} label=" " rules={RULES}>
                        <TimePicker.RangePicker
                          disabled={mode === 'view'}
                          bordered={mode === 'edit'}
                          minuteStep={30}
                          format="HH:mm"
                          renderExtraFooter={() => (
                            <div onClick={() => toggleDayIsAllDay(day)}>
                              24/24
                            </div>
                          )}
                          order={false}
                        />
                      </Form.Item>
                      {mode === 'edit' && (
                        <>
                          {idx === 0 ? (
                            <div
                              className="add-range"
                              onClick={() =>
                                add([
                                  moment('09:00:00', 'HH:mm'),
                                  moment('18:00:00', 'HH:mm'),
                                ])
                              }
                            >
                              <PlusOutlined />
                            </div>
                          ) : (
                            <div
                              className="delete-range"
                              onClick={() => remove(idx)}
                            >
                              <DeleteOutlined />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </>
              )}
            </Form.List>
          )}
        </>
      )}
    </div>
  );

  const renderBasic = () =>
    type === 'daily' ? (
      Object.keys(openingHours.days).map((day) => renderDaySection(day))
    ) : (
      <Form.List name={[...fieldPrefix, 'openingHours', 'slots']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((subField, idx) => (
              <div className="range-row" key={idx.toString()}>
                <Form.Item {...subField} label=" " rules={RULES}>
                  <TimePicker.RangePicker
                    disabled={mode === 'view'}
                    bordered={mode === 'edit'}
                    minuteStep={30}
                    format="HH:mm"
                    renderExtraFooter={() => (
                      <div onClick={toggleIsAllDay}>24/24</div>
                    )}
                    order={false}
                  />
                </Form.Item>
                {mode === 'edit' && (
                  <>
                    {idx === 0 ? (
                      <div
                        className="add-range"
                        onClick={() =>
                          add([
                            moment('09:00:00', 'HH:mm'),
                            moment('18:00:00', 'HH:mm'),
                          ])
                        }
                      >
                        <PlusOutlined />
                      </div>
                    ) : (
                      <div className="delete-range" onClick={() => remove(idx)}>
                        <DeleteOutlined />
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </>
        )}
      </Form.List>
    );

  const renderHoursConfiguration = () =>
    isAllDay && type === 'weekly' ? renderIsAllDay() : renderBasic();

  return (
    <div
      className={`time-range-section input-row ${
        mode === 'view' ? 'input-row-no-rules' : ''
      }`}
    >
      <>
        <Form.Item
          name={[...fieldPrefix, 'openingHours', 'innerType']}
          label="Opening hours"
          rules={[
            ({ getFieldValue }) => ({
              validator() {
                const newType = getFieldValue([
                  ...previousPrefixes,
                  ...fieldPrefix,
                  'openingHours',
                  'type',
                ]);

                if (!newType) {
                  return Promise.reject('Required');
                }

                if (
                  newType === 'daily' &&
                  Object.keys(openingHours.days)
                    .map((day) =>
                      getFieldValue([
                        ...previousPrefixes,
                        ...fieldPrefix,
                        'openingHours',
                        'days',
                        day,
                        'enabled',
                      ])
                    )
                    .every((d) => !d)
                ) {
                  return Promise.reject('You have to select at least one day.');
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Radio.Group
            disabled={mode === 'view'}
            defaultValue={openingHours.type}
          >
            {mode === 'edit' &&
              presetHours.map((p) => (
                <Radio
                  key={p.name}
                  value={p.name}
                  onChange={() => setPreset(p.openingHours)}
                >{`Same as ${p.name}`}</Radio>
              ))}
            <Radio
              value="weekly"
              onChange={() => setPreset({ type: 'weekly' })}
            >
              Same every day
            </Radio>
            <Radio value="daily" onChange={() => setPreset({ type: 'daily' })}>
              Depends on the day
            </Radio>
            {nonApplicableOption && (
              <Radio value="na" onChange={() => setPreset({ type: 'na' })}>
                Not applicable
              </Radio>
            )}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={[...fieldPrefix, 'openingHours', 'type']}
          style={{ display: 'none' }}
        >
          <Input type="hidden" defaultValue={openingHours.type} />
        </Form.Item>
        {type !== 'na' && renderHoursConfiguration()}
      </>
    </div>
  );
};

type Props = {
  mode?: string;
  openingHours: any;
  field?: any;
  setFieldsValue: (openingHours: any) => void;
  nonApplicableOption?: boolean;
  validateFields: ValidateFields;
  presetHours?: PresetOpeningHours[];
  previousPrefixes?: string[];
};

export default TimeRangeSection;
