import { Menu } from 'antd';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { EntityConnectProps, entityConnect } from 'app/redux/entityConnect';
import { ICountry } from 'app/typings';

import ListPage from './ListPage';

const CountryListPage: FC<Props> = ({
  title,
  countries,
  fetchEntities,
  children,
}) => {
  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const [countryId, setCountryId] = useState(1);

  const handleChangeTab = useCallback(({ key }: any) => {
    setCountryId(+key);
  }, []);

  return (
    <ListPage
      title={title}
      menu={
        <Menu
          mode="horizontal"
          onClick={handleChangeTab}
          selectedKeys={[countryId.toString()]}
        >
          {countries &&
            countries.map((c) => <Menu.Item key={c.id}>{c.name}</Menu.Item>)}
        </Menu>
      }
    >
      {children({ countryId })}
    </ListPage>
  );
};

type ChildProps = {
  countryId: number;
};

type ConnectedProps = {
  countries: ICountry[];
};

type OwnProps = {
  title: string;
  children: (props: ChildProps) => ReactNode;
};

type Props = EntityConnectProps<ConnectedProps, OwnProps>;

export default entityConnect<ConnectedProps, OwnProps>(['countries'])(
  CountryListPage
);
