import { PAX } from 'app/redux/models/PAX/PAX';

import { pluralize } from './strings';

export const formatPaxAsString = (pax: PAX) => {
  const parts: string[] = [];

  if (pax.adults > 0) {
    parts.push(`${pluralize(pax.adults, 'adult', 'adults')}`);
  }

  if (pax.children > 0) {
    parts.push(`${pluralize(pax.children, 'child', 'children')}`);
  }

  if (pax.babies > 0) {
    parts.push(`${pluralize(pax.babies, 'baby', 'babies')}`);
  }

  return parts.join(', ');
};
