import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, FormInstance, InputNumber } from 'antd';
import React, { FC } from 'react';

import { HotelRoomsForm } from '../../Types';

import './General.scss';

const General: FC<Props> = ({
  form,
  roomCount,
  onFinish,
  setRoomCountIsSet,
}) => (
  <div className="general">
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={{ roomCount }}
      onValuesChange={(changedValues, allValues) => {
        setRoomCountIsSet(!!allValues?.roomCount);
      }}
    >
      <Form.Item
        name="roomCount"
        label="Total room count"
        rules={[
          {
            required: true,
            min: 1,
            type: 'number',
            message: 'Required, min 1',
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>
    </Form>
    <div className="description">
      <InfoCircleOutlined />
      <div>All categories combined</div>
    </div>
  </div>
);

type Props = {
  form: FormInstance;
  roomCount?: number;
  onFinish: (values: HotelRoomsForm) => void;
  setRoomCountIsSet: (roomCountIsSet: boolean) => void;
};

export default General;
