import { SearchOutlined } from '@ant-design/icons';
import { Col, Form, Row, Select } from 'antd';
import { useMemo } from 'react';

import { useAppSelector } from 'app/redux/hooks';
import { selectTagList } from 'app/redux/selectors/tags';
import { requiredRule } from 'app/utils/rules';
import { isDefined } from 'app/utils/typing';

import { SubFormSection, colSizes } from '../SubFormSection';

const { Option } = Select;

export const TagsSectionForm = () => {
  const tags = useAppSelector(selectTagList);

  const filteredTags = useMemo(
    () =>
      tags
        .filter((tag) => isDefined(tag.filterCategory))
        .sort((tag1, tag2) => tag1.name.localeCompare(tag2.name)),
    [tags]
  );

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <SubFormSection title="Tags">
      <Row gutter={[24, 12]}>
        <Col {...colSizes}>
          <Form.Item name="tags" rules={[requiredRule]}>
            <Select
              showArrow
              mode="multiple"
              placeholder="Search for tags"
              filterOption={filterOption}
              suffixIcon={<SearchOutlined />}
            >
              {filteredTags.map((data) => (
                <Option key={data.id} value={data.id} label={data.name}>
                  {data.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </SubFormSection>
  );
};
