import { useQueryClient } from '@tanstack/react-query';
import { id } from 'date-fns/locale';
import queryString from 'query-string';

import { PreviewResult } from 'app/redux/models/PreviewResult/PreviewResult';
import { apiRequest } from 'app/utils/request/api';

const path = 'admin/quick-filters/preview' as const;

export const usePreviewResults = () => {
  const queryClient = useQueryClient();

  const getPreviewResults = (tagIds: number[], dayPackage: boolean) => {
    return queryClient.fetchQuery<
      PreviewResult[],
      unknown,
      PreviewResult[],
      (
        | typeof path
        | {
            id: Locale;
          }
      )[]
    >({
      queryKey: [path, { id }],
      queryFn: () =>
        apiRequest({
          path: `${path}?${queryString.stringify({ dayPackage, tagIds })}`,
        }),
    });
  };

  return {
    getPreviewResults,
  };
};
