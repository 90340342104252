import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import {
  QuickFilter,
  QuickFilterForm,
} from 'app/components/pages/QuickFilters/types';
import { apiRequest } from 'app/utils/request/api';

const path = '/admin/quick-filters/:id' as const;

export const useGetQuickFilterDetails = (id: number | undefined) => {
  const query = useQuery<
    QuickFilter,
    unknown,
    QuickFilter,
    (
      | typeof path
      | {
          id: number | undefined;
        }
    )[]
  >({
    queryKey: [path, { id }],
    queryFn: () =>
      apiRequest({
        path: path,
        params: { id: id?.toString() ?? '' },
      }),
    staleTime: Infinity,
    enabled: !!id,
  });

  const quickFilter = query.data;

  const formattedQuickFilterForm: QuickFilterForm | undefined = useMemo(() => {
    if (quickFilter) {
      return {
        id: quickFilter.id,
        pictureId: quickFilter.pictureId,
        tags: quickFilter.tags.map((tag) => parseInt(tag.toString())),
        translationEn: quickFilter.label.en,
        translationFr: quickFilter.label.fr,
        translationEs: quickFilter.label.es,
        translationKey: quickFilter.label.key,
        sliceTitleEn: quickFilter.sliceTitle.en,
        sliceTitleFr: quickFilter.sliceTitle.fr,
        sliceTitleEs: quickFilter.sliceTitle.es,
        sliceTitleKey: quickFilter.sliceTitle.key,
        slug: quickFilter.slug,
        type: quickFilter.dayPackage ? 'moment' : 'staycation',
      };
    }
  }, [quickFilter]);

  return formattedQuickFilterForm;
};
