import './EditoLocationTag.scss';

type Props = {
  location: string;
};

export const EditoLocationTag = ({ location }: Props) => {
  return (
    <div className="editoLocationTagContainer">
      <div className="title">{location}</div>
    </div>
  );
};
