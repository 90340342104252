import _ from 'lodash';
import React from 'react';

import {
  GiftCard,
  PrintGiftCardProperties,
} from 'app/redux/models/GiftCard/GiftCard';

import Line from '../Line/Line';

type Props = {
  giftCard: GiftCard & PrintGiftCardProperties;
};

const PrintProperties = ({ giftCard }: Props) => {
  const { giftCardType, color, senderName, beneficiaryName, message } =
    giftCard;

  return (
    <>
      <Line title="Type" value={_.capitalize(giftCardType)} />
      <Line title="Color" value={_.capitalize(color)} />
      <Line title="From" value={senderName} />
      <Line title="To" value={beneficiaryName} />
      <Line title="Message" value={message} />
    </>
  );
};

export default PrintProperties;
