import { Layout, Table } from 'antd';
import _ from 'lodash';
import React, { FC, useCallback, useEffect } from 'react';

import List from 'app/components/lists/List';
import ListPage from 'app/components/lists/ListPage';
import { entityCell } from 'app/components/lists/cells';
import { entityConnect } from 'app/redux/entityConnect';
import { IHotel, IMerchPackage } from 'app/typings';

const { Content } = Layout;
const { Column } = Table;

const MerchPackagesList: FC<Props> = ({
  merchPackages,
  hotels,
  loading,
  fetchEntities,
}) => {
  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const computeStatus = useCallback((pkg: IMerchPackage) => {
    const completedExperiences = _.filter(pkg.experiences, 'valueProposition');

    if (!pkg.valueProposition || !completedExperiences.length) {
      return 'Uncomplete';
    }

    return `${_.countBy(
      completedExperiences,
      'featured'
    )} featured experiences`;
  }, []);

  return (
    <ListPage title="Packages Merch">
      <Layout>
        <Content className="page__content">
          <List name="packages" entities={merchPackages} loading={loading}>
            <Column
              title="Hotel"
              dataIndex="hotelId"
              key="hotel"
              render={entityCell(hotels)}
            />
            <Column title="Status" key="status" render={computeStatus} />
          </List>
        </Content>
      </Layout>
    </ListPage>
  );
};

type Props = {
  merchPackages: IMerchPackage[];
  hotels: IHotel;
  loading: boolean;
  fetchEntities: () => void;
};

export default entityConnect(['merchPackages', 'hotels'])(MerchPackagesList);
