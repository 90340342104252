import { AnyAction } from 'redux';

import { actions } from 'app/redux/actions/entities';

import { User } from '../models/User/User';

export type Auth = {
  user?: User;
  token?: string;
};

const initialState: Auth = {};

function auth(state = initialState, action: AnyAction) {
  switch (action.type) {
    case actions.USER_LOGGED_IN:
      return { user: action.user, token: action.token };
    default:
      return state;
  }
}

export default auth;
