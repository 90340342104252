import { List } from 'antd';
import React from 'react';

type Props<T> = {
  data: Array<T>;
  renderItem: (item: T, idx: number) => React.ReactNode;
  bordered?: boolean;
  split?: boolean;
};

export const SList = <T extends object>({
  data,
  renderItem,
  bordered = false,
  split = true,
}: Props<T>) => (
  <List
    dataSource={data}
    renderItem={renderItem}
    bordered={bordered}
    split={split}
  />
);

export default SList;
