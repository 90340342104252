import { AnyAction } from '@reduxjs/toolkit';

import * as bookingsActions from 'app/redux/actions/bookings';
import type { Booking } from 'app/redux/models/Booking/Booking';

export type Bookings = {
  isLoading: boolean;
  list: Booking[];
};

export const initialState: Bookings = {
  isLoading: false,
  list: [],
};

function bookings(state = initialState, action: AnyAction): Bookings {
  if (bookingsActions.tryFetchBookings.match(action)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (bookingsActions.fetchBookingsSuccess.match(action)) {
    return {
      ...state,
      isLoading: false,
      list: action.payload.bookings,
    };
  }

  if (bookingsActions.fetchBookingsFailure.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  return state;
}

export default bookings;
