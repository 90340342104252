import { format } from 'date-fns';

import { useFetchDashboardData } from 'app/hooks/data/useHotelAnalytics';
import {
  DATA_SECTIONS,
  DATA_TYPE_ENUM_VALUE,
  DataType,
  FormattedApiResults,
  ICharts,
  PeriodToCompare,
  SECTIONS_ANCHOR_VALUES,
  SectionsAnchor,
  Unit,
} from 'app/typings/analytics';

import { formatChartsData } from './Utils';

type DashboardData = {
  hotelId?: number;
  unit: Unit;
  datesCurrentPeriod: {
    start: Date;
    end: Date;
  };
  datesPreviousPeriod: {
    start: Date;
    end: Date;
  };
  periodToCompare: PeriodToCompare;
};

export const useQueryDashboardData = ({
  hotelId,
  unit,
  datesCurrentPeriod,
  datesPreviousPeriod,
  periodToCompare,
}: DashboardData) => {
  const currentResult = useFetchDashboardData(
    [...DATA_TYPE_ENUM_VALUE],
    periodToCompare,
    {
      hotelId: hotelId,
      unit,
      startDate: format(datesCurrentPeriod.start, 'yyyy-MM-dd'),
      endDate: format(datesCurrentPeriod.end, 'yyyy-MM-dd'),
    }
  );

  const previousResults = useFetchDashboardData(
    [...DATA_TYPE_ENUM_VALUE],
    periodToCompare,
    {
      hotelId: hotelId,
      unit,
      startDate: format(datesPreviousPeriod.start, 'yyyy-MM-dd'),
      endDate: format(datesPreviousPeriod.end, 'yyyy-MM-dd'),
    }
  );

  const isLoading = currentResult.some((result) => result.isLoading);
  const isLoadingPrevious = previousResults.some((result) => result.isLoading);

  const sortedByType = [...previousResults, ...currentResult].reduce<{
    [key in DataType]: ICharts[];
  }>((acc, obj) => {
    const type = obj.data?.type as DataType;

    if (type && !acc[type]) {
      acc[type] = [];
    }

    if (type && obj.data) {
      acc[type].push(obj.data as ICharts);
    }

    return acc;
  }, {} as { [key in DataType]: ICharts[] });

  const sortedArray = Object.values(sortedByType);

  const isReady = !isLoading && !isLoadingPrevious;

  const formattedArray = sortedArray.map((array) => {
    return {
      type: array[0].type,
      dataCharts: formatChartsData(
        array[0].type,
        periodToCompare,
        array[0],
        array[1]
      ),
      dataCards: {
        previous: array[0] ? array[0].aggregatedValue : 0,
        current: array[1] ? array[1].aggregatedValue : 0,
        datesPreviousPeriod,
        datesCurrentPeriod,
      },
    };
  });

  const organizedData = SECTIONS_ANCHOR_VALUES.reduce<{
    [key in SectionsAnchor]: FormattedApiResults[];
  }>(
    (acc, section) => {
      const maxSet = Math.max(
        ...Object.values(DATA_SECTIONS).map((data) =>
          data.section === section ? data.set : 0
        )
      );

      acc[section] = Array.from({ length: maxSet }, () => ({
        cards: [],
        graphs: [],
      }));

      return acc;
    },

    {} as {
      [key in SectionsAnchor]: FormattedApiResults[];
    }
  );

  formattedArray.forEach((item) => {
    const dataSection = DATA_SECTIONS[item.type];

    if (dataSection) {
      const setIndex = dataSection.set - 1;

      organizedData[dataSection.section][setIndex].cards.push({
        type: item.type,
        data: item.dataCards,
      });
      organizedData[dataSection.section][setIndex].graphs.push({
        type: item.type,
        data: item.dataCharts,
      });
    }
  });

  return { organizedData, isReady };
};
