import { message } from 'antd';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import React, { FC, useState } from 'react';

import { useAppSelector } from 'app/redux/hooks';
import { getFilename } from 'app/utils/files';

import { Picture } from './Types';
import UploadList from './UploadList/UploadList';
import UploadZone from './UploadZone/UploadZone';

const omit = (key: any, obj: any) => {
  const { [key]: omitted, ...rest } = obj;

  return rest;
};

const options = {
  maxSizeMB: 3,
  maxWidthOrHeight: 3000,
  useWebWorker: true,
};

export const Uploader: FC<Props> = ({
  pictures,
  add,
  remove,
  showHint,
  edit,
  previewable = false,
}) => {
  const apiUrl = useAppSelector((state) => state.navigation.apiUrl);
  const [uploadingItems, setUploadingItems] = useState({});
  const upsertUploadingItem = (key: any, value: any) =>
    setUploadingItems((previousUploadingItems) => ({
      ...previousUploadingItems,
      [key]: value,
    }));
  const removeUploadingItem = (key: any) =>
    setUploadingItems((previousUploadingItems) =>
      omit(key, previousUploadingItems)
    );

  const uploadToS3 = async (formData: any, fileName: any, key: any) => {
    try {
      const uploaded = await axios.post(`${apiUrl}/pictures`, formData, {
        onUploadProgress: (progress) =>
          upsertUploadingItem(key, progress.loaded / progress.total),
      });

      if (uploaded.status !== 200) {
        throw new Error(uploaded.statusText);
      }

      return uploaded;
    } catch (error) {
      const errorMessage =
        (error as any).message === 'Network Error'
          ? 'Network Error: connexion lost temporarily'
          : (error as any).message;

      throw new Error(`Error while uploading ${fileName}: ${errorMessage}`);
    }
  };

  const upload = async ({ file }: any) => {
    const key = `${file.uid}`;

    try {
      // Compress file
      upsertUploadingItem(key, 0);

      const compressedFile = await imageCompression(file, options);

      const fileName = getFilename(file.name);
      const formData = new FormData();

      formData.append('file', compressedFile);

      const uploaded = await uploadToS3(formData, fileName, key);

      removeUploadingItem(key);

      if (pictures.find((pic) => pic.pictureId === uploaded.data.pictureId)) {
        throw new Error(`Same file already uploaded : ${fileName}`);
      }

      add(uploaded.data);
    } catch (error) {
      message.error((error as any).message);
      removeUploadingItem(key);
    }
  };

  return (
    <>
      <UploadZone
        upload={upload}
        highlightedRequirement={pictures.length < 20}
        showHint={showHint}
      />
      <UploadList
        pictures={pictures}
        uploadingItems={uploadingItems}
        remove={remove}
        edit={edit}
        previewable={previewable}
        fromUploader
      />
    </>
  );
};

type Props = {
  pictures: Array<Picture>;
  add: (picture: Picture) => void;
  showHint?: boolean;
  remove?: (uid: string) => void;
  edit?: (picture?: Picture) => void;
  previewable?: boolean;
};

export default Uploader;
