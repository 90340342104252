import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const selectExperiences = (state: RootState) => state.experiences;

export const getHotelExperiences = createSelector(
  selectExperiences,
  (experiences) => experiences.hotelExperiences
);

export const getExperiencesExperiences = createSelector(
  selectExperiences,
  (experiences) => experiences.experiences
);

export const getExperiencesLoading = createSelector(
  selectExperiences,
  (experiences) => experiences.loading
);
