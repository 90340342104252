import React, { FC, useState } from 'react';

import { Picture } from 'app/components/commons/Uploader/Types';
import Uploader from 'app/components/commons/Uploader/Uploader';

import HeaderWrapper from '../HeaderWrapper/HeaderWrapper';

import './MediaForm.scss';

const MediaForm: FC<Props> = ({ handleSubmit, initialValue }) => {
  const [pictures, setPictures] = useState<Array<Picture>>(initialValue);
  const add = (picture: any) =>
    setPictures((previousPictures) => [...previousPictures, picture]);
  const remove = (uid: any) =>
    setPictures((previousPictures) => {
      const index = previousPictures.findIndex(
        (pic) => uid === `${pic.pictureId}-${pic.id}`
      );

      if (index < 0) {
        return previousPictures;
      }

      return [
        ...previousPictures.slice(0, index),
        ...previousPictures.slice(index + 1),
      ];
    });
  const submit = () => handleSubmit(pictures);

  return (
    <HeaderWrapper nextDisabled={pictures.length < 20} handleNext={submit}>
      <div className="media-form-container">
        <div className="media-form">
          <div className="media-form-header">
            <div className="title">Photos</div>
            <div className="explanation">
              Upload all your photos here (hotel, spaces, rooms…), you’ll use
              them to complete the next steps
            </div>
          </div>
          <div className="media-form-body">
            <Uploader
              pictures={pictures}
              add={add}
              remove={remove}
              previewable
              showHint
            />
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};

type Props = {
  handleSubmit: (pictures: Array<Picture>) => void;
  initialValue: Array<Picture>;
};

export default MediaForm;
