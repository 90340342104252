import { message } from 'antd';
import queryString from 'query-string';
import { all, put, takeEvery } from 'redux-saga/effects';

import * as customTagsActions from 'app/redux/actions/customTags';
import { get, post, requestGenerator } from 'app/redux/requests';

const URL = 'tags';

export function* tryFetchCustomTags() {
  yield requestGenerator(
    () => put(customTagsActions.startLoading()),
    () => {
      const stringifiedParams = queryString.stringify({
        tagType: 'package_custom',
      });

      return get(`${URL}?${stringifiedParams}`);
    },
    (result: any) =>
      put(customTagsActions.fetchCustomTagsSuccess({ customTags: result })),
    (error: any) =>
      all([
        put(customTagsActions.stopLoading()),
        message.error('Error fetching CustomTags', error),
      ])
  );
}

export function* tryCreateCustomTag({
  payload,
}: ReturnType<typeof customTagsActions.tryCreateCustomTag>) {
  const { name } = payload;

  yield requestGenerator(
    () => Promise.resolve(),
    () => post(`${URL}`, { name }),
    () => put(customTagsActions.tryFetchCustomTags()),
    (error: any) => message.error('Error creating CustomTag', error)
  );
}

export default function* customTagsSaga() {
  yield takeEvery(
    customTagsActions.CUSTOM_TAGS_TRY_FETCH_CUSTOM_TAGS,
    tryFetchCustomTags
  );
  yield takeEvery(
    customTagsActions.CUSTOM_TAGS_TRY_CREATE_CUSTOM_TAG,
    tryCreateCustomTag
  );
}
