import { Club } from 'app/redux/models/Club/Club';
import { Country } from 'app/redux/models/Country/Country';
import { IEditoEventList, IEditoEventListResponse } from 'app/typings/edito';
import { getEditoEventStatus } from 'app/utils/edito';

export const formatEditoEventListResponseToList = (
  editoEvents: IEditoEventListResponse[],
  countries: Country[],
  clubs: Club[]
): IEditoEventList[] => {
  return editoEvents.map((event) => ({
    countryId: event.countryId,
    picture: event.picture,
    id: event.id,
    published: event.published,
    title: event.title,
    clubId: event.clubId,
    displayOrder: event.displayOrder,
    status:
      event.startDate && event.endDate
        ? getEditoEventStatus({
            endDate: event.endDate,
            published: event.published,
            startDate: event.startDate,
            publishedAt: event.publishedAt,
          })
        : 'draft',
    countryLabel:
      countries.find((country) => country.id === event.countryId)?.name ?? '',
    clubLabel: clubs.find((club) => club.id === event.clubId)?.name,
    updatedAt: new Date(event.updatedAt),
    startDate: event.startDate && new Date(event.startDate),
    publishedAt: event.publishedAt ? new Date(event.publishedAt) : undefined,
    endDate: event.endDate && new Date(event.endDate),
  }));
};
