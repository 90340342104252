import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React from 'react';

import Pagination from 'app/components/commons/Range/Pagination/Pagination';

const PaginatedSearchHeader = ({
  onSearch,
  currentSearch,
  ...props
}: Props) => {
  const onChange = (evt: any) => {
    onSearch(evt.target.value);
  };

  return (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={currentSearch}
          onChange={onChange}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
      </div>
      <Pagination className="button-wrapper" {...props} />
    </div>
  );
};

type Props = {
  onSearch: (value: string) => void;
  currentSearch?: string;
  currentPage: number;
  setPageNumber: (nextPage: number) => void;
  isLastPage: boolean;
};

export default PaginatedSearchHeader;
