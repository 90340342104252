import { FilterCustomTagsPayload } from 'app/redux/actions/customTags';
import type { CustomTag } from 'app/redux/models/CustomTag/CustomTag';
import { SortOrder } from 'app/utils/sort';

// This function normalizes the string for comparison purposes
export const cleanString = (str: string) =>
  str
    // change diacritics to their base characters
    .normalize('NFD')
    // remove all non-alphanumeric characters
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
    // lowercase the string
    .toLowerCase();

type DataBySearch = {
  [key: string]: any;
  name: string;
};

const filterBySearch = (dataList: DataBySearch[], search?: string) => {
  if (!search) {
    return dataList;
  }

  const cleanedSearch = cleanString(search);

  return dataList.filter(({ name }) => {
    const cleanedName = cleanString(name);

    return cleanedName.includes(cleanedSearch);
  });
};

type DataByCreationDate = {
  [key: string]: any;
  createdAt: string;
};

const sortByCreationDate = (
  dataList: DataByCreationDate[],
  dateSorting: SortOrder
) => {
  if (dateSorting === 'none') {
    return dataList;
  }

  return dataList.sort((dataA, dataB) => {
    const valueOfA = new Date(dataA.createdAt).valueOf();
    const valueOfB = new Date(dataB.createdAt).valueOf();

    return dateSorting === 'descend'
      ? valueOfB - valueOfA
      : valueOfA - valueOfB;
  });
};

const selectByOffsetAndLimit = (
  dataList: object[],
  offset: any,
  limit: any
) => {
  return dataList.slice(offset, offset + limit);
};

export const sortAndFilterCustomTags = (
  customTags: CustomTag[],
  params: FilterCustomTagsPayload
): CustomTag[] => {
  const { search, dateSorting, offset, limit } = params;

  const filtered = filterBySearch(customTags, search) as CustomTag[];

  const sorted = sortByCreationDate(filtered, dateSorting) as CustomTag[];

  const selected = selectByOffsetAndLimit(sorted, offset, limit) as CustomTag[];

  return selected;
};
