import { Modal, ModalFuncProps } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';

type NotificationProps = {
  title?: string;
  content?: React.ReactNode;
  buttonOkText?: string;
  buttonCancelText?: string;
  visible: boolean;
  type?: ModalFuncProps['type'];
  onOk?: () => void;
  onCancel?: () => void;
  hasCancelButton?: boolean;
  centered?: boolean;
  style?: React.CSSProperties;
};

export const Notification = ({
  title,
  content,
  buttonOkText,
  buttonCancelText,
  visible,
  type = 'success',
  onOk,
  onCancel,
  hasCancelButton = true,
  centered = false,
  style,
}: NotificationProps) => {
  const config: ModalFuncProps = useMemo(
    () => ({
      title,
      content,
      okText: buttonOkText || 'OK',
      cancelText: buttonCancelText || 'Cancel',
      onOk,
      onCancel,
      cancelButtonProps: {
        style: !hasCancelButton ? { display: 'none' } : {},
      },
      style,
      centered,
      type,
    }),
    [
      title,
      content,
      buttonOkText,
      buttonCancelText,
      onOk,
      onCancel,
      hasCancelButton,
      centered,
      style,
      type,
    ]
  );

  const showModal = useCallback(() => {
    switch (type) {
      case 'info':
        Modal.info(config);
        break;
      case 'warning':
        Modal.warning(config);
        break;
      case 'error':
        Modal.error(config);
        break;
      case 'confirm':
        Modal.confirm(config);
        break;
      default:
        Modal.success(config);
        break;
    }
  }, [type, config]);

  useEffect(() => {
    if (visible) {
      showModal();
    }
  }, [visible, showModal]);

  return null;
};

export default Notification;
