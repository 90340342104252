import { Layout } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import 'styles/layout/page.scss';

const { Content, Header } = Layout;

const Page = ({ title, children }) => (
  <Layout className="page__container">
    <Header className="page__list-header">
      <h1>{title}</h1>
    </Header>
    <Content className="page__body">{children}</Content>
  </Layout>
);

Page.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Page;
