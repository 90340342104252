import { Button, Progress } from 'antd';
import React, { ReactNode } from 'react';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';

import { Task, TaskSource } from '../Types';

type TasksProps = {
  taskInfo: Task;
  clickButton?: () => void;
  renderModal?: (onCompleteTask: () => void) => ReactNode;
  source: TaskSource;
  fromDashboard?: boolean;
};

export const Tasks = ({
  taskInfo,
  clickButton,
  renderModal,
  source,
  fromDashboard = false,
}: TasksProps) => {
  const { track } = useAmplitude();

  const updateTask = () => {
    clickButton && clickButton();
  };

  const onCompleteTask = () => {
    track('Complete Task', {
      taskName: taskInfo.taskName,
      source,
    });
  };

  if (fromDashboard) {
    return (
      <div className="dashboardTask" key={taskInfo.taskName}>
        <div className="task_content">
          {taskInfo.icon}
          <div className="title_description">
            <p className="title">{taskInfo.title}</p>
            <p className="description">{taskInfo.description}</p>
          </div>
        </div>
        <Button onClick={clickButton} className="updateButton">
          Update
        </Button>
      </div>
    );
  }

  return (
    <div className="section_item">
      <div className="section_item_info">
        <div className="section_item_info_icon">{taskInfo.icon}</div>
        <div className="section_item_info_text">
          <p className="title">{taskInfo.title}</p>
          <p className="description">{taskInfo.description}</p>
        </div>
        <div className="section_item_info_progress">
          <Progress
            percent={taskInfo.percent}
            showInfo={false}
            strokeColor={'#FF2E63'}
            size="small"
            className="bar"
          />
          <p className="score">
            {taskInfo.value === 0 ? 0 : taskInfo.max}/{taskInfo.max}
          </p>
        </div>
      </div>
      <Button size="large" type="ghost" onClick={updateTask}>
        Update
      </Button>
      {renderModal && renderModal(onCompleteTask)}
    </div>
  );
};
