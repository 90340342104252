import { message } from 'antd';
import { all, takeEvery } from 'redux-saga/effects';

import * as vouchersActions from 'app/redux/actions/vouchers';
import { post, requestGenerator } from 'app/redux/requests';

const URL = 'vouchers';

export function* trySaveVoucher({
  payload,
}: ReturnType<typeof vouchersActions.trySaveVoucher>) {
  yield requestGenerator(
    () => Promise.resolve(),
    () => post(URL, payload),
    () => message.success('Voucher successfully created'),
    (error: any) => message.error('Error creating Voucher', error),
    { expectJson: false }
  );
}

export default function* vouchersSaga() {
  yield all([
    takeEvery(vouchersActions.VOUCHERS_TRY_SAVE_VOUCHER, trySaveVoucher),
  ]);
}
