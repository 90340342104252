export const rangeNumbers = (start: number, end: number) => {
  if (end < start) {
    throw new Error('End is inferior or equal to start');
  }

  if (end === start) {
    return [];
  }

  const length = end - start;

  return Array.from<number>({ length }).map((_, index) => index + start);
};
