import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import EditPictureModal from 'app/components/commons/EditPictureModal/EditPictureModal';
import UploadList from 'app/components/commons/Uploader/UploadList/UploadList';
import { noop } from 'app/utils/functions';

import { Picture } from '../Uploader/Types';

import './CoverPicture.scss';
import CoverPictureModal, {
  ISelectablePicture,
} from './CoverPictureModal/CoverPictureModal';

type Value = Picture | undefined;

type Props = {
  value?: Value;
  onChange?: (value: Value) => void;
  pictures?: ISelectablePicture[];
  disabled?: boolean;
  id?: string;
};

export const CoverPicture = ({
  value,
  onChange,
  pictures,
  disabled,
  id,
}: Props) => {
  const [internalValue, setInternalValue] = useState<Value>(value);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pictureToEdit, setPictureToEdit] = useState<Value>();

  // When the value prop changes, let's reset the internal value.
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  if (!onChange) {
    return null;
  }

  const handleOnChange = (newValue: Value) => {
    setInternalValue(newValue);
    onChange(newValue);
  };

  const onOk = (newPicture: Value) => {
    handleOnChange(newPicture);
    setModalIsOpen(false);
  };

  const savePicture = (newPictureValues: Picture) => {
    handleOnChange({ ...pictureToEdit, ...newPictureValues });
    setPictureToEdit(undefined);
  };

  const deletePicture = () => {
    handleOnChange(undefined);
    setPictureToEdit(undefined);
  };

  return (
    <div className="cover-picture" id={id}>
      <UploadList
        pictures={internalValue ? [internalValue] : []}
        uploadingItems={{}}
        onChange={noop}
        edit={setPictureToEdit}
        disabled={disabled}
        customButton={
          <div onClick={() => setModalIsOpen(true)}>
            <PlusOutlined />
            <span className="text">{internalValue ? 'Replace' : 'Add'}</span>
          </div>
        }
      />
      <CoverPictureModal
        open={modalIsOpen}
        title="Cover"
        onOk={onOk}
        onCancel={() => setModalIsOpen(false)}
        pictures={pictures}
      />
      {!!pictureToEdit && (
        <EditPictureModal
          picture={pictureToEdit}
          closeModal={() => setPictureToEdit(undefined)}
          savePicture={savePicture}
          deletePicture={deletePicture}
        />
      )}
    </div>
  );
};

export default CoverPicture;
