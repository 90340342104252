import { Collapse, Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { BillingContact } from 'app/components/commons/BillingContact/BillingContact';
import { BookingContact } from 'app/components/commons/BookingContact/BookingContact';
import { GuestContact } from 'app/components/commons/GuestContact/GuestContact';
import { SaleContact } from 'app/components/commons/SaleContact/SaleContact';
import SearchBox from 'app/components/fields/SearchBox';
import FormSection from 'app/components/forms/FormSection';
import { Hotel } from 'app/redux/models/Hotel/Hotel';
import {
  ChannelManagerDataSource,
  ChannelManagers,
} from 'app/utils/channelManagers/channelManagers';
import { RateModesDataSource } from 'app/utils/channelManagers/rateModes';

import './Communication.scss';
import style from './Communication.style';

type Props = {
  mode: 'view' | 'edit';
  values: Hotel;
  setFieldsValue: (value: Store) => void;
  originalBillingEmails: string[] | undefined;
};

const RULES = [{ required: true }];

const Communication = ({
  mode,
  values,
  setFieldsValue,
  originalBillingEmails,
}: Props) => {
  const communicationRef = useRef<null | HTMLDivElement>(null);
  const { hash } = useLocation();

  useLayoutEffect(() => {
    if (
      communicationRef &&
      communicationRef.current &&
      hash === '#communication'
    ) {
      communicationRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [communicationRef, hash]);

  const isDisabled = mode === 'view';

  return (
    <div ref={communicationRef} className="communicationContainer">
      <FormSection title="Communication">
        <Collapse
          bordered={false}
          defaultActiveKey={[
            'sale-contact',
            'notif-contact',
            'guest-contact',
            'billing-contact',
          ]}
        >
          <SaleContact mode={mode} key="sale-contact" />

          <BookingContact
            key="notif-contact"
            mode={mode}
            notifEmails={values.notifEmails}
            billingEmails={values.billingEmails}
            setNotifEmails={(notifEmails) => {
              setFieldsValue({ ...values, notifEmails });
            }}
            channelManager={
              <>
                <Form.Item
                  label="Channel manager"
                  name="channelManager"
                  rules={RULES}
                >
                  <SearchBox
                    dataSource={ChannelManagerDataSource}
                    mode={mode}
                    style={style.searchBoxField}
                    disabled={isDisabled}
                  />
                </Form.Item>
                {values.channelManager &&
                  values.channelManager !== ChannelManagers.NONE && (
                    <Form.Item
                      label="Connected rates"
                      name="rateMode"
                      rules={RULES}
                    >
                      <SearchBox
                        dataSource={RateModesDataSource}
                        mode={mode}
                        style={style.searchBoxField}
                        disabled={isDisabled}
                      />
                    </Form.Item>
                  )}
              </>
            }
          />
          <GuestContact mode={mode} key="guest-contact" />
          <BillingContact
            mode={mode}
            key="billing-contact"
            originalBillingEmails={originalBillingEmails}
            notifEmails={values.notifEmails}
          />
        </Collapse>
      </FormSection>
    </div>
  );
};

export default Communication;
