import React, { FC, ReactElement, ReactNode } from 'react';

import './SCard.scss';

const SCard: FC<Props> = ({
  title,
  subtitle = '',
  className = '',
  isExpanded = true,
  noHeaderBorder = false,
  noBorderContent = false,
  leftHeader,
  rightHeader,
  children,
}) => (
  <div className={`staycation-card ${className}`}>
    <div
      className={`staycation-card__header ${
        !isExpanded || noHeaderBorder ? 'staycation-card__header-no-border' : ''
      }`}
    >
      <div className="staycation-card__header-left">
        {leftHeader}
        <div className="staycation-card__header-text">
          <div className="staycation-card__title">{title}</div>
          {!!subtitle && (
            <div className="staycation-card__subtitle">{subtitle}</div>
          )}
        </div>
      </div>
      {rightHeader}
    </div>
    {!!isExpanded && (
      <div
        className={`staycation-card__content ${
          noBorderContent ? 'staycation-card__content_no-padding' : ''
        }`}
      >
        {children}
      </div>
    )}
  </div>
);

type Props = {
  title: string;
  subtitle?: string;
  className?: string;
  isExpanded?: boolean;
  noHeaderBorder?: boolean;
  noBorderContent?: boolean;
  leftHeader?: ReactElement;
  rightHeader?: ReactElement;
  children?: ReactNode;
};

export default SCard;
