import { Button, Form, Layout } from 'antd';
import React from 'react';

import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { ISlide } from 'app/typings/slides';

import styles from './SliderForm.module.scss';
import { SlidesList } from './SlidesList';
import { SliderFormInitialValues, SliderFormValidValues } from './types';

const { Content, Footer } = Layout;

type SliderFormProps = {
  clubId: number;
  initialValues: SliderFormInitialValues;
  onFinish: (formValues: SliderFormValidValues) => void;
  isSubmitting: boolean;
};

export const SliderForm = ({
  clubId,
  initialValues,
  onFinish,
  isSubmitting,
}: SliderFormProps) => {
  const [form] = Form.useForm();

  return (
    <>
      <Content className={styles.content}>
        <FormLegacyRenderProp
          form={form}
          initialValues={initialValues}
          onFinish={onFinish}
        >
          {(values, { setFieldsValue }) => {
            return (
              <Form.List name="slides">
                {(fields, { add, remove, move }) => (
                  <SlidesList
                    clubId={clubId}
                    values={values.slides}
                    fields={fields}
                    add={add}
                    remove={remove}
                    move={move}
                    update={(slideData: Partial<ISlide>, index: number) => {
                      const slides = values.slides.map(
                        (currentSlide, currentIndex) => {
                          if (index === currentIndex) {
                            return { ...currentSlide, ...slideData };
                          }

                          return currentSlide;
                        }
                      );

                      setFieldsValue({ slides });
                    }}
                  />
                )}
              </Form.List>
            );
          }}
        </FormLegacyRenderProp>
      </Content>

      <Footer className={styles.footer}>
        <Button
          type="primary"
          size="large"
          loading={isSubmitting}
          disabled={!form.isFieldsTouched() || isSubmitting}
          onClick={form.submit}
        >
          Save
        </Button>
      </Footer>
    </>
  );
};
