import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const selectCollections = (state: RootState) => state.collections;
export const selectCollection = createSelector(
  selectCollections,
  (collections) => collections.collection
);
export const selectCollectionsList = createSelector(
  selectCollections,
  (collections) => collections.collections
);
export const selectCollectionLoading = createSelector(
  selectCollections,
  (collections) => collections.loading
);
export const selectCollectionPreviews = createSelector(
  selectCollections,
  (collections) =>
    collections.previews.sort((a, b) => a.popularity - b.popularity)
);
