import {
  addWeeks,
  differenceInCalendarDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  endOfISOWeek,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getWeek,
  startOfISOWeek,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

import {
  BIMESTER_PERIOD,
  DataType,
  DatesPeriod,
  FORTNIGHT_PERIOD,
  ICharts,
  Period,
  PeriodToCompare,
  QUATERLY_PERIOD,
  Unit,
  YEARLY_PERIOD,
} from 'app/typings/analytics';
import { assertNever } from 'app/utils/typing';

export const getPeriodsDates = (
  dateRange: Date[],
  unit: Unit,
  periodToCompare: PeriodToCompare
) => {
  const datesCurrentPeriod = {
    start: roundDateLowerBound(dateRange[0], unit),
    end: roundDateUpperBound(dateRange[1], unit),
  };

  const datesPreviousPeriod =
    periodToCompare === 'previousPeriod'
      ? getPreviousPeriodDates(
          datesCurrentPeriod,
          dateRange[0],
          dateRange[1],
          unit
        )
      : getLastYearPeriodDates(datesCurrentPeriod, unit);

  return { datesCurrentPeriod, datesPreviousPeriod };
};

export const formatChartsData = (
  datatype: DataType,
  periodToCompare: PeriodToCompare,
  previousPeriod?: ICharts,
  currentPeriod?: ICharts
) => {
  if (!currentPeriod || !previousPeriod) {
    return [];
  }

  const formatCurrentPeriod = currentPeriod.data.map((item) => {
    return {
      category: getDataTypeWording(datatype, periodToCompare)
        ?.chartCurrentLineName,
      value: item.value,
      date: item.startDate,
      item: item,
    };
  });

  const formatPrevPeriod = previousPeriod.data.map((item, i) => {
    return {
      category: getDataTypeWording(datatype, periodToCompare)
        ?.chartPreviousLineName,
      value: item.value,
      date: formatCurrentPeriod[i]?.date || item.startDate,
      item: item,
    };
  });

  return formatPrevPeriod.concat(formatCurrentPeriod);
};

export const getRangeValue = (
  unit: Unit,
  period?: Period,
  range?: Date[]
): [Date, Date] => {
  switch (period) {
    case 'last7Days':
      return [subDays(new Date(), 6), new Date()];
    case 'last4Weeks':
      return [
        subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 3),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ];
    case 'last3Months':
      return [subMonths(startOfMonth(new Date()), 2), endOfMonth(new Date())];
    case 'last12Months':
      return [subMonths(startOfMonth(new Date()), 11), endOfMonth(new Date())];

    default:
      if (range) {
        return [
          roundDateLowerBound(range[0], unit),
          roundDateUpperBound(range[1], unit),
        ];
      }

      return [subWeeks(new Date(), 6), addWeeks(new Date(), 6)];
  }
};

const getLastYearPeriodDates = (
  datesCurrentPeriod: DatesPeriod,
  unit: Unit
) => {
  const getLastYearWeekNumber = (date: Date) => {
    const currentWeekNumber = getWeek(date, {
      weekStartsOn: 0,
    });

    const lastYearWeekNumber = getWeek(
      roundDateLowerBound(subYears(date, 1), unit)
    );

    const diff = currentWeekNumber - lastYearWeekNumber;

    return diff > 0
      ? addWeeks(roundDateLowerBound(subYears(date, 1), unit), diff)
      : subWeeks(roundDateLowerBound(subYears(date, 1), unit), Math.abs(diff));
  };

  if (unit === 'WEEKLY') {
    const startLastYearDate = getLastYearWeekNumber(datesCurrentPeriod.start);
    const diff = differenceInWeeks(
      datesCurrentPeriod.end,
      datesCurrentPeriod.start
    );

    return {
      start: startLastYearDate,
      end: addWeeks(startLastYearDate, diff),
    };
  }

  return {
    start: subYears(datesCurrentPeriod.start, 1),
    end: subYears(datesCurrentPeriod.end, 1),
  };
};

const getPreviousPeriodDates = (
  datesCurrentPeriod: DatesPeriod,
  dateSelectStart: Date,
  dateSelectEnd: Date,
  unit: Unit
) => {
  const endDate = subDays(datesCurrentPeriod.start, 1);

  const getStartPreviousDate = () => {
    switch (unit) {
      case 'DAILY':
        return subDays(
          endDate,
          differenceInCalendarDays(
            roundDateUpperBound(dateSelectEnd, unit),
            roundDateLowerBound(dateSelectStart, unit)
          )
        );
      case 'WEEKLY':
        return subWeeks(
          endDate,
          differenceInWeeks(
            roundDateUpperBound(dateSelectEnd, unit),
            roundDateLowerBound(dateSelectStart, unit)
          )
        );
      case 'MONTHLY':
        return subMonths(
          endDate,
          differenceInMonths(
            roundDateUpperBound(dateSelectEnd, unit),
            roundDateLowerBound(dateSelectStart, unit)
          )
        );
      case 'YEARLY':
        return subYears(
          endDate,
          differenceInYears(
            roundDateUpperBound(dateSelectEnd, unit),
            roundDateLowerBound(dateSelectStart, unit)
          )
        );
      default:
        assertNever(unit);
    }
  };

  return {
    start: roundDateLowerBound(getStartPreviousDate(), unit),
    end: roundDateUpperBound(endDate, unit),
  };
};

const roundDateLowerBound = (date: Date, unit: Unit) => {
  switch (unit) {
    case 'WEEKLY':
      return startOfWeek(date, { weekStartsOn: 1 });
    case 'MONTHLY':
      return startOfMonth(date);
    case 'YEARLY':
      return startOfYear(date);
    case 'DAILY':
      return date;
    default:
      assertNever(unit);
  }
};

const roundDateUpperBound = (date: Date, unit: Unit) => {
  switch (unit) {
    case 'WEEKLY':
      return endOfWeek(date, { weekStartsOn: 1 });
    case 'MONTHLY':
      return endOfMonth(date);
    case 'YEARLY':
      return endOfYear(date);
    case 'DAILY':
      return date;
    default:
      assertNever(unit);
  }
};

enum DifferenceType {
  POSITIVE = 'success',
  NEUTRAL = 'neutral',
  NEGATIVE = 'danger',
}

export const getPercentage = (currentValue: number, previousValue: number) => {
  if (previousValue === 0) {
    return {
      percentage: 0,
      type: DifferenceType.NEUTRAL,
    };
  }

  const percentage = ((currentValue - previousValue) / previousValue) * 100;
  let type: DifferenceType;

  if (percentage > 0) {
    type = DifferenceType.POSITIVE;
  } else if (percentage < 0) {
    type = DifferenceType.NEGATIVE;
  } else {
    type = DifferenceType.NEUTRAL;
  }

  return {
    percentage: Math.round(percentage),
    type: type,
  };
};

export type DataSuffix = 'currency' | 'percent';

export type DataTypeWording = {
  title: string;
  chartCurrentLineName: string;
  chartPreviousLineName: string;
  hasSuffix?: DataSuffix;
  hasChart: boolean;
  explanation?: string;
};

export const getDataTypeWording = (
  dataType: DataType,
  periodToCompare: PeriodToCompare
): DataTypeWording => {
  const periodWording = getPeriodWording(periodToCompare);

  switch (dataType) {
    case 'REVENUE_CHECKIN':
      return {
        title: 'Check-ins revenue',
        chartCurrentLineName: 'Current revenue',
        chartPreviousLineName: `${periodWording} revenue`,
        hasSuffix: 'currency',
        hasChart: true,
      };
    case 'REVENUE_PAYMENT':
      return {
        title: 'Bookings revenue',
        chartCurrentLineName: 'Current revenue',
        chartPreviousLineName: `${periodWording} revenue`,
        hasSuffix: 'currency',
        hasChart: true,
      };
    case 'BOOKINGS_CHECKIN':
      return {
        title: 'Check-ins volume',
        chartCurrentLineName: 'Current check-ins',
        chartPreviousLineName: `${periodWording} check-ins`,
        hasChart: true,
      };
    case 'BOOKINGS_PAYMENT':
      return {
        title: 'Bookings volume',
        chartCurrentLineName: 'Current bookings',
        chartPreviousLineName: `${periodWording} bookings`,
        hasChart: true,
        explanation: undefined,
      };
    case 'ADDON_REVENUE':
      return {
        title: 'Add-on revenue',
        chartCurrentLineName: 'Current addon revenue',
        chartPreviousLineName: `${periodWording} addon revenue`,
        hasChart: true,
        hasSuffix: 'currency',
      };
    case 'ADDON_RATIO':
      return {
        title: '% bookings with Add-on',
        chartCurrentLineName: 'Current addon percentage',
        chartPreviousLineName: `${periodWording} addon percentage`,
        hasSuffix: 'percent',
        hasChart: false,
      };
    case 'UPGRADE_REVENUE':
      return {
        title: 'Upgrade revenue',
        chartCurrentLineName: 'Current upgrade revenue',
        chartPreviousLineName: `${periodWording} upgrade revenue`,
        hasChart: true,
        hasSuffix: 'currency',
      };
    case 'UPGRADE_RATIO':
      return {
        title: '% bookings with Upgrade',
        chartCurrentLineName: 'Current upgrade percentage',
        chartPreviousLineName: `${periodWording} upgrade percentage`,
        hasSuffix: 'percent',
        hasChart: false,
      };
    case 'STOCK':
      return {
        title: 'Stock to sell',
        chartCurrentLineName: 'Current stock',
        chartPreviousLineName: `${periodWording} stock`,
        hasChart: true,
      };
    case 'HVS_STOCK':
      return {
        title: 'High demand stock to sell',
        chartCurrentLineName: 'Current high demand stock',
        chartPreviousLineName: `${periodWording} high demand stock`,
        hasChart: true,
        explanation:
          'High demand days are Fridays and Saturdays. These stocks are 80% more likely to get booked.',
      };
    case 'PAGE_VIEWS':
      return {
        title: 'Page views',
        chartCurrentLineName: 'Current page views',
        chartPreviousLineName: `${periodWording} page views`,
        hasChart: true,
      };
    case 'CVR_7D':
      return {
        title: 'Average daily conversion',
        chartCurrentLineName: 'Current conversion',
        chartPreviousLineName: `${periodWording} conversion`,
        hasSuffix: 'percent',
        hasChart: true,
        explanation:
          'This is the percentage of users who booked after viewing your page.',
      };
    case 'WISHLISTED':
      return {
        title: 'Wishlisted',
        chartCurrentLineName: 'Current wishlisted',
        chartPreviousLineName: `${periodWording} wishlisted`,
        hasChart: false,
      };

    default:
      assertNever(dataType);
  }
};

export const getPeriodWording = (periodToCompare: PeriodToCompare) => {
  return periodToCompare === 'lastYear' ? 'Last year' : 'Previous period';
};

export const formatChartDateWithUnit = (
  unit: Unit,
  date: Date,
  dateRange: Date[]
) => {
  const nbDaysBetweenRange = differenceInCalendarDays(
    dateRange[1],
    dateRange[0]
  );

  switch (unit) {
    case 'WEEKLY':
      return `${format(startOfISOWeek(date), 'dd')} - ${format(
        endOfISOWeek(date),
        nbDaysBetweenRange <= YEARLY_PERIOD ? 'dd MMM' : 'dd MMM yy'
      )}`;
    case 'MONTHLY':
      return format(
        date,
        nbDaysBetweenRange <= YEARLY_PERIOD ? 'MMM' : 'MMM yy'
      );
    case 'YEARLY':
      return format(date, 'yyyy');
    case 'DAILY':
      return format(date, 'dd MMM');
    default:
      assertNever(unit);
  }
};

export const formatTooltipDateWithUnit = (unit: Unit, date: Date) => {
  switch (unit) {
    case 'WEEKLY':
      return `${format(startOfISOWeek(date), 'dd MMM yyyy')} - ${format(
        endOfISOWeek(new Date(date)),
        'dd MMM yyyy'
      )}`;
    case 'MONTHLY':
      return `${format(startOfMonth(date), 'dd')} - ${format(
        endOfMonth(new Date(date)),
        'dd MMM yyyy'
      )}`;
    case 'YEARLY':
      return `${format(startOfYear(date), 'dd MMM')} - ${format(
        endOfYear(new Date(date)),
        'dd MMM yyyy'
      )}`;
    case 'DAILY':
      return format(date, 'eee dd MMM yyyy');
    default:
      assertNever(unit);
  }
};

export const getUnitFromRange = (
  periods: Period,
  UNIT_OPTIONS: { value: Unit; label: string }[],
  dateRange: Date[]
): {
  value?: Unit;
  label?: string;
}[] => {
  const nbDaysBetweenRange = differenceInCalendarDays(
    dateRange[1],
    dateRange[0]
  );

  if (nbDaysBetweenRange <= FORTNIGHT_PERIOD || periods === 'last7Days') {
    return [
      [...UNIT_OPTIONS].find(({ label }) => label === 'Daily') ?? {
        value: 'DAILY',
        label: 'Daily',
      },
    ];
  }

  if (nbDaysBetweenRange <= BIMESTER_PERIOD || periods === 'last4Weeks') {
    return [...UNIT_OPTIONS].filter(
      ({ label }) => label === 'Daily' || label === 'Weekly'
    );
  }

  if (nbDaysBetweenRange <= QUATERLY_PERIOD) {
    return [...UNIT_OPTIONS].filter(
      ({ label }) =>
        label === 'Daily' || label === 'Weekly' || label === 'Monthly'
    );
  }

  if (
    nbDaysBetweenRange <= YEARLY_PERIOD ||
    periods === 'last3Months' ||
    periods === 'last12Months'
  ) {
    return [...UNIT_OPTIONS].filter(
      ({ label }) => label === 'Weekly' || label === 'Monthly'
    );
  }

  return [...UNIT_OPTIONS].filter(
    ({ label }) =>
      label === 'Weekly' || label === 'Monthly' || label === 'Yearly'
  );
};

export const formatChartItemsForTooltipData = (items: ItemsProps[]) => {
  return items.map((item) => {
    return {
      text: item.data.category || undefined,
      color: item.color || undefined,
      startDate: item.data.item.startDate,
      endDate: item.data.item.endDate,
      value: item.data.value,
    };
  });
};

export type ItemsProps = {
  title?: string;
  data: {
    category?: string;
    value: number;
    date?: string;
    item: {
      startDate: Date;
      endDate: Date;
      value?: number;
    };
  };
  mappingData?: {
    _origin: {
      category: string;
      value: string;
      date: string;
      item: {
        startDate: string;
        endDate: string;
        value: number;
      };
    };
    x: number;
    y: number;
    color: string;
    shape: string;
  };
  name?: string;
  value?: string | number;
  color?: string;
  marker?: boolean;
  x?: number;
  y?: number;
};

export const formatDateText = (date: DatesPeriod) => {
  return `${format(date.start, 'eee dd MMM yy')} - ${format(
    date.end,
    'eee dd MMM yy'
  )}`;
};

export const renderDataWithSuffix = (
  data: number,
  currencyFormatter: (price: number) => string,
  hasSuffix?: DataSuffix
) => {
  if (!hasSuffix) {
    return Math.round(data);
  }

  switch (hasSuffix) {
    case 'currency':
      return currencyFormatter(data);
    case 'percent':
      return `${(data * 100).toFixed(2)} %`;

    default:
      assertNever(hasSuffix);
  }
};
