import { Form, FormInstance, FormListFieldData, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';

import CoverPicture from 'app/components/commons/CoverPicture/CoverPicture';
import { IEditoEventForm } from 'app/typings/edito';
import { requiredRule } from 'app/utils/rules';

import { HeaderWrapper } from '../../HeaderWrapper/HeaderWrapper';

import { EditoSectionActionForm } from './ActionForm/EditoSectionActionForm';
import './EditoSectionsForm.scss';

interface Props {
  field: FormListFieldData;
  form: FormInstance<IEditoEventForm>;
}

export const EditoSectionsForm = ({ field, form }: Props) => {
  const { name } = field;

  return (
    <div className="editoSectionsForm">
      <HeaderWrapper title="Section title">
        <FormItem name={[name, 'title']} rules={[requiredRule]}>
          <Input placeholder="ex. Massés comme jamais" />
        </FormItem>
      </HeaderWrapper>

      <HeaderWrapper title="Section description" addTitleOptional>
        <FormItem name={[name, 'description']}>
          <TextArea placeholder="ex. Palpez, roulez, détendez-vous." />
        </FormItem>
      </HeaderWrapper>

      <HeaderWrapper title="Image">
        <div className="imageRow">
          <Form.Item name={[name, 'picture']} rules={[requiredRule]}>
            <CoverPicture />
          </Form.Item>
        </div>
      </HeaderWrapper>

      <HeaderWrapper title="Action" addTitleOptional>
        <EditoSectionActionForm form={form} field={field} />
      </HeaderWrapper>
    </div>
  );
};
