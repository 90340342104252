import FormItem from 'antd/es/form/FormItem';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import { isAfter, isBefore } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { useCallback } from 'react';

import {
  disabledEndTimesAfterStartDate,
  disabledStartTimesBeforeEndDate,
} from 'app/utils/dates';
import { requiredRule } from 'app/utils/rules';

import { EditoContainer } from '../../../commons/Detail/EditoContainer/EditoContainer';
import { HeaderWrapper } from '../../../commons/Detail/HeaderWrapper/HeaderWrapper';

import './EditoEventPublication.scss';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

type Props = {
  startDate?: Date;
  endDate?: Date;
};

export const EditoEventPublication = ({ startDate, endDate }: Props) => {
  const disabledStartTime = useCallback(
    (startValue: Date | null) => {
      if (!startValue || !endDate) {
        return {};
      }

      return disabledStartTimesBeforeEndDate(startValue, endDate);
    },
    [endDate]
  );

  const disabledEndTime = useCallback(
    (endValue: Date | null) => {
      if (!startDate || !endValue) {
        return {};
      }

      return disabledEndTimesAfterStartDate(endValue, startDate);
    },
    [startDate]
  );

  return (
    <EditoContainer
      title="Publication"
      subTitle="Define when the event will be featured on the product Homepage"
    >
      <div className="editoEventPublicationContainer">
        <HeaderWrapper title="Start date">
          <FormItem name="startDate" rules={[requiredRule]}>
            <DatePicker
              placeholder="Select time"
              showTime
              disabledTime={disabledStartTime}
              disabledDate={(date: Date) =>
                endDate ? isAfter(date, endDate) : false
              }
            />
          </FormItem>
        </HeaderWrapper>
        <HeaderWrapper title="End date">
          <FormItem name="endDate" rules={[requiredRule]}>
            <DatePicker
              placeholder="Select time"
              showTime
              disabledTime={disabledEndTime}
              disabledDate={(date: Date) =>
                startDate ? isBefore(date, startDate) : false
              }
            />
          </FormItem>
        </HeaderWrapper>
      </div>
    </EditoContainer>
  );
};
