import React, { FC } from 'react';
import { RenderImageProps } from 'react-photo-gallery';

import Pic from 'app/components/commons/Pic';

import Checkmark from './Checkmark/Checkmark';
import './SelectablePicture.scss';

const SelectablePicture: FC<RenderImageProps<Custom>> = ({
  index,
  onClick,
  photo,
  margin,
  direction,
  top,
  left,
}) => (
  <div
    key={index}
    style={{
      position: direction === 'column' ? 'absolute' : 'relative',
      top,
      left,
      margin,
      height: photo.height,
      width: photo.width,
    }}
    className={`selectable-image ${
      photo.selected ? 'selectable-image--selected' : ''
    }`}
  >
    {photo.selected && <Checkmark />}
    <Pic
      pictureId={photo.pictureId}
      width={photo.width}
      height={photo.height}
      alt={photo.alt}
      onClick={(event: any) => onClick?.(event, { index })}
    />
  </div>
);

type Custom = {
  pictureId: string;
  selected: boolean;
};

export default SelectablePicture;
