export const actions = {
  TRY_INIT_PASSWORD: 'TRY_INIT_PASSWORD',
};

export function tryInitPassword(token, email, password, redirect, role) {
  return {
    token,
    email,
    password,
    redirect,
    type: actions.TRY_INIT_PASSWORD,
    role,
  };
}
