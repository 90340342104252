import { Form } from 'antd';
import React, { FC } from 'react';

import { Option, Select } from 'app/components/fields/Select';
import type { NewHotel as NewHotelType } from 'app/redux/models/NewHotel/NewHotel';
import {
  ChannelManagerDataSource,
  ChannelManagers,
} from 'app/utils/channelManagers/channelManagers';
import {
  RateModes,
  RateModesDataSource,
} from 'app/utils/channelManagers/rateModes';

import './ChannelManagerSelect.scss';

const ChannelManagerSelect: FC<Props> = ({ onValuesChange, values }) => (
  <div className="channel-manager-block">
    <Form.Item
      name="channelManager"
      label="Channel manager"
      rules={[{ required: true }]}
    >
      <Select
        showSearch
        defaultValue={ChannelManagers.NONE}
        onSelect={(cm) => {
          onValuesChange(null, {
            ...values,
            channelManager: cm,
          });
        }}
      >
        {ChannelManagerDataSource.map((data) => (
          <Option key={data.id} value={data.id}>
            {data.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
    {values?.channelManager &&
      values.channelManager !== ChannelManagers.NONE && (
        <Form.Item
          name="rateMode"
          label="Connected rates"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            defaultValue={RateModes.MULTI_RATE}
            onSelect={(rm) => {
              onValuesChange(null, {
                ...values,
                rateMode: rm,
              });
            }}
          >
            {RateModesDataSource.map((data) => (
              <Option key={data.id} value={data.id}>
                {data.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
  </div>
);

type Props = {
  onValuesChange: (_: any, values: Partial<NewHotelType>) => void;
  values?: any;
};

export default ChannelManagerSelect;
