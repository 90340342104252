import {
  addDays,
  eachDayOfInterval,
  format,
  isAfter,
  isBefore,
} from 'date-fns';

import {
  AvailableOn,
  WeekDays,
} from 'app/components/commons/Experience/Availability/utils';
import { isEndOfWeek, isInDateArray, isPassed } from 'app/utils/dates';
import { calculatePercentageDecrease, roundToDecimal } from 'app/utils/number';

import {
  Experience,
  Inventory,
  Opening,
  OpeningErrors,
  Package,
} from './Types';

export const MINIMAL_DISCOUNT_DAY_PACKAGE = 0;
export const MINIMAL_DISCOUNT_STAYCATION = 18;
export const MAXIMUM_DISCOUNT = 70;
export const NUMBER_OF_DAYS_RANGE = 16;
export const NUMBER_OF_WEEKDAYS = 7;

export const prepareExtranetInventory: (
  inventory: Inventory | undefined,
  daysInRange: Interval,
  roomCount: number
) => Inventory | undefined = (inventory, daysInRange, roomCount) => {
  if (!inventory) {
    return;
  }

  const allHavePriority = inventory.rooms.every((room) => room.room.priority);

  const sortedRoom = inventory.rooms.sort((a, b) => {
    if (allHavePriority) {
      return (a.room.priority || 0) - (b.room.priority || 0);
    }

    return a.room.id - b.room.id;
  });

  const filledRooms = sortedRoom.map((room) => {
    const filledOpenings: Array<Opening> = [];

    eachDayOfInterval(daysInRange)
      .map((m) => format(m, 'yyyy-MM-dd'))
      .forEach((date) => {
        const openings = [...room.openings, ...filledOpenings];
        const exists = openings.find((op) => op.date === date);

        if (!exists) {
          filledOpenings.push({
            date,
            roomId: room.room.id,
            hotelId: room.room.hotelId,
            stock: 0,
            booked: 0,
            remaining: 0,
            price: 0,
            bar: 0,
            discountPrice: 0,
            isForced: false,
            closed: false,
          });
        }
      });

    const validatedOpenings = room.openings.map((o) => {
      const errors = validateOpening(o, inventory.pkg.dayPackage, roomCount);

      return { ...o, errorStatus: errors };
    });

    return {
      ...room,
      openings: [...validatedOpenings, ...filledOpenings],
    };
  });

  return {
    ...inventory,
    rooms: filledRooms,
  };
};

export const checkDaysRestrictionsForSingleNight = (
  experience: Experience,
  date: Date,
  isDayPackage: boolean
) => {
  const { availableDays, availableDayLimits } = experience;

  const noDayRestriction = availableDays.length === 0;
  const currentDay = date.getDay();

  const getDay = (dayOfWeek: number) =>
    Object.values(WeekDays).find(
      (_, index) =>
        (index + 1) % NUMBER_OF_WEEKDAYS === dayOfWeek % NUMBER_OF_WEEKDAYS
    );

  const checkinDay = getDay(currentDay);
  const checkoutDay = getDay(currentDay + (isDayPackage ? 0 : 1));

  const isCheckinDayValid =
    availableDayLimits.includes(AvailableOn.CHECK_IN) &&
    (noDayRestriction || (checkinDay && availableDays?.includes(checkinDay)));

  const isCheckoutDayValid =
    availableDayLimits.includes(AvailableOn.CHECK_OUT) &&
    (noDayRestriction || (checkoutDay && availableDays?.includes(checkoutDay)));

  return isCheckinDayValid || isCheckoutDayValid;
};

export const computePackageValue: (
  opening: Opening | undefined,
  inventory: Inventory | undefined,
  date: Date | string
) => number = (opening, inventory, date) => {
  if (!inventory) {
    return 0;
  }

  const filteredExperiences = inventory.pkg.experiences.filter((experience) => {
    if (!experience.included) return false;

    const formattedDate = new Date(format(new Date(date), 'yyyy-MM-dd'));
    const isCheckinExperience = experience.availableDayLimits.includes(
      AvailableOn.CHECK_IN
    );
    const dateToUseForClosingPeriods =
      inventory.pkg.dayPackage || isCheckinExperience
        ? formattedDate
        : addDays(formattedDate, 1);

    const isDuringClosingPeriod = experience.closingPeriods.some(
      (closingPeriod) =>
        !isAfter(new Date(closingPeriod.start), dateToUseForClosingPeriods) &&
        !isBefore(new Date(closingPeriod.end), dateToUseForClosingPeriods)
    );

    const isDayAvailable = checkDaysRestrictionsForSingleNight(
      experience,
      formattedDate,
      inventory.pkg.dayPackage
    );

    return !isDuringClosingPeriod && isDayAvailable;
  });
  const experiencesPrice = filteredExperiences.map(
    ({ price, priceDescription }) => {
      const parsedPrice = price || 0;

      if (priceDescription === '/pers.') {
        return parsedPrice * 2;
      }

      return parsedPrice;
    }
  );
  const packageValue = experiencesPrice.reduce(
    (acc, price) => acc + price,
    opening?.bar ?? 0
  );

  return packageValue;
};

export const isExperienceOptional = (exp: Experience) =>
  exp.included &&
  exp.price &&
  exp.price > 0 &&
  (exp.closingPeriods.length ||
    (exp.availableDays.length && exp.availableDays.length < 7));

export const packageHasOptionalExperiences = (pkg: Package) =>
  pkg.experiences.some(isExperienceOptional);

export const computeCrossedPriceDescription: (
  inventory: Inventory | undefined,
  currencyFormatter: (price?: number) => string
) => string = (inventory, currencyFormatter) => {
  if (!inventory) {
    return '';
  }

  const includedExperiences = inventory.pkg.experiences.filter(
    (experience) => experience.included
  );
  const crossPriceDescription = includedExperiences.reduce((acc, exp) => {
    const parsedPrice = exp.price || 0;
    const isOptional = isExperienceOptional(exp) ? '*' : '';
    const descriptionSuffix = `${
      exp.priceDescription ? ' ' + exp.priceDescription : ''
    }`;

    return `${acc} + ${exp.category.name}${isOptional} (${currencyFormatter(
      parsedPrice
    )}${descriptionSuffix})`;
  }, '');

  return crossPriceDescription;
};

type BulkEditOpening = {
  discountPrice: number;
  bar?: number;
};

export const computeDiscount = (
  opening: Opening | BulkEditOpening | undefined,
  packageValue: number
) => {
  if (!opening?.discountPrice || packageValue === 0) {
    return undefined;
  }

  const discountPercentage = calculatePercentageDecrease(
    opening.discountPrice,
    packageValue
  );
  const roundedDiscountPercentage = roundToDecimal(discountPercentage, 2);

  return roundedDiscountPercentage;
};

export const computePriceFromDiscount = (
  discount: number,
  packageValue: number
) => Math.floor(packageValue - (packageValue * discount) / 100);

const isDiscountNotEnough = (
  discountPrice: number | undefined,
  price: number,
  isDayPackage: boolean
) => {
  if (!discountPrice || !price) {
    return false;
  }

  return isDayPackage
    ? 100 - (discountPrice * 100) / price < MINIMAL_DISCOUNT_DAY_PACKAGE
    : 100 - (discountPrice * 100) / price < MINIMAL_DISCOUNT_STAYCATION;
};

const isDiscountTooMuch = (
  discountPrice: number | undefined,
  price: number
) => {
  if (!discountPrice || !price) {
    return false;
  }

  return 100 - (discountPrice * 100) / price >= MAXIMUM_DISCOUNT;
};

export const validateOpening = (
  opening: Opening,
  isDayPackage: boolean,
  roomCount: number
) => {
  const barError = opening?.bar > 0 ? undefined : OpeningErrors.MISSING_BAR;

  const rateError =
    isDiscountNotEnough(opening.discountPrice, opening.price, isDayPackage) &&
    barError === undefined
      ? OpeningErrors.EXCEED_RATE
      : undefined;

  const missingDiscountPriceError = !opening.discountPrice
    ? OpeningErrors.MISSING_DISCOUNT_PRICE
    : undefined;

  const wrongStockError =
    opening.stock > roomCount ? OpeningErrors.WRONG_STOCK : undefined;

  const discountPriceError = isDiscountTooMuch(
    opening.discountPrice,
    opening.price
  )
    ? OpeningErrors.UNDER_MINIMUM_PRICE
    : undefined;

  const noDiscountError =
    opening.discountPrice !== undefined &&
    opening.discountPrice > opening.price &&
    barError === undefined
      ? OpeningErrors.NO_DISCOUNT
      : undefined;

  return [
    barError,
    rateError,
    missingDiscountPriceError,
    wrongStockError,
    discountPriceError,
    noDiscountError,
  ].filter((e): e is OpeningErrors => !!e);
};

export const computeRowClassNames = (date: Date, bookableDays: string[]) => {
  return [
    'extranet-inventory__property-cell',
    'extranet-inventory__property-cell--large',
    {
      'extranet-inventory__property-cell--passed': isPassed(date),
      'extranet-inventory__property-cell--inactive':
        !isInDateArray(date, bookableDays) || isPassed(date),
      'extranet-inventory__property-cell--end-of-week': isEndOfWeek(date),
    },
  ];
};

export const getDiscountLabel = (
  discount: number | undefined,
  date: Date,
  isDayPackage: boolean
) => {
  const minimalDiscount = isDayPackage
    ? MINIMAL_DISCOUNT_DAY_PACKAGE
    : MINIMAL_DISCOUNT_STAYCATION;

  if (discount === undefined || isPassed(date)) {
    return '';
  }

  if (discount === Infinity) {
    return 'BAR needed';
  }

  if (discount > -1 && isDayPackage) {
    return 'No discount';
  }

  if (discount > -minimalDiscount) {
    return `Min ${minimalDiscount}%`;
  }

  if (discount < -MAXIMUM_DISCOUNT) {
    return `Max ${MAXIMUM_DISCOUNT}%`;
  }

  return '';
};

export const isValidDiscount = (
  discount: number | undefined,
  dayPackage: boolean
) =>
  discount !== undefined &&
  discount <=
    (dayPackage
      ? MINIMAL_DISCOUNT_DAY_PACKAGE
      : -MINIMAL_DISCOUNT_STAYCATION) &&
  discount >= -MAXIMUM_DISCOUNT;

export const getDiscountClassName = (
  discount: number | undefined,
  date: Date,
  opening: Opening | undefined,
  inventory: Inventory
) => {
  if (opening?.isForced) {
    return opening.published ? 'green-text' : 'red-text';
  }

  if (discount === undefined || (inventory.pkg.dayPackage && discount === 0)) {
    return 'grey-text';
  }

  if (!isPassed(date)) {
    if (!isValidDiscount(discount, inventory.pkg.dayPackage)) {
      return 'red-text';
    }

    if (isValidDiscount(discount, inventory.pkg.dayPackage)) {
      return 'green-text';
    }
  }

  return 'grey-text';
};

export const setBusinessWeek = (date: Date) => ({
  start: date,
  end: addDays(date, NUMBER_OF_DAYS_RANGE - 1),
});
