import { Layout, PageHeader, Tabs, TabsProps } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useHistory, useLocation } from 'react-router-dom';

import { QuickFiltersList } from './QuickFiltersList/QuickFiltersList';
import './QuickFiltersListPage.scss';

const tabs: TabsProps['items'] = [
  {
    key: 'staycation',
    label: 'Staycations',
    children: <QuickFiltersList dayPackage={false} />,
  },
  {
    key: 'moment',
    label: 'Moments',
    children: <QuickFiltersList dayPackage={true} />,
  },
];

export const QuickFiltersListPage = () => {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(useLocation().search);
  const initialType = query.get('type');

  const onSwitchTab = (key: string) => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.set('type', key);

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <Layout className="page-list">
      <PageHeader title="Quick filters" />
      <Content className="quickFilterContent">
        <Tabs
          onChange={onSwitchTab}
          items={tabs}
          defaultActiveKey={initialType ?? 'staycation'}
          className="quickFilterTabs"
        />
      </Content>
    </Layout>
  );
};
