import { MenuOutlined } from '@ant-design/icons';
import { Table, TableProps } from 'antd';
import {
  SortStart,
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import './DraggableTable.scss';

const Container = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
);

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
);

interface Props<RecordType> extends Omit<TableProps<RecordType>, 'dataSource'> {
  dataSource: RecordType[];
  onSortEnd: ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => void;
}

export const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab' }} />
));

export const DraggableTable = <RecordType extends { id: number }>({
  ...props
}: Props<RecordType>) => {
  const { onSortEnd } = props;

  const DraggableContainer = (props: SortableContainerProps) => (
    <Container
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      onSortStart={handleSortStart}
      {...props}
    />
  );

  const handleSortStart = ({ node, helper }: SortStart) => {
    (node.childNodes as NodeListOf<HTMLTableRowElement>).forEach(
      (td, index: number) => {
        (helper.childNodes as NodeListOf<HTMLTableRowElement>)[
          index
        ].style.width = `${td.offsetWidth}px`;
      }
    );
  };

  const DraggableBodyRow = ({ ...restProps }) => {
    const index = props.dataSource.findIndex(
      (x) => x.id === restProps['data-row-key']
    );

    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      rowKey="id"
      className={props.className}
      {...props}
      components={{
        ...props.components,
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};
