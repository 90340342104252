import { Col, Form, Input, Row } from 'antd';
import React, { FC } from 'react';

const RULES = [{ required: true, message: 'Required' }];

const CustomInput: FC<Props> = ({
  name,
  label,
  required = false,
  className,
  length = 20,
}) => (
  <Row>
    <Col span={length}>
      <div className={className}>
        <Form.Item
          name={name}
          label={label}
          rules={required ? RULES : [{ required: false }]}
        >
          <Input />
        </Form.Item>
      </div>
    </Col>
  </Row>
);

type Props = {
  name: string | Array<string>;
  label: string;
  required?: boolean;
  className: string;
  length?: number;
};

export default CustomInput;
