import { createAction } from '@reduxjs/toolkit';

import type { Disclaimer } from 'app/redux/models/Disclaimer/Disclaimer';

import { Package, PackageListItem } from '../models/Package/Package';

type PackageTryFetchPackagePayload = {
  packageId: number;
};

type PackageFetchPackageSuccessPayload = {
  result: Package;
};

type PackageTryFetchDiscoverPayload = {
  packageId: number;
};

type PackageFetchDiscoverSuccessPayload = {
  result: any;
};

type PackageTrySaveDiscoverPayload = {
  packageId: number;
  discover: any;
};

type PackageSaveDiscoverSuccessPayload = {
  discover: any;
};

type PackageTryFetchCoverPayload = {
  packageId: number;
};

type PackageFetchCoverSuccessPayload = {
  result: any;
};

type PackageTrySaveCoverPayload = {
  packageId: number;
  cover: any;
};

type PackageTryFetchCopywritingPayload = {
  packageId: number;
};

type PackageTrySavePackagePayload = {
  pkg: any;
};

type PackageTryDeletePackagePayload = {
  packageId: number;
};

type PackageFetchCopywritingSuccessPayload = {
  result: any;
};

type PackageTrySaveCopywritingPayload = {
  packageId: number;
  copywriting: any;
};

type PackageFetchDisclaimersSuccessPayload = {
  result: any;
};

type PackageSaveDisclaimersPayload = {
  packageId: number;
  disclaimers: Array<Disclaimer>;
};

type PackageFetchDisclaimersPayload = {
  packageId: number;
};

type PackageFetchPackagesSuccessPayload = {
  result: PackageListItem[];
};

type PackageTryPublishPayload = {
  packageId: number;
  published: boolean;
};

type PackagePublishSuccessPayload = {
  packageId: number;
  published: boolean;
};

type PackageTryHidePayload = {
  packageId: number;
  hidden: boolean;
};

type PackageHideSuccessPayload = {
  packageId: number;
  hidden: boolean;
};

export const PACKAGES_START_LOADING = 'PACKAGES_START_LOADING';
export const PACKAGES_STOP_LOADING = 'PACKAGES_STOP_LOADING';
export const PACKAGES_TRY_FETCH_PACKAGE = 'PACKAGES_TRY_FETCH_PACKAGE';
export const PACKAGES_FETCH_PACKAGE_SUCCESS = 'PACKAGES_FETCH_PACKAGE_SUCCESS';
export const PACKAGES_TRY_SAVE_PACKAGE = 'PACKAGES_TRY_SAVE_PACKAGE';
export const PACKAGES_TRY_DELETE_PACKAGE = 'PACKAGES_TRY_DELETE_PACKAGE';
export const PACKAGES_TRY_FETCH_DISCOVER = 'PACKAGES_TRY_FETCH_DISCOVER';
export const PACKAGES_FETCH_DISCOVER_SUCCESS =
  'PACKAGES_FETCH_DISCOVER_SUCCESS';
export const PACKAGES_TRY_SAVE_DISCOVER = 'PACKAGES_TRY_SAVE_DISCOVER';
export const PACKAGES_SAVE_DISCOVER_SUCCESS = 'PACKAGES_SAVE_DISCOVER_SUCCESS';
export const PACKAGES_TRY_FETCH_COVER = 'PACKAGES_TRY_FETCH_COVER';
export const PACKAGES_FETCH_COVER_SUCCESS = 'PACKAGES_FETCH_COVER_SUCCESS';
export const PACKAGES_TRY_SAVE_COVER = 'PACKAGES_TRY_SAVE_COVER';
export const PACKAGES_TRY_FETCH_COPYWRITING = 'PACKAGES_TRY_FETCH_COPYWRITING';
export const PACKAGES_FETCH_COPYWRITING_SUCCESS =
  'PACKAGES_FETCH_COPYWRITING_SUCCESS';
export const PACKAGES_TRY_SAVE_COPYWRITING = 'PACKAGES_TRY_SAVE_COPYWRITING';
export const PACKAGE_FETCH_DISCLAIMERS = 'PACKAGE_FETCH_DISCLAIMERS';
export const PACKAGE_FETCH_DISCLAIMERS_SUCCESS =
  'PACKAGE_FETCH_DISCLAIMERS_SUCCESS';
export const PACKAGE_SAVE_DISCLAIMERS = 'PACKAGE_SAVE_DISCLAIMERS';
export const PACKAGES_TRY_FETCH_PACKAGES = 'PACKAGES_TRY_FETCH_PACKAGES';
export const PACKAGES_FETCH_PACKAGES_SUCCESS =
  'PACKAGES_FETCH_PACKAGES_SUCCESS';
export const PACKAGES_TRY_PUBLISH_PACKAGE = 'PACKAGES_TRY_PUBLISH_PACKAGE';
export const PACKAGES_PUBLISH_PACKAGE_SUCCESS =
  'PACKAGES_PUBLISH_PACKAGE_SUCCESS';
export const PACKAGES_TRY_HIDE_PACKAGE = 'PACKAGES_TRY_HIDE_PACKAGE';
export const PACKAGES_HIDE_PACKAGE_SUCCESS = 'PACKAGES_HIDE_PACKAGE_SUCCESS';

export const startLoading = createAction(PACKAGES_START_LOADING);
export const stopLoading = createAction(PACKAGES_STOP_LOADING);

export const tryFetchPackage = createAction<PackageTryFetchPackagePayload>(
  PACKAGES_TRY_FETCH_PACKAGE
);
export const fetchPackageSuccess =
  createAction<PackageFetchPackageSuccessPayload>(
    PACKAGES_FETCH_PACKAGE_SUCCESS
  );

export const tryFetchPackages = createAction(PACKAGES_TRY_FETCH_PACKAGES);
export const fetchPackagesSuccess =
  createAction<PackageFetchPackagesSuccessPayload>(
    PACKAGES_FETCH_PACKAGES_SUCCESS
  );

export const trySavePackage = createAction<PackageTrySavePackagePayload>(
  PACKAGES_TRY_SAVE_PACKAGE
);

export const tryDeletePackage = createAction<PackageTryDeletePackagePayload>(
  PACKAGES_TRY_DELETE_PACKAGE
);

export const tryFetchDiscover = createAction<PackageTryFetchDiscoverPayload>(
  PACKAGES_TRY_FETCH_DISCOVER
);
export const fetchDiscoverSuccess =
  createAction<PackageFetchDiscoverSuccessPayload>(
    PACKAGES_FETCH_DISCOVER_SUCCESS
  );

export const trySaveDiscover = createAction<PackageTrySaveDiscoverPayload>(
  PACKAGES_TRY_SAVE_DISCOVER
);
export const saveDiscoverSuccess =
  createAction<PackageSaveDiscoverSuccessPayload>(
    PACKAGES_SAVE_DISCOVER_SUCCESS
  );

export const tryFetchCover = createAction<PackageTryFetchCoverPayload>(
  PACKAGES_TRY_FETCH_COVER
);
export const fetchCoverSuccess = createAction<PackageFetchCoverSuccessPayload>(
  PACKAGES_FETCH_COVER_SUCCESS
);

export const trySaveCover = createAction<PackageTrySaveCoverPayload>(
  PACKAGES_TRY_SAVE_COVER
);

export const tryFetchCopywriting =
  createAction<PackageTryFetchCopywritingPayload>(
    PACKAGES_TRY_FETCH_COPYWRITING
  );
// eslint-disable-next-line max-len
export const fetchCopywritingSuccess =
  createAction<PackageFetchCopywritingSuccessPayload>(
    PACKAGES_FETCH_COPYWRITING_SUCCESS
  );

export const trySaveCopywriting =
  createAction<PackageTrySaveCopywritingPayload>(PACKAGES_TRY_SAVE_COPYWRITING);

export const fetchDisclaimers = createAction<PackageFetchDisclaimersPayload>(
  PACKAGE_FETCH_DISCLAIMERS
);
// eslint-disable-next-line max-len
export const fetchDisclaimersSuccess =
  createAction<PackageFetchDisclaimersSuccessPayload>(
    PACKAGE_FETCH_DISCLAIMERS_SUCCESS
  );

export const saveDisclaimers = createAction<PackageSaveDisclaimersPayload>(
  PACKAGE_SAVE_DISCLAIMERS
);

export const tryPublishPackage = createAction<PackageTryPublishPayload>(
  PACKAGES_TRY_PUBLISH_PACKAGE
);

export const publishPackageSuccess = createAction<PackagePublishSuccessPayload>(
  PACKAGES_PUBLISH_PACKAGE_SUCCESS
);

export const tryHidePackage = createAction<PackageTryHidePayload>(
  PACKAGES_TRY_HIDE_PACKAGE
);

export const hidePackageSuccess = createAction<PackageHideSuccessPayload>(
  PACKAGES_HIDE_PACKAGE_SUCCESS
);
