import { Layout } from 'antd';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { SDetailLayout } from 'app/components/StaycationUI';
import { fetchDisclaimers, saveDisclaimers } from 'app/redux/actions/packages';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import type { Disclaimer as DisclaimerType } from 'app/redux/models/Disclaimer/Disclaimer';
import { DisclaimerKind } from 'app/redux/models/Disclaimer/Disclaimer';

import DisclaimerCategory from './components/DisclaimerCategory/DisclaimerCategory';

const { Content } = Layout;

type Mode = 'view' | 'edit';

type Props = {
  headerMenu: ReactNode;
  headerExtra: Array<ReactNode>;
  packageId: number | undefined;
};

const noop = () => {};

export const Disclaimer = ({ headerMenu, headerExtra, packageId }: Props) => {
  const dispatch = useAppDispatch();
  const packageName = useAppSelector(
    (state) => state.packages.copywriting.name
  );
  const disclaimers = useAppSelector((state) => state.packages.disclaimers);
  const [disclaimersValues, setDisclaimersValues] = useState(disclaimers);

  const disclaimersAdvice = useMemo(
    () =>
      disclaimersValues
        .filter((d) => d.kind === 'ADVICE')
        .sort((a, b) => a.priority - b.priority),
    [disclaimersValues]
  );
  const disclaimersAlert = useMemo(
    () =>
      disclaimersValues
        .filter((d) => d.kind === 'ALERT')
        .sort((a, b) => a.priority - b.priority),
    [disclaimersValues]
  );

  const [mode, setMode] = useState<Mode>('view');
  const [isDirty, setIsDirty] = useState(false);

  const onEdit = useCallback(() => {
    setMode('edit');
  }, []);

  const onCancel = useCallback(() => {
    setIsDirty(false);
    setMode('view');
  }, []);

  const onChange = useCallback(
    (disclaimersChanged: Array<DisclaimerType>, kind: DisclaimerKind) => {
      const newDisclaimersAdvice =
        kind === 'ADVICE' ? disclaimersChanged : disclaimersAdvice;
      const newDisclaimersAlert =
        kind === 'ALERT' ? disclaimersChanged : disclaimersAlert;

      const newDisclaimers = newDisclaimersAdvice.concat(newDisclaimersAlert);

      const isDisclaimersValid = newDisclaimers.every(
        (d) => d.text.length && d.startDate
      );

      setDisclaimersValues(newDisclaimers);
      setIsDirty(isDisclaimersValid);
    },
    [disclaimersAdvice, disclaimersAlert]
  );

  const onSave = useCallback(() => {
    if (packageId) {
      dispatch(saveDisclaimers({ packageId, disclaimers: disclaimersValues }));
    }

    setIsDirty(false);
    setMode('view');
  }, [dispatch, packageId, disclaimersValues]);

  useEffect(() => {
    if (packageId) {
      dispatch(fetchDisclaimers({ packageId }));
    }
  }, [dispatch, packageId]);

  useEffect(() => {
    if (mode === 'view') {
      // We reset the page with the value stored in the state when the mode is changed to view
      setDisclaimersValues(disclaimers);
    }
  }, [disclaimers, mode]);

  if (!packageId) {
    return null;
  }

  return (
    <SDetailLayout
      title={packageName || `Package #${packageId}`}
      mode={mode}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      reset={noop}
      isDirty={isDirty}
      headerMenu={headerMenu}
      headerExtra={headerExtra}
      isValid
    >
      <Content className="package-detail__content">
        <DisclaimerCategory
          mode={mode}
          kind={DisclaimerKind.ADVICE}
          onValueChanged={onChange}
          disclaimers={disclaimersAdvice}
          packageId={packageId}
        />
        <DisclaimerCategory
          mode={mode}
          kind={DisclaimerKind.ALERT}
          onValueChanged={onChange}
          disclaimers={disclaimersAlert}
          packageId={packageId}
        />
      </Content>
    </SDetailLayout>
  );
};

export default Disclaimer;
