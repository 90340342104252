import { Button, Form, Input, InputNumber, Modal } from 'antd';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';

import CurrencyInput from 'app/components/commons/Currency/CurrencyInput/CurrencyInput';
import DatePickerWithHelper from 'app/components/fields/DatePickerWithHelper/DatePickerWithHelper';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { CurrencyContext } from 'app/context/CurrencyContext/CurrencyContext';
import { GiftCard, GiftCardEditForm } from 'app/redux/models/GiftCard/GiftCard';
import { parseInputNumber } from 'app/utils/typing';

import EmailEditForm from './EmailEditForm/EmailEditForm';
import './GiftCardEdit.scss';
import PhysicalEditForm from './PhysicalEditForm/PhysicalEditForm';
import PrintEditForm from './PrintEditForm/PrintEditForm';

const RULES = [{ required: true, message: 'Required' }];

export const GiftCardEdit = ({
  visible,
  giftCard,
  onCancel,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();
  const { currencySymbol } = useContext(CurrencyContext);

  const isNotGiftCard =
    giftCard.giftCardType === 'voucher' ||
    giftCard.giftCardType === 'incentive' ||
    giftCard.giftCardType === 'internal';

  const formValues = useMemo(() => {
    const commonProperties = {
      expirationDate: giftCard.expirationDate
        ? moment(giftCard.expirationDate)
        : null,
      remainingValue: giftCard.remainingValue,
      beneficiaryName: giftCard.beneficiaryName,
    };

    if (
      giftCard.giftCardType === 'voucher' ||
      giftCard.giftCardType === 'internal'
    ) {
      return {
        ...commonProperties,
        minAmount: giftCard.minAmount,
      };
    }

    if (giftCard.giftCardType === 'incentive') {
      return {
        ...commonProperties,
        startDate: giftCard.startDate ? moment(giftCard.startDate) : null,
        minAmount: giftCard.minAmount,
        maxUse: giftCard.maxUse,
      };
    }

    if (giftCard.giftCardType === 'print') {
      return {
        ...commonProperties,
        senderName: giftCard.senderName,
        color: giftCard.color,
        message: giftCard.message,
      };
    }

    if (giftCard.giftCardType === 'email') {
      return {
        ...commonProperties,
        senderName: giftCard.senderName,
        color: giftCard.color,
        message: giftCard.message,
        beneficiaryEmail: giftCard.beneficiaryEmail,
        billingEmail: giftCard.billingEmail,
        emailSendDate: giftCard.emailSendDate
          ? moment(giftCard.expirationDate)
          : null,
      };
    }

    return {
      ...commonProperties,
      senderName: giftCard.senderName,
      shippingFirstName: giftCard.shippingFirstName,
      shippingLastName: giftCard.shippingLastName,
      shippingStreet1: giftCard.shippingStreet1,
      shippingStreet2: giftCard.shippingStreet2,
      shippingZipCode: giftCard.shippingZipCode,
      shippingCity: giftCard.shippingCity,
      shippingCountryId: giftCard.shippingCountryId,
    };
  }, [giftCard]);

  const onFinish = (values: any) => {
    const updatedGiftCard: GiftCardEditForm = {
      ...values,
      emailSendDate: values.emailSendDate?.format('YYYY-MM-DD'),
    };

    onSubmit(updatedGiftCard);
  };

  const isFormInvalid =
    !form.isFieldsTouched() ||
    form.getFieldsError().filter(({ errors }) => errors.length > 0).length > 0;

  const specificForm = () => {
    if (isNotGiftCard) {
      return null;
    }

    if (giftCard.giftCardType === 'print') {
      return <PrintEditForm />;
    }

    if (giftCard.giftCardType === 'email') {
      return <EmailEditForm />;
    }

    return <PhysicalEditForm />;
  };

  return (
    <Modal
      width={660}
      onCancel={onCancel}
      okText="Save"
      visible={visible}
      title="Edit gift card"
      centered
      className="gift-card-modal"
      footer={[
        <Button
          type="primary"
          onClick={() => form.submit()}
          key="save"
          disabled={isFormInvalid}
        >
          Save
        </Button>,
      ]}
    >
      {visible && (
        <FormLegacyRenderProp
          form={form}
          onFinish={onFinish}
          initialValues={formValues}
          labelWrap
        >
          {
            // This form relies on the render prop syntax to get access to updated form values during render.
            // TODO try to make this form work without the render prop syntax
            (currentFormValues) => (
              <>
                {giftCard.giftCardType === 'incentive' && (
                  <div className="input-row">
                    <Form.Item
                      name="startDate"
                      label="Start date"
                      rules={RULES}
                    >
                      <DatePickerWithHelper format="YYYY-MM-DD" />
                    </Form.Item>
                  </div>
                )}
                <div className="input-row">
                  <Form.Item
                    name="expirationDate"
                    label="Expiry date"
                    rules={RULES}
                  >
                    <DatePickerWithHelper format="YYYY-MM-DD" />
                  </Form.Item>
                </div>
                <div className="input-row">
                  <Form.Item
                    name="remainingValue"
                    label="Remaining Value"
                    rules={RULES}
                  >
                    <CurrencyInput
                      min={0}
                      currency={currencySymbol}
                      parser={parseInputNumber}
                    />
                  </Form.Item>
                </div>
                {!!isNotGiftCard && (
                  <div className="input-row">
                    <Form.Item
                      name="minAmount"
                      label="Minimum code use amount"
                      rules={RULES}
                    >
                      <InputNumber min={0} />
                    </Form.Item>
                  </div>
                )}
                {giftCard.giftCardType === 'incentive' && (
                  <div className="input-row">
                    <Form.Item name="maxUse" label="Max use" rules={RULES}>
                      <InputNumber min={1} />
                    </Form.Item>
                  </div>
                )}
                {!isNotGiftCard && (
                  <div className="input-row">
                    <Form.Item
                      name="senderName"
                      label="Sender Name"
                      rules={RULES}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                )}
                <div className="input-row">
                  <Form.Item
                    name="beneficiaryName"
                    label="Beneficiary Name"
                    rules={RULES}
                  >
                    <Input />
                  </Form.Item>
                </div>
                {specificForm()}
              </>
            )
          }
        </FormLegacyRenderProp>
      )}
    </Modal>
  );
};

type Props = {
  visible: boolean;
  giftCard: GiftCard;
  onCancel: () => void;
  onSubmit: (body: GiftCardEditForm) => void;
};

export default GiftCardEdit;
