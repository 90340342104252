import type { InputNumberProps } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useMemo } from 'react';

import { useAppSelector } from 'app/redux/hooks';
import { parseInputNumber, parseNumber } from 'app/utils/typing';

import CurrencyContext from '../CurrencyContext/CurrencyContext';
import CurrencyInput from '../CurrencyInput/CurrencyInput';

const CurrencyWrapper = ({ children, hotelId, hotel, countryId }: Props) => {
  const hotels = useAppSelector((state) => state.hotels.hotels);
  const countries = useAppSelector((state) => state.conf.countries);

  const country: { currency?: string; locale?: string; slug?: string } =
    useMemo(() => {
      if (!countries) {
        return {};
      }

      if (countryId) {
        return countries.find((c) => c.id === countryId) || {};
      }

      if (hotel) {
        const hotelCountryId = hotel.countryId || hotel.address?.countryId;

        return countries.find((c) => c.id === hotelCountryId) || {};
      }

      if (hotelId && hotels) {
        const hotelMatch = hotels.find((h) => h.id === hotelId);
        const hotelCountryId = hotelMatch?.countryId;

        return countries.find((c) => c.id === hotelCountryId) || {};
      }

      return {};
    }, [hotelId, hotels, countries, hotel, countryId]);

  const { currency, locale, slug: countrySlug } = country;

  const currencyFormatter = useMemo(() => {
    if (!currency || !locale) {
      return () => '0';
    }

    return (price: any) => {
      const parsedPrice = parseNumber(price) || 0;
      const formatter = new Intl.NumberFormat(locale, {
        currency,
        style: 'currency',
        // @ts-ignore
        notation: 'standard',
        minimumFractionDigits: Number.isInteger(price) ? 0 : 2,
        maximumFractionDigits: 2,
      });

      return formatter.format(parsedPrice);
    };
  }, [currency, locale]);

  const currencySymbol = useMemo(() => {
    if (!currency) {
      return '';
    }

    return getSymbolFromCurrency(currency) || '';
  }, [currency]);

  const currencyInput = (props: InputNumberProps) => (
    <CurrencyInput
      currency={currencySymbol}
      parser={parseInputNumber}
      {...props}
    />
  );

  return (
    <CurrencyContext.Provider
      value={{
        currencyFormatter,
        currencySymbol,
        currencyInput,
        currency,
        locale,
        countrySlug,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

type Props = {
  children: React.ReactNode;
  hotelId?: number | null;
  hotel?: any;
  countryId?: number;
};

export default CurrencyWrapper;
