import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const selectCustomTags = (state: RootState) => state.customTags;

export const getCustomTags = createSelector(
  selectCustomTags,
  (customTags) => customTags.customTags
);

export const getFilteredCustomTags = createSelector(
  selectCustomTags,
  (customTags) => customTags.filteredCustomTags
);

export const getIsLoading = createSelector(
  selectCustomTags,
  (customTags) => customTags.isLoading
);
