import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, FormListFieldData, Tooltip } from 'antd';
import { useState } from 'react';

import { InputRow } from 'app/components/commons/CustomFields/CustomFields';
import { Option, Select } from 'app/components/fields/Select';
import {
  SortableCollapse,
  SortablePanel,
} from 'app/components/layout/Sortable/Collapse';
import { tryFetchCollectionPreview } from 'app/redux/actions/collections';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { Collection } from 'app/redux/models/Collection/Collection';
import { Tag } from 'app/redux/models/Tag/Tag';
import { selectCollectionPreviews } from 'app/redux/selectors/collections';
import { selectTagList } from 'app/redux/selectors/tags';

import './CollectionSections.scss';
import MultipleDatePicker from './MultipleDatePicker/MultipleDatePicker';
import PreviewModal from './PreviewModal/PreviewModal';

export type ModalData = {
  title: string;
  countryId: number;
  clubId?: number;
  mode: string;
  tagIds: number[];
  dayPackage: boolean;
  dates: string[];
  includedOnly: boolean;
};

type Props = {
  values: Partial<Collection>;
  fields: FormListFieldData[];
  add: (defaultValue?: any, insertIndex?: number) => void;
  remove: (index: number) => void;
  move: (from: number, to: number) => void;
  isCollectionWhen?: boolean;
};

const RULES = [{ required: true, message: 'Required' }];

const CollectionSections = ({
  fields,
  add,
  remove,
  move,
  values,
  isCollectionWhen,
}: Props) => {
  const [activeKeys, setActiveKeys] = useState([`section-0`]);
  const [modalVisible, setModalVisible] = useState(false);

  const dispatch = useAppDispatch();
  const previews = useAppSelector(selectCollectionPreviews);
  const tags = useAppSelector(selectTagList);
  const [tagsToDisplay, setTagsToDisplay] = useState<Tag[]>([]);

  const handleAdd = () => {
    const nextIndex = values?.sections?.length || 1;

    setActiveKeys((prevActiveKeys) => [
      ...prevActiveKeys,
      `section-${nextIndex}`,
    ]);
    add({ rule: { dayPackage: false, includedOnly: false } });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const handlePreview = (data: ModalData) => {
    setModalVisible(true);

    dispatch(tryFetchCollectionPreview(data));

    const newTags = tags.filter((tag) => data.tagIds?.includes(tag.id));

    setTagsToDisplay(newTags);
  };

  const onCancel = () => setModalVisible(false);

  const displayTooltip =
    values.sliceDisplay && values.sections && values.sections.length >= 1;

  return (
    <div className="collection-sections">
      <SortableCollapse
        activeKey={activeKeys}
        onToggle={setActiveKeys}
        onSortEnd={({ oldIndex, newIndex }) => move(oldIndex, newIndex)}
        useDragHandle
      >
        {fields.map(({ key, name }, index) => {
          const defaultName = `Section ${index + 1}`;
          const sectionTitle = values.sections?.[name]?.title;
          const mode = values.sections?.[name]?.rule?.mode;
          const tagIds = values.sections?.[name]?.rule?.tagIds;
          const countryId = values.countryId;
          const clubId = values.clubId;
          const title = sectionTitle || defaultName;
          const dayPackage = values.sections?.[name]?.rule?.dayPackage || false;
          const dates = values.sections?.[name]?.rule?.dates ?? [];
          const includedOnly =
            values.sections?.[name]?.rule.includedOnly || false;

          return (
            <SortablePanel
              index={index}
              key={`section-${index}`}
              title={title}
              onRemove={() => handleRemove(index)}
              className="collection-sections__panel"
              card
              menuDisplayed={(values?.sections?.length || 0) > 1}
            >
              <h3>Section</h3>
              <InputRow
                label="Title"
                name={[name, 'title']}
                placeholder="Section title"
                required
                key={key}
              />
              <div className="input-row">
                <div className="input-label">Mode</div>
                <div className="input-select">
                  <Form.Item
                    name={[name, 'rule', 'mode']}
                    rules={RULES}
                    key={key}
                  >
                    <Select placeholder="AND/OR">
                      <Option value="AND">AND</Option>
                      <Option value="OR">OR</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="input-row">
                <div className="input-label">Tags</div>
                <div className="input-search">
                  <Form.Item name={[name, 'rule', 'tagIds']} rules={RULES}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      mode="multiple"
                      placeholder="Select a few tags"
                      useDefaultPopupContainer
                    >
                      {tags.map((data) => (
                        <Option key={data.id} value={data.id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="input-row">
                <div className="input-label">Included only</div>
                <div className="input-checkbox">
                  <Form.Item
                    name={[name, 'rule', 'includedOnly']}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                </div>
              </div>
              <div className="input-row">
                <div className="input-label">Day package</div>
                <div className="input-checkbox">
                  <Form.Item
                    name={[name, 'rule', 'dayPackage']}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                </div>
              </div>
              {isCollectionWhen && (
                <div className="input-row">
                  <div className="input-label">Collection WHEN dates</div>
                  <div className="input-search">
                    <Form.Item name={[name, 'rule', 'dates']} rules={RULES}>
                      <MultipleDatePicker />
                    </Form.Item>
                  </div>
                </div>
              )}

              <Button
                disabled={!mode && !tagIds && !countryId}
                onClick={() => {
                  if (mode && tagIds && countryId) {
                    handlePreview({
                      title,
                      mode,
                      tagIds,
                      countryId,
                      clubId,
                      dayPackage,
                      dates,
                      includedOnly,
                    });
                  }
                }}
              >
                Preview
              </Button>
            </SortablePanel>
          );
        })}
      </SortableCollapse>
      <div className="collection-sections__add">
        <Tooltip
          title="Collections with slice display enabled cannot have more than one section"
          trigger={displayTooltip ? 'hover' : []}
        >
          <Button
            icon={<PlusOutlined />}
            disabled={displayTooltip}
            onClick={handleAdd}
          >
            Add Section
          </Button>
        </Tooltip>
      </div>
      <PreviewModal
        previews={previews}
        visible={modalVisible}
        onCancel={onCancel}
        tags={tagsToDisplay}
      />
    </div>
  );
};

export default CollectionSections;
