import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { ISlideSavePayload } from 'app/typings/slides';
import { apiMutation } from 'app/utils/request/api';

import { path as slidesPath } from './useSlides';

const path = '/admin/slides/:clubId' as const;

export function useSlidesSave(clubId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: ISlideSavePayload[]) =>
      apiMutation(
        'POST',
        {
          path,
          params: { clubId: clubId.toString() },
        },
        payload
      ) as Promise<void>,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [slidesPath] });
      message.success('Slider saved');
    },
    onError: () => {
      message.error('Error saving slider');
    },
  });
}
