export type GestureListItem = {
  readonly hotelName: string;
  readonly hotelId: number;
  readonly billId?: number;
  readonly billReference?: string;
  readonly amount: number;
  readonly clubId: number;
  readonly createdAt: string;
  readonly comment: string;
};

export function createGestureListItem(values: any): GestureListItem {
  const {
    hotelName,
    hotelId,
    billId,
    billReference,
    amount,
    clubId,
    createdAt,
    comment,
  } = values;

  return {
    hotelName,
    hotelId,
    billId,
    billReference,
    amount,
    clubId,
    createdAt,
    comment,
  };
}
