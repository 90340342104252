import { Cascader } from 'antd';
import React, { useEffect, useState } from 'react';

const buildChildrenOptions = (dataSource: any, options: any) =>
  options.map((option: any) => {
    const children = dataSource.filter((el: any) => el.parentId === option.id);

    if (!children.length) {
      return {
        value: option.id,
        label: option.name,
      };
    }

    return {
      value: option.id,
      label: option.name,
      children: buildChildrenOptions(dataSource, children),
    };
  });

const mapDataSource = (dataSource: any) => {
  const parentOptions = dataSource.filter(
    (el: any) => el.parentId === undefined
  );
  const allOptions = buildChildrenOptions(dataSource, parentOptions);

  return allOptions;
};

const findPath = (leafValue: any, options: any, valuePath: any) =>
  options.reduce((acc: any, option: any) => {
    if (acc) {
      return acc;
    }

    const newPath = [...valuePath, option.value];

    if (option.value === leafValue) {
      return newPath;
    }

    if (option.children) {
      return findPath(leafValue, option.children, newPath);
    }

    return null;
  }, null);

const getPath = (value: any, options: any) => {
  const path = findPath(value, options, []);

  return path;
};

const Cascada = ({ dataSource, value, onChange, mode }: Props) => {
  const [options, setOptions] = useState([]);
  const [path, setPath] = useState<number[]>([]);

  useEffect(() => {
    const newOptions = mapDataSource(dataSource);

    setOptions(newOptions);
  }, [dataSource]);

  useEffect(() => {
    const newPath = getPath(value, options) || [];

    setPath(newPath);
  }, [options, value]);

  if (!onChange) {
    return null;
  }

  const handleChange = (selectedPath: any) => {
    const newValue = selectedPath[selectedPath.length - 1];

    return onChange(newValue);
  };

  const filterFunction = (inputValue: any, inputPath: any) => {
    const formattedInput = inputValue.toLowerCase();

    return inputPath.some((option: any) => {
      const formattedLabel = option.label.toLowerCase();

      return formattedLabel.includes(formattedInput);
    });
  };

  if (mode === 'view') {
    const viewValues = path.reduce((acc: string, val: number) => {
      const name = dataSource.find((opt) => opt.id === val)?.name;

      if (acc === '') {
        return `${name}`;
      }

      return `${acc} / ${name}`;
    }, '');

    return <div>{viewValues}</div>;
  }

  return (
    <Cascader
      placeholder="Select a category"
      // @ts-ignore
      value={path}
      onChange={handleChange}
      expandTrigger="hover"
      options={options}
      showSearch={{ filter: filterFunction }}
      getPopupContainer={(trigger) => trigger.parentElement || trigger}
    />
  );
};

type Props = {
  dataSource: Array<{
    id: number;
    name: string;
    parentId?: number;
  }>;
  value?: number;
  onChange?: (newValue: number) => void;
  mode: string;
};

export default Cascada;
