import {
  EllipsisOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input, Layout, Menu, PageHeader, Table } from 'antd';
import { push } from 'connected-react-router';
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
  CoverCellEmoji,
  PublishedCell,
  SwitchCell,
  SwitchCellWithDisabled,
  dateTimeCell,
} from 'app/components/lists/Cells/Cells';
import {
  CollectionsTryFetchCollectionsPayload,
  tryFetchCollections,
  tryUpdateCollectionParams,
  tryUpdateCollectionPublished,
} from 'app/redux/actions/collections';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import type { CollectionItem } from 'app/redux/models/CollectionItem/CollectionItem';
import { getUser } from 'app/redux/selectors/auth';
import { selectCollectionsList } from 'app/redux/selectors/collections';
import { selectClubs, selectCountries } from 'app/redux/selectors/conf';
import { useDebounce } from 'app/utils/hooks/useDebounce';
import {
  extractBoolean,
  parseArrayParam,
  useQueryParamHistory,
} from 'app/utils/queryParamHistory';
import {
  SortOrder,
  convertFromAntdSortOrder,
  convertToAntdSortOrder,
  getSortOrder,
} from 'app/utils/sort';

import 'styles/layout/page-list.scss';

import ModalCheckBox from '../Extranet/ExtranetBookings/ModalCheckBox';

const { Content } = Layout;
const { Column } = Table;

const LIMIT = 50;

const COUNTRIES_FLAGS = {
  FR: '🇫🇷',
  GB: '🇬🇧',
};

type CollectionsListQueryParam = {
  search: string;
  menuKey: string;
  pageNumber: number;
  nameSorting: SortOrder;
  updatedAtSorting: SortOrder;
  publishedFilter: boolean[];
};

type DisplayItem =
  | 'trendDisplay'
  | 'cardDisplay'
  | 'sliceDisplay'
  | 'searchDisplay';

const formatPublished = (publishedFilter?: boolean[]) => {
  if (publishedFilter?.length === 1) {
    return publishedFilter[0];
  }

  return undefined;
};

const formatSort = (sort: string) => {
  if (sort === 'ascend') {
    return 'ASC';
  }

  if (sort === 'descend') {
    return 'DESC';
  }

  return '';
};

const formatSorting = (queryParam: Partial<CollectionsListQueryParam>) => {
  if (queryParam.nameSorting && queryParam.nameSorting !== 'none') {
    return {
      sortingColumn: 'name',
      sortingOrder: formatSort(queryParam.nameSorting),
    };
  }

  if (queryParam.updatedAtSorting && queryParam.updatedAtSorting !== 'none') {
    return {
      sortingColumn: 'updatedAt',
      sortingOrder: formatSort(queryParam.updatedAtSorting),
    };
  }

  return null;
};

const queryParamInit = {
  pageNumber: 1,
  menuKey: 'country-1',
  nameSorting: 'none',
  updatedAtSorting: 'descend',
  publishedFilter: [true, false],
} as CollectionsListQueryParam;
const queryParamKeys = {
  pageNumber: 'p',
  menuKey: 'm',
  nameSorting: 'ns',
  updatedAtSorting: 'us',
  publishedFilter: 'pu',
  search: 's',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  nameSorting: getSortOrder,
  updatedAtSorting: getSortOrder,
  publishedFilter: parseArrayParam(extractBoolean),
};

const makePayload = (
  queryParam: Partial<CollectionsListQueryParam>
): CollectionsTryFetchCollectionsPayload => {
  const parsed = queryParam.menuKey?.split('-');
  const selectedType = parsed ? parsed[0] : undefined;
  const selectedId = parsed ? parseInt(parsed[1], 10) : undefined;

  return {
    search: queryParam.search,
    ...(formatSorting(queryParam) || undefined),
    offset: ((queryParam.pageNumber || 1) - 1) * LIMIT,
    limit: LIMIT,
    published: formatPublished(queryParam.publishedFilter),
    countryId:
      selectedType && selectedType === 'country' ? selectedId : undefined,
    clubId: selectedType && selectedType === 'club' ? selectedId : undefined,
  };
};

export const Collections = () => {
  const { queryParam, updateQueryParam } =
    useQueryParamHistory<CollectionsListQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const collections: CollectionItem[] = useAppSelector(selectCollectionsList);
  const isLoading = useAppSelector(
    (state) => state.collections.loading || state.tags.loading
  );
  const clubs = useAppSelector(selectClubs);
  const countries = useAppSelector(selectCountries);
  const appUrl = useAppSelector((state) => state.navigation.appUrl);
  const user = useAppSelector(getUser);

  const dispatch = useAppDispatch();

  const debouncedQueryParams = useDebounce(queryParam);

  useEffect(() => {
    const payload = makePayload(debouncedQueryParams);

    dispatch(tryFetchCollections(payload));
  }, [debouncedQueryParams, dispatch]);

  const navigateToEditCollection = (collectionId: number) =>
    dispatch(push(`collections/${collectionId}/edit`));
  const updateCollectionPublished = (
    collectionId: number,
    published: boolean
  ) =>
    dispatch(
      tryUpdateCollectionPublished({
        collectionId,
        published,
        fetchParams: makePayload(queryParam),
      })
    );

  const updateCollectionDisplay = (
    collectionId: number,
    value: boolean,
    displayPropertySet: DisplayItem
  ) => {
    const collection = collections.find((c) => c.id === collectionId);

    if (collection) {
      dispatch(
        tryUpdateCollectionParams({
          collectionId,
          trendDisplay: collection.trendDisplay,
          cardDisplay: collection.cardDisplay,
          sliceDisplay: collection.sliceDisplay,
          searchDisplay: collection.searchDisplay,
          [displayPropertySet]: value,
          fetchParams: makePayload(queryParam),
        })
      );
    }
  };

  const sortedCountries = useMemo(() => {
    if (!countries) {
      return null;
    }

    return countries.sort((countryA, countryB) => countryA.id - countryB.id);
  }, [countries]);

  const handlePreviewClick = (collection: CollectionItem) => {
    window.open(`${appUrl}/collections/${collection.slug}`);
  };

  const handleRowActions = (collection: CollectionItem) => ({
    onClick: () => navigateToEditCollection(collection.id),
  });

  const handleOnChange = (__: any, filter: any, sorter: any) => {
    const { field, order } = sorter;

    const adaptedOrder = convertFromAntdSortOrder(order);

    if (field === 'name') {
      updateQueryParam({
        publishedFilter: filter.published || [],
        nameSorting: adaptedOrder,
        updatedAtSorting: 'none',
        pageNumber: 1,
      });
    }

    if (field === 'updatedAt') {
      updateQueryParam({
        publishedFilter: filter.published || [],
        updatedAtSorting: adaptedOrder,
        nameSorting: 'none',
        pageNumber: 1,
      });
    }
  };

  const handleSearch = (nextSearch: string) => {
    updateQueryParam({
      search: nextSearch,
      pageNumber: 1,
    });
  };

  const handleOffset = (x: number) => {
    updateQueryParam({ pageNumber: (queryParam.pageNumber || 1) + x });
  };

  const handleKey = (key: string) => {
    updateQueryParam({
      menuKey: key,
      pageNumber: 1,
    });
  };

  const handleIsPublishedFilter = (statuses: any) => {
    if (!statuses.length) {
      updateQueryParam({ publishedFilter: [true, false], pageNumber: 1 });
    } else {
      updateQueryParam({ publishedFilter: statuses, pageNumber: 1 });
    }
  };

  const pagination = (className?: string) => (
    <div className={className}>
      <Button
        disabled={queryParam.pageNumber === 1}
        onClick={() => handleOffset(-1)}
      >
        <LeftOutlined />
      </Button>
      <Button>{queryParam.pageNumber}</Button>
      <Button onClick={() => handleOffset(1)}>
        <RightOutlined />
      </Button>
    </div>
  );

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={queryParam.search}
          onChange={(event) => handleSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
      </div>
      {pagination('button-wrapper')}
    </div>
  );

  const headerExtra = (
    <Link to="collections/new">
      <Button icon={<PlusOutlined />} type="primary">
        New
      </Button>
    </Link>
  );

  const headerFooter = (
    <Menu
      mode="horizontal"
      onClick={({ key }) => handleKey(key)}
      selectedKeys={[queryParam.menuKey || 'country-1']}
    >
      {sortedCountries &&
        sortedCountries.map((country) => (
          <Menu.Item key={`country-${country.id}`} title="country">
            {COUNTRIES_FLAGS[country.slug as 'FR' | 'GB']} &nbsp; {country.name}
          </Menu.Item>
        ))}
      {clubs &&
        clubs.map((club) => (
          <Menu.Item
            key={`club-${club.id}`}
            title="club"
            className="deprecated"
          >
            {club.name}
          </Menu.Item>
        ))}
    </Menu>
  );

  const actionMenu = (collection: CollectionItem) => (
    <div className="actions-menu">
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              key="preview"
              onClick={() => handlePreviewClick(collection)}
            >
              Preview
            </Menu.Item>
            <Menu.Item
              key="edit"
              onClick={() => navigateToEditCollection(collection.id)}
            >
              Edit
            </Menu.Item>
            {collection.published ? (
              <Menu.Item
                key="publish"
                onClick={() => updateCollectionPublished(collection.id, false)}
              >
                Unpublish
              </Menu.Item>
            ) : (
              <Menu.Item
                key="publish"
                onClick={() => updateCollectionPublished(collection.id, true)}
              >
                Publish
              </Menu.Item>
            )}
          </Menu>
        }
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );

  const publishedColumnTitle = (
    <ModalCheckBox
      title="Status"
      defaultValue={[true, false]}
      onChange={handleIsPublishedFilter}
      items={[
        {
          value: true,
          label: 'Published',
        },
        {
          value: false,
          label: 'Unpublished',
        },
      ]}
      value={queryParam.publishedFilter}
    />
  );

  return (
    <Layout className="page-list">
      <PageHeader
        className="header"
        title="Collections"
        extra={headerExtra}
        footer={headerFooter}
      />
      <Content className="body">
        <Table
          dataSource={collections}
          pagination={false}
          title={header}
          loading={isLoading}
          rowKey="id"
          onRow={handleRowActions}
          onChange={handleOnChange}
          sortDirections={['ascend', 'descend', 'ascend']}
        >
          <Column
            title="Name"
            dataIndex="name"
            render={CoverCellEmoji}
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.nameSorting)}
          />
          <Column title="Section count" dataIndex="sectionCount" />
          <Column
            title="Trend"
            dataIndex="trendDisplay"
            render={SwitchCell((id, value) =>
              updateCollectionDisplay(id, value, 'trendDisplay')
            )}
            align="center"
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
          />
          <Column<CollectionItem>
            title="Card"
            dataIndex="cardDisplay"
            render={SwitchCellWithDisabled(
              (id, value) => updateCollectionDisplay(id, value, 'cardDisplay'),
              (collection) =>
                !collection.cover
                  ? 'You need to add a cover to enable card display.'
                  : undefined
            )}
            align="center"
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
          />
          <Column<CollectionItem>
            title="Slice"
            dataIndex="sliceDisplay"
            render={SwitchCellWithDisabled(
              (id, value) => updateCollectionDisplay(id, value, 'sliceDisplay'),
              (collection) =>
                collection.sectionCount !== 1
                  ? 'You need to have one section to enable slice display.'
                  : undefined
            )}
            align="center"
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
          />
          <Column<CollectionItem>
            title="Search"
            dataIndex="searchDisplay"
            render={SwitchCellWithDisabled(
              (id, value) =>
                updateCollectionDisplay(id, value, 'searchDisplay'),
              (collection) => {
                if (user?.role !== 'superadmin') {
                  return 'You need to be a superadmin to enable search display';
                }

                if (!collection.searchName) {
                  return 'You need to add a search title to enable search display.';
                }

                return undefined;
              }
            )}
            align="center"
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
          />
          <Column
            title="Last modified"
            dataIndex="updatedAt"
            render={dateTimeCell('yyyy-MM-dd HH:mm:ss')}
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.updatedAtSorting)}
          />
          <Column
            title={publishedColumnTitle}
            dataIndex="published"
            render={PublishedCell}
          />
          <Column
            render={actionMenu}
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            align="center"
          />
        </Table>
        {pagination('footer')}
      </Content>
    </Layout>
  );
};

export default Collections;
