import { PlusOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React, { useState } from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import { SList } from 'app/components/StaycationUI/List';
import { ExperienceType } from 'app/components/commons/Package/General/Types';

import SortableExperienceItem from './ExperienceItem/ExperienceItem';
import './ExperienceList.scss';
import ExperienceModal from './ExperienceModal/ExperienceModal';

export const ExperienceList = ({
  mode,
  selectedExperiences,
  hotelExperiences,
  staycationExperiences,
}: Props) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);

  const isEmpty = !selectedExperiences || !selectedExperiences.length;

  return (
    <div className="list-experiences">
      <Form.List name="experiences">
        {(fields, { add, remove }) => {
          const onSelect = (values: any) =>
            values
              .filter(
                (experience: any) =>
                  !selectedExperiences?.some((e) => e.id === experience.id)
              )
              .forEach((experience: any) =>
                add({ ...experience, included: null })
              );

          return (
            <>
              <SList
                data={mode === 'edit' || !isEmpty ? fields : []}
                renderItem={(field, index) => (
                  <SortableExperienceItem
                    field={field}
                    experience={selectedExperiences?.[index]}
                    mode={mode}
                    remove={() => remove(index)}
                    key={field.key}
                    index={index}
                    disabled={mode === 'view'}
                  />
                )}
                bordered
              />
              {mode === 'edit' && (
                <div
                  onClick={() => setIsSelectorOpen(true)}
                  className="list-experiences__add-button"
                >
                  <PlusOutlined />
                  <span className="text">{`Add experiences`}</span>
                </div>
              )}
              <ExperienceModal
                visible={isSelectorOpen}
                onSelect={onSelect}
                onCancel={() => setIsSelectorOpen(false)}
                hotelExperiences={hotelExperiences}
                staycationExperiences={staycationExperiences}
              />
            </>
          );
        }}
      </Form.List>
    </div>
  );
};

const SortableList = SortableContainer(ExperienceList);

const SortableExperiencesList = ({ move, ...props }: SortableProps) => (
  <SortableList axis="y" useDragHandle onSortEnd={move} {...props} />
);

type Props = {
  mode: string;
  selectedExperiences?: Array<ExperienceType>;
  hotelExperiences?: Array<ExperienceType>;
  staycationExperiences?: Array<ExperienceType>;
};

type SortableProps = Props & {
  move: (indexes: { oldIndex: number; newIndex: number }) => void;
};

export default SortableExperiencesList;
