import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import './HeaderWrapper.scss';

interface Props {
  title: string;
  addTitleOptional?: boolean;
  gapTitleChildren?: number;
  isBold?: boolean;
  maxCharacters?: number;
}

export const HeaderWrapper = ({
  title,
  addTitleOptional = false,
  gapTitleChildren = 8,
  children,
  isBold = true,
  maxCharacters,
}: PropsWithChildren<Props>) => {
  return (
    <div className="headerWrapper">
      <div
        className="titleChildren"
        style={{
          gap: gapTitleChildren,
        }}
      >
        <div className="titleContainer">
          <span className={classNames([isBold ? 'bold' : 'normal'])}>
            {title}
          </span>
          {addTitleOptional && <span className="optional">(optional)</span>}
        </div>
        {children}
      </div>
      {maxCharacters && (
        <div className="maxCharacters">{`Max ${maxCharacters} characters`}</div>
      )}
    </div>
  );
};
