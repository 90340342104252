import { IPictureForm } from 'app/typings/misc';

export type PreviewResult = {
  readonly id: number;
  readonly name: string;
  readonly coverPicture: IPictureForm;
  readonly tagIds: number[];
  readonly popularity: number;
  readonly clubId: number;
  readonly clubName: string;
};

export function createCollectionPreview(values: any): PreviewResult {
  const { id, name, coverPicture, tagIds, popularity, clubId, clubName } =
    values;

  return {
    id,
    name,
    coverPicture,
    tagIds,
    popularity,
    clubId,
    clubName,
  };
}
