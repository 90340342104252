import {
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Form, Switch } from 'antd';
import classNames from 'classnames';
import React, { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';

import { ExtraGuestDetail } from '../Utils';

const RULES = [{ required: true, message: 'Required' }];

type ConfigurationProps = {
  taskInformation: ExtraGuestDetail;
  isSwitchOn: boolean;
  setIsSwitchOn: (isSwitchOn: boolean) => void;
};

export const Configuration = ({
  taskInformation,
  isSwitchOn,
  setIsSwitchOn,
}: ConfigurationProps) => {
  const { currencyInput } = useContext(CurrencyContext);

  const configurationClassName = classNames('configuration', {
    disabled: !isSwitchOn,
  });

  return (
    <div className={configurationClassName}>
      <div className="configuration__header">
        <div className="configuration__header__title">
          {taskInformation.priceConfiguration.section.icon}
          {taskInformation.priceConfiguration.section.title}
        </div>
        <Form.Item rules={RULES}>
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={setIsSwitchOn}
            checked={isSwitchOn}
          />
        </Form.Item>
      </div>
      <div className="configuration__body">
        <div className="configuration__body__price">
          {taskInformation.priceConfiguration.section.price.firstPart}
          <Form.Item name={taskInformation.valueName} rules={RULES}>
            {currencyInput({
              min: 0,
              onWheel: (e) => e.currentTarget.blur(),
              disabled: !isSwitchOn,
            })}
          </Form.Item>
          {taskInformation.priceConfiguration.section.price.secondPart}
        </div>
        <div className="configuration__body__info">
          <QuestionCircleOutlined />
          {taskInformation.priceConfiguration.section.information}
        </div>
      </div>
    </div>
  );
};
