import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { getEditoEventDetailPath } from 'app/routes/edito';
import { IEditoEventForm, IEditoEventResponse } from 'app/typings/edito';
import { apiRequest } from 'app/utils/request/api';

import { formatEditoEventResponseToForm } from './format/details';

export const useGetEditoEventDetails = (id: number | undefined) => {
  const query = useQuery<
    IEditoEventResponse,
    unknown,
    IEditoEventResponse,
    (
      | typeof getEditoEventDetailPath
      | {
          id: number | undefined;
        }
    )[]
  >({
    queryKey: [getEditoEventDetailPath, { id }],
    queryFn: () =>
      apiRequest({
        path: getEditoEventDetailPath,
        params: { id: id?.toString() ?? '' },
      }),
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!id,
  });

  const editoEvent = query.data;

  const initialEditoEventForm: IEditoEventForm | undefined = useMemo(() => {
    if (editoEvent) {
      return formatEditoEventResponseToForm(editoEvent);
    }
  }, [editoEvent]);

  return {
    ...query,
    initialEditoEventForm,
  };
};
