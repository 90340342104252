import React from 'react';

import SCard from 'app/components/StaycationUI/SCard/SCard';
import HotelLinks from 'app/components/commons/Hotel/MarketingBrief/HotelLinks/HotelLinks';

const FourthStep = ({ prefix }: Props) => (
  <>
    <SCard title="Hotel links" className="third-step">
      <HotelLinks prefix={prefix} />
    </SCard>
  </>
);

type Props = {
  prefix: string;
};

export default FourthStep;
