import { Button, ButtonProps, Modal } from 'antd';
import React, {
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  SlideEntitiesTable,
  SlideEntitiesTableProps,
} from './SlideEntitiesTable';

type SlideEntityModalState = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

const SlideEntityModalContext = createContext<
  SlideEntityModalState | undefined
>(undefined);

export const SlideEntityModalProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const context: SlideEntityModalState = useMemo(
    () => ({
      isOpen,
      open,
      close,
    }),
    [isOpen, open, close]
  );

  return (
    <SlideEntityModalContext.Provider value={context}>
      {children}
    </SlideEntityModalContext.Provider>
  );
};

function useSlideEntityModalContext() {
  const context = useContext(SlideEntityModalContext);

  if (!context) {
    throw new Error(
      'SlideEntityModalButton must be used inside a SlideEntityModal'
    );
  }

  return context;
}

export const SlideEntityModalButton = (props: Omit<ButtonProps, 'onClick'>) => {
  const { open } = useSlideEntityModalContext();

  return <Button onClick={open} {...props} />;
};

type SlideEntityModalProps = SlideEntitiesTableProps & {
  title: ReactNode;
};

export function SlideEntityModal({
  title,
  onRowClick,
  ...props
}: SlideEntityModalProps) {
  const { isOpen, close } = useSlideEntityModalContext();

  return (
    <Modal
      width={900}
      title={title}
      open={isOpen}
      onCancel={close}
      footer={null}
    >
      <SlideEntitiesTable
        {...props}
        onRowClick={(row) => {
          if (onRowClick) {
            onRowClick(row);
          }

          close();
        }}
      />
    </Modal>
  );
}
