import { ThunderboltFilled } from '@ant-design/icons';
import { Alert } from 'antd';

export const PricingRuleBulkEditAlert = () => {
  return (
    <Alert
      className="extranet-inventory-modal__automatedDiscount"
      message={
        'The pricing rule will be removed from the days you manually edited'
      }
      icon={<ThunderboltFilled size={16} />}
      showIcon
    />
  );
};
