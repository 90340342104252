import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import PanelHeader from 'app/components/layout/PanelHeader/PanelHeader';

import 'styles/layout/Sortable/Collapse/sortable-panel.scss';

import SortableHandle from '../SortableHandle';

import PanelMenu from './PanelMenu';

const { Panel } = Collapse;

const SortablePanel = SortableElement(
  ({
    index,
    title,
    onRemove,
    card,
    children,
    menuDisplayed = true,
    className = '',
    ...props
  }) => (
    <Panel
      {...props}
      key={props.key}
      className={`panel sortable-panel ${className}`}
      header={
        <PanelHeader
          key={index}
          name={title}
          actions={[
            <SortableHandle key="handle" />,
            menuDisplayed && <PanelMenu key="menu" onRemove={onRemove} />,
          ]}
          card={card}
        />
      }
      forceRender
    >
      {children}
    </Panel>
  )
);

SortablePanel.propTypes = {
  title: PropTypes.string.isRequired,
  card: PropTypes.bool,
  onRemove: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  menuDisplayed: PropTypes.bool,
};

export default SortablePanel;
