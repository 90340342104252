import { InfoCircleOutlined } from '@ant-design/icons';
import { getDistance } from 'geolib';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import { Option, Select } from 'app/components/fields/Select';
import { useAppSelector } from 'app/redux/hooks';
import type { Club } from 'app/redux/models/Club/Club';
import { selectCountries } from 'app/redux/selectors/conf';
import {
  GooglePlaceAddress,
  GooglePlacePayload,
  formatGooglePlacePayload,
} from 'app/utils/googlePlace/googlePlace';

import FormGroup from './FormGroup/FormGroup';
import './GetLucky.scss';

const GetLucky: FC<Props> = ({ clubs, onSelect }) => {
  const [data, setData] = useState<Array<Data>>([]);
  const [id, setId] = useState<string>();
  const [autocompleteService, setAutocompleteService] = useState<any>();
  const [placesService, setPlacesService] = useState<any>();
  const countries = useAppSelector(selectCountries);

  useEffect(() => {
    // @ts-ignore
    const Google = window.google;

    if (Google) {
      setAutocompleteService(new Google.maps.places.AutocompleteService());
      setPlacesService(
        new Google.maps.places.PlacesService(document.createElement('div'))
      );
    }
  }, []);

  const nearbySearchCallback = (place: GooglePlacePayload, subways: any) => {
    const addr = place.address_components || [];
    const countryComponent = _.find(addr, (addressEntry: GooglePlaceAddress) =>
      _.includes(addressEntry.types, 'country')
    );
    const country =
      countries.find(
        (c) =>
          c.slug.toLowerCase() === countryComponent?.short_name.toLowerCase()
      ) || countries[0];
    const countryId = country.id;
    const coordinates = place.geometry?.location;
    const coords = {
      lng: coordinates?.lng(),
      lat: coordinates?.lat(),
    };
    const closestClubs = clubs
      .filter((c) => country.authorizedCountries.includes(c.countryId))
      .map((club) => ({
        ...club,
        distance: getDistance(club.city?.coords, coords),
      }))
      .sort((a, b) => a.distance - b.distance);

    const { streetNumber, street, city, department, region, zipCode } =
      formatGooglePlacePayload(addr);

    const maybeStreetNumber = streetNumber ? `${streetNumber}, ` : '';

    onSelect({
      countryId,
      name: place.name,
      phone: place.formatted_phone_number?.replace(/\s/g, ''),
      address: {
        city,
        department,
        region,
        zipCode,
        countryId,
        street: `${maybeStreetNumber}${street}`,
      },
      groupId:
        closestClubs.filter((c) => c.countryId === countryId)[0]?.groupId ||
        closestClubs[0]?.groupId,
      location: { coords },
      subway: subways?.[0]?.name,
    });
  };

  const getDetailsCallback = (place: any) => {
    if (!place?.geometry?.location) {
      return;
    }

    const payload = {
      location: place.geometry.location,
      radius: 1000,
      type: ['subway_station'],
    };

    placesService.nearbySearch(payload, (subways: any) =>
      nearbySearchCallback(place, subways)
    );
  };

  const getPlaceDetails = (newId: any) => {
    const payload = {
      placeId: newId,
      fields: [
        'name',
        'address_component',
        'geometry',
        'formatted_phone_number',
      ],
    };

    placesService.getDetails(payload, getDetailsCallback);
  };

  const handleChange = (newId: any) => {
    setId(newId);
    getPlaceDetails(newId);
  };

  const getPlacePredictionsCallback = (
    predictions: Array<Prediction>
  ): void => {
    if (!predictions) {
      return;
    }

    const newData = predictions.map((pred) => ({
      id: pred.place_id,
      text: pred.structured_formatting.main_text,
      subtext: pred.structured_formatting.secondary_text,
    }));
    const filteredNewData = newData.filter((d) => d.id);

    setData(filteredNewData);
  };

  const findPlaces = (input: any) => {
    if (input.length < 4) {
      return;
    }

    try {
      const payload = {
        input,
        types: ['establishment'],
      };

      autocompleteService.getPlacePredictions(
        payload,
        getPlacePredictionsCallback
      );
    } catch (e) {
      console.log('Error while fetching google maps data: ', e);
    }
  };

  return (
    <div className="get-lucky">
      <FormGroup label="Get lucky 🍀️">
        <Select
          showSearch
          value={id}
          showArrow={false}
          className="selector"
          filterOption={false}
          onSearch={_.debounce(findPlaces, 200)}
          onChange={handleChange}
          notFoundContent="No results. Please type at least 4 characters."
        >
          {data.map((d) => (
            <Option key={d.id} value={d.id}>
              <span className="text">{d.text}</span>
              <span className="description">
                {d.subtext && `(${d.subtext})`}
              </span>
            </Option>
          ))}
        </Select>
        <div className="sub-text">
          <InfoCircleOutlined className="info-circle" />
          Type hotel name to autofill the form!
        </div>
      </FormGroup>
    </div>
  );
};

type Prediction = {
  place_id: number;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
};

type Data = {
  id: number;
  text: string;
  subtext: string;
};

type Props = {
  clubs: Array<Club>;
  onSelect: (data: any) => void;
};

export default GetLucky;
