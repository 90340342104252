import React from 'react';

const pic = (picId: string): string => `image:pictures/${picId}`;

const PictureComponent = ({
  pictureId,
  alt = '',
  className = '',
  updatedAt,
  ...props
}: Props) => (
  <img
    {...props}
    className={`twic pic ${className}`}
    data-src={pic(pictureId)}
    src="https://staycation.twic.pics/v1/placeholder:transparent"
    alt={alt}
  />
);

type Props = {
  pictureId: string;
  className?: string;
  alt?: string;
  [key: string]: any;
};

export default PictureComponent;
