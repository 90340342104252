import { Form, Select } from 'antd';
import { FormItemProps } from 'antd/es/form';
import { useMemo } from 'react';

import { Emoji } from 'app/components/pages/QuickFilters/types';
import { useAppSelector } from 'app/redux/hooks';
import { selectEmojis } from 'app/redux/selectors/pictures';

import PictureComponent from '../PictureComponent/PictureComponent';

const { Option } = Select;

const prefixEmojiLengthToRemove = 6;

const formatEmojiName = (emojiName: string) => {
  return emojiName.substring(prefixEmojiLengthToRemove).replaceAll('_', ' ');
};

interface Props<T> {
  formProps: FormItemProps<T>;
}

export const EmojiFormSelect = <T,>({ formProps }: Props<T>) => {
  const emojis: Emoji[] = useAppSelector(selectEmojis);

  const filteredEmojis = useMemo(
    () =>
      emojis
        .sort((emoji1, emoji2) => emoji1.name.localeCompare(emoji2.name))
        .map((emoji) => ({ ...emoji, name: formatEmojiName(emoji.name) })),
    [emojis]
  );

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Form.Item {...formProps}>
      <Select
        placeholder="Select an emoji"
        showSearch
        filterOption={filterOption}
      >
        {filteredEmojis.map((emoji: Emoji) => (
          <Option key={emoji.id} value={emoji.id} label={emoji.name}>
            <PictureComponent pictureId={emoji.pictureId} width={16} />
            <span
              className="emoji-option"
              style={{ textTransform: 'capitalize' }}
            >
              {emoji.name}
            </span>
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
