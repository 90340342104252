import { PlusOutlined } from '@ant-design/icons';
import React, { FC, useState } from 'react';

import UploadList from 'app/components/commons/Uploader/UploadList/UploadList';
import GalleryModal from 'app/components/pages/Galleries/GalleryModal/GalleryModal';

import './Pictures.scss';

const DEFAULT_PICTURES_CATEGORIES = [{ name: 'Sourcing', type: 'sourcing' }];

export const Pictures: FC<Props> = ({
  value,
  onChange,
  roomCategoryId,
  categories,
  hotelId,
  picturesCategories = DEFAULT_PICTURES_CATEGORIES,
  kind = 'sourcing',
  previewable = false,
  removable = false,
  draggable = false,
}) => {
  const [internalValue, setInternalValue] = useState(value);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const pictures = internalValue;

  if (!onChange) {
    return null;
  }

  const handleOnChange = (newValue: any) => {
    setInternalValue(newValue);
    onChange(newValue);
  };

  const addPictures = (newPictures: any) => {
    handleOnChange([...pictures, ...newPictures]);
  };

  const handleRemovePicture = (index: any) => {
    if (index >= 0) {
      handleOnChange([
        ...pictures.slice(0, index),
        ...pictures.slice(index + 1),
      ]);
    }
  };

  const onRemove = (uid: any) => {
    const index = pictures.findIndex(
      (pic: any) => uid === `${pic.pictureId}-${pic.id}`
    );

    handleRemovePicture(index);
  };

  return (
    <div className="pictures-content">
      <UploadList
        pictures={pictures || []}
        uploadingItems={{}}
        onChange={handleOnChange}
        remove={removable ? onRemove : undefined}
        customButton={
          <div onClick={() => setModalIsVisible(true)}>
            <PlusOutlined />
            <span className="text">Add</span>
          </div>
        }
        previewable={previewable}
        draggable={draggable}
      />
      <GalleryModal
        visible={modalIsVisible}
        title={roomCategoryId ? categories[roomCategoryId] : 'New room'}
        onOk={(newPictures) => {
          addPictures(newPictures);
          setModalIsVisible(false);
        }}
        onCancel={() => setModalIsVisible(false)}
        hotelId={hotelId}
        okText="Save"
        cancelText="Cancel"
        categories={picturesCategories}
        subTitle="Select photos in your media gallery or upload new ones to illustrate this space"
        kind={kind}
      />
    </div>
  );
};

type Props = {
  value?: any;
  onChange?: any;
  roomCategoryId?: number;
  categories: { [id: number]: string };
  hotelId: number;
  picturesCategories?: Array<{ name: string; type: string }>;
  kind?: string;
  previewable?: boolean;
  removable?: boolean;
  draggable?: boolean;
};

export default Pictures;
