import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

const Pagination = ({
  className,
  currentPage,
  setPageNumber,
  isLastPage,
}: Props) => (
  <div className={className}>
    <Button
      disabled={currentPage === 1}
      onClick={() => setPageNumber(currentPage - 1)}
    >
      <LeftOutlined />
    </Button>
    <Button>{currentPage}</Button>
    <Button
      disabled={isLastPage}
      onClick={() => setPageNumber(currentPage + 1)}
    >
      <RightOutlined />
    </Button>
  </div>
);

type Props = {
  className?: string;
  currentPage: number;
  setPageNumber: (nextPage: number) => void;
  isLastPage: boolean;
};

export default Pagination;
