import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { useHistory } from 'react-router-dom';

import ActionCellMenu from 'app/components/lists/ActionCellMenu';
import { dateTimeCell, dateTimeCell2 } from 'app/components/lists/cells';
import { UserBooking } from 'app/redux/models/Users/UserDetail';
import { sortDate } from 'app/utils/sort';

import 'styles/pages/Users/user-details.scss';

const STATUS_FILTER_VALUES = [
  {
    value: 'accepted',
    text: 'Accepted',
  },
  {
    value: 'rejected',
    text: 'Rejected',
  },
  {
    value: 'cancelled',
    text: 'Cancelled',
  },
];

const DEFAULT_STATUS_FILTER = ['accepted'];

const handleStatusFilter = (
  value: string | number | boolean,
  userBooking: UserBooking
) => value === userBooking.status;

const sortBookingsByCreatedAtField = (
  valueA: UserBooking,
  valueB: UserBooking
) => sortDate(valueA.createdAt, valueB.createdAt, 'ascend');

const sortBookingsByCheckinField = (valueA: UserBooking, valueB: UserBooking) =>
  sortDate(valueA.checkin, valueB.checkin, 'ascend');

type BookingLinkedProps = {
  bookings: UserBooking[];
};

const BookingLinked: React.FC<BookingLinkedProps> = ({
  bookings,
}: BookingLinkedProps) => {
  const history = useHistory();

  const handleActionClick = ({ code }: UserBooking) => {
    history.push(`/bookings/${code}`);
  };

  const handleRowClick = ({ code }: UserBooking) => {
    return {
      onClick: () => history.push(`/bookings/${code}`),
    };
  };

  return (
    <div className="bloc" style={{ minWidth: '800px' }}>
      <div className="title">Bookings</div>
      <Table
        dataSource={bookings}
        pagination={false}
        rowKey="code"
        onRow={handleRowClick}
      >
        <Column className="strong" key="code" title="Code" dataIndex="code" />
        <Column
          key="date"
          title="Date"
          sorter={sortBookingsByCreatedAtField}
          defaultSortOrder="descend"
          dataIndex="createdAt"
          render={dateTimeCell2}
        />
        <Column key="hotelName" title="Hotel" dataIndex="hotelName" />
        <Column
          key="checkin"
          sorter={sortBookingsByCheckinField}
          defaultSortOrder="descend"
          title="Check-in"
          render={(checkin) => <div>{dateTimeCell('dd/MM/yyyy')(checkin)}</div>}
          dataIndex="checkin"
        />
        <Column
          filters={STATUS_FILTER_VALUES}
          className="strong"
          title="Status"
          key="status"
          dataIndex="status"
          defaultFilteredValue={DEFAULT_STATUS_FILTER}
          filterResetToDefaultFilteredValue
          onFilter={handleStatusFilter}
          filterMultiple
          render={(value) => (
            <div>
              {STATUS_FILTER_VALUES.find((status) => status.value === value)
                ?.text ?? ''}
            </div>
          )}
        />
        <Column
          key="actions"
          className="actions-column"
          onCell={() => ({ onClick: (e) => e.stopPropagation() })}
          render={(entity) => (
            <ActionCellMenu
              entity={entity}
              handleActionClick={handleActionClick}
            />
          )}
        />
      </Table>
    </div>
  );
};

export default BookingLinked;
