import { Divider, Form, Modal } from 'antd';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import {
  useAutomateDiscountDelete,
  useAutomateDiscountSave,
} from 'app/hooks/data/useAutomateDiscount';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import { RateModes } from 'app/utils/channelManagers/rateModes';
import { assertNever } from 'app/utils/typing';

import { Inventory } from '../../Types';
import { isValidDiscount } from '../../Utils';

import './PricingRule.scss';
import { Rules } from './Rules';
import { RULES, RuleKeys } from './Utils';
import { PricingRuleDescription } from './_components/PricingRuleDescription';
import { PricingRuleModalFooter } from './_components/PricingRuleModalFooter/PricingRuleModalFooter';
import { PricingRuleModalHeader } from './_components/PricingRuleModalHeader';

export type FieldActiveRules = {
  standardDiscount: boolean;
  lastMinuteDiscount: boolean;
};

type Props = {
  onClose: () => void;
  inventory: Inventory;
  hotelId: number;
  autoDiscountRate?: number;
  lastMinuteAutoDiscountRate?: number;
  lastMinuteAutoDiscountDays?: number;
  startCurrentRange: Date | number;
  isOpen: boolean;
  onCompleteTask?: () => void;
};

export const PricingRule = ({
  onClose,
  inventory,
  hotelId,
  autoDiscountRate,
  lastMinuteAutoDiscountRate,
  lastMinuteAutoDiscountDays,
  startCurrentRange,
  isOpen,
  onCompleteTask,
}: Props) => {
  const { track } = useAmplitude();
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const isSingleRate = inventory?.rateMode === RateModes.SINGLE_RATE;

  const initialFormValue = {
    discount: autoDiscountRate && Math.trunc(autoDiscountRate * 100),
    lastMinuteDiscountValue:
      lastMinuteAutoDiscountRate &&
      Math.trunc(lastMinuteAutoDiscountRate * 100),
    lastMinuteDays: lastMinuteAutoDiscountDays,
  };
  const initialDisplayValue = useMemo(
    () => ({
      standardDiscount: !!autoDiscountRate,
      lastMinuteDiscount:
        !!lastMinuteAutoDiscountRate || !!lastMinuteAutoDiscountDays,
    }),
    [autoDiscountRate, lastMinuteAutoDiscountDays, lastMinuteAutoDiscountRate]
  );

  const [activeRules, setActiveRules] =
    useState<FieldActiveRules>(initialDisplayValue);

  const { mutate: updatePricingRule } = useAutomateDiscountSave(
    hotelId,
    format(startCurrentRange, 'yyyy-MM-dd')
  );

  const { mutate: deletePricingRule } = useAutomateDiscountDelete(
    hotelId,
    format(startCurrentRange, 'yyyy-MM-dd')
  );

  const handleSaveForm = (values: any) => {
    updatePricingRule({
      ...values,
      discount: activeRules.standardDiscount
        ? values.discount / 100
        : undefined,
      lastMinuteDiscount: activeRules.lastMinuteDiscount
        ? values.lastMinuteDiscountValue / 100
        : undefined,
      lastMinuteDays: activeRules.lastMinuteDiscount
        ? values.lastMinuteDays
        : undefined,
      enabled: true,
    });

    if (!initialDisplayValue.standardDiscount && activeRules.standardDiscount) {
      track('Activate default pricing rule', {
        min_discount_value: values.discount + '%',
      });
    } else if (
      initialDisplayValue.standardDiscount &&
      !activeRules.standardDiscount
    ) {
      track('Deactivate default rule');
    }

    if (
      !initialDisplayValue.lastMinuteDiscount &&
      activeRules.lastMinuteDiscount
    ) {
      track('Activate last minute pricing rule', {
        last_minute_discount: values.lastMinuteDiscountValue + '%',
        last_minute_days: values.lastMinuteDays,
      });
    } else if (
      initialDisplayValue.lastMinuteDiscount &&
      !activeRules.lastMinuteDiscount
    ) {
      track('Deactivation last minute pricing rule');
    }

    onCompleteTask?.();
    onClose();
  };

  const handleDeactivationAutomation = () => {
    onClose();

    if (initialDisplayValue.standardDiscount) {
      track('Deactivate default rule');
    }

    if (initialDisplayValue.lastMinuteDiscount) {
      track('Deactivation last minute pricing rule');
    }

    deletePricingRule(hotelId);
  };

  const handleCancelModal = () => {
    track('Click cancel button');
    form.resetFields();
    setActiveRules(initialDisplayValue);
    onClose();
  };

  const toggleSwitch = (switchValue: boolean, section: string) => {
    setActiveRules((values) => ({ ...values, [section]: switchValue }));

    !switchValue && form.setFieldValue([section], undefined);

    form.validateFields();
  };

  const discount = Math.trunc(formValues?.discount);
  const lastMinuteDiscountValue = Math.trunc(
    formValues?.lastMinuteDiscountValue
  );
  const lastMinuteDays = formValues?.lastMinuteDays;
  const hasStandardDiscount = activeRules.standardDiscount;
  const hasLastMinuteDiscount = activeRules.lastMinuteDiscount;
  const pkgDayPackage = inventory?.pkg.dayPackage;

  const invalidDiscountRate =
    hasStandardDiscount &&
    (discount == null ||
      (inventory && !isValidDiscount(-discount, pkgDayPackage)));

  const invalidLMDays =
    hasLastMinuteDiscount && (lastMinuteDays == null || lastMinuteDays === 0);

  const invalidLMRate =
    hasLastMinuteDiscount &&
    (lastMinuteDiscountValue == null ||
      (inventory && !isValidDiscount(-lastMinuteDiscountValue, pkgDayPackage)));

  const canSubmitAutoDiscountRate =
    autoDiscountRate &&
    !invalidDiscountRate &&
    Math.trunc(autoDiscountRate * 100) !== discount;

  const canSubmitLastMinuteAutoDiscountRate =
    lastMinuteAutoDiscountRate &&
    !invalidLMRate &&
    Math.trunc(lastMinuteAutoDiscountRate * 100) !== lastMinuteDiscountValue;

  const canSubmitLastMinuteAutoDiscountDays =
    lastMinuteAutoDiscountDays &&
    !invalidLMDays &&
    lastMinuteAutoDiscountDays !== lastMinuteDays;

  useEffect(() => {
    const hasFormErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length > 0);

    setCanSubmit(
      !invalidDiscountRate &&
        !invalidLMRate &&
        !invalidLMDays &&
        (canSubmitAutoDiscountRate ||
          canSubmitLastMinuteAutoDiscountRate ||
          canSubmitLastMinuteAutoDiscountDays ||
          initialDisplayValue.standardDiscount !== hasStandardDiscount ||
          initialDisplayValue.lastMinuteDiscount !== hasLastMinuteDiscount) &&
        !hasFormErrors
    );
  }, [
    autoDiscountRate,
    canSubmitAutoDiscountRate,
    canSubmitLastMinuteAutoDiscountDays,
    canSubmitLastMinuteAutoDiscountRate,
    form,
    discount,
    lastMinuteDays,
    lastMinuteDiscountValue,
    initialDisplayValue.standardDiscount,
    initialDisplayValue.lastMinuteDiscount,
    invalidDiscountRate,
    invalidLMDays,
    invalidLMRate,
    lastMinuteAutoDiscountDays,
    lastMinuteAutoDiscountRate,
    hasStandardDiscount,
    hasLastMinuteDiscount,
  ]);

  useEffect(() => {
    setActiveRules({
      standardDiscount: !!autoDiscountRate,
      lastMinuteDiscount:
        !!lastMinuteAutoDiscountRate || !!lastMinuteAutoDiscountDays,
    });
  }, [
    autoDiscountRate,
    lastMinuteAutoDiscountRate,
    lastMinuteAutoDiscountDays,
    form,
  ]);

  const getIsRuleInvalid = (rule: RuleKeys) => {
    switch (rule) {
      case 'standardDiscount':
        return invalidDiscountRate;

      case 'lastMinuteDiscount':
        return invalidLMRate;

      default:
        assertNever(rule);
    }
  };

  return (
    <Modal
      centered
      width={610}
      className="automatedDiscountModal"
      open={isOpen}
      title={<PricingRuleModalHeader />}
      onCancel={handleCancelModal}
      destroyOnClose
      footer={
        <PricingRuleModalFooter
          handleCancelModal={handleCancelModal}
          handleDeactivationAutomation={handleDeactivationAutomation}
          isFormInvalid={!canSubmit}
          form={form}
          autoDiscountRate={autoDiscountRate}
          lastMinuteAutoDiscountRate={lastMinuteAutoDiscountRate}
          lastMinuteAutoDiscountDays={lastMinuteAutoDiscountDays}
          openPricingRule={activeRules.standardDiscount}
          openLastMinutePricingRule={activeRules.lastMinuteDiscount}
          isSingleRate={isSingleRate}
          activeRules={activeRules}
        />
      }
    >
      <div className="automatedDiscountModal__modal_wrapper">
        <FormLegacyRenderProp
          name="automatedDiscounForm"
          autoComplete="off"
          className="automatedDiscountModal__modal_wrapper__discount_form"
          form={form}
          onFinish={handleSaveForm}
          initialValues={initialFormValue}
        >
          {
            // This form relies on the render prop syntax to get access to updated form values during render.
            // TODO try to make this form work without the render prop syntax
            () =>
              inventory &&
              [...RULES].map((rule, index) => {
                return (
                  <Fragment key={index}>
                    <Rules
                      ruleInfo={rule}
                      isRuleActive={activeRules[rule.key]}
                      inventory={inventory}
                      toggleSwitch={(switchValue: boolean) =>
                        toggleSwitch(switchValue, rule.key)
                      }
                      isSingleRate={isSingleRate}
                      isRuleInvalid={getIsRuleInvalid(rule.key)}
                    />
                    {index < RULES.length - 1 && (
                      <Divider className="divider" plain>
                        <div className="divider-text">and / or</div>
                      </Divider>
                    )}
                  </Fragment>
                );
              })
          }
        </FormLegacyRenderProp>
      </div>
      <Divider className="no-margin-divider" />
      <PricingRuleDescription isSingleRate={isSingleRate} />
    </Modal>
  );
};
