import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';

import {
  TryFetchGesturesPayload,
  tryFetchGestures,
} from 'app/redux/actions/commercialGestures';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { GestureListItem } from 'app/redux/models/CommercialGestures/CommercialGestureListItem';
import { Country } from 'app/redux/models/Country/Country';
import { Hotel } from 'app/redux/models/Hotel/Hotel';
import { getGestures } from 'app/redux/selectors/commercialGestures';
import { useQueryParamHistory } from 'app/utils/queryParamHistory';
import { scrollToTop } from 'app/utils/scroll';
import {
  SortOrder,
  convertFromAntdSortOrder,
  formatSortingForAPI,
  getSortOrder,
  isValidSortOrder,
} from 'app/utils/sort';

type Props = {
  hotels: Array<Hotel>;
  countries: Array<Country>;
  loading: boolean;
};

type GesturesQueryParam = {
  search: string;
  pageNumber: number;
  dateSorting: SortOrder;
};

const PAGE_SIZE = 10;

const { Column } = Table;

const formatDate = (date: any) => {
  return date && moment.utc(date).local().format('DD/MM/YYYY à HH:mm');
};

const queryParamInit = {
  pageNumber: 1,
  dateSorting: 'descend',
} as GesturesQueryParam;
const queryParamKeys = {
  pageNumber: 'p',
  dateSorting: 'ds',
  search: 's',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  dateSorting: getSortOrder,
};

const makePayload = (
  queryParam: Partial<GesturesQueryParam>
): TryFetchGesturesPayload => {
  const validSortOrder = isValidSortOrder(queryParam.dateSorting);

  return {
    search: queryParam.search,
    sortingColumn: validSortOrder ? 'createdAt' : undefined,
    sortingOrder: validSortOrder
      ? formatSortingForAPI(queryParam.dateSorting)
      : undefined,
    offset: ((queryParam.pageNumber || 1) - 1) * PAGE_SIZE,
    limit: PAGE_SIZE,
  };
};

const CommercialGestureList = ({ hotels, countries, loading }: Props) => {
  const dispatch = useAppDispatch();
  const gestures: Array<GestureListItem> = useAppSelector(getGestures);

  const formatAmount = (number: any, row: any) => {
    const hotel = hotels.find((h) => h.id === row.hotelId);
    const country = countries.find((c) => c.id === hotel?.countryId);

    return country ? `${number} ${country.currency}` : number;
  };

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<GesturesQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const debounceFetch = useMemo(
    () =>
      debounce(
        (params: TryFetchGesturesPayload) => dispatch(tryFetchGestures(params)),
        500
      ),
    [dispatch]
  );

  useEffect(() => {
    const payload = makePayload(queryParam);

    debounceFetch(payload);
  }, [queryParam, debounceFetch]);

  const handleOffset = (x: number) => {
    updateQueryParam({ pageNumber: (queryParam?.pageNumber || 1) + x });

    scrollToTop();
  };

  const dateSortingHandler = (sort: SortOrder) => {
    updateQueryParam({
      dateSorting: sort,
      pageNumber: 1,
    });
  };

  const handleSearch = (search: string) => {
    updateQueryParam({
      search: search,
      pageNumber: 1,
    });
  };

  const pagination = (className: any) => (
    <div className={className}>
      <Button
        disabled={queryParam.pageNumber === 1}
        onClick={() => handleOffset(-1)}
      >
        <LeftOutlined />
      </Button>
      <Button>{queryParam.pageNumber}</Button>
      <Button
        disabled={gestures.length < PAGE_SIZE}
        onClick={() => handleOffset(1)}
      >
        <RightOutlined />
      </Button>
    </div>
  );

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={queryParam.search}
          onChange={(event) => handleSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
      </div>
      {pagination('button-wrapper')}
    </div>
  );

  return (
    <>
      <Table<GestureListItem>
        rowKey="id"
        loading={loading}
        dataSource={gestures}
        pagination={false}
        title={header}
        onChange={(__, ___, sorter) => {
          // @ts-ignore
          const { order } = sorter;

          dateSortingHandler(convertFromAntdSortOrder(order));
        }}
      >
        <Column key="name" title="Hotel" dataIndex="hotelName" />
        <Column
          key="createdAt"
          title="Creation Date"
          dataIndex="createdAt"
          render={formatDate}
        />
        <Column
          key="amount"
          title="Amount"
          dataIndex="amount"
          render={formatAmount}
        />
        <Column key="comment" title="Comment" dataIndex="comment" />
      </Table>
      {pagination('footer')}
    </>
  );
};

export default CommercialGestureList;
