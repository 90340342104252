import type { AnyAction } from 'redux';

import * as actions from 'app/redux/actions/packages';
import { createDisclaimer } from 'app/redux/models/Disclaimer/Disclaimer';
import type { Disclaimer } from 'app/redux/models/Disclaimer/Disclaimer';

import { Package, PackageListItem } from '../models/Package/Package';

export type Packages = {
  loading: boolean;
  package: Package | undefined;
  packages: PackageListItem[];
  discover: any;
  cover: any;
  copywriting: any;
  disclaimers: Array<Disclaimer>;
  tagIds?: number[];
};

export const initialState: Packages = {
  loading: false,
  package: undefined,
  packages: [],
  discover: undefined,
  cover: undefined,
  copywriting: undefined,
  disclaimers: [],
  tagIds: undefined,
};

function packages(state = initialState, action: AnyAction): Packages {
  if (actions.startLoading.match(action)) {
    return {
      ...state,
      loading: true,
    };
  }

  if (actions.stopLoading.match(action)) {
    return {
      ...state,
      loading: false,
    };
  }

  if (actions.fetchPackageSuccess.match(action)) {
    return {
      ...state,
      package: action.payload.result,
      loading: false,
    };
  }

  if (actions.fetchPackagesSuccess.match(action)) {
    return {
      ...state,
      packages: action.payload.result,
      loading: false,
    };
  }

  if (actions.fetchDiscoverSuccess.match(action)) {
    return {
      ...state,
      discover: action.payload.result,
    };
  }

  if (actions.saveDiscoverSuccess.match(action)) {
    return {
      ...state,
      discover: action.payload.discover,
    };
  }

  if (actions.fetchCoverSuccess.match(action)) {
    return {
      ...state,
      cover: action.payload.result,
    };
  }

  if (actions.fetchCopywritingSuccess.match(action)) {
    return {
      ...state,
      copywriting: action.payload.result,
    };
  }

  if (actions.fetchDisclaimersSuccess.match(action)) {
    return {
      ...state,
      disclaimers: action.payload.result.disclaimers.map((d: any) =>
        createDisclaimer(d)
      ),
    };
  }

  if (actions.publishPackageSuccess.match(action)) {
    const { packageId, published } = action.payload;

    const index = state.packages.findIndex((p) => p.id === packageId);

    if (index !== 1) {
      const pkg = {
        ...state.packages[index],
        published,
      };

      return {
        ...state,
        packages: [
          ...state.packages.slice(0, index),
          pkg,
          ...state.packages.slice(index + 1),
        ],
        loading: false,
      };
    }
  }

  if (actions.hidePackageSuccess.match(action)) {
    const { packageId, hidden } = action.payload;

    const index = state.packages.findIndex((p) => p.id === packageId);

    if (index !== 1) {
      const pkg = {
        ...state.packages[index],
        hidden,
      };

      return {
        ...state,
        packages: [
          ...state.packages.slice(0, index),
          pkg,
          ...state.packages.slice(index + 1),
        ],
        loading: false,
      };
    }

    return {
      ...state,
      loading: false,
    };
  }

  return state;
}

export default packages;
