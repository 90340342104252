import { Button, Layout } from 'antd';
import React, { PropsWithChildren, useContext } from 'react';

import HeaderContext from '../HeaderContext/HeaderContext';

import './HeaderWrapper.scss';

const { Content, Header } = Layout;

const HeaderWrapper = ({
  children,
  nextDisabled,
  handleNext,
}: PropsWithChildren<Props>) => {
  const { steps, step, prev } = useContext(HeaderContext);
  const currentStep = steps.find((el) => el.order === step);

  return (
    <Layout className="header-wrapper">
      <Header className="header">
        <Button onClick={prev}>Back</Button>
        <h1>{currentStep?.title}</h1>
        <Button onClick={handleNext} disabled={nextDisabled} type="primary">
          Next
        </Button>
      </Header>
      <Content className="body">
        <div className="form">{children}</div>
      </Content>
    </Layout>
  );
};

type Props = {
  nextDisabled: boolean;
  handleNext: () => void;
};

export default HeaderWrapper;
