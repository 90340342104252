import CheckableTag from 'antd/lib/tag/CheckableTag';
import React from 'react';

import './Tag.scss';

type TagProps = {
  text: string;
};

export const Tag = ({ text }: TagProps) => {
  return (
    <CheckableTag checked={true} className="tag">
      {text}
    </CheckableTag>
  );
};
export default Tag;
