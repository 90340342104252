import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import React from 'react';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';

const { Panel } = Collapse;

type PricingRuleDescriptionProps = {
  isSingleRate: boolean;
};

export const PricingRuleDescription = ({
  isSingleRate,
}: PricingRuleDescriptionProps) => {
  const { track } = useAmplitude();

  return (
    <div className="automatedDiscountModal__modal_wrapper">
      <Collapse
        className="automatedDiscountModal__modal_wrapper__automation_explanation_collapse"
        bordered={false}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} />
        )}
        onChange={(e) =>
          track('Click Pricing Rule Description Collapse', {
            isOpen: e.length > 0 ? true : false,
          })
        }
      >
        <Panel
          key="Pricing Rule Description"
          header={
            <div className="explanation_header">
              <QuestionCircleOutlined /> <h3>How does it work?</h3>
            </div>
          }
          className="explanation_panel"
        >
          <ul className="description_list">
            <li className="description_list__item">
              <span className="description_list__item--bold">📈 Dynamic :</span>{' '}
              Your Staycation rate evolves with your BAR, according to the set
              discount rate.
            </li>
            <li className="description_list__item">
              <span className="description_list__item--bold">
                🤚 Editable :
              </span>{' '}
              {isSingleRate
                ? 'You are still able to edit your rates manually on specific dates.'
                : 'You are still able to edit your rates on specific dates with the standard discount.'}
            </li>
            <li className="description_list__item">
              <span className="description_list__item--bold">
                🤖 Automatic :
              </span>{' '}
              Your connected stocks on all room categories will be automatically
              published.
            </li>
            <li className="description_list__item">
              <span className="description_list__item--bold">🛌 Global :</span>{' '}
              Your pricing rule will be applied to all of your room categories.
            </li>
            <li className="description_list__item">
              <span className="description_list__item--bold">
                🔄 Flexible :
              </span>
              You can deactivate the default discount at any time and revert to
              your initial rates and discount.
            </li>
          </ul>
        </Panel>
      </Collapse>
    </div>
  );
};
