import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import React from 'react';

import SCard from 'app/components/StaycationUI/SCard/SCard';
import Cascada from 'app/components/commons/Experience/BasicInfo/Cascada/Cascada';
import { useAppSelector } from 'app/redux/hooks';
import type { NewHotel as NewHotelType } from 'app/redux/models/NewHotel/NewHotel';
import { selectExperienceCategories } from 'app/redux/selectors/conf';
import { getNewHotelExperienceIds } from 'app/redux/selectors/hotels';

import './ThirdStep.scss';

type Props = {
  onValuesChange: (_: any, values: Partial<NewHotelType>) => void;
};

const ThirdStep = ({ onValuesChange }: Props) => {
  const experienceCategories = useAppSelector(selectExperienceCategories);
  const categoryIds = useAppSelector(getNewHotelExperienceIds);
  const categoryIdsDefined = categoryIds ? [...categoryIds] : [null];

  return (
    <SCard title="Create experiences" className="third-step">
      <div className="add-experiences">
        {categoryIdsDefined.map((categoryId, index) => (
          <Row
            className="experience-row"
            key={categoryId ? `${categoryId}` : `${index}`}
          >
            <Col span={24}>
              <div className="container-experience-item">
                <div className="with-delete">
                  <Form.Item
                    label={`Experience #${index + 1}`}
                    name={['experiences', index]}
                    rules={[]}
                  >
                    <Cascada dataSource={experienceCategories} mode="edit" />
                  </Form.Item>
                </div>
                <DeleteOutlined
                  className="delete-icon"
                  onClick={() => {
                    const newExperiencesCategoryIds = [...categoryIdsDefined];

                    newExperiencesCategoryIds.splice(index, 1);

                    onValuesChange(null, {
                      experiences: newExperiencesCategoryIds,
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
        ))}
        <Row className="email-row" key="add-email-key">
          <Col span={20}>
            <Form.Item label=" ">
              <Button
                icon={<PlusOutlined />}
                onClick={() =>
                  onValuesChange(null, {
                    experiences: [...categoryIdsDefined, null],
                  })
                }
              >
                Add experience
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </SCard>
  );
};

export default ThirdStep;
