import { Col, Row } from 'antd';

import { EmojiFormSelect } from 'app/components/commons/EmojiFormSelect/EmojiFormSelect';
import { requiredRule } from 'app/utils/rules';

import { SubFormSection, colSizes } from '../SubFormSection';

export const EmojiSectionForm = () => {
  return (
    <SubFormSection title="Emoji">
      <Row gutter={[24, 12]}>
        <Col {...colSizes}>
          <EmojiFormSelect
            formProps={{
              name: 'pictureId',
              rules: [requiredRule],
            }}
          />
        </Col>
      </Row>
    </SubFormSection>
  );
};
