import { HOURS_IN_DAY } from '@stdlib/constants-time';
import { Form, Layout, message } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  DEFAULT_CHECKIN,
  DEFAULT_CHECKOUT,
  EARLY_CHECKIN,
  LATE_CHECKOUT,
  WeekDays,
} from 'app/components/commons/Experience/Availability/utils';
import ExperienceForm from 'app/components/commons/Experience/ExperienceForm';
import { NOT_APPLICABLE_SPACE_ID } from 'app/components/commons/Experience/PracticalInfo/SpacesSelect/SpacesSelect';
import {
  formatDateRangeForAPI,
  formatOpeningHoursForAPI,
  parseDateRangeFromAPI,
  parseOpeningHoursFromAPI,
} from 'app/components/commons/TimeRangeSection/utils';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import {
  resetExperience,
  tryFetchExperience,
  trySaveExperience,
} from 'app/redux/actions/experiences';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import type { Experience as ExperienceType } from 'app/redux/models/Experience/Experience';
import { createExperience } from 'app/redux/models/Experience/Experience';

import './Experience.scss';

export const Experience = ({ match }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const stateExperience = useAppSelector(
    (state) => state.experiences.experience
  );
  const experienceId = parseInt(match?.params?.id, 10);

  const experience = useMemo(
    () =>
      stateExperience?.id === experienceId
        ? {
            ...stateExperience,
            spaceIds:
              stateExperience.spaceIds.length === 0
                ? [NOT_APPLICABLE_SPACE_ID]
                : stateExperience.spaceIds,
          }
        : createExperience(null),
    [stateExperience, experienceId]
  );

  const [mode, setMode] = useState<'view' | 'edit'>(
    experienceId ? 'view' : 'edit'
  );
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);

  useEffect(() => {
    if (experienceId) {
      dispatch(tryFetchExperience(experienceId));
    }
  }, [dispatch, experienceId]);

  useEffect(
    () => () => {
      dispatch(resetExperience());
    },
    [dispatch]
  );

  const onCancel = useCallback(() => {
    if (experienceId) {
      setMode('view');
    } else {
      history.push('/experiences');
    }
  }, [experienceId, history]);

  const onFinish = useCallback(
    (values: any) => {
      if (
        (values.duration && !values.durationDescription) ||
        (!values.duration && values.durationDescription)
      ) {
        message.error('Duration needs time value and a unit of time');

        return;
      }

      if (
        (values.price && !values.priceDescription) ||
        (!values.price && values.priceDescription)
      ) {
        message.error('Price needs value and a price description');

        return;
      }

      if (
        (values.quantity && !values.quantityDescription) ||
        (!values.quantity && values.quantityDescription)
      ) {
        message.error('Quantity needs value and a quantity description');

        return;
      }

      if (
        (values.purchasePrice || values.publicPrice) &&
        values.priceDescription === null
      ) {
        message.error(
          'Purchase and public price needs value and a price description'
        );

        return;
      }

      const weekDayArray = Object.values(WeekDays);

      const hasEveryDay =
        values.availableDays &&
        weekDayArray.every((day) => values.availableDays.includes(day));

      const formatted: ExperienceType = {
        ...experience,
        ...values,
        openingHours: values.openingHours
          ? formatOpeningHoursForAPI(values.openingHours)
          : null,
        time: values.time ? moment(values.time).format('HH:mm') : null,
        roomServiceAvailable: values.roomServiceAvailable || false,
        roomServiceIncluded: values.roomServiceIncluded || false,
        id: experience?.id || 0,
        published:
          experience?.published === undefined ? true : experience.published,
        spaceIds:
          values.spaceIds?.filter((s: any) => s !== NOT_APPLICABLE_SPACE_ID) ||
          [],
        coverPicture: values.pictures[0],
        price: values.price || 0,
        closingPeriods: formatDateRangeForAPI(values.closingPeriods),
        closedHoursBeforeCheckin:
          values.addOnLimitAvailabilityUnit === 'hours'
            ? values.closedHoursBeforeCheckin
            : values.closedHoursBeforeCheckin * HOURS_IN_DAY,
        maxQuantity: values.maxQuantity || null,
        availableDays:
          values.availableDaysRadio === 'everyDay' || hasEveryDay
            ? []
            : values.availableDays,
        availableDayLimits: values.availableOn,
      };

      dispatch(trySaveExperience(formatted));
      setMode('view');

      history.push('/experiences');
    },
    [dispatch, experience, history]
  );

  const formattedValues: ExperienceType | null = useMemo(() => {
    if (!experience) {
      return null;
    }

    const addOnLimitAvailabilityUnit =
      experience.closedHoursBeforeCheckin &&
      experience.closedHoursBeforeCheckin % HOURS_IN_DAY === 0
        ? 'days'
        : 'hours';

    const weekDayArray = Object.values(WeekDays);

    const hasEveryDay =
      experience.availableDays.length === 0 ||
      weekDayArray.every((day) => experience.availableDays.includes(day));

    return {
      ...experience,
      openingHours: parseOpeningHoursFromAPI(experience.openingHours),
      pictures: experience.pictures,
      closingPeriods: parseDateRangeFromAPI(experience.closingPeriods),
      closedHoursBeforeCheckin:
        addOnLimitAvailabilityUnit === 'days' &&
        experience.closedHoursBeforeCheckin
          ? experience.closedHoursBeforeCheckin / HOURS_IN_DAY
          : experience.closedHoursBeforeCheckin,
      addOnLimitAvailabilityUnit: addOnLimitAvailabilityUnit,
      availableDaysRadio: hasEveryDay ? 'everyDay' : 'specificDays',
      availableDays: experience.availableDays ?? weekDayArray,
      availableOn: experience.availableDayLimits,
    };
  }, [experience]);

  useEffect(() => {
    if (formattedValues !== null) {
      form.setFieldsValue(formattedValues);
    }
  }, [form, formattedValues]);

  useEffect(() => {
    if (!experienceId && formValues && formValues.categoryId) {
      if (formValues.categoryId === EARLY_CHECKIN) {
        form.setFieldsValue({ ...formValues, availableOn: DEFAULT_CHECKIN });
      } else if (formValues.categoryId === LATE_CHECKOUT) {
        form.setFieldsValue({ ...formValues, availableOn: DEFAULT_CHECKOUT });
      }
    }
  }, [form, formValues, experienceId]);

  if (!formattedValues) {
    return null;
  }

  return (
    <Layout className="master-page">
      <FormLegacyRenderProp
        form={form}
        onFinish={onFinish}
        initialValues={formattedValues}
      >
        {(
          values,
          {
            resetFields,
            isFieldsTouched,
            submit,
            setFieldsValue,
            validateFields,
          }
        ) => (
          <ExperienceForm
            values={values}
            initialValues={formattedValues}
            setFieldsValue={setFieldsValue}
            resetFields={resetFields}
            isFieldsTouched={isFieldsTouched}
            submit={submit}
            onCancel={onCancel}
            mode={mode}
            setMode={setMode}
            experienceId={experienceId}
            isFormValid={form
              .getFieldsError()
              .every((item) => item.errors.length > 0)}
            validateFields={validateFields}
          />
        )}
      </FormLegacyRenderProp>
    </Layout>
  );
};

type Props = {
  match: any;
};

export default Experience;
