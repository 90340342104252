import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { FC, useMemo, useState } from 'react';

import { CATEGORIES } from 'app/components/commons/Hotel/Spaces/utils';
import { SpaceType } from 'app/redux/models/SpaceType/SpaceType';

import './SpaceModal.scss';

const MIN = 0;
const MAX = 9;

const SpaceCounter: FC<SpaceCounterProps> = ({
  counter,
  onPlus,
  onMinus,
  isMax,
  isMin,
}) => (
  <div className="counter-wrapper">
    <div
      className={`counter-minus ${isMin ? 'counter-minus-disabled' : ''}`}
      onClick={isMin ? undefined : onMinus}
    >
      <MinusCircleOutlined />
    </div>
    <div className="counter">{counter}</div>
    <div
      className={`counter-plus ${isMax ? 'counter-plus-disabled' : ''}`}
      onClick={isMax ? undefined : onPlus}
    >
      <PlusCircleOutlined />
    </div>
  </div>
);

export const SpaceModal: FC<Props> = ({
  visible,
  onSave,
  onCancel,
  values,
  spaceTypes,
}) => {
  const defaultCounter = spaceTypes.reduce(
    (acc, val) => ({
      ...acc,
      [val.id]: 0,
    }),
    {}
  );
  const [spaceCounter, setSpaceCounter] = useState<any>(defaultCounter);

  const categories = useMemo(
    () =>
      spaceTypes
        .map((type) => {
          const space = values.spaces.find(
            (sp: any) => sp.spaceTypeId === type.id
          );

          return {
            ...type,
            exists: !!space,
          };
        })
        .reduce((acc: any, val) => {
          acc[val.category] = acc[val.category] || [];
          acc[val.category].push(val);

          return acc;
        }, {}),
    [spaceTypes, values]
  );

  const onOk = () => {
    const newSpaces: number[] = [];

    Object.keys(spaceCounter).forEach((key) => {
      for (let i = 0; i < spaceCounter[key]; i += 1) {
        newSpaces.push(parseInt(key, 10));
      }
    });
    onSave(newSpaces);
    onCancel();
    setSpaceCounter(defaultCounter);
  };

  return (
    <Modal
      width={800}
      onOk={onOk}
      okText="Save"
      onCancel={() => {
        onCancel();
        setSpaceCounter(defaultCounter);
      }}
      visible={visible}
      title="Add spaces"
      centered
      className="space-modal"
    >
      <div className="modal-content">
        {CATEGORIES.map((cat, idx) => (
          <div className="category-container" key={idx.toString()}>
            <div className="category-title">{`${cat} spaces`}</div>
            {categories[cat]?.map((type: any, index: any) => (
              <div className="type-container" key={index.toString()}>
                <div className="type-title">{type.name}</div>
                <SpaceCounter
                  counter={spaceCounter[type.id]}
                  onPlus={() =>
                    setSpaceCounter({
                      ...spaceCounter,
                      [type.id]: spaceCounter[type.id] + 1,
                    })
                  }
                  onMinus={() =>
                    setSpaceCounter({
                      ...spaceCounter,
                      [type.id]: spaceCounter[type.id] - 1,
                    })
                  }
                  isMin={spaceCounter[type.id] === MIN}
                  isMax={
                    spaceCounter[type.id] === MAX ||
                    (!type.multiple && spaceCounter[type.id] === 1) ||
                    (!type.multiple && type.exists)
                  }
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </Modal>
  );
};

type SpaceCounterProps = {
  counter: number;
  onPlus: () => void;
  onMinus: () => void;
  isMin: boolean;
  isMax: boolean;
};

type Props = {
  visible: boolean;
  onSave: (newSpaces: Array<number>) => void;
  onCancel: () => void;
  values: any;
  spaceTypes: Array<SpaceType>;
};

export default SpaceModal;
