import {
  HistoryOutlined,
  ThunderboltOutlined,
  UserAddOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

import DoubleThunderbolt from 'app/components/commons/DoubleThunderbolt/DoubleThunderbolt';
import {
  EARLY_CHECKIN,
  LATE_CHECKOUT,
} from 'app/components/commons/Experience/Availability/utils';
import {
  BABY_COT_BED_CATEGORY_ID,
  BED_TASK_CATEGORIES,
  DAY_PACKAGE_ROOM_CATEGORY_ID,
} from 'app/components/commons/Room/Types';
import { useAdditionalPax } from 'app/hooks/data/useAdditionalPax';
import { useExperiencesByHotelId } from 'app/hooks/data/useExperience';
import { useRooms } from 'app/hooks/data/useRooms';
import { useAppSelector } from 'app/redux/hooks';
import {
  selectHotelAdminHotelId,
  selectHotelAdminHotels,
} from 'app/redux/selectors/hotelAdmin';
import { assertNever } from 'app/utils/typing';

import { Inventory } from '../ExtranetInventory/Types';
import { isValidDiscount } from '../ExtranetInventory/Utils';

import { Task, TaskInfo, TaskName } from './TaskCenter/Types';

export const useTaskCenter = () => {
  const hotelId = useAppSelector(selectHotelAdminHotelId);

  const hotels = useAppSelector(selectHotelAdminHotels);
  const hotel = hotels.find((h) => h.id === hotelId);

  const hasChannelManager =
    hotel?.channelManager && hotel.channelManager !== 'NONE';
  const { data: rooms } = useRooms(hotelId as number);
  const { data: packageEG } = useAdditionalPax(hotelId as number);
  const { data: experiences } = useExperiencesByHotelId(hotelId as number);

  const hasActivatedPricingRule = hotel && !!hotel.autoDiscountRate;
  const hasActivatedLastMinutePricingRule =
    hotel && !!hotel.lastMinuteAutoDiscountRate;

  const filteredRooms = rooms?.filter(
    (room) => room.categoryId !== DAY_PACKAGE_ROOM_CATEGORY_ID
  );

  const doneTask: Array<TaskInfo> = BED_TASK_CATEGORIES.filter(
    (category) =>
      filteredRooms &&
      filteredRooms.length > 0 &&
      filteredRooms.some((room) =>
        room.beds.some((bed) => bed.categoryId === category)
      )
  ).map((category) => ({
    categoryId: category,
    taskName: category === BABY_COT_BED_CATEGORY_ID ? 'Baby cot' : 'Guest bed',
    priority: category === BABY_COT_BED_CATEGORY_ID ? 6 : 7,
    ...getTaskInformations(
      category === BABY_COT_BED_CATEGORY_ID ? 'Baby cot' : 'Guest bed'
    ),
  }));

  const missingTask: Array<TaskInfo> = BED_TASK_CATEGORIES.filter(
    (category) =>
      filteredRooms &&
      filteredRooms.length > 0 &&
      !filteredRooms.some((room) =>
        room.beds.some((bed) => bed.categoryId === category)
      )
  ).map((category) => ({
    categoryId: category,
    taskName: category === BABY_COT_BED_CATEGORY_ID ? 'Baby cot' : 'Guest bed',
    priority: category === BABY_COT_BED_CATEGORY_ID ? 6 : 7,
    ...getTaskInformations(
      category === BABY_COT_BED_CATEGORY_ID ? 'Baby cot' : 'Guest bed'
    ),
  }));

  hasChannelManager &&
    (hasActivatedPricingRule
      ? doneTask.push({
          taskName: 'Pricing rule',
          priority: 5,
          ...getTaskInformations('Pricing rule'),
        })
      : missingTask.push({
          taskName: 'Pricing rule',
          priority: 5,
          ...getTaskInformations('Pricing rule'),
        }));

  hasChannelManager &&
    (hasActivatedLastMinutePricingRule
      ? doneTask.push({
          taskName: 'Last minute pricing rule',
          priority: 4,
          ...getTaskInformations('Last minute pricing rule'),
        })
      : missingTask.push({
          taskName: 'Last minute pricing rule',
          priority: 4,
          ...getTaskInformations('Last minute pricing rule'),
        }));

  packageEG?.additionalAdultPrice !== undefined
    ? doneTask.push({
        taskName: 'Attract extra guests',
        priority: 2,
        ...getTaskInformations('Attract extra guests'),
      })
    : missingTask.push({
        taskName: 'Attract extra guests',
        priority: 2,
        ...getTaskInformations('Attract extra guests'),
      });

  packageEG?.singleCustomerDiscount !== undefined
    ? doneTask.push({
        taskName: 'Attract single guests',
        priority: 3,
        ...getTaskInformations('Attract single guests'),
      })
    : missingTask.push({
        taskName: 'Attract single guests',
        priority: 3,
        ...getTaskInformations('Attract single guests'),
      });

  const haveEarlyCheckInExp = experiences?.some(
    (exp) => exp.categoryId === EARLY_CHECKIN
  );

  const haveEarlyCheckOutExp = experiences?.some(
    (exp) => exp.categoryId === LATE_CHECKOUT
  );

  if (!haveEarlyCheckInExp) {
    missingTask.push({
      taskName: 'Early Check-in',
      priority: 1,
      ...getTaskInformations('Early Check-in'),
    });
  }

  if (!haveEarlyCheckOutExp) {
    missingTask.push({
      taskName: 'Late Check-out',
      priority: 1,
      ...getTaskInformations('Late Check-out'),
    });
  }

  const newDoneTask: Task[] = doneTask.map((task) => ({
    ...task,
    value: task.max,
    percent: getProgressPercent(task.max, task.max),
  }));

  const newMissingTask: Task[] = missingTask.map((task) => ({
    ...task,
    value: task.min,
    percent: getProgressPercent(task.min, task.max),
  }));

  newDoneTask.sort((taskA, taskB) => taskA.priority - taskB.priority);
  newMissingTask.sort((taskA, taskB) => taskA.priority - taskB.priority);

  return { doneTask: newDoneTask, missingTask: newMissingTask };
};

export type TaskInformation = {
  title: string;
  description: string;
  icon: JSX.Element;
  redirect: string | null;
  max: number;
  min: number;
};
export const getTaskInformations = (taskName: TaskName): TaskInformation => {
  switch (taskName) {
    case 'Baby cot':
      return {
        title: 'Complete your baby cots',
        description: 'Extend your offer to users coming with babies.',
        icon: <UserAddOutlined />,
        redirect: null,
        max: 1,
        min: 0,
      };

    case 'Guest bed':
      return {
        title: 'Complete your guest beds',
        description: 'Extend your offer to users coming with children.',
        icon: <UserAddOutlined />,
        redirect: null,
        max: 1,
        min: 0,
      };

    case 'Pricing rule':
      return {
        title: 'Set a standard discount',
        description:
          'Automatically publish your connected stocks according to your rules.',
        icon: <ThunderboltOutlined />,
        redirect: 'inventory',
        max: 1,
        min: 0,
      };

    case 'Last minute pricing rule':
      return {
        title: 'Set a last minute discount',
        description:
          'Set a different default discount to your last minute stocks.',
        icon: <DoubleThunderbolt />,
        redirect: 'inventory',
        max: 1,
        min: 0,
      };

    case 'Attract extra guests':
      return {
        title: 'Attract extra guests',
        description:
          'Adapt the price of your package for users travelling with more than 2 adults.',
        icon: <UsergroupAddOutlined />,
        redirect: null,
        max: 1,
        min: 0,
      };
    case 'Early Check-in':
      return {
        title: 'Boost your revenues with an Early Check-in',
        description:
          'Add an optional Early Check-in to improve your page visibility, conversion and average basket.',
        icon: <HistoryOutlined />,
        redirect: null,
        max: 1,
        min: 0,
      };

    case 'Attract single guests':
      return {
        title: 'Attract single guests',
        description:
          'Adapt the price of your package for users travelling alone.',
        icon: <UserOutlined />,
        redirect: null,
        max: 1,
        min: 0,
      };
    case 'Late Check-out':
      return {
        title: 'Boost your revenues with a Late Check-out',
        description:
          'Add an optional Late Check-out to improve your page visibility, conversion and average basket.',
        icon: <HistoryOutlined />,
        redirect: null,
        max: 1,
        min: 0,
      };

    default:
      assertNever(taskName);
  }
};

export const getProgressPercent = (actual: number, max: number) => {
  if (actual === 0) {
    return 0;
  }

  return (actual / max) * 100;
};

export const validateDiscountInput = (
  isRuleActive: boolean,
  inputDiscount: number,
  inventory: Inventory
) => {
  const discount = Math.trunc(inputDiscount);

  if (!isRuleActive) {
    return Promise.resolve('');
  }

  if (
    discount == null ||
    (inventory && !isValidDiscount(-discount, inventory.pkg.dayPackage))
  ) {
    return Promise.reject(new Error('Invalid discount'));
  }

  return Promise.resolve('');
};
