import { Col, Form, Input, InputNumber, Row } from 'antd';
import { Store } from 'antd/lib/form/interface';
import _ from 'lodash';
import React, { useEffect } from 'react';

import LazySelect from 'app/components/fields/LazySelect';
import SearchBox from 'app/components/fields/SearchBox';
import { Option, Select } from 'app/components/fields/Select';
import FormSection from 'app/components/forms/FormSection';
import Card from 'app/components/layout/Card';
import { useAdminUser } from 'app/hooks/data/useAdminUser';
import { tryFetchHotels } from 'app/redux/actions/hotels';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { Hotel } from 'app/redux/models/Hotel/Hotel';
import { selectCountries, selectHotelGroups } from 'app/redux/selectors/conf';
import { getHotelsHotels } from 'app/redux/selectors/hotels';

import Communication from './Communication';

type Props = {
  mode: 'view' | 'edit';
  values: Hotel;
  setFieldsValue: (value: Store) => void;
  originalBillingEmails: string[] | undefined;
};

const RULES = [{ required: true }];

const BasicInfo = ({
  mode,
  values,
  setFieldsValue,
  originalBillingEmails,
}: Props) => {
  const { data: admins } = useAdminUser();

  const dispatch = useAppDispatch();
  const countries = useAppSelector(selectCountries);
  const hotelGroups = useAppSelector(selectHotelGroups);
  const hotels = useAppSelector(getHotelsHotels);
  const isDisabled = mode === 'view';

  useEffect(() => {
    dispatch(tryFetchHotels());
  }, [dispatch]);

  return (
    <Card title="Basic information" formOnly>
      <FormSection title="Identity">
        <Form.Item label="Hotel name" name="name" rules={RULES}>
          <Input
            disabled={isDisabled}
            placeholder="Molitor, Vice Versa, etc..."
          />
        </Form.Item>
        <Row>
          <Col span={8}>
            <Form.Item label="Stars" name="stars" rules={RULES}>
              <InputNumber
                min={0}
                max={5}
                disabled={isDisabled}
                placeholder="1 - 5"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Room count" name="roomCount" rules={RULES}>
              <InputNumber
                min={0}
                step={10}
                disabled={isDisabled}
                placeholder="10 - 500"
              />
            </Form.Item>
          </Col>
        </Row>
      </FormSection>
      <FormSection title="Address">
        <Row>
          <Col span={16}>
            <Form.Item
              label="Street"
              name={['address', 'street']}
              rules={RULES}
            >
              <Input disabled={isDisabled} placeholder="130 av. Parmentier" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Zip" name={['address', 'zipCode']} rules={RULES}>
              <Input disabled={isDisabled} placeholder="75011" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="City" name={['address', 'city']} rules={RULES}>
              <Input disabled={isDisabled} placeholder="Magny-les-Hameaux" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Country"
              name={['address', 'countryId']}
              rules={RULES}
            >
              <Select disabled={isDisabled} placeholder="France, UK, ...">
                {countries.map((country) => (
                  <Option value={country.id}>{country.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Station"
              name="subway"
              // This rule should never cause an error, because it accepts any value from the Input
              // We only add it because if there is no rule, we can't edit the input's value
              // https://staycation-tech.atlassian.net/browse/P1-1044
              // TODO: remove this line when we upgrade antd to a version >= 4.17.0 (this version solves the bug).
              rules={[{ type: 'string', message: 'Please enter a text' }]}
            >
              <Input disabled={isDisabled} placeholder="Ordeal's sisters M8" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Lat"
              name={['location', 'coords', 'lat']}
              rules={RULES}
            >
              <InputNumber disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Lng"
              name={['location', 'coords', 'lng']}
              rules={RULES}
            >
              <InputNumber disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>
      </FormSection>
      <FormSection title="Display names">
        <Row>
          <Col span={18}>
            <Form.Item label="City" name={['address', 'city']} rules={RULES}>
              <LazySelect
                defaultValue=""
                // @ts-ignore
                baseOptions={_.uniqBy(_.map(hotels, 'address.city')).sort()}
                placeholder="big city name or a larger well-know area"
                extra="This is the name seen by users far away from this city"
                disabled={isDisabled}
                mode={mode}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Form.Item label="Subcity" name={['address', 'subCity']}>
              <LazySelect
                defaultValue=""
                // @ts-ignore
                baseOptions={_.uniqBy(_.map(hotels, 'address.subCity')).sort()}
                placeholder="district or small city name"
                extra="This is the name seen by user living close to this city"
                disabled={isDisabled}
                mode={mode}
              />
            </Form.Item>
          </Col>
        </Row>
      </FormSection>
      <Communication
        mode={mode}
        values={values}
        setFieldsValue={setFieldsValue}
        originalBillingEmails={originalBillingEmails}
      />
      <div className="admin_settings">
        <FormSection title="Admin settings">
          <Form.Item label="Hotel Group" name="groupId" rules={RULES}>
            <SearchBox
              dataSource={hotelGroups}
              placeholder="Choose in which group the hotel will appear"
              disabled={isDisabled}
            />
          </Form.Item>
          <Form.Item label="Contact BD or AM" name="managerId" rules={RULES}>
            <Select
              showSearch
              disabled={isDisabled}
              placeholder="Select a contact"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {admins?.map((admin) => (
                <Option
                  title={`${admin.firstName} ${admin.lastName}`}
                  key={admin.email}
                  id={admin.id}
                  value={admin.id}
                >
                  {admin.firstName && admin.lastName
                    ? `${admin.firstName} ${admin.lastName} - ${admin.email}`
                    : `${admin.email}`}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Parent hotel Id" name="parentHotelId">
            <InputNumber min={1} disabled={isDisabled} />
          </Form.Item>
        </FormSection>
      </div>
    </Card>
  );
};

export default BasicInfo;
