import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import {
  addDays,
  addWeeks,
  eachDayOfInterval,
  endOfWeek,
  format,
  isAfter,
  isSameDay,
  isSameISOWeek,
  isSunday,
  startOfDay,
  subDays,
} from 'date-fns';
import { FC } from 'react';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';

import { DuplicationMode, DuplicationModeData } from '../../Types';

export const DuplicationMenu: FC<Props> = ({
  date,
  currentRange,
  tryDuplicate,
  handleDuplicateHover,
  dropDuplicationHover,
}) => {
  const { track } = useAmplitude();

  const disableDuplicateToEndOfWeek = () => {
    const isEndOfWeek = isSunday(date);
    const endOfWeekDay = endOfWeek(startOfDay(date), {
      weekStartsOn: 1,
    });

    const isCurrentWeekIncomplete =
      isAfter(endOfWeekDay, currentRange.end) &&
      !isSameDay(endOfWeekDay, currentRange.end);

    return isEndOfWeek || isCurrentWeekIncomplete;
  };

  const disableDuplicateToEndOfNextWeek = () => {
    const isInLastWeek = isSameISOWeek(date, currentRange.end);
    const endOfNextWeekDay = endOfWeek(addWeeks(startOfDay(date), 1), {
      weekStartsOn: 1,
    });

    const isNextWeekIncomplete =
      isAfter(endOfNextWeekDay, currentRange.end) &&
      !isSameDay(endOfNextWeekDay, currentRange.end);

    return isInLastWeek || isNextWeekIncomplete;
  };

  const disableDuplicateToEndOfPeriod = isSameDay(date, currentRange.end);

  const remainingRange = isSameDay(date, currentRange.end)
    ? undefined
    : {
        start: date,
        end: subDays(currentRange.end, 1),
      };

  const getNumberOfDaysLabel = (day: Date) => {
    const numberOfDays = eachDayOfInterval({
      start: date,
      end: addDays(day, 1),
    }).length;

    return numberOfDays === 2 ? '1 day' : `${numberOfDays - 1} days`;
  };

  const trackOpen = (open: boolean) => {
    if (open) {
      track('Click Line Duplication', {
        from: format(date, 'yyyy-MM-dd'),
      });
    }
  };

  return (
    <Dropdown
      dropdownRender={() => (
        <Menu>
          <Menu.Item
            key={'duplicate-to-end-of-week'}
            disabled={disableDuplicateToEndOfWeek()}
            onMouseEnter={() =>
              handleDuplicateHover(addDays(date, 1), {
                mode: DuplicationMode.END_OF_WEEK,
              })
            }
            onMouseLeave={() => dropDuplicationHover()}
            onClick={() => tryDuplicate({ mode: DuplicationMode.END_OF_WEEK })}
          >
            Duplicate until end of this week
          </Menu.Item>
          <Menu.Item
            key={'duplicate-to-end-of-next-week'}
            disabled={disableDuplicateToEndOfNextWeek()}
            onMouseEnter={() =>
              handleDuplicateHover(addDays(date, 1), {
                mode: DuplicationMode.END_OF_NEXT_WEEK,
              })
            }
            onMouseLeave={() => dropDuplicationHover()}
            onClick={() =>
              tryDuplicate({ mode: DuplicationMode.END_OF_NEXT_WEEK })
            }
          >
            Duplicate until end of next week
          </Menu.Item>
          <Menu.Item
            key={'duplicate-to-end-of-period'}
            disabled={disableDuplicateToEndOfPeriod}
            onMouseEnter={() =>
              handleDuplicateHover(addDays(date, 1), {
                mode: DuplicationMode.END_OF_TABLE,
              })
            }
            onMouseLeave={() => dropDuplicationHover()}
            onClick={() => tryDuplicate({ mode: DuplicationMode.END_OF_TABLE })}
          >
            Duplicate until end of period
            {` (${format(currentRange.end, 'EEE dd/MM')})`}
          </Menu.Item>
          <Menu.SubMenu
            key={'duplicate-to-end-of-period-submenu'}
            disabled={disableDuplicateToEndOfPeriod}
            title="Duplicate until day of my choice"
          >
            {remainingRange &&
              eachDayOfInterval(remainingRange).map((day) => {
                return (
                  <Menu.Item
                    key={`duplicate-to-end-of-period-submenu-${day}`}
                    onMouseEnter={() =>
                      handleDuplicateHover(addDays(date, 1), {
                        mode: DuplicationMode.CUSTOM,
                        customDay: day,
                      })
                    }
                    onMouseLeave={() => dropDuplicationHover()}
                    onClick={() =>
                      tryDuplicate({
                        mode: DuplicationMode.CUSTOM,
                        customDay: day,
                      })
                    }
                  >
                    {`${getNumberOfDaysLabel(day)} (${format(
                      addDays(day, 1),
                      'EEE dd/MM'
                    )})`}
                  </Menu.Item>
                );
              })}
          </Menu.SubMenu>
        </Menu>
      )}
      trigger={['click']}
      onOpenChange={trackOpen}
    >
      <EllipsisOutlined rotate={90} />
    </Dropdown>
  );
};

type Props = {
  currentRange: Interval;
  date: Date;
  tryDuplicate: (modeData: DuplicationModeData) => void;
  handleDuplicateHover: (day: Date, hoverMode: DuplicationModeData) => void;
  dropDuplicationHover: () => void;
};

export default DuplicationMenu;
