import { Layout } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import HotelBuilder from 'app/components/pages/Extranet/HotelBuilder/HotelBuilder';
import UsersnapProvider from 'app/context/UsersnapContext/UsersnapContext';
import { initApp } from 'app/redux/actions/navigation';

const ExtApp = ({ user, appUrl, appReady, init }) => {
  useEffect(() => {
    init();
  }, [init]);

  if (!user || !appReady) {
    return null;
  }

  if (
    user &&
    user.role === 'user' &&
    (!user.hotelIds || !user.hotelIds.length)
  ) {
    // TODO: see how we can handle this better
    return window.location.replace(appUrl);
  }

  return (
    <UsersnapProvider>
      <Layout className="app">
        <Switch>
          <Route path="/ext/:hotelId" component={HotelBuilder} />
          <Redirect
            exact
            from="/ext"
            to={`/ext/${(user.hotelIds && user.hotelIds[0]) || 1}`}
          />
        </Switch>
      </Layout>
    </UsersnapProvider>
  );
};

ExtApp.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
    hotelIds: PropTypes.array,
  }),
  appUrl: PropTypes.string.isRequired,
  appReady: PropTypes.bool,
  init: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  appUrl: state.navigation.appUrl,
  appReady: state.navigation.appReady,
});

const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(initApp('builder')),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExtApp));
