import { Popover } from 'antd';
import * as React from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';

import styles from './ColorPicker.module.scss';

export type ColorPickerProps = React.HTMLAttributes<HTMLInputElement> & {
  value?: string;
  onChange?: (color: string) => void;
};

export const ColorPicker = ({
  value,
  onChange,
  ...props
}: ColorPickerProps) => {
  return (
    <Popover
      trigger="click"
      content={
        <div>
          <HexColorPicker color={value} onChange={onChange} />

          <div className={styles.inputContainer}>
            <span className={styles.inputLeftElement}>#</span>
            <HexColorInput {...props} color={value} className={styles.input} />
          </div>
        </div>
      }
    >
      <button
        aria-label="Click to edit color"
        className={styles.button}
        style={{ backgroundColor: value }}
      />
    </Popover>
  );
};
