import { message } from 'antd';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as collectionsActions from 'app/redux/actions/collections';
import {
  get,
  patch,
  post,
  requestGenerator,
  put as rput,
} from 'app/redux/requests';

export function* tryFetchCollections(
  action: ReturnType<typeof collectionsActions.tryFetchCollections>
) {
  yield requestGenerator(
    () => put(collectionsActions.startLoading()),
    () => {
      const stringifiedParams = queryString.stringify(action.payload);

      const request = `collections?${stringifiedParams}`;

      return get(request);
    },
    (result: any) =>
      put(collectionsActions.fetchCollectionsSuccess({ result })),
    (error: any) =>
      all([
        put(collectionsActions.stopLoading()),
        message.error('Error fetching Collections', error),
      ])
  );
}

export function* tryFetchCollectionPreview(
  action: ReturnType<typeof collectionsActions.tryFetchCollectionPreview>
) {
  yield requestGenerator(
    () => put(collectionsActions.startLoading()),
    () => {
      const stringifiedParams = queryString.stringify(action.payload);

      const request = `collections/preview?${stringifiedParams}`;

      return get(request);
    },
    (result: any) =>
      put(collectionsActions.fetchCollectionPreviewSuccess({ result })),
    (error: any) =>
      all([
        put(collectionsActions.stopLoading()),
        message.error('Error fetching Collection Preview', error),
      ])
  );
}

export function* tryUpdateCollectionParams(
  action: ReturnType<typeof collectionsActions.tryUpdateCollectionParams>
) {
  const {
    collectionId,
    trendDisplay,
    cardDisplay,
    sliceDisplay,
    searchDisplay,
    fetchParams,
  } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () =>
      patch(`collections/${collectionId}/display`, {
        trendDisplay,
        cardDisplay,
        sliceDisplay,
        searchDisplay,
      }),
    () =>
      all([
        message.success(`Featured collection #${collectionId}`),
        put(collectionsActions.tryFetchCollections(fetchParams)),
      ]),
    (error: any) =>
      message.error(
        `Failed to update display settings for  collection #${collectionId}`,
        error
      )
  );
}

export function* tryUpdateCollectionPublished(
  action: ReturnType<typeof collectionsActions.tryUpdateCollectionPublished>
) {
  const { collectionId, published, fetchParams } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => patch(`collections/${collectionId}/publish`, { published }),
    () =>
      all([
        message.success(`Published collection #${collectionId}`),
        put(collectionsActions.tryFetchCollections(fetchParams)),
      ]),
    (error: any) =>
      message.error(`Failed to publish collection #${collectionId}`, error)
  );
}

function* tryFetchCollection(
  action: ReturnType<typeof collectionsActions.tryFetchCollection>
) {
  const { collectionId } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => get(`collections/${collectionId}`),
    (result: any) => put(collectionsActions.fetchCollectionSuccess({ result })),
    (error: any) =>
      message.error(`Error fetching Collection id ${collectionId}`, error)
  );
}

function* tryCreateCollection(
  action: ReturnType<typeof collectionsActions.tryCreateCollection>
) {
  const { collection } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => post('collections', collection),
    (collection: any) =>
      all([
        message.success('Collection created'),
        put(push(`/collections/${collection.id}/edit`)),
      ]),
    (error: any, status: any) => {
      if (status === 409) {
        return message.error('Slug already exists');
      }

      return message.error('Error creating collection', error);
    }
  );
}

function* tryUpdateCollection(
  action: ReturnType<typeof collectionsActions.tryUpdateCollection>
) {
  const { id, collection } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => rput(`collections/${id}`, collection),
    (collection: any) => {
      message.success('Collection updated');

      return put(
        collectionsActions.fetchCollectionSuccess({ result: collection })
      );
    },
    (error: any) => message.error('Error updating collection', error)
  );
}

export default function* collectionsSaga() {
  yield all([
    takeEvery(
      collectionsActions.COLLECTIONS_TRY_FETCH_COLLECTIONS,
      tryFetchCollections
    ),
    takeEvery(
      collectionsActions.COLLECTIONS_TRY_FETCH_COLLECTION_PREVIEW,
      tryFetchCollectionPreview
    ),
    takeEvery(
      collectionsActions.COLLECTIONS_TRY_UPDATE_COLLECTION_PARAMS,
      tryUpdateCollectionParams
    ),
    takeEvery(
      collectionsActions.COLLECTIONS_TRY_UPDATE_COLLECTION_PUBLISHED,
      tryUpdateCollectionPublished
    ),
    takeLatest(
      collectionsActions.COLLECTIONS_TRY_FETCH_COLLECTION,
      tryFetchCollection
    ),
    takeLatest(
      collectionsActions.COLLECTIONS_TRY_CREATE_COLLECTION,
      tryCreateCollection
    ),
    takeLatest(
      collectionsActions.COLLECTIONS_TRY_UPDATE_COLLECTION,
      tryUpdateCollection
    ),
  ]);
}
