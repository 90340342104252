import { message } from 'antd';
import { push } from 'connected-react-router';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import * as packagesActions from 'app/redux/actions/packages';
import { pruneDisclaimer } from 'app/redux/models/Disclaimer/Disclaimer';
import {
  del,
  get,
  patch,
  post,
  requestGenerator,
  put as rput,
} from 'app/redux/requests';

export function* tryFetchPackage(
  action: ReturnType<typeof packagesActions.tryFetchPackage>
) {
  const {
    payload: { packageId },
  } = action;

  yield requestGenerator(
    () => put(packagesActions.startLoading()),
    () => get(`packages/${packageId}`),
    (result: any) =>
      put(
        packagesActions.fetchPackageSuccess({
          result: { id: packageId, ...result },
        })
      ),
    (error: any) =>
      all([
        put(packagesActions.stopLoading()),
        call(message.error('Error fetching Package', error)),
      ])
  );
}

export function* tryFetchPackages() {
  yield requestGenerator(
    () => put(packagesActions.startLoading()),
    () => get(`packages`),
    (result: any) =>
      put(
        packagesActions.fetchPackagesSuccess({
          result: result,
        })
      ),
    (error: any) =>
      all([
        put(packagesActions.stopLoading()),
        call(message.error('Error fetching Packages', error)),
      ])
  );
}

export function* trySavePackage(
  action: ReturnType<typeof packagesActions.trySavePackage>
) {
  const {
    payload: { pkg },
  } = action;

  yield requestGenerator(
    () => Promise.resolve,
    () => post('packages', pkg),
    (packageId: number) =>
      all([
        put(
          packagesActions.fetchPackageSuccess({
            result: { id: packageId, ...pkg },
          })
        ),
        put(packagesActions.tryFetchCopywriting({ packageId })),
        put(packagesActions.tryFetchDiscover({ packageId })),
        put(packagesActions.tryFetchCover({ packageId })),
        put(push(`/packages/${packageId}/edit`)),
      ]),
    (error: any) => message.error('Error posting Package', error)
  );
}

export function* tryDeletePackage(
  action: ReturnType<typeof packagesActions.tryDeletePackage>
) {
  const {
    payload: { packageId },
  } = action;

  yield requestGenerator(
    () => Promise.resolve,
    () => del(`packages/${packageId}`),
    () =>
      all([
        message.success(`Package ${packageId} deleted`),
        put(push('/packages')),
      ]),
    () => message.error('Not possible: this package is used somewhere else')
  );
}

export function* tryFetchDiscover(
  action: ReturnType<typeof packagesActions.tryFetchDiscover>
) {
  const {
    payload: { packageId },
  } = action;

  yield requestGenerator(
    () => Promise.resolve,
    () => get(`packages/${packageId}/discover`),
    (result: any) =>
      put(
        packagesActions.fetchDiscoverSuccess({
          result: { packageId, ...result },
        })
      ),
    (error: any) => {
      message.error('Error fetching Discover', error);
    }
  );
}

export function* trySaveDiscover(
  action: ReturnType<typeof packagesActions.trySaveDiscover>
) {
  const {
    payload: { packageId, discover },
  } = action;

  yield requestGenerator(
    () => Promise.resolve,
    () => rput(`packages/${packageId}/discover`, discover),
    () =>
      put(
        packagesActions.fetchDiscoverSuccess({
          result: { packageId, ...discover },
        })
      ),
    (error: any) => {
      message.error('Error posting Discover', error);
    }
  );
}

export function* tryFetchCover(
  action: ReturnType<typeof packagesActions.tryFetchCover>
) {
  const {
    payload: { packageId },
  } = action;

  yield requestGenerator(
    () => Promise.resolve,
    () => get(`packages/${packageId}/cover`),
    (result: any) =>
      put(
        packagesActions.fetchCoverSuccess({
          result: { id: packageId, ...result },
        })
      ),
    (error: any) => {
      message.error('Error fetching Cover', error);
    }
  );
}

export function* trySaveCover(
  action: ReturnType<typeof packagesActions.trySaveCover>
) {
  const {
    payload: { packageId, cover },
  } = action;

  yield requestGenerator(
    () => Promise.resolve,
    () => rput(`packages/${packageId}/cover`, cover),
    () => put(packagesActions.tryFetchCover({ packageId })),
    (error: any) => {
      message.error('Error posting Cover', error);
    }
  );
}

export function* tryFetchCopywriting(
  action: ReturnType<typeof packagesActions.tryFetchCopywriting>
) {
  const {
    payload: { packageId },
  } = action;

  yield requestGenerator(
    () => Promise.resolve,
    () => get(`packages/${packageId}/copywriting`),
    (result: any) =>
      put(
        packagesActions.fetchCopywritingSuccess({
          result: { id: packageId, ...result },
        })
      ),
    (error: any) => {
      message.error('Error fetching Copywriting', error);
    }
  );
}

export function* trySaveCopywriting(
  action: ReturnType<typeof packagesActions.trySaveCopywriting>
) {
  const {
    payload: { packageId, copywriting },
  } = action;

  yield requestGenerator(
    () => Promise.resolve,
    () => rput(`packages/${packageId}/copywriting`, copywriting),
    () =>
      all([
        put(packagesActions.tryFetchCopywriting({ packageId })),
        put(packagesActions.tryFetchPackage({ packageId })),
      ]),
    (error: any) => {
      message.error('Error saving Copywriting', error);
    }
  );
}

export function* fetchDisclaimers(
  action: ReturnType<typeof packagesActions.fetchDisclaimers>
) {
  const {
    payload: { packageId },
  } = action;

  yield requestGenerator(
    () => Promise.resolve,
    () => get(`packages/${packageId}/disclaimers`),
    (result: any) =>
      put(
        packagesActions.fetchDisclaimersSuccess({
          result: { id: packageId, disclaimers: result },
        })
      ),
    (error: any) => {
      message.error('Error fetching disclaimers', error);
    }
  );
}

export function* saveDisclaimers(
  action: ReturnType<typeof packagesActions.saveDisclaimers>
) {
  const {
    payload: { packageId, disclaimers },
  } = action;
  const disclaimersPruned = disclaimers.map((d) => pruneDisclaimer(d));

  yield requestGenerator(
    () => Promise.resolve,
    () => rput(`packages/${packageId}/disclaimers`, disclaimersPruned),
    (result: any) =>
      put(
        packagesActions.fetchDisclaimersSuccess({
          result: { id: packageId, disclaimers: result },
        })
      ),
    (error: any) => {
      message.error('Error saving disclaimers', error);
    }
  );
}

export function* tryPublishPackage(
  action: ReturnType<typeof packagesActions.tryPublishPackage>
) {
  const { packageId, published } = action.payload;

  yield requestGenerator(
    () => put(packagesActions.startLoading()),
    () => patch(`packages/${packageId}/publish`, { published }),
    () =>
      all([
        put(
          packagesActions.publishPackageSuccess({
            packageId,
            published,
          })
        ),
        call(
          message.success(
            `Package ${packageId} ${published ? 'published' : 'unpublished'}`
          )
        ),
      ]),
    (error: any) =>
      all([
        put(packagesActions.stopLoading()),
        call(message.error(`Error publishing package ${packageId}`, error)),
      ])
  );
}

export function* tryHidePackage(
  action: ReturnType<typeof packagesActions.tryHidePackage>
) {
  const { packageId, hidden } = action.payload;

  yield requestGenerator(
    () => put(packagesActions.startLoading()),
    () => patch(`packages/${packageId}/hide`, { hidden }),
    () =>
      all([
        put(
          packagesActions.hidePackageSuccess({
            packageId,
            hidden,
          })
        ),
        call(
          message.success(`Package ${packageId} ${hidden ? 'hidden' : 'shown'}`)
        ),
      ]),
    (error: any) =>
      all([
        put(packagesActions.stopLoading()),
        call(message.error(`Error hiding package ${packageId}`, error)),
      ])
  );
}

export default function* entitiesSagas() {
  yield all([
    takeEvery(packagesActions.PACKAGES_TRY_FETCH_PACKAGE, tryFetchPackage),
    takeEvery(packagesActions.PACKAGES_TRY_FETCH_PACKAGES, tryFetchPackages),
    takeEvery(packagesActions.PACKAGES_TRY_SAVE_PACKAGE, trySavePackage),
    takeEvery(packagesActions.PACKAGES_TRY_DELETE_PACKAGE, tryDeletePackage),
    takeEvery(packagesActions.PACKAGES_TRY_FETCH_DISCOVER, tryFetchDiscover),
    takeEvery(packagesActions.PACKAGES_TRY_SAVE_DISCOVER, trySaveDiscover),
    takeEvery(packagesActions.PACKAGES_TRY_FETCH_COVER, tryFetchCover),
    takeEvery(packagesActions.PACKAGES_TRY_SAVE_COVER, trySaveCover),
    takeEvery(
      packagesActions.PACKAGES_TRY_FETCH_COPYWRITING,
      tryFetchCopywriting
    ),
    takeEvery(
      packagesActions.PACKAGES_TRY_SAVE_COPYWRITING,
      trySaveCopywriting
    ),
    takeEvery(packagesActions.PACKAGE_FETCH_DISCLAIMERS, fetchDisclaimers),
    takeEvery(packagesActions.PACKAGE_SAVE_DISCLAIMERS, saveDisclaimers),
    takeEvery(packagesActions.PACKAGES_TRY_PUBLISH_PACKAGE, tryPublishPackage),
    takeEvery(packagesActions.PACKAGES_TRY_HIDE_PACKAGE, tryHidePackage),
  ]);
}
