/* eslint-disable no-case-declarations */
import { AnyAction } from '@reduxjs/toolkit';

import {
  EXPERIENCES_FETCH_EXPERIENCES_BRANDS_SUCCESS,
  EXPERIENCES_FETCH_EXPERIENCES_SUCCESS,
  EXPERIENCES_FETCH_EXPERIENCE_SUCCESS,
  EXPERIENCES_FETCH_HOTEL_EXPERIENCES_SUCCESS,
  EXPERIENCES_FETCH_HOTEL_EXPERIENCE_SUCCESS,
  EXPERIENCES_PUBLISH_EXPERIENCE_SUCCESS,
  EXPERIENCES_RESET_EXPERIENCE,
  EXPERIENCES_START_LOADING,
  EXPERIENCES_STOP_LOADING,
} from 'app/redux/actions/experiences';
import { createExperience } from 'app/redux/models/Experience/Experience';
import type { Experience } from 'app/redux/models/Experience/Experience';
import { createExperienceListElement } from 'app/redux/models/ExperienceListElement/ExperienceListElement';
import type { ExperienceListElement } from 'app/redux/models/ExperienceListElement/ExperienceListElement';

export type Experiences = {
  readonly loading: boolean;
  readonly hotelExperiences: Array<Experience>;
  readonly experiencesBrands: Array<any>;
  readonly experience: Experience | null;
  readonly experiences: Array<ExperienceListElement>;
};

export const initialState: Experiences = {
  loading: false,
  hotelExperiences: [],
  experiencesBrands: [],
  experience: null,
  experiences: [],
};

function experiences(state = initialState, action: AnyAction): Experiences {
  switch (action.type) {
    case EXPERIENCES_START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EXPERIENCES_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case EXPERIENCES_FETCH_HOTEL_EXPERIENCES_SUCCESS:
      return {
        ...state,
        hotelExperiences: action.result.map(createExperience),
      };
    case EXPERIENCES_FETCH_EXPERIENCES_BRANDS_SUCCESS:
      return {
        ...state,
        experiencesBrands: action.result,
      };
    case EXPERIENCES_FETCH_EXPERIENCE_SUCCESS:
      return {
        ...state,
        experience: createExperience(action.result),
      };
    case EXPERIENCES_FETCH_HOTEL_EXPERIENCE_SUCCESS:
      const expIndex = state.hotelExperiences.findIndex(
        (exp) => exp.id === action.result.id
      );

      if (expIndex < 0) {
        return state;
      }

      return {
        ...state,
        hotelExperiences: [
          ...state.hotelExperiences.slice(0, expIndex),
          createExperience(action.result),
          ...state.hotelExperiences.slice(expIndex + 1),
        ],
      };
    case EXPERIENCES_FETCH_EXPERIENCES_SUCCESS:
      const newExperiences = action.result.map((e: any) =>
        createExperienceListElement(e)
      );

      return {
        ...state,
        experiences: newExperiences,
      };

    case EXPERIENCES_PUBLISH_EXPERIENCE_SUCCESS: {
      const expIndex = state.experiences.findIndex(
        (e) => e.id === action.experienceId
      );

      if (expIndex < 0) {
        return state;
      }

      return {
        ...state,
        experiences: [
          ...state.experiences.slice(0, expIndex),
          {
            ...state.experiences[expIndex],
            published: action.result.published,
          },
          ...state.experiences.slice(expIndex + 1),
        ],
      };
    }

    case EXPERIENCES_RESET_EXPERIENCE: {
      return {
        ...state,
        experience: null,
      };
    }

    default:
      return state;
  }
}

export default experiences;
