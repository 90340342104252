import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { apiMutation } from 'app/utils/request/api';

import { QuickFilterListItem } from '../../../components/pages/QuickFilters/types';

const path = '/admin/quick-filters/:id/published' as const;

type PayloadProps = { id: string; published: boolean };

export const useUpdatePublishQuickFilters = () => {
  return useMutation<QuickFilterListItem[], unknown, PayloadProps, unknown>({
    mutationFn: async (payload: PayloadProps) =>
      apiMutation(
        'PATCH',
        {
          path: path,
          params: { id: payload.id },
        },
        { published: payload.published }
      ),
    onSuccess: () => {
      message.success('Successfully updated');
    },
    onError: () => {
      message.error('Error while updating quick filter');
    },
  });
};
