import { Menu, MenuProps } from 'antd';
import React, { useState } from 'react';

import { SectionsAnchor } from 'app/typings/analytics';

export const DashboardMenu = ({
  menu,
}: {
  menu: { label: string; key: string }[];
}) => {
  const items: MenuProps['items'] = menu;
  const [currentAnchor, setCurrentAnchor] =
    useState<SectionsAnchor>('revenues');

  const onClick: MenuProps['onClick'] = (e) => {
    const element = document.getElementById(e.key);

    setCurrentAnchor(e.key as SectionsAnchor);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Menu
      defaultActiveFirst
      onClick={onClick}
      mode="horizontal"
      items={items}
      selectedKeys={[currentAnchor]}
    />
  );
};

export default DashboardMenu;
