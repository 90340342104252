import { Form, Switch } from 'antd';
import React from 'react';

import { SCard } from 'app/components/StaycationUI';
import {
  CheckboxRow,
  ColorPickerRow,
  InputRow,
  InputTextAreaRow,
  PictureRow,
  SelectRow,
} from 'app/components/commons/CustomFields/CustomFields';
import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';
import { Option, Select } from 'app/components/fields/Select';
import { useAppSelector } from 'app/redux/hooks';
import { selectClubs, selectCountries } from 'app/redux/selectors/conf';
import { selectEmojis } from 'app/redux/selectors/pictures';

const RULES = [{ required: true, message: 'Required' }];

const formatEmojiName = (emojiName: any) => {
  const shortEmojiName = emojiName.substr(6);
  const firstLetter = shortEmojiName.charAt(0).toUpperCase();
  const remainder = shortEmojiName.slice(1);

  return `${firstLetter}${remainder}`;
};

type Props = {
  isNew: boolean;
  isCollectionWhen?: boolean;
  setIsCollectionWhen: (isCollectionWhen: boolean) => void;
};

const CollectionForm = ({
  isNew,
  isCollectionWhen,
  setIsCollectionWhen,
}: Props) => {
  const form = Form.useFormInstance();
  const emojis = useAppSelector(selectEmojis);
  const clubs = useAppSelector(selectClubs);
  const countries = useAppSelector(selectCountries);

  const handleCollectionWhenToggle = (switchValue: boolean) => {
    setIsCollectionWhen(switchValue);

    if (!switchValue) {
      const listSections = form.getFieldsValue();

      if (listSections.sections && listSections.sections.length > 0) {
        listSections.sections.forEach((element: any, key: any) => {
          form.setFieldValue(['sections', key, 'rule', 'dates'], []);
        });
      }
    }
  };

  return (
    <SCard title="General">
      <SelectRow
        label="Country"
        name="countryId"
        placeholder="France, Belgique"
        required
        disabled={!isNew}
        showSearch
        dataSource={countries}
      />
      <div className="input-row">
        <div className="input-label">Club</div>
        <div className="input-select">
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const countryId = getFieldValue('countryId');
              const filteredClubs = clubs.filter(
                (club) => club.countryId === countryId
              );

              return (
                <Form.Item name="clubId">
                  <Select
                    placeholder="Club"
                    disabled={!countryId || !isNew}
                    allowClear
                  >
                    {filteredClubs.map((club) => (
                      <Option key={club.id} value={club.id}>
                        {club.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
        </div>
      </div>
      <InputRow
        label="Title"
        name="name"
        placeholder="Staycations avec piscine"
        required
      />
      <InputRow
        label="Slug"
        name="slug"
        placeholder="as-piscine-fr"
        required
        disabled={!isNew}
      />
      <InputRow
        label="Trend title"
        name="trendName"
        placeholder="Piscine"
        required
        max={25}
      />
      <InputRow
        label="Card title"
        name="cardName"
        placeholder="Piscine"
        required
        max={25}
      />
      <InputRow
        label="Slice title"
        name="sliceName"
        placeholder="Les meilleurs piscines avec votre +1"
        required
      />
      <InputRow
        label="Search title"
        name="searchName"
        placeholder="Piscine"
        max={15}
      />
      <InputRow
        label="Page title"
        name="pageTitle"
        placeholder="Piscine à Paris : les meilleures expériences d'hôtel 4 & 5 étoiles"
      />
      <InputRow
        label="Introduction"
        name="introduction"
        placeholder="Introduction"
      />
      <div className="input-row">
        <div className="input-label">Emoji</div>
        <div className="input-select">
          <Form.Item name="emojiId" rules={RULES}>
            <Select placeholder="Select an emoji">
              {emojis.map((emoji: any) => (
                <Option key={emoji.id} value={emoji.id}>
                  <PictureComponent {...emoji} width={15} />
                  <span className="emoji-option">
                    {formatEmojiName(emoji.name)}
                  </span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => (
          <PictureRow
            label="Cover Picture"
            name="cover"
            required={!!getFieldValue('imageDisplayed')}
          />
        )}
      </Form.Item>

      <CheckboxRow name="imageDisplayed" label="Image Displayed" />
      <ColorPickerRow label="Cover Color" name="color" required />

      <InputTextAreaRow
        label="Meta Desc."
        name="description"
        placeholder="À vous les piscines d'hôtel de luxe à Paris et alentours, chaque week-end."
        rows={4}
      />

      <div className="input-row">
        <div className="input-label">Collection WHEN</div>
        <div className="input-select">
          <Form.Item
            label="Switch"
            shouldUpdate
            valuePropName="checked"
            noStyle
          >
            <Switch
              checked={isCollectionWhen}
              onChange={handleCollectionWhenToggle}
            />
          </Form.Item>
        </div>
      </div>
    </SCard>
  );
};

export default CollectionForm;
