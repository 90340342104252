import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React from 'react';

type Props = {
  onActionClick: () => void;
};

export const BillsActionMenu = ({ onActionClick }: Props) => {
  return (
    <div className="actions-menu">
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={onActionClick}>Download</Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );
};
