import { Picture } from '../Picture/Picture';

type Mode = 'OR' | 'AND';

export type CollectionRules = {
  readonly dates: Array<string>;
  readonly tagIds: Array<number>;
  readonly mode: Mode;
  readonly dayPackage: boolean;
  readonly includedOnly: boolean;
};

export type CollectionSection = {
  readonly title: string;
  readonly rule: CollectionRules;
  readonly sectionOrder: number;
  readonly id?: number;
};

export type CollectionForm = {
  readonly slug: string;
  readonly name: string;
  readonly emojiId: number;
  readonly cover?: Picture;
  readonly trendName: string;
  readonly cardName: string;
  readonly sliceName: string;
  readonly searchName: string;
  readonly color: string;
  readonly introduction?: string;
  readonly pageTitle?: string;
  readonly description?: string;
  readonly clubId?: number;
  readonly countryId: number;
  readonly sections: Array<CollectionSection>;
  readonly imageDisplayed: boolean;
  readonly trendDisplay: boolean;
  readonly cardDisplay: boolean;
  readonly sliceDisplay: boolean;
};

export type Collection = CollectionForm & {
  readonly id?: number;
};

export function createCollection(values: any): Collection {
  const {
    id,
    slug,
    name,
    emojiId,
    cover,
    trendName,
    cardName,
    sliceName,
    searchName,
    color,
    introduction,
    pageTitle,
    description,
    clubId,
    countryId,
    sections,
    imageDisplayed,
    trendDisplay,
    cardDisplay,
    sliceDisplay,
  } = values;

  return {
    id,
    slug,
    name,
    emojiId,
    cover,
    trendName,
    cardName,
    sliceName,
    searchName,
    color,
    introduction,
    pageTitle,
    description,
    clubId,
    countryId,
    sections,
    imageDisplayed,
    trendDisplay,
    cardDisplay,
    sliceDisplay,
  };
}
