import { Badge, Layout, Menu } from 'antd';
import React, { FC } from 'react';
import { Link, NavLink } from 'react-router-dom';

import BuilderLogo from 'app/components/commons/icons/BuilderLogo';

import 'styles/layout/side-menu.scss';

import './SideMenu.scss';

const { Sider } = Layout;

const SideMenu: FC<Props> = ({ steps, step, validSteps }) => {
  const currentStep = steps.find((el) => el.order === step);
  const selectedKeys = currentStep ? [currentStep.key] : [];

  return (
    <Sider className="sider hotel-builder-sider">
      <Link to="/ext">
        <div className="title">
          <BuilderLogo />
        </div>
      </Link>
      <Menu
        theme="dark"
        className="menu"
        mode="inline"
        selectedKeys={selectedKeys}
      >
        {steps.map(({ key, title, order }) => {
          const state =
            order === step
              ? 'in-progress'
              : validSteps[order]
              ? 'done'
              : 'not-done';

          return (
            <Menu.Item
              className={`standalone-item ${state}`}
              key={key}
              disabled={state !== 'in-progress'}
            >
              <NavLink to="#">
                <div>
                  <Badge color="grey" />
                  {title}
                </div>
              </NavLink>
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
};

type Props = {
  steps: Array<{
    key: string;
    title: string;
    order: number;
  }>;
  step: number;
  validSteps: {
    [key: string]: boolean;
  };
};

export default SideMenu;
