import { Button } from 'antd';
import React from 'react';

import { GiftCard } from 'app/redux/models/GiftCard/GiftCard';
import { formatCurrency } from 'app/utils/strings';

import Line from '../Line/Line';

type Props = {
  giftCard: GiftCard;
  setIsRefundModalVisible: (isVisible: boolean) => void;
};

const Payment = ({ giftCard, setIsRefundModalVisible }: Props) => {
  const { cardInfo, initialValue, currency, status } = giftCard;

  const chargedAmount = formatCurrency(initialValue, currency);

  return (
    <div className="bloc">
      <div className="title-with-button2">
        <div className="label">Payment</div>
        {status === 'active' && (
          <Button
            className="button"
            onClick={() => setIsRefundModalVisible(true)}
          >
            Refund
          </Button>
        )}
      </div>
      <div className="generic-content">
        {cardInfo && (
          <Line title="Card info" value={`•••• ${cardInfo.last4}`} />
        )}
        <Line title="Charged amount" value={chargedAmount} />
      </div>
    </div>
  );
};

export default Payment;
