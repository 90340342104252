import React from 'react';

const Info = (props) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M12 23.75C5.51 23.75.25 18.49.25 12S5.51.25 12 .25 23.75 5.51 23.75 12 18.49 23.75 12 23.75zm0-1.5c5.66 0 10.25-4.59 10.25-10.25S17.66 1.75 12 1.75 1.75 6.34 1.75 12 6.34 22.25 12 22.25z" />
      <path d="M12.335 14.67a.75.75 0 1 1-.67-1.34l2.342-1.172a2.25 2.25 0 0 0 1.243-2.012V10A2.25 2.25 0 0 0 13 7.75h-1A2.25 2.25 0 0 0 9.75 10a.75.75 0 1 1-1.5 0A3.75 3.75 0 0 1 12 6.25h1A3.75 3.75 0 0 1 16.75 10v.146a3.75 3.75 0 0 1-2.073 3.354l-2.342 1.17zM12.75 18.01a.75.75 0 1 1-1.5 0V18a.75.75 0 1 1 1.5 0v.01z" />
    </g>
  </svg>
);

export default Info;
