import type { ExperienceCategory } from 'app/redux/models/ExperienceCategory/ExperienceCategory';

import { DurationDescription, ExperienceNameArg } from './Types';

const displayDuration = (duration: number, durationDescription: string) => {
  switch (durationDescription) {
    case DurationDescription.MINUTE: {
      if (duration < 60) {
        return ` ${duration}min`;
      }

      const hour = Math.floor(duration / 60);
      const minutes = duration % 60;

      return minutes === 0
        ? ` ${hour}h`
        : ` ${hour}h${minutes < 10 ? `0${minutes}` : minutes}`;
    }

    case DurationDescription.HOUR:
      return `${duration}h`;
    case DurationDescription.HALF_DAY:
      return `${duration} ${duration > 1 ? 'half-days' : 'half-day'}`;
    case DurationDescription.DAY:
      return `${duration} ${duration > 1 ? 'days' : 'day'}`;
    default:
      return `${duration}h`;
  }
};

// eslint-disable-next-line import/prefer-default-export
export const computeExperienceName = ({
  category,
  quantity,
  brand,
  duration,
  durationDescription,
  time,
  locations,
}: ExperienceNameArg): string => {
  if (!category) {
    return 'Experience';
  }

  const categoryName = category.name;
  const brandString = (category.brand && brand) || '';
  const durationString =
    category.duration && duration && durationDescription
      ? displayDuration(duration, durationDescription)
      : '';
  const timeString =
    category.time && time
      ? `• ${typeof time !== 'string' ? time.format('HH:mm') : time}`
      : '';
  const location = category.locations && locations ? `• ${locations}` : '';
  const quantityString = category.quantityTitle ? quantity : '';
  const title = [
    quantityString,
    categoryName,
    brandString,
    durationString,
    timeString,
    location,
  ]
    .filter((e) => !!e)
    .join(' ');

  return title || 'Experience';
};

export const getCategoryEmoji = (
  categoryId: number,
  experienceCategories: Array<ExperienceCategory>,
  emojis: Array<any>
) => {
  const category = experienceCategories.find((exp) => exp.id === categoryId);

  if (category) {
    const emoji = emojis.find((e) => e.id === category.pictureId);

    return emoji || {};
  }

  return {};
};
