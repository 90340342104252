import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, TimePicker } from 'antd';
import moment from 'moment';
import React, { FC } from 'react';

import { SpaceValueType } from 'app/components/commons/Hotel/Types';

import './Desk.scss';

const RULES = [{ required: true, message: 'Required' }];

export const Desk: FC<Props> = ({ mode = 'edit', values }) => (
  <div className="desk">
    {(!!values.desk?.floor || mode === 'edit') && (
      <div
        className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}
      >
        <Form.Item name={['desk', 'floor']} label="Floor">
          {mode === 'edit' || !values.desk?.floor ? (
            <InputNumber min={-5} placeholder="1, 2, 3, ..." />
          ) : (
            <div className="view-text">{values.desk.floor}</div>
          )}
        </Form.Item>
        {mode === 'edit' && (
          <div className="description">
            <InfoCircleOutlined />
            <div>Considering 0 as ground floor</div>
          </div>
        )}
      </div>
    )}
    {(!!values.desk?.phone || mode === 'edit') && (
      <div
        className={`input-row input-row-small-input ${
          mode === 'view' ? 'input-row-no-rules' : ''
        }`}
      >
        <Form.Item
          name={['desk', 'phone']}
          label="Direct phone"
          rules={[
            {
              pattern: /^[0-9 .()+-]*$/,
              message:
                'Invalid phone format, characters authorized : "0-9 . ( ) + -"',
            },
          ]}
        >
          {mode === 'edit' || !values.desk?.phone ? (
            <Input placeholder="555-555-789" />
          ) : (
            <div className="view-text">{values.desk.phone}</div>
          )}
        </Form.Item>
        {mode === 'edit' && (
          <div className="description">
            <InfoCircleOutlined />
            <div>If there is one</div>
          </div>
        )}
      </div>
    )}
    <div className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}>
      <Form.Item name={['desk', 'checkInTime']} label="Check-in" rules={RULES}>
        {mode === 'edit' || !values.desk?.checkInTime ? (
          <TimePicker minuteStep={30} format="HH:mm" />
        ) : (
          <div className="view-text">
            {moment(values.desk.checkInTime).format('HH:mm')}
          </div>
        )}
      </Form.Item>
    </div>
    <div className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}>
      <Form.Item
        name={['desk', 'checkOutTime']}
        label="Check-out"
        rules={RULES}
      >
        {mode === 'edit' || !values.desk?.checkOutTime ? (
          <TimePicker minuteStep={30} format="HH:mm" />
        ) : (
          <div className="view-text">
            {moment(values.desk.checkOutTime).format('HH:mm')}
          </div>
        )}
      </Form.Item>
    </div>
  </div>
);

type Props = {
  mode?: string;
  values: SpaceValueType;
};

export default Desk;
