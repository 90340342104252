export type UserDetailData = {
  birthDate: string;
  club: string;
  createdAt: string;
  email: string;
  firstName: string;
  gender: string;
  id: number;
  langage: string;
  lastLogin: string;
  lastName: string;
  locality: string;
  phoneNumber: string;
  role: string;
  referrerId: number;
  avatarPath: string;
  deletedAt: string;
  countrySlug: string;
  referralCode: string;
};

export type UserBooking = {
  code: string;
  status:
    | 'accepted'
    | 'rejected'
    | 'cancelled'
    | 'expired'
    | 'payment_failure'
    | 'cart'
    | 'aborted';
  hotelName: string;
  checkin: string;
  createdAt: string;
  amount: number;
  experienceScore: number;
};

export type UserGiftCard = {
  code: string;
  cardType: string;
  date: string;
  amount: number;
  status: 'active' | 'empty' | 'expired' | 'pending' | 'rejected';
};

export type UserDetail = {
  referres: Array<number>;
  user: UserDetailData;
  userBookings: Array<UserBooking>;
  userGiftCards: Array<UserGiftCard>;
};

export type UserList = {
  id: number;
  firstName?: string;
  lastName?: string;
  createdAt: string;
  email: string;
  role: string;
  clubId: number;
};

function createUser(values: any): UserDetailData {
  const {
    birthDate,
    club,
    createdAt,
    email,
    firstName,
    gender,
    id,
    langage,
    lastLogin,
    lastName,
    locality,
    phoneNumber,
    role,
    referrerId,
    avatarPath,
    deletedAt,
    countrySlug,
    referralCode,
  } = values;

  return {
    birthDate,
    club,
    createdAt,
    email,
    firstName,
    gender,
    id,
    langage,
    lastLogin,
    lastName,
    locality,
    phoneNumber,
    role,
    referrerId,
    avatarPath,
    deletedAt,
    countrySlug,
    referralCode,
  };
}

function createUserBooking(values: any): UserBooking {
  const {
    code,
    hotelName,
    checkin,
    createdAt,
    amount,
    experienceScore,
    status,
  } = values;

  return {
    status,
    code,
    hotelName,
    checkin,
    createdAt,
    amount,
    experienceScore,
  };
}

function createUserGiftCard(values: any): UserGiftCard {
  const { code, cardType, date, amount, status } = values;

  return {
    code,
    cardType,
    date,
    amount,
    status,
  };
}

export function createUserDetail(values: any): UserDetail {
  const { referres, user, userBookings, userGiftCards } = values;

  return {
    referres,
    user: createUser(user),
    userBookings: userBookings.map((ub: any) => createUserBooking(ub)),
    userGiftCards: userGiftCards.map((ugc: any) => createUserGiftCard(ugc)),
  };
}
