import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import {
  resetDirtyOpenings,
  tryFetchInventory,
} from 'app/redux/actions/hotelAdmin';
import { useAppDispatch } from 'app/redux/hooks';
import { IBulkEditPayload } from 'app/typings/bulkEdit';
import { apiMutation } from 'app/utils/request/api';

const path = '/hotel-admin/bulk-openings/:hotelId' as const;

export function useBulkEditSave(hotelId: number, start?: Date | string) {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: async (payload: IBulkEditPayload) =>
      apiMutation(
        'POST',
        {
          path,
          params: { hotelId: hotelId.toString() },
        },
        payload
      ) as Promise<[]>,
    onSuccess: (_, bulkEditPayload) => {
      if (!bulkEditPayload?.dryRun) {
        dispatch(tryFetchInventory({ hotelId, start }));
        dispatch(resetDirtyOpenings());
      }
    },
    onError: (err) => {
      message.error('Error saving BulkEdit');
    },
  });
}
