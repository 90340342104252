import { createAction } from '@reduxjs/toolkit';
import type { DateRange } from 'moment-range';

import type { Inventory } from 'app/components/pages/Extranet/ExtranetInventory/Types';
import type { Bill } from 'app/redux/models/Bill/Bill';
import type { ExtranetBooking } from 'app/redux/models/ExtranetBooking/ExtranetBooking';
import type { HotelAdminContact } from 'app/redux/models/Hotel/Hotel';

import { Opening } from '../../components/pages/Extranet/ExtranetInventory/Types';

export const TRY_FETCH_SALE_DATE = 'TRY_FETCH_SALE_DATE';
export const TRY_SAVE_OPENINGS = 'TRY_SAVE_OPENINGS';
export const FETCH_SALE_DATE_SUCCESS = 'FETCH_SALE_DATE_SUCCESS';
export const FETCH_SALE_DATE_FAILED = 'FETCH_SALE_DATE_FAILED';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const UPDATE_HOTEL_ID = 'UPDATE_HOTEL_ID';
export const UPDATE_DIRTY_OPENING = 'UPDATE_DIRTY_OPENING';
export const UPDATE_DIRTY_OPENING_FIELD = 'UPDATE_DIRTY_OPENING_FIELD';
export const TOGGLE_IS_EDITING = 'TOGGLE_IS_EDITING';
export const RESET_DIRTY_OPENINGS = 'RESET_DIRTY_OPENINGS';
export const TRY_VALIDATE_OPENINGS = 'TRY_VALIDATE_OPENINGS';
export const SET_DIRTY_OPENINGS = 'SET_DIRTY_OPENINGS';
export const TOGGLE_VALIDATION_FAILED = 'TOGGLE_VALIDATION_FAILED';
export const TOGGLE_VIEW_VALIDATION_FAILED = 'TOGGLE_VIEW_VALIDATION_FAILED';
export const TRY_FETCH_BOOKINGS = 'TRY_FETCH_BOOKINGS';
export const FETCH_BOOKINGS_SUCCESS = 'FETCH_BOOKINGS_SUCCESS';
export const TRY_FETCH_BOOKINGS_CSV = 'TRY_FETCH_BOOKINGS_CSV';
export const FETCH_BOOKINGS_CSV_SUCCESS = 'FETCH_BOOKINGS_CSV_SUCCESS';
export const TRY_FETCH_BOOKING = 'TRY_FETCH_BOOKING';
export const FETCH_BOOKING_SUCCESS = 'FETCH_BOOKING_SUCCESS';
export const TRY_FETCH_FEEDBACKS = 'TRY_FETCH_FEEDBACKS';
export const FETCH_FEEDBACKS_SUCCESS = 'FETCH_FEEDBACKS_SUCCESS';
export const FETCH_FEEDBACKS_FAILED = 'FETCH_FEEDBACKS_FAILED';
export const PREFILL_THIS_WEEK_OPENINGS = 'PREFILL_THIS_WEEK_OPENINGS';
export const TRY_FETCH_HOTEL_MARKS = 'TRY_FETCH_HOTEL_MARKS';
export const FETCH_HOTEL_MARKS_SUCCESS = 'FETCH_HOTEL_MARKS_SUCCESS';
export const TRY_FETCH_HOTELS = 'TRY_FETCH_HOTELS';
export const FETCH_HOTELS_SUCCESS = 'FETCH_HOTELS_SUCCESS';
export const HOTEL_ADMIN_BILLS_START_LOADING =
  'HOTEL_ADMIN_BILLS_START_LOADING';
export const HOTEL_ADMIN_BILLS_STOP_LOADING = 'HOTEL_ADMIN_BILLS_STOP_LOADING';

export const HOTEL_ADMIN_BILLS_TRY_FETCH_BILLS =
  'HOTEL_ADMIN_BILLS_TRY_FETCH_BILLS';
export const HOTEL_ADMIN_BILLS_FETCH_BILLS_SUCCESS =
  'HOTEL_ADMIN_BILLS_FETCH_BILLS_SUCCESS';
export const HOTEL_ADMIN_BILLS_FETCH_BILLS_FAILURE =
  'HOTEL_ADMIN_BILLS_FETCH_BILLS_FAILURE';

export const HOTEL_ADMIN_BILLS_DOWNLOAD_BILL =
  'HOTEL_ADMIN_BILLS_DOWNLOAD_BILL';
export const HOTEL_ADMIN_BILLS_DOWNLOAD_BILL_SUCCESS =
  'HOTEL_ADMIN_BILLS_DOWNLOAD_BILL_SUCCESS';
export const HOTEL_ADMIN_BILLS_DOWNLOAD_BILL_FAILURE =
  'HOTEL_ADMIN_BILLS_DOWNLOAD_BILL_FAILURE';
export const HOTEL_ADMIN_BILLS_RESET_BILL_OBJECT_PDF =
  'HOTEL_ADMIN_BILLS_RESET_BILL_OBJECT_PDF';
export const HOTEL_ADMIN_FETCH_CONTACTS = 'HOTEL_ADMIN_FETCH_CONTACTS';
export const HOTEL_ADMIN_FETCH_CONTACTS_SUCCESS =
  'HOTEL_ADMIN_FETCH_CONTACTS_SUCCESS';
export const HOTEL_ADMIN_FETCH_CONTACTS_FAILED =
  'HOTEL_ADMIN_FETCH_CONTACTS_FAILED';
export const HOTEL_ADMIN_SAVE_CONTACTS = 'HOTEL_ADMIN_SAVE_CONTACTS';
export const HOTEL_ADMIN_SAVE_CONTACTS_SUCCESS =
  'HOTEL_ADMIN_SAVE_CONTACTS_SUCCESS';
export const HOTEL_ADMIN_SAVE_CONTACTS_FAILED =
  'HOTEL_ADMIN_SAVECONTACTS_FAILED';

export type HotelAdminTryFetchBillsPayload = {
  sortingColumn?: string;
  sortingOrder?: string;
  offset?: number;
  limit?: number;
  hotelId: number;
};

type HotelAdminFetchBillsSuccessPayload = {
  bills: Bill[];
};

export type HotelAdminDownloadBillPayload = {
  billId: number;
  hotelId: number;
};

type HotelAdminDownloadBillSuccessPayload = {
  pdf: Blob;
  filename: string;
};

type HotelAdminTryFetchInventoryPayload = {
  hotelId: number;
  start: any;
};

type HotelAdminTrySaveOpeningsPayload = {
  hotelId: number;
  openings: any;
  start: any;
  inventory?: Inventory;
};

type HotelAdminTryValidateOpeningsPayload = {
  hotelId: number;
  openings: any;
  start: any;
  inventory?: Inventory;
  mode: string;
};

type HotelAdminFetchInventorySuccessPayload = {
  result: any;
};

type HotelAdminUpdateHotelIdPayload = {
  hotelId: number;
};

type HotelAdminUpdateDirtyOpeningPayload = {
  opening: Opening;
  isForced: boolean;
};

type HotelAdminToggleIsEditingPayload = {
  value: boolean;
};

type HotelAdminSetDirtyOpeningPayload = {
  openings: any;
};

type HotelAdminToggleValidationFailedPayload = {
  value: any;
};

type HotelAdminToggleViewValidationFailedPayload = {
  value: any;
};

export type HotelAdminTryFetchBookingsPayload = {
  hotelId: number;
  offset: number;
  search?: string;
  sorting?: string[];
  beginning?: string;
  ending?: string;
  status?: string[];
};

type HotelAdminFetchBookingsSuccessPayload = {
  result: ExtranetBooking[];
};

type HotelAdminTryFetchBookingsCSVPayload = {
  hotelId: number;
  search?: string;
  sorting?: string[];
  beginning?: string;
  ending?: string;
  status?: string[];
};

type HotelAdminFetchBookingsCSVSuccessPayload = {
  result: any;
};

type HotelAdminTryFetchBookingPayload = {
  bookingCode: string;
};

type HotelAdminFetchBookingSuccessPayload = {
  result: any;
};

type HotelAdminTryFetchFeedbacksPayload = {
  hotelId: number;
  offset: number;
  search?: string;
  sorting?: string;
  beginning?: string;
  ending?: string;
};

type HotelAdminFetchFeedbacksSuccessPayload = {
  result: any;
};

type HotelAdminPrefillThisWeekOpeningsPayload = {
  currentRange: DateRange;
};

type HotelAdminTryFetchMarksPayload = {
  hotelId: number;
};

type HotelAdminFetchMarksSuccessPayload = {
  result: any;
};

type HotelAdminFetchHotelsSuccessPayload = {
  result: any;
};

type HotelAdminFetchContactPayload = {
  hotelId: number;
};
type HotelAdminFetchContactSuccessPayload = {
  res: HotelAdminContact;
};
type HotelAdminSaveContactPayload = {
  hotelId: number;
  form: HotelAdminContact;
};
type HotelAdminSaveContactSuccessPayload = {
  res: HotelAdminContact;
};

export const tryFetchInventory =
  createAction<HotelAdminTryFetchInventoryPayload>(TRY_FETCH_SALE_DATE);

export const trySaveOpenings =
  createAction<HotelAdminTrySaveOpeningsPayload>(TRY_SAVE_OPENINGS);

export const tryValidateOpenings =
  createAction<HotelAdminTryValidateOpeningsPayload>(TRY_VALIDATE_OPENINGS);

export const fetchInventorySuccess =
  createAction<HotelAdminFetchInventorySuccessPayload>(FETCH_SALE_DATE_SUCCESS);

export const fetchInventoryError = createAction(FETCH_SALE_DATE_FAILED);
export const startLoading = createAction(START_LOADING);
export const stopLoading = createAction(STOP_LOADING);

export const updateHotelId =
  createAction<HotelAdminUpdateHotelIdPayload>(UPDATE_HOTEL_ID);

export const updateDirtyOpening =
  createAction<HotelAdminUpdateDirtyOpeningPayload>(UPDATE_DIRTY_OPENING);

export const toggleIsEditing =
  createAction<HotelAdminToggleIsEditingPayload>(TOGGLE_IS_EDITING);

export const resetDirtyOpenings = createAction(RESET_DIRTY_OPENINGS);

export const setDirtyOpenings =
  createAction<HotelAdminSetDirtyOpeningPayload>(SET_DIRTY_OPENINGS);

export const toggleValidationFailed =
  createAction<HotelAdminToggleValidationFailedPayload>(
    TOGGLE_VALIDATION_FAILED
  );

export const toggleViewValidationFailed =
  createAction<HotelAdminToggleViewValidationFailedPayload>(
    TOGGLE_VIEW_VALIDATION_FAILED
  );

export const tryFetchBookings =
  createAction<HotelAdminTryFetchBookingsPayload>(TRY_FETCH_BOOKINGS);

export const fetchBookingsSuccess =
  createAction<HotelAdminFetchBookingsSuccessPayload>(FETCH_BOOKINGS_SUCCESS);

export const tryFetchBookingsCSV =
  createAction<HotelAdminTryFetchBookingsCSVPayload>(TRY_FETCH_BOOKINGS_CSV);

export const fetchBookingsCSVSuccess =
  createAction<HotelAdminFetchBookingsCSVSuccessPayload>(
    FETCH_BOOKINGS_CSV_SUCCESS
  );

export const tryFetchBooking =
  createAction<HotelAdminTryFetchBookingPayload>(TRY_FETCH_BOOKING);

export const fetchBookingSuccess =
  createAction<HotelAdminFetchBookingSuccessPayload>(FETCH_BOOKING_SUCCESS);

export const tryFetchFeedbacks =
  createAction<HotelAdminTryFetchFeedbacksPayload>(TRY_FETCH_FEEDBACKS);

export const fetchFeedbacksSuccess =
  createAction<HotelAdminFetchFeedbacksSuccessPayload>(FETCH_FEEDBACKS_SUCCESS);
export const fetchFeedbacksFailed = createAction(FETCH_FEEDBACKS_FAILED);

export const prefillThisWeekOpenings =
  createAction<HotelAdminPrefillThisWeekOpeningsPayload>(
    PREFILL_THIS_WEEK_OPENINGS
  );

export const tryFetchMarks = createAction<HotelAdminTryFetchMarksPayload>(
  TRY_FETCH_HOTEL_MARKS
);

export const fetchMarksSuccess =
  createAction<HotelAdminFetchMarksSuccessPayload>(FETCH_HOTEL_MARKS_SUCCESS);

export const tryFetchHotels = createAction(TRY_FETCH_HOTELS);

export const fetchHotelsSuccess =
  createAction<HotelAdminFetchHotelsSuccessPayload>(FETCH_HOTELS_SUCCESS);

export const startLoadingBills = createAction(HOTEL_ADMIN_BILLS_START_LOADING);
export const stopLoadingBills = createAction(HOTEL_ADMIN_BILLS_STOP_LOADING);

export const tryFetchBills = createAction<HotelAdminTryFetchBillsPayload>(
  HOTEL_ADMIN_BILLS_TRY_FETCH_BILLS
);
export const fetchBillsSuccess =
  createAction<HotelAdminFetchBillsSuccessPayload>(
    HOTEL_ADMIN_BILLS_FETCH_BILLS_SUCCESS
  );
export const fetchBillsFailure = createAction(
  HOTEL_ADMIN_BILLS_FETCH_BILLS_FAILURE
);

export const downloadBill = createAction<HotelAdminDownloadBillPayload>(
  HOTEL_ADMIN_BILLS_DOWNLOAD_BILL
);
export const downloadBillSuccess =
  createAction<HotelAdminDownloadBillSuccessPayload>(
    HOTEL_ADMIN_BILLS_DOWNLOAD_BILL_SUCCESS
  );
export const downloadBillFailure = createAction(
  HOTEL_ADMIN_BILLS_DOWNLOAD_BILL_FAILURE
);

export const resetBillObjectPdf = createAction(
  HOTEL_ADMIN_BILLS_RESET_BILL_OBJECT_PDF
);

export const fetchContacts = createAction<HotelAdminFetchContactPayload>(
  HOTEL_ADMIN_FETCH_CONTACTS
);

export const fetchContactsSuccess =
  createAction<HotelAdminFetchContactSuccessPayload>(
    HOTEL_ADMIN_FETCH_CONTACTS_SUCCESS
  );

export const fetchContactsFailed = createAction(
  HOTEL_ADMIN_FETCH_CONTACTS_FAILED
);

export const saveContacts = createAction<HotelAdminSaveContactPayload>(
  HOTEL_ADMIN_SAVE_CONTACTS
);

export const saveContactsSuccess =
  createAction<HotelAdminSaveContactSuccessPayload>(
    HOTEL_ADMIN_SAVE_CONTACTS_SUCCESS
  );

export const saveContactsFailed = createAction(
  HOTEL_ADMIN_SAVE_CONTACTS_FAILED
);
