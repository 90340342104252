import { format } from 'date-fns';
import moment from 'moment';

import { OpeningHours } from 'app/components/commons/Hotel/Types';
import { DateRange } from 'app/redux/models/Experience/Experience';

export const DAYS = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

export const originalToFormSlot = (slot: any) => {
  const start = moment.utc(slot.start, ['HH:mm:ss', 'YYYY-MM-DDTHH:mm:ss']);
  const end = moment.utc(slot.end, ['HH:mm:ss', 'YYYY-MM-DDTHH:mm:ss']);

  return [start, end];
};

export const parseOpeningHoursFromAPI = (
  openingHours: any,
  nonApplicableOption = false
) => {
  const defaultSlot = [{ start: '09:00:00', end: '18:00:00' }];

  if (!openingHours) {
    return {
      type: nonApplicableOption ? 'na' : 'weekly',
      isAllDay: false,
      slots: defaultSlot.map(originalToFormSlot),
      days: DAYS.reduce((acc: any, key) => {
        acc[key] = {
          enabled: false,
          isAllDay: false,
          slots: defaultSlot.map(originalToFormSlot),
        };

        return acc;
      }, {}),
    };
  }

  const slots = openingHours.slots || defaultSlot;

  return {
    ...openingHours,
    type: openingHours.type || 'weekly',
    isAllDay: !!openingHours.isAllDay,
    slots: slots.map(originalToFormSlot),
    days: DAYS.reduce((acc: any, key) => {
      const dailySlots = openingHours.days?.[key]?.slots || defaultSlot;

      acc[key] = {
        ...openingHours.days?.[key],
        enabled: !!openingHours.days?.[key],
        isAllDay: !!openingHours.days?.[key]?.isAllDay,
        slots: dailySlots.map(originalToFormSlot),
      };

      return acc;
    }, {}),
  };
};

export const formatOpeningHoursForAPI = (
  openingHours: any
): OpeningHours | null => {
  if (openingHours.type === 'na') {
    return null;
  }

  if (openingHours.type === 'weekly') {
    if (openingHours.isAllDay) {
      return {
        type: 'weekly',
        isAllDay: true,
      };
    }

    return {
      type: 'weekly',
      slots: openingHours.slots.map((formattedSlot: any) => ({
        start:
          formattedSlot?.[0] && moment(formattedSlot[0]).format('HH:mm:ss'),
        end: formattedSlot?.[1] && moment(formattedSlot[1]).format('HH:mm:ss'),
      })),
    };
  }

  return {
    type: 'daily',
    days: DAYS.reduce((acc: any, key) => {
      const dayHours = openingHours.days[key];

      if (!dayHours.enabled) {
        return acc;
      }

      if (dayHours.isAllDay) {
        acc[key] = {
          isAllDay: true,
        };
      } else {
        acc[key] = {
          slots: dayHours.slots.map((formattedSlot: any) => ({
            start:
              formattedSlot?.[0] && moment(formattedSlot[0]).format('HH:mm:ss'),
            end:
              formattedSlot?.[1] && moment(formattedSlot[1]).format('HH:mm:ss'),
          })),
        };
      }

      return acc;
    }, {}),
  };
};

export const parseDateRangeFromAPI = (
  dateRanges: Array<DateRange>
): Array<[Date, Date]> => {
  return dateRanges.map((d) => [d.start, d.end]);
};

export const formatDateRangeForAPI = (
  dateRanges?: Array<[Date, Date]>
): Array<{ start: string; end: string }> => {
  if (!dateRanges) {
    return [];
  }

  return dateRanges.map((arr) => {
    const [start, end] = arr;

    return {
      start: format(start, 'yyyy-MM-dd'),
      end: format(end, 'yyyy-MM-dd'),
    };
  });
};
