import { IPictureForm } from 'app/typings/misc';

export interface ISlidePackage {
  id: number;
  name: string;
  hotelName: string;
  cover: IPictureForm;
  pictures: IPictureForm[];
}

export interface ISlideCollection {
  id: number;
  name: string;
  cover: IPictureForm;
}

export enum SlideType {
  PACKAGE = 'PACKAGE',
  COLLECTION = 'COLLECTION',
  EDITO = 'EDITO',
}

type SlideData = {
  id: number;
  clubId: number;
  picture: IPictureForm;
  title: string;
  priority: number;
};

export type ISlide = (
  | {
      packageId: number;
      slideType: SlideType.PACKAGE;
      pkg: ISlidePackage;
    }
  | {
      collectionId: number;
      slideType: SlideType.COLLECTION;
      collection: ISlideCollection;
    }
  | {
      slideType: SlideType.EDITO;
      category: string;
      webLink: string;
      appLink: string;
    }
) &
  SlideData;

export type ISlideSavePayload = {
  packageId?: number;
  collectionId?: number;
  picture: IPictureForm;
  title: string;
  priority: number;
  id?: number;
};
