import { get, keyBy } from 'lodash';

import { actions } from 'app/redux/actions/entities';

function entities(state = { edit: {} }, action) {
  switch (action.type) {
    case actions.FETCH_ENTITY_SUCCESS_WITH_PARAMS:
      return {
        ...state,
        [action.entity]: action.result,
      };
    case actions.FETCH_ENTITY_SUCCESS:
      return {
        ...state,
        [action.entity]: keyBy(action.result, 'id'),
      };
    case actions.PUBLISH_ENTITY_SUCCESS:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          [action.entityId]: {
            ...get(state, `${action.entity}.${action.entityId}`, {}),
            published: action.published,
          },
        },
      };
    case actions.GET_ENTITY_SUCCESS:
      return {
        ...state,
        [action.entity]: action.result,
      };
    default:
      return state;
  }
}

export default entities;
