import { get, pick } from 'lodash';

import { User } from 'app/redux/models/User/User';

declare global {
  interface Window {
    amplitude?: any;
  }
}

const amplitudeIdentify = (user: User) => {
  if (!window.amplitude) {
    return;
  }

  const userFields = [
    'id',
    'gender',
    'firstName',
    'clubId',
    'location.locality',
    'locale',
    'hasFacebook',
    'optinNewsletter',
    'createdAt',
    'hotelIds',
    'hotelRole',
  ];

  if (user.role && (user.role === 'superadmin' || user.role === 'admin')) {
    window.amplitude.getInstance().setOptOut(true);
  } else {
    window.amplitude.getInstance().setOptOut(false);
  }

  return window.amplitude
    .getInstance()
    .setUserProperties(pick(user, userFields));
};

export const amplitudeInit = (user: User, amplitudeKey: string) => {
  if (!window.amplitude) {
    return;
  }

  return window.amplitude.getInstance().init(
    amplitudeKey,
    user ? user.id : null,
    {
      includeUtm: true,
      includeReferrer: true,
    },
    () => amplitudeIdentify(user)
  );
};

export const amplitudeTrack = (
  event: string,
  properties: { [key: string]: string }
) => {
  if (!window.amplitude) {
    console.log('Amplitude event:', event, properties);

    return;
  }

  const referrer = document.referrer === '' ? 'direct' : document.referrer;

  return window.amplitude.getInstance().logEvent(event, {
    ...properties,
    previousPage: get(window.history, 'state.previous', referrer),
    sourceApp: 'web',
  });
};
