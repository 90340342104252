import { message } from 'antd';
import queryString from 'query-string';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import * as entitiesActions from 'app/redux/actions/entities';
import * as giftCardActions from 'app/redux/actions/giftCards';
import { get, post, requestGenerator, put as rput } from 'app/redux/requests';

const URL = 'admin/gift-cards';

export function* tryFetchGiftCards({
  payload,
}: ReturnType<typeof giftCardActions.tryFetchGiftCards>): any {
  const { offset, search, sorting, status, mytype } = payload;
  const stringifiedParams = queryString.stringify({
    offset,
    search,
    sorting,
    status,
    mytype,
  });

  return yield requestGenerator(
    () => {},
    () => get(`${URL}?${stringifiedParams}`),
    (result: any) =>
      put(giftCardActions.fetchGiftCardsSuccess({ giftCards: result })),
    (error: any) => put(giftCardActions.fetchGiftCardsFailure({ error }))
  );
}

export function* tryResendConfirmation({
  payload,
}: ReturnType<typeof giftCardActions.tryResendConfirmation>) {
  yield requestGenerator(
    () => put(giftCardActions.startResendConfirmation({})),
    () => post(`admin/gift-cards/resendConfMail/${payload.code}`),
    (id: any) =>
      all([
        put(giftCardActions.resendConfirmationSuccess(id)),
        message.success('Confirmation resent'),
      ]),
    (error: any) =>
      all([
        put(giftCardActions.resendConfirmationFailure(error)),
        call(message.error, 'Failed to resend confirmation', 10),
      ]),
    { expectJson: false }
  );
}

export function* tryResendECard({
  payload,
}: ReturnType<typeof giftCardActions.tryResendECard>) {
  yield requestGenerator(
    () => put(giftCardActions.startResendECard({})),
    () => post(`admin/gift-cards/resendGiftCard/${payload.code}`),
    (id: any) =>
      all([
        put(giftCardActions.resendECardSuccess(id)),
        message.success('E-card resent'),
      ]),
    (error: any) =>
      all([
        put(giftCardActions.resendECardFailure(error)),
        call(message.error, 'Failed to resend e-card', 10),
      ]),
    { expectJson: false }
  );
}

export function* tryEditGiftCard({
  payload,
}: ReturnType<typeof giftCardActions.tryEditGiftCard>) {
  yield requestGenerator(
    () => Promise.resolve(),
    () => rput(`admin/gift-cards/${payload.code}`, payload.body),
    (result: any) =>
      all([
        put(
          entitiesActions.getEntitySuccess(
            'giftCardDetails',
            payload.code,
            result
          )
        ),
        payload.refetch(),
        message.success('Gift card edited'),
      ]),
    () => call(message.error, 'Failed to edit gift card', 10)
  );
}

export function* tryDownloadPdf({
  payload,
}: ReturnType<typeof giftCardActions.tryDownloadPdf>): any {
  const appUrl = yield select((state) => state.navigation.appUrl);

  window.location.replace(`${appUrl}/fr/gift-card/pdf/${payload.code}`);
}

export function* refundGiftCard({
  payload,
}: ReturnType<typeof giftCardActions.refundGiftCard>) {
  yield requestGenerator(
    () => Promise.resolve(),
    () => post(`admin/gift-cards/${payload.code}/refund`, payload.body),
    () =>
      all([
        put(entitiesActions.tryGetEntity('giftCardDetails', payload.code)),
        message.success('Successfully refund gift card'),
      ]),
    () => call(message.error, 'Failed to refund gift card', 10)
  );
}

export default function* giftCardSaga() {
  yield all([
    takeEvery(giftCardActions.TRY_FETCH_GIFT_CARDS, tryFetchGiftCards),
    takeEvery(giftCardActions.TRY_RESEND_CONFIRMATION, tryResendConfirmation),
    takeEvery(giftCardActions.TRY_RESEND_E_CARD, tryResendECard),
    takeEvery(giftCardActions.TRY_EDIT_GIFT_CARD, tryEditGiftCard),
    takeEvery(giftCardActions.TRY_DOWNLOAD_PDF, tryDownloadPdf),
    takeEvery(giftCardActions.REFUND_GIFT_CARD, refundGiftCard),
  ]);
}
