import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { QuickFilterListItem } from 'app/components/pages/QuickFilters/types';
import { apiRequest } from 'app/utils/request/api';

const path = '/admin/quick-filters' as const;

type Props = {
  dayPackage: boolean;
};

export const sortQuickFilters = (quickFilters: QuickFilterListItem[]) => {
  const publishedQuickFilters =
    quickFilters
      ?.filter((quickFilter) => quickFilter.published)
      .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0)) ?? [];
  const unPublishedQuickFilters =
    quickFilters
      ?.filter((quickFilter) => !quickFilter.published)
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  return [...publishedQuickFilters, ...unPublishedQuickFilters];
};

export const useGetQuickFilters = ({ dayPackage }: Props) => {
  const query = useQuery<QuickFilterListItem[]>({
    queryKey: [path, { dayPackage }],
    queryFn: () =>
      apiRequest({
        path: path,
        queryParams: { dayPackage },
      }),
  });

  const quickFilters = useMemo(
    () => sortQuickFilters(query.data ?? []),
    [query.data]
  );

  return {
    loading: query.isLoading,
    quickFilters,
  };
};
