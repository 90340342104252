import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Layout, PageHeader, Table } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { tryFetchHotelsGalleries } from 'app/redux/actions/pictures';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { useQueryParamHistory } from 'app/utils/queryParamHistory';
import {
  SortOrder,
  convertFromAntdSortOrder,
  convertToAntdSortOrder,
  formatSortingForAPI,
  getSortOrder,
} from 'app/utils/sort';

import 'styles/layout/page-list.scss';

const { Content } = Layout;
const { Column } = Table;

const LIMIT = 50;

type GalleriesListQueryParam = {
  search: string;
  pageNumber: number;
  nameSorting: SortOrder;
  numberSorting: SortOrder;
  dateSorting: SortOrder;
};

type PayloadParam = {
  search: string | undefined;
  sorting: string | undefined;
  offset: number;
  limit: number;
};

const makePayload = (
  queryParam: Partial<GalleriesListQueryParam>
): PayloadParam => {
  const formattedNameSorting = formatSortingForAPI(queryParam.nameSorting);
  const formattedNumberSorting = formatSortingForAPI(queryParam.numberSorting);
  const formattedDateSorting = formatSortingForAPI(queryParam.dateSorting);

  const sorting = formattedNameSorting
    ? `name-${formattedNameSorting}`
    : formattedNumberSorting
    ? `number-${formattedNumberSorting}`
    : formattedDateSorting
    ? `date-${formattedDateSorting}`
    : undefined;

  return {
    search: queryParam.search || undefined,
    sorting: sorting,
    offset: ((queryParam.pageNumber || 1) - 1) * LIMIT,
    limit: LIMIT,
  };
};

const queryParamInit = {
  pageNumber: 1,
  nameSorting: 'none',
  numberSorting: 'none',
  dateSorting: 'descend',
} as GalleriesListQueryParam;
const queryParamKeys = {
  pageNumber: 'p',
  nameSorting: 'ns',
  numberSorting: 'nus',
  dateSorting: 'ds',
  search: 's',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  nameSorting: getSortOrder,
  numberSorting: getSortOrder,
  dateSorting: getSortOrder,
};

export const Galleries = () => {
  const { hotelsGalleries, isLoading } = useAppSelector((s) => s.pictures);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<GalleriesListQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const debounceFetch = useMemo(
    () =>
      debounce(
        (params: PayloadParam) => dispatch(tryFetchHotelsGalleries(params)),
        500
      ),
    [dispatch]
  );

  useEffect(() => {
    debounceFetch(makePayload(queryParam));
  }, [queryParam, debounceFetch]);

  const nameSortingHandler = (sort: SortOrder) => {
    updateQueryParam({
      nameSorting: sort,
      numberSorting: 'none',
      dateSorting: 'none',
      pageNumber: 1,
    });
  };

  const sizeSortingHandler = (sort: SortOrder) => {
    updateQueryParam({
      nameSorting: 'none',
      numberSorting: sort,
      dateSorting: 'none',
      pageNumber: 1,
    });
  };

  const dateSortingHandler = (sort: SortOrder) => {
    updateQueryParam({
      nameSorting: 'none',
      numberSorting: 'none',
      dateSorting: sort,
      pageNumber: 1,
    });
  };

  const handleOffset = (x: any) => {
    updateQueryParam({
      pageNumber: (queryParam.pageNumber || 1) + x,
    });
  };

  const handleSearch = (search: any) => {
    updateQueryParam({
      search,
      pageNumber: 1,
    });
  };

  const formatDate = (date: any) =>
    date && moment.utc(date).local().format('DD/MM/YYYY à HH:mm');

  const pagination = (className: any) => (
    <div className={className}>
      <Button
        disabled={queryParam.pageNumber === 1}
        onClick={() => handleOffset(-1)}
      >
        <LeftOutlined />
      </Button>
      <Button>{queryParam.pageNumber}</Button>
      <Button
        disabled={hotelsGalleries.length < 50}
        onClick={() => handleOffset(1)}
      >
        <RightOutlined />
      </Button>
    </div>
  );

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={queryParam.search}
          onChange={(event) => handleSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
      </div>
      {pagination('button-wrapper')}
    </div>
  );

  const handleRowActions = ({ id }: any) => ({
    onClick: () => history.push(`/hotels/${id}/gallery`),
  });

  return (
    <Layout className="page-list">
      <PageHeader className="header" title="Gallery" />
      <Content className="body">
        <Table
          dataSource={hotelsGalleries}
          pagination={false}
          title={header}
          loading={isLoading}
          rowKey="id"
          onRow={handleRowActions}
          onChange={(__, ___, sorter) => {
            // @ts-ignore
            const { field, order } = sorter;

            const adaptedOrder = convertFromAntdSortOrder(order);

            if (field === 'name') {
              nameSortingHandler(adaptedOrder);
            }

            if (field === 'number') {
              sizeSortingHandler(adaptedOrder);
            }

            if (field === 'date') {
              dateSortingHandler(adaptedOrder);
            }
          }}
        >
          <Column
            title="Hotel name"
            dataIndex="name"
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.nameSorting)}
          />
          <Column
            title="Size"
            dataIndex="number"
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.numberSorting)}
          />
          <Column
            title="Last modified"
            dataIndex="date"
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.dateSorting)}
            render={formatDate}
          />
        </Table>
        {pagination('footer')}
      </Content>
    </Layout>
  );
};

export default Galleries;
