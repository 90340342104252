import { AnyAction } from '@reduxjs/toolkit';

import * as billsActions from 'app/redux/actions/bills';
import { createBill } from 'app/redux/models/Bill/Bill';
import type { Bill } from 'app/redux/models/Bill/Bill';

export type Bills = {
  isLoading: boolean;
  bills: Bill[];
  billPdfObject: {
    pdf: Blob;
    filename: string;
  } | null;
  isDownloading: boolean;
};

export const initialState: Bills = {
  isLoading: false,
  bills: [],
  billPdfObject: null,
  isDownloading: false,
};

function bills(state = initialState, action: AnyAction): Bills {
  if (billsActions.tryFetchBills.match(action)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (billsActions.fetchBillsSuccess.match(action)) {
    const newBills = action.payload.bills.map(createBill);

    return {
      ...state,
      isLoading: false,
      bills: newBills,
    };
  }

  if (billsActions.fetchBillsFailure.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (billsActions.downloadBill.match(action)) {
    return {
      ...state,
      isDownloading: true,
    };
  }

  if (billsActions.downloadBillSuccess.match(action)) {
    const { pdf, filename } = action.payload;

    return {
      ...state,
      billPdfObject: {
        pdf,
        filename,
      },
    };
  }

  if (billsActions.downloadBillFailure.match(action)) {
    return {
      ...state,
      isDownloading: false,
    };
  }

  if (billsActions.resetBillObjectPdf.match(action)) {
    return {
      ...state,
      billPdfObject: initialState.billPdfObject,
      isDownloading: false,
    };
  }

  return state;
}

export default bills;
