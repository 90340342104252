import { AnyAction } from 'redux';

import * as actions from 'app/redux/actions/users';
import { UserDetail, UserList } from 'app/redux/models/Users/UserDetail';

export type Users = {
  readonly loading: boolean;
  readonly detail: UserDetail | null;
  readonly list: UserList[];
  readonly listLoading: boolean;
};

export const initialState: Users = {
  loading: false,
  detail: null,
  list: [],
  listLoading: false,
};

function users(state = initialState, action: AnyAction): Users {
  if (actions.startLoading.match(action)) {
    return {
      ...state,
      loading: true,
    };
  }

  if (actions.stopLoading.match(action)) {
    return {
      ...state,
      loading: false,
    };
  }

  if (actions.fetchDetailSuccess.match(action)) {
    return {
      ...state,
      detail: action.payload.detail,
    };
  }

  if (actions.saveUserDetailSuccess.match(action)) {
    return {
      ...state,
      detail: action.payload.detail,
    };
  }

  if (actions.deleteUserSuccess.match(action)) {
    return {
      ...state,
      detail: action.payload.detail,
    };
  }

  if (actions.tryFetchUsers.match(action)) {
    return {
      ...state,
      listLoading: true,
    };
  }

  if (actions.fetchUsersSuccess.match(action)) {
    return {
      ...state,
      list: action.payload.users,
      listLoading: false,
    };
  }

  if (actions.fetchUsersError.match(action)) {
    return {
      ...state,
      listLoading: false,
    };
  }

  return state;
}

export default users;
