import { useQuery } from '@tanstack/react-query';

import { Club } from 'app/redux/models/Club/Club';
import { apiRequest } from 'app/utils/request/api';

const path = '/clubs' as const;

export function useClubs() {
  return useQuery({
    queryKey: [path],
    queryFn: () => apiRequest({ path }) as Promise<Club[]>,
    staleTime: Infinity,
  });
}
