import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  CollapsePanelProps,
  Form,
  Input,
  Row,
} from 'antd';
import React from 'react';

import PanelHeader from 'app/components/layout/PanelHeader/PanelHeader';
import type { Hotel } from 'app/redux/models/Hotel/Hotel';

const { Panel } = Collapse;

type Props = Partial<CollapsePanelProps> & {
  mode: 'view' | 'edit';
  originalBillingEmails: string[] | undefined;
  notifEmails: Hotel['notifEmails'];
  trackAdd?: () => void;
  trackDelete?: () => void;
};

export const BillingContact = ({
  mode,
  originalBillingEmails,
  notifEmails,
  trackAdd,
  trackDelete,
  ...panelProps
}: Props) => {
  const hasNoContact =
    originalBillingEmails && originalBillingEmails.length === 0;
  const hasAtLeastOneContactToNotify = notifEmails.some(
    ({ billing }) => billing
  );

  return (
    <Panel
      key="billing-contact"
      className="panel"
      header={
        <PanelHeader
          name="Contact for billing"
          subTitle="Person(s) who will receive the invoices (1 person or more)"
        />
      }
      {...panelProps}
    >
      <Form.List name="billingEmails">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.length === 0 && mode === 'view' && <i>No contacts yet</i>}
              {fields.map((field) => (
                <Row style={{ alignItems: 'baseline' }} key={field.key}>
                  <Col span={20}>
                    <Form.Item
                      {...field}
                      label="Email"
                      rules={[
                        { required: true, message: 'Required' },
                        {
                          type: 'email',
                          message: 'Please enter a valid email',
                        },
                      ]}
                    >
                      <Input disabled={mode === 'view'} />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    {mode !== 'view' &&
                      ((hasNoContact && hasAtLeastOneContactToNotify) ||
                        fields.length > 1) && (
                        <DeleteOutlined
                          style={{ marginLeft: 12 }}
                          onClick={() => {
                            remove(field.name);

                            if (trackDelete) {
                              trackDelete();
                            }
                          }}
                        />
                      )}
                  </Col>
                </Row>
              ))}
              {mode !== 'view' && (
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => {
                    if (trackAdd) {
                      trackAdd();
                    }

                    add();
                  }}
                >
                  Add email
                </Button>
              )}
            </>
          );
        }}
      </Form.List>
    </Panel>
  );
};
