import { Button, Form, FormInstance, Popover } from 'antd';
import React, { useState } from 'react';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import { pluralize } from 'app/utils/strings';

import { FieldActiveRules } from '../../PricingRule';
import { PricingRulePopin } from '../PricingRulePopin';

import './PricingRuleModalFooter.scss';

type PricingRuleModalFooterProps = {
  handleCancelModal: () => void;
  handleDeactivationAutomation: () => void;
  isFormInvalid: any;
  form: FormInstance;
  autoDiscountRate?: number;
  lastMinuteAutoDiscountRate?: number;
  lastMinuteAutoDiscountDays?: number;
  openPricingRule: boolean;
  openLastMinutePricingRule: boolean;
  isSingleRate: boolean;
  activeRules: FieldActiveRules;
};

export const PricingRuleModalFooter = ({
  handleCancelModal,
  handleDeactivationAutomation,
  isFormInvalid,
  form,
  autoDiscountRate,
  lastMinuteAutoDiscountRate,
  lastMinuteAutoDiscountDays,
  openPricingRule,
  openLastMinutePricingRule,
  isSingleRate,
  activeRules,
}: PricingRuleModalFooterProps) => {
  const { track } = useAmplitude();
  const formValues = Form.useWatch([], form);

  const [isConfirmationPopinVisible, setIsConfirmationPopinVisible] =
    useState(false);
  const [isUpdatePopinVisible, setIsUpdatePopinVisible] = useState(false);

  const showDeactivatePopover =
    (!!autoDiscountRate ||
      !!lastMinuteAutoDiscountRate ||
      !!lastMinuteAutoDiscountDays) &&
    !openPricingRule &&
    !openLastMinutePricingRule;

  const showMultiRateLMRule =
    !isSingleRate &&
    activeRules.lastMinuteDiscount &&
    !activeRules.standardDiscount &&
    !!formValues?.lastMinuteDiscountValue &&
    !!formValues?.lastMinuteDays;

  const showMultiRateGlobalRule =
    !isSingleRate && activeRules.standardDiscount && !!formValues?.discount;

  const canOpenDeactivatePopOver =
    !!autoDiscountRate ||
    !!lastMinuteAutoDiscountRate ||
    !!lastMinuteAutoDiscountDays;

  const ClickNoDeactivationPopin = () => {
    track('Click Cancel popin');
    setIsConfirmationPopinVisible(false);
  };

  const ClickYesDeactivationPopin = () => {
    track('Deactivate rule');
    setIsConfirmationPopinVisible(false);
  };

  const ActiveUpdateButton = ({ onClick }: { onClick?: () => void }) => {
    return (
      <Button
        id="saveAutomatedDiscountRule"
        key="save"
        size="large"
        type="primary"
        htmlType="submit"
        onClick={onClick}
        disabled={isFormInvalid}
      >
        {autoDiscountRate ||
        lastMinuteAutoDiscountRate ||
        lastMinuteAutoDiscountDays
          ? 'Update'
          : 'Activate'}
      </Button>
    );
  };

  const getPopinContent = () => {
    if (showMultiRateGlobalRule) {
      return {
        description:
          'The Staycation rate on your Channel Manager will not be taken into consideration anymore.',
        onClickNo: () => {
          setIsUpdatePopinVisible(false);
        },
        onClickYes: () => {
          form.submit();
        },
      };
    }

    if (showMultiRateLMRule) {
      return {
        description: `From ${pluralize(
          formValues?.lastMinuteDays,
          'day',
          'days'
        )} before arrival, the last minute pricing rule will override your connected rates.`,
        onClickNo: () => {
          setIsUpdatePopinVisible(false);
        },
        onClickYes: () => form.submit(),
      };
    }

    if (showDeactivatePopover) {
      return {
        title: 'Do you really want to deactivate?',
        description:
          'Your Staycation rate and discount will return to the amount they had before pricing rule.',
        onClickNo: () => {
          track('Click Cancel popin');
          setIsUpdatePopinVisible(false);
        },
        onClickYes: () => {
          handleDeactivationAutomation();
          track('Deactivate rule');
          setIsUpdatePopinVisible(false);
        },
      };
    }

    return {
      title: '',
      description: '',
      onClickNo: () => {},
      onClickYes: () => {},
    };
  };

  const { title, description, onClickYes, onClickNo } = getPopinContent();

  return (
    <div className="automatedDiscountModalFooter">
      <div className="automatedDiscountModalFooter__left">
        <Popover
          content={
            <PricingRulePopin
              handleDeactivationAutomation={handleDeactivationAutomation}
              setIsConfirmationPopinVisible={setIsConfirmationPopinVisible}
              title="Do you really want to deactivate?"
              description="Your Staycation rate and discount will return to the amount they had
          before pricing rule."
              onClickNo={ClickNoDeactivationPopin}
              onClickYes={() => {
                handleDeactivationAutomation();
                ClickYesDeactivationPopin();
              }}
            />
          }
          placement="top"
          trigger={!!canOpenDeactivatePopOver ? 'click' : []}
          open={canOpenDeactivatePopOver && isConfirmationPopinVisible}
          onOpenChange={(visible) => {
            setIsConfirmationPopinVisible(visible);
          }}
        >
          <Button
            type="link"
            className="automatedDiscountModalFooter__left__remove_rule"
            disabled={!canOpenDeactivatePopOver}
          >
            Deactivate
          </Button>
        </Popover>
      </div>
      <div className="automatedDiscountModalFooter__right">
        <Form.Item shouldUpdate>
          {() => (
            <>
              <Button
                id="cancelAutomatedDiscountRule"
                size="large"
                type="ghost"
                onClick={handleCancelModal}
              >
                Cancel
              </Button>
              {showDeactivatePopover ||
              showMultiRateLMRule ||
              showMultiRateGlobalRule ? (
                <Popover
                  content={
                    <PricingRulePopin
                      handleDeactivationAutomation={() => form.submit()}
                      setIsConfirmationPopinVisible={setIsUpdatePopinVisible}
                      title={title}
                      description={description}
                      onClickNo={onClickNo}
                      onClickYes={onClickYes}
                    />
                  }
                  placement="top"
                  trigger={'click'}
                  open={isUpdatePopinVisible}
                  onOpenChange={(visible) => {
                    setIsUpdatePopinVisible(visible);
                  }}
                >
                  <ActiveUpdateButton />
                </Popover>
              ) : (
                <ActiveUpdateButton onClick={() => form.submit()} />
              )}
            </>
          )}
        </Form.Item>
      </div>
    </div>
  );
};
