import * as Sentry from '@sentry/browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { message } from 'antd';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from 'app/components/App';
import store, { env } from 'app/redux/store';
import history from 'app/utils/history';

message.config({
  duration: 5,
});

// Prevent file drag & drop
window.addEventListener('dragover', (e) => e.preventDefault(), false);
window.addEventListener('drop', (e) => e.preventDefault(), false);

// Sentry
Sentry.init({
  environment: env.REACT_APP_ENV,
  dsn: env.REACT_APP_SENTRY_DSN,
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    {/*
      // @ts-ignore- library typing error */}
    <ConnectedRouter history={history}>
      <QueryClientProvider client={queryClient}>
        <App />

        {/* Uncomment the following line to enable React Query Devtools */}
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </ConnectedRouter>
  </Provider>
);
