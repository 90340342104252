import { PlusOutlined } from '@ant-design/icons/lib';
import { Button, Form, FormListFieldData, Input, Radio, Tooltip } from 'antd';
import React, { useState } from 'react';

import CoverPicture from 'app/components/commons/CoverPicture/CoverPicture';
import Pic from 'app/components/commons/Pic';
import {
  SortableCollapse,
  SortablePanel,
} from 'app/components/layout/Sortable/Collapse';
import {
  SlideEntityModal,
  SlideEntityModalButton,
  SlideEntityModalProvider,
} from 'app/components/pages/Slider/SlideEntityModal';
import { useAppSelector } from 'app/redux/hooks';
import { getUser } from 'app/redux/selectors/auth';
import { ISlide, ISlidePackage, SlideType } from 'app/typings/slides';
import { formatPackageName } from 'app/utils/packages';
import { isUserSuperAdmin } from 'app/utils/user/user';

import styles from './SlidesList.module.scss';
import { SliderFormInitialValues } from './types';

const REQUIRED_FIELD_PROPS = {
  required: true,
  rules: [{ required: true, message: 'Required' }],
};

type SlidesListProps = {
  clubId: number;
  values: SliderFormInitialValues['slides'];
  fields: FormListFieldData[];
  add: (defaultValue: Partial<ISlide>, insertIndex?: number) => void;
  remove: (index: number) => void;
  move: (from: number, to: number) => void;
  update: (slideData: Partial<ISlide>, index: number) => void;
};

export const SlidesList = ({
  clubId,
  values,
  fields,
  add,
  remove,
  move,
  update,
}: SlidesListProps) => {
  const user = useAppSelector(getUser);
  const [activeKeys, setActiveKeys] = useState(
    fields.length > 0 ? [fields[0].key.toString()] : []
  );

  const handleAdd = () => {
    setActiveKeys((prevActiveKeys) => [
      ...prevActiveKeys,
      values.length.toString(),
    ]);
    add({});
  };

  const isSuperAdmin = isUserSuperAdmin(user);

  return (
    <div>
      <SortableCollapse
        activeKey={activeKeys}
        onToggle={setActiveKeys}
        onSortEnd={({ oldIndex, newIndex }) => move(oldIndex, newIndex)}
        useDragHandle
      >
        {fields.map((field, index) => {
          const slide = values[index];
          const panelTitle = slide.title || `Slide ${index + 1}`;
          const isSlideEdito = slide.slideType === SlideType.EDITO;

          return (
            <SortablePanel
              key={field.key}
              index={index}
              title={panelTitle}
              onRemove={() => remove(index)}
              /** Non super admin users can not deleted a slider edito */
              menuDisplayed={!isSlideEdito || isSuperAdmin}
              card={false}
            >
              <SlideFields
                {...field}
                slide={values[index]}
                clubId={clubId}
                onChange={(value) => update(value, index)}
                isSuperAdmin={isSuperAdmin}
              />
            </SortablePanel>
          );
        })}
      </SortableCollapse>

      <div className={styles.addButtonContainer}>
        <Button icon={<PlusOutlined />} onClick={handleAdd}>
          Add a Slide
        </Button>
      </div>
    </div>
  );
};

export default SlidesList;

type SlideFieldsProps = {
  clubId: number;
  slide: Partial<ISlide>;
  onChange: (slideData: Partial<ISlide>) => void;
  isSuperAdmin: boolean;
} & FormListFieldData;

const SlideFields = ({
  slide,
  clubId,
  name,
  onChange,
  isSuperAdmin,
}: SlideFieldsProps) => {
  const isSlideEdito = slide.slideType === SlideType.EDITO;

  return (
    <>
      <Tooltip
        title="Superadmin only"
        trigger={isSlideEdito && !isSuperAdmin ? 'hover' : []}
      >
        <Form.Item
          label="Title"
          name={[name, 'title']}
          {...REQUIRED_FIELD_PROPS}
        >
          <Input
            placeholder="Slide title"
            disabled={isSlideEdito && !isSuperAdmin}
          />
        </Form.Item>

        <Form.Item
          label="Type"
          name={[name, 'slideType']}
          {...REQUIRED_FIELD_PROPS}
        >
          <Radio.Group>
            <Radio
              value={SlideType.PACKAGE}
              onChange={() => {
                onChange({ collection: undefined, picture: undefined });
              }}
              disabled={isSlideEdito && !isSuperAdmin}
            >
              Package
            </Radio>
            <Radio
              value={SlideType.COLLECTION}
              onChange={() => {
                onChange({ pkg: undefined, picture: undefined });
              }}
              disabled={isSlideEdito && !isSuperAdmin}
            >
              Collection
            </Radio>

            <Tooltip
              title="Superadmin only"
              trigger={!isSlideEdito && !isSuperAdmin ? 'hover' : []}
            >
              <Radio
                value={SlideType.EDITO}
                disabled={!isSuperAdmin}
                onChange={() => {
                  onChange({
                    pkg: undefined,
                    collection: undefined,
                    picture: undefined,
                  });
                }}
              >
                Edito
              </Radio>
            </Tooltip>
          </Radio.Group>
        </Form.Item>

        {slide.slideType === SlideType.PACKAGE && (
          <>
            <Form.Item label="Package">
              <SlideEntityModalProvider>
                {slide.pkg ? (
                  <div className={styles.entity}>
                    <div className={styles.entityThumbnail}>
                      <Pic {...slide.pkg.cover} />
                    </div>
                    <div className={styles.entityName}>
                      {formatPackageName(slide.pkg)}
                    </div>

                    <SlideEntityModalButton>
                      Change Package
                    </SlideEntityModalButton>
                  </div>
                ) : (
                  <SlideEntityModalButton icon={<PlusOutlined />}>
                    Choose Package
                  </SlideEntityModalButton>
                )}

                <Form.Item
                  noStyle
                  name={[name, 'pkg']}
                  {...REQUIRED_FIELD_PROPS}
                >
                  <SlideEntityModal
                    title="Select a Package"
                    slideType={slide.slideType}
                    clubId={clubId}
                    onRowClick={(pkg) => {
                      onChange({
                        pkg: pkg as ISlidePackage,
                        picture: undefined,
                      });
                    }}
                  />
                </Form.Item>
              </SlideEntityModalProvider>
            </Form.Item>

            {slide.pkg && (
              <Form.Item
                label="Cover Picture"
                name={[name, 'picture']}
                {...REQUIRED_FIELD_PROPS}
              >
                <CoverPicture
                  pictures={slide.pkg.pictures.map((p) => ({
                    ...p,
                    selected: p.pictureId === slide.picture?.pictureId,
                  }))}
                />
              </Form.Item>
            )}
          </>
        )}

        {slide.slideType === SlideType.COLLECTION && (
          <>
            <Form.Item label="Collection">
              <SlideEntityModalProvider>
                {slide.collection ? (
                  <div className={styles.entity}>
                    <div className={styles.entityThumbnail}>
                      <Pic {...slide.collection.cover} />
                    </div>

                    <div className={styles.entityName}>
                      {slide.collection.name}
                    </div>

                    <SlideEntityModalButton>
                      Change Collection
                    </SlideEntityModalButton>
                  </div>
                ) : (
                  <SlideEntityModalButton icon={<PlusOutlined />}>
                    Choose Collection
                  </SlideEntityModalButton>
                )}

                <Form.Item
                  name={[name, 'collection']}
                  noStyle
                  {...REQUIRED_FIELD_PROPS}
                >
                  <SlideEntityModal
                    title="Select a Collection"
                    slideType={slide.slideType}
                    clubId={clubId}
                    onRowClick={(collection) => {
                      onChange({ collection, picture: undefined });
                    }}
                  />
                </Form.Item>
              </SlideEntityModalProvider>
            </Form.Item>

            {slide.collection && (
              <Form.Item
                label="Cover Picture"
                name={[name, 'picture']}
                {...REQUIRED_FIELD_PROPS}
              >
                <CoverPicture
                  pictures={[
                    {
                      ...slide.collection.cover,
                      selected:
                        slide.collection.cover.pictureId ===
                        slide.picture?.pictureId,
                    },
                  ]}
                />
              </Form.Item>
            )}
          </>
        )}

        {slide.slideType === SlideType.EDITO && (
          <>
            <Form.Item
              label="Category"
              name={[name, 'category']}
              {...REQUIRED_FIELD_PROPS}
            >
              <Input
                disabled={!isSuperAdmin}
                onInput={(e) =>
                  ((e.target as HTMLInputElement).value = (
                    e.target as HTMLInputElement
                  ).value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Web link"
              name={[name, 'webLink']}
              {...REQUIRED_FIELD_PROPS}
            >
              <Input.TextArea disabled={!isSuperAdmin} />
            </Form.Item>
            <Form.Item
              label="App link"
              name={[name, 'appLink']}
              {...REQUIRED_FIELD_PROPS}
            >
              <Input.TextArea disabled={!isSuperAdmin} />
            </Form.Item>
            <Form.Item
              label="Cover Picture"
              name={[name, 'picture']}
              {...REQUIRED_FIELD_PROPS}
            >
              <CoverPicture disabled={!isSuperAdmin} />
            </Form.Item>
          </>
        )}
      </Tooltip>
    </>
  );
};
