const size = {
  INPUT_SIZE: {
    LARGE: '440px',
    MEDIUM: '320px',
    SMALL: '200px',
    TINY: '140px',
  },
};

export default size;
