import { DatePicker, Form, Input } from 'antd';
import moment from 'moment';
import React from 'react';

import ColorFormItem from '../ColorFormItem/ColorFormItem';
import MessageFormItem from '../MessageFormItem/MessageFormItem';

const RULES = [{ required: true, message: 'Required' }];

const EmailEditForm = () => (
  <>
    <ColorFormItem />
    <MessageFormItem />
    <div className="input-row">
      <Form.Item
        name="beneficiaryEmail"
        label="Beneficiary email"
        rules={[
          ...RULES,
          { type: 'email', message: 'Please enter a valid email' },
        ]}
      >
        <Input />
      </Form.Item>
    </div>
    <div className="input-row">
      <Form.Item
        name="billingEmail"
        label="Sender email"
        rules={[
          ...RULES,
          { type: 'email', message: 'Please enter a valid email' },
        ]}
      >
        <Input />
      </Form.Item>
    </div>
    <div className="input-row">
      <Form.Item name="emailSendDate" label="Email Send Date" rules={RULES}>
        <DatePicker
          disabledDate={(current) => current && current < moment().endOf('day')}
          format="YYYY-MM-DD"
        />
      </Form.Item>
    </div>
  </>
);

export default EmailEditForm;
