import { Radio } from 'antd';
import React from 'react';

const CustomPrice = ({ value, onChange, mode }: Props) => (
  <>
    <Radio.Group
      value={value === 0}
      onChange={(event) => {
        if (onChange) {
          onChange(event.target.value ? 0 : undefined);
        }
      }}
      disabled={mode === 'view'}
    >
      <Radio value>Free of charge</Radio>
      <Radio value={false}>Applicable fees</Radio>
    </Radio.Group>
  </>
);

type Props = {
  value?: number;
  onChange?: (newPrice?: number) => void;
  mode?: string;
};

export default CustomPrice;
