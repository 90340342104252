import { slugify } from 'app/utils/strings';

export const getFileSizeInBytes = (size) => {
  const kB = size / 1024;
  const MB = kB / 1024;
  const GB = MB / 1024;

  if (kB < 1) {
    return `${size} B`;
  }

  if (MB < 1) {
    return `${Math.round(kB)} kB`;
  }

  if (GB < 1) {
    return `${Math.round(MB)} MB`;
  }

  return `${Math.round(GB)} GB`;
};

export const getFilename = (filename) => slugify(filename.split('.')[0]);
