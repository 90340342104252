import { PropsWithChildren } from 'react';

import './FormSection.scss';

interface Props {
  title: string;
  subTitle?: string;
}

export const FormSection = ({
  title,
  children,
  subTitle,
}: PropsWithChildren<Props>) => {
  return (
    <div className="formSectionContainer">
      <div>
        <div className="title"> {title}</div>
        {subTitle && <div className="sub-title">{subTitle}</div>}
      </div>
      {children}
    </div>
  );
};
