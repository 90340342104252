// Prevent : TypeError: Do not know how to serialize a BigInt
//=> https://github.com/GoogleChromeLabs/jsbi/issues/30#issuecomment-1721402063
Object.defineProperty(BigInt.prototype, 'toJSON', {
  get() {
    return () => String(this);
  },
});

//https://fr.wikipedia.org/wiki/International_Bank_Account_Number
export const validateIban = (iban: string) => {
  // Vérification de la longueur de la chaine (entre 14 et 34 caractères)
  if (iban.length < 14 || iban.length > 34) {
    return false;
  }

  //Les deux premiers caractères doivent être des lettres
  if (!/^[A-Z]{2}/.test(iban)) {
    return false;
  }

  //Déplacer les 4 premiers caractères à la fin du compte
  const reorderedIban = iban.substring(4) + iban.substring(0, 4);

  //Convertir les lettres en chiffres
  //Remplacer les lettres par des chiffres au moyen d'une table de conversion (A=10, B=11, C=12 etc.),
  const digitsConvertedIban = convertLettersToDigits(reorderedIban);

  //Diviser le nombre ainsi obtenu par 97,
  //Si le reste n'est pas égal à 1 l'IBAN est incorrect : Modulo de 97 égal à 1.
  if (mod97(digitsConvertedIban) === 1) {
    return true;
  }

  return false;
};

const mod97 = (value: string) => {
  let remainder = '';

  for (const char of value) {
    const block = remainder + char;

    remainder = (parseInt(block) % 97).toString();
  }

  return parseInt(remainder);
};

const convertLettersToDigits = (iban: string) => {
  return iban
    .split('')
    .map((char) => {
      const charCode = char.charCodeAt(0);

      return charCode >= 65 && charCode <= 90
        ? (charCode - 55).toString()
        : char;
    })
    .join('');
};
