import { Button, Layout, Table } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';

import ClubListPage from 'app/components/lists/ClubListPage';
import List from 'app/components/lists/List';
import { SwitchCell, entityCell } from 'app/components/lists/cells';
import {
  duplicateGuide,
  tryFeatureGuide,
  tryHybridGuide,
  trySortGuides,
} from 'app/redux/actions/guides';
import { entityConnect } from 'app/redux/entityConnect';
import { useAppDispatch } from 'app/redux/hooks';
import { GuideCategory, ListGuide } from 'app/redux/models/Guides/Guides';
import { move } from 'app/utils/lists';

type Props = {
  guides: Array<ListGuide>;
  guideCategories: Array<GuideCategory>;
  loading: boolean;
  fetchEntities: () => void;
};

const { Content } = Layout;
const { Column } = Table;

const GuidesList = ({
  guides,
  guideCategories,
  loading,
  fetchEntities,
}: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const handleSort = ({ oldIndex, newIndex }: any) => {
    const sortedGuides = move(guides, oldIndex - 1, newIndex - 1);

    dispatch(trySortGuides({ guideIds: sortedGuides.map((g: any) => g.id) }));
  };

  const renderActions = () => [
    <Column
      key="featured"
      title="Featured"
      dataIndex="featured"
      render={SwitchCell((guideId: any) =>
        dispatch(tryFeatureGuide({ guideId }))
      )}
      align="center"
      onCell={() => ({ onClick: (e) => e.stopPropagation() })}
    />,
    <Column
      key="isHybrid"
      title="Hybrid"
      dataIndex="isHybrid"
      render={SwitchCell((guideId: any, isHybrid: any) =>
        dispatch(tryHybridGuide({ guideId, isHybrid }))
      )}
      align="center"
      onCell={() => ({ onClick: (e) => e.stopPropagation() })}
    />,
  ];

  const handleDuplicate = (entity: any) => () => {
    dispatch(
      duplicateGuide({
        guideId: entity.id,
        featured: entity.featured,
        isHybrid: entity.isHybrid,
      })
    );
  };

  return (
    <ClubListPage title="Guides">
      {({ clubId }) => (
        <Layout>
          <Content className="page__content">
            <List
              sortable
              name="guides"
              loading={loading}
              entities={_.sortBy(_.filter(guides, { clubId }), 'priority')}
              clubId={clubId}
              actionColumns={renderActions()}
              actionMenus={(entity: any) => [
                <Button onClick={handleDuplicate(entity)}>Duplicate</Button>,
              ]}
              onSort={handleSort}
            >
              <Column
                key="category"
                title="Category"
                dataIndex="categoryId"
                render={entityCell(guideCategories)}
              />
            </List>
          </Content>
        </Layout>
      )}
    </ClubListPage>
  );
};

export default entityConnect(['guides', 'guideCategories'])(GuidesList);
