import { Button, Form, Modal, Popover } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { BABY_COT_BED_CATEGORY_ID } from 'app/components/commons/Room/Types';
import type { Room as RoomType } from 'app/components/commons/Room/Types';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import {
  useAdditionalPax,
  useAdditionalPaxExperience,
  useSaveAdditionalPax,
} from 'app/hooks/data/useAdditionalPax';
import { useExperiencesCategories } from 'app/hooks/data/useExperience';
import { useAppSelector } from 'app/redux/hooks';
import { IHotel } from 'app/typings';

import { Configuration } from './Components/Configuration';
import { Overview, PER_PERSON } from './Components/Overview';
import './ExtraGuest.scss';
import { getExtraGuestDetail, getSumOfPerPersonExperience } from './Utils';

type ExtraGuestType = {
  hotel: IHotel;
  taskName: 'Attract extra guests' | 'Attract single guests';
  isOpen?: boolean;
  onCompleteTask?: () => void;
  rooms: RoomType[];
};

export const ExtraGuest = ({
  hotel,
  taskName,
  isOpen,
  onCompleteTask,
  rooms,
}: ExtraGuestType) => {
  const [form] = Form.useForm();
  const { clearOpenTask } = useContext(TaskCenterContext);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const { data: pkg } = useAdditionalPax(hotel.id);
  const { data: experienceCategories } = useExperiencesCategories();
  const { data: experiences } = useAdditionalPaxExperience(
    hotel.id,
    pkg?.packageId
  );
  const { mutate: saveAdditionalPax } = useSaveAdditionalPax(
    hotel.id,
    pkg?.packageId,
    taskName
  );

  const taskInformation = getExtraGuestDetail(taskName);

  const initialSwitchOn = useMemo(
    () => pkg?.[taskInformation.valueName] !== undefined,
    [pkg, taskInformation]
  );
  const [isSwitchOn, setIsSwitchOn] = useState(initialSwitchOn);

  const bedCategories = useAppSelector((state) => state.conf.bedCategories);

  const includedExperiences = experiences?.filter(
    (exp) => exp.included && exp.published
  );

  const sumPrice = getSumOfPerPersonExperience(PER_PERSON, includedExperiences);

  const getBedCapacityById = (bedId: number) => {
    const bed = bedCategories.find((b) => b.id === bedId);

    return bed ? bed.capacity : 0;
  };

  const roomsWithCapacityGreaterThan2Adults = rooms.filter((room) => {
    const totalCapacity = room.beds.reduce((sum, bed) => {
      const bedType =
        bedCategories.find((b) => b.id === bed.categoryId)?.type || '';

      if (
        bed.categoryId &&
        bed.categoryId !== BABY_COT_BED_CATEGORY_ID &&
        !bedType.includes('kid')
      ) {
        return sum + getBedCapacityById(bed.categoryId);
      }

      return sum;
    }, 0);

    return totalCapacity > 2;
  });

  const onFinish = async (
    values:
      | { additionalAdultPrice?: number }
      | { singleCustomerDiscount?: number }
  ) => {
    onCompleteTask?.();

    const mergeValues = { ...pkg, ...values };

    const newValues = !isSwitchOn
      ? { ...mergeValues, [taskInformation.valueName]: undefined }
      : mergeValues;

    if (pkg) {
      saveAdditionalPax({
        packageId: pkg.packageId,
        ...newValues,
      });
    }

    clearOpenTask();
    setIsSwitchOn(initialSwitchOn);
  };

  const handleClose = () => {
    clearOpenTask();
    setIsSwitchOn(initialSwitchOn);
  };

  const initialValues = useMemo(
    () => ({
      additionalAdultPrice: (pkg && pkg.additionalAdultPrice) ?? sumPrice,
      singleCustomerDiscount: (pkg && pkg.singleCustomerDiscount) ?? sumPrice,
    }),
    [pkg, sumPrice]
  );

  useEffect(() => {
    if (pkg) {
      form.setFieldsValue({
        additionalAdultPrice: pkg.additionalAdultPrice ?? sumPrice,
        singleCustomerDiscount: pkg.singleCustomerDiscount ?? sumPrice,
      });
    }
  }, [pkg, form, sumPrice]);

  useEffect(() => {
    const inputValue = form.getFieldValue(taskInformation.valueName);
    const initialValue = initialValues[taskInformation.valueName];

    if (
      initialSwitchOn !== isSwitchOn ||
      (inputValue !== initialValue && initialSwitchOn)
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [isSwitchOn, form, taskInformation, initialSwitchOn, initialValues]);

  const renderSaveButton = () => {
    if (
      taskInformation.hasRoom &&
      roomsWithCapacityGreaterThan2Adults.length === 0 &&
      !isSubmitDisabled &&
      isSwitchOn
    ) {
      return (
        <Popover
          content={
            <div className="extra-guest-confirmation-popin">
              <div className="popin-text">
                <p>
                  Please also contact your Account Manager to define which rooms
                  are adapted for more than 2 adults.
                </p>
              </div>
              <div className="popin-cta">
                <Button
                  id="validateDesactivationDiscountRule"
                  className="popin-yes"
                  type="primary"
                  onClick={form.submit}
                >
                  OK
                </Button>
              </div>
            </div>
          }
          placement="top"
          trigger={'click'}
        >
          <Button
            key="save"
            size="large"
            type="primary"
            htmlType="submit"
            className="saveBtn"
            disabled={isSubmitDisabled}
          >
            Save
          </Button>
        </Popover>
      );
    }

    return (
      <Button
        key="save"
        size="large"
        type="primary"
        htmlType="submit"
        onClick={form.submit}
        className="saveBtn"
        disabled={isSubmitDisabled}
      >
        Save
      </Button>
    );
  };

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      preserve={false}
    >
      {(values) => (
        <Modal
          width={920}
          className="extraGuestModal"
          bodyStyle={{ overflowY: 'auto' }}
          open={isOpen}
          title={
            <div className="extraGuestModal__header">
              <h1>{taskInformation.title}</h1>
              <p> {taskInformation.description} </p>
            </div>
          }
          onCancel={handleClose}
          wrapClassName="extraGuestModalContainer"
          centered
          footer={[
            <Button size="large" type="ghost" onClick={handleClose}>
              Close
            </Button>,
            renderSaveButton(),
          ]}
          destroyOnClose
        >
          <div className="extraGuestModal__body">
            <div className="bodySection">
              <h1>Overview</h1>
              <div className="overview">
                {includedExperiences && experienceCategories && (
                  <Overview
                    includedExperiences={includedExperiences}
                    roomsList={roomsWithCapacityGreaterThan2Adults}
                    hasRoom={taskInformation.hasRoom}
                    bedCategories={bedCategories}
                    experienceCategories={experienceCategories}
                    sumPrice={sumPrice}
                  />
                )}
              </div>
            </div>
            <div className="bodySection">
              <h1>{taskInformation.priceConfiguration.title}</h1>
              <Configuration
                taskInformation={taskInformation}
                setIsSwitchOn={setIsSwitchOn}
                isSwitchOn={isSwitchOn}
              />
            </div>
          </div>
        </Modal>
      )}
    </FormLegacyRenderProp>
  );
};
