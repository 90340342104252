import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { IAddOnPayload } from 'app/typings/addOn';
import { apiMutation } from 'app/utils/request/api';

const path = '/hotel-admin/:hotelId/experiences' as const;

export function useAddOn(hotelId: number) {
  return useMutation({
    mutationFn: async (payload: IAddOnPayload) =>
      apiMutation(
        'POST',
        {
          path,
          params: { hotelId: hotelId.toString() },
        },
        payload
      ) as Promise<[]>,
    onError: (err) => {
      message.error('Error saving add on');
    },
  });
}
