/* eslint-disable max-len */
import { createAction } from '@reduxjs/toolkit';

import type {
  Collection,
  CollectionForm,
} from 'app/redux/models/Collection/Collection';
import type { CollectionItem } from 'app/redux/models/CollectionItem/CollectionItem';
import type { PreviewResult } from 'app/redux/models/PreviewResult/PreviewResult';
import { IPictureForm } from 'app/typings';

export type Preview = {
  id: number;
  name: string;
  coverPicture: IPictureForm;
  tagIds: number[];
  popularity: number;
  clubId: number;
  clubName: string;
};

type CollectionsFetchCollectionsSuccessPayload = {
  result: CollectionItem[];
};

export type CollectionsTryFetchCollectionsPayload = {
  search?: string;
  published?: boolean;
  countryId?: number;
  clubId?: number;
  sorting?: any;
  offset?: number;
  limit?: number;
};

type CollectionsTryFetchCollectionPreviewPayload = {
  countryId: number;
  clubId?: number;
  mode: string;
  tagIds: number[];
  dayPackage: boolean;
  dates: string[];
  includedOnly: boolean;
};

type CollectionsFetchCollectionPreviewSuccessPayload = {
  result: PreviewResult[];
};

type CollectionsTryUpdateCollectionParamsPayload = {
  collectionId: number;
  trendDisplay: boolean;
  cardDisplay: boolean;
  sliceDisplay: boolean;
  searchDisplay: boolean;
  fetchParams: CollectionsTryFetchCollectionsPayload;
};

type CollectionsTryUpdateCollectionPublishedPayload = {
  collectionId: number;
  published: boolean;
  fetchParams: CollectionsTryFetchCollectionsPayload;
};

type CollectionsTryFetchCollectionPayload = {
  collectionId: number;
};

type CollectionsFetchCollectionSuccessPayload = {
  result: Collection;
};

type CollectionsTryCreateCollectionPayload = {
  collection: Collection;
};

type CollectionsTryUpdateCollectionPayload = {
  id: number;
  collection: CollectionForm;
};

export const COLLECTIONS_START_LOADING = 'COLLECTIONS_START_LOADING';
export const COLLECTIONS_STOP_LOADING = 'COLLECTIONS_STOP_LOADING';

export const COLLECTIONS_TRY_FETCH_COLLECTIONS =
  'COLLECTIONS_TRY_FETCH_COLLECTIONS';
export const COLLECTIONS_FETCH_COLLECTIONS_SUCCESS =
  'COLLECTIONS_FETCH_COLLECTIONS_SUCCESS';

export const COLLECTIONS_TRY_FETCH_COLLECTION_PREVIEW =
  'COLLECTIONS_TRY_FETCH_COLLECTION_PREVIEW';
export const COLLECTIONS_FETCH_COLLECTION_PREVIEW_SUCCESS =
  'COLLECTIONS_FETCH_COLLECTION_PREVIEW_SUCCESS';

export const COLLECTIONS_TRY_UPDATE_COLLECTION_PARAMS =
  'COLLECTIONS_TRY_UPDATE_COLLECTION_PARAMS';

export const COLLECTIONS_TRY_UPDATE_COLLECTION_PUBLISHED =
  'COLLECTIONS_TRY_UPDATE_COLLECTION_PUBLISHED';

export const COLLECTIONS_TRY_FETCH_COLLECTION =
  'COLLECTIONS_TRY_FETCH_COLLECTION';
export const COLLECTIONS_FETCH_COLLECTION_SUCCESS =
  'COLLECTIONS_FETCH_COLLECTION_SUCCESS';
export const COLLECTIONS_TRY_CREATE_COLLECTION =
  'COLLECTIONS_TRY_CREATE_COLLECTION';
export const COLLECTIONS_TRY_UPDATE_COLLECTION =
  'COLLECTIONS_TRY_UPDATE_COLLECTION';
export const COLLECTIONS_RESET_COLLECTION = 'COLLECTIONS_RESET_COLLECTION';

export const startLoading = createAction(COLLECTIONS_START_LOADING);
export const stopLoading = createAction(COLLECTIONS_STOP_LOADING);

export const tryFetchCollections =
  createAction<CollectionsTryFetchCollectionsPayload>(
    COLLECTIONS_TRY_FETCH_COLLECTIONS
  );
export const fetchCollectionsSuccess =
  createAction<CollectionsFetchCollectionsSuccessPayload>(
    COLLECTIONS_FETCH_COLLECTIONS_SUCCESS
  );

export const tryFetchCollectionPreview =
  createAction<CollectionsTryFetchCollectionPreviewPayload>(
    COLLECTIONS_TRY_FETCH_COLLECTION_PREVIEW
  );
export const fetchCollectionPreviewSuccess =
  createAction<CollectionsFetchCollectionPreviewSuccessPayload>(
    COLLECTIONS_FETCH_COLLECTION_PREVIEW_SUCCESS
  );

export const tryUpdateCollectionParams =
  createAction<CollectionsTryUpdateCollectionParamsPayload>(
    COLLECTIONS_TRY_UPDATE_COLLECTION_PARAMS
  );

export const tryUpdateCollectionPublished =
  createAction<CollectionsTryUpdateCollectionPublishedPayload>(
    COLLECTIONS_TRY_UPDATE_COLLECTION_PUBLISHED
  );

export const tryFetchCollection = createAction<
  CollectionsTryFetchCollectionPayload,
  typeof COLLECTIONS_TRY_FETCH_COLLECTION
>(COLLECTIONS_TRY_FETCH_COLLECTION);
export const fetchCollectionSuccess = createAction<
  CollectionsFetchCollectionSuccessPayload,
  typeof COLLECTIONS_FETCH_COLLECTION_SUCCESS
>(COLLECTIONS_FETCH_COLLECTION_SUCCESS);
export const tryCreateCollection = createAction<
  CollectionsTryCreateCollectionPayload,
  typeof COLLECTIONS_TRY_CREATE_COLLECTION
>(COLLECTIONS_TRY_CREATE_COLLECTION);
export const tryUpdateCollection = createAction<
  CollectionsTryUpdateCollectionPayload,
  typeof COLLECTIONS_TRY_UPDATE_COLLECTION
>(COLLECTIONS_TRY_UPDATE_COLLECTION);
export const resetCollection = createAction(COLLECTIONS_RESET_COLLECTION);
