import { EditOutlined } from '@ant-design/icons';
import { Badge, Button, Form, List, Modal } from 'antd';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';

import { SCard } from 'app/components/StaycationUI';
import CoolToKnow from 'app/components/commons/CoolToKnow/CoolToKnow';
import ExperienceForm from 'app/components/commons/Experience/ExperienceForm';
import { NOT_APPLICABLE_SPACE_ID } from 'app/components/commons/Experience/PracticalInfo/SpacesSelect/SpacesSelect';
import { computeExperienceName } from 'app/components/commons/Experience/utils';
import {
  formatDateRangeForAPI,
  formatOpeningHoursForAPI,
  parseDateRangeFromAPI,
  parseOpeningHoursFromAPI,
} from 'app/components/commons/TimeRangeSection/utils';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { useAppSelector } from 'app/redux/hooks';
import { Experience } from 'app/redux/models/Experience/Experience';
import { selectExperienceCategoriesByIds } from 'app/redux/selectors/conf';
import { getHotelExperiences } from 'app/redux/selectors/experiences';

import HeaderWrapper from '../HeaderWrapper/HeaderWrapper';

import './ExperiencesForm.scss';

type Props = {
  saveExperience: (experience: Experience) => void;
  handleNext: () => void;
  hotelId: number;
};

const ExperiencesForm = ({ saveExperience, handleNext }: Props) => {
  const [selectedExperience, setSelectedExperience] =
    useState<Experience | null>(null);

  const experiences = useAppSelector(getHotelExperiences);
  const experiencesCategoryIds = useMemo(
    () => experiences.map((experience) => experience.categoryId),
    [experiences]
  );
  const experienceCategories = useAppSelector(
    selectExperienceCategoriesByIds(experiencesCategoryIds)
  );

  const findCategoryById = useCallback(
    (experience: Experience) => {
      const maybeCategory = experienceCategories.find(
        (category) => category.id === experience.categoryId
      );

      return maybeCategory;
    },
    [experienceCategories]
  );

  const handleClose = (resetFields?: () => void) => () => {
    setSelectedExperience(null);

    if (resetFields) {
      resetFields();
    }
  };

  const handleSave = (values: Experience) => {
    if (!selectedExperience) {
      return;
    }

    const formatted: Experience = {
      ...selectedExperience,
      ...values,
      openingHours: values.openingHours
        ? formatOpeningHoursForAPI(values.openingHours)
        : null,
      time: values.time ? moment(values.time).format('HH:mm') : null,
      roomServiceAvailable: values.roomServiceAvailable || false,
      roomServiceIncluded: values.roomServiceIncluded || false,
      id: selectedExperience.id || 0,
      published:
        selectedExperience.published === undefined
          ? true
          : selectedExperience.published,
      spaceIds:
        values.spaceIds?.filter((s) => s !== NOT_APPLICABLE_SPACE_ID) || [],
      price: values.price || 0,
      coverPicture: values.pictures ? values.pictures[0] : undefined,
      // We take value from the API and not the form since it's not modified in the builder
      closingPeriods: formatDateRangeForAPI(
        parseDateRangeFromAPI(selectedExperience.closingPeriods)
      ),
    };

    saveExperience(formatted);

    setSelectedExperience(null);
  };

  const isExperienceFilled = (experience: Experience) =>
    !!experience.coverPicture;
  const areExperiencesFilled = experiences.every(isExperienceFilled);

  const renderExperienceActionButton = (experience: Experience) => {
    if (isExperienceFilled(experience)) {
      return <EditOutlined onClick={() => setSelectedExperience(experience)} />;
    }

    return (
      <Button
        onClick={() => setSelectedExperience(experience)}
        className="fill-experience-button"
      >
        Fill
      </Button>
    );
  };

  const renderExperience = (experience: Experience) => (
    <List.Item className="experiences-form-list-item">
      <div>
        <Badge color={isExperienceFilled(experience) ? 'green' : 'orange'} />
        {computeExperienceName({
          ...experience,
          category: findCategoryById(experience),
        })}
      </div>

      {renderExperienceActionButton(experience)}
    </List.Item>
  );

  const renderFooter = (isFieldsTouched: boolean, submit: () => void) => (
    <>
      <Button key="cancel" onClick={handleClose()}>
        Cancel
      </Button>
      <Button
        key="submit"
        disabled={!isFieldsTouched}
        onClick={() => submit()}
        type="primary"
      >
        Save
      </Button>
    </>
  );

  const formattedValues = (): Experience | null => {
    if (!selectedExperience) {
      return null;
    }

    return {
      ...selectedExperience,
      openingHours: parseOpeningHoursFromAPI(selectedExperience.openingHours),
      pictures: selectedExperience.pictures,
      closingPeriods: parseDateRangeFromAPI(selectedExperience.closingPeriods),
    };
  };

  const [form] = Form.useForm();

  return (
    <HeaderWrapper nextDisabled={!areExperiencesFilled} handleNext={handleNext}>
      <SCard
        title="Hotel experiences"
        subtitle="Fill all the experience information"
        isExpanded
        key="experiences"
        className="experiences-form-list"
      >
        <List
          size="large"
          dataSource={experiences}
          renderItem={renderExperience}
        />

        {!!selectedExperience && (
          <FormLegacyRenderProp
            onFinish={handleSave}
            initialValues={formattedValues() as Experience}
            form={form}
          >
            {(
              values,
              {
                resetFields,
                isFieldsTouched,
                submit,
                setFieldsValue,
                getFieldsError,
                validateFields,
              }
            ) => {
              const category = findCategoryById(selectedExperience);
              const experienceName = computeExperienceName({
                ...selectedExperience,
                category,
              });

              return (
                <Modal
                  title={experienceName}
                  width={800}
                  onCancel={handleClose(resetFields)}
                  footer={renderFooter(isFieldsTouched(), submit)}
                  visible
                  className="experiences-modal"
                  wrapClassName="hotel-builder-modal"
                >
                  {category && (
                    <ExperienceForm
                      isBuilder
                      title={experienceName}
                      values={values}
                      setFieldsValue={setFieldsValue}
                      resetFields={resetFields}
                      isFieldsTouched={isFieldsTouched}
                      submit={submit}
                      onCancel={handleClose}
                      mode="edit"
                      experienceId={selectedExperience.id}
                      isFormValid={getFieldsError().every(
                        (item) => item.errors.length > 0
                      )}
                      validateFields={validateFields}
                    />
                  )}

                  <CoolToKnow />
                </Modal>
              );
            }}
          </FormLegacyRenderProp>
        )}
      </SCard>
    </HeaderWrapper>
  );
};

export default ExperiencesForm;
