export type BedCategory = {
  readonly id: number;
  readonly name: string;
  readonly capacity: number;
  readonly priority: number;
  readonly type: string;
};

export function createBedCategory(values: any): BedCategory {
  const { id, name, capacity, priority, type } = values;

  return {
    id,
    name,
    capacity,
    priority,
    type,
  };
}
