import { Form } from 'antd';
import React, { FC, ReactNode } from 'react';

const FormGroup: FC<Props> = ({
  label,
  extra,
  touched,
  warning,
  error,
  children,
}) => (
  <Form.Item
    label={label}
    extra={extra}
    colon={false}
    validateStatus={touched && error ? 'error' : warning ? 'warning' : ''}
    help={(touched && error) || warning}
  >
    {children}
  </Form.Item>
);

type Props = {
  label?: string;
  extra?: string;
  warning?: string;
  error?: string;
  touched?: boolean;
  children?: ReactNode;
};

export default FormGroup;
