import { Form } from 'antd';
import React, { useEffect, useMemo } from 'react';

import Features from 'app/components/commons/Features/Features';
import { tryFetchTags } from 'app/redux/actions/tags';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { Tag } from 'app/redux/models/Tag/Tag';
import { selectTagList } from 'app/redux/selectors/tags';
import { upperCaseFirstLetter } from 'app/utils/strings';

import './HotelDescriptions.scss';

type CleanTag = Omit<Tag, 'tagType'> & { tagType: string };

type TagsByCategories = Record<string, CleanTag[]>;

type Props = {
  mode?: string;
  prefix?: string;
};

export const HotelDescriptions = ({ mode = 'edit', prefix = '' }: Props) => {
  const dispatch = useAppDispatch();
  const tags = useAppSelector(selectTagList);

  useEffect(() => {
    dispatch(tryFetchTags());
  }, [dispatch]);

  const cleanTags = useMemo(() => {
    return tags.filter(
      (tag) => tag.tagType && tag.tagType !== 'package_custom'
    );
  }, [tags]) as CleanTag[];

  const tagsByCategories = useMemo<TagsByCategories>(() => {
    if (!cleanTags?.length) {
      return {};
    }

    return cleanTags.reduce((acc: any, tag) => {
      const category = tag.tagType;
      const capitalizedCategory = upperCaseFirstLetter(category);

      if (!acc[capitalizedCategory]) {
        acc[capitalizedCategory] = [];
      }

      const tagName = tag.name;
      const capitalizedTagName = upperCaseFirstLetter(tagName);

      acc[capitalizedCategory].push({
        ...tag,
        name: capitalizedTagName,
      });

      return acc;
    }, {});
  }, [cleanTags]);

  return (
    <div className="hotel-descriptions">
      <div className="input-row">
        <Form.Item name={prefix ? [prefix, 'tagIds'] : 'tagIds'}>
          <Features featuresByCategory={tagsByCategories} mode={mode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default HotelDescriptions;
