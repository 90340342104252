import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import history from 'app/utils/history';

import auth from './auth';
import type { Auth } from './auth';
import bills from './bills';
import type { Bills } from './bills';
import booking from './booking';
import type { Booking } from './booking';
import bookings from './bookings';
import type { Bookings } from './bookings';
import collections from './collections';
import type { Collections } from './collections';
import gestures from './commercialGestures';
import type { CommercialGestures } from './commercialGestures';
import conf from './conf';
import type { Conf } from './conf';
import customTags from './customTags';
import type { CustomTags } from './customTags';
import edition from './edition';
import entities from './entities';
import experiences from './experiences';
import type { Experiences } from './experiences';
import giftCards, { GiftCards } from './giftCards';
import guides from './guides';
import type { Guides } from './guides';
import hotelAdmin from './hotelAdmin';
import type { HotelAdmin } from './hotelAdmin';
import hotels from './hotels';
import type { Hotels } from './hotels';
import navigation, { Navigation } from './navigation';
import openings from './openings';
import packages from './packages';
import type { Packages } from './packages';
import pictures from './pictures';
import type { Pictures } from './pictures';
import rooms from './rooms';
import type { Rooms } from './rooms';
import tags from './tags';
import type { Tags } from './tags';
import users from './users';
import type { Users } from './users';

export type ApplicationState = {
  auth: Auth;
  bills: Bills;
  bookings: Bookings;
  collections: Collections;
  customTags: CustomTags;
  form: any;
  edition: any;
  entities: any;
  navigation: Navigation;
  hotelAdmin: HotelAdmin;
  hotels: Hotels;
  giftCards: GiftCards;
  guides: Guides;
  booking: Booking;
  rooms: Rooms;
  pictures: Pictures;
  conf: Conf;
  packages: Packages;
  experiences: Experiences;
  openings: any;
  router: any;
  tags: Tags;
  users: Users;
  gestures: CommercialGestures;
};

export default combineReducers<ApplicationState>({
  auth,
  bills,
  bookings,
  collections,
  customTags,
  form,
  edition,
  entities,
  navigation,
  hotelAdmin,
  hotels,
  giftCards,
  guides,
  booking,
  rooms,
  pictures,
  gestures,
  conf,
  packages,
  experiences,
  openings,
  router: connectRouter(history),
  tags,
  users,
});
