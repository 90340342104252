import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import { filter, head, includes, keys } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { SortableContainer } from 'react-sortable-hoc';

import SortableRow from 'app/components/layout/Sortable/SortableRow';

import ActionsMenu from './ActionsMenu';
import { CoverCell, PublishedCell, dateTimeCell } from './cells';

const { Column } = Table;

class List extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    entities: PropTypes.arrayOf(PropTypes.shape()),
    loading: PropTypes.bool,
    children: PropTypes.node,
    actionColumns: PropTypes.node, // TODO: not the best idea actions+children?
    actionMenus: PropTypes.func,
    history: PropTypes.shape(),
    sortable: PropTypes.bool,
    onSort: PropTypes.func,
    isPackageList: PropTypes.bool,
  };

  static getDerivedStateFromProps(props, state) {
    const { entities, hotels } = props;
    const { searchText } = state;

    if (searchText) {
      const filteredEntities = filter(entities, (entity) => {
        if (!entity) {
          return false;
        }

        const matchesName = entity.name
          .toLowerCase()
          .includes(searchText.toLowerCase());
        const findHotel = hotels && hotels.find((h) => h.id === entity.hotelId);
        const matchesHotel = findHotel
          ? findHotel.name.toLowerCase().includes(searchText.toLowerCase())
          : false;

        // TODO: Must rethink the whole relationship system so we don't have to pass the full list of hotels...
        // TODO: Maybe just add it to the sql query?
        return matchesName || matchesHotel;
      });

      return {
        filteredEntities: props.isPackageList
          ? filteredEntities.map((entity) => ({
              ...entity,
              name: entity.name || `Package #${entity.id}`,
            }))
          : filteredEntities,
      };
    }

    return {
      filteredEntities: props.isPackageList
        ? entities.map((entity) => ({
            ...entity,
            name: entity.name || `Package #${entity.id}`,
          }))
        : entities,
    };
  }

  state = { searchText: null };

  hasKey = (key) => includes(keys(head(this.props.entities)), key);

  handleRowActions = ({ id }) => {
    const { name, history } = this.props;

    return {
      onClick: () => history.push(`${name}/${id}/edit`),
    };
  };

  handleSearch = (event) => this.setState({ searchText: event.target.value });

  render() {
    const {
      name,
      loading,
      sortable,
      onSort,
      children,
      actionColumns,
      actionMenus,
    } = this.props;
    const { filteredEntities } = this.state;

    const TableComponent = sortable ? SortableContainer(Table) : Table;

    // TODO: having a fixed header would be great but: https://github.com/ant-design/ant-design/issues/8078
    return (
      <div>
        <div className="page__actions">
          {!sortable && (
            <Input
              placeholder="Search here..."
              onChange={this.handleSearch}
              suffix={<SearchOutlined className="certain-category-icon" />}
            />
          )}
          <div className="page__actions-buttons">
            <Link to={`${name}/new`}>
              <Button icon={<PlusOutlined />} type="primary">
                New
              </Button>
            </Link>
          </div>
        </div>
        <TableComponent
          rowKey={sortable ? 'priority' : 'id'}
          loading={loading}
          onRow={this.handleRowActions}
          dataSource={filteredEntities}
          pagination={
            !sortable && {
              pageSize: 50,
              showSizeChanger: false,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} ${name}`,
            }
          }
          useDragHandle
          onSortEnd={onSort}
          components={sortable && { body: { row: SortableRow } }}
        >
          <Column
            key="name"
            title="Name"
            dataIndex="name"
            render={this.hasKey('coverPicture') && CoverCell}
            sorter={!sortable && ((a, b) => a.name.localeCompare(b.name))}
          />
          {children}
          <Column
            key="updatedAt"
            title="Last modified"
            dataIndex="updatedAt"
            render={dateTimeCell('yyyy-MM-dd HH:mm:ss')}
            sorter={
              !sortable &&
              ((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt))
            }
          />
          {this.hasKey('published') && this.hasKey('hidden') && (
            <Column
              title="Published"
              dataIndex="published"
              key="published"
              render={PublishedCell}
              filters={
                !sortable && [
                  { text: 'Hidden', value: 2 },
                  { text: 'Published', value: 1 },
                  { text: 'Unpublished', value: 0 },
                ]
              }
              onFilter={(value, record) =>
                value === 2
                  ? record.hidden === !!+value
                  : record.published === !!+value
              }
            />
          )}
          {actionColumns}
          <Column
            key="actions"
            render={(entity) => (
              <ActionsMenu
                name={name}
                entity={entity}
                sortable={sortable}
                customActions={actionMenus && actionMenus(entity)}
              />
            )}
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            align="center"
          />
        </TableComponent>
      </div>
    );
  }
}

export default withRouter(List);
