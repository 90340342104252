import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { SCard } from 'app/components/StaycationUI';
import Desk from 'app/components/commons/Hotel/Spaces/Desk/Desk';
import InformativePhoto from 'app/components/commons/Hotel/Spaces/InformativePhoto/InformativePhoto';
import Space from 'app/components/commons/Hotel/Spaces/Space/Space';
import SpaceModal from 'app/components/commons/Hotel/Spaces/SpaceModal/SpaceModal';
import {
  formatNewSpaces,
  formatSpacesFormFromAPI,
  reformatSpacesFormForAPI,
} from 'app/components/commons/Hotel/Spaces/utils';
import { SpaceSubmitType } from 'app/components/commons/Hotel/Types';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import HeaderWrapper from 'app/components/pages/Extranet/HotelBuilder/HeaderWrapper/HeaderWrapper';
import { trySaveSpaces } from 'app/redux/actions/hotels';
import type { Hotel } from 'app/redux/models/Hotel/Hotel';
import { SpaceType } from 'app/redux/models/SpaceType/SpaceType';

import './SpacesForm.scss';

export const SpacesForm: FC<Props> = ({
  submitSpaces,
  spaceTypes,
  hotel,
  onSubmit,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values: any) => {
      const reformatted = reformatSpacesFormForAPI(values, hotel, spaceTypes);

      submitSpaces(hotel.id, reformatted);
      onSubmit();
    },
    [spaceTypes, hotel, submitSpaces, onSubmit]
  );

  const formattedValues = useMemo(() => {
    if (!hotel) {
      return {};
    }

    return formatSpacesFormFromAPI(hotel, spaceTypes);
  }, [hotel, spaceTypes]);

  const onModalSave = (newSpaces: any) => {
    const values = form.getFieldsValue();
    const formatted = formatNewSpaces(newSpaces, values, spaceTypes, false);

    form.setFieldsValue(formatted);
  };

  return (
    <HeaderWrapper nextDisabled={false} handleNext={() => form.submit()}>
      <FormLegacyRenderProp
        form={form}
        onFinish={onFinish}
        initialValues={formattedValues}
        className="spaces-form"
      >
        {(values, { setFieldsValue, validateFields }) => (
          <div className="form-container">
            <SCard title="Informative Photo">
              <InformativePhoto
                values={values}
                hotelId={hotel.id}
                picturesCategories={[
                  { type: 'official', name: 'Media gallery' },
                ]}
                kind="official"
              />
            </SCard>
            <SCard title="Desk">
              <Desk values={values} />
            </SCard>
            <Form.List name="spaces">
              {(fields, { remove }) => (
                <>
                  {fields.map((field, idx) => {
                    const space = values.spaces[idx];
                    const spaceType = spaceTypes.find(
                      (type) => type.id === space.spaceTypeId
                    );

                    if (!space || !spaceType) {
                      return null;
                    }

                    return (
                      <SCard
                        key={field.key}
                        title={spaceType.name}
                        rightHeader={
                          <div
                            onClick={() => remove(idx)}
                            className="card-delete"
                          >
                            <DeleteOutlined />
                          </div>
                        }
                      >
                        <Space
                          values={values}
                          field={field}
                          fieldIndex={idx}
                          spaceType={spaceType}
                          setFieldsValue={setFieldsValue}
                          hotelId={hotel.id}
                          picturesCategories={[
                            { type: 'official', name: 'Media gallery' },
                          ]}
                          kind="official"
                          previousPrefixes={['spaces']}
                          validateFields={validateFields}
                        />
                      </SCard>
                    );
                  })}
                  <div className="spaces__add-button-container">
                    <div
                      onClick={() => setIsModalOpen(true)}
                      className="spaces__add-button"
                    >
                      <PlusOutlined />
                      <span className="text">Add spaces</span>
                    </div>
                    <SpaceModal
                      onCancel={() => setIsModalOpen(false)}
                      onSave={onModalSave}
                      visible={isModalOpen}
                      values={values}
                      spaceTypes={spaceTypes}
                    />
                  </div>
                </>
              )}
            </Form.List>
          </div>
        )}
      </FormLegacyRenderProp>
    </HeaderWrapper>
  );
};

type Props = {
  submitSpaces: (hotelId: number, values: SpaceSubmitType) => void;
  spaceTypes: Array<SpaceType>;
  hotel: Hotel;
  onSubmit: () => void;
};

const mapDispatchToProps = (dispatch: any) => ({
  submitSpaces: (hotelId: any, values: any) =>
    dispatch(trySaveSpaces({ hotelId, spaces: values })),
});

export default connect(undefined, mapDispatchToProps)(SpacesForm);
