import { Menu } from 'antd';
import _ from 'lodash';
import React, { FC, ReactNode, useCallback, useState } from 'react';

import type { Club } from 'app/redux/models/Club/Club';

import ListPage from './ListPage';

const ClubListPage: FC<Props> = ({
  title,
  clubs,
  withContentWrapper,
  children,
}) => {
  const [clubId, setClubId] = useState(1);
  const [countryId, setCountryId] = useState(1);

  const handleChangeTab = useCallback(
    ({ key }: any) => {
      setClubId(+key);

      const club = _.find(clubs, { id: +key });

      if (club) {
        setCountryId(club.countryId);
      }
    },
    [clubs]
  );

  return (
    <ListPage
      title={title}
      menu={
        <Menu
          mode="horizontal"
          onClick={handleChangeTab}
          selectedKeys={[clubId.toString()]}
        >
          {clubs &&
            clubs.map((cl) => <Menu.Item key={cl.id}>{cl.name}</Menu.Item>)}
        </Menu>
      }
      withContentWrapper={withContentWrapper}
    >
      {children({ clubId, countryId })}
    </ListPage>
  );
};

type ChildProps = {
  clubId: number;
  countryId: number;
};

type Props = {
  title: string;
  clubs: Club[] | undefined;
  withContentWrapper?: boolean;
  children: (props: ChildProps) => ReactNode;
};

export default ClubListPage;
