import * as Sentry from '@sentry/browser';
import { Layout } from 'antd';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import AdminApp from 'app/components/AdminApp';
import ExtApp from 'app/components/ExtApp';
import HotelApp from 'app/components/HotelApp';
import PublicApp from 'app/components/PublicApp';

import 'styles/app.scss';

class App extends Component {
  componentDidCatch(error: unknown) {
    Sentry.captureException(error);

    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }
  }

  render() {
    return (
      <Layout className="app">
        <Switch>
          <Route path="/ext" component={ExtApp as any} />
          <Route path="/extranet" component={HotelApp as any} />
          <Route path="/public" component={PublicApp} />
          <Route path="/" component={AdminApp as any} />
        </Switch>
      </Layout>
    );
  }
}

export default App;
