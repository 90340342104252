import type { InputNumberProps } from 'antd';
import React from 'react';

const CurrencyContext = React.createContext<Props>({
  currencyFormatter: () => '',
  currencySymbol: '',
  currencyInput: () => <span />,
  currency: undefined,
  locale: undefined,
  countrySlug: undefined,
});

type Props = {
  currencyFormatter: (price?: number) => string;
  currencySymbol: string;
  currencyInput: (props: InputNumberProps) => React.ReactElement;
  currency?: string;
  locale?: string;
  countrySlug?: string;
};

export default CurrencyContext;
