import _ from 'lodash';
import slug from 'slug';

export function generateCode(codeLen) {
  return Math.random()
    .toString(36)
    .substring(2, codeLen + 2)
    .toUpperCase();
}

export const pluralize = (n, single, plural) =>
  `${n} ${n > 1 ? plural : single}`;

export const slugify = (str) => slug(str, { lower: true });

export const upperCaseFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const toSnakeCase = (string) => {
  if (!string) return '';

  return (
    string.charAt(0).toLowerCase() +
    string.slice(1).replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
  );
};

export const formatCurrency = (price, currency, defaultText = '') => {
  const intlConfig = {
    currency,
    style: currency ? 'currency' : undefined,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };

  if (!currency) {
    return price.toFixed(2);
  }

  return _.isNumber(price)
    ? new Intl.NumberFormat('en-US', intlConfig).format(price)
    : defaultText;
};

export const altRoomName = (categoryName, name) =>
  name ? `${categoryName} (${name})` : categoryName;
