import { Button, Form, Input, Modal } from 'antd';
import * as EmailValidator from 'email-validator';
import React from 'react';

import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { updateBookingEmail } from 'app/redux/actions/booking';
import { useAppDispatch } from 'app/redux/hooks';

const RULES = [{ required: true, message: 'Required' }];

type Props = {
  email: string;
  visible: boolean;
  onCancel: () => void;
};

export const EditEmailModal = ({ visible, onCancel, email }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const isFormInvalid =
    !form.isFieldsTouched() ||
    !EmailValidator.validate(form.getFieldValue('email'));
  const formValues = { email };

  const onSubmit = () => {
    dispatch(updateBookingEmail({ email: form.getFieldValue('email') }));
    onCancel();
  };

  return (
    <Modal
      width={660}
      onCancel={onCancel}
      okText="Save"
      visible={visible}
      title="Edit email"
      centered
      footer={[
        <Button
          type="primary"
          onClick={() => form.submit()}
          key="save"
          disabled={isFormInvalid}
        >
          Save
        </Button>,
      ]}
    >
      {visible && (
        <FormLegacyRenderProp
          form={form}
          onFinish={onSubmit}
          initialValues={formValues}
        >
          {
            // Some of our forms rely on the render prop syntax to get access to updated form values during render.
            // TODO try to remove this render prop syntax and see if it still works.
            () => (
              <div className="input-row">
                <Form.Item name="email" label="Email" rules={RULES}>
                  <Input />
                </Form.Item>
              </div>
            )
          }
        </FormLegacyRenderProp>
      )}
    </Modal>
  );
};

export default EditEmailModal;
