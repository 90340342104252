import { DatePicker as AntDatePicker } from 'antd';
import { DatePickerProps } from 'antd/es/date-picker';
// TODO: orib. How to solve this?
//  1. Use only moment, everywhere (not recommended)
//  2. Use dayJs instead of moment + date-fns https://ant.design/docs/react/replace-moment
//  3. Find another solution?
import { Moment } from 'moment';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import React, { FC, useCallback, useState } from 'react';

export const { RangePicker } = AntDatePicker;

const DatePicker: FC<Props> = ({
  value,
  onChange,
  valueFormat,
  timezone,
  ...props
}) => {
  const convertWithTimezone = useCallback(
    (date?: string) => {
      // TODO: should be a better way to do it?
      if (!date) {
        return null;
      }

      return moment.tz(date, timezone);
    },
    [timezone]
  );

  const [date, setDate] = useState<Moment | null>(convertWithTimezone(value));

  const handleChange = useCallback(
    (momentDate: Moment | null) => {
      const tzDate = convertWithTimezone(
        momentDate?.format('YYYY-MM-DD HH:mm:ss')
      );

      onChange(
        valueFormat ? tzDate?.format(valueFormat) : tzDate?.toISOString()
      );
      setDate(tzDate);
    },
    [convertWithTimezone, onChange, valueFormat]
  );

  return (
    <AntDatePicker
      {...props}
      value={date}
      onChange={handleChange}
      picker="date"
      getPopupContainer={(trigger) => trigger.parentElement || trigger}
    />
  );
};

type Props = Omit<DatePickerProps, 'value' | 'onChange'> & {
  value?: string;
  onChange: (date?: string) => void;
  valueFormat?: string;
  timezone?: string;
  showTime?: boolean; // TODO: dunno why I have to add it manually (should be in DatePickerProps)
};

export default DatePicker;
