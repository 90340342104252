import { Form } from 'antd';
import React, { FC, useEffect, useState } from 'react';

import HeaderWrapper from '../HeaderWrapper/HeaderWrapper';
import { HotelRoomsForm, Room } from '../Types';

import General from './General/General';
import RoomCategories from './RoomCategories/RoomCategories';
import './RoomsForm.scss';

const RoomsForm: FC<Props> = ({
  hotel,
  rooms,
  saveHotel,
  saveRoom,
  deleteRoom,
}) => {
  const [form] = Form.useForm();
  const [roomCountIsSet, setRoomCountIsSet] = useState<boolean>(
    !!hotel.roomCount
  );
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    setButtonDisabled(!rooms.length || !roomCountIsSet);
  }, [rooms, roomCountIsSet]);

  return (
    <HeaderWrapper
      nextDisabled={buttonDisabled}
      handleNext={() => form.submit()}
    >
      <div className="rooms-form-container">
        <div className="rooms-form">
          <div className="section">
            <div className="section-header">
              <div className="title">General</div>
            </div>
            <div className="section-body">
              <General
                form={form}
                roomCount={hotel.roomCount}
                onFinish={saveHotel}
                setRoomCountIsSet={setRoomCountIsSet}
              />
            </div>
          </div>
          <div className="section">
            <div className="section-header">
              <div className="title">Room categories</div>
              <div className="subtitle">Add all your room categories</div>
            </div>
            <div className="section-body">
              <RoomCategories
                rooms={rooms}
                saveRoom={saveRoom}
                deleteRoom={deleteRoom}
                hotelId={hotel.id}
              />
            </div>
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};

type Props = {
  saveRoom: (room: Room) => void;
  deleteRoom: (roomId: number) => void;
  saveHotel: (values: HotelRoomsForm) => void;
  rooms: Array<Room>;
  hotel: HotelRoomsForm & { id: number };
};

export default RoomsForm;
