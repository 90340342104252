import { EditoStatus } from 'app/typings/edito';
import { capitalizeFirstLetter } from 'app/utils/string';

import './EditoStatusTag.scss';

type Props = {
  status: EditoStatus;
};

const statusTagColors: Record<
  EditoStatus,
  {
    title: string;
    background: string;
  }
> = {
  featured: {
    background: '#F4FBF6',
    title: '#28a745',
  },
  scheduled: {
    background: '#F8F4FB',
    title: '#6828A7',
  },
  draft: {
    title: '#808080',
    background: '#F9F9F9',
  },
  published: {
    title: '#28A745',
    background: '#F4FBF6',
  },
  unpublished: {
    title: '#FF9500',
    background: '#FFFAF2',
  },
};

export const EditoStatusTag = ({ status }: Props) => {
  return (
    <div
      className="editoStatusTagContainer"
      style={{ backgroundColor: statusTagColors[status].background }}
    >
      <div
        className="status"
        style={{ backgroundColor: statusTagColors[status].title }}
      ></div>
      <div className="title" style={{ color: statusTagColors[status].title }}>
        {capitalizeFirstLetter(status)}
      </div>
    </div>
  );
};
