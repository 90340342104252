import { Checkbox, Col, Row } from 'antd';
import classNames from 'classnames';
import { eachDayOfInterval, format } from 'date-fns';
import { connect } from 'react-redux';

import { useAppSelector } from 'app/redux/hooks';
import { isPassed } from 'app/utils/dates';

import { Opening, Room } from '../../Types';
import { computeRowClassNames } from '../../Utils';

type Props = {
  dirtyOpenings: Array<Opening>;
  isEditing: boolean;
  room: Room;
  currentRange: Interval;
  hasRights: boolean;
  handleForcedOpening: (
    dirtyOpening: Opening | undefined,
    value: boolean
  ) => void;
};

export const ForcedRow = ({
  dirtyOpenings,
  isEditing,
  room,
  currentRange,
  hasRights,
  handleForcedOpening,
}: Props) => {
  const bookableDays = useAppSelector((s) =>
    s.hotelAdmin.inventory
      ? s.hotelAdmin.inventory.saleDate.bookableDays.concat(
          s.hotelAdmin.inventory.saleDate.extraDays
        )
      : []
  );

  const renderPublished = (date: Date) => {
    const opening = room.openings.find(
      (o) => o.date === format(date, 'yyyy-MM-dd')
    );
    const dirtyOpening =
      dirtyOpenings.find(
        (o) =>
          o.date === format(date, 'yyyy-MM-dd') && o.roomId === room.room.id
      ) || opening;
    const isForcedAllowed =
      !!dirtyOpening?.stock &&
      !!dirtyOpening?.bar &&
      !!dirtyOpening?.discountPrice;

    return (
      <Col
        className={classNames(computeRowClassNames(date, bookableDays))}
        flex="1"
        key={`${format(date, 'yyyy-MM-dd')}-${
          opening ? opening.roomId : ''
        }-stock`}
      >
        <Checkbox
          disabled={isPassed(date) || !isForcedAllowed}
          defaultChecked={dirtyOpening?.published}
          onChange={(e) => handleForcedOpening(dirtyOpening, e.target.checked)}
        />
      </Col>
    );
  };

  if (!isEditing || !hasRights) {
    return null;
  }

  return (
    <Row>
      <Col className="extranet-inventory__property-cell extranet-inventory__head">
        Published
      </Col>
      {eachDayOfInterval(currentRange).map(renderPublished)}
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  dirtyOpenings: state.hotelAdmin.dirtyOpenings,
  isEditing: state.hotelAdmin.isEditing,
});

export default connect(mapStateToProps)(ForcedRow);
