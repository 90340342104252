import { Checkbox, Input, InputNumber, Radio, Switch } from 'antd';
import { isNil } from 'lodash';
import React from 'react';
import { Field } from 'redux-form';

import { Select } from 'app/components/fields/Select';
import FormGroup from 'app/components/forms/FormGroup';

import CascadingSearchBox from './CascadingSearchBox';
import DatePicker, { RangePicker } from './DatePicker';
import LazySelect from './LazySelect';
import NumberWithSuffix from './NumberWithSuffix';
import RoomSelect from './RoomSelect';
import SearchBox from './SearchBox';
import TimePicker from './TimePicker';

// TODO: need a RenderField without FormGroup
const RenderField = ({
  input: { value, onChange },
  meta: { touched, error },
  sync,
  defaultValue,
  field: FieldComponent,
  valueProp,
  onChangeProp,
  label,
  extra,
  componentProps,
  children,
  warning,
  ...rest
}) => {
  const inputProps = {
    [valueProp]: !isNil(value) && value !== '' ? value : defaultValue, // undefined needed for Select fields.
    [onChangeProp]: onChange,
  };

  return (
    <FormGroup
      label={label}
      extra={extra}
      touched={touched || sync}
      error={error}
      warning={warning}
    >
      <FieldComponent {...inputProps} {...componentProps} {...rest}>
        {children}
      </FieldComponent>
    </FormGroup>
  );
};

const BasicTextComponent = (props) => (
  <div {...props}>{props.value ? props.value.toString() : ''}</div>
);

export const makeField =
  (valueProp = 'value', onChangeProp = 'onChange') =>
  (Component) =>
  (props) => {
    if (props.mode === 'view') {
      return (
        <Field
          {...props}
          field={BasicTextComponent}
          valueProp={valueProp}
          onChangeProp={onChangeProp}
          component={RenderField}
        />
      );
    }

    return (
      <Field
        {...props}
        field={Component}
        valueProp={valueProp}
        onChangeProp={onChangeProp}
        component={RenderField}
      />
    );
  };

export const makeInputField = makeField();
export const makeCheckboxField = makeField('checked', 'onChange');
export const makeSelectField = makeField('value', 'onSelect');

export const ViewField = makeInputField(BasicTextComponent);
export const InputField = makeInputField(Input);
export const InputNumberField = makeInputField(InputNumber);
export const TextAreaField = makeInputField(Input.TextArea);
export const NumberWithSuffixField = makeInputField(NumberWithSuffix); // TODO: not sure if it should be here or just colocalized with the usage of one-time custom component
export const CheckboxGroupField = makeInputField(Checkbox.Group);
export const TimePickerField = makeInputField(TimePicker);
export const CascadingSearchBoxField = makeInputField(CascadingSearchBox);
export const RadioGroupField = makeInputField(Radio.Group);
export const DatePickerField = makeInputField(DatePicker);
export const RangePickerField = makeInputField(RangePicker);
export const SearchBoxField = makeInputField(SearchBox);
export const LazySelectField = makeInputField(LazySelect);

export const SwitchField = makeCheckboxField(Switch);

export const SelectField = makeSelectField(Select);
export const RoomSelectField = makeSelectField(RoomSelect);
