import { Button, Col, Form, Input, Modal, Popconfirm, Row } from 'antd';
import moment from 'moment';
import React, { FC } from 'react';

import Pic from 'app/components/commons/Pic';
import { Picture } from 'app/components/commons/Uploader/Types';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { getFileSizeInBytes } from 'app/utils/files';

import './EditPictureModal.scss';

const RULES = [{ required: true, message: 'Required' }];

const EditPictureModal: FC<Props> = ({
  picture,
  closeModal,
  savePicture,
  deletePicture,
  kind,
}) => {
  const [form] = Form.useForm();

  const checkChanges = ({ name, alt, credit }: any) => {
    if (name !== picture.name) {
      return true;
    }

    if (alt !== picture.alt) {
      return true;
    }

    if (credit !== picture.credit) {
      return true;
    }

    return false;
  };

  const renderFooter = (values: any) => {
    const hasChanged = checkChanges(values);

    return (
      <>
        <Popconfirm
          title="Delete this picture?"
          onConfirm={deletePicture}
          okText="Yes"
          cancelText="No"
          key="delete"
        >
          <Button>Delete</Button>
        </Popconfirm>
        <Button
          key="save"
          type="primary"
          onClick={() => form.submit()}
          disabled={!hasChanged}
        >
          Save
        </Button>
      </>
    );
  };

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={savePicture}
      initialValues={picture}
      layout="vertical"
    >
      {(values) => (
        <Modal
          width={800}
          visible
          title={`Edit ${kind ? `${kind} ` : ''}Media`}
          onCancel={closeModal}
          footer={renderFooter(values)}
          className="edit-modal"
        >
          <Row className="edit-picture-modal">
            <Col span={9}>
              <div className="thumbnail">
                <Pic
                  pictureId={picture.pictureId}
                  alt={picture.alt}
                  width={265}
                />
              </div>
              <div className="infos">
                <div>{moment(picture.updatedAt).format('MMMM Do YYYY')}</div>
                <div>
                  {picture.dimensions.width}x{picture.dimensions.height}
                  {' • '}
                  {getFileSizeInBytes(picture.size)}
                </div>
              </div>
            </Col>
            <Col span={15}>
              <Form.Item label="Title" name="name" rules={RULES}>
                <Input />
              </Form.Item>
              <Form.Item label="Alternative text" name="alt">
                <Input />
              </Form.Item>
              <Form.Item label="Credit" name="credit">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      )}
    </FormLegacyRenderProp>
  );
};

type Props = {
  picture: Picture;
  closeModal: () => void;
  savePicture: (picture: any) => void;
  deletePicture: () => void;
  kind?: string;
};

export default EditPictureModal;
