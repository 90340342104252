import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const selectPictures = (state: RootState) => state.pictures;
export const selectEmojis = createSelector(
  selectPictures,
  (pictures) => pictures.emojis
);
export const selectPicturesPictures = createSelector(
  selectPictures,
  (pictures) => pictures.pictures
);
export const selectOrderedByIdPictures = createSelector(
  selectPicturesPictures,
  (pictures) =>
    pictures
      .filter((pic) => pic.kind === 'official')
      .sort((p1, p2) => p1.id - p2.id)
);
