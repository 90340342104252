import { useState } from 'react';

import { EditoEventStatus, SelectedEventClubId } from 'app/typings/edito';
import { getEditoEventStatusFromQuery } from 'app/utils/edito';
import { useSearchParams } from 'app/utils/searchParams';

export const useEditoEventListParams = () => {
  const { getSearchParam, setSearchParams } = useSearchParams();
  const initialSearch = getSearchParam('search');
  const initialCountryId = getSearchParam('countryId');
  const initialClubId = getSearchParam('clubId');
  const initialStatus = getEditoEventStatusFromQuery(getSearchParam('status'));

  const [searchedText, setSearchedText] = useState(initialSearch ?? '');
  const [selectedCountryId, setSelectedCountryId] = useState(
    initialCountryId && !isNaN(Number(initialCountryId))
      ? Number(initialCountryId)
      : 1
  );
  const [selectedClubId, setSelectedClubId] = useState<SelectedEventClubId>(
    initialClubId && !isNaN(Number(initialClubId))
      ? Number(initialClubId)
      : 'all'
  );

  const [selectedStatus, setSelectedStatus] = useState<
    EditoEventStatus | 'all'
  >(initialStatus);

  const onChangeSearchText = (search: string) => {
    setSearchParams({ search });
    setSearchedText(search);
  };

  const onChangeCountryId = (countryId: number) => {
    if (selectedCountryId !== countryId) {
      onChangeClubId('all');
    }

    setSearchParams({ countryId: countryId.toString() });
    setSelectedCountryId(countryId);
  };

  const onChangeClubId = (clubId: SelectedEventClubId) => {
    setSearchParams({
      clubId: clubId.toString(),
    });

    setSelectedClubId(clubId);
  };

  const onChangeStatus = (status: EditoEventStatus | 'all') => {
    setSearchParams({
      status,
    });

    setSelectedStatus(status);
  };

  return {
    searchedText,
    selectedClubId,
    selectedCountryId,
    onChangeSearchText,
    onChangeClubId,
    onChangeCountryId,
    selectedStatus,
    onChangeStatus,
  };
};
