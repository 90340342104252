import { Cascader } from 'antd';
import { filter, has, includes, last } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const mapDataSource = (dataSource) => {
  const parentNodes = filter(
    dataSource,
    (element) => !has(element, 'parentId')
  );

  const findChildren = (nodes) =>
    nodes.map((node) => {
      const children = filter(dataSource, { parentId: node.id });

      const option = { value: node.id, label: node.name };

      if (!children.length) {
        return option;
      }

      return { ...option, children: findChildren(children) };
    });

  return findChildren(parentNodes);
};

const getValue = (value, options) => {
  let result;

  const findValuePath = (leafValue, nodes, valuePath) =>
    nodes.map((node) => {
      const updatedValuePath = [...valuePath, node.value];

      if (node.value === leafValue) {
        result = updatedValuePath;

        return 'found';
      }

      if (!node.children) {
        return 'notfound';
      }

      return findValuePath(leafValue, node.children, updatedValuePath);
    });

  findValuePath(value, options, []);

  return result;
};

class CascadingSearchBox extends Component {
  static propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    dataSource: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ).isRequired,
  };

  static getDerivedStateFromProps(props) {
    const options = mapDataSource(props.dataSource);
    const value = getValue(props.value, options);

    return { value, options };
  }

  state = { options: [] };

  filterOptions = (inputValue, path) =>
    path.some((option) =>
      includes(option.label.toLowerCase(), inputValue.toLowerCase())
    );

  handleChange = (values) => this.props.onChange(last(values));

  render() {
    return (
      <Cascader
        {...this.props}
        value={this.state.value}
        onChange={this.handleChange}
        expandTrigger="hover"
        options={this.state.options}
        showSearch={{ filter: this.filterOptions }}
      />
    );
  }
}

export default CascadingSearchBox;
